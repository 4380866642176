import { CarOutlined, ReloadOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import './css/index.css';
import logoTc from '../../components/image/Logo_TC.png';
import QRCode from 'react-qr-code';
import imgTruck from '../image/truck.svg';
import imgFork from '../image/forklift.svg';
import imgUser from '../image/user.svg';
import imgUserDB from '../image/user_db.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';

const DashBoardTab = (props) => {
  const { qrId, setTriggerQr, listMember, listOrder, teamName,totalOrder,totalMem } = props;
  const intl = useIntl();
  const qrCodeRef = useRef(null);
  const [order, setOrder] = useState([]);
  const [member, setMember] = useState([]);
  const [timeLeft, setTimeLeft] = useState();
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const date = new Date();
  const currentDate = moment(date).format('DD/MM/YYYY');
  const hours = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  const sec = String(date.getSeconds()).padStart(2, '0');
  const formattedTime = `${currentDate} ${hours}:${min}:${sec}`;

  useEffect(() => {
    setTimeLeft(15 * 60);
  }, [qrId]);

  useEffect(() => {
    if (listOrder) {
      setOrder([
        {
          text: intl.formatMessage({ id: 'DashBWaiting', defaultMessage: 'รอเริ่มงาน' }),
          number: listOrder.waiting,
          color: '#F79128',
          id: 1,
          highlight: true,
        },
        {
          text: intl.formatMessage({ id: 'DashBEnroute', defaultMessage: 'ระหว่างทาง' }),
          number: listOrder.enroute,
          color: '#49C25C',
          id: 2,
        },
        {
          text: intl.formatMessage({ id: 'DashBOngoing', defaultMessage: 'กำลังจัดส่ง' }),
          number: listOrder.ongoing,
          color: '#3BA222',
          id: 3,
        },
        {
          text: intl.formatMessage({ id: 'DashBCompleted', defaultMessage: 'ส่งสำเร็จ' }),
          number: listOrder.completed,
          color: '#1B663A',
          id: 4,
        },
        {
          text: intl.formatMessage({ id: 'DashBDeliverybyMerchant', defaultMessage: 'จัดส่งเอง' }),
          number: listOrder.selfcompleted,
          color: '#2658AD',
          id: 5,
        },

        {
          text: intl.formatMessage({ id: 'DashBReturnedItem', defaultMessage: 'สินค้าตีกลับ' }),
          number: listOrder.returnitem || 0,
          color: '#E73845',
          id: 6,
        },
        {
          text: intl.formatMessage({ id: 'DashBItemnotFound', defaultMessage: 'ไม่พบสินค้า' }),
          number: listOrder.itemnotfound || 0,
          color: '#909AAF',
          id: 7,
        },
      ]);
    }
  }, [listOrder]);

  useEffect(() => {
    if (listMember) {
      setMember([
        {
          text: intl.formatMessage({ id: 'DashBAvailable', defaultMessage: 'พร้อมรับงาน' }),
          number: listMember.available,
          color: '#3A90FF',
          id: 1,
        },
        {
          text: intl.formatMessage({ id: 'DashBOngoing', defaultMessage: 'กำลังทำงาน' }),
          number: listMember.ongoing,
          color: '#49C25C',
          id: 2,
        },
        {
          text: intl.formatMessage({ id: 'DashBOnBreak', defaultMessage: 'พักเบรก' }),
          number: listMember.offline,
          color: '#909AAE',
          id: 3,
        },
      ]);
    }
  }, [listMember]);

  console.log('order', order);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          handleTimeOut();
          return 15 * 60;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleTimeOut = () => {
    setTriggerQr((event) => !event);
  };

  const resetTimer = () => {
    // setTimeLeft(15 * 60);
    setTriggerQr((event) => !event);
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Card
        style={{
          borderRadius: '8px 8px 0px 0px',
          height: '97vh',
          // overflowY: 'auto',
        }}
      >
        <Row style={{ marginBottom: '8px' }}>
          <Col span={12}>
            {/* <div className="dashboard-column-center">
              <img src={logoTc} alt="logo" style={{ width: 'auto', height: '4vw' }} />
              <div className="dashboard-tab-title">{teamName}</div>
            </div> */}
            <div className="dashboard-qr">
              {/* <QRCode id={qrId} ref={qrCodeRef} value={qrId} size={(window.innerWidth * 7) / 100} /> */}
              <QRCode id={qrId} ref={qrCodeRef} value={qrId} size={(window.innerWidth * 8) / 100} />
            </div>
          </Col>
          <Col span={12}>
            <div className="dashboard-column-center ">
              {/* <div className="dashboard-qr">
                <QRCode id={qrId} ref={qrCodeRef} value={qrId} size={(window.innerWidth * 7) / 100} />
              </div> */}
              <div className="dashboard-column-center">
                <img src={logoTc} alt="logo" style={{ width: 'auto', height: '4vw' }} />
                <div className="dashboard-tab-title">{teamName}</div>
              </div>
              <div className="dashboard-qr-time" style={{ padding: '5px', backgroundColor: '#F3FAEF', borderRadius: '0 0 8px 8px' }}>
                <span className="dashboard-font18" style={{ color: '#000' }}>
                  {formattedTime}
                </span>
              </div>
            </div>
          </Col>
        </Row>

        {/* <div className="dashboard-qr-time" style={{ padding: '5px', backgroundColor: '#F3FAEF', borderRadius: '0 0 8px 8px' }}>
          <span className="dashboard-font18" style={{ color: '#000' }}>
            {formattedTime}
          </span>
        </div> */}

        <div>
          <div className="dashboard-status">
            <span className="dashboard-font20" style={{ display: 'flex', alignItems: 'center' }}>
              {/* <CarOutlined style={{ color: item?.color, marginRight: '6px' }} />  */}
              <FormattedMessage id="DashBTotalQueuestoday" defaultMessage="งานทั้งหมดวันนี้" />
            </span>
            <span className={'dashboard-font20'} style={{ paddingRight: '3px' }}>
              {totalOrder}
            </span>
          </div>

          {order?.map((item) => (
            <Card
              style={{
                padding: 'unset',
                margin: '0.2vw',
                backgroundColor: item.highlight ? '#FFF5EA' : '#fff',
                border: item.highlight ? '1px solid #FFBE7E' : '1px solid #fff',
                borderRadius: '8px',
              }}
              bodyStyle={{ padding: 'unset' }}
            >
              <div className="dashboard-status">
                <span className="dashboard-font18" style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <CarOutlined style={{ color: item?.color, marginRight: '6px' }} />  */}
                  <div
                    style={{
                      width: '2.5vw',
                      height: '2.5vw',
                      borderRadius: '50%',
                      backgroundColor: item?.color,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '1vw',
                    }}
                  >
                    <div
                      style={{
                        width: '1.875vw',
                        height: '3vh ',
                        mask: `url(${imgFork}) no-repeat center`,
                        WebkitMask: `url(${imgFork}) no-repeat center`,
                        // backgroundColor: item?.color || '#000000',
                        backgroundColor: '#FFF5EA' || '#000000',
                      }}
                    ></div>
                  </div>
                  {item?.text}
                </span>
                <span className={item.highlight ? 'dashboard-font20' : 'dashboard-font18'}>{item?.number}</span>
              </div>
            </Card>
          ))}

          <Divider style={{ margin: '1vw 1vw 1vw 0vw', padding: 0 }} />
          <div className="dashboard-status">
            <span className="dashboard-font20" style={{ display: 'flex', alignItems: 'center' }}>
              {/* <CarOutlined style={{ color: item?.color, marginRight: '6px' }} />  */}
              <FormattedMessage id="DashBTotalMember" defaultMessage="สมาชิกทั้งหมด" />
            </span>
            <span className={'dashboard-font20'} style={{ paddingRight: '3px' }}>
              {totalMem}
            </span>
          </div>
          {member?.map((item) => (
            <Card
              style={{
                padding: 'unset',
                margin: '0.2vw',
                backgroundColor: item.highlight ? '#FFFAE5' : '#fff',
                border: item.highlight ? '1px solid #FFD700' : '1px solid #fff',
                borderRadius: '8px',
              }}
              bodyStyle={{ padding: 'unset' }}
            >
              <div className="dashboard-status">
                <span className="dashboard-font18" style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <CarOutlined style={{ color: item?.color, marginRight: '6px' }} />  */}
                  <div
                    style={{
                      width: '2.5vw',
                      height: '2.5vw',
                      borderRadius: '50%',
                      backgroundColor: item?.color,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '1vw',
                    }}
                  >
                    <div
                      style={{
                        width: '1.875vw',
                        height: '3vh ',
                        mask: `url(${imgUserDB}) no-repeat center`,
                        WebkitMask: `url(${imgUserDB}) no-repeat center`,
                        // backgroundColor: item?.color || '#000000',
                        backgroundColor: '#FFF5EA' || '#000000',
                      }}
                    ></div>
                  </div>
                  {item?.text}
                </span>
                <span className={item.highlight ? 'dashboard-font20' : 'dashboard-font18'}>{item?.number}</span>
              </div>
            </Card>
          ))}
        </div>
      </Card>

      {/* <div
        className="dashboard-font18"
        style={{
          margin: 0,
          padding: 0,
          // backgroundColor: "#909090",
          backgroundColor: '#F4F4F4',
          borderRadius: '0 0 8px 8px',
          height: '5vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          zIndex: 100,
        }}
      >
        {formattedTime}
      </div> */}
    </div>
  );
};

export default DashBoardTab;
