import React, { useState } from 'react';
import { Button, Card, Checkbox, Divider, Dropdown, Icon, Menu, Table } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ChartDoughnut from '../../component/chart-doughnut';
import './index.css';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';

const DoughnutRevenue = (prop) => {
  const intl = useIntl();
  const { chartData } = prop;

  // const chartData = [
  //   { name: 'ตลาดส้ม', value: 400, percentage: '20.00%', color: '#0CA150' },
  //   { name: 'ตลาดผลไม้รวม', value: 300, percentage: '15.00%', color: '#6BBB6E' },
  //   { name: 'ตลาดดอกไม้และสังฆภัณฑ์', value: 300, percentage: '15.00%', color: '#519DE9' },
  //   { name: 'ตลาดสด ชั้น 1', value: 200, percentage: '10.00%', color: '#0066CC' },
  //   { name: 'ตลาดสด ชั้น 2', value: 200, percentage: '10.00%', color: '#B2B0EA' },
  //   { name: 'ตลาดของแห้ง', value: 200, percentage: '10.00%', color: '#EC7A08' },
  //   { name: 'ตลาดอาหารทะเล', value: 200, percentage: '10.00%', color: '#EF9234' },
  //   { name: 'ตลาดปลาน้ำจืด', value: 200, percentage: '10.00%', color: '#C8190B' },
  // ];

  const tableData = chartData.map((item, index) => ({
    key: index,
    label: item.name,
    percentage: item.percentage,
    value: item.value,
    color: item.color,
  }));

  const tableColumns = [
    {
      title: 'Market',
      dataIndex: 'label',
      key: 'label',
      width: 200,
      sorter: (a, b) => a.label.length - b.label.length,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '25px',
              height: '25px',
              marginRight: 10,
              backgroundColor: record.color,
              borderRadius: '8px',
              textAlign: 'center',
              color: '#fff',
              lineHeight: '25px',
            }}
          >
            {record.key + 1}
          </div>
          {text}
        </div>
      ),
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a, b) => a.percentage - b.percentage,
      align: 'right',
      width: 130,
      render: (text, record) => <span style={{ paddingRight: '25px' }}>{Number(record.percentage).toFixed(1)}</span>,
    },
    {
      title: 'Revenue (THB)',
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => a.value - b.value,
      align: 'right',
      width: 200,
      render: (text, record) => <span style={{ paddingRight: '30px' }}>{numberFormatter(record.value)}</span>,
    },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ flex: 1 }}>
        <ChartDoughnut data={chartData} />
      </div>
      <div style={{ width: '60%' }}>
        <Table
          className="doughnut-table"
          columns={tableColumns}
          dataSource={tableData}
          pagination={false}
          scroll={{ x: _.sumBy(tableColumns, 'width'), y: 280 }}
        />
      </div>
    </div>
  );
};

export default DoughnutRevenue;
