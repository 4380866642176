import React, { useContext, useRef, useState } from 'react';
import { OverviewProvider } from './context';
import './index.css';
import OverviewPage from './overview-page';
import { ConfigProvider } from 'antd';
import FullScreen from 'react-fullscreen-crossbrowser';


const OverviewProviderPage = () => {
  const fullscreenPageRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    // <ConfigProvider getPopupContainer={() => fullscreenPageRef.current || document.body}>
    <FullScreen enabled={isFullScreen} onChange={setIsFullScreen}>
      <OverviewProvider>
        <div ref={fullscreenPageRef}>
          <OverviewPage 
            // fullscreenPageRef={fullscreenPageRef} 
            isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen}
          />
        </div>
      </OverviewProvider>
    </FullScreen>

    // </ConfigProvider>
  );
};

export default OverviewProviderPage;
