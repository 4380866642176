import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getReimbursementList = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/reimbusementlist`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const saveReimbursement = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/reimbusement`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const editReimbursement = async (payload) => {
  try {
    const response = await httpClient.put(`/reference/manager/company/${comCode}/web/reimbusement`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteReimbursementApi = async (id) => {
  try {
    const response = await httpClient.delete(`/reference/manager/company/${comCode}/web/reimbusement/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

///////////////////////////////////////////////////////////////////////

const getGasList = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/gaslist`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const saveGasApi = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/gas`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const editGasApi = async (payload) => {
  try {
    const response = await httpClient.put(`/reference/manager/company/${comCode}/web/gas`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteGasApi = async (id) => {
  try {
    const response = await httpClient.delete(`/reference/manager/company/${comCode}/web/del-gas/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//////////////////////////////////////////////////////

const getGasTypeList = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/gas-type-list`, payload);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const creatGasType = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/gas-type`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

const updateGasType = async (payload, ) => {
  try {
    const response = await httpClient.put(`/reference/manager/company/${comCode}/web/gas-type`, payload);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const deleteGasType = async (id) => {
  try {
    const response = await httpClient.delete(`/reference/manager/company/${comCode}/web/del-gas-type/${id}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
  
}



export {
  getReimbursementList,
  saveReimbursement,
  editReimbursement,
  deleteReimbursementApi,
  getGasList,
  saveGasApi,
  editGasApi,
  deleteGasApi,
  getGasTypeList,
  creatGasType,
  updateGasType,
  deleteGasType,
};
