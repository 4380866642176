import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Divider, Dropdown, Icon, Menu } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ChartLineBar from '../../component/chart-line-bar';

const OccRateArr = (prop) => {
  const { visibleDatasets, setVisibleDatasets, chartData } = prop;
  const intl = useIntl();

  // const chartData = [
  //   {
  //     name: 'This Month',
  //     bar: 60,
  //     line: 1700,
  //   },
  //   {
  //     name: 'Last Month',
  //     bar: 80,
  //     line: 1800,
  //   },
  //   {
  //     name: 'Last Year',
  //     bar: 45,
  //     line: 1500,
  //   },
  // ];

  // const [visibleDatasets, setVisibleDatasets] = useState({
  //   bar: true,
  //   line: true,
  // });

  const [tempVisibleDatasets, setTempVisibleDatasets] = useState({
    ...visibleDatasets,
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDataset = (key) => {
    setTempVisibleDatasets((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const confirmChanges = () => {
    setVisibleDatasets({ ...tempVisibleDatasets });
    setDropdownVisible(false);
  };

  const resetChanges = () => {
    setTempVisibleDatasets({ bar: true, line: true });
    setVisibleDatasets({ bar: true, line: true });
    setDropdownVisible(false);
  };

  const menuColumn = (
    <Menu style={{ width: '200px', paddingTop: '10px', paddingBottom: '0px' }}>
      <Menu.Item key="bar" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Checkbox checked={tempVisibleDatasets.bar} onChange={() => toggleDataset('bar')}>
          OCC Rate
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="line" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Checkbox checked={tempVisibleDatasets.line} onChange={() => toggleDataset('line')}>
          ARR
        </Checkbox>
      </Menu.Item>

      <Divider style={{ margin: '10px 0 0 0' }} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={confirmChanges}
          style={{
            fontSize: '14px',
            border: 'none',
            width: '48%',
            height: '37px',
          }}
        >
          <FormattedMessage id="btnOK" defaultMessage="OK" />
        </Button>
        <Button
          onClick={resetChanges}
          style={{
            fontSize: '14px',
            border: 'none',
            width: '48%',
            height: '37px',
          }}
        >
          <FormattedMessage id="btnReset" defaultMessage="Reset" />
        </Button>
      </div>
    </Menu>
  );

  const filteredData = chartData.map((item) => {
    const filteredItem = { name: item.name };
  
    Object.keys(item).forEach((key) => {
      if (visibleDatasets.bar && key.startsWith("bar")) {
        filteredItem[key] = item[key];
      }
      if (visibleDatasets.line && key.startsWith("line")) {
        filteredItem[key] = item[key];
      }
    });
  
    return filteredItem;
  });
  
  
  const chartConfig = {
    barColor: '#53C139',
    lineColor: '#134A0D',
    lineDash: '2 2',
    lineLegendType: 'plainline',
    domain: [0, 100],
    yLeft: 'OCC Rate (%)',
    yRight: 'ARR (THB/sq.m)',
  };

  const CustomLegend = ({ payload, barNames, lineNames }) => {
    const barItems = payload.filter((entry) => entry.dataKey.startsWith('bar'));
    const lineItems = payload.filter((entry) => entry.dataKey.startsWith('line'));

    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '100px', marginTop: '15px', lineHeight: '30px' }}>
        <div>
          {barItems.map((entry, index) => (
            <div key={entry.dataKey} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ width: '12px', height: '12px', backgroundColor: entry.color }}></div>
              <span>{barNames[entry.dataKey] || entry.value} (A)</span>
            </div>
          ))}
        </div>

        <div>
          {lineItems.map((entry, index) => (
            <div key={entry.dataKey} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ width: '12px', height: '2px', backgroundColor: entry.color }}></div>
              <span>{lineNames[entry.dataKey] || entry.value} (B)</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  console.log(chartData, 'chartData1')
  console.log(filteredData, 'chartData2')

  return <ChartLineBar data={filteredData} config={chartConfig} CustomLegend={CustomLegend} />;
};

export default OccRateArr;
