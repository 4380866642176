import { Button, Col, DatePicker, Icon, Row, Select } from 'antd';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import '../../index.css';
import moment from 'moment';

const { Option, OptGroup } = Select;
const { RangePicker, MonthPicker, YearPicker } = DatePicker;

const FilterOnTopTab = (props) => {
  const intl = useIntl();
  const {
    selectMarket,
    title,
    mutiSelect,
    button,
    onlyButton,
    optionCustom,
    defaultDuration,
    marketAllList,
    setSelectMarket,
    setDate,
    date,
    single,
    selectSingle,
    setSelectSigle,
    paddingEdit,
    sizeSelect,
    allMonth,
  } = props;
  const dateFormat = 'DD/MM/YYYY';
  const monthFormat = 'MMMM YYYY';
  const yearFormat = 'YYYY';

  const [activeView, setActiveView] = useState('store');
  const [selectDuration, setSelectDuration] = useState(defaultDuration ? defaultDuration : 'month');
  const options = [];
  const optionDuration = [
    {
      value: 'month',
      label: intl.formatMessage({ id: 'RevenueMonth', defaultMessage: 'Month' }),
    },
    {
      value: 'year',
      label: intl.formatMessage({ id: 'RevenueYear', defaultMessage: 'Year' }),
    },
    {
      value: 'quarter',
      label: intl.formatMessage({ id: 'RevenueQuarter', defaultMessage: 'Quarter' }),
    },
    {
      value: 'duration',
      label: intl.formatMessage({ id: 'RevenueDuration', defaultMessage: 'Duration' }),
    },
  ];
  const optionQuarter = [
    {
      value: '1',
      label: 'Q1',
    },
    {
      value: '2',
      label: 'Q2',
    },
    {
      value: '3',
      label: 'Q3',
    },
    {
      value: '4',
      label: 'Q4',
    },
  ];

  const optionYear = [
    {
      value: moment().format('YYYY'),
      label: moment().format('YYYY'),
    },
    {
      value: moment()
        .subtract(1, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(1, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(2, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(2, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(3, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(3, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(4, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(4, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(5, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(5, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(6, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(6, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(7, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(7, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(8, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(8, 'years')
        .format('YYYY'),
    },
    {
      value: moment()
        .subtract(9, 'years')
        .format('YYYY'),
      label: moment()
        .subtract(9, 'years')
        .format('YYYY'),
    },
  ];

  const onChangeDuration = (value) => {
    if (value === 'month') {
      const body = {
        type: value,
        value: moment().format('MMMM YYYY'),
      };
      setDate(body);
    } else if (value === 'year') {
      const body = {
        type: value,
        value: moment().format('YYYY'),
      };
      setDate(body);
    } else if (value === 'quarter') {
      const body = {
        type: value,
        value: '1',
      };
      setDate(body);
    } else {
      const dateArray = [moment().startOf('month'), moment().endOf('month')];
      const body = {
        type: value,
        value: dateArray,
      };
      setDate(body);
    }

    setSelectDuration(value);
  };

  const onChangeDate = (value, type) => {
    console.log('REDATEDDD', value);
    if (type === 'month') {
      if (value === null) {
        const body = {
          type: value,
          value: moment().format('MMMM YYYY'),
        };
        setDate(body);
      }
      const tempDate = moment(value, 'MMMM YYYY').format('MMMM YYYY');
      const body = {
        type: type,
        value: tempDate,
      };
      setDate(body);
    } else {
      if (value === null) {
        const dateArray = [moment().startOf('month'), moment().endOf('month')];
        const body = {
          type: value,
          value: dateArray,
        };
        setDate(body);
      } else {
        const body = {
          type: type,
          value: value,
        };
        setDate(body);
      }
    }
  };

  const onChangeSelectMarket = (value, key) => {
    console.log('SSISISIS',value);
    
    if (_.last(value) === 'all' || _.size(value) === 0  ) {
      if (selectSingle) {
        setSelectMarket([]);
      }else {
        setSelectMarket(['all']);
      }
      
    } else if (_.size(value) <= sizeSelect) {
      if (_.includes(value, 'all')) {
        const filterNoAll = _.filter(value, (item) => item !== 'all');
        setSelectMarket(filterNoAll);
      } else {
        setSelectMarket(value);
      }
    }
  };

  const onChangeSelectSingleMarket = (value) => {
    setSelectSigle(value)
  };

  const disabledDate = (current) => {
    if (allMonth) {
      return false
    } else {
      return !current.isSame(moment(), "month");
    }
    
  };

  return (
    <div
      style={{
        alignItems: 'center',
        padding: paddingEdit,
        backgroundColor: '#fff',
        borderRadius: '4px',
        top: 0,
        width: '100%',
        zIndex: 1,
      }}
    >
      {onlyButton === true ? (
        <Row style={{ width: '100%' }} type="flex" justify="end">
          {button}
        </Row>
      ) : (
        <Row type="flex" justify="space-between" align="middle">
          <Col span={4} style={{ fontWeight: 'bold', color: '#1D3557' }}>
            {' '}
            {title}
          </Col>
          <Col span={20}>
            <Row type="flex" justify="end" align="middle">
              {mutiSelect === true ? (
                <Col>
                  <Select
                    className="mutiSelectRe"
                    mode="multiple"
                    placeholder={intl.formatMessage({ id: 'taecoTableTalaadthaiPHSelectFilterMap', defaultMessage: 'Select Filter' })}
                    style={{
                      width: '330px',
                    }}
                    value={selectMarket}
                    onChange={(e) => {
                      onChangeSelectMarket(e);
                    }}
                    // suffixIcon={<>{_.size(selectMarket)} / 4</>}
                    maxTagTextLength={8}
                    maxTagCount={2}
                  >
                    {_.map(marketAllList, (opt) => (
                      <Option key={opt.objectId} value={opt.objectId}>
                        {opt.name}
                      </Option>
                      // <OptGroup label={opt.name}>
                      //   {opt.value.map((option) => (
                      //     <Option key={option.value} value={option.value}>
                      //       {option.name}
                      //     </Option>
                      //   ))}
                      // </OptGroup>
                    ))}
                  </Select>
                  <span
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '12px',
                      transform: 'translateY(-50%)',
                      fontSize: '14px',
                      color: '#999',
                      pointerEvents: 'none',
                    }}
                  >
                    {_.size(selectMarket)} / {sizeSelect}
                  </span>
                </Col>
              ) : null}
              {single === true ? (
                <Col>
                  <Select
                    className="mutiSelectRe"
                    placeholder={intl.formatMessage({ id: 'taecoTableTalaadthaiPHSelectFilterMap', defaultMessage: 'Select Filter' })}
                    style={{
                      width: '330px',
                    }}
                    value={selectSingle}
                    onChange={(e) => {
                      onChangeSelectSingleMarket(e);
                    }}
                    defaultValue={_.get(marketAllList,'[0].objectId')}
                  >
                    {_.map(marketAllList, (opt) => (
                      <Option key={opt.objectId} value={opt.objectId}>
                        {opt.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              ) : null}
              {date === true ? (
                <>
                  <div>
                    <Select
                      className="revenueSelect"
                      defaultValue={defaultDuration ? defaultDuration : 'month'}
                      onChange={onChangeDuration}
                    >
                      {optionDuration.map((opt) => {
                        return _.includes(optionCustom, opt.value) || _.size(optionCustom) === 0 ? (
                          <Option key={opt.value} value={opt.value}>
                            {opt.label}
                          </Option>
                        ) : null;
                      })}
                    </Select>
                  </div>
                  {selectDuration === 'month' ? (
                    <div>
                      {/* <DatePicker className="revenueSelectRight" onChange={() => {}} mode={selectDuration} /> */}
                      <MonthPicker
                        className="revenueSelectRight"
                        onChange={(e) => {
                          onChangeDate(e, selectDuration);
                        }}
                        mode={selectDuration}
                        format={monthFormat}
                        defaultValue={moment()}
                        allowClear={false}
                      />
                    </div>
                  ) : null}
                  {selectDuration === 'year' ? (
                    <div>
                      <Select
                        className="revenueSelectRight"
                        defaultValue={_.get(optionYear, '[0].value')}
                        onChange={(e) => {
                          onChangeDate(e, selectDuration);
                        }}
                      >
                        {optionYear.map((opt) => {
                          return (
                            <Option key={opt.value} value={opt.value}>
                              {opt.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  ) : null}
                  {selectDuration === 'quarter' ? (
                    <div>
                      <Select
                        className="revenueSelectRight"
                        defaultValue={'1'}
                        onChange={(e) => {
                          onChangeDate(e, selectDuration);
                        }}
                      >
                        {optionQuarter.map((opt) => {
                          return (
                            <Option key={opt.value} value={opt.value}>
                              {opt.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  ) : null}
                  {selectDuration === 'duration' ? (
                    <div>
                      <RangePicker
                        className="revenueSelectRight"
                        onChange={(e) => {
                          onChangeDate(e, selectDuration);
                        }}
                        format={dateFormat}
                        defaultValue={[moment(), moment()]}
                        allowClear={false}
                        disabledDate={disabledDate}
                      />
                    </div>
                  ) : null}{' '}
                </>
              ) : null}
              {button}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

// ModalParcelAddForm.defaultProps = {
//   visible: false,
//   record: [],
//   onCancel: () => console.warn('onCancel not function '),
//   type: 'add',
// };

FilterOnTopTab.defaultProps = {
  marketAllList: [],
  selectMarket: [],
  onChangeSelectMarket: () => console.warn('not function'),
  paddingEdit: '24px 24px 0px 24px',
  sizeSelect: 0,
};

export default FilterOnTopTab;
