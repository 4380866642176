import { Button, Col, DatePicker, Icon, Input, Modal, Row, Select, Table, Tag } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messageLabel } from '../../../components/message-component';
import CustomTableComponent from '../../../components/table';
import { useAppContext } from '../../../includes/indexProvider';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import LoadmoreCustomerCreditNote from '../../../components/loadmore/customer-credit-note';
import { getListInvoice } from '../../../controllers/credit-note/credit-note';
import moment from 'moment';
import httpClient from '../../../components/axiosClient';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;

const ModalAddInvoice = ({ visible, setVisble, setTrigger, dataForm, setDataForm, initial, onSetFieldInvNo }) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('modalAddInvoiceColumnINVNo', 'INV No.'),
      dataIndex: 'invNo',
      key: 'invNo',
      sorter: true,
      width: 150,
      ellipsis: true,
      fixed: 'left',
    },
    {
      title: messageLabel('modalAddInvoiceColumnSoNo', 'So No.'),
      dataIndex: 'soNo',
      key: 'soNo',
      sorter: true,
      width: 150,
    },
    {
      title: messageLabel('modalAddInvoiceColumnDueDate', 'Due Date'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: true,
      width: 150,
      render: (text, record, index) => (text ? moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'),
    },

    {
      title: messageLabel('modalAddInvoiceColumnAmount', 'Amount'),
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      width: 200,
      align: 'right',
      render:(text, record, index) => (text ? numberFormatter(text) : 0) + ' THB',
    },
    {
      title: messageLabel('modalAddInvoiceColumnCn', 'CN'),
      dataIndex: 'cn',
      key: 'cm',
      // sorter: true,
      width: 150,
      align: 'right',
    },
    {
      title: messageLabel('modalAddInvoiceColumnRemark', 'Remark'),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalAddInvoiceColumnTeam', 'Team'),
      dataIndex: 'team',
      key: 'team',
      sorter: true,
      ellipsis: true,

      width: 300,
    },

    {
      title: messageLabel('modalAddInvoiceColumnIssuedBy', 'Issued by'),
      dataIndex: 'issuedBy',
      key: 'issuedBy',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('modalAddInvoiceColumnIssuedDate', 'Issued Date'),
      dataIndex: 'issuedDate',
      key: 'issuedDate',
      width: 200,
      sorter: true,
      render: (text, record, index) => (text ? moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'),
    },
  ];

  const comCode = localStorage.getItem('comCode');

  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const [sortFieldTable, setSortFieldTable] = useState('');
  const [sortOrderTable, setSortOrderTable] = useState('');
  const [toggleFilter, setToggleFilter] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);

  const [filter, setFilter] = useState({});
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;

  const [customer, setCustomer] = useState();

  console.log('datasource', dataSource, dataForm, customer);

  // useEffect(() => {
  //   if (customer) {
  //     setDataForm((prev) => ({
  //       ...prev,
  //       customerInv: customer,
  //     }));
  //     handleToggleFilter();
  //   }
  // }, [customer]);

  useEffect(() => {
    if (_.get(dataForm, 'customerInv')) {
      setCustomer(_.get(dataForm, 'customerInv'));
    } else {
      setCustomer();
    }
  }, [dataForm]);

  useEffect(() => {
    setPageTable(1);
    setSizeTable(10);
  }, [visible]);

  console.log(' ', filter, customer);

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        invoiceNo: _.get(filter, 'invoiceNo') || undefined,
        saleOrderNo: _.get(filter, 'saleOrderNo') || undefined,
        issueDateFrom: _.get(filter, 'issueDateFrom') ? moment(_.get(filter, 'issueDateFrom')).format('YYYY-MM-DD') : undefined,
        issueDateTo: _.get(filter, 'issueDateTo') ? moment(_.get(filter, 'issueDateTo')).format('YYYY-MM-DD') : undefined,
        team: _.get(filter, 'team') || undefined,
        issueBy: _.get(filter, 'issuedBy') || undefined,

        customerId: _.get(customer, 'customerSearch') || undefined,

        limit: sizeTable,
        page: pageTable,
        orderBy: sortFieldTable,
        orderType: sortOrderTable,
      };

      const response = await getListInvoice(body);
      setDataSource(response.data);
      setTotal(response.total);
    };
    if (_.get(customer, 'customerSearch')) {
      getAPI();
    } else {
      setDataForm([]);
      setTotal(0);
    }
  }, [visible, toggleFilter, customer]);

  const handleToggleFilter = () => {
    setToggleFilter((event) => !event);
    setPageTable(1);
  };

  const handleChangeTable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrderTable('desc');
      } else {
        setSortOrderTable('asc');
      }
    } else {
      setSortOrderTable('');
    }
    setSortFieldTable(field);
    setPageTable(pagination.current);
    setSizeTable(pagination.pageSize);
    setToggleFilter((event) => !event);
  };

  const handleChangeFilter = async (value, code) => {
    setFilter({ ...filter, [code]: value });
  };

  const handleCancel = () => {
    setVisble(false);
    setPageTable(1);
    setSizeTable(10);
    setFilter({});
  };

  const onClickSetInvo = (record) => {
    if (_.get(dataForm, 'invNo')) {
      Modal.confirm({
        className: 'customer-modal-confirm',
        centered: true,
        icon: null,
        title: (
          <>
            {intl.formatMessage({
              id: 'addInvoiceValidate1',
              defaultMessage: 'Item will be cleared',
            })}
            <br />
            {intl.formatMessage({
              id: 'addInvoiceValidate2',
              defaultMessage: 'Do you want to changes invoice?',
            })}
          </>
        ),
        okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
        cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
        okButtonProps: {
          style: {
            width: '96px',
          },
          type: 'primary',
        },
        cancelButtonProps: {
          style: {
            width: '96px',
          },
          type: 'danger',
        },
        async onOk() {
          onSetInvo(record);
        },
        onCancel() {},
      });
    } else {
      onSetInvo(record);
    }
  };

  const onSetInvo = async (record) => {
    let customerCode;

    const body = {
      page: 1,
      limit: 10,
      customerName: _.get(record, 'customerName'),
    };

    try {
      const response = await httpClient.post(`/v3/business-partner/manager/company/${comCode}/customer-informations/search-v2`, body);
      console.log('customerDataList', response);
      if (response.status == 200) {
        customerCode = _.get(response, 'data.customers[0].customerCode') || '-';
      }
    } catch (error) {
      return;
    }

    console.log('onClickTable', record, dataForm);
    setDataForm((prev) => ({
      ...record,
      customer: customerCode + ' ' + _.get(record, 'customerName'),
      customerInv: customer,
      statusCode: _.get(dataForm, 'statusCode'),
      statusColor: _.get(dataForm, 'statusColor'),
      statusName: _.get(dataForm, 'statusName'),
    }));
    handleCancel();
    onSetFieldInvNo(record.invNo);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalAddInvoiceTitleAddInvoice', defaultMessage: 'Add Invoice' })}
      visible={visible}
      onOk={() => setVisble(false)}
      onCancel={() => handleCancel()}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '600px' }}
      className="modalBrandAndModel"
      footer={[
        <Button_02 key="close" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCloseItem" defaultMessage="Close" />
        </Button_02>,
      ]}
    >
      <Row>
        <Col span={6} style={{ borderRight: '1px solid #e8e8e8' }}>
          <div style={{ height: '555px' }}>
            <div
              className="listItemTextHeader"
              style={{
                padding: '24px 16px 16px 0px',
                marginTop: '-5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '50%', marginLeft: '24px' }}>
                <FormattedMessage id="modalAddInvoiceTitleFilter" defaultMessage="Filter" />
              </span>
            </div>
            <div style={{ padding: '16px 16px 8px 0px', marginLeft: '15px' }}>
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelInvoiceNo" defaultMessage="Invoice No." />
                </div>
                <div>
                  <Input
                    allowClear={true}
                    onChange={(e) => handleChangeFilter(e.target.value, 'invoiceNo')}
                    value={_.get(filter, 'invoiceNo')}
                    placeholder={intl.formatMessage({
                      id: 'modalAddInvoicePHRefInvoiceNo',
                      defaultMessage: 'Enter Invoice No.',
                    })}
                  />
                </div>
              </div>

              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelSaleOrderNo" defaultMessage="Sale Order No." />
                </div>
                <div>
                  <Input
                    allowClear={true}
                    onChange={(e) => handleChangeFilter(e.target.value, 'saleOrderNo')}
                    value={_.get(filter, 'saleOrderNo')}
                    placeholder={intl.formatMessage({
                      id: 'modalAddInvoicePHRefSaleOrderNo',
                      defaultMessage: 'Enter Sale Order No.',
                    })}
                  />
                </div>
              </div>

              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelFromIssuedDate" defaultMessage="From Issued Date" />
                </div>
                <div>
                  <DatePicker
                    style={{ width: '100%' }}
                    allowClear={false}
                    format={'DD/MM/YYYY'}
                    onChange={(e) => handleChangeFilter(e, 'issueDateFrom')}
                    placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefFromIssuedDate', defaultMessage: 'Select Issued Date' })}
                  />
                </div>
              </div>

              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelToIssuedDate" defaultMessage="To Issued Date" />
                </div>
                <div>
                  <DatePicker
                    style={{ width: '100%' }}
                    allowClear={false}
                    format={'DD/MM/YYYY'}
                    onChange={(e) => handleChangeFilter(e, 'issueDateTo')}
                    placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefToIssuedDate', defaultMessage: 'Select Issued Date' })}
                  />
                </div>
              </div>

              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelTeam" defaultMessage="Team" />
                </div>
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => handleChangeFilter(value, 'team')}
                  filterOption={false}
                  value={_.get(filter, 'team')}
                  placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefTeam', defaultMessage: 'Select Team' })}
                  style={{ width: '100%' }}
                >
                  {_.map(_.get(initial, 'optionsTeam'), (item) => (
                    <Option value={item.org_id}>{item.name}</Option>
                  ))}
                </Select>
              </div>

              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelIssuedby" defaultMessage="Issued by" />
                </div>
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => handleChangeFilter(value, 'issuedBy')}
                  filterOption={false}
                  value={_.get(filter, 'issuedBy')}
                  placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefIssuedBy', defaultMessage: 'Select Issued by' })}
                  style={{ width: '100%' }}
                >
                  {_.map(_.get(initial, 'optionsIssueBy'), (item) => (
                    <Option value={item.memComId}>{item.fullname}</Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <div style={{ height: '50px' }}>
            <div style={{ padding: '0px 16px 10px 24px' }}>
              <Button_01
                style={{ margin: '0px', width: '100%' }}
                key="print"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleToggleFilter()}
              >
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_01>
            </div>
          </div>
        </Col>

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Col span={18}>
          <div
            className="listItemTextHeader"
            style={{
              padding: '24px 16px 16px 24px',
              marginTop: '-5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <FormattedMessage id="modalAddInvoiceTitleInvoice" defaultMessage="Invoice" />
            </span>

            <div></div>
          </div>
          <div
            style={{
              padding: '16px',
              overflowY: 'auto',
              height: '540px',
            }}
          >
            <div style={{ marginBottom: '16px' }}>
              <LoadmoreCustomerCreditNote
                setData={setCustomer}
                initialId={_.get(customer, 'customerSearch') || undefined}
                initialName={_.get(customer, 'customerName') || undefined}
                visible={visible}
                required={false}
              />
              {/* <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                <FormattedMessage id="modalAddInvoiceLabelCustomer" defaultMessage="Customer" />
              </div>
              <Select
                allowClear
                showSearch
                onChange={(value) => handleChangeFilter(value, 'customer')}
                filterOption={false}
                value={_.get(filter, 'customer')}
                placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefCustomer', defaultMessage: 'Select Customer' })}
                style={{ width: '45%' }}
              >
                {_.map(listType, (item) => (
                  <Option value={item.code}>{langValue === 'TH' ? item.lang.TH : item.lang.EN}</Option>
                ))}
              </Select> */}
            </div>
            <Table
              dataSource={customer ? dataSource : []}
              columns={columns}
              onChange={handleChangeTable}
              scroll={{ x: _.sumBy(columns, 'width') }}
              pagination={
                total
                  ? {
                      total: total,
                      current: pageTable,
                      showTotal: showTotal,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      locale: { items_per_page: '' },
                      pageSize: sizeTable,
                      showSizeChanger: true,
                    }
                  : false
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (!event.target.href) {
                      onClickSetInvo(record);
                    }
                  },
                };
              }}
            />

            {total > 0 ? (
              <div className="total-items-member">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${total || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}

            {/* <CustomTableComponent
              columns={columns}
              dataSource={dataSource}
              total={total}
              onRow={true}
              role={true}
              onChange={handleChangeTable}
              paginationPage={pageTable}
              paginationShow={true}
              paginationSize={sizeTable}
              scroll={{ x: _.sumBy(columns, 'width'), y: '400px' }}
              // 'calc(72vh - 222px)'
              // contentAction={contentAction}
            /> */}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

ModalAddInvoice.defaultProps = {};

export default ModalAddInvoice;
