import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../../components/v2/button_02';
import Button_01 from '../../../../../components/v2/button_01';
import LabeRequireForm from '../../../../../components/label-required-form';
import { getUser, updateMember } from '../../../../../controllers/project-new/member';
import { getTeamOrg } from '../../../../../controllers/team/team';
import _ from 'lodash';
const { Option } = Select;

const ModalProjectEditMemeber = (props) => {
  const intl = useIntl();
  const comId = localStorage.getItem('comId');
  const memComId = localStorage.getItem('memComId');

  const { visible, setVisible, onOk, onCancel, setTrigger, projectId, projectMemberId } = props;
  const { form } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;

  const [listUserData, setListUserData] = useState([]);
  const [listTeamData, setListTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState('user');

  useEffect(() => {
    const getUserData = async () => {
      const body = {
        user_id: memComId,
        like: [],
        equal: [],
        date: [],
        order: 'created_at ASC',
        limit: 1000,
        index_page: 1,
        com_id: comId,
      };

      try {
        setLoading(true);
        const listData = await getUser({ body });
        console.log(listData.data, 'iwiw ??');
        setListUserData(listData.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const getTeamData = async () => {
      try {
        setLoading(true);
        const listData = await getTeamOrg();
        console.log(listData.data, 'xoxo ??');

        const modifiedTeamData = [{ name: 'All Team', org_id: 0 }, ...listData.data];
        setListTeamData(modifiedTeamData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getUserData();
    getTeamData();
  }, []);

  const handleSave = () => {
    validateFields()
      .then(async (values) => {
        let selectedComId = null;

        if (selectedType === 'user') {
          // Find comId for the selected user
          const selectedUser = listUserData.find((user) => user.mem_id === values.mem_or_team_id);
          selectedComId = selectedUser?.com_id || null;
        } else if (selectedType === 'team') {
          // Find comId for the selected team
          const selectedTeam = listTeamData.find((team) => team.org_id === values.mem_or_team_id);
          selectedComId = selectedTeam?.com_id || null;
        }

        const body = {
          project_member_id: projectMemberId,
          org_id: selectedType === 'team' ? `${values.mem_or_team_id}` : null,
          memid: selectedType === 'user' ? `${values.mem_or_team_id}` : null,
          project_id: projectId,
          member_type: values.member_type,
          priority: values.priority,
          comId: selectedComId,
        };
        console.log('body', body);
        const response = await updateMember({ body, projectId });
        console.log('Update Member', response);

        if (_.get(response, 'status') === 200) {
          console.log('Member updated successfully', response);
          resetFields();
          setVisible(false);
          setTrigger((prev) => !prev);
          onOk();
        } else {
          console.log('Failed to update member', response);
        }
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
    form.resetFields();
  };

  return (
    <div>
      <Modal
        title="Edit Member"
        visible={visible}
        onOk={handleSave}
        onCancel={onCancel}
        footer={[
          <Button_02 key="back" btnsize="wd_df" onClick={() => handleCancel()}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()} style={{ margin: 'unset' }}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]}
      >
        <Form layout="vertical" className="deliveryForm">
          <Row>
            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'projectDetailSettingAddMemberUserOrTeam', defaultMessage: 'User or Team' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('member_type', {
                  initialValue: 'user',
                })(
                  <Select placeholder="Employee or Team Type" className="memberExpenseFormExpenseType" onChange={handleTypeChange}>
                    <Option key="user" value="user">
                      User
                    </Option>
                    <Option key="team" value="team">
                      Team
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label={<LabeRequireForm text="" req={false} />}>
                {getFieldDecorator('mem_or_team_id', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select an item',
                    },
                  ],
                })(
                  <Select placeholder="Select Item" className="memberExpenseFormExpenseItem">
                    {selectedType === 'user' &&
                      listUserData.map((item) => (
                        <Option key={item.mem_id} value={item.mem_id}>
                          {item.fullname}
                        </Option>
                      ))}
                    {selectedType === 'team' &&
                      listTeamData.map((item) => (
                        <Option key={item.org_id} value={item.org_id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({
                  id: 'projectDetailSettingAddMemberEmployeeOrTeamType',
                  defaultMessage: 'Employee or Team Type',
                })}
                req={false}
              />
            }
          >
            <Input placeholder="Employee or Team Type" disabled />
          </Form.Item>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'projectDetailSettingAddMemberPhone', defaultMessage: 'Phone' })}
                req={false}
              />
            }
          >
            <Input placeholder="Phone" disabled />
          </Form.Item>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'projectDetailSettingAddMemberEmail', defaultMessage: 'Email' })}
                req={false}
              />
            }
          >
            <Input placeholder="Email" disabled />
          </Form.Item>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'projectDetailSettingAddMemberPriority', defaultMessage: 'Priority' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('priority', {
              rules: [
                { required: true, message: 'Please enter a priority' },
                {
                  validator: (rule, value, callback) => {
                    if (value !== undefined && value !== null && isNaN(value)) {
                      callback('Please enter a valid number');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<InputNumber className="custom-input-number" placeholder="Enter Priority (1-999)" min={1} max={999} />)}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Form.create()(ModalProjectEditMemeber);
