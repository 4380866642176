import React from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { useIntl, FormattedMessage } from 'react-intl';
import ReportInvoiceReportActionColumns from './action-columns';
import { Input, Form, Select, DatePicker, Row, Col } from 'antd';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportInvoiceReportAction = (props) => {
  const intl = useIntl();
  const {
    search,
    setSearch,
    setPage,
    setIsFetch,
    onFilterOrder,
    actionColumns,
    refTypeList,
    billingList,
    invoiceList,
    teamList,
    memberList,
  } = props;

  const handleApply = () => {
    setIsFetch((event) => !event);
    setPage(1);
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({
      ...prev,
      invNo: undefined,
      customer: undefined,
      invStatus: [],
      soNo: undefined,
      blNo: undefined,
      blStatus: [],
      issuedBy: undefined,
      team: [],
      issuedDate: [],
    }));
    setPage(1);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInvoiceReportColumnsINVNo', defaultMessage: 'INV No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInvoiceReportPHRefINVNo', defaultMessage: 'Enter INV No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'invNo')}
                value={_.get(search, 'invNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInvoiceReportColumnsCustomer', defaultMessage: 'Customer' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'reportInvoiceReportPHRefCustomer',
                  defaultMessage: 'Enter Customer Code or Customer Name',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'customer')}
                value={_.get(search, 'customer') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'menuReportInvoiceReporINVStatus', defaultMessage: 'INV Status' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onChange={(value) => onFilterOrder(value, 'invStatus')}
                filterOption={false}
                value={_.get(search, 'invStatus') || undefined}
                placeholder={intl.formatMessage({ id: 'reportInvoiceReportPHRefINVStatus', defaultMessage: 'Select INV Status' })}
                style={{ width: '100%' }}
              >
                {_.map(invoiceList, (item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInvoiceReportColumnsSONo', defaultMessage: 'SO No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInvoiceReportPHRefSONo', defaultMessage: 'Enter SO No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'soNo')}
                value={_.get(search, 'soNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportInvoiceReportColumnsBLNo', defaultMessage: 'BL No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportInvoiceReportPHRefBLNo', defaultMessage: 'Enter BL No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'blNo')}
                value={_.get(search, 'blNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'menuReportInvoiceReporBLStatus', defaultMessage: 'BL Status' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onChange={(value) => onFilterOrder(value, 'blStatus')}
                filterOption={false}
                value={_.get(search, 'blStatus') || undefined}
                placeholder={intl.formatMessage({ id: 'reportInvoiceReportPHRefBLStatus', defaultMessage: 'Select BL Status' })}
                style={{ width: '100%' }}
              >
                {_.map(billingList, (item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'menuReportInvoiceReporIssuedby', defaultMessage: 'Issued by' })}
                  req={false}
                />
              }
            >
              <Select
                showSearch
                allowClear={true}
                mode="multiple"
                onChange={(value) => onFilterOrder(value, 'issuedBy')}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(search, 'issuedBy') || undefined}
                placeholder={intl.formatMessage({ id: 'reportInvoiceReportPHRefIssuedby', defaultMessage: 'Select Issued by' })}
                style={{ width: '100%' }}
              >
                {_.map(memberList, (item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'menuReportInvoiceReporTeam', defaultMessage: 'Team' })} req={false} />
              }
            >
              <Select
                allowClear={true}
                onChange={(value) => onFilterOrder(value, 'team')}
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(search, 'team') || undefined}
                placeholder={intl.formatMessage({ id: 'reportInvoiceReportPHRefTeam', defaultMessage: 'Select Team' })}
                style={{ width: '100%' }}
              >
                {_.map(teamList, (item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'menuReportInvoiceReporIssuedDate', defaultMessage: 'Issued Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                allowClear={true}
                placeholder={[
                  intl.formatMessage({ id: 'reportReconcilePHExpiredDateFrom', defaultMessage: 'Select From Date' }),
                  intl.formatMessage({ id: 'reportReconcilePHExpiredDateTo', defaultMessage: 'Select To Date' }),
                ]}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => onFilterOrder(value, 'issuedDate')}
                style={{ width: '100%' }}
                format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                value={_.get(search, 'issuedDate')}
              />
            </Form.Item>
          </Col>

          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateFrom', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateTo', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'searchDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'searchDate')}
            />
            <ReportInvoiceReportActionColumns actionColumns={actionColumns} />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ReportInvoiceReportAction;
