import React from 'react';
import { Divider, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import Button_01 from '../../../../../components/v2/button_01';


const ListItemFilter = (props) => {
  const { onChange, searchSOValue, handleSubmitSearch} = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div style={{ padding: '16px 16px 16px 0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalAddSelectPricingSO" defaultMessage="Sale Order No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange('saleOrderNo', e.target.value)}
                value={_.get(searchSOValue, 'saleOrderNo')}
                placeholder={intl.formatMessage({
                  id: 'modalAddSelectPricingPHSO',
                  defaultMessage: 'Enter Sale Order No.',
                })}
              />
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalAddSelectPricingIssuedBy" defaultMessage="Issued by" />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange('issuedName', e.target.value)}
                value={_.get(searchSOValue, 'issuedName')}
                placeholder={intl.formatMessage({
                  id: 'modalAddSelectPricingPHIssuedBy',
                  defaultMessage: 'Enter Issued by',
                })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalAddSelectPricingFromSODate" defaultMessage="From SO Date" />
            </div>
            <div>
              <DatePicker
                format={dateFormat}
                style={{ width: '100%' }}
                value={_.get(searchSOValue, 'startDate') ? moment(_.get(searchSOValue, 'startDate')) : ''}
                onChange={(e) => onChange('startDate', e ? e.format("YYYY-MM-DD") : '')}
                placeholder={intl.formatMessage({ id: 'modalAddSelectPricingPHFromSODate', defaultMessage: 'Select SO Date' })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalAddSelectPricingToSODate" defaultMessage="To SO Date" />
            </div>
            <div>
              <DatePicker
                format={dateFormat}
                value={_.get(searchSOValue, 'endDate') ? moment(_.get(searchSOValue, 'endDate')) : ''}
                style={{ width: '100%' }}
                onChange={(e) => onChange('endDate', e ? e.format("YYYY-MM-DD") : '')}
                placeholder={intl.formatMessage({ id: 'modalAddSelectPricingPHToSODate', defaultMessage: 'Select SO Date' })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalAddSelectPricingQuotationNo" defaultMessage="Quotation No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange('quotationNo', e.target.value)}
                value={_.get(searchSOValue, 'quotationNo')}
                placeholder={intl.formatMessage({
                  id: 'modalAddSelectPricingPHQuotationNo',
                  defaultMessage: 'Enter Quotation No.',
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px 16px 16px 0px' }}>
        <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={handleSubmitSearch}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button_01>
      </div>
    </div>
  );
};

export default ListItemFilter;
