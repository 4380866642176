import { Divider, Form, Icon, Input, InputNumber, Modal, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Button02 from '../../../../components/v2/button_02';
import Button01 from '../../../../components/v2/button_02';
import ModalSelectItemProjectGas from '../../../../components/modal-select-item-project';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import { PageSettings } from '../../../../config/page-settings';
import { handleKeyDownNumber } from '../../../../component-function/fnc-number';
import ModalSelectItemPayDeduct from '../../../../components/modal-select-item-pay-deduct';
import { getItemBasePay } from '../../../../controllers/base-pay/basepay';
import { editReimbursement, saveReimbursement } from '../../../../controllers/project-new/project-setting';
import { errorNotification, successNotification } from '../../../../components/v2/notification';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ModalReimbursement = (props) => {
  const intl = useIntl();
  const { visible, setVisibel, setTrigger, record, setRecord, id } = props;
  const { form } = props;
  const { getFieldDecorator, resetFields, getFieldValue, setFieldsValue } = form;

  const [open, setOpen] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [listItem, setListItem] = useState([]);

  console.log('ModalReimbursement record', record);

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        limit: 10000,
        page: 1,
        // status: 'active',
        noType: 'pay',
      };

      const response = await getItemBasePay(body);

      if (_.get(response, 'data.status.code') === 200) {
        const data = _.get(response, 'data.data.basePayList');
        const filterItemAction = _.filter(data, (item) => item.statusCode === 'active');
        setListItem(filterItemAction);
      }
    };
    getAPI();
  }, [triggerModal]);

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      const objReim = _.chain(listItem)
        .filter((item) => item.basePayId === values.reimbursement)
        .get('[0]')
        .value();

      if (!objReim) {
        errorNotification(intl.formatMessage({ id: 'projectSettingErrorItemInactive', defaultMessage: 'Item Inactive' }));
        return;
      }

      const body =
        _.get(record, 'action') === 'edit'
          ? {
              proJectId: id,
              reimbusement: objReim,
              limit: _.toNumber(values.limit),
              remark: values.remark ? values.remark : '',
              _id: _.get(record, '_id'),
            }
          : {
              proJectId: id,
              reimbusement: objReim,
              limit: _.toNumber(values.limit),
              remark: values.remark ? values.remark : '',
            };

      if (!objReim) {
        return;
      }
      console.log('handleSave', values, record, body, objReim);

      try {
        const response = _.get(record, 'action') === 'edit' ? await editReimbursement(body) : await saveReimbursement(body);

        console.log('saveReimbursement', response);

        if (_.get(response, 'status.code') === 200) {
          successNotification(_.get(response, 'status.message'));
          handleCancel();
        } else {
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.data.message);
      }

      setTrigger((event) => !event);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setRecord({});
    setVisibel(false);
  };

  let option = listItem.map((item, index) => (
    <Option key={item.basePayId} value={item.basePayId}>
      {item.basePayName}
    </Option>
  ));

  const onSelectItem = (itemId) => {
    setFieldsValue({
      ['reimbursement']: itemId,
    });
  };

  const checkItem = () => {
    const reimbursement = getFieldValue('reimbursement');
    if (_.get(record, 'reimbusement.basePayName') === reimbursement) {
      // สำหรับ edit ที่เป็น inactive ถ้ากลับมาไม่ต้องเคลียร์ค่า
    } else if (reimbursement) {
      const filterItem = _.filter(listItem, (item) => item.basePayId === reimbursement);
      if (_.size(filterItem) === 0) {
        setFieldsValue({
          ['reimbursement']: undefined,
        });
      }
    }
  };

  const onChangeLimit = (value) => {
    console.log('onChangeLimit', value);
    if (_.isNumber(_.toNumber(value))) {
      const number = Math.floor(_.toNumber(value));
      setTimeout(() => {
        setFieldsValue({
          limit: number,
        });
      }, 0);
    } else {
      setFieldsValue({
        limit: undefined,
      });
    }
  };

  const validateLimit = (rule, value, callback) => {
    const limit = _.toNumber(value);
    if (limit === 0) {
      callback(
        intl.formatMessage({
          id: 'projectSettingReimbursementValidatelimitZero',
          defaultMessage: 'Please enter a number greater than 0.',
        })
      );
    } else if (limit > 10000) {
      callback(
        intl.formatMessage({
          id: 'projectSettingReimbursementValidatelimitMax',
          defaultMessage: 'The number exceeds limitation.',
        })
      );
    } else {
      callback();
    }
  };

  return (
    <div>
      <Modal
        visible={visible}
        onOk={handleSave}
        onCancel={handleCancel}
        className="button-modal"
        title={
          _.get(record, 'action') === 'edit' ? (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557', margin: '0px' }}>
              <FormattedMessage id="projectDetailSettingModalEditReimbursement2" defaultMessage="Edit Reimbursement" />
            </Title>
          ) : (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557', margin: '0px' }}>
              <FormattedMessage id="projectDetailSettingModalAddReimbursement2" defaultMessage="Add Reimbursement" />
            </Title>
          )
        }
        footer={[
          <Button02
            style={{ margin: '0px 0px 0px 10px', fontSize: '13px' }}
            className="btn-style-new"
            key="back"
            fontsize="sm"
            btnsize="wd_df"
            onClick={handleCancel}
          >
            <FormattedMessage id="btnCloseBasePay" defaultMessage="Close" />
          </Button02>,
          <Button01
            style={{ fontSize: '13px' }}
            className="btn-style-new2"
            key="submit"
            fontsize="sm"
            btnsize="wd_df"
            type="primary"
            onClick={handleSave}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'projectDetailSettingLableReimbursement2', defaultMessage: 'Reimbursement' })}
                req={true}
              />
            }
          >
            {getFieldDecorator('reimbursement', {
              initialValue: _.size(_.filter(listItem, (item) => item.basePayId === _.get(record, 'reimbusement.basePayId')))
                ? _.get(record, 'reimbusement.basePayId')
                : _.get(record, 'reimbusement.basePayName'),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'projectSettingReimbursementValidateReimbursement',
                    defaultMessage: 'Please Select Reimbursement',
                  }),
                },
              ],
            })(
              <Select
                placeholder={intl.formatMessage({
                  id: 'projectSettingReimbursementPlaceReimbursement',
                  defaultMessage: 'Select Reimbursement',
                })}
                className="user-management-role-select"
                style={{ width: '100%' }}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <PageSettings.Consumer>
                      {({ checkPermissionAction }) => (
                        <>
                          {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A5') ? (
                            <div>
                              <Divider style={{ margin: '4px 0' }} />
                              <div
                                style={{ padding: '4px 8px', cursor: 'pointer' }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => {
                                  setOpen(true);
                                }}
                              >
                                <Icon type="plus" /> <FormattedMessage id="projectSettingReimbursementAddItem" defaultMessage="Add Item" />
                              </div>
                            </div>
                          ) : null}
                        </>
                      )}
                    </PageSettings.Consumer>
                  </div>
                )}
              >
                {option}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'projectSettingLabLimit', defaultMessage: 'Limit' })} req={true} />}
          >
            {getFieldDecorator('limit', {
              initialValue: _.get(record, 'limit', 0),
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'projectSettingReimbursementValidatelimit',
                    defaultMessage: 'Please Enter Limit',
                  }),
                },
                {
                  validator: validateLimit,
                },
              ],
            })(
              <Input
                className="disabled-handle-count"
                placeholder={intl.formatMessage({ id: 'projectSettingReimbursementPlaceLimit', defaultMessage: 'Enter Limit' })}
                onKeyDown={handleKeyDownNumber}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            )}
          </Form.Item>

          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'projectSettingLabRemark', defaultMessage: 'Remark' })} req={false} />}
          >
            {getFieldDecorator('remark', {
              initialValue: _.get(record, 'remark'),
            })(
              <TextArea
                rows={3}
                placeholder={intl.formatMessage({ id: 'projectSettingReimbursementPlaceRemark', defaultMessage: 'Enter Remark' })}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>

      <ModalSelectItemPayDeduct
        visible={open}
        setVisble={setOpen}
        setTrigger={setTriggerModal}
        // menuCode={'reimbursement'}
        menuCode={'basePay'}
        onSelectItem={onSelectItem}
        onCheckItem={checkItem}
      />
    </div>
  );
};

const ModalReimbursementForm = Form.create({
  name: 'global_state',
})(ModalReimbursement);

export default ModalReimbursementForm;
