import React, { useContext, useState, useRef } from 'react';
import { Table, Popover, Button, Modal } from 'antd';
import { CreditNoteContext } from './credit-note-context';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { PageSettings } from '../../config/page-settings';
import CreditNoteAction from './action/action';
import { deleteCn } from '../../controllers/credit-note/credit-note';
import { errorNotification, successNotification } from '../../components/v2/notification';

const CreditNoteTab = () => {
  const props = useContext(CreditNoteContext);
  const ref = useRef();
  const intl = useIntl();
  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const { checkPermissionAction } = useContext(PageSettings);

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteCn(id);
        if (response.status === 200) {
          successNotification(intl.formatMessage({ id: `creditNoteDeletedSuccess`, defaultMessage: 'Credit Note Deleted Successfully' }));

          props.setTrigger((cur) => !cur);
        } else {
          errorNotification(intl.formatMessage({ id: `creditNoteDeletedUnSuccess`, defaultMessage: 'Credit Note Deleted UnSuccessfully' }));
        }
      },
      onCancel() {},
    });
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    console.log('record', record);
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              {
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => props.handleOpenModal(record, 'view')}>
                    <FormattedMessage id="btnView" defaultMessage="View" />
                  </Button>
                </div>
              }
              {record &&
              (record.statusCode === 'draft' || record.statusCode === 'torevise') &&
              checkPermissionAction('P87PG1C1', 'P87PG1C1A3') ? (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => props.handleOpenModal(record, 'edit')}>
                    <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
                  </Button>
                </div>
              ) : null}
              {record && record.statusCode === 'draft' && checkPermissionAction('P87PG1C1', 'P87PG1C1A5') ? (
                <div>
                  <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record.cnId)}>
                    <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                  </Button>
                </div>
              ) : null}
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  const ignoreColumn = ['blNo', 'blDate'];

  return (
    <div style={{ height: 'calc(100vh - 190px)', overflowY: 'auto' }}>
      <CreditNoteAction />
      <div style={{ padding: '0px 24px' }} onMouseLeave={handleMouseLeave}>
        <Table
          // className='po-table'
          columns={
            props.keyTabMonitor === 'approved'
              ? props.newDataColumns
              : _.filter(props.newDataColumns, (column) => !ignoreColumn.includes(column.key))
          }
          dataSource={props.creditNoteList}
          scroll={{ x: _.sumBy(props.newDataColumns, 'width') }}
          onChange={props.handleChange}
          loading={props.loading}
          pagination={{
            total: props.total,
            current: props.paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.href) {
                  const { x, y } = ref.current.getBoundingClientRect();
                  setVisiblePopoverTable(true);
                  setOffsetPopover([event.pageX - x, event.pageY - y]);
                  setRecordPopover(record);
                }
              },
            };
          }}
        />
        {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
        {props.total > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${props.total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CreditNoteTab;
