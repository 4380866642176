import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Divider, Dropdown, Icon, Menu } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ChartLineBar from '../../component/chart-line-bar';

const OccRateArr = (prop) => {
  // const { visibleDatasets, setVisibleDatasets,chartData } = prop;

  const chartData = [
    {
      name: 'Jan 25',
      bar: 60,
      line: 1700,
    },
    {
      name: 'Feb 25',
      bar: 65,
      line: 1750,
    },
    {
      name: 'Mar 25',
      bar: 70,
      line: 1800,
    },
    {
      name: 'Apr 25',
      bar: 75,
      line: 1850,
    },
    {
      name: 'May 25',
      bar: 80,
      line: 1900,
    },
    {
      name: 'Jun 25',
      bar: 85,
      line: 2000,
    },
    {
      name: 'Jul 25',
      bar: 90,
      line: 2100,
    },
    {
      name: 'Aug 25',
      bar: 95,
      line: 2200,
    },
    {
      name: 'Sep 25',
      bar: 100,
      line: 2300,
    },
    {
      name: 'Oct 25',
      bar: 105,
      line: 2400,
    },
    {
      name: 'Nov 25',
      bar: 110,
      line: 2500,
    },
    {
      name: 'Dec 25',
      bar: 115,
      line: 2600,
    },
  ];
  

  // const [visibleDatasets, setVisibleDatasets] = useState({
  //   bar: true,
  //   line: true,
  // });

  // const [tempVisibleDatasets, setTempVisibleDatasets] = useState({
  //   ...visibleDatasets,
  // });

  // const [dropdownVisible, setDropdownVisible] = useState(false);

  // const toggleDataset = (key) => {
  //   setTempVisibleDatasets((prev) => ({ ...prev, [key]: !prev[key] }));
  // };

  // const confirmChanges = () => {
  //   setVisibleDatasets({ ...tempVisibleDatasets });
  //   setDropdownVisible(false);
  // };

  // const resetChanges = () => {
  //   setTempVisibleDatasets({ bar: true, line: true });
  //   setVisibleDatasets({ bar: true, line: true });
  //   setDropdownVisible(false);
  // };

  // const menuColumn = (
  //   <Menu style={{ width: '200px', paddingTop: '10px', paddingBottom: '0px' }}>
  //     <Menu.Item key="bar" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
  //       <Checkbox checked={tempVisibleDatasets.bar} onChange={() => toggleDataset('bar')}>
  //         OCC Rate
  //       </Checkbox>
  //     </Menu.Item>
  //     <Menu.Item key="line" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
  //       <Checkbox checked={tempVisibleDatasets.line} onChange={() => toggleDataset('line')}>
  //         ARR
  //       </Checkbox>
  //     </Menu.Item>

  //     <Divider style={{ margin: '10px 0 0 0' }} />

  //     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  //       <Button
  //         onClick={confirmChanges}
  //         style={{
  //           fontSize: '14px',
  //           border: 'none',
  //           width: '48%',
  //           height: '37px',
  //         }}
  //       >
  //         <FormattedMessage id="btnOK" defaultMessage="OK" />
  //       </Button>
  //       <Button
  //         onClick={resetChanges}
  //         style={{
  //           fontSize: '14px',
  //           border: 'none',
  //           width: '48%',
  //           height: '37px',
  //         }}
  //       >
  //         <FormattedMessage id="btnReset" defaultMessage="Reset" />
  //       </Button>
  //     </div>
  //   </Menu>
  // );

  // const filteredData = chartData.map((item) => ({
  //   name: item.name,
  //   ...(visibleDatasets.bar && { bar: item.bar }),
  //   ...(visibleDatasets.line && { line: item.line }),
  // }));

  const chartConfig = {
    barLegend: 'OCC Rate - All Market',
    lineLegend: 'ARR - All Market',
    barColor: '#53C139',
    lineColor: '#134A0D',
    lineDash: '0 0',
    lineLegendType: 'plainline',
    domain: [0, 100],
    yLeft: 'OCC Rate (%)',
    yRight: 'ARR (THB/sq.m)',
  };

  return <ChartLineBar data={chartData} config={chartConfig} />;
};

export default OccRateArr;
