import _ from 'lodash';
import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
} from 'recharts';
import './index.css';
import { Col, Row } from 'antd';

const NetAreaChart = (prop) => {
  const { chartData } = prop;
  // const data = [
  //   {
  //     name: 'Page A',
  //     a: 4000,
  //     b: 2400,
  //     c: 2400,
  //     d: 1000,
  //   },
  //   {
  //     name: 'Page B',
  //     a: 3000,
  //     b: 1398,
  //     c: 2210,
  //     d: 1500,
  //   },
  //   {
  //     name: 'Page C',
  //     a: 2000,
  //     b: 9800,
  //     c: 2290,
  //     d: 2000,
  //   },
  //   {
  //     name: 'Page D',
  //     a: 2780,
  //     b: 3908,
  //     c: 2000,
  //     d: 2500,
  //   },
  //   {
  //     name: 'Page E',
  //     a: 1890,
  //     b: 4800,
  //     c: 2181,
  //     d: 3000,
  //   },
  //   {
  //     name: 'Page F',
  //     a: 2390,
  //     b: 3800,
  //     c: 2500,
  //     d: 3500,
  //   },
  //   {
  //     name: 'Page G',
  //     a: 3490,
  //     b: 4300,
  //     c: 2100,
  //     d: 4000,
  //   },
  // ];

  const getColor = (index) => {
    const colors = ['#53C139', '#FFB100', '#6490CF', '#E37769'];
    return colors[index % colors.length];
  };



  // const dataBar = _.get(chartData, '[0].bar');
  // const dataScatter = _.get(chartData, '[0].scatter');

  // const chartDataKeyA = _.keys(_.omit(dataBar, 'name'));
  const chartDataKeyB = _.keys(_.omit(_.get(chartData, '[0]'), 'name'));
  const chartDataKey = _.keys(_.omit(_.get(chartData, '[0]'), 'name'));
  const heightGraph = _.size(chartDataKey) > 4 ? 550 : 350

  console.log('keeeyy', chartData);

  const renderLegendIcon = (type, color) => {
    switch (type) {
      case 'square':
        return <div style={{ width: 10, height: 10, backgroundColor: color }} />;
      case 'plainline':
        return <div style={{ width: 20, height: 2, backgroundColor: color }} />;
      case 'line':
        return (
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: 7,
                height: 2,
                backgroundColor: color,
              }}
            />
            <div
              style={{
                width: 6,
                height: 2,
              }}
            />
            <div
              style={{
                width: 7,
                height: 2,
                backgroundColor: color,
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const customLegend = (props) => {
    const { payload } = props;
    console.log('Legend', payload);
    return (
      <>
        <ul className="custom-list">
          {payload.map((entry, index) => {
            // return entry.value !== 'scatter' ? (
              return _.includes(entry.value, 'Net Area (A)') ? (
              <li key={`item-${index}`}>
                <Row type="flex" justify="center">
                  <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    {renderLegendIcon(entry.type, entry.color)} <span style={{ marginLeft: '10px' }}>{entry.value}</span>
                  </Col>
                  <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    {renderLegendIcon(entry.type, '#1E3557')} <span style={{ marginLeft: '10px' }}>Net Area (B) - {entry.value.substring(14)}</span>
                  </Col>
                </Row>
              </li>
            ) : null;
          })}
        </ul>
        <ul className="custom-list">
          {payload.map((entry, index) => {
            return _.includes(entry.value, 'Net Area (A)') ? (
              <li key={`item-${index}`}>
                <Row type="flex" justify="center">
                  <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Average {entry.value}</span>
                  </Col>
                  <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Average Net Area (B) - {entry.value.substring(14)}</span>
                  </Col>
                </Row>
              </li>
            ) : null;
          })}
        </ul>
      </>
    );
  };

  return (
    <ResponsiveContainer style={{ marginTop: '20px', padding: '5px' }} width="100%" height={heightGraph}>
      {/* <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      > */}
      <ComposedChart data={chartData}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend content={customLegend} />
        {chartDataKey.map((key, index) => (
          <>
            {_.includes(key, 'Net Area (A)') ? (
              <Bar
                legendType="square"
                key={key}
                dataKey={key}
                fill={getColor(index)}
                activeBar={<Rectangle fill={getColor(index)} stroke={getColor(index)} />}
                barSize={10}
              />
            ) : (
              <Scatter legendType="square" key={key} dataKey={key} shape="square" fill="#1D3557"  />
            )}
          </>
        ))}
      </ComposedChart>
      {/* <Bar dataKey="a" label='ตลาด1' fill="#53C139" activeBar={<Rectangle fill="#53C139" stroke="#53C139" />} barSize={10} />
        <Bar dataKey="b" label='ตลาด2' fill="#FFB100" activeBar={<Rectangle fill="#FFB100" stroke="#FFB100" />} barSize={10} />
        <Bar dataKey="c" label='ตลาด3' fill="#6490CF" activeBar={<Rectangle fill="#6490CF" stroke="#6490CF" />} barSize={10} />
        <Bar dataKey="d" label='ตลาด4' fill="#E37769" activeBar={<Rectangle fill="#E37769" stroke="#E37769" />} barSize={10} /> */}
    </ResponsiveContainer>
  );
};

export default NetAreaChart;
