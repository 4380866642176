import { Modal, Tabs, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageSettings } from '../../../../config/page-settings';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import _ from 'lodash';
import { CnPDFDownloader, deleteCn, getCreditNoteDetail, updateStatusCreditNote } from '../../../../controllers/credit-note/credit-note';
import CreditNoteDetail from './detail';
import ReferenceTab from '../../../../components/reference';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import ModalCnStatusForm from '../status';
import ModalPDFViewver from '../../../../component-function/pdf-viewver';
import { UrlPDF } from '../../../../component-function/generator-pdf';

const { TabPane } = Tabs;

const CreditNoteView = (props) => {
  const { visible, setVisible, setTrigger, defaultData, initial, keyTabMonitor, nextStatus, actionSaveToStatus, handleEdit } = props;

  const intl = useIntl();
  const { checkPermissionAction } = useContext(PageSettings);

  const [tabChange, setTabChange] = useState('1');
  const [dataForm, setDataForm] = useState({});
  const [selectItem, setSelectItem] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);

  const [refItem, setRefItem] = useState([]);
  const [type, setType] = useState();

  const [visibleStatus, setVisibleStatus] = useState(false);

  const [loadingButton, setLoaddingButton] = useState(false);

    const [urlPrint, setUrlPrint] = useState();
    const [visiblePrint, setVisiblePrint] = useState(false);
  

  console.log('dataInfo', dataForm, initial);
  console.log('defaultDataView', defaultData, dataForm, selectItem, attachmentData);

  useEffect(() => {
    const getDetail = async () => {
      const response = await getCreditNoteDetail(_.get(defaultData, 'cnId'));

      const data = _.get(response, 'data');
      console.log('getCreditNote', response);

      setDataForm(data);
      setSelectItem(_.get(response, 'selectItem'));
      setAttachmentData(_.get(response, 'attachmentData'));

      setRefItem([]);
    };

    if (visible && _.get(defaultData, 'action') !== 'create') {
      getDetail();
    }
  }, [visible]);

  const handleTabChange = (tab) => {
    setTabChange(tab);
  };

  const handleCancel = () => {
    setVisible(false);
    setDataForm({});
    setSelectItem([]);
    setTabChange('1');
    setAttachmentData([]);
  };

  const operations = _.get(defaultData, 'action') !== 'create' && (
    <div style={{ textAlign: 'center', display: 'flex', padding: '10px' }}>
      <Tag className="tag-center-style" color={_.get(dataForm, 'statusColor') || ''}>
        {_.get(dataForm, 'statusName') || ''}
      </Tag>
    </div>
  );

  const handleSave = async (type) => {
    setType(type);
    setVisibleStatus(true);
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteCn(id);
        if (response.status === 200) {
          successNotification(intl.formatMessage({ id: `creditNoteDeletedSuccess`, defaultMessage: 'Credit Note Deleted Successfully' }));
          props.setTrigger((cur) => !cur);
        } else {
          errorNotification(intl.formatMessage({ id: `creditNoteDeletedUnSuccess`, defaultMessage: 'Credit Note Deleted UnSuccessfully' }));
        }
      },
      onCancel() {},
    });
  };

  const handlePrint = async () => {
    // const formateData = fomatePDF(editDefault);
    const body = {
      data: dataForm,
      selectItem: selectItem,
    }
    const response = await CnPDFDownloader(body);
    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      if (url) {
        setUrlPrint(url);
        setVisiblePrint(true);
      }
    }
  };

  const handlePrintA5 = async () => {
    // const response = await POPDFDownloaderA5(_.get(editDefault, 'poNo'), formateData);
    // if (_.get(response, 'status') === 200) {
    //   const url = await UrlPDF(response);
    //   if (url) {
    //     setUrlPrint(url);
    //     setVisiblePrint(true);
    //   }
    // }
  };

  const handleButton = () => {
    return [
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div>
        {keyTabMonitor !== 'draft' && (
          <Button01 style={{ margin: 'unset' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
            <FormattedMessage id="btnPrint" defaultMessage="Print" />
          </Button01>
        )}
        </div>
        <div>{renderButtons()}</div>
      </div>,
    ];
  };

  const renderButtons = () => {
    const buttons = [];

    buttons.push(
      <Button02 style={{ margin: '0px 5px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button02>
    );

    if (_.includes(nextStatus, 'canceled') && checkPermissionAction('P87PG1C1', 'P87PG1C1A4')) {
      buttons.push(
        <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('canceled')}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button01>
      );
    }

    if (_.includes(nextStatus, 'torevise') && checkPermissionAction('P87PG1C1', 'P87PG1C1A7')) {
      buttons.push(
        <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('torevise')}>
          <FormattedMessage id="btnToRevise" defaultMessage="To Revise" />
        </Button01>
      );
    }

    if (_.includes(nextStatus, 'deleted') && checkPermissionAction('P87PG1C1', 'P87PG1C1A5')) {
      buttons.push(
        <Button01
          style={{ margin: '0px 5px' }}
          key="delete"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleDelete(_.get(dataForm, 'cnId'))}
        >
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </Button01>
      );
    }

    if ((keyTabMonitor === 'torevise' || keyTabMonitor === 'draft') && checkPermissionAction('P87PG1C1', 'P87PG1C1A3')) {
      buttons.push(
        <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleEdit()}>
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button01>
      );
    }

    if (_.includes(nextStatus, 'waiting') && checkPermissionAction('P87PG1C1', 'P87PG1C1A8')) {
      buttons.push(
        <Button01
          style={{ margin: '0px 5px', width: 'auto' }}
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSaveWaiting('waiting')}
          disabled={loadingButton ? true : false}
        >
          <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
        </Button01>
      );
    }
    if (_.includes(nextStatus, 'approved') && checkPermissionAction('P87PG1C1', 'P87PG1C1A9')) {
      buttons.push(
        <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('approved')}>
          <FormattedMessage id="btnApprove" defaultMessage="Approve" />
        </Button01>
      );
    }
    if (_.includes(nextStatus, 'rejected') && checkPermissionAction('P87PG1C1', 'P87PG1C1A10')) {
      buttons.push(
        <Button01 style={{ margin: '0px 5px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('rejected')}>
          <FormattedMessage id="btnReject" defaultMessage="Reject" />
        </Button01>
      );
    }

    return buttons;
  };

  const handleSaveWaiting = async (type) => {
    setLoaddingButton(true);
    const body = {
      statusCode: type,
      creditNoteId: _.get(dataForm, 'cnId'),
    };
    const response = await updateStatusCreditNote(body);
    if (_.get(response, 'data.status') === 200) {
      successNotification(_.get(response, 'data.message'));
      setVisible(false);
      setTrigger((cur) => !cur);
      actionSaveToStatus(type, _.get(dataForm, 'cnId'));
    } else {
      errorNotification(_.get(response, 'data.error'));
    }
    setLoaddingButton(false);
  };

  return (
    <Modal
      title={
        (keyTabMonitor === 'draft' && _.get(dataForm, 'statusCode') === 'draft')
          ? intl.formatMessage({ id: `creditNoteModalTitleFormView`, defaultMessage: 'Credit Note' })
          : `${intl.formatMessage({ id: `creditNoteModalTitleFormView`, defaultMessage: 'Credit Note' })} ・ ${_.get(dataForm, 'cnNo')}`
      }
      visible={visible}
      onCancel={handleCancel}
      width={1000}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      zIndex={100}
      footer={visible ? handleButton() : undefined}
    >
      <Tabs animated={false} onChange={handleTabChange} activeKey={tabChange} tabBarExtraContent={operations} className="po-tabs-modal">
        <TabPane tab={intl.formatMessage({ id: `creditNoteModalTitleDetails`, defaultMessage: 'Details' })} key="1">
          <CreditNoteDetail
            initial={initial}
            dataForm={dataForm}
            setDataForm={setDataForm}
            selectItem={selectItem}
            setSelectItem={setSelectItem}
            attachmentData={attachmentData}
            setAttachmentData={setAttachmentData}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `creditNoteModalTitleReference`, defaultMessage: 'Reference' })} key="2">
          <ReferenceTab visiblemodal={visible} selectItem={refItem} setSelectItem={setRefItem} typeRef={'po'} />
        </TabPane>
      </Tabs>

      <ModalCnStatusForm
        visible={visibleStatus}
        setVisible={setVisibleStatus}
        setVisibleMain={setVisible}
        type={type}
        data={dataForm}
        setTrigger={setTrigger}
        keyTabMonitor={keyTabMonitor}
        actionSaveToStatus={actionSaveToStatus}
      />

      <ModalPDFViewver
        visible={visiblePrint}
        setVisible={setVisiblePrint}
        url={urlPrint}
        handlePrint={handlePrint}
        handlePrintA5={handlePrintA5}
        singlePage={true}
      /> 
    </Modal>
  );
};

export default CreditNoteView;
