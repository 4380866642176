import React from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import Button_01 from '../../../components/v2/button_01';
import LabeRequireForm from '../../../components/label-required-form';
import Button_02 from '../../../components/v2/button_02';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import ActionColumnsNetVehicleIncomeReport from './action-columns';

const { RangePicker } = DatePicker;

const ActionNetVehicleIncomeReport = (props) => {
  const { actionColumns, search, setSearch, setIsFetch, setPage, onFilterOrder } = props;
  const intl = useIntl();

  const handleApply = () => {
    setIsFetch((event) => !event);
    setPage(1);
  };

  const handleReset = () => {
    setIsFetch((event) => !event);
    setSearch((prev) => ({
      ...prev,
      taskNo: '',
      licensePlate: '',
      sourceName: '',
      sourcePostCode: '',
      destinationName: '',
      destinationPostCode: '',
    }));
    setPage(1);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'netVehicleIncomeReportTaskNoColumn', defaultMessage: 'Task No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'netVehicleIncomeReportTaskNoPH', defaultMessage: 'Enter Task No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'taskNo')}
                value={_.get(search, 'taskNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'netVehicleIncomeReportLicensePlateColumn', defaultMessage: 'License Plate' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'netVehicleIncomeReportLicensePlatePH', defaultMessage: 'Enter License Plate' })}
                onChange={(e) => onFilterOrder(e.target.value, 'licensePlate')}
                value={_.get(search, 'licensePlate') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'netVehicleIncomeReportSourceNameColumn', defaultMessage: 'Source Name' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'netVehicleIncomeReportSourceNamePH', defaultMessage: 'Enter Source Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'sourceName')}
                value={_.get(search, 'sourceName') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'netVehicleIncomeReportSourcePostcodeColumn', defaultMessage: 'Source Postcode' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'netVehicleIncomeReportSourcePostcodePH', defaultMessage: 'Enter Source Postcode' })}
                onChange={(e) => onFilterOrder(e.target.value, 'sourcePostCode')}
                value={_.get(search, 'sourcePostCode') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'netVehicleIncomeReportDestinationNameColumn', defaultMessage: 'Destination Name' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'netVehicleIncomeReportDestinationNamePH',
                  defaultMessage: 'Enter Destination Name',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'destinationName')}
                value={_.get(search, 'destinationName') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({
                    id: 'netVehicleIncomeReportDestinationPostcodeColumn',
                    defaultMessage: 'Destination Postcode',
                  })}
                  req={false}
                />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({
                  id: 'netVehicleIncomeReportDestinationPostcodePH',
                  defaultMessage: 'Enter Destination Postcode',
                })}
                onChange={(e) => onFilterOrder(e.target.value, 'destinationPostCode')}
                value={_.get(search, 'destinationPostCode') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label={<LabeRequireForm text={''} req={false} />}>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ paddingTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              value={_.get(search, 'filterDate')}
              format={['YYYY/MM/DD', 'YYYY/MM/DD']}
              onChange={(value) => onFilterOrder(value, 'filterDate')}
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'quotationFilterPHFormDueDate', defaultMessage: 'Select Form Date' }),
                intl.formatMessage({ id: 'quotationFilterPHToDueDate', defaultMessage: 'Selet To Date' }),
              ]}
            />
            <ActionColumnsNetVehicleIncomeReport actionColumns={actionColumns} />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ActionNetVehicleIncomeReport;
