import React, { createContext, useEffect, useState } from 'react';
import {
  createSaleOrder,
  getIntialListSaleOrder,
  getMemberIssueBy,
  getSaleOrderById,
  getSaleOrderList,
  updateSaleOrder,
  updateStatusSaleOrder,
  uploadAttachment,
} from '../../controllers/sale-order/sale-order-api-new';
import _ from 'lodash';
import moment from 'moment';
import { getMemberAsset } from '../../controllers/asset-resource/asset-controller';
import { errorNotification, successNotification } from '../../components/v2/notification';
import {
  getSummaryAccount,
  getSaleOrderTOARList,
  getARDetail,
  getSaveAR,
  uploadARAttachment,
  getARPaidDetail,
  updateARStatus,
  getSaleOderDetail,
  getSaleOrderPendingList,
  getSaveNewAR,
} from '../../controllers/account-receive';
import { getCustomerLoadMore } from '../../controllers/getCustomer/get-customer-new';
import { GetSignaturePrint, GetSignaturePrintIssu } from '../../controllers/quotation/quotation-api-new';
import { getStockListById } from '../../controllers/po/po';
import {
  createBilling,
  createBillingPaid,
  exportBL,
  getBillingDetail,
  getBillingDetailPaid,
  getBillingList,
  getSummaryBL,
  updateStatusBL,
  uploadBLAttachment,
  uploadBLAttachmentPaid,
} from '../../controllers/billing-note/billingapi';
import {
  exportFileBilling,
  exportFileInvoice,
  getDetailInvoice,
  getInvoiceList,
  getTeamOrg,
} from '../../controllers/invoice-new/invoice-api';
import { useIntl } from 'react-intl';
import { getCreditNoteDetail, getCreditNoteDetailOrigin } from '../../controllers/credit-note/credit-note';

const AccountReceiveContext = createContext();

const SaleOrderProvider = ({ children }) => {
  const [isLoadButton, setIsLoadButton] = useState(false);

  const [isOpenQuotation, seIsOpenQuotation] = useState(false);
  const [isOpenBillingTask, setIsOpenBillingTask] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [record, setRecord] = useState();
  const [signatureUser, setSignatureUser] = useState();
  const [signatureUserInvoice, setSignatureUserInvoice] = useState();
  const [recordInvoice, setRecordInvoice] = useState();
  const [recordReport, setRecordReport] = useState();

  const [openUpload, setOpenUpload] = useState(false);
  const [openUploadPaid, setOpenUploadPaid] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [attachmentPaidData, setAttachmentPaidData] = useState([]);
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [summaryData, setSummaryData] = useState([]);
  const [paidData, setPaidData] = useState([]);
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const intl = useIntl();

  const [tabKey, setTabKey] = useState('pending');
  const [initialMemberList, setInitialMemberList] = useState([]);
  const [initialTaxList, setInitialTaxList] = useState([]);
  const [initialReasonList, setInitialReasonList] = useState([]);
  const [initialCampaignList, setInitialCampaignList] = useState([]);
  const [cancelItemData, setCancelItemData] = useState([]);
  const [event, setEvent] = useState(false);
  const [typepatment, setTypePayment] = useState('');
  const [tempVoucher, setTempVoucher] = useState([]);
  const [tempVoucherBrand, setTempVoucherBrand] = useState([]);
  const [tempVoucherModel, setTempVoucherModel] = useState([]);
  const [tempVoucherItem, setTempVoucherItem] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [saleOrderQTY, setSaleOrderQTY] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [customerPage, setCustomerPage] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [viewDetail, setViewDetail] = useState([]);
  const [totalWarehouseList, setTotalWarehouseList] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState();
  const [statusList, setStatusList] = useState([]);
  const [teamList, setTeamList] = useState([]);

  const [selectKeysTable, setSelectKeysTable] = useState([]);
  const [selectAllRow, setSelectAllRow] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listCreate, setListCreate] = useState([]);

  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [visibleCancelPaid, setVisibleCancelPaid] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleWaiting, setVisibleWaiting] = useState(false);
  const [visiblePaid, setVisiblePaid] = useState(false);
  const [visibleCash, setVisibleCash] = useState(false);
  const [visibleBank, setVisibleBank] = useState(false);
  const [visibleCredit, setVisibleCredit] = useState(false);
  const [visibleCreditNote, setVisibleCreditNote] = useState(false);
  const [visibleCheque, setVisibleCheque] = useState(false);
  const [visibleVoucher, setVisibleVoucher] = useState(false);
  const [visibleAddItem, setVisibleAddItem] = useState(false);
  const [visibleSelectVoucher, setVisibleSelectVoucher] = useState(false);
  const [visibleViewInvoice, setVisibleViewInvoice] = useState(false);
  const [reportDataPending, setReportDataPending] = useState({
    // quotationList: [],
    // quotationTotal: 0,
    // extraSort: undefined,
    // fieldSort: '',
    // orderSort: 'asc',
    // paginationPage: 1,
    // paginationSize: 10,
    // loading: false,
    // quotationNo: '',
    // invoiceNo: '',
    // paymentTermNo: '',
    // saleOrderNo: '',
    // customerName: '',
    // rangePickerDate: [],
    // statusCode: [],
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    documentNo: '',
    saleOrderNo: '',
    team: '',
    // customerId: undefined,
    customerName: undefined,
    statusCode: [],
    rangePickerDate: [],
    rangePickerDueDate: [],
    type: '',
    // rangePickerDate: [moment().startOf('month'), moment().endOf('month')],
  });

  const [reportDataWaiting, setReportDataWaiting] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: undefined,
    orderSort: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    team: '',
    paymentTermNo: '',
    billingNo: '',
    customerName: undefined,
    rangePickerDate: [],
    rangePickerDueDate: [],
  });

  const [reportDataPaid, setReportDataPaid] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: undefined,
    orderSort: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    team: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: undefined,
    rangePickerDate: [moment().add(-3, 'month'), moment().add('month')],
    rangePickerDueDate: [],
  });

  const [reportDataCanceled, setReportDataCanceled] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: undefined,
    orderSort: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    team: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: undefined,
    rangePickerDate: [moment().add(-3, 'month'), moment().add('month')],
    rangePickerDueDate: [],
  });

  useEffect(() => {
    const getInitialData = async () => {
      const getTeam = await getTeamOrg();
      console.log(getTeam, 'dasaw');
      setTeamList(getTeam.team[0].children);
    };
    getInitialData();
  }, []);

  useEffect(() => {
    const getApiSummary = async () => {
      const body = {
        tabCode: tabKey,
      };

      const response = await getSummaryBL(body);
      console.log('summaryList', response.data);
      setSummaryData(response.data.summaryList);
    };

    getApiSummary();
  }, [event, tabKey]);

  useEffect(() => {
    setReportDataPending((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'pending',
        isCreateBilling: true,
        // pageNumber:
        //   _.get(reportDataPending, 'customerName') ||
        //     _.get(reportDataPending, 'type') ||
        //     _.get(reportDataPending, 'invoiceNo') ||
        //     _.get(reportDataPending, 'rangePickerDueDate[0]') ||
        //     _.get(reportDataPending, 'rangePickerDueDate[1]')
        //     ? 1
        //     : _.get(reportDataPending, 'paginationPage'),
        pageNumber: _.get(reportDataPending, 'paginationPage'),
        limit: _.get(reportDataPending, 'paginationSize'),
        orderBy: _.get(reportDataPending, 'fieldSort'),
        orderType: _.get(reportDataPending, 'orderSort'),
        invoiceNo: _.get(reportDataPending, 'documentNo') || '',
        documentNo: _.get(reportDataPending, 'documentNo'),
        team: _.get(reportDataPending, 'team'),
        paymentTermNo: _.get(reportDataPending, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataPending, 'saleOrderNo'),
        // customerId: [_.get(reportDataPending, 'customerId')],
        customerId: _.get(reportDataPending, 'customerName') ? [_.get(reportDataPending, 'customerName')] : [],
        statusCode: ['waiting'],
        startDate: _.get(reportDataPending, 'rangePickerDate[0]')
          ? moment(_.get(reportDataPending, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : '',
        endDate: _.get(reportDataPending, 'rangePickerDate[1]')
          ? moment(_.get(reportDataPending, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : '',
        dueDateStart: _.get(reportDataPending, 'rangePickerDueDate[0]')
          ? moment(_.get(reportDataPending, 'rangePickerDueDate[0]')).format('YYYY-MM-DD')
          : '',
        dueDateEnd: _.get(reportDataPending, 'rangePickerDueDate[1]')
          ? moment(_.get(reportDataPending, 'rangePickerDueDate[1]')).format('YYYY-MM-DD')
          : '',
        type: _.get(reportDataPending, 'type') || undefined,
        newType: (() => {
          if (!reportDataPending) return { $in: ['task', 'invoice', 'creditnote'] };

          const typeValue = _.get(reportDataPending, 'type');
          if (typeValue === undefined) return { $in: ['task', 'invoice', 'creditnote'] };
          if (typeValue === 'task') return 'task';
          if (typeValue === 'invoice') return { $in: ['invoice', 'creditnote'] };

          return typeValue;
        })(),
      };

      console.log(payload, 'invoicePayload');

      const response = await getInvoiceList(payload);

      console.log(response, 'invoiceResponce');

      if (_.get(response, 'status.code') === 200) {
        setReportDataPending((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.invoiceList'),
          // quotationList: _.get(response, 'data.saleOrderARList'),
          quotationTotal: _.get(response, 'data.totalItem'),
          blTypeList: _.get(response, 'data.blTypeList'),
        }));
        // setStatusList(_.get(response, 'data.statusList'));
      }
    };

    setReportDataPending((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'pending') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataPending, 'paginationPage'),
    _.get(reportDataPending, 'paginationSize'),
    _.get(reportDataPending, 'fieldSort'),
    _.get(reportDataPending, 'orderSort'),
    _.get(reportDataPending, 'customerName'),
    _.get(reportDataPending, 'type'),
  ]);

  useEffect(() => {
    setReportDataWaiting((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'waitingpaid',
        issueDateStart: _.get(reportDataWaiting, 'rangePickerDate[0]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : undefined,
        issueDateEnd: _.get(reportDataWaiting, 'rangePickerDate[1]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : undefined,
        pageNumber:
          _.get(reportDataWaiting, 'billingNo') ||
          _.get(reportDataWaiting, 'customerName') ||
          _.get(reportDataWaiting, 'statusCode') ||
          _.get(reportDataWaiting, 'paymentTermNo')
            ? 1
            : _.get(reportDataWaiting, 'paginationPage'),
        limit: _.get(reportDataWaiting, 'paginationSize'),
        orderBy: _.get(reportDataWaiting, 'fieldSort'),
        orderType: _.get(reportDataWaiting, 'orderSort'),
        paymentTermNo: _.get(reportDataWaiting, 'paymentTermNo'),
        billingNo: _.get(reportDataWaiting, 'billingNo'),
        team: _.get(reportDataWaiting, 'team'),
        customerId: _.get(reportDataWaiting, 'customerName') ? [_.get(reportDataWaiting, 'customerName')] : [],
        invoiceNo: '',
        statusCode: _.get(reportDataWaiting, 'statusCode') ? [_.get(reportDataWaiting, 'statusCode')] : [],
      };

      console.log(payload, 'BillingPayload');

      const response = await getBillingList(payload);

      console.log(response, 'BillingResponse');

      setReportDataWaiting((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.billingList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
      setStatusList(_.get(response, 'data.statusList'));
      console.log('WaitingPage2', reportDataWaiting);
    };
    setReportDataWaiting((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'waitingpaid' || tabKey === 'pending') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataWaiting, 'rangePickerDate[0]'),
    _.get(reportDataWaiting, 'rangePickerDate[1]'),
    _.get(reportDataWaiting, 'paginationPage'),
    _.get(reportDataWaiting, 'paginationSize'),
    _.get(reportDataWaiting, 'fieldSort'),
    _.get(reportDataWaiting, 'orderSort'),
  ]);

  useEffect(() => {
    setReportDataPaid((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'paid',
        issueDateStart: _.get(reportDataPaid, 'rangePickerDate[0]')
          ? moment(_.get(reportDataPaid, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : undefined,
        issueDateEnd: _.get(reportDataPaid, 'rangePickerDate[1]')
          ? moment(_.get(reportDataPaid, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : undefined,
        pageNumber:
          _.get(reportDataPaid, 'billingNo') || _.get(reportDataPaid, 'customerName') ? 1 : _.get(reportDataPaid, 'paginationPage'),
        limit: _.get(reportDataPaid, 'paginationSize'),
        orderBy: _.get(reportDataPaid, 'fieldSort'),
        orderType: _.get(reportDataPaid, 'orderSort'),
        paymentTermNo: _.get(reportDataPaid, 'paymentTermNo'),
        billingNo: _.get(reportDataPaid, 'billingNo'),
        team: _.get(reportDataPaid, 'team'),
        customerId: _.get(reportDataPaid, 'customerName') ? [_.get(reportDataPaid, 'customerName')] : [],
        invoiceNo: '',
        statusCode: [],
        // tabCode: 'paid',
        // issueDateStart: _.get(reportDataPaid, 'rangePickerDate[0]')
        //   ? moment(_.get(reportDataPaid, 'rangePickerDate[0]')).format('YYYY-MM-DD')
        //   : undefined,
        // issueDateEnd: _.get(reportDataPaid, 'rangePickerDate[0]')
        //   ? moment(_.get(reportDataPaid, 'rangePickerDate[1]')).format('YYYY-MM-DD')
        //   : undefined,
        // pageNumber: _.get(reportDataPaid, 'paginationPage'),
        // limit: _.get(reportDataPaid, 'paginationSize'),
        // orderBy: _.get(reportDataPaid, 'fieldSort'),
        // orderType: _.get(reportDataPaid, 'orderSort'),
        // invoiceNo: _.get(reportDataPaid, 'invoiceNo'),
        // paymentTermNo: _.get(reportDataPaid, 'paymentTermNo'),
        // saleOrderNo: _.get(reportDataPaid, 'saleOrderNo'),
        // customerId: _.get(reportDataPaid, 'customerName') ? [_.get(reportDataPaid, 'customerName')] : '',
      };
      console.log(payload, 'BillingPayload');

      const response = await getBillingList(payload);

      console.log(response, 'BillingResponse');
      setReportDataPaid((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.billingList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
    };
    setReportDataPaid((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'paid') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataPaid, 'rangePickerDate[0]'),
    _.get(reportDataPaid, 'rangePickerDate[1]'),
    _.get(reportDataPaid, 'paginationPage'),
    _.get(reportDataPaid, 'paginationSize'),
    _.get(reportDataPaid, 'fieldSort'),
    _.get(reportDataPaid, 'orderSort'),
  ]);

  useEffect(() => {
    setReportDataCanceled((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        tabCode: 'canceled',
        issueDateStart: _.get(reportDataCanceled, 'rangePickerDate[0]')
          ? moment(_.get(reportDataCanceled, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : undefined,
        issueDateEnd: _.get(reportDataCanceled, 'rangePickerDate[1]')
          ? moment(_.get(reportDataCanceled, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : undefined,
        pageNumber:
          _.get(reportDataCanceled, 'billingNo') || _.get(reportDataCanceled, 'customerName')
            ? 1
            : _.get(reportDataCanceled, 'paginationPage'),
        limit: _.get(reportDataCanceled, 'paginationSize'),
        orderBy: _.get(reportDataCanceled, 'fieldSort'),
        orderType: _.get(reportDataCanceled, 'orderSort'),
        paymentTermNo: _.get(reportDataCanceled, 'paymentTermNo'),
        billingNo: _.get(reportDataCanceled, 'billingNo'),
        team: _.get(reportDataCanceled, 'team'),
        customerId: _.get(reportDataCanceled, 'customerName') ? [_.get(reportDataCanceled, 'customerName')] : [],
        invoiceNo: '',
        statusCode: [],
      };

      console.log(payload, 'BillingPayload');

      const response = await getBillingList(payload);

      console.log(response, 'BillingResponse');
      setReportDataCanceled((prev) => ({
        ...prev,
        quotationList: _.get(response, 'data.billingList'),
        quotationTotal: _.get(response, 'data.totalItem'),
      }));
    };
    setReportDataCanceled((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'canceled') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataCanceled, 'rangePickerDate[0]'),
    _.get(reportDataCanceled, 'rangePickerDate[1]'),
    _.get(reportDataCanceled, 'paginationPage'),
    _.get(reportDataCanceled, 'paginationSize'),
    _.get(reportDataCanceled, 'fieldSort'),
    _.get(reportDataCanceled, 'orderSort'),
  ]);

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getMemberIssueBy(payload);
      setInitialMemberList(result || []);
    };
    getApi();
  }, []);

  useEffect(() => {
    async function getUser() {
      try {
        const tabValue =
          tabKey === 'pending'
            ? reportDataPending
            : tabKey === 'waitingpaid'
            ? reportDataWaiting
            : tabKey === 'paid'
            ? reportDataPaid
            : tabKey === 'canceled'
            ? reportDataCanceled
            : null;

        const pageStart = 1;
        const pageEnd = customerPage;

        const data = await getCustomerLoadMore(pageStart, pageEnd, _.get(tabValue, 'customerName'));
        console.log('CustomerVoucher', data);
        setCustomerList(_.get(data, 'customers'));
        setCustomerLoading(false);
      } catch (error) {}
    }

    getUser();
  }, [
    customerPage,
    _.get(reportDataPending, 'customerName'),
    _.get(reportDataWaiting, 'customerName'),
    _.get(reportDataPaid, 'customerName'),
    _.get(reportDataCanceled, 'customerName'),
  ]);

  useEffect(() => {
    const getApi = async () => {
      const resultTax = await getIntialListSaleOrder('tax');
      const resultReason = await getIntialListSaleOrder('reason');
      const resultCampaign = await getIntialListSaleOrder('campaign');
      setInitialTaxList(resultTax || []);
      setInitialReasonList(resultReason || []);
      setInitialCampaignList(resultCampaign || []);
    };
    getApi();
  }, []);

  useEffect(() => {
    const getApi = async () => {
      const response = await getStockListById('2aa809fe-b903-4466-a222-f9a4bb1d7352');
      const tempmap = _.map(_.get(response, 'data.data.stockList'), (witem, index) => ({
        index: index,
        qty: _.get(witem, 'qty'),
        qtyColor: _.get(witem, 'qtyColor'),
        totalCost: _.get(witem, 'totalCost'),
        warehouseCode: _.get(witem, 'warehouseCode'),
        warehouseId: _.get(witem, 'warehouseId'),
        warehouseName: _.get(witem, 'warehouseName'),
        selectQty: undefined,
      }));
      setWarehouseList(_.get(response, 'data.data.stockList'));
      setTotalWarehouseList(_.get(response, 'data.data.totalItem'));
    };
    // getApi();
  }, [visibleAddItem]);

  console.log('initialReasonList', listCreate);

  const handleOpenModalQuotationPending = async (row, code) => {
    try {
      let response;

      console.log(row, 'rowrow');

      const documentId = _.chain(_.size(listCreate) > 0 ? listCreate : row)
        .thru((o) => {
          let resultId = '';
          let resultObject = {};

          if (_.isArray(o)) {
            resultObject = _.find(o, (ob) => {
              return _.get(ob, 'type') === _.get(row, 'type') ? true : false;
            });
          } else if (_.isObject(o)) {
            resultObject = o;
          }

          const rowType = _.get(resultObject, 'type');
          if (rowType === 'invoice') {
            resultId = _.get(resultObject, 'invoiceId');
          } else if (rowType === 'creditnote') {
            resultId = _.get(resultObject, 'creditNoteId');
          }
          return resultId;
        })
        .value();

      if (row.type === 'invoice') {
        response = await getDetailInvoice(documentId);
      }
      if (row.type === 'creditnote') {
        response = await getCreditNoteDetailOrigin(documentId);
      }

      if (row.type === 'invoice') {
        setRecord({
          ..._.get(response, 'data'),
          itemList: _.size(listCreate) > 0 ? listCreate : [row],
          code: code,
        });
      }

      if (row.type === 'creditnote') {
        setRecord({
          ...response,
          customerId: response.invoiceHeader.customerDetail.customerId,
          customerName: response.invoiceHeader.customerDetail.customerName,
          customerAddressBookName: response.invoiceHeader.customerDetail.customerAddressBookName,
          customerAddress: response.invoiceHeader.customerDetail.customerAddressBookFullAddress,
          customerTaxNo: response.invoiceHeader.customerDetail.customerTaxNo,
          customerContactName: response.invoiceHeader.customerDetail.customerContactName,
          customerContactPhone: response.invoiceHeader.customerDetail.customerContactPhone,
          customerContactEmail: response.invoiceHeader.customerDetail.customerContactEmail,
          issueBy: response.invoiceHeader.issueDetail.issueBy,
          issueDateTxt: response.invoiceHeader.issueDetail.issueDate,
          dueDate: response.invoiceHeader.issueDetail.dueDate,
          itemList: _.size(listCreate) > 0 ? listCreate : [row],
          code: code,
        });
      }

      const tempwh = _.map(_.get(response, 'data.saleOrderItemList'), (r) => ({
        ...r,
        qtyDeliver: 0,
      }));

      setSaleOrderQTY(tempwh);
      seIsOpenQuotation(true);
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };

  const handleOpenModalBillingTaskPending = async (row, code) => {
    try {
      const response = await getDetailInvoice(_.size(listCreate) > 0 ? _.get(listCreate, '[0].invoiceId') : _.get(row, 'invoiceId'));
      console.log('handleOpenModalBillingTaskPending', response, listCreate, row);
      let listCreateNew = [];
      if (_.size(listCreate)) {
        listCreateNew = _.map(listCreate, (row) => {
          return {
            ...row,
            taskNoTemp: row.invoiceNo || '',
            taskTypeTemp: row.taskTypeName || '',
            descriptionTemp: `${row.formAddressName || ''} - ${row.toAddressName || ''}, ${row.startDateTxt || ''} - ${row.endDateTxt ||
              ''}`,

            // descriptionTemp:
            //   row.formAddressName || '' + ' - ' + row.toAddressName || '' + ', ' + row.startDateTxt || '' + ' - ' + row.endDateTxt || '',
            amountTemp: row.freigt_price || 0,
            otherValue: row.otherValue || 0,
            sumOtherAmount: parseFloat(row.freigt_price) + parseFloat(row.otherValue || 0) || 0,
            taskId: row.invoiceId,
          };
        });
      } else {
        row.taskNoTemp = row.invoiceNo || '';
        row.taskTypeTemp = row.taskTypeName || '';
        row.descriptionTemp = `${row.formAddressName || ''} - ${row.toAddressName || ''}, ${row.startDateTxt || ''} - ${row.endDateTxt ||
          ''}`;
        // row.formAddressName || '' + ' - ' + row.toAddressName || '' + ', ' + row.startDateTxt + ' - ' + row.endDateTxt;
        row.amountTemp = row.freigt_price || 0;
        row.otherValue = row.otherValue || 0;
        row.sumOtherAmount = parseFloat(row.freigt_price) + parseFloat(row.otherValue || 0) || 0;
        row.taskId = row.invoiceId;
      }

      console.log('handleOpenModalBillingTaskPending', row);
      if (_.get(reportDataPending, 'type') === 'invoice') {
        setRecord({ ..._.get(response, 'data'), itemList: _.size(listCreate) > 0 ? [] : [], code: code });
      } else {
        setRecord({
          ..._.get(response, 'data'),
          itemList: _.size(listCreate) > 0 ? listCreateNew : [row],
          code: code,
        });
      }

      const tempwh = _.map(_.get(response, 'data.saleOrderItemList'), (r) => ({
        ...r,
        qtyDeliver: 0,
      }));

      setSaleOrderQTY(tempwh);
      setIsOpenBillingTask(true);
    } catch (error) {}
  };

  const handleOpenModalQuotationInvoice = async (row, code) => {
    try {
      const payload = {
        saleOrderId: _.get(row, 'saleOrderId'),
        tabCode: code,
        issueDateStart: '2023-01-01',
        issueDateEnd: '2025-01-01',
        saleOrderNo: '',
        customerName: '',
        paymentTermNo: '',
        pageNumber: 1,
        limit: 10,
      };

      const response = await getSaleOrderTOARList(payload);
      setViewDetail(_.get(response, 'data.saleOrderARList'));
      // setRecord({ ..._.get(response, 'data.saleOrderDetail'), code: code });
      // setSaleOrderQTY(_.get(response, 'data.saleOrderItemList'));
      // seIsOpenQuotation(true);
    } catch (error) {}
  };

  const handleOpenModalQuotation = async (row, code) => {
    if (row) {
      try {
        const response = await getBillingDetail(row.billingId);
        // const responseSignature = _.get(response, 'data.receiveById')
        //   ? GetSignaturePrintIssu(_.get(response, 'data.receiveById'))
        //   : await GetSignaturePrint();
        // console.log('getSaleOrderById', code, response);

        const itemListInvoice = _.map(_.get(response, 'data.invoiceList'), (o, i) => ({
          ...o,
          issueDateTxt: _.get(o, 'issueDate'),
          dueDateTxt: _.get(o, 'dueDate'),
          totalNum: _.get(o, 'amountNum'),
          totalTxt: _.get(o, 'amount'),
          type: 'invoice',
        }));

        const itemListCreditNote = _.map(_.get(response, 'data.creditNoteList'), (o, i) => ({
          ...o,
          invoiceNo: _.get(o, 'creditNoteNo'),
          issueDateTxt: _.get(o, 'issueDate'),
          remark: _.get(o, 'remark') || '-',
          dueDateTxt: _.get(o, 'dueDate'),
          totalTxt: _.get(o, 'totalTxt'),
          type: 'creditNote',
        }));

        console.log(_.concat(itemListInvoice, itemListCreditNote), 'itemListTest');
        console.log(row, 'rowrow / /', response);

        setRecord({
          ..._.get(response, 'data'),
          itemList: _.concat(itemListInvoice, itemListCreditNote),
          code: _.get(response, 'data.stautsCode'),
          attanchment: _.get(response, 'data.attanchment'),
        });
        // setRecordReport(_.get(response, 'data.invoiceList'));
        handleCountSize(_.get(response, 'data.invoiceList'), _.get(response, 'data'));
        // setSignatureUser(_.get(responseSignature, 'data'));

        seIsOpenQuotation(true);
      } catch (error) {}
    } else {
      seIsOpenQuotation(true);
      setRecord();
    }
  };

  const handleOpenModalQuotationTask = async (row, code) => {
    if (row) {
      try {
        const response = await getBillingDetail(row.billingId);
        // const responseSignature = _.get(response, 'data.receiveById')
        //   ? GetSignaturePrintIssu(_.get(response, 'data.receiveById'))
        //   : await GetSignaturePrint();
        const itemList = _.map(_.get(response, 'data.taskList'), (o, i) => ({
          ...o,
          issueDateTxt: _.get(o, 'issueDate'),
          dueDateTxt: _.get(o, 'dueDate'),
          totalTxt: _.get(o, 'amount'),
          taskNoTemp: _.get(o, 'taskNo') || '',
          taskTypeTemp: _.get(o, 'taskType.taskTypeName') || '',
          descriptionTemp: `${_.get(o, 'formAddressName') || ''} - ${_.get(o, 'toAddressName') || ''}, ${_.get(o, 'startDateTxt') ||
            ''} - ${_.get(o, 'endDateTxt') || ''}`,
          amountTemp: _.get(o, 'amount'),
          otherValue: _.get(o, 'other'),
          sumOtherAmount: parseFloat(_.get(o, 'amount')) + parseFloat(_.get(o, 'other') || 0) || 0,
          taskId: _.get(o, 'invoiceId'),
        }));

        setRecord({
          ..._.get(response, 'data'),
          itemList: itemList,
          code: _.get(response, 'data.stautsCode'),
          attanchment: _.get(response, 'data.attanchment'),
        });
        // setRecordReport(_.get(response, 'data.invoiceList'));
        handleCountSize(_.get(response, 'data.invoiceList'), _.get(response, 'data'));
        // setSignatureUser(_.get(responseSignature, 'data'));

        setIsOpenBillingTask(true);
      } catch (error) {}
    } else {
      setIsOpenBillingTask(true);
      setRecord();
    }
  };

  const handleOpenModalQuotationCreate = async (code) => {
    if (listCreate) {
      try {
        setRecord({ itemList: listCreate, code: 'view' });

        seIsOpenQuotation(true);
      } catch (error) {}
    } else {
      seIsOpenQuotation(true);
      setRecord();
    }
  };

  const handleOpenModalBillingTaskCreate = async (code) => {
    if (listCreate) {
      try {
        setRecord({ itemList: listCreate, code: 'view' });

        setIsOpenBillingTask(true);
      } catch (error) {}
    } else {
      setIsOpenBillingTask(true);
      setRecord();
    }
  };

  const handleCountSize = (data, alldata) => {
    let count = 0;
    let reportmap = [];
    console.log('recordReportDatadefult', data, alldata);

    // let tempgdata = _.chain(data)
    //   .groupBy('credit')
    //   .map((value, key) => ({ credit: key, detail: value }))
    //   .value();
    // console.log('recordReportData', tempgdata);
    // const day = _.split(_.get(alldata, 'issueDate'), '-');
    // const years = parseInt(day[0]) + 543;

    // _.map(tempgdata, (item) => {
    //   let total = _.sumBy(_.get(item, 'detail'), 'totalNum');
    //   console.log('credisDays', day);
    //   const newday = parseInt(day[2]) + parseInt(item.credit);
    //   const newdate = _.toString(newday + '/' + parseInt(day[1]) + '/' + years);
    //   reportmap.push(
    //     { credit: item.credit, role: 'head', total: total, creditday: newdate }
    //     // item
    //   );
    //   _.map(_.get(item, 'detail'), (detail) => {
    //     count = count + 1;
    //     reportmap.push({ ...detail, index: count });
    //   });
    // });

    console.log('recordReportData', data);

    //     const day = _.split(_.get(alldata, 'issueDate'), '-');
    // const years = parseInt(day[0]) + 543;

    // console.log('year543',years,'/',day);

    _.map(data, (item) => {
      // let total = _.sumBy(item, 'totalNum');
      // reportmap.push({ credit: item.credit, role: 'head', total: total });
      // _.map(_.get(data, 'detail'), (detail) => {
      const day = _.split(_.get(item, 'issueDate'), '/');
      const years = parseInt(day[2]) + 543;
      const newdate = _.toString(parseInt(day[0]) + '/' + parseInt(day[1]) + '/' + years);
      const dueday = _.split(_.get(item, 'dueDate'), '/');
      const dueyears = parseInt(dueday[2]) + 543;
      const duenewdate = _.toString(parseInt(dueday[0]) + '/' + parseInt(day[1]) + '/' + dueyears);
      console.log('year543', years, '/', day);
      count = count + 1;
      reportmap.push({ ...item, issueDate: newdate, dueDate: duenewdate, index: count });
      // if (item.remark !== '') {
      //   reportmap.push({ remarkline: item.remark, role: 'head', total: item.amount });
      // }

      // });
    });

    setRecordReport(reportmap);
    console.log('reportmap', reportmap);
  };

  const handleCustomerPopupScroll = (e) => {
    console.log('customerFFF', e);
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerPage(customerPage + 10);
      setCustomerLoading(true);
    }
  };

  const handleOpenModalUpload = (row) => {
    if (row) {
      setOpenUpload(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUpload(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleSaveQuotation = async (payload, resetStateAll) => {
    try {
      const response = await createBilling(payload);

      if (_.size(attachmentData) > 0) {
        for (let index = 0; index < attachmentData.length; index++) {
          const file = _.get(attachmentData[index], 'fileRaw');
          const formData = new FormData();
          formData.append('file', file);
          formData.append('billingId', _.get(response, 'data.billingId'));
          formData.append('remark', attachmentData[index].remark === undefined ? '' : attachmentData[index].remark);
          if (attachmentData[index].fileHash === undefined) {
            await uploadBLAttachment(formData);
          }
        }
      }

      console.log('createSaleOrder', response);

      if (_.get(response, 'status.code') === 200) {
        successNotification(_.get(response, 'status.message'));
        resetStateAll();
        setIsFetch((prev) => !prev);
        setVisiblePaid(false);
        seIsOpenQuotation(false);
        setIsOpenBillingTask(false);
        setListCreate([]);
      } else {
        errorNotification(_.get(response, 'status.message'));
      }
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
    }
    setIsLoadButton(false);

    // } catch (error) {
    //   console.log('createSaleOrderError', error);
    //   errorNotification(_.get(error, 'status.message'));
    //   return;
    // }
  };

  const handleSavePaid = async (payload) => {
    const response = await createBillingPaid(payload);

    if (_.size(attachmentData) > 0) {
      for (let index = 0; index < attachmentData.length; index++) {
        const file = _.get(attachmentData[index], 'fileRaw');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('billingId', _.get(record, 'saleOrderNo'));
        formData.append('remark', attachmentData[index].remark);
        if (attachmentData[index].fileHash === undefined) {
          await uploadBLAttachmentPaid(formData);
        }
      }
    }

    console.log('createSaleOrder', response);

    if (_.get(response, 'status.code') === 200) {
      successNotification(_.get(response, 'status.message'));
    } else {
      errorNotification(_.get(response, 'status.message'));
    }

    setIsFetch((prev) => !prev);
    setVisiblePaid(false);
    seIsOpenQuotation(false);
    setIsOpenBillingTask(false);
    // } catch (error) {
    //   console.log('createSaleOrderError', error);
    //   errorNotification(_.get(error, 'status.message'));
    //   return;
    // }
  };

  const handleCancelQuotation = () => {
    seIsOpenQuotation(false);
    setIsOpenBillingTask(false);
    setRecord();
  };

  const handleCancelBillingTask = () => {
    setIsOpenBillingTask(false);
    setRecord();
  };

  const handleOpenPaid = async (code, arId) => {
    try {
      const response = await getBillingDetailPaid(arId);

      setPaidData({ ..._.get(response, 'data'), code: code });
      setVisiblePaid(true);
    } catch (error) {}
  };

  const handleCancelPaid = (value) => {
    setVisiblePaid(false);
    setPaidData([]);
  };

  const handleOpenApprove = (value) => {
    setVisibleApprove(true);
  };

  const handleSaveApprove = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      setIsFetch((prev) => !prev);
      setVisibleApprove(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelApprove = () => {
    setVisibleApprove(false);
  };

  const handleOpenReject = (value) => {
    setVisibleReject(true);
  };

  const handleSaveReject = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      setIsFetch((prev) => !prev);
      setVisibleReject(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelReject = (value) => {
    setVisibleReject(false);
  };

  const handleOpenCancel = (value) => {
    setVisibleCancel(true);
  };

  const handleSaveCancel = async (value) => {
    try {
      const response = await updateStatusBL(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        successNotification('Success');
        handleCancelQuotation();
      }
      setIsFetch((prev) => !prev);
      setVisibleCancel(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelCancelQT = (value) => {
    setVisibleCancel(false);
  };

  const handleOpenDelete = (value) => {
    setVisibleDelete(true);
  };

  const handleSaveDelete = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      setIsFetch((prev) => !prev);
      setVisibleDelete(false);
      seIsOpenQuotation(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelDelete = (value) => {
    setVisibleDelete(false);
  };

  const handleOpenWaiting = (value) => {
    setVisibleWaiting(true);
  };

  const handleSaveWaiting = async (value) => {
    try {
      const response = await updateARStatus(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        successNotification('Success');
        handleCancelQuotation();
      }
      setIsFetch((prev) => !prev);
      setVisibleWaiting(false);
      setVisibleViewInvoice(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelWaiting = (value) => {
    setVisibleWaiting(false);
  };

  const handleOpenModalUploadPaid = (row) => {
    if (row) {
      setOpenUploadPaid(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUploadPaid(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleOpenCancelPaid = (value) => {
    setVisibleCancelPaid(true);
    setCancelItemData(value);
  };

  const handleCloseCancelPaid = () => {
    setVisibleCancelPaid(false);
  };

  const handleOpenCash = (value) => {
    setVisibleCash(true);
    setTypePayment(value);
  };

  const handleCancelCash = (value) => {
    setVisibleCash(false);
  };

  const handleOpenBank = (value) => {
    setVisibleBank(true);
  };

  const handleCancelBank = (value) => {
    //visibleBank
    setVisibleBank(false);
  };

  const handleOpenCredit = (value) => {
    setVisibleCredit(true);
  };

  const handleCancelCredit = (value) => {
    //visibleCredit
    setVisibleCredit(false);
  };

  const handleOpenCreditNote = (value) => {
    setVisibleCreditNote(true);
  };

  const handleCancelCreditNote = (value) => {
    //visibleCredit
    setVisibleCreditNote(false);
  };

  const handleOpenCheque = (value) => {
    setVisibleCheque(true);
  };

  const handleCancelCheque = (value) => {
    //visibleCheque
    setVisibleCheque(false);
  };

  const handleOpenVoucher = (value) => {
    setVisibleVoucher(true);
  };

  const handleCancelVoucher = (value) => {
    //visibleVoucher
    setVisibleVoucher(false);
  };

  const openVoucherListModal = (value) => {
    setVisibleSelectVoucher(true);
  };

  const handleOpenAddItem = async (value) => {
    setVisibleAddItem(true);
  };

  const handleCancelAddItem = (value) => {
    //visibleVoucher
    setVisibleAddItem(false);
  };

  const handleOpenViewInvoice = async (row) => {
    if (row) {
      try {
        const response = await getARDetail(row.arId);
        const responseSignature = await GetSignaturePrint();
        // console.log('getSaleOrderById', code, response);

        setRecordInvoice({ ..._.get(response, 'data'), code: _.get(response, 'data.stautsCode') });
        setSignatureUserInvoice(_.get(responseSignature, 'data'));
        setVisibleViewInvoice(true);
      } catch (error) {}
    } else {
      setVisibleViewInvoice(true);
      setRecord();
    }
  };

  const handleCancelViewInvoice = (value) => {
    //visibleVoucher
    setVisibleViewInvoice(false);
  };

  const handleCleckSaleOrder = async (record, selectItem) => {
    console.log('RowRecordAR', record, '/', selectItem);
    const response = await getStockListById(_.get(record, 'itemId'));
    const whrp = _.get(response, 'data.data.stockList');
    setRecordList(record);

    const tempft = _.filter(selectItem, (i) => {
      return i.saleOrderItemId === _.get(record, 'saleOrderItemId');
    });
    const ftSO = tempft[0];
    const ftsoindex = _.get(ftSO, 'warehouseSeletedList');
    console.log('ftSO', ftsoindex);
    console.log('warehouseListQTY', warehouseList);

    const tempsetWh = _.map(whrp, (i) => {
      const fineIn = _.findIndex(ftsoindex, ['warehouseId', i.warehouseId]);
      console.log('fineIn', fineIn);
      if (fineIn > -1) {
        i.qty = parseInt(i.qty) - parseInt(ftsoindex[fineIn].qty);
      }

      return i;
    });

    if (ftSO) {
      setWarehouseList(tempsetWh);
    } else {
      setWarehouseList(_.get(response, 'data.data.stockList'));
    }
  };

  const handleExport = async () => {
    console.log('handleExport', record, tabKey);
    try {
      const tabValue =
        tabKey === 'pending'
          ? reportDataPending
          : tabKey === 'waitingpaid'
          ? reportDataWaiting
          : tabKey === 'paid'
          ? reportDataPaid
          : tabKey === 'canceled'
          ? reportDataCanceled
          : null;

      const payload = {
        tabCode: tabKey,
        pageNumber: 1,
        limit: _.get(tabValue, 'quotationTotal'),
        orderBy: _.get(tabValue, 'fieldSort'),
        orderType: _.get(tabValue, 'orderSort'),
        _issueDateEnd: _.get(tabValue, 'rangePickerDate[1]') ? moment(_.get(tabValue, 'rangePickerDate[1]')).format('YYYY-MM-DD') : '',
        _issueDateStart: _.get(tabValue, 'rangePickerDate[0]') ? moment(_.get(tabValue, 'rangePickerDate[0]')).format('YYYY-MM-DD') : '',
        paymentTermNo: _.get(tabValue, 'paymentTermNo'),
        billingNo: _.get(tabValue, 'billingNo'),
        customerId: _.get(tabValue, 'customerId'),
        invoiceNo: '',
        paymentTermNo: '',
        saleOrderNo: '',
        customerId: _.get(tabValue, 'customerName') ? [_.get(tabValue, 'customerName')] : [],
        isCreateBilling: false,
        viewFormat:
          tabKey === 'waitingpaid'
            ? [
                {
                  colName: intl.formatMessage({ id: 'billingฺBLNo', defaultMessage: 'BL No.' }),
                  sequence: 1,
                  colCode: 'billingNo',
                },
                {
                  colName: intl.formatMessage({ id: 'billingCustomer', defaultMessage: 'Customer' }),
                  sequence: 2,
                  colCode: 'customerName',
                },
                {
                  colName: intl.formatMessage({ id: 'billingType', defaultMessage: 'Type' }),
                  sequence: 3,
                  colCode: 'type',
                },
                {
                  colName: intl.formatMessage({ id: 'billingItems', defaultMessage: 'Items' }),
                  sequence: 4,
                  colCode: 'totalItem',
                },
                {
                  colName: intl.formatMessage({ id: 'billingTotalAmount', defaultMessage: 'Total Amount' }),
                  sequence: 5,
                  colCode: 'totalTxt',
                },
                {
                  colName: intl.formatMessage({ id: 'billingPaidAmount', defaultMessage: 'Paid Amount' }),
                  sequence: 6,
                  colCode: 'paidAmount',
                },
                {
                  colName: intl.formatMessage({ id: 'billingAccruedAmount', defaultMessage: 'Accrued Amount' }),
                  sequence: 7,
                  colCode: 'accrued',
                },
                {
                  colName: intl.formatMessage({ id: 'quotationLabelDueDate', defaultMessage: 'Due Date' }),
                  sequence: 8,
                  colCode: 'dueDateTxt',
                },
                {
                  colName: intl.formatMessage({ id: 'invoiceLabelTeam', defaultMessage: 'Team' }),
                  sequence: 9,
                  colCode: 'team',
                },
                {
                  colName: intl.formatMessage({ id: 'quotationLabelIssuedBy', defaultMessage: 'Issued by' }),
                  sequence: 10,
                  colCode: 'issueBy',
                },
                {
                  colName: intl.formatMessage({ id: 'quotationLabelIssuedDate', defaultMessage: 'Issued Date' }),
                  sequence: 11,
                  colCode: 'issueDateTxt',
                },
              ]
            : [
                {
                  colName: intl.formatMessage({ id: 'billingฺBLNo', defaultMessage: 'BL No.' }),
                  sequence: 1,
                  colCode: 'billingNo',
                },
                {
                  colName: intl.formatMessage({ id: 'billingCustomer', defaultMessage: 'Customer' }),
                  sequence: 2,
                  colCode: 'customerName',
                },
                {
                  colName: intl.formatMessage({ id: 'billingType', defaultMessage: 'Type' }),
                  sequence: 3,
                  colCode: 'type',
                },
                {
                  colName: intl.formatMessage({ id: 'billingItems', defaultMessage: 'Items' }),
                  sequence: 4,
                  colCode: 'totalItem',
                },
                {
                  colName: intl.formatMessage({ id: 'billingTotalAmount', defaultMessage: 'Total Amount' }),
                  sequence: 5,
                  colCode: 'totalTxt',
                },
                {
                  colName: intl.formatMessage({ id: 'quotationLabelDueDate', defaultMessage: 'Due Date' }),
                  sequence: 6,
                  colCode: 'dueDateTxt',
                },
                {
                  colName: intl.formatMessage({ id: 'invoiceLabelTeam', defaultMessage: 'Team' }),
                  sequence: 7,
                  colCode: 'team',
                },
                {
                  colName: intl.formatMessage({ id: 'quotationLabelIssuedBy', defaultMessage: 'Issued by' }),
                  sequence: 8,
                  colCode: 'issueBy',
                },

                {
                  colName: intl.formatMessage({ id: 'quotationLabelIssuedDate', defaultMessage: 'Issued Date' }),
                  sequence: 9,
                  colCode: 'issueDateTxt',
                },
              ],
      };

      const response = await exportBL(payload);
      console.log('exportBL', response);
      if (response.data.status.code === 200) {
        window.open(response.data.data.url);
        // successNotification(response.data.getUrl.message);
      } else {
        // errorNotification(response.data.getUrl.message);
      }
    } catch (error) {}
  };

  const handleExportPending = async () => {
    try {
      const payload = {
        pageNumber: 1,
        limit: _.get(reportDataPending, 'quotationTotal'),
        orderBy: _.get(reportDataPending, 'fieldSort'),
        orderType: _.get(reportDataPending, 'orderSort'),
        invoiceNo: _.get(reportDataPending, 'invoiceNo'),
        paymentTermNo: _.get(reportDataPending, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataPending, 'saleOrderNo'),
        customerId: _.get(reportDataPending, 'customerId'),
        statusCode: ['pending'],
        invoiceNo: '',
        paymentTermNo: '',
        saleOrderNo: '',
        customerId: _.get(reportDataPending, 'customerName') ? [_.get(reportDataPending, 'customerName')] : [],
        isCreateBilling: false,
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'invoiceLabelDocumentNo', defaultMessage: 'Document No.' }),
            sequence: 1,
            colCode: 'invoiceNo',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceLabelType', defaultMessage: 'Type' }),
            sequence: 2,
            colCode: 'type',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' }),
            sequence: 3,
            colCode: 'customerName',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelItem', defaultMessage: 'Item' }),
            sequence: 4,
            colCode: 'totalItem',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceLabelTotal', defaultMessage: 'Total' }),
            sequence: 5,
            colCode: 'totalTxt',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceLabelDueDate', defaultMessage: 'Due Date' }),
            sequence: 6,
            colCode: 'dueDateTxt',
          },
          {
            colName: intl.formatMessage({ id: 'quotationLabelIssuedBy', defaultMessage: 'Issued by' }),
            sequence: 7,
            colCode: 'issueBy',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceLabelTeam', defaultMessage: 'Team' }),
            sequence: 8,
            colCode: 'team',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceLabelIssued Date', defaultMessage: 'Issued Date' }),
            sequence: 9,
            colCode: 'issueDateTxt',
          },
        ],
      };

      const response = await exportFileBilling(payload);

      if (response.data.status.code === 200) {
        window.open(response.data.data.url);
        // successNotification(response.data.getUrl.message);
      } else {
        // errorNotification(response.data.getUrl.message);
      }
    } catch (error) {}
  };

  return (
    <AccountReceiveContext.Provider
      value={{
        state: {
          isLoadButton,
          isOpenQuotation,
          isFetch,
          openUpload,
          attachmentData,
          typeAttachment,
          defaultAttachment,
          reportDataPending,
          reportDataWaiting,
          reportDataPaid,
          reportDataCanceled,
          initialMemberList,
          initialTaxList,
          initialReasonList,
          initialCampaignList,
          record,
          visibleApprove,
          visibleReject,
          visibleCancel,
          visibleDelete,
          visibleWaiting,
          visiblePaid,
          visibleCash,
          summaryData,
          attachmentPaidData,
          paidData,
          visibleCancelPaid,
          cancelItemData,
          openUploadPaid,
          visibleBank,
          visibleCredit,
          visibleCreditNote,
          visibleCheque,
          visibleVoucher,
          typepatment,
          visibleSelectVoucher,
          tempVoucher,
          tempVoucherBrand,
          tempVoucherModel,
          tempVoucherItem,
          customerList,
          customerLoading,
          customerPage,
          signatureUser,
          visibleAddItem,
          pageTable,
          sizeTable,
          warehouseList,
          saleOrderQTY,
          recordList,
          viewDetail,
          totalWarehouseList,
          visibleViewInvoice,
          recordInvoice,
          signatureUserInvoice,
          recordReport,
          selectKeysTable,
          selectedRowKeys,
          listCreate,
          selectCustomer,
          tabKey,
          statusList,
          isOpenBillingTask,
          teamList,
        },
        setState: {
          setIsLoadButton,
          seIsOpenQuotation,
          setIsFetch,
          setOpenUpload,
          setAttachmentData,
          setTypeAttachment,
          setDefaultAttachment,
          setTabKey,
          setReportDataPending,
          setReportDataWaiting,
          setReportDataPaid,
          setReportDataCanceled,
          setRecord,
          setAttachmentPaidData,
          setPaidData,
          setVisibleCash,
          setVisibleCancelPaid,
          setOpenUploadPaid,
          setVisibleBank,
          setTypePayment,
          setVisibleSelectVoucher,
          setTempVoucher,
          setTempVoucherBrand,
          setTempVoucherModel,
          setTempVoucherItem,
          setCustomerPage,
          setCustomerLoading,
          setVisibleAddItem,
          setPageTable,
          setSizeTable,
          setWarehouseList,
          setSaleOrderQTY,
          setRecordList,
          setViewDetail,
          setTotalWarehouseList,
          setRecordReport,
          setSelectedRowKeys,
          setSelectAllRow,
          setSelectKeysTable,
          setListCreate,
          setSelectCustomer,
          setIsOpenBillingTask,
        },
        func: {
          handleOpenModalQuotation,
          handleSaveQuotation,
          handleOpenModalUpload,
          handleCancelQuotation,
          handleOpenModalUpload,
          handleOpenApprove,
          handleSaveApprove,
          handleCancelApprove,
          handleOpenReject,
          handleSaveReject,
          handleCancelReject,
          handleOpenCancel,
          handleSaveCancel,
          handleCancelCancelQT,
          handleOpenDelete,
          handleSaveDelete,
          handleCancelDelete,
          handleOpenWaiting,
          handleSaveWaiting,
          handleCancelWaiting,
          handleOpenPaid,
          handleCancelPaid,
          handleOpenCash,
          handleCancelCash,
          handleOpenCancelPaid,
          handleCloseCancelPaid,
          handleOpenModalUploadPaid,
          handleOpenBank,
          handleCancelBank,
          handleOpenCredit,
          handleCancelCredit,
          handleOpenCheque,
          handleCancelCheque,
          handleOpenVoucher,
          handleCancelVoucher,
          openVoucherListModal,
          handleCustomerPopupScroll,
          handleOpenAddItem,
          handleCancelAddItem,
          handleOpenModalQuotationPending,
          handleCleckSaleOrder,
          handleOpenModalQuotationInvoice,
          handleOpenViewInvoice,
          handleCancelViewInvoice,
          handleOpenModalQuotationCreate,
          handleExport,
          handleExportPending,
          handleSavePaid,
          handleOpenModalBillingTaskCreate,
          handleCancelBillingTask,
          handleOpenModalBillingTaskPending,
          handleOpenModalQuotationTask,
          handleOpenCreditNote,
          handleCancelCreditNote,
        },
      }}
    >
      {children}
    </AccountReceiveContext.Provider>
  );
};

export { AccountReceiveContext, SaleOrderProvider };
