import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/getFiles`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const uploadAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/uploadFile`, payload);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const upDateAttachment = async (payload) => {
  try {
    const response = await httpClient.put(`/v3/project/manager/${comCode}/update`, payload);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const deleteAttachment = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/project/manager/${comCode}/remove`, payload);
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getPricingList = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/referencelist`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createPricingList = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/reference/project-saleorder`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const editPricingList = async (payload) => {
  try {
    const response = await httpClient.put(`/reference/manager/company/${comCode}/web/project-config`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deletePricingList = async (Id) => {
  try {
    const response = await httpClient.delete(`/reference/manager/company/${comCode}/web/reference/${Id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const exportPricingList = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/referencelist/export`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getDestinationPricing = async (payload) => {
  try {
    const response = await httpClient.post(`/v5/business-partner/manager/company/${comCode}/web/customer-address/search`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

const getWeightPricing = async (payload) => {
  try {
    const response = await httpClient.post(`/reference/manager/company/${comCode}/web/project-weight-cond`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export {
  getAttachment,
  uploadAttachment,
  upDateAttachment,
  deleteAttachment,
  getPricingList,
  createPricingList,
  editPricingList,
  deletePricingList,
  exportPricingList,
  getDestinationPricing,
  getWeightPricing
}