import { Col, DatePicker, Divider, Form, Row, Select, Input, Icon, Badge } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
// import ModalCustomerQuotationForm from '....//select-customer/customer-modal';
import _ from 'lodash';
import moment from 'moment';

const { Option } = Select;
const { Search } = Input;
const dateFormat = 'ddd, MMM DD YYYY';

const CustomerFormView = ({ dataForm, form, record }) => {
  const intl = useIntl();
  const memComId = localStorage.getItem('memComId');
  const { getFieldDecorator } = form;

  return (
    <Form name="customer" colon={false}>
      {/* <ModalCustomerQuotationForm visible={isOpen} onCancel={handleCancel} onOk={handleSave} setDataForm={setDataForm} dataForm={dataForm} /> */}

      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />}
            className="form-line-height"
          >
            {/* {_.get(dataForm, 'customerName') || '-'} */}

            {getFieldDecorator('by', {
              initialValue: _.get(record, 'issueBy') || memComId,
            })}

            <div className="text-group-customer">
              <div className="text-location-short">
                <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerName')}</span>
              </div>
              <div className="text-location-short">
                {/* <span style={{ color: '#E74C3C' }}>{`Over Credit Limit (${'20,000'} THB)`}</span> */}
              </div>
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerAddressBookId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span
                    title={`${_.get(dataForm, 'address')}} ${(<Badge color="#000000" className="badge-text" />)} ${_.get(
                      dataForm,
                      'fullAddress'
                    )}`}
                    style={{ color: '#050505' }}
                  >
                    {_.get(dataForm, 'address') || '-'} - {/* <Badge color="#000000" className="badge-text" /> */}{' '}
                    {_.get(dataForm, 'fullAddress') || '-'}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })} {_.get(dataForm, 'customerTaxNo') || '-'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>{intl.formatMessage({ id: 'quotationLabelTaxNo', defaultMessage: 'Tax No.' })}</span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })} req={false} />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'customerContactId') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerContactName')}</span>
                </div>
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>
                    {_.get(dataForm, 'customerContactPhone')}, {_.get(dataForm, 'customerContactEmail')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelCustomerContact', defaultMessage: 'Contact' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'quotationLabelPhone', defaultMessage: 'Email' })},{' '}
                    {intl.formatMessage({ id: 'quotationLabelPhone', defaultMessage: 'Phone,' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider type="horizontal" style={{ marginBottom: '10px' }} />
      <Row gutter={[24]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelIssueBy', defaultMessage: 'Issue by' })} req={false} />}
          >
            {getFieldDecorator('customerName', {
              initialValue: _.get(record, 'customerName') || '',
            })}
            {_.get(dataForm, 'issuedName') || '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={intl.formatMessage({ id: 'quotationLabelIssueDate', defaultMessage: 'Issue Date' })}>
            {getFieldDecorator('date', {
              initialValue: _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD') : moment(new Date()),
            })}
            {_.get(dataForm, 'issueDate') ? moment(_.get(dataForm, 'issueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'quotationLabelReferenceNo', defaultMessage: 'Reference No.' })}
                req={false}
              />
            }
          >
            {getFieldDecorator('refNo', {
              initialValue: _.get(record, 'referenceNo') || '',
            })}
            {_.get(dataForm, 'referenceNo') || '-'}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CustomerFormView.defaultProps = {};

export default CustomerFormView;
