import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card, Modal, notification, Icon, Row, Col, Button, Dropdown, Menu } from 'antd';
import Provider from './provider';
import { useIntl, FormattedMessage } from 'react-intl';

import Details from './details-new';
import Member from './member/index-new';
import Shift from './shift/view/index';
import ShiftV1 from './shift-v1/index';
import ShiftNew from './shift-v1/index_New';
import Services from './services/index';
import cssStyle from './css/index.css';
import { Tabs } from 'antd';
import { PageSettings } from '../../config/page-settings';
import ShiftSetting from './shift-setting/index';
import styled from 'styled-components';
import { OrganizationContext } from './organization-context';
import { useQuery } from '../../helpers/general-helper';
import _ from 'lodash';
import httpClient from '../../components/axiosClient';
import Button01 from '../../components/v2/button_01';
import Button02 from '../../components/v2/button_02';
import Button04 from '../../components/v2/button_04';
import Button07 from '../../components/v2/button_07';
import ShiftV2 from './shift-v2/index';
import { StickyContainer, Sticky } from 'react-sticky';
import { DownOutlined } from '@ant-design/icons';
import ButtonActionGroup from '../../components/action-button';
import Button_01 from '../../components/v2/button_01';
import ModalDashboard from '../../components/modal-dashboard';

const { TabPane } = Tabs;

const StyleCard = styled(Card)`
  .ant-card-body {
    padding: 0px;
    height: 100%;
  }
`;

export default (props) => {
  const { handleVisibleMenuTree, statusMenuTree } = props;
  const query = useQuery();
  const app = Provider.useAppContext();
  const intl = useIntl();
  const { stateMain, setStateMain, fncMain } = useContext(OrganizationContext);
  const { checkPermissionAction } = useContext(PageSettings);
  const [key, setKey] = useState(query.get('tab') ? query.get('tab') : undefined);
  const [tabsDisabled, setTabsDisabled] = useState(false);
  const [sendDetailBtn, setSendDetailBtn] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const [openDashboard, setOpenDashboard] = useState(false);

  console.log('stateMain', stateMain);

  const handleChangeTab = (key) => {
    setKey(key);
    setStateMain.setKeyDetail(key);
    setStateMain.setKeyTabsMain(key);
    setStateMain.setTriggerTabChange((curren) => !curren);
  };

  useEffect(() => {
    if (key != undefined && key != 'details' && stateMain.addNewOrganization === false) {
      setKey(key);
    } else {
      setKey('details');
    }
  }, [stateMain.orgId]);

  useEffect(() => {
    if (props.newForm) {
      setTabsDisabled(true);
    } else {
      setTabsDisabled(false);
    }
  }, [props.newForm]);

  const handleDelete = (contact) => {
    Modal.confirm({
      className: 'organizationTree-modal-confirm',
      icon: <Icon type="delete" style={{ color: '#FF0000' }} />,
      title: intl.formatMessage({ id: 'userMgntUsersDetailModalDelete', defaultMessage: 'Are you sure to delete ?' }),
      okText: intl.formatMessage({ id: 'btnYes', defaultMessage: 'Yes' }),
      cancelText: intl.formatMessage({ id: 'btnNo', defaultMessage: 'No' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        try {
          const response = await httpClient.delete(`/resource/manager/company/${stateMain.comId}/delete/organization/${stateMain.orgId}`);
          if (response.status === 200) {
            deletedNotificationSuccess(response.data.message);
            setStateMain.setAddNewOrganization(true);
            setStateMain.setOrgId();
            setStateMain.setParentId();
            setStateMain.setPageDefault(false);
            fncMain.getRefresh();
          }
        } catch (error) {
          deletedNotificationError(error.response.data.message);
        }
      },
      onCancel() {},
    });
  };

  const deletedNotificationSuccess = (message) => {
    notification.success({
      message: message,
    });
  };

  const deletedNotificationError = (message) => {
    notification.error({
      message: message,
    });
  };

  const handleRenderTopBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <Row>
          <Col span={1} className="col-button-hide">
            <Button className="button-disabled-summary" style={{ border: 'unset' }} onClick={handleVisibleMenuTree}>
              <Icon type={statusMenuTree ? 'menu-fold' : 'menu-unfold'} style={{ color: '#e73845' }} />
            </Button>
          </Col>
          <Col span={23}>
            <DefaultTabBar {...props} style={{ ...style, zIndex: 1, background: '#fff' }} />
          </Col>
        </Row>
      )}
    </Sticky>
  );

  const menuColumn = (
    <Menu>
      {checkPermissionAction('P3PG1C2', 'P3PG1C2A3') ? (
        <Menu.Item key="1">
          <Button
            ghost
            type="link"
            onClick={() => {
              setSendDetailBtn(true);
              setOpenDrop(!openDrop);
            }}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button>
        </Menu.Item>
      ) : null}

      {checkPermissionAction('P3PG1C1', 'P3PG1C1A3') ? (
        <Menu.Item key="2">
          <Button
            ghost
            type="link"
            onClick={() => {
              handleDelete();
              setOpenDrop(!openDrop);
            }}
          >
            <FormattedMessage id="orgTreeBtnDelete" defaultMessage="Delete" />
          </Button>
        </Menu.Item>
      ) : null}
    </Menu>
  );
  const operations = (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div style={{ paddingRight: '24px' }}>
          {checkPermissionAction('P3PG1C2', 'P3PG1C2A3') || checkPermissionAction('P3PG1C1', 'P3PG1C1A3') ? (
            <>
              <Button_01
                key="submit"
                type="primary"
                btnsize="wd_df"
                onClick={() => {
                  setOpenDashboard(true);
                }}
                style={{ padding: '0px' }}
              >
                <FormattedMessage id="btnDashboard" defaultMessage="Dashboard" />
              </Button_01>
              <ButtonActionGroup menu={menuColumn} />
            </>
          ) : // <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight" >

          null}
        </div>
      )}
    </PageSettings.Consumer>
  );


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P3PG1C1', 'P3PG1C1A1') &&
        (checkPermissionAction('P3PG1C2', 'P3PG1C2A1') ||
          checkPermissionAction('P3PG1C5', 'P3PG1C5A1') ||
          checkPermissionAction('P3PG1C6', 'P3PG1C6A1') ||
          checkPermissionAction('P3PG1C8', 'P3PG1C8A1') ||
          checkPermissionAction('P3PG1C7', 'P3PG1C7A1')) ? (
          <div
            style={{
              minHeight: 'calc(100vh - 112px)',
              padding: '0px',
              backgroundColor: '#ffffff',
              border: '1px solid #e8e8e8',
            }}
          >
            <StickyContainer>
              <Tabs
                className="organization-tabs"
                activeKey={key}
                onChange={handleChangeTab}
                size="large"
                tabBarExtraContent={key === 'details' ? operations : null}
                animated={false}
                renderTabBar={handleRenderTopBar}
              >
                {checkPermissionAction('P3PG1C2', 'P3PG1C2A1') ? (
                  <TabPane tab={<FormattedMessage id="orgTabDetail" defaultMessage="Detail" />} key="details">
                    <Details
                      keyTab={key}
                      newForm={props.newForm}
                      handleDelete={handleDelete}
                      sendDetailBtn={sendDetailBtn}
                      setSendDetailBtn={setSendDetailBtn}
                    />
                  </TabPane>
                ) : null}

                {checkPermissionAction('P3PG1C5', 'P3PG1C5A1') ? (
                  <TabPane tab={<FormattedMessage id="orgTabMember" defaultMessage="Member" />} key="member" disabled={tabsDisabled}>
                    {/* <Member keyTab={key} comId={stateMain.comId} orgId={stateMain.orgId} /> */}
                    <Member
                      keyTab={key}
                      orgId={stateMain.orgIdSelect}
                      setOrgId={setStateMain.setOrgIdSelect}
                      setOrgIdMain={setStateMain.setOrgId}
                      setExpandedKeys={setStateMain.setExpandedKeys}
                    />
                  </TabPane>
                ) : null}

                {checkPermissionAction('P3PG1C6', 'P3PG1C6A1') ? (
                  <TabPane tab={<FormattedMessage id="orgTabService" defaultMessage="Service" />} key="services" disabled={tabsDisabled}>
                    {/* <Services
                        keyTab={key}
                        comId={stateMain.comId}
                        orgId={stateMain.orgId}
                      /> */}
                    <Services keyTab={key} />
                  </TabPane>
                ) : null}

                {checkPermissionAction('P3PG1C8', 'P3PG1C8A1') && stateMain.checkApprover ? (
                  <TabPane
                    tab={<FormattedMessage id="orgTabShiftSetting" defaultMessage="Shift Setting" />}
                    key="shiftSetting"
                    disabled={tabsDisabled}
                  >
                    <ShiftSetting
                      keyTab={key}
                      // comId={stateMain.comId}
                      // orgId={stateMain.orgId}
                    />
                  </TabPane>
                ) : null}

                {checkPermissionAction('P3PG1C7', 'P3PG1C7A1') && (stateMain.checkApprover || stateMain.checkMember) ? (
                  <TabPane tab={<FormattedMessage id="orgTabShift" defaultMessage="Shift" />} key="shift" disabled={tabsDisabled}>
                    {/* <ShiftV2 comId={stateMain.comId} orgId={stateMain.orgId} orgDetail={stateMain.organizationsDetail} shiftType={stateMain.shiftsType} /> */}
                    <ShiftV2 />
                  </TabPane>
                ) : null}
              </Tabs>
            </StickyContainer>

            <ModalDashboard visible={openDashboard} setVisible={setOpenDashboard} orgId={stateMain.orgId} teamName={stateMain.teamName}/>
          </div>
        ) : null
      }
    </PageSettings.Consumer>
  );
};
