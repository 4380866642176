import React, { createContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import ModalAllowance from './components/modal';
import { getListAllowancePage, getAllowanceDetail, exportAllowanceResource } from '../../controllers/resource-allowance/allowance';
import moment from 'moment';
import { numberFormatter, numberParser } from '../../component-function/fnc-inputnumber-currency';
import { errorNotification, successNotification } from '../../components/v2/notification';
import ModalPDFViewverOptionalColumn from '../../component-function/pdf-viewver-optional-column';
import { UrlPDF } from '../../component-function/generator-pdf';
import { BLTaskPDFDownloader } from '../../controllers/billing-note/billingapi';
import { downloadPDFApi } from '../../controllers/pdf';
import { getInitialColumns } from '../../controllers/initial-column';

const AllowanceProvider = createContext();
const IGNORE_COLUMNS = ['project', 'startDate', 'dueDate', 'approveBy', 'approveDate', 'rejectBy', 'rejectDate'];
const langValue = localStorage.getItem('langValue');

const AllowanceContext = ({ children }) => {
  const intl = useIntl();
  const tab = [
    {
      name: intl.formatMessage({ id: `resourceAllowanceTitlePending`, defaultMessage: 'Pending' }),
      code: 'pending',
    },
    {
      name: intl.formatMessage({ id: `resourceAllowanceTitleWaiting`, defaultMessage: 'Waiting for Approval' }),
      code: 'waiting',
    },
    {
      name: intl.formatMessage({ id: `resourceAllowanceTitleApproved`, defaultMessage: 'Approved' }),
      code: 'approve',
    },
    {
      name: intl.formatMessage({ id: `resourceAllowanceTitlePaid`, defaultMessage: 'Paid' }),
      code: 'paid',
    },
    {
      name: intl.formatMessage({ id: `resourceAllowanceTitleRejected`, defaultMessage: 'Rejected' }),
      code: 'reject',
    },
  ];
  // const columnsNormal = {
  //   columns: [
  //     {
  //       title: '#',
  //       dataIndex: 'index',
  //       key: 'index',
  //       width: 70,
  //       align: 'center',
  //       fixed: 'left',
  //       // render: (text, record, index) => index + 1
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceNo', defaultMessage: 'Allowance No.' }),
  //       dataIndex: 'allowanceNo',
  //       key: 'allowanceNo',
  //       width: 200,
  //       fixed: 'left',
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceType', defaultMessage: 'Type' }),
  //       dataIndex: 'allowanceType',
  //       key: 'allowanceType',
  //       width: 150,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceTaskNo', defaultMessage: 'Task No.' }),
  //       dataIndex: 'taskNo',
  //       key: 'taskNo',
  //       width: 150,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceCustomer', defaultMessage: 'Customer' }),
  //       dataIndex: 'customerName',
  //       key: 'customerName',
  //       width: 150,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceProject', defaultMessage: 'Project' }),
  //       dataIndex: 'project',
  //       key: 'project',
  //       width: 150,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceTaskType', defaultMessage: 'Task Type' }),
  //       dataIndex: 'taskType',
  //       key: 'taskType',
  //       width: 150,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceSource', defaultMessage: 'Source' }),
  //       dataIndex: 'source',
  //       key: 'source',
  //       width: 400,
  //       sorter: false,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceDestination', defaultMessage: 'Destination' }),
  //       dataIndex: 'destination',
  //       key: 'destination',
  //       width: 400,
  //       sorter: false,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceStartDate', defaultMessage: 'Start Date' }),
  //       dataIndex: 'startDate',
  //       key: 'startDate',
  //       width: 200,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceDueDate', defaultMessage: 'Due Date' }),
  //       dataIndex: 'dueDate',
  //       key: 'dueDate',
  //       width: 200,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceDistance', defaultMessage: 'Distance (km)' }),
  //       dataIndex: 'distance',
  //       key: 'distance',
  //       width: 150,
  //       sorter: true,
  //       // render: (text, record, index) =>
  //       //   _.get(record, 'allowanceTypeCode') === 'tripallowance'
  //       //     ? '-'
  //       //     : numberFormatter((numberParser(_.get(record, 'distance', '0')) / 1000).toFixed(2)),
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceTotal', defaultMessage: 'Total' }),
  //       dataIndex: 'total',
  //       key: 'total',
  //       width: 150,
  //       sorter: true,
  //       align: 'right',
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceAssignee1', defaultMessage: 'Assignee 1' }),
  //       dataIndex: 'assignee1',
  //       key: 'assignee1',
  //       width: 200,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceAssignee2', defaultMessage: 'Assignee 2' }),
  //       dataIndex: 'assignee2',
  //       key: 'assignee2',
  //       width: 200,
  //       sorter: true,
  //     },
  //     {
  //       title: intl.formatMessage({ id: 'resourceAllowanceLicensePlate', defaultMessage: 'License Plate' }),
  //       dataIndex: 'licensePlate',
  //       key: 'licensePlate',
  //       width: 200,
  //       sorter: true,
  //     },
  //   ],
  //   setShowColumnArr: [
  //     'index',
  //     'allowanceNo',
  //     'allowanceType',
  //     'taskNo',
  //     'customerName',
  //     'project',
  //     'taskType',
  //     'source',
  //     'destination',
  //     'startDate',
  //     'dueDate',
  //     'distance',
  //     'total',
  //     'assignee1',
  //     'assignee2',
  //     'licensePlate',
  //   ],
  //   setShowColumn: {
  //     index: true,
  //     allowanceNo: true,
  //     allowanceType: true,
  //     taskNo: true,
  //     customerName: true,
  //     project: false,
  //     taskType: true,
  //     source: true,
  //     destination: true,
  //     startDate: false,
  //     dueDate: false,
  //     distance: true,
  //     total: true,
  //     assignee1: true,
  //     assignee2: true,
  //     licensePlate: true,
  //   },
  // };
  // const optionalColumns = {
  //   approved: {
  //     columns: [
  //       {
  //         title: intl.formatMessage({ id: 'resourceAllowanceApprovedBy', defaultMessage: 'Approved By' }),
  //         dataIndex: 'approveBy',
  //         key: 'approveBy',
  //         width: 200,
  //         sorter: true,
  //       },
  //       {
  //         title: intl.formatMessage({ id: 'resourceAllowanceApprovedDate', defaultMessage: 'Approved Date' }),
  //         dataIndex: 'approveDate',
  //         key: 'approveDate',
  //         width: 200,
  //         sorter: true,
  //       },
  //     ],
  //     setShowColumnArr: ['approveBy', 'approveDate'],
  //     setShowColumn: { approveBy: false, approveDate: false },
  //   },
  //   rejected: {
  //     columns: [
  //       {
  //         title: intl.formatMessage({ id: 'resourceAllowanceRejectReason', defaultMessage: 'Reject Reason' }),
  //         dataIndex: 'rejectReason',
  //         key: 'rejectReason',
  //         width: 200,
  //         sorter: true,
  //       },
  //       {
  //         title: intl.formatMessage({ id: 'resourceAllowanceRejectRemark', defaultMessage: 'Reject Remark' }),
  //         dataIndex: 'rejectRemark',
  //         key: 'rejectRemark',
  //         width: 300,
  //         sorter: false,
  //       },
  //       {
  //         title: intl.formatMessage({ id: 'resourceAllowanceRejectBy', defaultMessage: 'Reject By' }),
  //         dataIndex: 'rejectBy',
  //         key: 'rejectBy',
  //         width: 200,
  //         sorter: true,
  //       },
  //       {
  //         title: intl.formatMessage({ id: 'resourceAllowanceRejectDate', defaultMessage: 'Reject Date' }),
  //         dataIndex: 'rejectDate',
  //         key: 'rejectDate',
  //         width: 200,
  //         sorter: true,
  //       },
  //     ],
  //     setShowColumnArr: ['rejectReason', 'rejectRemark', 'rejectBy', 'rejectDate'],
  //     setShowColumn: {
  //       rejectReason: true,
  //       rejectRemark: true,
  //       rejectBy: false,
  //       rejectDate: false,
  //     },
  //   },
  // };

  const [columnNormal2, setColumnNormal2] = useState({
    columns: [],
    setShowColumnArr: [],
    setShowColumn: {},
  });

  const [columnWidthMaximum, setColumnWidthMaximum] = useState(0);
  const [optionalColumns2, setOptionalColumns2] = useState({
    approved: {
      columns: [],
      setShowColumnArr: [],
      setShowColumn: {},
    },
    rejected: {
      columns: [],
      setShowColumnArr: [],
      setShowColumn: {},
    },
  });

  // const ignoreColumn = ['project', 'startDate', 'dueDate', 'approveBy', 'approveDate', 'rejectBy', 'rejectDate'];
  // const newColumns = columnNormal2.columns.filter((col) => !ignoreColumn.includes(col.key));
  // // const newListArray = columnsNormal.setShowColumnArr.filter((col) => col !== ignoreColumn);
  // const newListArray = _.difference(columnNormal2.setShowColumnArr, ignoreColumn);
  const [tabValue, setTabValue] = useState('pending');
  // const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  // const [actionColumns, setActionColumns] = useState([...columnNormal2.columns]);
  // const [listArrayColumns, setListArrayColumns] = useState([...newListArray]);
  // const [defaultShowColumn, setDefaultShowColumn] = useState({ ...columnNormal2.setShowColumn });
  const [datasource, setDatasource] = useState([]);
  const [searchGroup, setSearchGroup] = useState({});
  const [tableChange, setTableChange] = useState({ page: 1, pageSize: 10, orderBy: null, orderField: null });
  const [trigger, setTrigger] = useState(false);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [editData, setEditData] = useState();
  const [itemType, setItemType] = useState();
  const [taskType, setTaskType] = useState();
  const [allowancType, setAllowancType] = useState([]);
  const [rejectList, setRejectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [openPdf, setOpenPdf] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const columnIndex = 10;

  const mapColumnTable = ({ cols }) => {
    const mapColumn = _.map(cols, (o) => {
      const notShow = _.includes(
        ['rejectDate', 'rejectBy', 'rejectRemark', 'rejectReason', 'approveDate', 'approveBy', 'project', 'startDate', 'dueDate'],
        o.columnCode
      )
        ? false
        : true;
      return {
        ...o,
        // title: langValue === 'EN' ? o.columnName.en : o.columnName.th,
        title: <FormattedMessage id={o.columnLanguageCode} defaultMessage={o.columnName.en}/>,
        dataIndex: o.columnMapping,
        key: o.columnMapping,
        width: o.columnWidth.web,
        widthPdf: o.columnWidth.pdf,
        align: o.columnAlign,
        fixed: o.columnFixed,
        sorter: o.columnSorter,
        default: notShow,
      };
    });

    const rejectKeys = ['rejectDate', 'rejectBy', 'rejectRemark', 'rejectReason', 'approveDate', 'approveBy'];
    const filteredColumns = _.filter(mapColumn, (column) => !_.includes(rejectKeys, column.key));
    const mapKeyColumns = _.map(filteredColumns, (column) => column.columnCode);
    const mapShowColumn = _.reduce(
      filteredColumns,
      (obj, item) => {
        obj[item.dataIndex] = item.default;
        return obj;
      },
      {}
    );
    return { col: filteredColumns, keyShowArr: mapKeyColumns, keyShow: mapShowColumn };
  };

  const mapOptionalColumnTable = ({ cols }) => {
    const approvedKeys = ['approveBy', 'approveDate'];
    const rejectedKeys = ['rejectReason', 'rejectRemark', 'rejectBy', 'rejectDate'];
    const approvedColumns = cols
      .filter((col) => approvedKeys.includes(col.columnCode))
      .map((o) => ({
        // title: langValue === 'EN' ? o.columnName.en : o.columnName.th,
        title: <FormattedMessage id={o.columnLanguageCode} defaultMessage={o.columnName.en}/>,
        dataIndex: o.columnMapping,
        key: o.columnMapping,
        width: o.columnWidth.web,
        widthPdf: o.columnWidth.pdf,
        align: o.columnAlign,
        fixed: o.columnFixed,
        sorter: o.columnSorter,
      }));

    const rejectedColumns = cols
      .filter((col) => rejectedKeys.includes(col.columnCode))
      .map((o) => ({
        // title: langValue === 'EN' ? o.columnName.en : o.columnName.th,
        title: <FormattedMessage id={o.columnLanguageCode} defaultMessage={o.columnName.en}/>,
        dataIndex: o.columnMapping,
        key: o.columnMapping,
        width: o.columnWidth.web,
        widthPdf: o.columnWidth.pdf,
        align: o.columnAlign,
        fixed: o.columnFixed,
        sorter: o.columnSorter,
      }));

    const optionalColumns = {
      approved: {
        columns: approvedColumns,
        setShowColumnArr: approvedKeys,
        setShowColumn: { approveBy: false, approveDate: false },
      },
      rejected: {
        columns: rejectedColumns,
        setShowColumnArr: rejectedKeys,
        setShowColumn: {
          rejectReason: true,
          rejectRemark: true,
          rejectBy: false,
          rejectDate: false,
        },
      },
    };

    return optionalColumns;
  };

  // Compute the filtered columns based on the ignore list
  const newColumns = useMemo(() => {
    return columnNormal2.columns.filter((col) => !IGNORE_COLUMNS.includes(col.key));
  }, [columnNormal2.columns]);

  // Compute the list array from the setShowColumnArr by excluding the ignored keys
  const newListArray = useMemo(() => {
    return _.difference(columnNormal2.setShowColumnArr, IGNORE_COLUMNS);
  }, [columnNormal2.setShowColumnArr]);

  // Initialize state with computed values
  const [newDataColumns, setNewDataColumns] = useState(newColumns);
  const [actionColumns, setActionColumns] = useState(columnNormal2.columns);
  const [listArrayColumns, setListArrayColumns] = useState(newListArray);
  const [defaultShowColumn, setDefaultShowColumn] = useState(columnNormal2.setShowColumn);

  // Update states when columnNormal2 changes
  useEffect(() => {
    setNewDataColumns(newColumns);
    setActionColumns(columnNormal2.columns);
    setListArrayColumns(newListArray);
    setDefaultShowColumn(columnNormal2.setShowColumn);
  }, [columnNormal2, newColumns, newListArray]);

  useEffect(() => {
    const getApi = async () => {
      const response = await getInitialColumns({ tableCode: 'resourceAllowance' });
      console.log('getInitialColumns', response);
      const getColumns = _.get(response, 'data.data.tableConfig.columns');
      const getColumnWidthMaximum = _.get(response, 'data.data.tableConfig.columnWidthMaximum');
      const { col, keyShowArr, keyShow } = mapColumnTable({ cols: getColumns });
      const { approved, rejected } = mapOptionalColumnTable({ cols: getColumns });
      setColumnNormal2({ columns: col, setShowColumnArr: keyShowArr, setShowColumn: keyShow });
      setOptionalColumns2({ approved: approved, rejected: rejected });
      setColumnWidthMaximum(getColumnWidthMaximum);
    };
    // columnWidthMaximum
    getApi();
  }, []);

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      const payload = {
        tabCode: tabValue,
        allowanceNo: _.get(searchGroup, 'allowanceNo') ? _.get(searchGroup, 'allowanceNo') : null,
        allowanceTypeCodeList: _.get(searchGroup, 'allowanceTypeCodeList') ? [_.get(searchGroup, 'allowanceTypeCodeList')] : [],
        taskNo: _.get(searchGroup, 'taskNo') ? _.get(searchGroup, 'taskNo') : null,
        customerName: _.get(searchGroup, 'customerName') ? _.get(searchGroup, 'customerName') : null,
        projectId: _.get(searchGroup, 'project') ? [_.get(searchGroup, 'project')] : [],
        taskTypeId: _.get(searchGroup, 'taskTypeId') ? [_.get(searchGroup, 'taskTypeId')] : [],
        sourceId: _.get(searchGroup, 'source') ? [_.get(searchGroup, 'source')] : [],
        // destinationId: _.get(searchGroup,'destinationId') ? _.get(searchGroup,'destinationId') :  [],
        // distanceFrom: _.get(searchGroup,'distanceFrom') ? _.get(searchGroup,'distanceFrom') :  null,
        // distanceTo: _.get(searchGroup,'distanceTo') ? _.get(searchGroup,'distanceTo') :  null,
        startDate: _.get(searchGroup, 'startDate') ? moment(_.get(searchGroup, 'startDate')).format('YYYY-MM-DD') : null,
        endDate: _.get(searchGroup, 'endDate') ? moment(_.get(searchGroup, 'endDate')).format('YYYY-MM-DD') : null,
        pageNumber: _.get(tableChange, 'page'),
        limit: _.get(tableChange, 'pageSize'),
        orderBy: _.get(tableChange, 'orderField') ? _.get(tableChange, 'orderField') : null,
        orderType: _.get(tableChange, 'orderBy') ? _.get(tableChange, 'orderBy') : null,

        licensePlate: _.get(searchGroup, 'licensePlate') ? _.get(searchGroup, 'licensePlate') : null,
        assignee1: _.get(searchGroup, 'assignee1') ? _.get(searchGroup, 'assignee1') : null,
        assignee2: _.get(searchGroup, 'assignee2') ? _.get(searchGroup, 'assignee2') : null,
      };

      const response = await getListAllowancePage(payload);
      if (_.get(response, 'status.code') === 200) {
        setDatasource(_.get(response, 'data.allowanceList'));
        setTotal(_.get(response, 'data.totalItem'));
        setItemType(_.get(response, 'data.allowanceTypeList'));
        setTaskType(_.get(response, 'data.taskTypeList'));
        setAllowancType(_.get(response, 'data.allowanceItemType'));
        setRejectList(_.get(response, 'data.reasonRejectList'));
        setLoading(false);
      }
    };

    if (tabValue) {
      getApi();
    }
  }, [tabValue, trigger, tableChange]);

  console.log(columnNormal2, '1test_columnNormal2');
  console.log(columnWidthMaximum, '2test_columnWidthMaximum');
  console.log(optionalColumns2, '3test_optionalColumns2');
  console.log(urlPdf, '4test_urlPdf');
  console.log(newColumns, '5test_newColumns');
  console.log(newListArray, '6test_newListArray');
  console.log(newDataColumns, '7test_newDataColumns');
  console.log(actionColumns, '8test_actionColumns');
  console.log(listArrayColumns, '9test_listArrayColumns');
  console.log(defaultShowColumn, '10test_defaultShowColumn');
  console.log(columnNormal2.columns, '11test_columnNormal2.columns');
  console.log(columnNormal2.setShowColumn, '12test_columnNormal2.setShowColumn');
  console.log(columnNormal2.setShowColumnArr, '13test_columnNormal2.setShowColumnArr');

  const handleTabChange = (value) => {
    setVisible(false);
    setTabValue(value);
    columnSet(value);
    setTableChange({ ...tableChange, page: 1 });
  };

  const columnSet = (type) => {
    if (type !== 'pending') {
      const ignoreColumn = ['project', 'startDate', 'dueDate', 'approveBy', 'approveDate', 'rejectBy', 'rejectDate', 'distance'];
      const newListArrayNP = _.difference(columnNormal2.setShowColumnArr, ignoreColumn);
      const newColumnsrAll = columnNormal2.columns.filter((col) => !ignoreColumn.includes(col.key));
      setNewDataColumns(newColumnsrAll);
      setActionColumns(columnNormal2.columns);
      setListArrayColumns(newListArrayNP);
      setDefaultShowColumn({ ...columnNormal2.setShowColumn, distance: false });
      if (type === 'approve') {
        const ignoreColumn = ['rejectBy', 'rejectDate'];
        const tempcolumn = _.get(optionalColumns2, 'approved.columns');
        const newColumnsApp = tempcolumn.filter((col) => !ignoreColumn.includes(col.key));
        setActionColumns(columnNormal2.columns.concat(_.get(optionalColumns2, 'approved.columns')));
        setDefaultShowColumn({ ...columnNormal2.setShowColumn, ..._.get(optionalColumns2, 'approved.setShowColumn'), distance: false });
      } else if (type === 'reject') {
        const ignoreColumn = ['rejectBy', 'rejectDate'];
        const defaultColumn = ['rejectReason', 'rejectRemark'];
        const tempcolumn = _.get(optionalColumns2, 'rejected.columns');
        const newColumnsreject = tempcolumn.filter((col) => !ignoreColumn.includes(col.key));
        setNewDataColumns(newColumnsrAll.concat(newColumnsreject));
        setActionColumns(columnNormal2.columns.concat(_.get(optionalColumns2, 'rejected.columns')));
        setListArrayColumns(newListArrayNP.concat(defaultColumn));
        setDefaultShowColumn({ ...columnNormal2.setShowColumn, ..._.get(optionalColumns2, 'rejected.setShowColumn'), distance: false });
      }
    } else {
      setNewDataColumns(newColumns);
      setActionColumns(columnNormal2.columns);
      setListArrayColumns(newListArray);
      setDefaultShowColumn(columnNormal2.setShowColumn);
    }
  };

  const handleOpenModal = async (data) => {
    const getDetail = await getAllowanceDetail(_.get(data, 'allowanceId'));
    if (_.get(getDetail, 'status') === 200) {
      setEditData(_.get(getDetail, 'data.data'));
      setVisibleModal(true);
    }
  };

  const handleTableChange = (pagination, sorter, extra) => {
    let field = extra.field;

    if (extra.order) {
      if (extra.order === 'descend') {
        setTableChange((prev) => ({ ...prev, orderBy: 'desc' }));
      } else {
        setTableChange((prev) => ({ ...prev, orderBy: 'asc' }));
      }
    } else {
      setTableChange((prev) => ({ ...prev, orderBy: '' }));
    }
    setTableChange((prev) => ({ ...prev, orderField: field, page: pagination.current, pageSize: pagination.pageSize }));
  };

  const newDataColumnsExport = _.chain(actionColumns)
    .filter((o) => o.dataIndex !== 'index')
    .map((o, index) => ({
      colName: intl.formatMessage({ id: o.title?.props?.id, defaultMessage: o.title?.props?.defaultMessage }) || o.title,
      sequence: index + 1,
      colCode: o.dataIndex,
    }))
    .value();

  console.log('actionColumns handleExport', actionColumns, newDataColumns, newDataColumnsExport);

  const handleExport = async () => {
    try {
      let NewFormatExport = newDataColumnsExport;
      // const defaultFormat = [
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceNo', defaultMessage: 'Allowance NO.' }),
      //     sequence: 1,
      //     colCode: 'allowanceNo',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceType', defaultMessage: 'Type' }),
      //     sequence: 2,
      //     colCode: 'allowanceType',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceTaskNo', defaultMessage: 'Task No.' }),
      //     sequence: 3,
      //     colCode: 'taskNo',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceCustomer', defaultMessage: 'Customer' }),
      //     sequence: 4,
      //     colCode: 'customerName',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceProject', defaultMessage: 'Project' }),
      //     sequence: 5,
      //     colCode: 'project',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceTaskType', defaultMessage: 'Task Type' }),
      //     sequence: 6,
      //     colCode: 'taskType',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceSource', defaultMessage: 'Source' }),
      //     sequence: 7,
      //     colCode: 'source',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceDestination', defaultMessage: 'Destination' }),
      //     sequence: 8,
      //     colCode: 'destination',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceStartDate', defaultMessage: 'Start Date' }),
      //     sequence: 9,
      //     colCode: 'startDate',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceDueDate', defaultMessage: 'Due Date' }),
      //     sequence: 10,
      //     colCode: 'dueDate',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceDistance', defaultMessage: 'Distance (km)' }),
      //     sequence: 11,
      //     colCode: 'distance',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceTotal', defaultMessage: 'Total' }),
      //     sequence: 12,
      //     colCode: 'total',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceAssignee1', defaultMessage: 'Assignee 1' }),
      //     sequence: 13,
      //     colCode: 'assignee1',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceAssignee2', defaultMessage: 'Assignee 2' }),
      //     sequence: 14,
      //     colCode: 'assignee2',
      //   },
      //   {
      //     colName: intl.formatMessage({ id: 'resourceAllowanceLicensePlate', defaultMessage: 'License Plate' }),
      //     sequence: 15,
      //     colCode: 'licensePlate',
      //   },
      // ];
      // if (tabValue === 'approve') {
      //   NewFormatExport = _.concat(defaultFormat, [
      //     {
      //       colName: intl.formatMessage({ id: 'resourceAllowanceApprovedBy', defaultMessage: 'Approved By' }),
      //       sequence: 13,
      //       colCode: 'approveBy',
      //     },
      //     {
      //       colName: intl.formatMessage({ id: 'resourceAllowanceApprovedDate', defaultMessage: 'Approved Date' }),
      //       sequence: 14,
      //       colCode: 'approveDate',
      //     },
      //   ]);
      // } else if (tabValue === 'reject') {
      //   NewFormatExport = _.concat(defaultFormat, [
      //     {
      //       colName: intl.formatMessage({ id: 'resourceAllowanceRejectReason', defaultMessage: 'Reject Reason' }),
      //       sequence: 13,
      //       colCode: 'rejectReason',
      //     },
      //     {
      //       colName: intl.formatMessage({ id: 'resourceAllowanceRejectRemark', defaultMessage: 'Reject Remark' }),
      //       sequence: 14,
      //       colCode: 'rejectRemark',
      //     },
      //     {
      //       colName: intl.formatMessage({ id: 'resourceAllowanceRejectBy', defaultMessage: 'Reject By' }),
      //       sequence: 15,
      //       colCode: 'rejectBy',
      //     },
      //     {
      //       colName: intl.formatMessage({ id: 'resourceAllowanceRejectDate', defaultMessage: 'Reject Date' }),
      //       sequence: 16,
      //       colCode: 'rejectDate',
      //     },
      //   ]);
      // }

      const body = {
        tabCode: tabValue,
        allowanceNo: _.get(searchGroup, 'allowanceNo') ? _.get(searchGroup, 'allowanceNo') : null,
        allowanceTypeCodeList: _.get(searchGroup, 'allowanceTypeCodeList') ? [_.get(searchGroup, 'allowanceTypeCodeList')] : [],
        taskNo: _.get(searchGroup, 'taskNo') ? _.get(searchGroup, 'taskNo') : null,
        customerName: _.get(searchGroup, 'customerName') ? _.get(searchGroup, 'customerName') : null,
        projectId: _.get(searchGroup, 'project') ? [_.get(searchGroup, 'project')] : [],
        taskTypeId: _.get(searchGroup, 'taskTypeId') ? [_.get(searchGroup, 'taskTypeId')] : [],
        sourceId: _.get(searchGroup, 'source') ? [_.get(searchGroup, 'source')] : [],
        startDate: _.get(searchGroup, 'startDate') ? moment(_.get(searchGroup, 'startDate')).format('YYYY-MM-DD') : null,
        endDate: _.get(searchGroup, 'endDate') ? moment(_.get(searchGroup, 'endDate')).format('YYYY-MM-DD') : null,
        pageNumber: 1,
        limit: total,
        orderBy: _.get(tableChange, 'orderField') ? _.get(tableChange, 'orderField') : null,
        orderType: _.get(tableChange, 'orderBy') ? _.get(tableChange, 'orderBy') : null,

        viewFormat: _.size(NewFormatExport) > 0 ? NewFormatExport : NewFormatExport,
      };
      const response = await exportAllowanceResource(body);

      if (response.data.status.code === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {}
  };

  console.log('actionColumnsxxxxx', actionColumns);

  //คอลัมแบบเก่าต้องหา default แบบใหม่เอาไปใช้ได้เลย
  const getColumnsWithVisibility = () => {
    const findKeyIndex = _.filter(actionColumns, (o) => o.key !== 'index');

    return findKeyIndex.map((col) => ({
      ...col,
      default: defaultShowColumn[col.key] || false,
    }));
  };

  //เรียก  pdf ครั้งแรก
  useEffect(() => {
    setLoadingPdf(true);

    const getApi = async () => {
      const mapColumnChecked = _.chain(getColumnsWithVisibility())
        .filter((o) => o.default !== false)
        .map((o) => o.dataIndex)
        .value();

      let mapColumnCheckedAdd = [...mapColumnChecked];
      const body = {
        filter: {
          tabCode: tabValue,
          allowanceNo: _.get(searchGroup, 'allowanceNo') ? _.get(searchGroup, 'allowanceNo') : null,
          allowanceTypeCodeList: _.get(searchGroup, 'allowanceTypeCodeList') ? [_.get(searchGroup, 'allowanceTypeCodeList')] : [],
          taskNo: _.get(searchGroup, 'taskNo') ? _.get(searchGroup, 'taskNo') : null,
          customerName: _.get(searchGroup, 'customerName') ? _.get(searchGroup, 'customerName') : null,
          projectId: _.get(searchGroup, 'project') ? [_.get(searchGroup, 'project')] : [],
          taskTypeId: _.get(searchGroup, 'taskTypeId') ? [_.get(searchGroup, 'taskTypeId')] : [],
          sourceId: _.get(searchGroup, 'source') ? [_.get(searchGroup, 'source')] : [],
          startDate: _.get(searchGroup, 'startDate') ? moment(_.get(searchGroup, 'startDate')).format('YYYY-MM-DD') : null,
          endDate: _.get(searchGroup, 'endDate') ? moment(_.get(searchGroup, 'endDate')).format('YYYY-MM-DD') : null,
          pageNumber: 1,
          limit: 10000,
          orderBy: _.get(tableChange, 'orderField') ? _.get(tableChange, 'orderField') : null,
          orderType: _.get(tableChange, 'orderBy') ? _.get(tableChange, 'orderBy') : null,
        },
        printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
      };

      console.log('handleApply body', body);

      const response = await downloadPDFApi({ tableCode: 'resourceAllowance', body: body });
      console.log('handleApply response', response);

      if (_.get(response, 'status') === 200) {
        const url = await UrlPDF(response);
        console.log('handleApply url', url);

        if (url) {
          setUrlPdf(url);
          setLoadingPdf(false);
        }
      } else {
        setUrlPdf(null);
        setLoadingPdf(false);
      }
    };

    if (openPdf) {
      getApi();
    }
  }, [openPdf]);

  //เปิด pdf
  const handlePrintPdf = () => {
    setOpenPdf(true);
  };

  //apply column pdf
  const handleApply = async (columnChecked) => {
    setLoadingPdf(true);
    const mapColumnChecked = _.chain(columnChecked)
      .filter((o) => o.default !== false)
      .map((o) => o.dataIndex)
      .value();

    let mapColumnCheckedAdd = [...mapColumnChecked];
    const body = {
      filter: {
        tabCode: tabValue,
        allowanceNo: _.get(searchGroup, 'allowanceNo') ? _.get(searchGroup, 'allowanceNo') : null,
        allowanceTypeCodeList: _.get(searchGroup, 'allowanceTypeCodeList') ? [_.get(searchGroup, 'allowanceTypeCodeList')] : [],
        taskNo: _.get(searchGroup, 'taskNo') ? _.get(searchGroup, 'taskNo') : null,
        customerName: _.get(searchGroup, 'customerName') ? _.get(searchGroup, 'customerName') : null,
        projectId: _.get(searchGroup, 'project') ? [_.get(searchGroup, 'project')] : [],
        taskTypeId: _.get(searchGroup, 'taskTypeId') ? [_.get(searchGroup, 'taskTypeId')] : [],
        sourceId: _.get(searchGroup, 'source') ? [_.get(searchGroup, 'source')] : [],
        startDate: _.get(searchGroup, 'startDate') ? moment(_.get(searchGroup, 'startDate')).format('YYYY-MM-DD') : null,
        endDate: _.get(searchGroup, 'endDate') ? moment(_.get(searchGroup, 'endDate')).format('YYYY-MM-DD') : null,
        pageNumber: 1,
        limit: 10000,
        orderBy: _.get(tableChange, 'orderField') ? _.get(tableChange, 'orderField') : null,
        orderType: _.get(tableChange, 'orderBy') ? _.get(tableChange, 'orderBy') : null,
      },
      printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
    };

    console.log('handleApply body', body);

    const response = await downloadPDFApi({ tableCode: 'resourceAllowance', body: body });
    console.log('handleApply response', response);

    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      console.log('handleApply url', url);

      if (url) {
        setUrlPdf(url);
        setLoadingPdf(false);
      }
    } else {
      setUrlPdf(null);
      setLoadingPdf(false);
    }
  };

  return (
    <AllowanceProvider.Provider
      value={{
        state: {
          columnsNormal: columnNormal2,
          tab,
          tabValue,
          newDataColumns,
          datasource,
          searchGroup,
          tableChange,
          listArrayColumns,
          defaultShowColumn,
          newColumns,
          textErrorSelectColumn,
          visible,
          actionColumns,
          itemType,
          taskType,
          loading,
          total,
          openPdf,
        },
        setState: {
          setSearchGroup,
          setTableChange,
          setTrigger,
          setNewDataColumns,
          setListArrayColumns,
          setDefaultShowColumn,
          setTextErrorSelectColumn,
          setVisible,
          setOpenPdf,
        },
        fnc: {
          handleTabChange,
          handleOpenModal,
          handleTableChange,
          handleExport,
          handlePrintPdf,
        },
      }}
    >
      {children}

      <ModalAllowance
        visible={visibleModal}
        setVisible={setVisibleModal}
        data={editData}
        setData={setEditData}
        allowancType={allowancType}
        setTrigger={setTrigger}
        rejectList={rejectList}
        setTableChange={setTableChange}
        tableChange={tableChange}
        total={total}
      />

      <ModalPDFViewverOptionalColumn
        paperSize={columnWidthMaximum}
        paperIndexSize={columnIndex}
        paperUnit={intl.formatMessage({ id: 'unitCm', defaultMessage: 'cm.' })}
        visible={openPdf}
        url={urlPdf}
        loading={loadingPdf}
        setVisible={setOpenPdf}
        handleApply={handleApply}
        column={getColumnsWithVisibility()} //actionColumn
      />
    </AllowanceProvider.Provider>
  );
};

export { AllowanceProvider, AllowanceContext };
