import { Button, Col, DatePicker, Form, Icon, Input, Modal, Row, Select, Table, Tag } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messageLabel } from '../../../components/message-component';
import CustomTableComponent from '../../../components/table';
import { getItemBasePay } from '../../../controllers/base-pay/basepay';
import { useAppContext } from '../../../includes/indexProvider';
import Button_02 from '../../../components/v2/button_02';
import Button_01 from '../../../components/v2/button_01';
import LabeRequireForm from '../../../components/label-required-form';
import { getListItemCreditNote } from '../../../controllers/credit-note/credit-note';
import { errorNotification } from '../../../components/v2/notification';
import { getDataAll, getModelName } from '../../../controllers/item-master/get-data-all';
import moment from 'moment';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';

const { Option } = Select;

const ModalAddItemCreditNote = ({ visible, setVisble, setTrigger, selectItem, setSelectItem, dataForm }) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      // render: (text, record, index) => index,
    },
    {
      title: messageLabel('modalAddInvoiceColumnItemCode', 'Item Code'),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 150,
      ellipsis: true,
      fixed: 'left',
    },
    {
      title: messageLabel('modalAddInvoiceColumnitem', 'Item'),
      dataIndex: 'item',
      key: 'item',
      sorter: true,
      width: 150,
    },
    {
      title: messageLabel('modalAddInvoiceColumnQty', 'Qty'),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      width: 150,
      align: 'right',
    },

    {
      title: messageLabel('modalAddInvoiceColumnUnit', 'Unit'),
      dataIndex: 'unitName',
      key: 'unitName',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalAddInvoiceColumnPrice', 'Price/Unit'),
      dataIndex: 'priceUnit',
      key: 'priceUnit',
      sorter: true,
      width: 150,
      render:(text, record, index) => (text ? numberFormatter(text) : 0) + ' THB',
    },
    {
      title: messageLabel('modalAddInvoiceColumnAmount', 'Amount'),
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      width: 200,
      render:(text, record, index) => (text ? numberFormatter(text) : 0) + ' THB',
    },
    {
      title: messageLabel('modalAddInvoiceColumnCategory', 'Category'),
      dataIndex: 'category',
      key: 'category',
      sorter: true,
      width: 150,
    },
  ];
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;

  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const [sortFieldTable, setSortFieldTable] = useState('');
  const [sortOrderTable, setSortOrderTable] = useState('');
  const [toggleFilter, setToggleFilter] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({});

  const [listBrand, setListBrand] = useState([]);
  const [listModal, setListModal] = useState([]);

  console.log('filterAddItem', filter, dataForm);
  console.log('datasource', dataSource);

  useEffect(() => {
    const getBrand = async () => {
      const response = await getDataAll();
      setListBrand(_.get(response, 'itemBrand'));
    };
    getBrand();
  }, []);

  useEffect(() => {
    const getModal = async () => {
      const response = await getModelName({ brandId: _.get(filter, 'brand') });
      setListModal(response);
    };
    if (_.get(filter, 'brand')) getModal();
  }, [_.get(filter, 'brand')]);

  useEffect(() => {
    setPageTable(1);
    setSizeTable(10);
  }, [visible]);

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        // invoiceNo: _.get(dataForm, 'invNo'),
        // saleOrderNo: _.get(dataForm, 'invNo'),
        // issueDateFrom: _.get(dataForm, 'invNo'),
        // customerId: _.get(dataForm, 'customerInv.customerSearch'),
        // issueBy: _.get(dataForm, 'soNo'),

        limit: sizeTable,
        page: pageTable,
        orderBy: sortFieldTable,
        orderType: sortOrderTable,

        invoiceId: _.get(dataForm, 'invId'),
        limit: sizeTable,
        order: sortOrderTable,
        orderBy: sortFieldTable,
        page: pageTable,
        itemName: _.get(filter, 'itemName'),
        itemCode: _.get(filter, 'itemCode'),
        modelId: _.get(filter, 'brand') ? _.get(filter, 'model') : undefined,
        brandId: _.get(filter, 'brand'),
        category: _.get(filter, 'category'),
      };

      const response = await getListItemCreditNote(body);
      setDataSource(response.data);
      setTotal(response.total);
    };
    getAPI();
  }, [visible, toggleFilter]);

  const handleToggleFilter = () => {
    setToggleFilter((event) => !event);
    setPageTable(1);
  };

  const handleChangeTable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrderTable('desc');
      } else {
        setSortOrderTable('asc');
      }
    } else {
      setSortOrderTable('');
    }
    setSortFieldTable(field);
    setPageTable(pagination.current);
    setSizeTable(pagination.pageSize);
    setToggleFilter((event) => !event);
  };

  const handleChangeFilter = async (value, code) => {
    setFilter({ ...filter, [code]: value });
  };

  const handleCancel = () => {
    setVisble(false);
    setPageTable(1);
    setSizeTable(10);
    setFilter({});
  };

  const checkIsItem = (record) => {
    const filterIsItem = _.filter(selectItem, (item) => item.id === record.id);
    if (_.size(filterIsItem) > 0) {
      errorNotification(
        intl.formatMessage({ id: 'creditNoteNotiErrorAddItem', defaultMessage: 'The Item quantity exceeds the quantity of invoice.' })
      );
      return true;
    } else {
      return false;
    }
  };

  const onClickSetItem = (record) => {
    console.log('onClickTable', record, selectItem);

    if (checkIsItem(record)) return;

    setSelectItem((prev) => [
      ...prev,
      {
        itemCode: _.get(record, 'itemCode'),
        item: _.get(record, 'item'),
        invQty: _.get(record, 'qty'),
        invUnit: _.get(record, 'unit'),
        invUnitName: _.get(record, 'unitName'),
        invPriceUnit: _.get(record, 'priceUnit'),
        invAmount: _.get(record, 'amount'),
        category: _.get(record, 'category'),
        invTotal: _.toNumber(_.get(record, 'qty')) * _.toNumber(_.get(record, 'priceUnit')),
        id: _.get(record, 'id'),
        type: '',
        priceUnit: _.get(record, 'priceUnit'),
        empty: {
          qty: false,
          priceUnit: false,
          other: false,
          type: false,
        },
      },
    ]);
    handleCancel();
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalAddInvoiceTitleAddItem', defaultMessage: 'Add Item' })}
      visible={visible}
      onOk={() => setVisble(false)}
      onCancel={() => handleCancel()}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '600px' }}
      className="modalBrandAndModel"
      footer={[
        <Button_02 key="close" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCloseItem" defaultMessage="Close" />
        </Button_02>,
      ]}
    >
      <Row>
        <Col span={6} style={{ borderRight: '1px solid #e8e8e8' }}>
          <div style={{ height: '555px' }}>
            <div
              className="listItemTextHeader"
              style={{
                padding: '24px 16px 16px 0px',
                marginTop: '-5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '50%', marginLeft: '24px' }}>
                <FormattedMessage id="modalAddInvoiceTitleFilter" defaultMessage="Filter" />
              </span>
            </div>
            <div style={{ padding: '16px 16px 8px 0px', marginLeft: '15px' }}>
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelCategory" defaultMessage="Category" />
                </div>
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => handleChangeFilter(value, 'category')}
                  filterOption={false}
                  value={_.get(filter, 'category')}
                  placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefCategory', defaultMessage: 'Select Category' })}
                  style={{ width: '100%' }}
                >
                  <Option value={'product'}>
                    <FormattedMessage id="modalItemMasterFormItemNameTextProduct" defaultMessage="Product" />
                  </Option>
                  <Option value={'service'}>
                    <FormattedMessage id="modalItemMasterFormItemNameTextService" defaultMessage="Service" />
                  </Option>
                </Select>
              </div>{' '}
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelBrand" defaultMessage="Brand" />
                </div>
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => handleChangeFilter(value, 'brand')}
                  filterOption={false}
                  value={_.get(filter, 'brand')}
                  placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefBrand', defaultMessage: 'Select Brand' })}
                  style={{ width: '100%' }}
                >
                  {_.map(listBrand, (item) => (
                    <Option value={item.itemBrandId}>{item.itemBrandNo + ' ' + item.itemBrandName}</Option>
                  ))}
                </Select>
              </div>{' '}
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelModel" defaultMessage="Model" />
                </div>
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => handleChangeFilter(value, 'model')}
                  filterOption={false}
                  value={_.get(filter, 'brand') ? _.get(filter, 'model') : undefined}
                  placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefModel', defaultMessage: 'Select Model' })}
                  style={{ width: '100%' }}
                  disabled={!_.get(filter, 'brand')}
                >
                  {_.map(listModal, (item) => (
                    <Option value={item.itemModelId}>{item.itemModelNo + ' ' + item.itemModelName}</Option>
                  ))}
                </Select>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelItemCode" defaultMessage="Item Code" />
                </div>
                <div>
                  <Input
                    allowClear={true}
                    onChange={(e) => handleChangeFilter(e.target.value, 'itemCode')}
                    value={_.get(filter, 'itemCode')}
                    placeholder={intl.formatMessage({
                      id: 'modalAddInvoicePHRefItemCode',
                      defaultMessage: 'Enter Item Code',
                    })}
                  />
                </div>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalAddInvoiceLabelItemName" defaultMessage="Item Name" />
                </div>
                <div>
                  <Input
                    allowClear={true}
                    onChange={(e) => handleChangeFilter(e.target.value, 'itemName')}
                    value={_.get(filter, 'itemName')}
                    placeholder={intl.formatMessage({
                      id: 'modalAddInvoicePHRefItemName',
                      defaultMessage: 'Enter Item Name',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: '50px' }}>
            <div style={{ padding: '0px 16px 10px 24px' }}>
              <Button_01
                style={{ margin: '0px', width: '100%' }}
                key="print"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleToggleFilter()}
              >
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_01>
            </div>
          </div>
        </Col>

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Col span={18}>
          <div
            className="listItemTextHeader"
            style={{
              padding: '24px 16px 16px 24px',
              marginTop: '-5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <FormattedMessage id="modalAddInvoiceTitleItem" defaultMessage="Item" />
            </span>

            <div></div>
          </div>
          <div
            style={{
              padding: '16px',
              overflowY: 'auto',
              height: '540px',
            }}
          >
            <div style={{ marginBottom: '16px', marginLeft: '12px' }}>
              <Form colon={false} layout="vertival" style={{}}>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'creditNoteFormLabelINVNo', defaultMessage: 'INV No.' })}
                          req={false}
                        />
                      }
                      className="form-line-height"
                    >
                      <div className="text-location-short">
                        <span style={{ color: '#050505' }}>{_.get(dataForm, 'invNo') || '-'}</span>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'creditNoteFormLabelINVDate', defaultMessage: 'INV Date' })}
                          req={false}
                        />
                      }
                      className="form-line-height"
                    >
                      <div className="text-location-short">
                        <span style={{ color: '#050505' }}>
                          {_.get(dataForm, 'invDate') ? moment(_.get(dataForm, 'invDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
                        </span>
                      </div>
                    </Form.Item>
                  </Col>{' '}
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          text={intl.formatMessage({ id: 'creditNoteFormLabelCNIssued', defaultMessage: 'CN Issued' })}
                          req={false}
                        />
                      }
                      className="form-line-height"
                    >
                      <div className="text-location-short">
                        <span style={{ color: '#050505' }}>{_.get(dataForm, 'cn') || 0}</span>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <Table
              dataSource={dataSource}
              columns={columns}
              onChange={handleChangeTable}
              scroll={{ x: _.sumBy(columns, 'width') }}
              pagination={
                total
                  ? {
                      total: total,
                      current: pageTable,
                      showTotal: showTotal,
                      defaultCurrent: 1,
                      pageSizeOptions: ['10', '20', '30', '40', '50'],
                      locale: { items_per_page: '' },
                      pageSize: sizeTable,
                      showSizeChanger: true,
                    }
                  : false
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (!event.target.href) {
                      onClickSetItem(record);
                    }
                  },
                };
              }}
            />

            {total > 0 ? (
              <div className="total-items-member">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${total || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

ModalAddItemCreditNote.defaultProps = {};

export default ModalAddItemCreditNote;
