import React, { useEffect, useRef, useState } from 'react';
import { Modal, Icon, Select, Row, Col, Divider, Checkbox, Card, Spin } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button01 from '../components/v2/button_01';
import Button02 from '../components/v2/button_02';
import _ from 'lodash';
import NoDataBackground from '../components/no-data-page';

const ModalPDFViewverOptionalColumn = (props) => {
  const { visible, setVisible, url, paperSize, paperIndexSize, paperUnit, column, handleApply, loading } = props;
  const formatNumber = (value) => parseFloat(value).toFixed(2);
  const intl = useIntl();
  const iframeRef = useRef(null);
  const [columnChecked, setColumnChecked] = useState([]);
  const [screenNoPdf, setScreenNoPdf] = useState(false);

  console.log('loadingpdf', loading);

  useEffect(() => {
    if (visible) {
      setColumnChecked([...column]);
    }
  }, [visible]);

  const totalSelectedWidth = columnChecked.reduce((total, col) => {
    return col.default ? total + Number(formatNumber(col.widthPdf / 10) || 0) : total;
  }, 0);

  const totalWidth = column.reduce((total, col) => {
    return col.default ? total + Number(formatNumber(col.widthPdf / 10) || 0) : total;
  }, 0);

  useEffect(() => {
    if (visible && totalWidth > (paperSize - paperIndexSize) / 10) {
      setScreenNoPdf(true);
    }
  }, [visible, totalWidth]);

  console.log('modalx column', column);
  console.log('modalx columnChecked', columnChecked);

  const handleCheckboxChange = (e, key) => {
    const updatedColumns = columnChecked.map((col) => {
      if (col.key === key) {
        return { ...col, default: e.target.checked };
      }
      return col;
    });
    setColumnChecked(updatedColumns);
  };

  const handleCancel = () => {
    setVisible(false);
    setColumnChecked([]);
  };

  const handlePrintDialog = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.print();
    }
  };

  const footer = (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon type="info-circle" style={{ color: '#1D3557' }} />
        <span style={{ color: '#1D3557', fontSize: '14px', marginLeft: '8px' }}>
          {`กระดาษมีความกว้าง ${formatNumber(paperSize / 10)} ${paperUnit} จำนวนคอลัมน์ที่เลือกจึงต้องมีความกว้างไม่เกิน ${formatNumber(
            paperSize / 10
          )} ${paperUnit}`}
        </span>
      </div>
      <div>
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} margin=" unset">
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handlePrintDialog}>
          <FormattedMessage id="btnPrint" defaultMessage="Print" />
        </Button01>
      </div>
    </div>
  );

  const renderPdfContent = () => {
    if (screenNoPdf) {
      return (
        <Card>
          <NoDataBackground
            text1={{ id: 'nodataColumnsMoreThanConfig', text: 'Columns more than Config' }}
            text2={{ id: 'x', text: ' ' }}
          />
        </Card>
      );
    }

    if (url) {
      return (
        <Spin spinning={loading} style={{ marginTop: '300px' }}>
          <iframe ref={iframeRef} src={url} width="100%" height="600px" style={{ display: loading ? 'none' : 'block' }} />
        </Spin>
      );
    }

    return <p>Loading PDF...</p>;
  };

  return (
    <div>
      <Modal
        title={intl.formatMessage({ id: 'pdfViewverTitle', defaultMessage: 'PDF Document Preview' })}
        onCancel={handleCancel}
        visible={visible}
        centered={true}
        width={1080}
        bodyStyle={{
          padding: 'unset',
        }}
        footer={footer}
      >
        <Row>
          <Col span={7} style={{ borderRight: '1px solid #E5E5E5' }}>
            <div className="layout-pdf-preview-optional-column">
              <div className="header-pdf-preview-optional-column">
                <p className="title-pdf-preview-optional-column">
                  <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" />
                </p>
                <div className="layout-item-pdf-preview-optional-column">
                  <span className="item1-pdf-preview-optional-column">
                    {' '}
                    {`เลือกไปแล้ว ${formatNumber(totalSelectedWidth + paperIndexSize / 10)} ${paperUnit}`}
                  </span>
                  <Divider type="vertical" />
                  <span className="item2-pdf-preview-optional-column">
                    {' '}
                    {`เลือกได้อีก ${formatNumber(paperSize / 10 - totalSelectedWidth - paperIndexSize / 10)} ${paperUnit}`}
                  </span>
                </div>
              </div>
              <Divider type="horizontal" style={{ margin: 'unset' }} />
              <div className="content-pdf-preview-optional-column">
                {_.map(columnChecked, (o) => (
                  <div className="item-optional-column" key={o.key}>
                    <Checkbox checked={o.default} value={o.default} onChange={(e) => handleCheckboxChange(e, o.key)}>
                      {o.title}
                    </Checkbox>
                    <span className="item-title-optional-column">
                      {formatNumber(o.widthPdf / 10)} {paperUnit}
                    </span>
                  </div>
                ))}
              </div>

              <div className="footer-pdf-preview-optional-column">
                <Button01
                  key="apply"
                  type="primary"
                  btnsize="wd_lg"
                  style={{ margin: 'unset' }}
                  disabled={totalSelectedWidth + paperIndexSize / 10 > formatNumber(paperSize / 10)}
                  onClick={() => {
                    if (_.isFunction(handleApply)) {
                      handleApply(columnChecked);
                      setScreenNoPdf(false);
                    }
                  }}
                >
                  <FormattedMessage id="btnApply" defaultMessage="Apply" />
                </Button01>
              </div>
            </div>
          </Col>
          <Col span={17}>
            {renderPdfContent()}
            {/* {screenNoPdf ? (
              <Card className="detail-card-blank-page">
                <NoDataBackground
                  text1={{ id: 'nodataColumnsMoreThanConfig', text: 'Columns more than Config' }}
                  text2={{ id: 'x', text: ' ' }}
                />
              </Card>
            ) : url ? (
              <Spin spinning={loading}>
                <iframe ref={iframeRef} src={url} width="100%" height="600px" />
              </Spin>
            ) : (
              <p>Loading PDF...</p>
            )} */}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ModalPDFViewverOptionalColumn;
