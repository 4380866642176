import { Button, Checkbox, Dropdown, Icon, Menu } from 'antd';
import React, { useEffect } from 'react';
import Button_01 from '../../../components/v2/button_01';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

const ActionColumnsNetVehicleIncomeReport = (props) => {
  const {
    columns,
    setShowColumn,
    newDataColumns,
    setNewDataColumns,
    listArrayColumns,
    setListArrayColumns,
    defaultShowColumn,
    setDefaultShowColumn,
    newColumns,
    textErrorSelectColumn,
    setTextErrorSelectColumn,
    visible,
    setVisible,
    columnMinimum,
    allColumns,
    setAllDataColumns,
  } = props.actionColumns;

  useEffect(() => {
    const newListArrayColumns = newColumns.map((element) => element.key);
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  }, []);

  const ignoreColumn = ['index', 'date'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      const newListArrayColumns = newDataColumns.map((element) => element.key);
      const reduceOBJ = _.reduce(
        newListArrayColumns,
        (result, value) => {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const checkedValuecolumns = (e) => {
    const { checked, value } = e.target;
    const updatedShowColumn = { ...defaultShowColumn };

    if (checked) {
      if (!listArrayColumns.includes(value)) {
        setListArrayColumns([...listArrayColumns, value]);
      }
      updatedShowColumn[value] = true;
    } else {
      setListArrayColumns(listArrayColumns.filter((col) => col !== value));
      updatedShowColumn[value] = false;
    }

    setDefaultShowColumn(updatedShowColumn);
  };

  const handleCheckOk = () => {
    if (listArrayColumns.length <= (columnMinimum || 4)) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    }
    setTextErrorSelectColumn('');

    const filterColumns = (columns, defaultShowColumn) => {
      return columns
        .filter((column) => defaultShowColumn[column.key] !== false)
        .map((column) => {
          if (column.children && column.children.length > 0) {
            column.children = filterColumns(column.children, defaultShowColumn);
            if (column.key === 'expense' && column.children.every((child) => !defaultShowColumn[child.key])) {
              return null;
            }
          }
          return column;
        })
        .filter((column) => column !== null);
    };

    const filteredColumns = filterColumns(columns, defaultShowColumn);
    setNewDataColumns(filteredColumns);
    handleOnVisiblecolumns();

    const allColumnsWithDefaultFalse = allColumns.map(column => ({
      ...column,
      default: defaultShowColumn[column.key],
    }));
  
    setAllDataColumns(allColumnsWithDefaultFalse);
  };

  const handleReset = () => {
    const newListArrayColumns = newColumns.map((element) => element.key);
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
    handleOnVisiblecolumns();
    setAllDataColumns(allColumns);
  };

  const renderColumnCheckbox = (column) => {
    if (column.key === 'expense') {
      return (
        <div key={column.key}>
          {column.children.map((child) => renderColumnCheckbox(child))}
        </div>
      );
    }

    return (
      <div key={column.key} style={{ display: 'block', marginBottom: '10px', paddingLeft: column.children ? '20px' : '0px' }}>
        <Checkbox
          value={column.key}
          onChange={checkedValuecolumns}
          checked={defaultShowColumn[column.key] ? true : false}
        >
          <span style={{ fontSize: '13px' }}>{column.title}</span>
        </Checkbox>
        {column.children && column.children.length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            {column.children.map((child) => renderColumnCheckbox(child))}
          </div>
        )}
      </div>
    );
  };

  const menuColumn = () => (
    <Menu className="menu-columns">
      <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {columnNew.map((column) => renderColumnCheckbox(column))}
        </div>
      </Menu.Item>
      <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>
        {textErrorSelectColumn}
      </Menu.Item>
      <Menu.Item style={{ backgroundColor: '#ffffff' }}>
        <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
            <FormattedMessage id="btnOK" defaultMessage="OK" />
          </Button>
          <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
            <FormattedMessage id="btnReset" defaultMessage="Reset" />
          </Button>
        </Button.Group>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      className="list-view-dropdown"
      trigger={['click']}
      placement="bottomRight"
      overlay={menuColumn}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <Button_01 type="primary" btnsize="wd_at" fontSize="sm" onClick={handleOnVisiblecolumns} className="style-buttun-columns">
        <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" /> <Icon type="down" />
      </Button_01>
    </Dropdown>
  );
};

export default ActionColumnsNetVehicleIncomeReport;
