import './index.css';
import { Button, Card, Col, Dropdown, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import SummaryNetVehicleIncomeReport from './components/summary';
import ActionNetVehicleIncomeReport from './components/action';
import TableNetVehicleIncomeReport from './components/table';
import { exportListNetVehicleIncomeReport, getListNetVehicleIncomeReport } from '../../controllers/net-vehicle-income-report/api';
import { render } from 'less';
import _ from 'lodash';
import moment from 'moment';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import { downloadExcelArrayBuffer } from '../../component-function/download-excel';
import ModalPDFViewverOptionalColumn from '../../component-function/pdf-viewver-optional-column';
import { UrlPDF } from '../../component-function/generator-pdf';
import { downloadPDFApi } from '../../controllers/pdf';

const NetVehicleIncomeReport = () => {
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
      widthPdf: 40,
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportDateColumn" defaultMessage="Date"/>,
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      sorter: true,
      width: 150,
      fixed: 'left',
      default: true,
      widthPdf: 20,
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportTaskNoColumn" defaultMessage="Task No."/>,
      dataIndex: 'taskNo',
      key: 'taskNo',
      align: 'left',
      sorter: true,
      width: 180,
      default: true,
      widthPdf: 28,
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportLicensePlateColumn" defaultMessage="License Plate"/>,
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      align: 'left',
      sorter: true,
      width: 180,
      default: true,
      widthPdf: 20,
      render: (text) => text || '-',
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportSourceNameColumn" defaultMessage="Source Name"/>,
      dataIndex: 'sourceName',
      key: 'sourceName',
      align: 'left',
      sorter: true,
      default: false,
      widthPdf: 20,
      render: (text) => text || '-',
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportSourceAddressColumn" defaultMessage="Source Address"/>,
      dataIndex: 'sourceAddress',
      key: 'sourceAddress',
      align: 'left',
      sorter: true,
      default: true,
      ellipsis: true,
      widthPdf: 35,
      render: (text) => {
        const displayText = text.length > 65 ? text.slice(0, 60) + '...' : text || '-';
        return displayText;
      },
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportSourcePostcodeColumn" defaultMessage="Source Postcode"/>,
      dataIndex: 'sourcePostCode',
      key: 'sourcePostCode',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 20,
      render: (text) => text || '-',
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportDestinationNameColumn" defaultMessage="Destination Name"/>,
      dataIndex: 'destinationName',
      key: 'destinationName',
      align: 'left',
      sorter: true,
      default: false,
      widthPdf: 20,
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportDestinationAddressColumn" defaultMessage="Destination Address"/>,
      dataIndex: 'destinationAddress',
      key: 'destinationAddress',
      align: 'left',
      sorter: true,
      default: true,
      ellipsis: true,
      widthPdf: 35,
      render: (text) => {
        const displayText = text.length > 65 ? text.slice(0, 60) + '...' : text;
        return displayText;
      },
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportDestinationPostcodeColumn" defaultMessage="Destination Postcode"/>,
      dataIndex: 'destinationPostcode',
      key: 'destinationPostcode',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 20,
      render: (text) => text || '-',
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportRevenueColumn" defaultMessage="Revenue"/>,
      dataIndex: 'revenue',
      key: 'revenue',
      align: 'right',
      sorter: true,
      width: 180,
      default: true,
      widthPdf: 22.2,
      numFormat: 2,
      render: (text, record, index) => numberFormatter(record.revenue || 0) + ' THB',
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportExpenseColumn" defaultMessage="Expense"/>,
      dataIndex: 'expense',
      key: 'expense',
      default: true,
      children: [
        {
          title: <FormattedMessage id="netVehicleIncomeReportGasColumn" defaultMessage="Gas"/>,
          dataIndex: 'expenseGas',
          key: 'expenseGas',
          align: 'right',
          sorter: true,
          width: 200,
          default: true,
          widthPdf: 22.2,
          numFormat: 2,
          render: (text, record) => numberFormatter(_.get(record, 'expenseGas') || 0) + ' THB',
        },
        {
          title: <FormattedMessage id="netVehicleIncomeReportAllowanceColumn" defaultMessage="Allowance"/>,
          dataIndex: 'expenseAllowance',
          key: 'expenseAllowance',
          align: 'right',
          sorter: true,
          width: 200,
          default: true,
          widthPdf: 22.2,
          numFormat: 2,
          render: (text, record) => numberFormatter(_.get(record, 'expenseAllowance') || 0) + ' THB',
        },
        {
          title: <FormattedMessage id="netVehicleIncomeReportTripAllowanceColumn" defaultMessage="Trip Allowance"/>,
          dataIndex: 'expenseTripAllowance',
          key: 'expenseTripAllowance',
          align: 'right',
          sorter: true,
          width: 200,
          default: true,
          widthPdf: 22.2,
          numFormat: 2,
          render: (text, record) => numberFormatter(_.get(record, 'expenseTripAllowance') || 0) + ' THB',
        },
        {
          title: <FormattedMessage id="netVehicleIncomeReportTollOrParkingFeeColumn" defaultMessage="Toll Fee / Parking Fee"/>,
          dataIndex: 'expenseTollFee',
          key: 'expenseTollFee',
          align: 'right',
          sorter: true,
          width: 200,
          default: true,
          widthPdf: 22.2,
          numFormat: 2,
          render: (text, record) => numberFormatter(_.get(record, 'expenseTollFee') || 0) + ' THB',
        },
        {
          title: <FormattedMessage id="netVehicleIncomeReportOtherColumn" defaultMessage="Other"/>,
          dataIndex: 'expenseOther',
          key: 'expenseOther',
          align: 'right',
          sorter: true,
          width: 200,
          default: true,
          widthPdf: 22.2,
          numFormat: 2,
          render: (text, record) => numberFormatter(_.get(record, 'expenseOther') || 0) + ' THB',
        },
      ],
    },
    {
      title: <FormattedMessage id="netVehicleIncomeReportEarningsPerTripColumn" defaultMessage="Earnings per Trip"/>,
      dataIndex: 'earningsPerTrip',
      key: 'earningsPerTrip',
      align: 'right',
      sorter: true,
      default: true,
      widthPdf: 22.2,
      numFormat: 2,
      render: (text) => numberFormatter(text || 0) + ' THB',
    },
  ];

  const getColumnDataIndexes = (columns) => {
    return columns.reduce((acc, column) => {
      acc.push(column.dataIndex);
      if (column.children && column.children.length > 0) {
        acc.push(...getColumnDataIndexes(column.children));
      }
      return acc;
    }, []);
  };

  const setShowColumnArr = getColumnDataIndexes(columns);

  const getColumnDefaults = (columns) => {
    return columns.reduce((acc, column) => {
      acc[column.dataIndex] = column.default || false;
      if (column.children && column.children.length > 0) {
        acc = { ...acc, ...getColumnDefaults(column.children) };
      }
      return acc;
    }, {});
  };

  const setShowColumn = getColumnDefaults(columns);

  const getIgnoreColumns = (columns) => {
    return columns
      .filter((column) => column.default === false)
      .reduce((acc, column) => {
        acc.push(column.dataIndex);
        if (column.children && column.children.length > 0) {
          acc.push(...getIgnoreColumns(column.children));
        }
        return acc;
      }, []);
  };

  const ignoreColumn = getIgnoreColumns(columns);

  const getColumnAndChildren = (columns) => {
    return columns.reduce((acc, column) => {
      if (column.key === 'expense' || column.key === 'index') {
        if (column.children && column.children.length > 0) {
          acc.push(...getColumnAndChildren(column.children));
        }
        return acc;
      }

      acc.push(column);

      if (column.children && column.children.length > 0) {
        acc.push(...getColumnAndChildren(column.children));
      }

      return acc;
    }, []);
  };

  const allColumns = getColumnAndChildren(columns);

  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const columnMinimum = 2;
  const columnIndex = 10;
  const [isFetch, setIsFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [listData, setListData] = useState([]);
  const [listSummaryData, setListSummaryData] = useState([]);
  const [search, setSearch] = useState({ filterDate: [moment().startOf('month'), moment().endOf('month')] });
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [openPdf, setOpenPdf] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [allDataColumns, setAllDataColumns] = useState([...allColumns]);
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');

  console.log(setShowColumnArr, 'columns(1)setShowColumnArr');
  console.log(setShowColumn, 'columns(2)setShowColumn');
  console.log(ignoreColumn, 'columns(3)ignoreColumn');
  console.log(newColumns, 'columns(4)newColumns');
  console.log(newDataColumns, 'columns(5)newDataColumns)');
  console.log(listArrayColumns, 'columns(6)listArrayColumns');
  console.log(defaultShowColumn, 'columns(7)defaultShowColumn');
  console.log(textErrorSelectColumn, 'columns(8)textErrorSelectColumn');
  console.log(allColumns, 'columns(9)allColumns');
  console.log(allDataColumns, 'columns(10)allDataColumns');

  useEffect(() => {
    const getListData = async () => {
      setLoading(true);
      const payload = {
        where: {
          date: {
            startDate: moment(_.get(search, 'filterDate[0]')).format('YYYY-MM-DD'),
            endDate: moment(_.get(search, 'filterDate[1]')).format('YYYY-MM-DD'),
          },
          taskNo: _.get(search, 'taskNo'),
          licensePlate: _.get(search, 'licensePlate'),
          sourceName: _.get(search, 'sourceName'),
          sourcePostCode: _.get(search, 'sourcePostCode'),
          destinationName: _.get(search, 'destinationName'),
          destinationPostCode: _.get(search, 'destinationPostCode'),
        },
        page: page,
        pageSize: size,
        orderBy: sortField,
        order: sortOrder,
      };
      const response = await getListNetVehicleIncomeReport(payload);

      console.log(response, 'listdata');

      setListData(_.get(response, 'data.list'));
      setListSummaryData(_.get(response, 'data.listSummary'));
      setTotal(_.get(response, 'data.total'));
      setLoading(false);
    };

    getListData();
  }, [page, size, sortOrder, sortField, isFetch, _.get(search, 'filterDate')]);

  console.log(listData, 'listdata');

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
    if (code === 'filterDate') setPage(1);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    const viewFormat = _.chain(allDataColumns)
      .filter((o) => o.default !== false)
      .map((o, index) => ({
        header: intl.formatMessage({ id: o.title?.props?.id, defaultMessage: o.title?.props?.defaultMessage }) || o.title,
        key: o.key,
        width: 30,
      }))
      .value();
    try {
      const body = {
        where: {
          date: {
            startDate: moment(_.get(search, 'filterDate[0]')).format('YYYY-MM-DD'),
            endDate: moment(_.get(search, 'filterDate[1]')).format('YYYY-MM-DD'),
          },
          taskNo: _.get(search, 'taskNo'),
          licensePlate: _.get(search, 'licensePlate'),
          sourceName: _.get(search, 'sourceName'),
          sourcePostCode: _.get(search, 'sourcePostCode'),
          destinationName: _.get(search, 'destinationName'),
          destinationPostCode: _.get(search, 'destinationPostCode'),
        },
        page: page,
        pageSize: size,
        orderBy: sortField,
        order: sortOrder,
        statusExport: true,
        viewFormat: viewFormat,
      };
      const response = await exportListNetVehicleIncomeReport(body);
      if (response.status === 200) {
        const disposition = response.headers['content-type'];

        downloadExcelArrayBuffer({ binaryString: response.data, disposition });
        successNotification('Success');
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      console.log('exportInvoiceReport error', error);
    }
  };

  //เรียก  pdf ครั้งแรก
  useEffect(() => {
    setLoadingPdf(true);

    const getApi = async () => {
      const mapColumnChecked = _.chain(allDataColumns)
        .filter((o) => o.default !== false)
        .map((o) => o.dataIndex)
        .value();

      let mapColumnCheckedAdd = [...mapColumnChecked];

      const printColumnOptions = _.chain(allDataColumns)
        .filter((o) => o.default !== false)
        .map((o, index) => ({
          columnHeaderName: intl.formatMessage({ id: o.title?.props?.id, defaultMessage: o.title?.props?.defaultMessage }) || o.title,
          columnWidth: o.widthPdf,
          columnMapping: o.dataIndex,
          columnAlign: o.align,
          columnNumberFormat: o.numFormat,
        }))
        .value();

      const body = {
        filter: {
          where: {
            date: {
              startDate: moment(_.get(search, 'filterDate[0]')).format('YYYY-MM-DD'),
              endDate: moment(_.get(search, 'filterDate[1]')).format('YYYY-MM-DD'),
            },
            taskNo: _.get(search, 'taskNo'),
            licensePlate: _.get(search, 'licensePlate'),
            sourceName: _.get(search, 'sourceName'),
            sourcePostCode: _.get(search, 'sourcePostCode'),
            destinationName: _.get(search, 'destinationName'),
            destinationPostCode: _.get(search, 'destinationPostCode'),
          },
          page: 1,
          pageSize: 10000,
          orderBy: sortField,
          order: sortOrder,
        },
        printTableOptions: {
          tableName: 'รายงานรายได้สุทธิของรถ',
        },
        printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
        printColumnOptions: _.concat(
          [{ columnHeaderName: '#', columnWidth: columnIndex, columnMapping: 'index', columnAlign: 'center' }],
          printColumnOptions
        ),
      };

      console.log('handleApply body', body);

      const response = await downloadPDFApi({ tableCode: 'resourceReportNetVehicle', body: body });
      console.log('handleApply response', response);

      if (_.get(response, 'status') === 200) {
        const url = await UrlPDF(response);
        console.log('handleApply url', url);

        if (url) {
          setUrlPdf(url);
          setLoadingPdf(false);
        }
      } else {
        setUrlPdf(null);
        setLoadingPdf(false);
      }
    };

    if (openPdf) {
      getApi();
    }
  }, [openPdf]);

  //เปิด pdf
  const handlePrintPdf = () => {
    setOpenPdf(true);
  };

  //apply column pdf
  const handleApply = async (columnChecked) => {
    setLoadingPdf(true);
    const mapColumnChecked = _.chain(columnChecked)
      .filter((o) => o.default !== false)
      .map((o) => o.dataIndex)
      .value();

    let mapColumnCheckedAdd = [...mapColumnChecked];

    const printColumnOptions = _.chain(columnChecked)
      .filter((o) => o.default !== false)
      .map((o, index) => ({
        columnHeaderName: intl.formatMessage({ id: o.title?.props?.id, defaultMessage: o.title?.props?.defaultMessage }) || o.title,
        columnWidth: o.widthPdf,
        columnMapping: o.dataIndex,
        columnAlign: o.align,
        columnNumberFormat: o.numFormat,
      }))
      .value();

    const body = {
      filter: {
        where: {
          date: {
            startDate: moment(_.get(search, 'filterDate[0]')).format('YYYY-MM-DD'),
            endDate: moment(_.get(search, 'filterDate[1]')).format('YYYY-MM-DD'),
          },
          taskNo: _.get(search, 'taskNo'),
          licensePlate: _.get(search, 'licensePlate'),
          sourceName: _.get(search, 'sourceName'),
          sourcePostCode: _.get(search, 'sourcePostCode'),
          destinationName: _.get(search, 'destinationName'),
          destinationPostCode: _.get(search, 'destinationPostCode'),
        },
        page: 1,
        pageSize: 10000,
        orderBy: sortField,
        order: sortOrder,
      },
      printTableOptions: {
        tableName: 'รายงานรายได้สุทธิของรถ',
      },
      printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
      printColumnOptions: _.concat(
        [{ columnHeaderName: '#', columnWidth: columnIndex, columnMapping: 'index', columnAlign: 'center' }],
        printColumnOptions
      ),
    };

    console.log('handleApply body', body);

    const response = await downloadPDFApi({ tableCode: 'resourceReportNetVehicle', body: body });
    console.log('handleApply response', response);

    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      console.log('handleApply url', url);

      if (url) {
        setUrlPdf(url);
        setLoadingPdf(false);
      }
    } else {
      setUrlPdf(null);
      setLoadingPdf(false);
    }
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={() => handleExport()}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={() => handlePrintPdf()}>
          <FormattedMessage id="btnPrintPDF" defaultMessage="Print (PDF)" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card
        className="net-vehicle-income-report"
        title={intl.formatMessage({ id: 'menuNetVehicleIncomeReport', defaultMessage: 'Net Vehicle Income Report' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
        extra={
          <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
            <Button_01 key="submit" type="primary" btnsize="wd_df">
              <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
            </Button_01>
          </Dropdown>
        }
      >
        <SummaryNetVehicleIncomeReport listSummaryData={listSummaryData} />
        <ActionNetVehicleIncomeReport
          search={search}
          setSearch={setSearch}
          setIsFetch={setIsFetch}
          setPage={setPage}
          onFilterOrder={onFilterOrder}
          actionColumns={{
            columns,
            setShowColumn,
            newDataColumns,
            setNewDataColumns,
            listArrayColumns,
            setListArrayColumns,
            newColumns,
            defaultShowColumn,
            setDefaultShowColumn,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum,
            allColumns,
            setAllDataColumns,
          }}
        />
        <TableNetVehicleIncomeReport
          columns={newDataColumns}
          listData={listData}
          page={page}
          total={total}
          loading={loading}
          showTotal={showTotal}
          handleChange={handleChange}
        />
        <ModalPDFViewverOptionalColumn
          paperSize={274}
          paperIndexSize={columnIndex}
          paperUnit={intl.formatMessage({ id: 'unitCm', defaultMessage: 'cm.' })}
          visible={openPdf}
          url={urlPdf}
          loading={loadingPdf}
          setVisible={setOpenPdf}
          handleApply={handleApply}
          column={allDataColumns} //actionColumn
        />
      </Card>
    </div>
  );
};

export default NetVehicleIncomeReport;
