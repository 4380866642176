import React, { useContext, useEffect, useState } from 'react';
import { OverviewContext } from '../../context';
import FilterOnTopTab from '../component/filter-op-top-tab';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import TableRevenueLeastArea from '../component/table-net-least-area';
import { messageLabel } from '../../../../components/message-component';
import _ from 'lodash';
import { Divider, Spin } from 'antd';
import DisplayBtn from '../component/revenue-display-btn';
import OccRateArr from './occ-rate-arr';
import AreaUtili from './area-utili';
import AreaUtiliMarketGroup from './area-utili-market';
import moment from 'moment';

const TabRevenueOverview = ({ fullscreenPageRef }) => {
  const { state, setState, func } = useContext(OverviewContext);

  const {
    marketAllList,
    revenueNet,
    revenueOcc,
    revenueArea,
    revenueAreaGroup,
    selectMarketOcc,
    selectMarketArea,
    selectMarketAreaGroup,
    marketAllGroupList,
    revenueNetDate,
    revenueOccDate,
    revenueAreaDate,
    revenueAreaGroupDate,
    revenueNetLoading,
    revenueOccLoading,
    revenueAreaLoading,
    revenueAreaGroupLoading,
    revenueOccAll,
  } = state;
  const {
    setMarketAllList,
    setRevenueNetDate,
    setRevenueOccDate,
    setRevenueAreaDate,
    setRevenueAreaGroupDate,
    setSelectMarketOcc,
    setSelectMarketArea,
    setSelectMarketAreaGroup,
  } = setState;
  const intl = useIntl();
  const aligntextleft = 'left';
  const aligntextRight = 'right';
  const widthChildColumn = 105;
  const [charDataOcc, setChartDataOcc] = useState([]);
  const [charDataArea, setChartDataArea] = useState([]);
  const [charDataAreaGroup, setChartDataAreaGroup] = useState([]);
  const [selectMarket, setSelectMarket] = useState({
    occ: [],
    area: [],
    areaU: [],
  });
  const [tableLeast, setTableLeast] = useState({
    page: 1,
    size: 10,
    field: undefined,
    order: undefined,
  });

  const [checkDisplayOcc, setcheckDisplayOcc] = useState({
    bar: true,
    line: true,
  });

  const [checkDisplayArea, setcheckDisplayArea] = useState({
    gross: true,
    net: true,
    efficiency: true,
  });

  const [checkDisplayAreaU, setcheckDisplayAreaU] = useState({
    gross: true,
    net: true,
    efficiency: true,
  });

  const TitleDate = (typedate, number) => {
    if (typedate === 'month' || typedate === 'duration') {
      if (number === 1) {
        return messageLabel('RevenueThisMonth', 'This Month');
      } else if (number === 2) {
        return messageLabel('RevenueLastMonthMoM', 'Last Month (MoM)');
      } else {
        return messageLabel('RevenueLastYearYoY', 'Last Year (YoY)');
      }
    } else if (typedate === 'year') {
      if (number === 1) {
        return messageLabel('RevenueThisYear', 'This Year');
      } else if (number === 2) {
        return messageLabel('RevenueLastYear ', 'Last Year');
      } else {
        return messageLabel('RevenueLast2Year', 'Last 2 Year');
      }
    } else {
      if (number === 1) {
        return messageLabel('RevenueThisQuater', 'This Quater');
      } else if (number === 2) {
        return messageLabel('RevenueLastQuater ', 'Last Quater');
      } else {
        return messageLabel('RevenueLastYearQuater', 'Last Year Quater');
      }
    }
  };

  const columnLeast = [
    {
      title: messageLabel('RevenueDescription', 'Description'),
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 200,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.description}</div>,
    },
    {
      title: TitleDate(_.get(revenueNetDate, 'type'), 1),
      children: [
        {
          title: messageLabel('RevenueAreasqm', 'Area (sq.m.)'),
          dataIndex: 'thisMonth.area',
          key: 'thisMonth.area',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.thisMonth.area}</div>,
        },
        {
          title: messageLabel('RevenueRevenueMB', 'Revenue (MB)'),
          dataIndex: 'thisMonth.revenue',
          key: 'thisMonth.revenue',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.thisMonth.revenue}</div>,
        },
      ],
    },
    {
      title: TitleDate(_.get(revenueNetDate, 'type'), 2),
      children: [
        {
          title: messageLabel('RevenueAreasqm', 'Area (sq.m.)'),
          dataIndex: 'lastMonth.area',
          key: 'lastMonth.area',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastMonth.area}</div>,
        },
        {
          title: messageLabel('RevenueRevenueMB', 'Revenue (MB)'),
          dataIndex: 'lastMonth.revenue',
          key: 'lastMonth.revenue',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastMonth.revenue}</div>,
        },
        {
          title: messageLabel('RevenueRevChange', 'Rev. Change (%)'),
          dataIndex: 'lastMonth.revChange',
          key: 'lastMonth.revChange',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastMonth.revChange}</div>,
        },
      ],
    },
    {
      title: TitleDate(_.get(revenueNetDate, 'type'), 3),
      children: [
        {
          title: messageLabel('RevenueAreasqm', 'Area (sq.m.)'),
          dataIndex: 'lastYear.area',
          key: 'lastYear.area',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastYear.area}</div>,
        },
        {
          title: messageLabel('RevenueRevenueMB', 'Revenue (MB)'),
          dataIndex: 'lastYear.revenue',
          key: 'lastYear.revenue',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastYear.revenue}</div>,
        },
        {
          title: messageLabel('RevenueRevChange', 'Rev. Change (%)'),
          dataIndex: 'lastYear.revChange',
          key: 'lastYear.revChange',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastYear.revChange}</div>,
        },
      ],
    },
  ];

  const columnOcc = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('RevenueMarket', 'Market'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.name}</div>,
    },
    {
      title: TitleDate(_.get(revenueOccDate, 'type'), 1),
      children: [
        {
          title: messageLabel('RevenueOCCRate', 'OCC Rate (%)'),
          dataIndex: 'thisMonth.occ',
          key: 'thisMonth.occ',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.thisMonth.occ - b.thisMonth.occ,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.thisMonth.occ}</div>,
        },
        {
          title: messageLabel('RevenueARR', 'ARR (THB/sq.m)'),
          dataIndex: 'thisMonth.arr',
          key: 'thisMonth.arr',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.thisMonth.arr - b.thisMonth.arr,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.thisMonth.arr}</div>,
        },
      ],
    },
    {
      title: TitleDate(_.get(revenueOccDate, 'type'), 2),
      children: [
        {
          title: messageLabel('RevenueOCCRate', 'OCC Rate (%)'),
          dataIndex: 'lastMonth.occ',
          key: 'lastMonth.occ',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.lastMonth.occ - b.lastMonth.occ,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastMonth.occ}</div>,
        },
        {
          title: messageLabel('RevenueARR', 'ARR (THB/sq.m)'),
          dataIndex: 'lastMonth.arr',
          key: 'lastMonth.arr',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.lastMonth.arr - b.lastMonth.arr,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastMonth.arr}</div>,
        },
      ],
    },
    {
      title: TitleDate(_.get(revenueOccDate, 'type'), 3),
      children: [
        {
          title: messageLabel('RevenueOCCRate', 'OCC Rate (%)'),
          dataIndex: 'lastYear.occ',
          key: 'lastYear.occ',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.lastYear.occ - b.lastYear.occ,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastYear.occ}</div>,
        },
        {
          title: messageLabel('RevenueARR', 'ARR (THB/sq.m)'),
          dataIndex: 'lastYear.arr',
          key: 'lastYear.arr',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.lastYear.arr - b.lastYear.arr,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.lastYear.arr}</div>,
        },
      ],
    },
  ];

  const columnMarketGross = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('RevenueMarket', 'Market'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.name}</div>,
    },
    {
      title: messageLabel('RevenueGross', 'Gross area (sq.m.)'),
      dataIndex: 'grossArea',
      key: 'grossArea',
      align: 'center',
      width: widthChildColumn,
      sorter: (a, b) => a.grossArea - b.grossArea,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.grossArea}</div>,
    },
    {
      title: messageLabel('RevenueGross', 'Net Area (sq.m.)'),
      dataIndex: 'netArea',
      key: 'netArea',
      align: 'center',
      width: widthChildColumn,
      sorter: (a, b) => a.netArea - b.netArea,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.netArea}</div>,
    },
    {
      title: messageLabel('RevenueGross', 'Efficiency (%)'),
      dataIndex: 'efficiency',
      key: 'efficiency',
      align: 'center',
      width: widthChildColumn,
      sorter: (a, b) => a.efficiency - b.efficiency,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.efficiency}</div>,
    },
  ];

  const displayOcc = [
    {
      key: 'bar',
      title: 'OCC Rate',
      check: true,
    },
    {
      key: 'line',
      title: 'ARR',
      check: true,
    },
  ];

  const displayArea = [
    {
      key: 'gross',
      title: 'Gross Area',
      check: true,
    },
    {
      key: 'net',
      title: 'Net Area',
      check: true,
    },
    {
      key: 'efficiency',
      title: 'Efficiency',
      check: true,
    },
  ];

  const changeToNumber = (value) => {
    const number = _.chain(value)
      .replace(/,/g, '')
      .toNumber()
      .value();
    return number;
  };

  useEffect(() => {
    const mapDataQcc = async () => {
      if (_.includes(selectMarketOcc, 'all') === false) {
        // const mapBar = _.map(revenueOcc, (item, index) => {
        //   console.log('itemBAR', item);
        //   let temp = [];

        //   return  _.reduce(
        //     item,
        //     (acc, marketItem) => {
        //       acc[`bar ${index}`] = changeToNumber(item.thisMonth.occ);
        //       acc[`line${index}`] = changeToNumber(item.thisMonth.arr);
        //       return acc;
        //     },
        //     {}
        //   );
        // });
        // const bodySumAll = [
        //   {
        //     name: intl.formatMessage({ id: 'RevenueThisMonth', defaultMessage: 'This Month' }),
        //     mapBar,
        //   },
        //   {
        //     name: intl.formatMessage({ id: 'RevenueLastMonth', defaultMessage: 'Last Month' }),
        //     mapBar,
        //   },
        //   {
        //     name: intl.formatMessage({ id: 'RevenueLastYear', defaultMessage: 'Last Year' }),
        //     mapBar,
        //   },
        // ];

        // const sumAllThisMonthOcc = _.sumBy(revenueOcc, (item) => changeToNumber(item.thisMonth.occ));
        // const sumAllThisMonthArr = _.sumBy(revenueOcc, (item) => changeToNumber(item.thisMonth.arr));
        // const sumAllLastMonthOcc = _.sumBy(revenueOcc, (item) => changeToNumber(item.lastMonth.occ));
        // const sumAllLastMonthArr = _.sumBy(revenueOcc, (item) => changeToNumber(item.lastMonth.arr));
        // const sumAllLastYearOcc = _.sumBy(revenueOcc, (item) => changeToNumber(item.lastYear.occ));
        // const sumAllLastYearArr = _.sumBy(revenueOcc, (item) => changeToNumber(item.lastYear.arr));

        // const bodySumAll = [
        //   {
        //     name: intl.formatMessage({ id: 'RevenueThisMonth', defaultMessage: 'This Month' }),
        //     bar: sumAllThisMonthOcc,
        //     line: sumAllThisMonthArr,
        //   },
        //   {
        //     name: intl.formatMessage({ id: 'RevenueLastMonth', defaultMessage: 'Last Month' }),
        //     bar: sumAllLastMonthOcc,
        //     line: sumAllLastMonthArr,
        //   },
        //   {
        //     name: intl.formatMessage({ id: 'RevenueLastYear', defaultMessage: 'Last Year' }),
        //     bar: sumAllLastYearOcc,
        //     line: sumAllLastYearArr,
        //   },
        // ];

        // setChartDataOcc(bodySumAll);

        const bodySumAll = [
          {
            name: intl.formatMessage({ id: 'RevenueThisMonth', defaultMessage: 'This Month' }),
            ...revenueOcc.reduce((acc, item, index) => {
              acc[`bar${index + 1}`] = changeToNumber(item.thisMonth.occ);
              acc[`barName${index + 1}`] = item.name;
              acc[`line${index + 1}`] = changeToNumber(item.thisMonth.arr);
              acc[`lineName${index + 1}`] = item.name;
              return acc;
            }, {}),
          },
          {
            name: intl.formatMessage({ id: 'RevenueLastMonth', defaultMessage: 'Last Month' }),
            ...revenueOcc.reduce((acc, item, index) => {
              acc[`bar${index + 1}`] = changeToNumber(item.lastMonth.occ);
              acc[`barName${index + 1}`] = item.name;
              acc[`line${index + 1}`] = changeToNumber(item.lastMonth.arr);
              acc[`lineName${index + 1}`] = item.name;
              return acc;
            }, {}),
          },
          {
            name: intl.formatMessage({ id: 'RevenueLastYear', defaultMessage: 'Last Year' }),
            ...revenueOcc.reduce((acc, item, index) => {
              acc[`bar${index + 1}`] = changeToNumber(item.lastYear.occ);
              acc[`barName${index + 1}`] = item.name;
              acc[`line${index + 1}`] = changeToNumber(item.lastYear.arr);
              acc[`lineName${index + 1}`] = item.name;
              return acc;
            }, {}),
          },
        ];

        setChartDataOcc(bodySumAll);
      } else {
        const bodySumAll = [
          {
            name: intl.formatMessage({ id: 'RevenueThisMonth', defaultMessage: 'This Month' }),
            bar: changeToNumber(_.get(revenueOccAll, 'thisMonth.occ')),
            barName: 'OCC Rate - All Market',
            line: changeToNumber(_.get(revenueOccAll, 'thisMonth.arr')),
            lineName: 'ARR - All Market',
          },
          {
            name: intl.formatMessage({ id: 'RevenueLastMonth', defaultMessage: 'Last Month' }),
            bar: changeToNumber(_.get(revenueOccAll, 'lastMonth.occ')),
            barName: 'OCC Rate - All Market',
            line: changeToNumber(_.get(revenueOccAll, 'lastMonth.arr')),
            lineName: 'ARR - All Market',
          },
          {
            name: intl.formatMessage({ id: 'RevenueLastYear', defaultMessage: 'Last Year' }),
            bar: changeToNumber(_.get(revenueOccAll, 'lastYear.occ')),
            barName: 'OCC Rate - All Market',
            line: changeToNumber(_.get(revenueOccAll, 'lastYear.arr')),
            lineName: 'ARR - All Market',
          },
        ];
        console.log('ccccccccccccc', revenueOccAll, 'asd', bodySumAll);
        setChartDataOcc(bodySumAll);
      }
    };

    if (_.size(revenueOcc) > 0) {
      mapDataQcc();
    }
  }, [revenueOcc]);

  useEffect(() => {
    const mapDataArea = async () => {
      const mapArea = _.map(revenueArea, (i) => {
        return {
          name: _.get(i, 'name'),
          gross: changeToNumber(_.get(i, 'grossArea')),
          net: changeToNumber(_.get(i, 'netArea')),
          efficiency: changeToNumber(_.get(i, 'efficiency')),
        };
      });
      setChartDataArea(mapArea);
    };

    if (_.size(revenueArea) > 0) {
      mapDataArea();
    }
  }, [revenueArea]);

  useEffect(() => {
    const mapDataAreaGroup = async () => {
      const mapArea = _.map(revenueAreaGroup, (i) => {
        return {
          name: _.get(i, 'name'),
          gross: changeToNumber(_.get(i, 'grossArea')),
          net: changeToNumber(_.get(i, 'netArea')),
          efficiency: changeToNumber(_.get(i, 'efficiency')),
        };
      });
      setChartDataAreaGroup(mapArea);
    };

    if (_.size(revenueAreaGroup) > 0) {
      mapDataAreaGroup();
    }
  }, [revenueAreaGroup]);

  const buttonExport = (
    <Button_01 type="primary">
      <FormattedMessage id="btnExport" defaultMessage="Export" />
    </Button_01>
  );

  const buttonDisplayOcc = <DisplayBtn displayData={displayOcc} checkDisplay={checkDisplayOcc} setcheckDisplayOcc={setcheckDisplayOcc} />;

  const buttonDisplayArea = (
    <DisplayBtn displayData={displayArea} checkDisplay={checkDisplayArea} setcheckDisplayOcc={setcheckDisplayArea} />
  );

  const buttonDisplayAreaU = (
    <DisplayBtn displayData={displayArea} checkDisplay={checkDisplayAreaU} setcheckDisplayOcc={setcheckDisplayAreaU} />
  );

  const dividerRevenue = (
    <div style={{ padding: '24px 24px 0px' }}>
      <Divider style={{ marginBottom: '0px' }} />
    </div>
  );

  const handleSetPage = (value) => {
    setTableLeast((prev) => ({ ...prev, Page: value }));
  };

  const handleSetSize = (value) => {
    setTableLeast((prev) => ({ ...prev, Size: value }));
  };

  const handleFieldSort = (value) => {
    setTableLeast((prev) => ({ ...prev, field: value }));
  };

  const handleOrderSort = (value) => {
    setTableLeast((prev) => ({ ...prev, order: value }));
  };

  return (
    <div style={{ backgroundColor: '#FFFF' }}>
      <Spin spinning={revenueNetLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'RevenueandNetLeaseArea', defaultMessage: 'Revenue and Net Occupied Area' })}
            button={buttonExport}
            // optionCustom={['month']}
            // defaultDuration={'month'}
            setDate={setRevenueNetDate}
            date={true}
          />
        </div>
        <div>
          <TableRevenueLeastArea columns={columnLeast} data={revenueNet} />
        </div>
      </Spin>
      {/* TalaadThai Revenue */}
      {dividerRevenue}
      <Spin spinning={revenueOccLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'RevenueOccRateAndArr', defaultMessage: 'OCC Rate and ARR' })}
            button={buttonDisplayOcc}
            mutiSelect={true}
            sizeSelect={4}
            marketAllList={marketAllList}
            selectMarket={selectMarketOcc}
            setSelectMarket={setSelectMarketOcc}
            setDate={setRevenueOccDate}
            date={true}
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <OccRateArr visibleDatasets={checkDisplayOcc} setVisibleDatasets={setcheckDisplayOcc} chartData={charDataOcc} />
        </div>
        <div>
          <FilterOnTopTab button={buttonExport} onlyButton={true} />
        </div>
        <div>
          <TableRevenueLeastArea
            columns={columnOcc}
            data={revenueOcc}
            total={_.size(revenueOcc)}
            paginationPage={_.get(tableLeast, 'page')}
            setPaginationPage={handleSetPage}
            paginationShow={true}
            setPaginationSize={handleSetSize}
            paginationSize={_.get(tableLeast, 'size')}
            setFieldSort={handleFieldSort}
            setOrderSort={handleOrderSort}
          />
        </div>
      </Spin>
      {/*------------------------------------------------------------ */}
      {dividerRevenue}
      <Spin spinning={revenueAreaLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'RevenueAreaUtilization', defaultMessage: 'Area Utilization' })}
            button={buttonDisplayArea}
            mutiSelect={true}
            sizeSelect={4}
            marketAllList={marketAllList}
            selectMarket={selectMarketArea}
            setSelectMarket={setSelectMarketArea}
            setDate={setRevenueAreaDate}
            date={true}
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <AreaUtili visibleDatasets={checkDisplayArea} setVisibleDatasets={setcheckDisplayArea} chartData={charDataArea} />
        </div>
        <div>
          <FilterOnTopTab button={buttonExport} onlyButton={true} />
        </div>
        <div>
          <TableRevenueLeastArea
            columns={columnMarketGross}
            data={revenueArea}
            total={_.size(revenueArea)}
            paginationPage={_.get(tableLeast, 'page')}
            setPaginationPage={handleSetPage}
            paginationShow={true}
            setPaginationSize={handleSetSize}
            paginationSize={_.get(tableLeast, 'size')}
            setFieldSort={handleFieldSort}
            setOrderSort={handleOrderSort}
          />
        </div>
      </Spin>
      {/*------------------------------------------------------------ */}
      {dividerRevenue}
      <Spin spinning={revenueAreaGroupLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'RevenueAreaUtilizationMarketGroup', defaultMessage: 'Area Utilization by Market Group' })}
            button={buttonDisplayAreaU}
            mutiSelect={true}
            sizeSelect={4}
            marketAllList={marketAllGroupList}
            selectMarket={selectMarketAreaGroup}
            setSelectMarket={setSelectMarketAreaGroup}
            setDate={setRevenueAreaGroupDate}
            date={true}
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <AreaUtiliMarketGroup
            visibleDatasets={checkDisplayAreaU}
            setVisibleDatasets={setcheckDisplayAreaU}
            chartData={charDataAreaGroup}
          />
        </div>
        <div>
          <FilterOnTopTab button={buttonExport} onlyButton={true} />
        </div>
        <div>
          <TableRevenueLeastArea
            columns={columnMarketGross}
            data={revenueAreaGroup}
            total={_.size(revenueAreaGroup)}
            paginationPage={_.get(tableLeast, 'page')}
            setPaginationPage={handleSetPage}
            paginationShow={true}
            setPaginationSize={handleSetSize}
            paginationSize={_.get(tableLeast, 'size')}
            setFieldSort={handleFieldSort}
            setOrderSort={handleOrderSort}
          />
        </div>
      </Spin>
      {dividerRevenue}
      <div style={{ marginTop: '24px' }}></div>
    </div>
  );
};

export default TabRevenueOverview;
