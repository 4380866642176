import React, { useEffect, useState } from 'react';
import { Col, Divider, Form, Modal, Row } from 'antd';
import LabeRequireForm from '../../../../../components/label-required-form';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import ModalProjectPricingSelectSOForm from './modal-select-so';
import Button_02 from '../../../../../components/v2/button_02';
import { deletePricingList, getWeightPricing } from '../../../../../controllers/project-new/project-detail';
import { PageSettings } from '../../../../../config/page-settings';
import CustomTableComponent from './table';
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal';
import { errorNotification, successNotification } from '../../../../../components/v2/notification';

const ModalProjectViewPricing = (props) => {
  const intl = useIntl();
  const { visible, onCancel, selectedRecord, pricingValue, setPricingValue, setTrigger, handleEditPricing, handleCopyPricing } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [listWeight, setListWeight] = useState([])

  useEffect(() => {
    const getInfo = async () => {
      const getWeight = await getWeightPricing({});
      setListWeight(_.get(getWeight, 'data'));
    };
    getInfo();
  }, []);

  useEffect(() => {
    console.log('dataTableView', selectedRecord);

    const setTable = _.orderBy(_.map(_.get(selectedRecord, 'projectConfig'), (o) => {
      return {
        ...o,
        sourceName: o.source.addressName ? o.source.addressName : 'ไม่กำหนด',
        destinationName: o.destination.addressName ? o.destination.addressName : 'ไม่กำหนด',
        weightName: _.chain(listWeight).filter((weight) => weight._id === o.projectWeightCondId).get('[0].name').value(),
        itemName: o.itemList.itemName ? o.itemList.itemName : '',
        unitName: o.itemList.unitName ? o.itemList.unitName : '',
      }
    }), ['index'], ['asc'])
    setDataTable(setTable);
  }, [selectedRecord, listWeight]);

  console.log('dataTableView', dataTable)

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: <FormattedMessage id="columnsProjectPricingSourec" defaultMessage="Sourec" />,
      dataIndex: 'sourceName',
      key: 'sourceName',
      width: 200,
      sorter: (a, b) => a.sourceName.localeCompare(b.sourceName),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingDestination" defaultMessage="Destination" />,
      dataIndex: 'destinationName',
      key: 'destinationName',
      width: 200,
      sorter: (a, b) => a.destinationName.localeCompare(b.destinationName),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingWeightCondition" defaultMessage="Weight Condition" />,
      dataIndex: 'weightName',
      key: 'weightName',
      width: 200,
      sorter: (a, b) => a.weightName.localeCompare(b.weightName),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingGasPriceMin" defaultMessage="Gas Price (Minimum)" />,
      dataIndex: 'gasPriceMin',
      key: 'gasPriceMin',
      width: 200,
      align: 'right',
      sorter: (a, b) => a.gasPriceMin - b.gasPriceMin,
      render: (text, record, index) => (
        <span>
          {numberFormatDecimal(_.get(record, 'gasPriceMin'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
        </span>
      ),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingGasPriceMax" defaultMessage="Gas Price (Maximum)" />,
      dataIndex: 'gasPriceMax',
      key: 'gasPriceMax',
      align: 'right',
      width: 200,
      sorter: (a, b) => a.gasPriceMax - b.gasPriceMax,
      render: (text, record, index) => (
        <span>
          {numberFormatDecimal(_.get(record, 'gasPriceMax'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
        </span>
      ),
    },

    {
      title: <FormattedMessage id="columnsProjectPricingItemOrService" defaultMessage="Item or Service" />,
      dataIndex: 'itemName',
      key: 'itemName',
      width: 350,
    },

    {
      title: <FormattedMessage id="columnsProjectPricingUnit" defaultMessage="Unit" />,
      dataIndex: 'unitName',
      key: 'unitName',
      width: 200,
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      // render: (text, record, index) => _.get(record, 'itemList.unitName'),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingPriceUnit" defaultMessage="Price/Unit" />,
      dataIndex: 'pricePerUnit',
      key: 'pricePerUnit',
      width: 200,
      align: 'right',
      sorter: (a, b) => a.pricePerUnit - b.pricePerUnit,
      render: (text, record, index) => (
        <span>
          {numberFormatDecimal(_.get(record, 'pricePerUnit'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
        </span>
      ),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingRemark" defaultMessage="Remark" />,
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
    },
  ];

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleDelete = async () => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deletePricingList(selectedRecord._id);

        if (_.get(response, 'status.code') === 200) {
          successNotification(intl.formatMessage({ id: `projectPricingDeletedSuccess`, defaultMessage: 'Pricing Deleted Successfully' }));
          setTrigger((prev) => !prev);
          onCloseModal();
        } else {
          errorNotification(intl.formatMessage({ id: `projectPricingDeletedUnSuccess`, defaultMessage: 'Pricing Deleted UnSuccessfully' }));
        }
      },
      onCancel() {},
    });
  };

  const onCloseModal = () => {
    onCancel()
    setDataTable([])
  }

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Modal
            title={`${intl.formatMessage({ id: `modalProjectPricing`, defaultMessage: 'Pricing' })} · ${_.get(selectedRecord, 'referenceNo')}`}
            visible={visible}
            onCancel={onCloseModal}
            width={1120}
            bodyStyle={{ overflowY: 'auto' }}
            footer={[
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div>
                  <Button_01
                    style={{ margin: 'unset' }}
                    key="print"
                    type="primary"
                    btnsize="wd_df"
                    onClick={() => handleCopyPricing(selectedRecord)}
                  >
                    <FormattedMessage id="btnCopy" defaultMessage="Copy" />
                  </Button_01>
                </div>
                <div>
                  <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCloseModal()} margin=" unset">
                    <FormattedMessage id="btnClose" defaultMessage="Close" />
                  </Button_02>
                  {checkPermissionAction('P39PG3C1', 'P39PG3C1A3') ? (
                    <Button_01
                      style={{ margin: '0px 0px 0px 10px' }}
                      key="delete"
                      type="primary"
                      btnsize="wd_df"
                      onClick={() => handleDelete()}
                    >
                      <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                    </Button_01>
                  ) : null}
                  {checkPermissionAction('P39PG3C1', 'P39PG3C1A4') ? (
                    <Button_01 key="submit" type="edit" btnsize="wd_df" onClick={() => handleEditPricing()}>
                      <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                    </Button_01>
                  ) : null}
                </div>
              </div>,
            ]}
          >
            <div style={{ height: '550px' }}>
              <Form name="customer" colon={false}>
                <Row gutter={[24]}>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          bold={true}
                          text={intl.formatMessage({ id: 'invoiceLabelSO', defaultMessage: 'Sales Order No.' })}
                          // req={reqTrue}
                        />
                      }
                      className="form-line-height"
                    >
                      <p>{_.get(selectedRecord, 'referenceNo')}</p>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          bold={true}
                          text={intl.formatMessage({ id: 'invoiceSaleOrderDate', defaultMessage: 'Sales Order Date' })}
                        />
                      }
                    >
                      <p>{_.get(selectedRecord, 'createdDate')}</p>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          bold={true}
                          text={intl.formatMessage({ id: 'invoiceSOIssuedby', defaultMessage: 'SO Issued by' })}
                        />
                      }
                    >
                      <p>{_.get(selectedRecord, 'createdByName')}</p>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24]}>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          bold={true}
                          text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })}
                        />
                      }
                    >
                      <p>{_.get(selectedRecord, 'businessPartnerName')}</p>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          bold={true}
                          text={intl.formatMessage({ id: 'ProjectPricingContactCol', defaultMessage: 'Contact' })}
                        />
                      }
                    >
                      <p>{_.get(selectedRecord, 'contactName')}</p>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={
                        <LabeRequireForm
                          bold={true}
                          text={intl.formatMessage({ id: 'ProjectPricingContactPhoneCol', defaultMessage: 'Contact Phone' })}
                        />
                      }
                    >
                      <p>{_.get(selectedRecord, 'contactPhone')}</p>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[24]}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px' }}>
                    <span className="item-title-text">
                      <FormattedMessage id="projectPricingConfiguration" defaultMessage="Configuration" />
                    </span>
                    <div></div>
                  </div>
                  <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px', padding: 'unset' }} />

                  <div style={{ margin: '24px' }}>
                    <CustomTableComponent columns={columns} dataSource={dataTable} scroll={{ x: true }} role={true} onRow={true} />
                  </div>

                </Row>
              </Form>
            </div>
          </Modal>
          <ModalProjectPricingSelectSOForm
            visible={isOpen}
            setVisible={setIsOpen}
            onOk={handleOpen}
            onCancel={handleCancel}
            setPricingValue={setPricingValue}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default ModalProjectViewPricing;
