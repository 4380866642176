import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Card, Col, Row, Table, Button, Menu, Checkbox } from 'antd';
import { MonitorContext } from '../../transportation-context';
import ActionSearch from '../components/action-search-list';
import ActionTeam from '../components/action-team-list';
import '../css/list.css';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { getInitialColumns } from '../../../../controllers/initial-column';
import ModalPDFViewverOptionalColumn from '../../../../component-function/pdf-viewver-optional-column';
import { downloadPDFApi } from '../../../../controllers/pdf';
import { UrlPDF } from '../../../../component-function/generator-pdf';
const langValue = localStorage.getItem('langValue');

export default () => {
  const intl = useIntl();
  const { state, fnc, setState } = useContext(MonitorContext);
  const {
    initialData,
    mainOrgId,
    mainOrgIdStr,
    resourceData,
    searchValue,
    selectBy,
    orgData,
    orgIdRaw,
    searchValueList,
    selectByList,
    loadingListView,
    LangCode,
    openPdf,
  } = state;
  const { handleChangeOption, handleSearch, handleSelect, handleSelectList, handleSearchList } = fnc;
  const { setOrgData, setOrgIdRaw, setSearchValueList, setOpenPdf } = setState;

  const [column2, setColumn2] = useState({
    columns: [],
    setShowColumnArr: [],
    setShowColumn: {},
  });

  const ignoreColumn = useMemo(() => {
    return _.map(
      _.filter(column2.columns, (item) => {
        return item.default === false;
      }),
      (item) => {
        return item.dataIndex;
      }
    );
  }, [column2.columns]);

  // Compute the filtered columns based on the ignore list
  const newColumns = useMemo(() => {
    return column2.columns.filter((col) => !ignoreColumn.includes(col.key));
  }, [column2.columns]);

  // Compute the list array from the setShowColumnArr by excluding the ignored keys
  const newListArray = useMemo(() => {
    return _.difference(column2.setShowColumnArr, ignoreColumn);
  }, [column2.setShowColumnArr]);

  // Initialize state with computed values
  const [newDataColumns, setNewDataColumns] = useState(newColumns);
  const [actionColumns, setActionColumns] = useState(column2.columns);
  const [listArrayColumns, setListArrayColumns] = useState(newListArray);
  const [defaultShowColumn, setDefaultShowColumn] = useState(column2.setShowColumn);

  // const columns = [
  //   {
  //     title: '#',
  //     dataIndex: 'index',
  //     key: 'index',
  //     width: 70,
  //     align: 'center',
  //     fixed: 'left',
  //     render: (text, record, index) => record.index,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableTaskNo${LangCode}`, defaultMessage: 'Task No.' }),
  //     dataIndex: 'taskNo',
  //     key: 'taskNo',
  //     width: 150,
  //     fixed: 'left',
  //     // sorter: (a, b) => a.taskNo - b.taskNo,
  //     // sortDirections: ['descend', 'ascend'],
  //     render: (text, record, index) => (
  //       <a className="list-view-a-task-view" key={record.taskId} onClick={() => fnc.handleOnClickTaskListView(record)}>
  //         {record.taskNo}
  //       </a>
  //     ),
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableTaskName${LangCode}`, defaultMessage: 'Task Name' }),
  //     dataIndex: 'taskName',
  //     key: 'taskName',
  //     width: 250,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableTaskType${LangCode}`, defaultMessage: 'Task Type' }),
  //     dataIndex: 'taskType',
  //     key: 'taskType',
  //     width: 130,
  //     // fixed: 'left',
  //     // sorter: (a, b) => a.taskType - b.taskType,
  //     // sortDirections: ['descend', 'ascend'],
  //     render: (text, record, index) => <span>{record.taskType.taskTypeName}</span>,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableCustomer${LangCode}`, defaultMessage: 'Customer' }),
  //     dataIndex: 'customerName',
  //     key: 'customerName',
  //     width: 150,
  //     // fixed: 'left',
  //     // sorter: (a, b) => a.customerName - b.customerName,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableContactName${LangCode}`, defaultMessage: 'Contact Name' }),
  //     dataIndex: 'contactName',
  //     key: 'contactName',
  //     width: 250,
  //     // fixed: 'left',
  //     // sorter: (a, b) => a.contact - b.contact,
  //     // sortDirections: ['descend', 'ascend'],
  //     render: (text, record, index) => <span>{record.contactName}</span>,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableContactPhone${LangCode}`, defaultMessage: 'Contact Phone' }),
  //     dataIndex: 'contactPhone',
  //     key: 'contactPhone',
  //     width: 180,
  //     // sorter: (a, b) => a.contactPhone - b.contactPhone,
  //     // sortDirections: ['descend', 'ascend'],
  //     render: (text, record, index) => (
  //       <span>{_.get(record, 'contact.contactId') ? _.get(record, 'contact.phone') : _.get(record, 'contactPhone')}</span>
  //     ),
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableFromLocation${LangCode}`, defaultMessage: 'From Location' }),
  //     dataIndex: 'fromAddress',
  //     key: 'fromAddress',
  //     width: 300,
  //     render: (text, record, index) => (
  //       <span title={record.fromAddress} className="span-address-ellipsis">
  //         {record.fromAddress}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableToLocation${LangCode}`, defaultMessage: 'To Location' }),
  //     dataIndex: 'deliveryAddress',
  //     key: 'deliveryAddress',
  //     width: 300,
  //     render: (text, record, index) => (
  //       <span title={record.deliveryAddress} className="span-address-ellipsis">
  //         {record.deliveryAddress}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableStatus${LangCode}`, defaultMessage: 'Status' }),
  //     dataIndex: 'status',
  //     key: 'status',
  //     width: 180,
  //     // sorter: (a, b) => a.status - b.status,
  //     // sortDirections: ['descend', 'ascend'],
  //     render: (text, record, index) => <span>{record.status.statusName}</span>,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableStartDate${LangCode}`, defaultMessage: 'Start Date' }),
  //     dataIndex: 'startDateView',
  //     key: 'startDateView',
  //     width: 200,
  //     // sorter: (a, b) => a.startDateView - b.startDateView,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableDueDate${LangCode}`, defaultMessage: 'Due Date' }),
  //     dataIndex: 'endDateView',
  //     key: 'endDateView',
  //     width: 200,
  //     // sorter: (a, b) => a.endDateView - b.endDateView,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableActualStartDate${LangCode}`, defaultMessage: 'Actual Start Date' }),
  //     dataIndex: 'actualStartDate',
  //     key: 'actualStartDate',
  //     width: 200,
  //     // sorter: (a, b) => a.actualStartDate - b.actualStartDate,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableActualCompletedDate${LangCode}`, defaultMessage: 'Actual Completed Date' }),
  //     dataIndex: 'actualCompletedDate',
  //     key: 'actualCompletedDate',
  //     width: 200,
  //     // sorter: (a, b) => a.actualCompletedDate - b.actualCompletedDate,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorMapPopoverReference${LangCode}`, defaultMessage: 'Reference' }),
  //     dataIndex: 'reference',
  //     key: 'reference',
  //     width: 300,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableRemark${LangCode}`, defaultMessage: 'Remark' }),
  //     dataIndex: 'remark',
  //     key: 'remark',
  //     width: 300,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableReference1${LangCode}`, defaultMessage: 'Reference 1' }),
  //     dataIndex: 'reference1',
  //     key: 'reference1',
  //     width: 200,
  //     // sorter: (a, b) => a.reference1 - b.reference1,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableReference2${LangCode}`, defaultMessage: 'Reference 2' }),
  //     dataIndex: 'reference2',
  //     key: 'reference2',
  //     width: 200,
  //     // sorter: (a, b) => a.reference2 - b.reference2,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableReference3${LangCode}`, defaultMessage: 'Reference 3' }),
  //     dataIndex: 'reference3',
  //     key: 'reference3',
  //     width: 200,
  //     // sorter: (a, b) => a.reference3 - b.reference3,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableReference4${LangCode}`, defaultMessage: 'Reference 4' }),
  //     dataIndex: 'reference4',
  //     key: 'reference4',
  //     width: 200,
  //   },
  //   {
  //     title: intl.formatMessage({ id: `monitorTabListViewTableCreatedDate${LangCode}`, defaultMessage: 'Created Date' }),
  //     dataIndex: 'createdDate',
  //     key: 'createdDate',
  //     width: 200,
  //     // sorter: (a, b) => a.createdDate - b.createdDate,
  //     // sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: intl.formatMessage({ id: `reportTaskReportViewTableAssigneeName${LangCode}`, defaultMessage: 'Assignee Name' }),
  //     dataIndex: 'assignee',
  //     key: 'assignee',
  //     width: 300,
  //     // sorter: (a, b) => a.assigneeName - b.assigneeName,
  //     // sortDirections: ['descend', 'ascend'],
  //     render: (text, record, index) => (
  //       <span title={record.assignee} className="span-address-ellipsis">
  //         {record.assignee}
  //       </span>
  //     ),
  //   },
  // ];

  // const setShowColumn = {
  //   index: true,
  //   taskNo: true,
  //   taskName: true,
  //   taskType: true,
  //   customerName: true,
  //   contactName: true,
  //   contactPhone: false,
  //   fromAddress: false,
  //   deliveryAddress: false,
  //   status: false,
  //   startDateView: true,
  //   endDateView: true,
  //   actualStartDate: true,
  //   actualCompletedDate: true,
  //   reference: false,
  //   remark: false,
  //   reference1: false,
  //   reference2: false,
  //   reference3: false,
  //   reference4: false,
  //   createdDate: false,
  //   assignee: true,
  // };
  // console.log('columnsssssss', columns);

  const setShowColumnArr = [
    'index',
    'taskNo',
    'taskName',
    'taskType',
    'customerName',
    'contactName',
    'startDateView',
    'endDateView',
    'actualStartDate',
    'actualCompletedDate',
    'assignee',
  ];

  // const ignoreColumn = [
  //   'contactPhone',
  //   'fromAddress',
  //   'deliveryAddress',
  //   'status',
  //   'reference',
  //   'remark',
  //   'reference1',
  //   'reference2',
  //   'reference3',
  //   'reference4',
  //   'createdDate',
  // ];
  // const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = [
    'index',
    'startDateView',
    'endDateView',
    'actualStartDate',
    'actualCompletedDate',
    'createdDate',
    'status',
    'reference4',
  ];
  const columnNewSearch = column2.columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  // const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  // const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  // const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [visible, setVisible] = useState(false);
  const [scrollTableX, setScrollTableX] = useState(2000);

  const [columnWidthMaximum, setColumnWidthMaximum] = useState(0);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);

  //ใช้แมปปิ้งเข้า table
  const mapColumnTable = ({ cols }) => {
    const mapColumn = _.map(cols, (o) => {
      const notShow = _.includes(
        [
          'contactPhone',
          'fromAddress',
          'deliveryAddress',
          'status',
          'reference',
          'remark',
          'reference1',
          'reference2',
          'reference3',
          'reference4',
          'createdDate',
        ],
        o.columnCode
      )
        ? false
        : true;

      const obj = {
        // title: langValue === 'EN' ? o.columnName.en : o.columnName.th,
        title: intl.formatMessage({ id: o.columnLanguageCode, defaultMessage: o.columnName.en }),
        dataIndex: o.columnCode,
        key: o.columnCode,
        width: o.columnWidth.web || undefined,
        widthPdf: o.columnWidth.pdf,
        widthExcel: o.columnWidth.excel,
        align: o.columnAlign,
        fixed: o.columnFixed,
        sorter: o.columnSorter,
        default: notShow,
      };
      if (o.columnCode === 'taskNo') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => (
            <a className="list-view-a-task-view" key={record.taskId} onClick={() => fnc.handleOnClickTaskListView(record)}>
              {record.taskNo}
            </a>
          ),
        };
      } else if (o.columnCode === 'taskType') {
        console.log('billingStatus', o);

        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => <span>{record.taskType.taskTypeName}</span>,
        };
      } else if (o.columnCode === 'contactPhone') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => (
            <span>{_.get(record, 'contact.contactId') ? _.get(record, 'contact.phone') : _.get(record, 'contactPhone')}</span>
          ),
        };
      } else if (o.columnCode === 'fromAddress') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => (
            <span title={record.fromAddress} className="span-address-ellipsis">
              {record.fromAddress}
            </span>
          ),
        };
      } else if (o.columnCode === 'deliveryAddress') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => (
            <span title={record.deliveryAddress} className="span-address-ellipsis">
              {record.deliveryAddress}
            </span>
          ),
        };
      } else if (o.columnCode === 'status') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => <span>{record.status.statusName}</span>,
        };
      } else if (o.columnCode === 'assignee') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => (
            <span title={record.assignee} className="span-address-ellipsis">
              {record.assignee}
            </span>
          ),
        };
      }

      return {
        ...o,
        ...obj,
      };
    });

    const rejectKeys = ['team.teamName'];
    const filteredColumns = _.filter(mapColumn, (column) => !_.includes(rejectKeys, column.dataIndex));
    const mapKeyColumns = _.map(filteredColumns, (column) => column.dataIndex);
    const mapShowColumn = _.reduce(
      mapColumn,
      (obj, item) => {
        obj[item.dataIndex] = item.default;
        return obj;
      },
      {}
    );

    console.log('xxxxxxx', mapColumn, mapKeyColumns, mapShowColumn);

    return { col: mapColumn, keyShowArr: mapKeyColumns, keyShow: mapShowColumn };
  };

  // Update states when columnNormal2 changes
  useEffect(() => {
    setNewDataColumns(newColumns);
    setActionColumns(column2.columns);
    setListArrayColumns(newListArray);
    setDefaultShowColumn(column2.setShowColumn);
  }, [column2, newColumns, newListArray]);

  useEffect(() => {
    const getApi = async () => {
      const response = await getInitialColumns({ tableCode: 'transportationMonitorListView' });
      const getColumns = _.get(response, 'data.data.tableConfig.columns');
      console.log('getColumns', getColumns);
      const getColumnWidthMaximum = _.get(response, 'data.data.tableConfig.columnWidthMaximum');
      const { col, keyShowArr, keyShow } = mapColumnTable({ cols: getColumns });
      setColumn2({ columns: col, setShowColumnArr: keyShowArr, setShowColumn: keyShow });
      setColumnWidthMaximum(getColumnWidthMaximum);
    };
    getApi();
  }, []);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < column2.columns.length; i++) {
      const found = listArrayColumns.find((element) => element == column2.columns[i].key);
      if (found != undefined) {
        setNewArr.push(column2.columns[i]);
      }
    }

    if (_.size(setNewArr) <= 6) {
      setScrollTableX(1000);
    } else if (_.size(setNewArr) <= 9) {
      setScrollTableX(1500);
    } else if (_.size(setNewArr) <= 12) {
      setScrollTableX(2000);
    } else if (_.size(setNewArr) <= 15) {
      setScrollTableX(3000);
    } else if (_.size(setNewArr) <= 17) {
      setScrollTableX(3800);
    } else if (_.size(setNewArr) <= 19) {
      setScrollTableX(3800);
    }
    {
    }

    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });

    console.log('newListArrayColumns', newColumns, newListArrayColumns);

    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(column2.setShowColumn);
    setTextErrorSelectColumn('');
    setScrollTableX(2000);
  };

  const handlePagination = (page) => {
    setState.setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setState.setPaginationSize(size);
    setState.setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setState.setOrderSort('desc');
      } else {
        setState.setOrderSort('asc');
      }
    } else {
      setState.setOrderSort('');
    }
    setState.setFieldSort(field);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };
  console.log('newDataColumns', newDataColumns);

  //คอลัมแบบเก่าต้องหา default แบบใหม่เอาไปใช้ได้เลย
  const getColumnsWithVisibility = () => {
    const findKeyIndex = _.filter(actionColumns, (o) => o.key !== 'index');

    return findKeyIndex.map((col) => ({
      ...col,
      default: defaultShowColumn[col.key] || false,
    }));
  };

  //เรียก  pdf ครั้งแรก
  useEffect(() => {
    setLoadingPdf(true);

    const getApi = async () => {
      const mapColumnChecked = _.chain(getColumnsWithVisibility())
        .filter((o) => o.default !== false)
        .map((o) => o.dataIndex)
        .value();

      let mapColumnCheckedAdd = [...mapColumnChecked];
      const body = {
        filter: {...state.listViewPayload, rowsPerPages: 10000},
        printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
      };

      console.log('handleApply body', body);

      const response = await downloadPDFApi({ tableCode: 'transportationMonitorListView', body: body });
      console.log('handleApply response', response);

      if (_.get(response, 'status') === 200) {
        const url = await UrlPDF(response);
        console.log('handleApply url', url);

        if (url) {
          setUrlPdf(url);
          setLoadingPdf(false);
        }
      } else {
        setUrlPdf(null);
        setLoadingPdf(false);
      }
    };

    if (openPdf) {
      getApi();
    }
  }, [openPdf]);

  //เปิด pdf
  const handlePrintPdf = () => {
    setOpenPdf(true);
  };

  //apply column pdf
  const handleApply = async (columnChecked) => {
    setLoadingPdf(true);
    const mapColumnChecked = _.chain(columnChecked)
      .filter((o) => o.default !== false)
      .map((o) => o.dataIndex)
      .value();

    let mapColumnCheckedAdd = [...mapColumnChecked];
    const body = {
      filter: {...state.listViewPayload, rowsPerPages: 10000},

      printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
    };

    console.log('handleApply body', body);

    const response = await downloadPDFApi({ tableCode: 'transportationMonitorListView', body: body });
    console.log('handleApply response', response);

    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      console.log('handleApply url', url);

      if (url) {
        setUrlPdf(url);
        setLoadingPdf(false);
      }
    } else {
      setUrlPdf(null);
      setLoadingPdf(false);
    }
  };

  return (
    <div className="container-list">
      <div className="container-list-header">
        <div>
          <ActionSearch
            handleSearch={handleSearchList}
            handleSelect={handleSelectList}
            searchValue={searchValueList}
            selectBy={selectByList}
            selectSearch={columnNewSearch}
            setInputValue={setState.setInputValue}
            setSearchValue={setSearchValueList}
            setPaginationPage={setState.setPaginationPage}
          />
        </div>
        <div className="action-team-all">
          <ActionTeam
            orgData={orgData}
            setOrgData={setOrgData}
            mainOrgId={mainOrgId}
            handleChangeOption={handleChangeOption}
            handleOnVisiblecolumns={handleOnVisiblecolumns}
            handleVisibleChange={handleVisibleChange}
            visible={visible}
            columns={column2.columns}
            checkedValuecolumns={checkedValuecolumns}
            defaultShowColumn={defaultShowColumn}
            textErrorSelectColumn={textErrorSelectColumn}
            handleCheckOk={handleCheckOk}
            handleReset={handleReset}
            setOrgIdRaw={setOrgIdRaw}
            orgIdRaw={orgIdRaw}
          />
        </div>
      </div>
      <div style={{ padding: '16px 26px' }}>
        <Table
          onChange={handleChange}
          loading={loadingListView}
          columns={newDataColumns}
          // scroll={{ x: scrollTableX, y: `calc(100vh - 360px)` }}
          scroll={{ x: _.sumBy(newDataColumns, 'width'), y: 'calc(100vh - 425px)' }}
          dataSource={state.listView}
          pagination={{
            total: state.total,
            current: state.paginationPage,
            showTotal: showTotal,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
            onChange: handlePagination,
            onShowSizeChange: handleSizeChange,
          }}
        />
        {state.total ? (
          <div className="total-items-overtime-report">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${state.total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </div>

      <ModalPDFViewverOptionalColumn
        paperSize={columnWidthMaximum}
        paperIndexSize={10}
        paperUnit={intl.formatMessage({ id: 'unitCm', defaultMessage: 'cm.' })}
        visible={openPdf}
        url={urlPdf}
        loading={loadingPdf}
        setVisible={setOpenPdf}
        handleApply={handleApply}
        column={getColumnsWithVisibility()} //actionColumn
      />
    </div>
  );
};
