import React from 'react';
import ChartLineBar from '../../component/chart-line-bar';

const Revenue = (prop) => {
  // const { chartData } = prop;

  const chartData = [
    { name: 'Jan 24', bar1: 58, bar2: 31, bar3: 58, bar4: 56, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 28, line2: 36, line3: 29, line4: 40, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Feb 24', bar1: 59, bar2: 44, bar3: 56, bar4: 35, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 23, line2: 37, line3: 31, line4: 40, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Mar 24', bar1: 26, bar2: 27, bar3: 41, bar4: 48, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 29, line2: 30, line3: 30, line4: 34, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Apr 24', bar1: 34, bar2: 26, bar3: 54, bar4: 42, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 22, line2: 39, line3: 35, line4: 35, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'May 24', bar1: 38, bar2: 23, bar3: 43, bar4: 53, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 26, line2: 36, line3: 31, line4: 36, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Jun 24', bar1: 53, bar2: 59, bar3: 30, bar4: 55, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 23, line2: 31, line3: 26, line4: 34, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Jul 24', bar1: 20, bar2: 20, bar3: 28, bar4: 51, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 26, line2: 36, line3: 28, line4: 37, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Aug 24', bar1: 32, bar2: 30, bar3: 43, bar4: 30, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 22, line2: 30, line3: 30, line4: 40, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Sep 24', bar1: 21, bar2: 39, bar3: 34, bar4: 52, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 20, line2: 30, line3: 34, line4: 34, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Oct 24', bar1: 60, bar2: 55, bar3: 28, bar4: 30, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 29, line2: 31, line3: 32, line4: 40, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Nov 24', bar1: 22, bar2: 50, bar3: 34, bar4: 39, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 24, line2: 30, line3: 33, line4: 39, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' },
    { name: 'Dec 24', bar1: 29, bar2: 25, bar3: 39, bar4: 48, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4', line1: 25, line2: 39, line3: 35, line4: 35, lineName1: 'Mock ตลาด1', lineName2: 'Mock ตลาด2', lineName3: 'Mock ตลาด3', lineName4: 'Mock ตลาด4' }
  ]  

  const chartConfig = {
    yLeft: "Revenue (MB)",
    yAxisBarId: "left",
    yAxisLineId: "left",
    domain: [0, 100],
  }

  const CustomLegend = ({ payload, barNames, lineNames }) => {
    const barItems = payload.filter((entry) => entry.dataKey.startsWith('bar'));
    const lineItems = payload.filter((entry) => entry.dataKey.startsWith('line'));

    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '100px', marginTop: '15px', lineHeight: '30px' }}>
        <div>
          {barItems.map((entry, index) => (
            <div key={entry.dataKey} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ width: '12px', height: '12px', backgroundColor: entry.color }}></div>
              <span>Revenue (A) - {barNames[entry.dataKey] || entry.value}</span>
            </div>
          ))}
        </div>

        <div>
          {lineItems.map((entry, index) => (
            <div key={entry.dataKey} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ width: '12px', height: '2px', backgroundColor: entry.color }}></div>
              <span>Revenue (B) - {lineNames[entry.dataKey] || entry.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return <ChartLineBar data={chartData} config={chartConfig} CustomLegend={CustomLegend}/>;
};

export default Revenue;
