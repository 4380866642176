import React, { useContext, useEffect, useState } from 'react';
import { PageSettings } from '../../config/page-settings';
import { SaleOrderContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, Dropdown, Menu, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';

import Button_01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import WaitingQuotation from './component-waiting/waiting';
import ApprovedQuotation from './component-approved/approved';
import RejectedQuotation from './component-rejected/rejected';
import CanceledQuotation from './component-canceled/canceled';
// import QuotationModal from './modal/quotation/quotation-modal';
import ModalCustomerSaleOrderForm from './modal/saleorder/saleorder-modal';
import UploadFileModal from './modal/upload-file/upload-modal';
import './css/index.css';
import _ from 'lodash';
import moment from 'moment';
import { getConfigMenu } from '../../controllers/get-config-menu/get-config-menu-api';
import DynamicQuotation from './dynamic/dynamic-quotation';
const { TabPane } = Tabs;

const SaleOrderPage = () => {
  const intl = useIntl();
  const { state, setState, func } = useContext(SaleOrderContext);
  const {
    isOpenQuotation,
    openUpload,
    attachmentData,
    typeAttachment,
    defaultAttachment,
    initialMemberList,
    record,
    reportDataWaiting,
    tabKey,
  } = state;
  const {
    seIsOpenQuotation,
    setAttachmentData,
    setTypeAttachment,
    setDefaultAttachment,
    setOpenUpload,
    setTabKey,
    setReportDataDynamic,
    setReportDataWaiting,
    setReportDataApproved,
    setReportDataRejected,
    setReportDataCanceled,
  } = setState;
  const {
    handleOpenModalQuotation,
    handleSaveQuotation,
    handleCancelQuotation,
    refreshModalSaleOrder,
    handleExport,
    handleSaveDelete,
  } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataWaiting;

  const [statusMap, setStatusMap] = useState([]);
  const [tabAllMenu, setTabAllMenu] = useState({});
  const [nextStatus, setNextStatus] = useState([]);
  const [visible, setVisible] = useState(false);

  console.log('record', record);
  console.log('nextStatus', nextStatus, tabAllMenu, _.get(record, 'code'), tabKey);

  useEffect(() => {
    const fetchConfigMenu = async () => {
      try {
        const response = await getConfigMenu('SO_status_wf');
        if (response && response.statusMap) {
          setStatusMap(response.statusMap);
          setTabAllMenu(response.config_val);
        }
      } catch (error) {
        console.error('Failed to fetch config menu', error);
      }
    };

    fetchConfigMenu();
  }, []);

  useEffect(() => {
    if (tabAllMenu && tabKey) setNextStatus(tabAllMenu[tabKey]);
  }, [tabKey, tabAllMenu]);

  const handleOpenModal = () => {
    handleOpenModalQuotation();
    setVisible(!visible);
  };

  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A2`) ? ( */}
        <Menu.Item key="1">
          <Button key="1" ghost type="link" style={{ padding: 'unset' }} onClick={() => handleOpenModal()}>
            <FormattedMessage id="quotationLabelCreateSalesOrder" defaultMessage="Create Sale Order" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
        {/* {checkPermissionAction(`P49PG2C2`, `P49PG2C2A3`) ? ( */}
        <Menu.Item key="2">
          <Button key="2" ghost type="link" style={{ padding: 'unset' }} onClick={() => handleExport()}>
            <FormattedMessage id="quotationLabelExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
        {/* ) : null} */}
      </Menu>
      //   )}
      // </PageSettings.Consumer>
    );
  };

  const handleTabChange = (key) => {
    setTabKey(key);
    const currentDateRange = [
      moment()
        .subtract(2, 'months')
        .startOf('month'),
      moment().endOf('month'),
    ];
    if (key === 'draft') {
      setReportDataDynamic((prev) => ({ ...prev, rangePickerDate: currentDateRange }));
    } else if (key === 'torevise') {
      setReportDataDynamic((prev) => ({ ...prev, rangePickerDate: currentDateRange }));
    } else if (key === 'waiting') {
      setReportDataDynamic((prev) => ({ ...prev, rangePickerDate: currentDateRange }));
    } else if (key === 'approved') {
      setReportDataApproved((prev) => ({ ...prev, rangePickerDate: currentDateRange }));
    } else if (key === 'rejected') {
      setReportDataDynamic((prev) => ({ ...prev, rangePickerDate: currentDateRange }));
    } else if (key === 'canceled') {
      setReportDataDynamic((prev) => ({ ...prev, rangePickerDate: currentDateRange }));
    }
  };

  const renderTabContent = (key) => {
    switch (key) {
      case 'draft':
        return <DynamicQuotation tabKey={tabKey} records={record} handleSaveDelete={handleSaveDelete} />;
      case 'torevise':
        return <DynamicQuotation tabKey={tabKey} />;
      case 'waiting':
        return <WaitingQuotation />;
      case 'approved':
        return <ApprovedQuotation />;
      case 'rejected':
        return <RejectedQuotation />;
      case 'canceled':
        return <CanceledQuotation />;
      default:
        return null;
    }
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card className="monitor-main-card">
                <StickyContainer>
                  <Tabs
                    className="monitor-main-tabs customer-tab-margin"
                    size="large"
                    defaultActiveKey="waiting"
                    activeKey={tabKey}
                    onChange={handleTabChange}
                    animated={false}
                    tabBarExtraContent={
                      <Dropdown
                        overlay={menuColumn}
                        trigger={['click']}
                        onVisibleChange={setVisible}
                        visible={visible}
                        placement="bottomRight"
                        className="ant-dropdown-custom"
                      >
                        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                          <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                          <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                        </Button_01>
                      </Dropdown>
                    }
                  >
                    {/* <TabPane
                      tab={intl.formatMessage({ id: `quotationLabelWaiting`, defaultMessage: 'Waiting' }) + ` (${quotationTotal})`}
                      key="waiting"
                    >
                      <WaitingQuotation />
                    </TabPane>

                    <TabPane tab={intl.formatMessage({ id: `quotationLabelApproved`, defaultMessage: 'Approved' })} key="approved">
                      <ApprovedQuotation />
                    </TabPane>

                    <TabPane tab={intl.formatMessage({ id: `quotationLabelRejected`, defaultMessage: 'Rejected' })} key="rejected">
                      <RejectedQuotation />
                    </TabPane>

                    <TabPane tab={intl.formatMessage({ id: `quotationLabelCanceled`, defaultMessage: 'Canceled' })} key="canceled">
                      <CanceledQuotation />
                    </TabPane> */}
                    {statusMap.map((status) => (
                      <TabPane
                        tab={
                          `${intl.formatMessage({ id: `quotationLabel${_.capitalize(status.code)}`, defaultMessage: status.code })}`
                          // status.code === 'waiting'
                          //   ? `${intl.formatMessage({
                          //       id: `quotationLabel${_.capitalize(status.code)}`,
                          //       defaultMessage: status.code,
                          //     })} (${quotationTotal})`
                          //   : `${intl.formatMessage({ id: `quotationLabel${_.capitalize(status.code)}`, defaultMessage: status.code })}`
                        }
                        key={status.code}
                      >
                        {renderTabContent(status.code)}
                      </TabPane>
                    ))}
                  </Tabs>
                </StickyContainer>
              </Card>
            </Col>
          </Row>

          <ModalCustomerSaleOrderForm
            title={
              tabKey === 'draft' && _.get(record, 'code')
                ? `${intl.formatMessage({ id: `quotationLabelSalesOrder`, defaultMessage: 'Sales Order' })}`
                : _.get(record, 'code') === 'view'
                ? `${intl.formatMessage({ id: `quotationLabelSalesOrder`, defaultMessage: 'Sales Order' })} · ${record.saleOrderNo}`
                : _.get(record, 'code') === 'edit'
                ? `${intl.formatMessage({ id: `quotationLabelEditSalesOrder`, defaultMessage: 'Edit Sales Order' })} · ${
                    record.saleOrderNo
                  }`
                : intl.formatMessage({ id: `quotationLabelCreateSalesOrder`, defaultMessage: 'Create Sales Order' })
            }
            visible={isOpenQuotation}
            onOk={handleSaveQuotation}
            onCancel={handleCancelQuotation}
            initialMemberList={initialMemberList}
            attData={attachmentData}
            setAttData={setAttachmentData}
            record={record}
            refreshModalSaleOrder={refreshModalSaleOrder}
            nextStatus={nextStatus}
            tabMain={tabKey}
          />

          <UploadFileModal
            visible={openUpload}
            setVisible={setOpenUpload}
            setAttData={setAttachmentData}
            attData={attachmentData}
            typeAtt={typeAttachment}
            defaultAtt={defaultAttachment}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default SaleOrderPage;
