import { Col, DatePicker, Divider, Form, Icon, Input, InputNumber, Modal, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Button02 from '../../../../components/v2/button_02';
import Button01 from '../../../../components/v2/button_02';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import { PageSettings } from '../../../../config/page-settings';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../component-function/fnc-number';
import moment from 'moment';
import ModalSelectItemProjectGas from '../../../../components/modal-select-item-project';
import { getItemBasePay } from '../../../../controllers/base-pay/basepay';
import { editGasApi, getGasTypeList, saveGasApi } from '../../../../controllers/project-new/project-setting';
import { errorNotification, successNotification } from '../../../../components/v2/notification';

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const ModalGas = (props) => {
  const intl = useIntl();
  const { visible, setVisibel, setTrigger, record, setRecord, id } = props;
  const { form } = props;
  const { getFieldDecorator, resetFields, getFieldValue, setFieldsValue } = form;

  const [open, setOpen] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [listItem, setListItem] = useState([]);

  console.log('ModalGas', record, listItem);
  console.log('getFieldValue', getFieldValue('startDate'));

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        limit: 10000,
        pageNumber: 1,
      };

      const response = await getGasTypeList(body);

      console.log('getGasTypeList', response);

      if (_.get(response, 'status.code') === 200) {
        const data = _.get(response, 'data.data');
        const filterItemAction = _.filter(data, (item) => item.gasStatus === 'Active');
        setListItem(filterItemAction);
      }
    };
    getAPI();
  }, [triggerModal]);

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (values.gasPrice === 0 || err) {
        if (values.gasPrice === 0) {
          form.setFields({
            gasPrice: {
              value: values.gasPrice,
              errors: [
                new Error(
                  intl.formatMessage({
                    id: 'projectSettingGasValidateGasPriceZero',
                    defaultMessage: 'Please enter a number greater than 0.',
                  })
                ),
              ],
            },
          });
        }

        return;
      }

      const objReim = _.chain(listItem)
        .filter((item) => item._id === values.gasType)
        .get('[0]')
        .value();

      if (!objReim) {
        errorNotification(intl.formatMessage({ id: 'projectSettingErrorItemInactive', defaultMessage: 'Item Inactive' }));
        return;
      }

      const body =
        _.get(record, 'action') === 'edit'
          ? {
              _id: _.get(record, '_id'),
              gasTypeId: values.gasType,
              proJectId: id,
              gasPrice: _.toNumber(values.gasPrice),
              startDate: moment(values.startDate).format('DD/MM/YYYY'),
              endDate: moment(values.endDate).format('DD/MM/YYYY'),
            }
          : {
              gasTypeId: values.gasType,
              proJectId: id,
              gasPrice: _.toNumber(values.gasPrice),
              startDate: moment(values.startDate).format('DD/MM/YYYY'),
              endDate: moment(values.endDate).format('DD/MM/YYYY'),
            };

      console.log('handleSave', values, record, body, objReim);

      try {
        const response = _.get(record, 'action') === 'edit' ? await editGasApi(body) : await saveGasApi(body);

        console.log('saveReimbursement', response);

        if (_.get(response, 'status.code') === 200) {
          successNotification(_.get(response, 'status.message'));
          handleCancel();
        } else {
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        errorNotification(error.response.data.data.message);
      }

      setTrigger((event) => !event);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setRecord({});
    setVisibel(false);
  };

  let option = listItem.map((item, index) => (
    <Option key={item._id} value={item._id}>
      {item.gasTypeName}
    </Option>
  ));

  const onSelectItem = (itemId) => {
    setFieldsValue({
      ['gasType']: itemId,
    });
  };

  const onChangeStartDate = (startDate) => {
    setFieldsValue({
      ['endDate']: undefined,
    });
  };

  const disabledDate = (current) => {
    return current && current < moment(getFieldValue('startDate')).add(1, 'day');
  };

  const checkItem = () => {
    const gasType = getFieldValue('gasType');
    if (_.get(record, 'projectGasType[0].gasTypeName') === gasType) {
      // สำหรับ edit ที่เป็น inactive ถ้ากลับมาไม่ต้องเคลียร์ค่า
    } else if (gasType) {
      const filterItem = _.filter(listItem, (item) => item._id === gasType);
      if (_.size(filterItem) === 0) {
        setFieldsValue({
          ['gasType']: undefined,
        });
      }
    }
  };

  const validateGasPrice = (rule, value, callback) => {
    const gasPrice = _.toNumber(value);
    if (value) {
      if (gasPrice === 0) {
        callback(
          intl.formatMessage({
            id: 'projectSettingGasValidateGasPriceZero',
            defaultMessage: 'Please enter a number greater than 0.',
          })
        );
      } else if (gasPrice > 10000) {
        callback(
          intl.formatMessage({
            id: 'projectSettingGasValidateGasPriceMax',
            defaultMessage: 'The number exceeds limitation.',
          })
        );
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  return (
    <div>
      <Modal
        visible={visible}
        onOk={handleSave}
        onCancel={handleCancel}
        className="button-modal"
        title={
          _.get(record, 'action') === 'edit' ? (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557', margin: '0px' }}>
              <FormattedMessage id="projectDetailSettingModalEditGas" defaultMessage="Edit Gas" />
            </Title>
          ) : (
            <Title style={{ fontSize: '15px', fontWeight: 400, color: '#1D3557', margin: '0px' }}>
              <FormattedMessage id="projectDetailSettingModalAddGas" defaultMessage="Add Gas" />
            </Title>
          )
        }
        footer={[
          <Button02
            style={{ margin: '0px 0px 0px 10px', fontSize: '13px' }}
            className="btn-style-new"
            key="back"
            fontsize="sm"
            btnsize="wd_df"
            onClick={handleCancel}
          >
            <FormattedMessage id="btnCloseBasePay" defaultMessage="Close" />
          </Button02>,
          <Button01
            style={{ fontSize: '13px' }}
            className="btn-style-new2"
            key="submit"
            fontsize="sm"
            btnsize="wd_df"
            type="primary"
            onClick={handleSave}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button01>,
        ]}
      >
        <Form layout="vertical">
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'projectSettingLabGasType', defaultMessage: 'Gas Type' })} req={true} />
                }
              >
                {getFieldDecorator('gasType', {
                  initialValue: _.size(_.filter(listItem, (item) => item._id === _.get(record, 'gasTypeId')))
                    ? _.get(record, 'gasTypeId')
                    : _.get(record, 'projectGasType[0].gasTypeName'),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'projectSettingGasValidateGasType',
                        defaultMessage: 'Please Select Gas Type',
                      }),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({ id: 'projectSettingGasPlaceGasType', defaultMessage: 'Select Gas Type' })}
                    className="user-management-role-select"
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <PageSettings.Consumer>
                          {({ checkPermissionAction }) => (
                            <>
                              {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A5') ? (
                                <div>
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div
                                    style={{ padding: '4px 8px', cursor: 'pointer' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => {
                                      setOpen(true);
                                    }}
                                  >
                                    <Icon type="plus" />{' '}
                                    <FormattedMessage id="projectSettingAddGasType" defaultMessage="Add Gas Type" />
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </PageSettings.Consumer>
                      </div>
                    )}
                  >
                    {option}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'projectSettingLabGasPrice', defaultMessage: 'Gas Price' })} req={true} />
                }
              >
                {getFieldDecorator('gasPrice', {
                  initialValue: _.get(record, 'gasPrice', 0.0),
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'projectSettingGasValidateGasPrice',
                        defaultMessage: 'Please enter Gas Price',
                      }),
                    },
                    {
                      validator: validateGasPrice,
                    },
                  ],
                })(
                  <Input
                    className="disabled-handle-count"
                    placeholder={intl.formatMessage({ id: 'projectSettingGasPlaceGasPrice', defaultMessage: 'Enter Gas Price' })}
                    suffix="THB"
                    onKeyDown={handleKeyDownDecimal2Fixed}
                    onPaste={e=>{
                      e.preventDefault();
                      return false
                    }}
                    // formatter={currencyFormatter}
                    // parser={currencyParser}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'projectSettingLabEffectiveStartDate', defaultMessage: 'Effective Start Date' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('startDate', {
                  initialValue: _.get(record, 'startDate') ? moment(_.get(record, 'startDate'), 'DD/MM/YYYY') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'projectSettingGasValidateStartDate',
                        defaultMessage: 'Please Select Effective Start Date',
                      }),
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    format={'DD/MM/YYYY'}
                    style={{ width: '100%' }}
                    onChange={onChangeStartDate}
                    placeholder={intl.formatMessage({
                      id: 'projectSettingGasPlaceStartDate',
                      defaultMessage: 'Select Effective Start Date',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <LabeRequireForm
                    text={intl.formatMessage({ id: 'projectSettingLabEffectiveEndDate', defaultMessage: 'Effective End Date' })}
                    req={true}
                  />
                }
              >
                {getFieldDecorator('endDate', {
                  initialValue: _.get(record, 'endDate') ? moment(_.get(record, 'endDate'), 'DD/MM/YYYY') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'projectSettingGasValidateEndDate',
                        defaultMessage: 'Please Select Effective End Date',
                      }),
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    format={'DD/MM/YYYY'}
                    style={{ width: '100%' }}
                    disabled={getFieldValue('startDate') ? false : true}
                    disabledDate={disabledDate}
                    placeholder={intl.formatMessage({
                      id: 'projectSettingGasPlaceEndDate',
                      defaultMessage: 'Select Effective End Date',
                    })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <ModalSelectItemProjectGas
        visible={open}
        setVisble={setOpen}
        setTrigger={setTriggerModal}
        menuCode={'gas'}
        onSelectItem={onSelectItem}
        onCheckItem={checkItem}
      />
    </div>
  );
};

const ModalGasForm = Form.create({
  name: 'global_state',
})(ModalGas);

export default ModalGasForm;
