import { Button, Checkbox, Dropdown, Icon, Menu } from "antd";
import React, { useState, useRef, useEffect } from "react"
import Button_01 from "../../../../../components/v2/button_01";
import { FormattedMessage } from "react-intl";

const ActionColumn = ({ checkboxItems, onReset, onSubmit }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const firstCheckboxRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpen(false); // Close the dropdown
        if (buttonRef.current) {
          buttonRef.current.focus(); // Focus back on the button
        }
      }
    };

    if (open) {
      document.addEventListener("keydown", handleKeyDown);

      // Delay focus slightly to ensure DOM is ready
      setTimeout(() => {
        if (firstCheckboxRef.current) {
          firstCheckboxRef.current.focus();
        }
      }, 0);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

   const menuColumn = () => {
      return (
        <Menu className="menu-columns">
          <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {checkboxItems.map((item, index) => (
            <div key={index} style={{ display: 'block', marginBottom: '10px' }}>
                <Checkbox
                  key={index}
                  ref={index === 0 ? firstCheckboxRef : null} // Attach ref to the first checkbox
                  checked={item.checked}
                  onChange={(e) => item.onChange(e.target.checked)}
                  aria-checked={item.checked}
                >
                  {item.label}
                </Checkbox>
                </div>
              ))}
          </Menu.Item>
            
          <Menu.Item style={{ backgroundColor: '#ffffff' }}>
            <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={onSubmit} style={{ width: '50%', fontSize: '13px' }}>
                <FormattedMessage id="btnOK" defaultMessage="OK" />
              </Button>
              <Button onClick={onReset} style={{ width: '50%', fontSize: '13px' }}>
                <FormattedMessage id="btnReset" defaultMessage="Reset" />
              </Button>
            </Button.Group>
          </Menu.Item>
        </Menu>
      );
    };

  return (
    <div>

      <Dropdown
      className="list-view-dropdown"
      trigger={['click']}
      placement="bottomRight"
      overlay={menuColumn}
      visible={open}
        onVisibleChange={(visible) => setOpen(visible)}
    >
      <Button_01 type="primary" btnsize="wd_at" fontSize="sm" onClick={() => setOpen(!open)}
          aria-expanded={open} ref={buttonRef} className="style-buttun-columns">
        <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" /> <Icon type="down" />
      </Button_01>
    </Dropdown>
    </div>
  );
};

export default ActionColumn
