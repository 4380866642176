import React, { useEffect, useState } from 'react';
import { Form, Modal, Card, Row, Col, Select, DatePicker, Input, Collapse, Icon, Divider, AutoComplete } from 'antd';
import Button01 from '../../v2/button_01';
import Button02 from '../../v2/button_02';
import '../css/index.css';
import _ from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../label-required-form';
import { fncGetUsers } from '../../../controllers/user/users';
import { handleKeyDownNumber } from '../../../component-function/fnc-number';
import { saveRequestItem, loadMoreRequestDetail } from '../../../controllers/item-request/item-request';
import moment from 'moment';
import { successNotification, errorNotification } from '../../v2/notification';
import { useDebounce } from '../../../controllers/debounce';
import ModalAllTicket from '../../modal-allticket';
import { getQueueMessages } from '../../../hooks/use-queue-api';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { Search } = Input;

const ModalBasketItem = (props) => {
  const { visible, setVisible, form, basket, handleClickItem, setBasket, setMainTrigger, requestTypeData } = props;
  const { getFieldDecorator, validateFields, getFieldValue, setFieldsValue, resetFields } = form;
  const intl = useIntl();
  const [basketWarehouse, setBasketWarehouse] = useState([]);
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState('');
  const [indexEnd, setIndexEnd] = useState(50);
  const [requestDetailData, setRequestDetailData] = useState([]);
  const debounceSearch = useDebounce(search, 500);
  const debounceScroll = useDebounce(indexEnd, 500);
  const filterRequest = _.filter(requestTypeData, (item) => {
    return item.code === getFieldValue('requestType');
  });
  const [visibleTicket, setVisibleTicket] = useState(false);
  const [loading, setLoading] = useState(false)
  // const [requestDetailData, setVisibleTicket] = useState([]);

  console.log('basketData', basket);

  useEffect(() => {
    async function getuser() {
      try {
        const memComId = localStorage.getItem('memComId');
        const response = await fncGetUsers(memComId, null, true);
        setUserData(response);
        // console.log("response", response)
      } catch (error) {}
    }
    getuser();
  }, []);

  useEffect(() => {
    async function getRequestDetail() {
      try {
        const body = {
          ticketNo: search,
          indexStart: 0,
          indexEnd: indexEnd,
        };
        const response = await loadMoreRequestDetail(body);
        setRequestDetailData(_.get(response, 'data.ticketList'));
        console.log('response', response);
      } catch (error) {}
    }
    if (visible && _.get(filterRequest, '[0].requestDetailType') !== 'input') {
      // getRequestDetail();
    }
  }, [visible, debounceSearch, debounceScroll]);

  useEffect(() => {
    const requestor = getFieldValue('requestor');
    if (requestor && userData) {
      const filterRequestor = _.filter(userData, (item) => {
        return item.mem_com_id === parseFloat(requestor);
      });
      setFieldsValue({ ['phone']: _.get(filterRequestor, '[0].phone') });
    }
  }, [getFieldValue('requestor'), userData, visible]);

  useEffect(() => {
    const requestType = getFieldValue('requestType');
    if (requestType) {
      setFieldsValue({ ['requestDetail']: undefined });
    }
  }, [getFieldValue('requestType')]);

  const handleSearchAsset = (value) => {
    setSearch(value);
  };

  const handleAssetPopupScroll = (e) => {
    let target = e.target;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      setIndexEnd(indexEnd + 50);
    }
  };

  useEffect(() => {
    const uniqData = _.uniqBy(basket, 'warehouseId');
    const mapData = _.map(uniqData, (item) => {
      const filterData = _.filter(basket, (e) => {
        return e.warehouseId === item.warehouseId;
      });
      return {
        warehouse: item.warehouseId,
        warehouseName: item.warehouseName,
        data: filterData,
      };
    });
    console.log('uniqData', uniqData);

    setBasketWarehouse(mapData);
  }, [basket]);

  const handleSave = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setLoading(true)
      const comCode = localStorage.getItem('comCode');


      console.log('TicketNumvber', requestDetailData);

      try {
        const body = {
          requestorId: values.requestor,
          expectDate: moment(values.expectDate).format('YYYY-MM-DD'),
          recipientId: values.recipient,
          phone: values.phone,
          remark: values.remark,
          requestStockList: basket,
          requestTypeCode: values.requestType,
          ticketNo: _.get(filterRequest, '[0].requestDetailType') !== 'input' ? _.get(requestDetailData, 'ticketNo') : undefined,
          requestTypeData:
            _.get(filterRequest, '[0].requestDetailType') !== 'input' ? _.get(requestDetailData, 'ticketId') : values.requestDetail,
          isRequestForApprove: true,
        };

        const payload = {
          apiMethod: 'post',
          urlQueue: `/queue/manager/company/${comCode}/warehouseitemmove`,
          apiUrl: `${process.env.REACT_APP_URL_MANAGER}/warehouse-manager/v1/warehouse/manager/company/${comCode}/web/itemrequest`,
          payload: body,
        };


        const response = await getQueueMessages(payload);
        // const response = await saveRequestItem(body);
        if (_.get(response, 'status.code') === 200) {
          successNotification(_.get(response, 'status.message'));
          setMainTrigger((current) => !current);
          setVisible(false);
          setBasket([]);
          setBasketWarehouse([]);
          setRequestDetailData([]);
          resetFields();
        } else {
          console.log('err1', response, response.data)
          errorNotification(_.get(response, 'status.message'));
        }
      } catch (error) {
        console.log('err2', error, error.data, error.response)
        errorNotification(_.get(error, 'response.data.status.message'));
      }
      setLoading(false)

    });
  };

  const handleCancel = () => {
    setRequestDetailData([]);
    resetFields();
    setVisible(false);
  };

  const headerTilte = (name, size) => {
    return (
      <div className="modalBasketCollapsHeader">
        {name} ({size})
      </div>
    );
  };

  const handleRemoveAll = () => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      width: 280,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        setBasket([]);
        setBasketWarehouse([]);
      },
      onCancel() {},
    });
  };

  const openTicketModal = (name, size) => {
    setVisibleTicket(true);
  };

  const closeTicketModal = (name, size) => {
    setVisibleTicket(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'menuItemRequest', defaultMessage: 'Item Request' })}
      centered={true}
      visible={visible}
      width={668}
      zIndex={500}
      onCancel={loading ? null : handleCancel}
      bodyStyle={{ padding: 'unset', maxHeight: '454px', overflowY: 'scroll' }}
      footer={[
        <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} disabled={loading}>
          <FormattedMessage id="userDetailModalBtnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} disabled={loading || (_.size(basket) > 0 ? false : true)}>
          <FormattedMessage id="btnConfirm" defaultMessage="Confirm" />
        </Button01>,
        <div style={{ float: 'left' }}>
          <span className="modalBasketCardRemoveAll" onClick={handleRemoveAll}>
            {' '}
            <Icon type="delete" style={{ marginRight: '5px' }} />
            <FormattedMessage id="btnRemoveAll" defaultMessage="Remove All" />
          </span>
        </div>,
      ]}
    >
      <div className="modalRequestItemBackground">
        <Card style={{ borderRadius: '10px' }} bodyStyle={{ padding: '16px 24px' }}>
          <Form form={form} layout="vertical">
            <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRequestorText', defaultMessage: 'Requestor' })}
                      req={true}
                      bold={true}
                    />
                  }
                >
                  {getFieldDecorator('requestor', {
                    initialValue: localStorage.getItem('memComId') ? localStorage.getItem('memComId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalBasketItemRequestorValidate', defaultMessage: 'Please Select Requestor' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: 'modalBasketItemRequestorPlaceholder', defaultMessage: 'Select Requestor' })}
                    >
                      {_.map(userData, (item) => (
                        <Option key={item.mem_com_id}>{item.fullname}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemExpectDateText', defaultMessage: 'Expect Date' })}
                      req={true}
                      bold={true}
                    />
                  }
                >
                  {getFieldDecorator('expectDate', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalBasketItemExpectDateValidate',
                          defaultMessage: 'Please Select Expect Date',
                        }),
                      },
                    ],
                  })(
                    <DatePicker
                      placeholder={intl.formatMessage({ id: 'modalBasketItemExpectDatePlaceholder', defaultMessage: 'Select Expect Date' })}
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRecipientText', defaultMessage: 'Recipient' })}
                      req={true}
                      bold={true}
                    />
                  }
                >
                  {getFieldDecorator('recipient', {
                    initialValue: localStorage.getItem('memComId') ? localStorage.getItem('memComId') : undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalBasketItemRecipientValidate', defaultMessage: 'Please Select Recipient' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({ id: 'modalBasketItemRecipientPlaceholder', defaultMessage: 'Select Recipient' })}
                    >
                      {_.map(userData, (item) => (
                        <Option key={item.mem_com_id}>{item.fullname}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemPhoneText', defaultMessage: 'Phone' })}
                      req={true}
                      bold={true}
                    />
                  }
                >
                  {getFieldDecorator('phone', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'modalBasketItemPhoneValidate', defaultMessage: 'Please Select Phone' }),
                      },
                    ],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'modalBasketItemPhonePlaceholder', defaultMessage: 'Enter Phone' })}
                      onKeyDown={handleKeyDownNumber}
                      maxLength={10}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRequestTypeText', defaultMessage: 'Request Type' })}
                      req={true}
                      bold={true}
                    />
                  }
                >
                  {getFieldDecorator('requestType', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalBasketItemRequestTypeValidate',
                          defaultMessage: 'Please Select Request Type',
                        }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'modalBasketItemRequestTypePlaceholder',
                        defaultMessage: 'Select Request Type',
                      })}
                    >
                      {_.map(requestTypeData, (item) => (
                        <Option value={item.code}>{item.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                {_.get(filterRequest, '[0].requestDetailType') !== 'input' ? (
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'modalBasketItemRequestDetailText', defaultMessage: 'Request Detail' })}
                        req={true}
                        bold={true}
                      />
                    }
                  >
                    {getFieldDecorator('requestDetail1', {
                      initialValue:
                        _.size(requestDetailData) > 0
                          ? _.get(requestDetailData, 'ticketNo') + ' ' + _.get(requestDetailData, 'ticketDetail')
                          : null,
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'modalBasketItemRequestDetailValidate',
                            defaultMessage: 'Please Select Request Detail',
                          }),
                        },
                      ],
                    })(
                      <Search
                        className="allticketinputBasket"
                        readOnly={true}
                        style={{ width: '100%', marginTop: '2px', cursor: 'pointer' }}
                        placeholder={intl.formatMessage({
                          id: 'modalBasketItemRequestDetailSelectPlaceholder',
                          defaultMessage: 'Select Request Detail',
                        })}
                        onSearch={openTicketModal}
                        onClick={openTicketModal}
                        disabled={getFieldValue('requestType') ? false : true}
                        enterButton
                      />
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={
                      <LabeRequireForm
                        text={intl.formatMessage({ id: 'modalBasketItemRequestDetailText', defaultMessage: 'Request Detail' })}
                        req={true}
                        bold={true}
                      />
                    }
                  >
                    {getFieldDecorator('requestDetail', {
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'modalBasketItemRequestDetailValidate',
                            defaultMessage: 'Please Select Request Detail',
                          }),
                        },
                      ],
                    })(
                      <Input
                        disabled={getFieldValue('requestType') ? false : true}
                        placeholder={intl.formatMessage({
                          id: 'modalBasketItemRequestDetailInputPlaceholder',
                          defaultMessage: 'Enter Request Detail',
                        })}
                      />
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col span={24}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'modalBasketItemRemarkText', defaultMessage: 'Remark' })}
                      req={false}
                      bold={true}
                    />
                  }
                >
                  {getFieldDecorator('remark', {})(<TextArea autosize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <div style={{ marginTop: '16px' }}>
          {_.size(basketWarehouse) > 0 ? (
            <Collapse
              className="modalBasketCollaps"
              style={{ borderRadius: '10px' }}
              expandIconPosition="right"
              defaultActiveKey={_.map(basketWarehouse, (item) => {
                return item.warehouse;
              })}
            >
              {basketWarehouse &&
                _.map(basketWarehouse, (item) => (
                  <Panel
                    header={<div style={{ paddingLeft: '14px' }}>{headerTilte(item.warehouseName, _.size(item.data))}</div>}
                    key={item.warehouse}
                  >
                    {_.map(item.data, (e, i) => (
                      <div style={{ padding: '0px 8px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => handleClickItem(e)}>
                          <div>
                            <div style={{ display: 'flex' }}>
                              {e.isHavaImg ? (
                                <div>
                                  <img src={e.img} className="modalRequestItemImg" />
                                </div>
                              ) : null}
                              <div style={{ marginLeft: _.get(e, 'isHavaImg') ? '16px' : '0' }}>
                                <div className="modalBasketCardTextName">{e.itemMasterName + ' ' + e.branName}</div>
                                <div className="modalBasketCardTextDescription">
                                  {_.chain(e.options.topicOptions)
                                    .filter((ob) => {
                                      return _.find(ob.options, ['select', true]) ? true : false;
                                    })
                                    .map((ob, index) => {
                                      // console.log('indexob',index,'/ob',ob);
                                      const findselect = _.filter(ob.options, (p) => {
                                        return p.select === true;
                                      });

                                      return _.map(findselect, (ot) => {
                                        return ot.name;
                                      });
                                    })

                                    .toString()
                                    .value()}
                                  {/* {_.map(e.options.topicOptions, (o) => {
                                    const filterOption = _.filter(o.options, (p) => {
                                      return p.select === true;
                                    });
                                    return (
                                      <div>
                                        {_.chain(filterOption)
                                          .map((ob) => {
                                            return ob.name
                                          })
                                          .toString()
                                          .value()  }
                                        {_.map(filterOption, (f,iTop) => (
                                          <>  {f.name}, {o.topicName}  {iTop !== _.size(item.data) - 1 ? <Divider type="horizontal" /> : null}  </>

                                        ))}
                                      </div>
                                    );
                                  })} */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="modalBasketCardTextStock">{e.requireAmount}</div>
                            <div className="modalBasketCardTextItems">
                              <FormattedMessage id="assetpreventiveItem" defaultMessage="Items" />
                            </div>
                          </div>
                        </div>
                        {i !== _.size(item.data) - 1 ? <Divider type="horizontal" /> : null}
                      </div>
                    ))}
                  </Panel>
                ))}
            </Collapse>
          ) : null}
        </div>
      </div>

      <ModalAllTicket
        visibleTicket={visibleTicket}
        closeTicketModal={closeTicketModal}
        requestDetailData={requestDetailData}
        setRequestDetailData={setRequestDetailData}
      />
    </Modal>
  );
};

const ModalFormBasketItem = Form.create({
  name: 'modal_form',
})(ModalBasketItem);

export default ModalFormBasketItem;
