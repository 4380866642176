import React, { useEffect, useRef, useState } from 'react';
import LabeRequireForm from '../../../../components/label-required-form';
import ModalAddInvoice from '../add-invoice';
import _ from 'lodash';
import CreditNoteInvForm from './detail-component/inv-form';
import Button_01 from '../../../../components/v2/button_01';
import { Button, Divider, Icon, Input, Popover, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalAddItemCreditNote from '../add-item';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../component-function/fnc-number';
import CustomTableComponent from '../form/detail-component/table';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import CreditNotePriceForm from './detail-component/price-form';
import ModalUploadFile from '../../../../components/modal-upload-edit';
import moment from 'moment';
import styled from 'styled-components';
import { deleteCnAttachment } from '../../../../controllers/credit-note/credit-note';
import { successNotification } from '../../../../components/v2/notification';

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .ant-input {
    padding-right: 45px; // Make room for suffix
    border-color: ${(props) => (props.$hasError ? 'red' : '#d9d9d9')};

    &:hover,
    &:focus {
      border-color: ${(props) => (props.$hasError ? 'red' : '#4096ff')};
    }
  }
`;

const Suffix = styled.span`
  position: absolute;
  right: 30px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
`;

const CustomInput = ({ value, onChange, hasError, onKeyDown, onFocus, suffixText = 'THB', onReset, className, disabled }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputContainer $hasError={hasError}>
        <Input value={value} onChange={onChange} onKeyDown={onKeyDown} onFocus={onFocus} className={className} disabled={disabled} />
        <Suffix>{suffixText}</Suffix>
        <Icon style={{ marginLeft: 10 }} className="color-reset-amount" type="undo" onClick={() => onReset()} />
      </InputContainer>
    </div>
  );
};

const { Option } = Select;

const CreditNoteDetail = (props) => {
  const { form, initial, dataForm, setDataForm, selectItem, setSelectItem, attachmentData, setAttachmentData } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const langValue = localStorage.getItem('langValue');

  const intl = useIntl();
  const ref2 = useRef();

  const [visibleInvoice, setVisibelInvoice] = useState(false);
  const [visibleAddItem, setVisibleAddItem] = useState(false);

  // Attachment
  const [openUpload, setOpenUpload] = useState(false);
  const [typeAttachment, setTypeAttachment] = useState('');
  const [defaultAttachment, setDefaultAttachment] = useState({});

  console.log('selectItem', selectItem);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 60,
      fixed: 'left',
      render: (text, record, index) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '60px' }}>{index + 1}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnItemCode', defaultMessage: 'ItemCode' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: (a, b) => {
        return a.itemCode.localeCompare(b.itemCode);
      },
      width: 140,
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '150px' }}>{text}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnItem', defaultMessage: 'Item' }),
      dataIndex: 'item',
      key: 'item',
      width: 140,
      sorter: (a, b) => {
        return a.item.localeCompare(b.item);
      },
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '120px' }}>{text}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnINVQty', defaultMessage: 'INV Qty' }),
      dataIndex: 'invQty',
      key: 'invQty',
      width: 140,
      sorter: (a, b) => {
        return a.item.localeCompare(b.item);
      },
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div>{text}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnINVPriceUnit', defaultMessage: 'INV Price/Unit' }),
      dataIndex: 'invPriceUnit',
      key: 'invPriceUnit',
      width: 200,
      sorter: (a, b) => {
        return a.invPriceUnit.localeCompare(b.invPriceUnit);
      },
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '120px' }}>{(text ? numberFormatter(text) : 0) + ' THB'}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnType', defaultMessage: 'Type' }),
      dataIndex: 'type',
      key: 'type',
      width: 200,
      sorter: (a, b) => {
        return a.type.localeCompare(b.type);
      },
      render: (text, record, index) => (
        <Select
          style={{ width: '100%', border: `1px solid ${_.get(record, 'empty.type') ? 'red' : '#D9D9D9'}`, borderRadius: '8px' }}
          value={record.type}
          onChange={(value) => handleChangeTable('type', value, index)}
        >
          <Option value="return">
            <FormattedMessage id="modalCreditNoteColumnTypeReturn" defaultMessage="Return" />
          </Option>
          <Option value="discount">
            <FormattedMessage id="modalCreditNoteColumnTypeDiscount" defaultMessage="Discount" />
          </Option>
        </Select>
      ),
    },

    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      width: 200,
      // align: _.get(dataForm, 'code') !== 'view' ? 'right' : 'left',
      sorter: (a, b) => {
        return a.qty.localeCompare(b.qty);
      },
      render: (text, record, index) => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                className="suffix-input-zindex"
                style={{ paddingRight: 10, borderColor: _.get(record, 'empty.qty') ? 'red' : undefined }}
                value={record.qty}
                onKeyDown={handleKeyDownNumber}
                onFocus={handleFocus}
                onChange={(e) => handleChangeTable('qty', e.target.value, index)}
                disabled={record.type === 'discount'}
              />
            </div>
            {/* )} */}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnUnit', defaultMessage: 'Unit' }),
      dataIndex: 'invUnitName',
      key: 'invUnitName',
      width: 100,
      sorter: (a, b) => {
        return a.invUnitName.localeCompare(b.invUnitName);
      },
      render: (text, record, index) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '120px' }}>{text}</div>
        </Popover>
      ),
    },

    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnDiscountPriceUnit', defaultMessage: 'Discount Price/Unit' }),
      dataIndex: 'priceUnit',
      key: 'priceUnit',
      width: 250,
      sorter: (a, b) => a.priceUnit.localeCompare(b.priceUnit),
      render: (text, record, index) => (
        <CustomInput
          value={record.priceUnit}
          onChange={(e) => handleChangeTable('priceUnit', e.target.value, index)}
          hasError={_.get(record, 'empty.priceUnit')}
          onKeyDown={handleKeyDownDecimal2Fixed}
          onFocus={handleFocus}
          suffixText={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
          onReset={() => handleChangeTable('priceUnit', record.invPriceUnit, index)}
          disabled={record.type === 'return'}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnCreditNoteRemark', defaultMessage: 'Creadit Note Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
      render: (text, record, index) => (
        <Select
          style={{ width: '100%', border: '1px solid #D9D9D9', borderRadius: '8px' }}
          value={record.remark}
          onChange={(value) => handleChangeTable('remark', value, index)}
          placeholder={intl.formatMessage({
            id: 'modalCreditNotePHRefRemark',
            defaultMessage: 'Select Remark',
          })}
          allowClear
        >
          {_.map(initial.optionsRemark, (item) => (
            <Option value={item.reasonCode}>{langValue === 'TH' ? item.detail.TH : item.detail.EN}</Option>
          ))}
          {/* <Option value="other">Other</Option> */}
        </Select>
      ),
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnOthers', defaultMessage: 'Others (Please specify)' }),
      dataIndex: 'others',
      key: 'others',
      width: 300,
      render: (text, record, index) => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                className="suffix-input-zindex"
                style={{ paddingRight: 10, height: '35px', borderColor: _.get(record, 'empty.others') ? 'red' : undefined }}
                value={record.others}
                onFocus={handleFocus}
                onChange={(e) => handleChangeTable('others', e.target.value, index)}
                placeholder={intl.formatMessage({
                  id: 'modalCreditNotePHRefOthers',
                  defaultMessage: 'Enter Remark',
                })}
              />
            </div>
            {/* )} */}
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amount',
      key: 'amount',
      fixed: 'right',
      width: 200,
      align: 'right',
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record) => {
        console.log('record', record);
        return (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {numberFormatter(_.get(record, 'amount') || 0) + ' ' + intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
          </div>
        );
      },
    },
  ];

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleChangeTable = (type, value, index) => {
    console.log('handleChangeTable', type, value, index);
    const newData = [...selectItem];
    newData[index][type] = value;

    if (type === 'type') {
      if (value === 'return') {
        const invPriceUnit = _.get(selectItem[index], 'invPriceUnit') ? _.toNumber(_.get(selectItem[index], 'invPriceUnit')) : 0;
        const qty = _.get(selectItem[index], 'qty') ? _.toNumber(_.get(selectItem[index], 'qty')) : 0;

        newData[index].priceUnit = invPriceUnit;
        newData[index].amount = qty * invPriceUnit;
        newData[index].empty.priceUnit = false;

      } else if (value === 'discount') {
        const invQty = _.get(selectItem[index], 'invQty') ? _.toNumber(_.get(selectItem[index], 'invQty')) : 0;
        const priceUnit = _.get(selectItem[index], 'priceUnit') ? _.toNumber(_.get(selectItem[index], 'priceUnit')) : 0;

        newData[index].qty = invQty;
        newData[index].amount = invQty * priceUnit;
        newData[index].empty.qty = false;

      }
      newData[index].empty.type = false;
    }

    // สำหรับ คำนวณ amount

    if (type === 'qty') {
      const invQty = _.get(selectItem[index], 'invQty') ? _.toNumber(_.get(selectItem[index], 'invQty')) : 0;
      const priceUnit = _.get(selectItem[index], 'priceUnit') ? _.toNumber(_.get(selectItem[index], 'priceUnit')) : 0;
      newData[index].amount = _.toNumber(value) * priceUnit;

      if (_.toNumber(value) > invQty) {
        newData[index].empty.qty = true;
      } else {
        newData[index].empty.qty = false;
      }
    }
    if (type === 'priceUnit') {
      const invPriceUnit = _.get(selectItem[index], 'invPriceUnit') ? _.toNumber(_.get(selectItem[index], 'invPriceUnit')) : 0;
      const qty = _.get(selectItem[index], 'qty') ? _.toNumber(_.get(selectItem[index], 'qty')) : 0;
      newData[index].amount = _.toNumber(value) * qty;

      if (_.toNumber(value) > invPriceUnit) {
        newData[index].empty.priceUnit = true;
      } else {
        newData[index].empty.priceUnit = false;
      }
    }

    // type remark
    if (type === 'remark' && newData[index].others !== 'RC605') {
      newData[index]['empty'].others = false;
    }

    // สำหรับ type others
    if (type === 'others' && value !== '' && newData[index]['empty'].others) {
      newData[index]['empty'].others = false;
    } else if (type === 'others' && value === '' && newData[index].remark === 'RC605') {
      newData[index]['empty'].others = true;
    }
    setSelectItem(newData);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const deleteItem = (record) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return item.id !== record.id;
    });

    const mapDelete = _.map(filterSelect, (item, index) => {
      return {
        ...item,
        index: index + 1,
      };
    });

    setSelectItem(mapDelete);
  };

  const handleOpenModalInvoice = () => {
    setVisibelInvoice(true);
  };

  const handleOpenModalAddItem = () => {
    setVisibleAddItem(true);
  };

  const onSetDataForm = (value, code) => {
    setDataForm({ ...dataForm, [code]: value });
  };

  // Attachment

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const contentActionAttment = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUpload(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const handleCreateUpload = () => {
    setTypeAttachment('add');
    setOpenUpload(true);
  };

  const handleEditUpload = (data) => {
    setDefaultAttachment(data);
    setTypeAttachment('edit');
    setOpenUpload(true);
  };

  console.log('attachmentData', attachmentData);

  const deleteAtt = async (record) => {
    const filterSelect = _.filter(attachmentData, (item) => {
      return item.id !== record.id;
    });
    setAttachmentData(filterSelect);

    if (record.form === 'back') {
      const response = await deleteCnAttachment({
        _id: record.id,
        creditNoteId: _.get(dataForm, 'cnId'),
      });

      if (response.status === 200) successNotification(_.get(response, 'data.message'));
    }
  };

  const onSetFieldInvNo = (value) => {
    setFieldsValue({
      invNo: value,
    });
    setSelectItem([]);
  };

  console.log(
    'empty',
    _.some(selectItem, (item) => _.get(item, 'empty.others')),
    selectItem
  );

  return (
    <div style={{ height: '550px', overflowY: 'auto' }}>
      <div style={{ margin: '0px 24px' }}>
        <CreditNoteInvForm
          form={form}
          dataForm={dataForm}
          handleOpenModalInvoice={handleOpenModalInvoice}
          initial={initial}
          onChange={onSetDataForm}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px 24px 0px 24px' }}>
        <span className="item-title-text">
          <FormattedMessage id="creditNoteItem" defaultMessage="Item" />
        </span>
        <div>
          <Button_01
            key="submit"
            type="primary"
            btnsize="wd_df"
            style={{ margin: 'unset' }}
            disabled={!_.get(dataForm, 'invNo')}
            onClick={() => handleOpenModalAddItem()}
          >
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button_01>
        </div>
      </div>
      <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />

      <div className="content-body">
        <CustomTableComponent
          onRow={true}
          role={true}
          columns={columns}
          dataSource={selectItem}
          scroll={{ x: true }}
          // contentAction={contentActionItem}
        />
        {_.some(selectItem, (item) => _.get(item, 'empty.type')) && (
          <div>
            <span style={{ color: 'red', marginTop: '24px', marginLeft: '12px' }}>
              <FormattedMessage id="pleaseEnterRequiredFieldType" defaultMessage="Please enter required field Type." />
            </span>
          </div>
        )}
        {_.some(selectItem, (item) => _.get(item, 'empty.qty')) && (
          <div>
            <span style={{ color: 'red', marginTop: '24px', marginLeft: '12px' }}>
              <FormattedMessage id="pleaseEnterRequiredFieldQty" defaultMessage="The Qty exceeds the Qty of invoice." />
            </span>
          </div>
        )}
        {_.some(selectItem, (item) => _.get(item, 'empty.priceUnit')) && (
          <div>
            <span style={{ color: 'red', marginTop: '24px', marginLeft: '12px' }}>
              <FormattedMessage id="pleaseEnterRequiredFieldPriceUnit" defaultMessage="The price/unit exceeds the price/unit of invoice." />
            </span>
          </div>
        )}
        {_.some(selectItem, (item) => _.get(item, 'empty.others')) && (
          <div>
            <span style={{ color: 'red', marginTop: '24px', marginLeft: '12px' }}>
              <FormattedMessage id="pleaseEnterRequiredField" defaultMessage="Please enter required field." />
            </span>
          </div>
        )}

        <div className="content-body-price">
          <CreditNotePriceForm form={form} dataForm={dataForm} setDataForm={setDataForm} selectItem={selectItem} initial={initial} />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px 24px 0px 24px' }}>
        <span className="item-title-text">
          <FormattedMessage id="purchesModalTitleAttachment" defaultMessage="Attachment" />
        </span>
        <div>
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={handleCreateUpload}>
            <FormattedMessage id="btnUpload" defaultMessage="Upload" />
          </Button_01>
        </div>
      </div>
      <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />

      <div className="content-body">
        <CustomTableComponent
          onRow={true}
          role={true}
          columns={columnsAtt}
          dataSource={_.sortBy(attachmentData, [
            function(o) {
              return o.index;
            },
          ])}
          scroll={{ x: true }}
          contentAction={contentActionAttment}
        />
      </div>

      {/* ************************************* Modal ************************************/}

      <ModalAddInvoice
        visible={visibleInvoice}
        setVisble={setVisibelInvoice}
        dataForm={dataForm}
        setDataForm={setDataForm}
        initial={initial}
        onSetFieldInvNo={onSetFieldInvNo}
      />
      <ModalAddItemCreditNote
        visible={visibleAddItem}
        setVisble={setVisibleAddItem}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        initial={initial}
        dataForm={dataForm}
      />
      <ModalUploadFile
        visible={openUpload}
        setVisible={setOpenUpload}
        attData={attachmentData}
        setAttData={setAttachmentData}
        typeAtt={typeAttachment}
        defaultAtt={defaultAttachment}
      />
    </div>
  );
};

export default CreditNoteDetail;
