import { Button, Col, DatePicker, Divider, Icon, Row, Select, Table } from 'antd';
import _ from 'lodash';
import React, { Children, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../index.css';
import { messageLabel } from '../../../../components/message-component';
import CustomTableComponent from '../../../sale-order-new/components/table';

const TableRevenueLeastArea = (props) => {
  const intl = useIntl();
  const {
    columns,
    data,
    total,
    paginationShow,
  } = props;

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  return (
    <div
      style={{
        alignItems: 'center',
        padding: '24px 24px 0px 24px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        width: '100%',
        zIndex: 1,
      }}
    >
      <Table
        className="table-global-scroll"
        bordered={true}
        dataSource={data}
        scroll={total ? { x: _.sumBy(columns, 'width') } : { x: 'max-content' }}
        columns={columns}
        pagination={
          total
            ? {
                total: total,
                showTotal: showTotal,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showSizeChanger: paginationShow,
                locale: { items_per_page: '' },
              }
            : false
        }
      />
      {total > 0 ? (
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default TableRevenueLeastArea;
