import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';

const { Option } = Select;

const CreditNotePriceView = (props) => {
  const { dataForm, initial } = props;
  const intl = useIntl();

  return (
    <Form colon={false} layout="vertival" className="creditnote-form">
      <Row gutter={[16]}>
        <Col span={9}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'creditnoteModalFormRemark', defaultMessage: 'Remark' })} req={false} />}
          >
            {_.get(dataForm, 'remark') || '-'}
          </Form.Item>
        </Col>
        <Col span={5}></Col>
        <Col span={10}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', marginTop: '35px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormTotalOriginalValue" defaultMessage="Total Original Value" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'totalOrigin', 0))} THB</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormTotalCorrectValue" defaultMessage="Total Correct Value" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'totalCorrect', 0))} THB</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormDifference" defaultMessage="Difference" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'difference', 0))} THB</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormVat" defaultMessage="Vat" /> {_.get(dataForm, 'vat') + '%'}
            </div>
            <div>{numberFormatter(_.get(dataForm, 'vatNumber', 0))} THB</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormTotal" defaultMessage="Total" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'total', 0))} THB</div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditNotePriceView;
