import httpClient from '../../components/axiosClient';

const getListNetVehicleIncomeReport = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/net-vehicle-income-report`, body);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

const exportListNetVehicleIncomeReport = async (body) => {
  const comCode = localStorage.getItem('comCode');
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/net-vehicle-income-report-excel`, body, {
      responseType: 'arraybuffer',
    });
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};

export { getListNetVehicleIncomeReport, exportListNetVehicleIncomeReport };
