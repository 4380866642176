import React, { useContext } from 'react';
import { Button, Card, Dropdown, Menu, Tabs } from 'antd';
import AllowanceTable from './table';
import { AllowanceProvider } from '../context';
import _ from 'lodash';
import '../index.css';
import Button01 from '../../../components/v2/button_01';
import { FormattedMessage } from 'react-intl';
import { PageSettings } from '../../../config/page-settings';
import Button_01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const AllowanceContent = () => {
  const { state, fnc } = useContext(AllowanceProvider);
  const { tab, tabValue } = state;
  const { handleTabChange, handleExport, handlePrintPdf } = fnc;
  const app = useContext(PageSettings);
  const { checkPermissionAction } = app;

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button ghost type="link" onClick={() => handleExport()}>
          <FormattedMessage id="btnExportExcel" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button ghost type="link" onClick={() => handlePrintPdf()}>
          <FormattedMessage id="btnPrintPDF" defaultMessage="Print (PDF)" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {checkPermissionAction('P80PG1C1', 'P80PG1C1A1') ? (
        <Card bodyStyle={{ padding: 'unset', height: 'calc(100vh - 110px)' }}>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            animated={false}
            activeKey={tabValue}
            onTabClick={handleTabChange}
            tabBarExtraContent={
              <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                  <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                  <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                </Button_01>
              </Dropdown>
            }
          >
            {_.map(tab, (item) => (
              <TabPane tab={item.name} key={item.code}>
                <AllowanceTable />
              </TabPane>
            ))}
          </Tabs>
        </Card>
      ) : null}
    </div>
  );
};

export default AllowanceContent;
