import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import moment from 'moment';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, onAllowClear, onClick } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div style={{ padding: '16px 16px 16px 0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddCreditNoteNo" defaultMessage="CN No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'cnNo')}
                value={_.get(searchGroup, 'cnNo')}
                placeholder={intl.formatMessage({
                  id: 'BLAddCreditNoteNoPlaceholder',
                  defaultMessage: 'Enter CN No.',
                })}
              />
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="invoiceLabelINVNo" defaultMessage="INV No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'invNo')}
                value={_.get(searchGroup, 'invNo')}
                placeholder={intl.formatMessage({
                  id: 'invoiceLabelINVNoPlaceHolder',
                  defaultMessage: 'Enter INV No.',
                })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddInvoiceFromINVDate" defaultMessage="From INV Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                style={{ width: '100%' }}
                value={_.get(searchGroup, 'fromINV') ? moment(_.get(searchGroup, 'fromINV')) : ''}
                onChange={(e) => onChange(e, 'fromINV')}
                placeholder={intl.formatMessage({ id: 'BLAddINVDatePlaceholder', defaultMessage: 'Select INV Date' })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddInvoiceToINVDate" defaultMessage="To INV Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                value={_.get(searchGroup, 'toINV') ? moment(_.get(searchGroup, 'toINV')) : ''}
                style={{ width: '100%' }}
                onChange={(e) => onChange(e, 'toINV')}
                placeholder={intl.formatMessage({ id: 'BLAddINVDatePlaceholder', defaultMessage: 'Select INV Date' })}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px 16px 16px 0px' }}>
        <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button_01>
      </div>
    </div>
  );
};

export default ListItemFilter;
