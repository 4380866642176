import React, { useState, useEffect } from 'react';
import { DatePicker, Form, Modal, Select, Row, Col, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import './index.css';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { handleKeyDownNumber, handleKeyDownDecimal2Fixed } from '../../../../component-function/fnc-number';
import LabeRequireForm from '../../../../components/label-required-form';
import Button_01 from '../../../../components/v2/button_01';
import Button_02 from '../../../../components/v2/button_02';
import moment from 'moment';
import { getConvertUoMBigger } from '../../../../controllers/po/po';

const { Option } = Select;

const ModalReceiveLotOrSerial = (props) => {
  const {
    form,
    visible,
    setVisible,
    lotNo,
    setLotNo,
    binLocationList,
    uomData,
    defaultData,
    setDefaultData,
    numbertypeList,
    setvisibleLotNoTable,
    itemData,
    reciveQTY,
    setReciveQTY,
    itemDataList,
    setItemDataList,
    setItemData,
    tempitemDataList,
    setTempItemDataList,
  } = props;
  const intl = useIntl();
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = form;
  const [lotNoSelect, setLotNoSelect] = useState('lot');
  // const [lotNoTemp, setlotNoTemp] = useState()

  const [uomList, setUomList] = useState([]);
  const [checkUOM, setCheckUOM] = useState(true);

  console.log('defaultData', defaultData, uomList, itemData, lotNo)

  useEffect(() => {
    const getStockList = async () => {
      const unitData = await getConvertUoMBigger(_.get(itemData, 'itemMasterId'));
      setUomList(_.get(unitData, 'data.data'));
    };
    if (itemData && visible) {
      getStockList();
    }
  }, [itemData, visible]);

  useEffect(() => {
    if (_.get(itemData, 'uomId') && visible === true && _.size(uomList) > 0) {
      const filteruom = _.filter(uomList, (item) => {
        return _.get(item, 'uomId') === _.get(itemData, 'uomId');
      });

      if (_.get(filteruom[0], 'isDefault') === true) {
        setCheckUOM(true);
      } else {
        setCheckUOM(false);
      }
    }
  }, [visible, uomList]);

  useEffect(() => {
    if (defaultData && visible) {
      setLotNoSelect(_.get(defaultData, 'lotNoSelect'));
    }
  }, [defaultData, visible]);

  const handleSave = (type) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }

      let plusRecive = parseFloat(values.qty) + parseFloat(reciveQTY);

      // {_.map(numbertypeList, (item) => (
      //   <Option value={item.code}>{item.name}</Option>
      // ))}

      const filterRecive = _.filter(itemDataList, (item) => {
        return (
          item.itemMasterId !== _.get(itemData, 'itemMasterId') ||
          (item.itemMasterId === _.get(itemData, 'itemMasterId') && item.uomId !== _.get(itemData, 'uomId'))
        );
      });

      if (defaultData) {
        if (parseFloat(values.qty) > parseFloat(_.get(defaultData, 'qty'))) {
          let newqty = parseFloat(values.qty) - parseFloat(_.get(defaultData, 'qty'));
          plusRecive = parseFloat(reciveQTY) + parseFloat(newqty);
        } else {
          let newqty = parseFloat(_.get(defaultData, 'qty')) - parseFloat(values.qty);
          plusRecive = parseFloat(reciveQTY) - parseFloat(newqty);
        }

        if (parseFloat(values.qty) === parseFloat(_.get(defaultData, 'qty'))) {
          plusRecive = parseFloat(reciveQTY);
        }
        const body = {
          ...values,
          qty: parseFloat(values.qty),
          receiveQty: plusRecive + ' / ' + _.get(itemData, 'qty') + ' ' + _.get(itemData, 'uomQtyTxt'),
          lotSerialNo: lotNoSelect === 'serial' ? 'Serial' + ' - ' + values.no : 'Lot' + ' - ' + values.no,
          no: values.no,
          lotNoSelect: lotNoSelect,
          index: _.get(defaultData, 'index'),
          id: _.get(defaultData, 'id'),
          itemCode: _.get(defaultData, 'itemCode'),
          itemName: _.get(defaultData, 'itemName'),
          itemMasterId: _.get(defaultData, 'itemMasterId'),
          // uomId: _.get(defaultData, 'uomId'),
          uomId: values.uomId,
          menufacturingDate: _.get(values, 'menufacturingDate')
            ? moment(_.get(values, 'menufacturingDate'), 'DD/MM/YYYY').format('YYYY-MM-DD')
            : '-',
          expiredDate: _.get(values, 'expiredDate') ? moment(_.get(values, 'expiredDate'), 'DD/MM/YYYY').format('YYYY-MM-DD') : '-',
          maxQty: _.get(defaultData, 'maxQty'),
          // uomQtyTxt: _.get(defaultData, 'uomQtyTxt'),
          uomQtyTxt: _.chain(uomList).filter((uom) => uom.uomId === values.uomId).get('[0].name').value(),
          received: _.get(defaultData, 'received'),
          itemPoId: _.get(defaultData, 'itemPoId'),
        };

        setLotNo((prevData) => {
          console.log('setLotNo', prevData, defaultData)
          const newData = prevData.map((item) => {
            if (item.id === _.get(defaultData, 'id') && item.uomId === _.get(defaultData, 'uomId')) {
              return { ...item, ...body };
            }
            return item;
          });
          return newData;
        });
      } else {
        const body = {
          ...values,
          qty: parseFloat(values.qty),
          receiveQty: plusRecive + ' / ' + _.get(itemData, 'qty') + ' ' + _.get(itemData, 'uomQtyTxt'),
          lotSerialNo: lotNoSelect === 'serial' ? 'Serial' + ' - ' + values.no : 'Lot' + ' - ' + values.no,
          no: values.no,
          lotNoSelect: lotNoSelect,
          index: _.size(lotNo) + 1,
          id: uuidv4(),
          itemCode: _.get(itemData, 'itemCode'),
          itemName: _.get(itemData, 'itemName'),
          itemMasterId: _.get(itemData, 'itemMasterId'),
          // uomId: _.get(itemData, 'uomId'),
          uomId: values.uomId,
          menufacturingDate: _.get(values, 'menufacturingDate')
            ? moment(_.get(values, 'menufacturingDate'), 'DD/MM/YYYY').format('YYYY-MM-DD')
            : '-',
          expiredDate: _.get(values, 'expiredDate') ? moment(_.get(values, 'expiredDate'), 'DD/MM/YYYY').format('YYYY-MM-DD') : '-',
          maxQty: _.get(itemData, 'qty'),
          // uomQtyTxt: _.get(itemData, 'uomQtyTxt'),
          uomQtyTxt: _.chain(uomList).filter((uom) => uom.uomId === values.uomId).get('[0].name').value(),
          received: _.get(itemData, 'received'),
          itemPoId: _.get(itemData, 'itemPoId'),
        };

        setLotNo([...lotNo, body]);
      }

      if (type === 'save' || defaultData) setVisible(false);

      setReciveQTY(plusRecive);

      const setobj = [
        {
          fda: _.get(itemData, 'fda'),
          itemCode: _.get(itemData, 'itemCode'),
          itemName: _.get(itemData, 'itemName'),
          itemMasterId: _.get(itemData, 'itemMasterId'),
          qty: _.get(itemData, 'qty'),
          // receiveQty: plusRecive + ' / ' + _.get(itemData, 'qty') + ' ' + _.get(itemData, 'uomQtyTxt'),
          receiveQty: _.get(itemData, 'receiveQty'),
          received: plusRecive,
          uomId: _.get(itemData, 'uomId'),
          uomQtyTxt: _.get(itemData, 'uomQtyTxt'),
          itemPoId: _.get(itemData, 'itemPoId'),
        },
      ];
      if (plusRecive === _.get(itemData, 'qty')) {
        setItemDataList(filterRecive);
      } else {
        setItemDataList(filterRecive.concat(setobj));
      }
      setTempItemDataList(filterRecive.concat(setobj));
      resetFields();
      setvisibleLotNoTable();
      setDefaultData();
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setDefaultData();
  };

  const handleSelectLot = (value) => {
    setLotNoSelect(value);
    if (value === 'serial') {
      setFieldsValue({
        ['qty']: 1,
      });
    } else {
      setFieldsValue({
        ['qty']: 1,
      });
    }
  };

  const checkAdd = [
    <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
      <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
    </Button_02>,
    <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave('save')}>
      <FormattedMessage id="btnSave" defaultMessage="Save" />
    </Button_01>,
  ];

  const TitleModal = () => {
    return (
      <div>
        <FormattedMessage id="recivePOTitleReceiveItem" defaultMessage="Receive Item" />・{_.get(itemData, 'itemCode')}{' '}
        {_.get(itemData, 'itemName')}
      </div>
    );
  };

  const handleAmount = (e, value) => {
    // if (parseFloat(value) <= 0) {
    //   e.target.value = 1;
    // }
    // if (value > _.get(itemData, 'qty') - reciveQTY) {
    //   e.target.value = _.get(itemData, 'qty') - reciveQTY;
    // }

    if (lotNoSelect === 'serial') {
      if (parseFloat(value) !== 1) {
        e.target.value = 1;
      }
    }
  };

  const validatorQty = (rule, value, callback) => {
    try {
      if (value) {
        const parsValue = parseFloat(value || 0);

        let minusQty = _.get(itemData, 'sumQty') || 0;
        let reciveQty = _.get(itemData, 'received') || 0;
        let maxQty = defaultData
          ? _.get(defaultData, 'maxQty')
          : _.get(itemData, 'sumQty')
          ? _.get(itemData, 'qty') - minusQty
          : _.get(itemData, 'qty') - (minusQty + reciveQty);

        console.log('lotNo', lotNo, 'DF', defaultData);
        console.log(
          'reciveQty',
          reciveQty,
          '/minuse:',
          minusQty,
          '/qty',
          _.get(itemData, 'qty'),
          '/max',
          maxQty,
          '/r',
          _.get(defaultData, 'received')
        );

        if (defaultData) {
          maxQty = maxQty - reciveByEdit();
          console.log('reciveByEdit', maxQty, '/,', reciveByEdit());
        }

        if (parsValue === 0) {
          callback(intl.formatMessage({ id: 'RecivePOQTYZero', defaultMessage: 'Quantity must be greater than 0' }));
        } else if (parsValue > maxQty) {
          callback(intl.formatMessage({ id: 'RecivePOQTYMore', defaultMessage: 'Quantity is excess quantity received' }));
        } else {
          callback();
        }
      } else {
        callback();
      }
    } catch (error) {}
  };

  const reciveByEdit = () => {
    const result = _.sumBy(lotNo, (i) => {
      // return defaultData.itemMasterId === _.get(i, 'itemMasterId') &&
      //   defaultData.uomId === _.get(i, 'uomId') &&
      console.log('lotqty', i.qty, '/', _.get(defaultData, 'received'));
      return defaultData.itemPoId === _.get(i, 'itemPoId') && _.get(defaultData, 'index') !== _.get(i, 'index')
        ? _.get(defaultData, 'received') === 0
          ? i.qty
          : _.get(defaultData, 'received')
          ? i.qty
          : i.qty + _.get(defaultData, 'received')
        : _.get(defaultData, 'received')
        ? _.get(defaultData, 'received')
        : 0;
    });
    return result;
  };

  return (
    <Modal
      title={<TitleModal />}
      visible={visible}
      width={659}
      centered={true}
      bodyStyle={{ padding: '12px 24px 24px 24px' }}
      onCancel={handleCancel}
      footer={checkAdd}
    >
      <Form form={form} layout="vertical" className="modalLotNoForm">
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'recivePOReceivedQty', defaultMessage: 'Received Qty' })} req={false} />
              }
            >
              {getFieldDecorator('receiveQty', {
                initialValue: _.get(defaultData, 'receiveQty')
                  ? reciveByEdit() + ' / ' + _.get(defaultData, 'maxQty') + ' ' + _.get(defaultData, 'uomQtyTxt')
                  : _.get(itemData, 'receiveQty'),
              })(<Input style={{ backgroundColor: '#F8F8F8' }} readOnly={true} value={_.get(itemData, 'receiveQty')} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '60%' }}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'recivePOReceiveQty', defaultMessage: 'Receive Qty' })} req={true} />
                  }
                >
                  {getFieldDecorator('qty', {
                    initialValue: _.get(defaultData, 'qty') || undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'recivePOReceiveQtyValidate', defaultMessage: 'Please Enter Receive Qty' }),
                      },
                      {
                        validator: validatorQty,
                      },
                    ],
                  })(
                    <Input
                      className="modalAddLotNoInputUOM"
                      onChange={(e) => handleAmount(e, e.target.value)}
                      value={lotNoSelect === 'serial' ? 1 : undefined}
                      placeholder={intl.formatMessage({ id: 'recivePOReceiveQtyPlaceholeder', defaultMessage: 'Enter Receive Qty' })}
                      onKeyDown={checkUOM === false ? handleKeyDownDecimal2Fixed : handleKeyDownNumber}
                    />
                  )}
                </Form.Item>
              </div>
              <div style={{ width: '40%' }}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'recivePOReceivedUom', defaultMessage: 'Uom' })} req={true} />}
                >
                  {getFieldDecorator('uomId', {
                    initialValue: defaultData ? _.get(defaultData, 'uomId') : _.get(itemData, 'uomId') || undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalUOMValidate',
                          defaultMessage: 'Please Select UOM',
                        }),
                      },
                    ],
                  })(
                    <Select 
                      className="modalAddQtySelect" 
                      // value={''}
                      >
                      {_.map(uomList, (item) => (
                        <Option value={item.uomId}>{item.name}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'modalLotNoLotNoText', defaultMessage: 'Add Lot No. or Serial No.' })}
                  req={true}
                />
              }
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '40%' }}>
                  <Select className="modalAddLotNoSelect" value={lotNoSelect} onSelect={handleSelectLot}>
                    {_.map(numbertypeList, (item) => (
                      <Option value={item.code}>{item.name}</Option>
                    ))}
                  </Select>
                </div>
                <div style={{ width: '60%' }}>
                  {getFieldDecorator('no', {
                    initialValue: _.get(defaultData, 'no') || undefined,
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'modalLotNoLotNoValidate',
                          defaultMessage: 'Please Enter Lot No. or Serial No.',
                        }),
                      },
                    ],
                  })(
                    <Input
                      className="modalAddLotNoInput"
                      placeholder={intl.formatMessage({ id: 'modalLotNoLotNoPlaceholeder', defaultMessage: 'Enter Lot No. or Serial No.' })}
                    />
                  )}
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'recivePOManufacturingDate', defaultMessage: 'Manufacturing Date' })} />
              }
            >
              {getFieldDecorator('menufacturingDate', {
                initialValue: _.get(defaultData, 'menufacturingDate')
                  ? _.get(defaultData, 'menufacturingDate') === '-'
                    ? undefined
                    : moment(_.get(defaultData, 'menufacturingDate'))
                  : undefined,
              })(
                <DatePicker
                  placeholder={intl.formatMessage({
                    id: 'recivePOManufacturingDatePlaceholeder',
                    defaultMessage: 'Select Manufacturing Date',
                  })}
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'modalLotNoExpiredDateText', defaultMessage: 'Expired Date' })} />}
            >
              {getFieldDecorator('expiredDate', {
                initialValue: _.get(defaultData, 'expiredDate')
                  ? _.get(defaultData, 'expiredDate') === '-'
                    ? undefined
                    : moment(_.get(defaultData, 'expiredDate'))
                  : undefined,
              })(
                <DatePicker
                  placeholder={intl.formatMessage({ id: 'modalLotNoExpiredDatePlaceholeder', defaultMessage: 'Select Expired Date' })}
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'modalItemMasterFormItemTextFDA', defaultMessage: 'FDA' })} req={false} />
              }
            >
              {getFieldDecorator('fda', {
                initialValue: _.get(defaultData, 'fda')
                  ? _.get(defaultData, 'fda')
                  : _.get(itemData, 'fda')
                  ? _.get(itemData, 'fda')
                  : undefined,
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'modalItemMasterFormItemFDA', defaultMessage: 'Enter FDA' })}
                  onKeyDown={handleKeyDownNumber}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ModalFormReceiveLotOrSerial = Form.create({
  name: 'modal_form',
})(ModalReceiveLotOrSerial);

export default ModalFormReceiveLotOrSerial;
