import _ from 'lodash';
import httpClient from '../../components/axiosClient';
import moment from 'moment';
import { ThaiNumberToText } from '../../component-function/generator-pdf';

const comCode = localStorage.getItem('comCode');
const langValue = localStorage.getItem('langValue');

const getConfigMenuCn = async (body) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/getMenuCn`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getRemarkCn = async () => {
  try {
    const response = await httpClient.get(`/v1/credit-note/manager/company/${comCode}/getCreditNoteRemark`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const searchCreditNote = async (body) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/getCreditNote`, body);

    console.log('searchCreditNote', response);

    const dataInfo = _.map(_.get(response, 'data.data'), (item) => {
      const teamMap = _.map(_.get(item, 'invoiceHeader.orgIdOwner'), 'teamName').join(', ');
      return {
        index: _.get(item, 'index'),
        cnId: _.get(item, 'creditNoteId'),
        cnNo: _.get(item, 'creditNoteNo'),
        invId: _.get(item, 'invoiceHeader.invoiceId'),
        invNo: _.get(item, 'invoiceHeader.invoiceNo'),
        invDate: _.get(item, 'invoiceHeader.issueDetail.issueDate')
          ? moment(_.get(item, 'invoiceHeader.issueDetail.issueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-',
        customerCode: _.get(item, 'invoiceHeader.saleOrderDetail.customerCode', '-'),
        customer: _.get(item, 'invoiceHeader.customerDetail.customerName', '-'),
        item: _.size(_.get(item, 'itemInvoice')),
        total: _.get(item, 'invoiceHeader.total'),
        blId: _.get(item, 'billingId'),
        blNo: _.get(item, 'invoiceHeader.billingNo'),
        blDate: _.get(item, 'invoiceHeader.detail.billingDate'),
        team: teamMap,
        createdBy: _.get(item, 'issueDetail.issueByName'),
        createdDate: _.get(item, 'issueDetail.issueDate')
          ? moment(_.get(item, 'issueDetail.issueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-',
        cnDate: '01/01/2023',
        statusCode: _.get(item, 'statusCode'),
      };
    });

    return {
      data: {
        data: {
          totalItem: _.get(response, 'data.total'),
          cnList: dataInfo,
        },
      },
    };
  } catch (error) {
    return error.response;
  }
};

const getCreditNoteDetail = async (id) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/getCreditNotesDetail`, { creditNoteId: id });

    console.log('getCreditNoteDetailApi', response, id);
    const dataInfo = _.get(response, 'data');
    return {
      data: {
        cnId: _.get(dataInfo, 'creditNoteId'),
        cnNo: _.get(dataInfo, 'creditNoteNo'),
        cn: _.get(dataInfo, 'cn'),
        invDate: _.get(dataInfo, 'issueDetail.issueDate'),
        customerCode: _.get(dataInfo, 'invoiceHeader.saleOrderDetail.customerCode'),
        customer:
          _.get(dataInfo, 'invoiceHeader.saleOrderDetail.customerCode') +
          ' ' +
          _.get(dataInfo, 'invoiceHeader.customerDetail.customerName'),
        customerName: _.get(dataInfo, 'invoiceHeader.customerDetail.customerName'),

        // item: 3,
        // blNo: 'RT-BL3010001',
        // blDate: '2023-01-01',

        // team: '01 Management, Test Company, ทีมทดสอบ, Test, FullStack, manual testing',
        // createdBy: 'Rinlaphat',
        cnDate: _.get(dataInfo, 'issueDetail.issueDate'),
        statusCode: _.get(dataInfo, 'statusCode'),
        statusName: langValue === 'TH' ? _.get(dataInfo, 'statusDetail.TH') : _.get(dataInfo, 'statusDetail.EN'),
        statusColor: _.get(dataInfo, 'statusDetail.color'),

        invIssuedBy: _.get(dataInfo, 'invoiceHeader.issueDetail.issueBy'),

        fullAddress: _.get(dataInfo, 'invoiceHeader.customerDetail.customerAddressBookFullAddress'),
        address: _.get(dataInfo, 'invoiceHeader.saleOrderDetail.customerAddressBookName'),
        customerTaxNo: _.get(dataInfo, 'invoiceHeader.customerDetail.customerTaxNo'),
        customerContactName: _.get(dataInfo, 'invoiceHeader.customerDetail.customerContactName'),
        customerContactPhone: _.get(dataInfo, 'invoiceHeader.customerDetail.customerContactPhone'),
        customerContactEmail: _.get(dataInfo, 'invoiceHeader.customerDetail.customerContactEmail'),

        customerInv: {
          customerSearch: _.get(dataInfo, 'invoiceHeader.customerDetail.customerId'),
          customer: {
            customerSearch: _.get(dataInfo, 'invoiceHeader.customerDetail.customerId'),
            customerName: _.get(dataInfo, 'invoiceHeader.customerDetail.customerName'),
          },
        },

        companyDetail: {
          ..._.get(dataInfo, 'invoiceHeader.saleOrderDetail.companyDetail'),         
          companyEmail: _.get(dataInfo, 'companyEmail'),
          companyWebsite: _.get(dataInfo, 'companyWebsite'),
        },

        // form inv
        invNo: _.get(dataInfo, 'invoiceHeader.invoiceNo'),
        invId: _.get(dataInfo, 'invoiceHeader.invoiceId'),
        issueBy: _.get(dataInfo, 'issueDetail.issueBy'),
        issueDate: _.get(dataInfo, 'issueDetail.issueDate'),

        // form price
        remark: _.get(dataInfo, 'remark'),
        totalOrigin: _.get(dataInfo, 'totalOrigin'),
        difference: _.get(dataInfo, 'difference'),
        totalCorrect: _.get(dataInfo, 'totalCorrect'),
        vat: _.get(dataInfo, 'vat', 0),
        vatNumber: _.get(dataInfo, 'vatNumber'),
        total: _.get(dataInfo, 'total'),
      },

      selectItem: _.map(_.get(dataInfo, 'itemInvoice'), (item, index) => {
        return {
          index: index + 1,
          itemCode: item.itemCode,
          item: item.item,
          invQty: _.toNumber(item.invoiceQty),
          invUnit: item.unit,
          invUnitName: item.unitName,
          invPriceUnit: item.invPriceUnit,
          invTotal: _.toNumber(item.invoiceQty) * _.toNumber(item.invPriceUnit),

          type: item.type,
          qty: item.qty,
          unitName: item.unitName,
          // unit: ,
          priceUnit: _.toNumber(item.priceUnit),
          remark: item.cnRemark,
          // remarkName: item.cnRemarkDetails ? langValue === 'TH' ? item.cnRemarkDetails.detail.TH : item.cnRemarkDetails.detail.EN : '',
          others: item.other,
          amount: _.toNumber(item.qty) * _.toNumber(item.priceUnit),
          id: item._id,
          empty: {
            qty: false,
            priceUnit: false,
            others: false,
            type: false,
          },
        };
      }),

      reference: [],
      attachmentData: _.map(_.get(dataInfo, 'attachFile'), (att, index) => {
        return {
          index: index + 1,
          attachmentName: att.fileName,
          id: att._id,
          remark: att.remark,
          fileUrl: att.url,
          fileRaw: att.url,
          file: [{ name: att.fileName, uid: att._id, thumbUrl: att.url }],
          form: 'back',
          type: 'uploaded',
        };
      }),
      
    };
  } catch (error) {
    return error.response;
  }
};

const getCreditNoteDetailOrigin = async (id) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/getCreditNotesDetail`, { creditNoteId: id });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getListInvoice = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/send-invoice-detail`, payload);
    console.log('getListInvoice', response);

    if (response.status === 200) {
      const dataInfo = _.map(_.get(response, 'data.data'), (data) => {
        const teamMap = _.map(_.get(data, 'orgIdOwner'), 'teamName').join(', ');
        return {
          index: _.get(data, 'index'),
          invId: _.get(data, 'invoiceId'),
          invNo: _.get(data, 'invoiceNo'),
          soNo: _.get(data, 'saleOrderNo'),
          dueDate: _.get(data, 'dueDate'),
          amount: _.get(data, 'total', 0),
          cn: _.get(data, 'cn', 0),
          remark: _.get(data, 'remark', '-'),
          team: teamMap,
          issuedBy: _.get(data, 'issueDetail.issueBy'),
          issuedDate: _.get(data, 'issueDetail.issueDate'),
          invDate: _.get(data, 'issueDetail.issueDate'),
          invIssuedBy: _.get(data, 'issueDetail.issueBy'),

          customerCode: _.get(data, 'customerDetail.customerId'),
          customer: _.get(data, 'customerDetail.customerCode', '-') + ' ' + _.get(data, 'customerDetail.customerName', '-'),
          customerName: _.get(data, 'customerDetail.customerName'),
          fullAddress: _.get(data, 'customerDetail.customerAddressBookFullAddress'),
          address: _.get(data, 'customerDetail.customerAddressBookName'),
          customerTaxNo: _.get(data, 'customerDetail.customerTaxNo'),
          customerContactName: _.get(data, 'customerDetail.customerContactName'),
          customerContactPhone: _.get(data, 'customerDetail.customerContactPhone'),
          customerContactEmail: _.get(data, 'customerDetail.customerContactEmail'),

          companyDetail: _.get(data, 'saleOrderDetail.companyDetail')
        };
      });
      return {
        data: dataInfo,
        total: _.get(response, 'data.total'),
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  } catch (error) {
    return error.response;
  }
};

const getListItemCreditNote = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/get-item-invoice`, payload);

    console.log('getListItemCreditNote', response);

    if (response.status === 200) {
      const dataInfo = _.map(_.get(response, 'data.data'), (data) => {
        return {
          index: _.get(data, 'index'),
          itemCode: _.get(data, 'itemMasterDetail.itemCode'),
          item: _.get(data, 'itemMasterDetail.itemName'),
          qty: _.get(data, 'qty'),
          unitName: _.get(data, 'itemMasterDetail.unitName'),
          unit: _.get(data, 'itemMasterDetail.unit'),
          priceUnit: _.get(data, 'itemMasterDetail.price'),
          amount: _.get(data, 'itemMasterDetail.total'),
          category: _.get(data, 'itemMasterDetail.detail.itemCategoryCode'),
          id: _.get(data, '_id'),
        };
      });
      return {
        data: dataInfo,
        total: _.get(response, 'data.total'),
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  } catch (error) {
    return error.response;
  }
};

const deleteCn = async (id) => {
  try {
    const response = await httpClient.delete(`/v1/credit-note/manager/company/${comCode}/deleteCreditNote/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const saveCreditNote = async (body) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/create-credit-notes`, body);
    console.log('handleSaveApi', response);
    return response;
  } catch (error) {
    console.log('handleSaveApierror', error.response);
    return error.response;
  }
};

const saveEditCreditNote = async (body) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/editCreditNote`, body);

    return response;
  } catch (error) {
    return error.response;
  }
};

const updateStatusCreditNote = async (body) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/updateStatus`, body);

    return response;
  } catch (error) {
    return error.response;
  }
};

const getCnReason = async () => {
  try {
    const response = await httpClient.get(`/v1/credit-note/manager/company/${comCode}/reasonCreditNote`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const uploadCnAttachment = async (formData, id) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/uploadFile/${id}`, formData);
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteCnAttachment = async (body) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/removeFile`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

const CnPDFDownloader = async (body) => {
  const { data, selectItem } = body;

  const id = _.get(data, 'cnNo');
  // const id = 'RT-PO24120005'

  console.log('CnPDFDownloader', data, selectItem, id);

  const payload = {
    approverMemComId: null,
    approverDate: null,
    approverName: null,
    companyName: _.get(data, 'companyDetail.companyName'),
    companyAddress: _.get(data, 'companyDetail.companyAddress'),
    companyTaxNo: _.get(data, 'companyDetail.companyTaxNo'),
    companyPhone: _.get(data, 'companyDetail.companyPhone'),
    companyEmail: _.get(data, 'companyDetail.companyEmail'),
    companyWebsite: _.get(data, 'companyDetail.companyWebsite'),

    customerTaxNo: _.get(data, 'customerTaxNo'),
    customerCode: _.get(data, 'customerCode'),
    customerName: _.get(data, 'customerName'),
    poId: '56bbaef3-eef8-4c2a-93ea-8a4d9e350fe9',
    customerAddress: _.get(data, 'fullAddress'),
    customerContact: _.get(data, 'customerContactName'),
    customerPhone: _.get(data, 'customerContactPhone'),

    billingNo: _.get(data, 'cnNo'),
    vendorSupplierPhone: '0999999999',
    vendorSupplierEmail: null,
    vendorSupplierId: '5cb5568f-48c4-44ff-847f-526409159a1b',
    vendorSupplierCode: 'VD001',
    vendorSupplierTaxNo: '1234560987127',
    vendorSupplierContactDetail: {
      vendorSupplierContactId: 'a61790d2-d275-478d-ad02-b9c5a482d4db',
      vendorSupplierContactName: 'K.Nut',
      vendorSupplierContactPhone: '0998887777',
    },
    itemAmount: '10 Items',
    totolPrice: '10,000.00 THB',
    issueDate: _.get(data, 'issueDate'),
    paymentTerm: 10,
    issueDateTxt: _.get(data, 'issueDate') ? moment(_.get(data, 'issueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-',
    referenceNo: '',
    quotationNo: '',
    statusName: _.get(data, 'statusName'),
    statusCode: _.get(data, 'statusCode'),
    statusColor: _.get(data, 'statusColor'),
    remark: _.get(data, 'remark'),
    taxInvoiceNumber: _.get(data, 'invNo'),
    invoiceNo: _.get(data, 'invNo'),
    invoiceId: _.get(data, 'invId'),
    itemList: _.map(selectItem, (item, index) => {
      return {
        itemPoId: '5e8b290d-3ae7-4881-97ff-13dd8aab5e5210000',
        index: index + 1,
        itemMasterId: '5e8b290d-3ae7-4881-97ff-13dd8aab5e52',
        qty: _.get(item, 'qty'),
        qtyWithUom: _.get(item, 'qty') + ' ' + _.get(item, 'invUnitName'),
        uomId: '63a110c8-c96c-4d66-98d3-8a49d1d21f78',
        pricePerUnit: _.get(item, 'priceUnit'),
        pricePerUnitTxt: '1,000.00 THB',
        amount: _.get(item, 'amount'),
        amountTxt: '10,000.00 THB',
        discountPercentage: 0,
        discountPrice: 0,
        discountPriceTxt: '0.00 THB',
        totalPrice: _.get(item, 'amount'),
        totalPriceTxt: '10,000.00 THB',
        remark: '',
        categoryCode: _.get(item, 'category'),
        itemName: _.get(item, 'item'),
        itemCode: _.get(item, 'itemCode'),
        uomName: 'Item',
      }
    }),
    invoiceTotal: _.get(data, 'totalOrigin'),
    correctInvoiceTotal: _.get(data, 'totalCorrect'),
    remainingDifference: _.get(data, 'difference'),
    deliveryPo: [],
    subtotal: 10000,
    discountPercent: 0,
    discount: 0,
    subtotalAfterDis: 10000,
    taxPercent: _.get(data, 'vat'),
    tax: _.get(data, 'vatNumber'),
    totalNum: _.get(data, 'total'),
    subtotalTxt: '10,000.00 THB',
    discountTxt: '0.00 THB',
    subtotalAfterDisTxt: '10,000.00 THB',
    taxTxt: '0.00 THB',
    total: _.get(data, 'total'),
    nextStatus: [],
    reOpen: false,
    totalNumberText: ThaiNumberToText(_.get(data, 'total', 0).toFixed(2)),
  };

  try {
    let url = `/v1/po/manager/company/${comCode}/credit-note-pdf-v2/${id}`
    if (comCode === "SA" || comCode === "SAT") {
      url = `/v1/po/manager/company/${comCode}/credit-note-pdf/${id}`
    }
    const response = await httpClient.post(url, payload, {
      responseType: 'blob',
    });
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {}
};

const exportCreditNote = async (body) => {
  try {
    const response = await httpClient.post(`/v1/credit-note/manager/company/${comCode}/getCreditNote`, body);

    return response.data
  } catch (error) {
    return error.response;
  }
};

const getCreditNoteBL = async (body) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/creditnotebl/page`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getConfigMenuCn,
  getRemarkCn,
  searchCreditNote,
  getCreditNoteDetail,
  getCreditNoteDetailOrigin,
  getListInvoice,
  getListItemCreditNote,
  deleteCn,
  saveCreditNote,
  saveEditCreditNote,
  updateStatusCreditNote,
  getCnReason,
  uploadCnAttachment,
  deleteCnAttachment,
  CnPDFDownloader,
  exportCreditNote,
  getCreditNoteBL
};
