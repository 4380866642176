import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Icon, Select, Dropdown, Button, Menu, Checkbox } from 'antd';
import '../css/action-search.css';
import Button01 from '../../../../components/v2/button_01';

const { Option } = Select;

export default (props) => {
  const {
    handleChangeOption,
    mainOrgId,
    orgData,
    columns,
    setOrgData,
    handleVisibleChange,
    handleOnVisiblecolumns,
    visible,
    checkedValuecolumns,
    defaultShowColumn,
    textErrorSelectColumn,
    handleCheckOk,
    handleReset,
    setOrgIdRaw,
    orgIdRaw,
  } = props;
  const intl = useIntl();

  const ignoreColumn = [
    'index',
    // 'taskNo',
    // 'taskType',
    // 'customerName',
  ];
  //const ignoreColumn = ['index'];
  const columnNew = columns.filter((col) => !ignoreColumn.includes(col.key));
  const menuColumn = () => {
    return (
      <Menu className="menu-columns">
        <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {columnNew.map((item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValuecolumns} checked={defaultShowColumn[item.key] ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectColumn}</Menu.Item>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  const orgInt =
    mainOrgId &&
    mainOrgId.map((item) => {
      return parseInt(item);
    });

  return (
    <div className="action-select-team-monitor">
      {/* <Select
        className="monitor-select-team"
        placeholder={intl.formatMessage({
          id: 'hintSelect',
          defaultMessage: 'Select Team',
        })}
        value={orgIdRaw.map(item => { return parseInt(item) })}
        defaultValue={[]}
        onChange={(e) => setOrgIdRaw(e)}
        maxTagCount={1}
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {orgData && orgData.map((e, index) => (
          <Option
            disabled={
              orgInt && orgInt.length > 50
                ? orgInt && orgInt.includes(e.orgId)
                  ? false
                  : true
                : false
            }
            key={index} value={e.orgId}>
            {e.orgName}</Option>
        ))}
      </Select> */}

      <Dropdown
        className="list-view-dropdown"
        trigger={['click']}
        placement="bottomRight"
        overlay={menuColumn}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <Button01 type="primary" btnsize="wd_at" fontSize="sm" onClick={handleOnVisiblecolumns}>
          <FormattedMessage id="monitorTabListViewColumnSelect" defaultMessage="Columns" /> <Icon type="down" />
        </Button01>
      </Dropdown>
    </div>
  );
};
