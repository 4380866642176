import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const downloadPDFApi = async ({ tableCode, body }) => {
  try {
    const response = await httpClient.post(`/v1/po/manager/company/${comCode}/print-report-table/${tableCode}`, body, {
      responseType: 'blob',
    });
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {}
};

export { downloadPDFApi };
