import React from 'react';
import { ResponsiveContainer, Bar, BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const ChartBar = ({ data, config, CustomLegend }) => {
  const { domain, yLeft } = config;

  const BarColors = ['#67C498', '#F7B255', '#6AB1E5', '#EB7379'];

  const barKeys = data.length > 0 ? Object.keys(data[0]).filter((key) => key.startsWith('bar') && !key.includes('Name')) : [];
  const barNames = barKeys.reduce((acc, key) => {
    const nameKey = `barName${key.replace('bar', '')}`;
    acc[key] = data[0][nameKey] || key;
    return acc;
  }, {});

  const baseHeight = 350;
  const extraHeightPerKey = 15;
  const dynamicHeight = baseHeight + (barKeys.length * 2) * extraHeightPerKey;

  return (
    <ResponsiveContainer style={{ marginTop: '20px', padding: '5px' }} width="100%" height={dynamicHeight}>
      <BarChart data={data}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis
          label={{ value: yLeft, angle: -90, position: 'insideLeft', fill: '#050505' }}
          domain={domain.left}
          tickCount={6}
          stroke="#050505"
          tickFormatter={(value) => {
            if (value >= 1000000) return `${Math.floor(value / 1000000)}M`;
            if (value >= 1000) return `${Math.floor(value / 1000)}k`;
            return Math.floor(value);
          }}
        />
        <Tooltip formatter={(value) => value.toLocaleString()} />
        <Legend content={CustomLegend ? <CustomLegend barNames={barNames} /> : null} />
        {barKeys.map((barKey, index) => (
          <Bar key={barKey} dataKey={barKey} name={barNames[barKey]} fill={barKeys.length === 1 ? "#53C139" : BarColors[index % BarColors.length]} barSize={data.length >= 8 ? 15 : 20} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartBar;
