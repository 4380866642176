import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getInitialColumns = async ({ tableCode }) => {
  try {
    const response = await httpClient.get(`/v1/po/manager/company/${comCode}/template-document/${tableCode}`);
    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {}
};

export { getInitialColumns };
