import React, { useState } from 'react';
import { Button, Card, Checkbox, Divider, Dropdown, Icon, Menu } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ChartLine from '../../component/chart-line';

const AreaUtili = (prop) => {
  const intl = useIntl();
  const {visibleDatasets,setVisibleDatasets,chartData} = prop

  // const chartData = [
  //   {
  //     name: 'ตลาดส้ม',
  //     gross: 100,
  //     net: 80,
  //     efficiency: 90,
  //   },
  //   {
  //     name: 'ตลาดผลไม้รวม',
  //     gross: 120,
  //     net: 90,
  //     efficiency: 90,
  //   },
  //   {
  //     name: 'ตลาดดอกไม้และสังฆภัณฑ์',
  //     gross: 150,
  //     net: 110,
  //     efficiency: 10,
  //   },
  //   {
  //     name: 'ตลาดสด ชั้น 1',
  //     gross: 170,
  //     net: 120,
  //     efficiency: 30,
  //   },
  //   {
  //     name: 'ตลาดสด ชั้น 2',
  //     gross: 160,
  //     net: 100,
  //     efficiency: 70,
  //   },
  //   {
  //     name: 'ตลาดของแห้ง',
  //     gross: 180,
  //     net: 140,
  //     efficiency: 40,
  //   },
  // ];

  const datasetConfig = {
    gross: { label: 'Gross Area', color: '#3BA223' },
    net: { label: 'Net Area', color: '#F88511' },
    efficiency: { label: 'Efficiency', color: '#0F5AA6' },
  };

  const dataKeys = Object.keys(datasetConfig);

  // const [visibleDatasets, setVisibleDatasets] = useState(dataKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {}));

  const [tempVisibleDatasets, setTempVisibleDatasets] = useState({
    ...visibleDatasets,
  });

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDataset = (key) => {
    setTempVisibleDatasets((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const confirmChanges = () => {
    setVisibleDatasets({ ...tempVisibleDatasets });
    setDropdownVisible(false);
  };

  const resetChanges = () => {
    setTempVisibleDatasets(dataKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {}));
    setVisibleDatasets(dataKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {}));
    setDropdownVisible(false);
  };

  const menuColumn = (
    <Menu style={{ width: '200px', paddingTop: '10px', paddingBottom: '0px' }}>
      {dataKeys.map((key) => (
        <Menu.Item key={key} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Checkbox checked={tempVisibleDatasets[key]} onChange={() => toggleDataset(key)}>
            {datasetConfig[key].label}
          </Checkbox>
        </Menu.Item>
      ))}

      <Divider style={{ margin: '10px 0 0 0' }} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={confirmChanges}
          style={{
            fontSize: '14px',
            border: 'none',
            width: '48%',
            height: '37px',
          }}
        >
          <FormattedMessage id="btnOK" defaultMessage="OK" />
        </Button>
        <Button
          onClick={resetChanges}
          style={{
            fontSize: '14px',
            border: 'none',
            width: '48%',
            height: '37px',
          }}
        >
          <FormattedMessage id="btnReset" defaultMessage="Reset" />
        </Button>
      </div>
    </Menu>
  );

  const filteredData = chartData.map((item) => ({
    name: item.name,
    ...dataKeys.reduce((acc, key) => (visibleDatasets[key] ? { ...acc, [key]: item[key] } : acc), {}),
  }));

  const chartConfig = {
    domain: {
      left: [0, 'auto'],
      right: [0, 100],
    },
    yAxisIdMapping: {
      gross: 'left',
      net: 'left',
      efficiency: 'right',
    },
    yLeft: "Area (sq.m.)",
    yRight: 'Efficiency (%)'
  };

  return (
    <ChartLine data={filteredData} legendFormatter={(value) => datasetConfig[value]?.label || value} datasetConfig={datasetConfig}  config={chartConfig}/>
    // <Card
    //   title={intl.formatMessage({ id: 'RevenueAreaUtilization', defaultMessage: 'Area Utilization' })}
    //   extra={
    //     <div>
    //       <Dropdown
    //         className="list-view-dropdown"
    //         trigger={['click']}
    //         placement="bottomRight"
    //         overlay={menuColumn}
    //         visible={dropdownVisible}
    //         onVisibleChange={(visible) => setDropdownVisible(visible)}
    //         getPopupContainer={(triggerNode) => triggerNode.parentNode}
    //       >
    //         <Button_01 type="primary" btnsize="wd_at" fontSize="sm" className="style-buttun-columns">
    //           <FormattedMessage id="RevenueChartDisplay" defaultMessage="Display" /> <Icon type="down" />
    //         </Button_01>
    //       </Dropdown>
    //     </div>
    //   }
    //   style={{ border: 'none' }}
    // >
    //   <RechartLine data={filteredData} legendFormatter={(value) => datasetConfig[value]?.label || value} datasetConfig={datasetConfig}  config={chartConfig}/>
    // </Card>
  );
};

export default AreaUtili;
