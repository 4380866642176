import React, { useState, useContext } from 'react';
import { Button, Card, Tag, Modal } from 'antd';
import { ProjectContext } from '../context';
import Button01 from '../../../components/v2/button_01';
import ActionProject from './action-project';
import { useIntl, FormattedMessage } from 'react-intl';
import ProjectSource from './list-propject';
import _ from 'lodash';
import '../project-list/css/index.css';
import { useEffect } from 'react';
import { getProjectList, deleteProject } from '../../../controllers/project/project-api';
import moment from 'moment';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';

const ProjectList = () => {
  const intl = useIntl();
  const { state, setState } = useContext(ProjectContext);
  const { fieldChange, fliterByColumn, paginationPage, paginationSize, orderSort, fieldSort, trigger, projectData } = state;
  const { setVisible, setDataSource, setTotal, setVisibleEdit, setProjectData, setTrigger } = setState;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: intl.formatMessage({
        id: `projectColProjectNo`,
        defaultMessage: 'Project No.',
      }),
      dataIndex: 'projectNo',
      key: 'projectNo',
      width: 200,
      fixed: 'left',
      sorter: true,
      render: (text, record, index) => record.projectNo,
    },
    {
      title: intl.formatMessage({
        id: `projectColProjectName`,
        defaultMessage: 'Project Name',
      }),
      dataIndex: 'projectName',
      key: 'projectName',
      width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => record.projectName,
    },
    {
      title: intl.formatMessage({
        id: `projectColType`,
        defaultMessage: 'Project Type',
      }),
      dataIndex: 'projectType',
      key: 'projectType',
      width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => record.projectType,
    },
    {
      title: intl.formatMessage({
        id: `projectColPriority`,
        defaultMessage: 'Priority',
      }),
      dataIndex: 'priority',
      key: 'priority',
      width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => record.priority,
    },
    {
      title: intl.formatMessage({
        id: `projectColCustomer`,
        defaultMessage: 'Customer',
      }),
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => record.customer,
    },
    {
      title: intl.formatMessage({
        id: `projectColProjectManager`,
        defaultMessage: 'Project Manager',
      }),
      dataIndex: 'projectManager',
      key: 'projectManager',
      width: 200,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => record.projectManager,
    },
    {
      title: intl.formatMessage({
        id: `projectColPlanDate`,
        defaultMessage: 'Plan Date',
      }),
      dataIndex: 'planDate',
      key: 'planDate',
      width: 320,
      sorter: true,
      width: 200,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => record.planDate,
    },
    {
      title: intl.formatMessage({
        id: `projectColRef`,
        defaultMessage: 'Reference No.',
      }),
      dataIndex: 'reference',
      key: 'reference',
      width: 200,
      sorter: true,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => record.reference,
    },
    {
      title: intl.formatMessage({
        id: `projectColRemark`,
        defaultMessage: 'Remark',
      }),
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      ellipsis: true,
      render: (text, record, index) => record.remark,
    },
  ];

  const setShowColumn = {
    index: true,
    projectNo: true,
    projectName: true,
    projectType: true,
    priority: false,
    customer: false,
    projectManager: true,
    planDate: true,
    reference: false,
    remark: false,
  };

  const setShowColumnArr = ['index', 'projectNo', 'projectName', 'projectType', 'projectManager', 'planDate'];

  const [visibleCreate, setVisibleCreate] = useState(false);
  // const [visibleEdit, setVisibleEdit] = useState();
  const [disabled, setDisabled] = useState(false);
  const [editData, setEditData] = useState();
  const [statusModal, setStatusModal] = useState('view');
  const ignoreColumn = ['priority', 'customer', 'reference', 'remark'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const ignoreSearchColumn = ['index'];
  const columnNewSearch = columns.filter((col) => !ignoreSearchColumn.includes(col.key));

  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({
    ...setShowColumn,
  });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [triggerModal, setTriggerModal] = useState(false);
  const [scrollTableX, setScrollTableX] = useState(1000);

  useEffect(() => {
    const getApi = async () => {
      let payload = {
        search: fieldChange,
        searchBy: fliterByColumn,
        orderType: orderSort,
        orderBy: fieldSort,
        paging: fieldChange ? 1 : paginationPage,
        rowsPerPages: paginationSize,
      };
      const response = await getProjectList(payload);
      const mapData =
        response.data &&
        response.data.map((item) => {
          return {
            id: item.projectId,
            index: item.index,
            projectNo: item.projectNo,
            projectName: item.projectName,
            projectType: item.projectTypeName,
            priority: item.projectPriorityName,
            customer: item.customerName,
            projectManager: item.projectMangerName,
            planDate: item.planDate,
            reference: item.referenceNo,
            remark: item.remark,
            projectImgProfile: item.projectImgProfile,
            projectTypeId: item.projectTypeId,
            projectPriorityId: item.projectPriorityId,
            planStart: moment(item.planStart, 'YYYY-MM-DD'),
            planFinish: moment(item.planFinish, 'YYYY-MM-DD'),
            customerId: item.customerId,
            projectMangerId: parseInt(item.projectMangerId),
            fileHash: item.projectImgProfileFileHash,
          };
        });
      setDataSource(mapData);
      setTotal(_.get(response, 'total'));
      // console.log('response:::', response);
    };
    getApi();
  }, [fieldChange, fliterByColumn, orderSort, fieldSort, paginationPage, paginationSize, trigger]);

  const openModalCreate = () => {
    setVisible(true);
  };

  const hanldeClickModal = async (value) => {
    setProjectData(value);
    setVisibleEdit(true);
  };

  const handleDelete = (data, status) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmDeleteProject2`,
        defaultMessage: 'Do you want to delete?',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteProject(data.id);
        if (response.status === 200) {
          setTrigger((current) => !current);
          successNotification(response.data.massage);
        } else {
          errorNotification(response.data.massage);
        }
      },
      onCancel() {},
    });
  };

  const actionCreateButton = (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P39PG1C1', 'P39PG1C1A2') ? (
          <Button01 className="button-project" fontsize="sm" type="primary" onClick={openModalCreate}>
            <FormattedMessage id="btnCreateProject" defaultMessage="Create Project" />
          </Button01>
        ) : null
      }
    </PageSettings.Consumer>
  );

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P39PG1C1', 'P39PG1C1A1') ? (
          <Card
            className="project-card"
            title={intl.formatMessage({
              id: `titleLabelProject`,
              defaultMessage: 'Project',
            })}
            extra={actionCreateButton}
          >
            <div style={{ height: 'calc(100vh - 190px)', overflowY: 'auto' }} >
              <div>
                <ActionProject
                  columns={columns}
                  setShowColumn={setShowColumn}
                  setShowColumnArr={setShowColumnArr}
                  columnNewSearch={columnNewSearch}
                  newDataColumns={newDataColumns}
                  listArrayColumns={listArrayColumns}
                  defaultShowColumn={defaultShowColumn}
                  newColumns={newColumns}
                  setNewDataColumns={setNewDataColumns}
                  setListArrayColumns={setListArrayColumns}
                  setDefaultShowColumn={setDefaultShowColumn}
                  setScrollTableX={setScrollTableX}
                />
              </div>
              <div>
                <ProjectSource
                  newDataColumns={newDataColumns}
                  hanldeClickModal={hanldeClickModal}
                  handleDelete={handleDelete}
                  scrollTableX={scrollTableX}
                />
              </div>
            </div>
          </Card>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default ProjectList;
