import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ChartLineBar = ({ data, config, CustomLegend }) => {
  const { barColor, lineColor, lineDash, lineLegendType, domain, yLeft, yRight, yAxisBarId="left", yAxisLineId="right" } = config;

  const BarColors = ['#67C498', '#F7B255', '#6AB1E5', '#EB7379'];
  const LineColors = ['#079448', '#F4821E', '#0F6FB5', '#BA3137'];

  const barKeys = data.length > 0 ? Object.keys(data[0]).filter((key) => key.startsWith('bar') && !key.includes('Name')) : [];
  const lineKeys = data.length > 0 ? Object.keys(data[0]).filter((key) => key.startsWith('line') && !key.includes('Name')) : [];
  const barNames = barKeys.reduce((acc, key) => {
    const nameKey = `barName${key.replace('bar', '')}`;
    acc[key] = data[0][nameKey] || key;
    return acc;
  }, {});

  const lineNames = lineKeys.reduce((acc, key) => {
    const nameKey = `lineName${key.replace('line', '')}`;
    acc[key] = data[0][nameKey] || key;
    return acc;
  }, {});

  const baseHeight = 350;
  const extraHeightPerKey = 15;
  const dynamicHeight = baseHeight + (barKeys.length + lineKeys.length) * extraHeightPerKey;

  return (
    <ResponsiveContainer style={{ marginTop: '20px', padding: '5px' }} width="100%" height={dynamicHeight}>
      <ComposedChart data={data}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis dataKey="name" stroke="#050505" />
        <YAxis
          label={{ value: yLeft, angle: -90, position: 'insideLeft', fill: '#050505' }}
          yAxisId="left"
          domain={domain}
          tickCount={6}
          stroke="#050505"
          tickFormatter={(value) => {
            if (value >= 1000000) return `${Math.floor(value / 1000000)}M`;
            if (value >= 1000) return `${Math.floor(value / 1000)}k`;
            return Math.floor(value);
          }}
        />
        <YAxis
          orientation="right"
          label={{ value: yRight, angle: 90, position: 'insideRight', fill: '#050505' }}
          yAxisId="right"
          domain={[0, 'auto']}
          tickCount={6}
          stroke="#050505"
          tickFormatter={(value) => {
            if (value >= 1000000) return `${Math.floor(value / 1000000)}M`;
            if (value >= 1000) return `${Math.floor(value / 1000)}k`;
            return Math.floor(value);
          }}
        />
        <Tooltip formatter={(value) => value.toLocaleString()} />
        <Legend
          content={CustomLegend ? <CustomLegend barNames={barNames} lineNames={lineNames} /> : null}
        />

        {/* <Bar dataKey="bar" name={barLegend} barSize={20} fill={barColor} yAxisId="left" />
        <Line
          name={lineLegend}
          dataKey="line"
          dot={{ strokeDasharray: '' }}
          stroke={lineColor}
          strokeDasharray={lineDash}
          yAxisId="right"
          legendType={lineLegendType}
        /> */}
        {barKeys.map((barKey, index) => (
          <Bar key={barKey} dataKey={barKey} name={barNames[barKey]} barSize={data.length >= 8 ? 15 : 20} fill={barKeys.length === 1 ? "#53C139" : BarColors[index % BarColors.length]} yAxisId={yAxisBarId} />
        ))}
        {lineKeys.map((lineKey, index) => (
          <Line
            key={lineKey}
            dataKey={lineKey}
            name={lineNames[lineKey]}
            dot={{ strokeDasharray: '' }}
            stroke={LineColors[index % LineColors.length]}
            strokeDasharray={lineKey === 'line' ? lineDash : ''}
            yAxisId={yAxisLineId}
            legendType={lineLegendType}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

ChartLineBar.defaultProps = {
  data: [],
  config: {
    barColor: '#8884d8',
    lineColor: '#82ca9d',
    lineDash: '2 2',
    domain: {
      left: [0, 100],
      right: [0, 'auto'],
    },
  },
};

export default ChartLineBar;
