import React, { useEffect } from 'react';
import { Badge, Col, DatePicker, Form, Icon, Input, Row, Select } from 'antd';
import _ from 'lodash';
import LabeRequireForm from '../../../../../components/label-required-form';
import { useIntl } from 'react-intl';
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;

const CreditNoteInvForm = (props) => {
  const { form, handleOpenModalInvoice, dataForm, initial, onChange } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();

  return (
    <Form form={form} colon={false} layout="vertival" className="creditnote-form">
      <Row gutter={[24, 8]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelINVNo', defaultMessage: 'INV No.' })} req={true} />}
          >
            {getFieldDecorator('invNo', {
              // initialValue: _.get(record, 'customerName') || '',
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'creditNoteFormWarnINVNo', defaultMessage: 'Please select INV No.' }),
                },
              ],
            })(
              <Search
                placeholder={intl.formatMessage({ id: 'creditNoteFormLableINVNoPlaceholder', defaultMessage: 'Select INV No.' })}
                onSearch={(value) => handleOpenModalInvoice()}
                enterButton
                className="search-icon-input"
                readOnly
              />
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelINVDate', defaultMessage: 'INV Date' })} req={false} />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>{_.get(dataForm, 'invDate') ? moment(_.get(dataForm, 'invDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}</span>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'creditNoteFormLabelINVIssuedBy', defaultMessage: 'INV Issued by' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>{_.get(dataForm, 'invIssuedBy') || '-'}</span>
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 8]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelCustomer', defaultMessage: 'Customer' })} req={false} />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>{_.get(dataForm, 'customer') || intl.formatMessage({ id: 'creditNoteFormLabelCustomer', defaultMessage: 'Customer' })}</span>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'creditnoteFormLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'invNo') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span
                    title={`${_.get(dataForm, 'address')}} ${(<Badge color="#000000" className="badge-text" />)} ${_.get(
                      dataForm,
                      'fullAddress'
                    )}`}
                    style={{ color: '#050505' }}
                  >
                    {_.get(dataForm, 'address')} - {/* <Badge color="#000000" className="badge-text" />  */}
                    {_.get(dataForm, 'fullAddress')}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelTaxNo', defaultMessage: 'Tax No.' })}{' '}
                    {_.get(dataForm, 'customerTaxNo') || '-'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelTaxNo', defaultMessage: 'Tax No.' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'creditnoteFormLabelCustomerContact', defaultMessage: 'Contact' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'invNo') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerContactName')}</span>
                </div>
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>
                    {_.get(dataForm, 'customerContactPhone')}, {_.get(dataForm, 'customerContactEmail')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelCustomerContact', defaultMessage: 'Contact' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelEmail', defaultMessage: 'Email' })},{' '}
                    {intl.formatMessage({ id: 'creditnoteFormLabelPhone', defaultMessage: 'Phone' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 8]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelIssueBy', defaultMessage: 'Issue by' })} req={true} />
            }
          >
            {getFieldDecorator('issueBy', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'creditNoteFormWarnIssueBy', defaultMessage: 'Please select Issue by' }),
                },
              ],
            })(
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'issueBy')}
                // filterOption={false}
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={_.get(dataForm, 'issueBy')}
                placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefIssuedBy', defaultMessage: 'Select Issued by' })}
                style={{ width: '100%' }}
              >
                {_.map(_.get(initial, 'optionsIssueBy'), (item) => (
                  <Option value={item.memComId}>{item.fullname}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelIssueDate', defaultMessage: 'Issue Date' })} req={true} />
            }
          >
            {getFieldDecorator('issueDate', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'creditNoteFormWarnIssueDate', defaultMessage: 'Please select Issue Date' }),
                },
              ],
            })(
              <DatePicker
                style={{ width: '100%' }}
                allowClear={false}
                format={'DD/MM/YYYY'}
                onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'), 'issueDate')}
                value={_.get(dataForm, 'issueDate')}
                placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefToIssuedDate', defaultMessage: 'Select Issued Date' })}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={8}></Col>
      </Row>
    </Form>
  );
};

export default CreditNoteInvForm;
