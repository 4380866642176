import './index.css';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  ComposedChart,
  Scatter,
  ScatterChart,
  DefaultTooltipContent,
} from 'recharts';

const ChartStackBar = ({ data, config }) => {
  const { chartColors, legendNames, yLeft } = config;
  const barKeys = Object.keys(data[0] || {}).filter((key) => key !== 'name' && key !== 'scatter');

  const calculateStackPercentage = (value, stackTotal) => {
    if (stackTotal === 0) return '0%';
    return `${((value / stackTotal) * 100).toFixed(1)}%`;
  };

  return (
    <ResponsiveContainer style={{ marginTop: '20px', padding: '5px' }} width="100%" height={350}>
      <ComposedChart data={data}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis dataKey="name" stroke="#050505" />
        <YAxis
          label={{ value: yLeft, angle: -90, position: 'insideLeft', fill: '#050505' }}
          tickCount={6}
          stroke="#050505"
          tickFormatter={(value) => {
            if (value >= 1000000) return `${Math.floor(value / 1000000)}M`;
            if (value >= 1000) return `${Math.floor(value / 1000)}k`;
            return Math.floor(value);
          }}     
        />
        <Tooltip
          content={({ payload, label }) => {
            if (!payload || payload.length === 0) return null;

            // Filter out scatter data from the tooltip
            const filteredPayload = payload.filter((item) => item.dataKey !== 'scatter');

            // If no remaining data, don't show tooltip
            if (filteredPayload.length === 0) return null;

            const formattedPayload = filteredPayload.map((item) => ({
              ...item,
              value: item.value.toLocaleString(),
            }));

            return <DefaultTooltipContent payload={formattedPayload} label={label} />;
          }}
        />
        <Legend formatter={(value) => legendNames[value] || value} />
        {barKeys.map((key, index) => (
          <Bar barSize={45} key={key} dataKey={key} stackId="a" name={legendNames[key] || key} fill={chartColors[key] || '#8884d8'}>
            {index === 0 && (
              <LabelList
                position="center"
                content={({ x, y, width, height, value, index }) => {
                  const stackTotal = barKeys.reduce((sum, k) => sum + data[index][k], 0);
                  const percentage = calculateStackPercentage(value, stackTotal);
                  return (
                    <text x={x + width / 2} y={y + height / 2} dy={4} fill="#fff" fontSize="12" fontWeight="bold" textAnchor="middle">
                      {percentage}
                    </text>
                  );
                }}
              />
            )}
          </Bar>
        ))}
        <Scatter
          dataKey="scatter"
          name={legendNames['scatter']}
          fill="#1D3557"
          shape={(props) => {
            const { cx, cy } = props;
            const barWidth = 45;
            const xLeft = cx - barWidth / 2;
            const xRight = cx + barWidth / 2;

            return <line x1={xLeft} y1={cy} x2={xRight} y2={cy} stroke="#1D3557" strokeWidth={2} />;
          }}
          legendType="square"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartStackBar;
