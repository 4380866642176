import React, { useContext, useRef, useState } from 'react';
import { MarketProvider } from './context';
import '../index.css';
import { ConfigProvider } from 'antd';
import MarketPage from './market-page';
import FullScreen from 'react-fullscreen-crossbrowser';

const MarketDashboardPage = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  return (
    <FullScreen enabled={isFullScreen} onChange={(setIsFullScreen)}>
      <MarketProvider>
        <div>
          <MarketPage isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} />
        </div>
      </MarketProvider>
    </FullScreen>
  );
};

export default MarketDashboardPage;
