import React, { useEffect, useContext, useState, useRef } from 'react';
import { Row, Col, Modal, Card, Tabs, Form, Select, Tag, Spin, Badge, Avatar } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { StickyContainer } from 'react-sticky';
import DeliveryOrderDetailInformation from './component/order';
import Button01 from '../../../../components/v2/button_01';
import Button02 from '../../../../components/v2/button_02';
import DeliveryOrderParcel from './component/parcel';
import DeliveryOrderTracking from './component/tracking';
import DeliveryOrderLogs from './component/logs';
import { DeliveryContext } from '../..';
import {
  getOrdersByIdParcel,
  getStatus,
  getOrdersTracking,
  getTaskDetailDeliveryOrder,
  getDataActionLogDo,
} from '../../../../controllers/orders-controller/api';
import _ from 'lodash';
import DeliveryEditOrderForm from '../shipment/modal/edit-order';
import DeliveryWeightTotal from './modal/weight-total';
import DeliveryParcel from './modal/parcel';
import DeliveryShippingPrice from './modal/shipping-price';
import DeliveryPay from './modal/qr';
import { successNotification, errorNotification } from '../../../../components/v2/notification';
import { editOrder } from '../../../../controllers/orders-controller/api';
import moment from 'moment';
import styled from 'styled-components';
import DeliveryOrderButtons from './component/button';
import DeliveryOrderTaskDetail from './component/task-detail';
import { getTaskDetailInformation } from '../../../../controllers/monitor/listView';
import { ModalTaskDetail } from '../../../../components/modal-task-detail-v2';
import { useAppContext } from '../../../../includes/indexProvider';
import DrawerDONote from '../../../../components/drawer-component/delivery-order-note';
import { getQueueMessages } from '../../../../hooks/use-queue-api';
import DeliveryCreateOrderForm from '../../../../components/modal-create-order';

export const DeliveryShipmentDetailContext = React.createContext();
const comCode = localStorage.getItem('comCode');

const { TabPane } = Tabs;

const DeliveryShipmentDetailForm = (props) => {
  const intl = useIntl();
  const app = useAppContext();
  const { form } = props;
  const { selectShipmentData, handleCancelShipmentDetail, selectBranchData, setSelectShipmentData, setTriggerOrder, sizeList } = useContext(
    DeliveryContext
  );
  const deliContext = useContext(DeliveryContext);
  const { validateFields, resetFields } = form;
  const [orderData, setOrderData] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const [visibleEditOrder, setVisibleEditOrder] = useState(false);
  const [editData, setEditData] = useState();
  const [visibleTotalWeight, setVisibleTotalWeight] = useState(false);
  const [visibleParcel, setVisibleParcel] = useState(false);
  const [visibleShipping, setVisibleShipping] = useState(false);
  const [visiblePay, setVisiblePay] = useState(false);
  const [statusNext, setStatusNext] = useState([]);
  const [statusSelect, setStatusSelect] = useState();
  const [typeModalParcel, setTypeModalParcel] = useState();
  const [trigger, setTrigger] = useState(false);
  const [trackingData, setTrackingData] = useState();
  const filterConfirmParcel = _.filter(_.get(editData, 'pacelDetails'), (item) => {
    return _.get(item, 'status.statusCode') === 'confirmed';
  });
  const [spinOrder, setSpinOrder] = useState(false);
  const [statusAddress, setStatusAddress] = useState();
  const [taskDetailData, setTaskDetailData] = useState();
  const [configTaskDetail, setConfigTaskDetail] = useState({ visible: false, id: null, type: 'view' });
  //---------------------------------------------------------
  const [noteData, setNoteData] = useState();
  //---------------------------------------------------------
  const [logData, setLogData] = useState();


  const listDetailType = {
    data : {
      orderDetailTypeList : [{
        code: "deliveryOrderParcel",
        txt: "Parcel"
      }],
    }
  }

  const SelectStatus = styled(Select)`
    width: 'auto';
    .ant-select-selection--single {
      border-radius: 10px !important;
      height: 24px;
      color: #fff;
      width: fit-content;
      font-size: 13px;
      background-color: ${_.get(statusSelect, 'statusColor')};
      border: 1px solid ${_.get(statusSelect, 'statusColor')};
    }
    .ant-select-selection-selected-value {
      margin-right: 5px;
      margin-top: 0px;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
      fill: #fff;
    }
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active,
    .ant-select-selection:hover,
    .ant-select-open .ant-select-selection {
      border-color: ${_.get(statusSelect, 'statusColor')} !important;
      box-shadow: none !important;
    }
  `;

  useEffect(() => {
    if (noteData) {
      app.fnc.getCodeAndDataDrawer('note', {
        title: intl.formatMessage({ id: 'noteTitle', defaultMessage: 'Note' }),
        content: <DrawerDONote data={noteData} />,
        noteData: noteData,
      });
    }
  }, [noteData]);

  useEffect(() => {
    const getOrderId = async () => {
      setSpinOrder(true);
      const response = await getOrdersByIdParcel(_.get(selectShipmentData, 'orderId'));
      const statusData = await getStatus(_.get(selectShipmentData, 'orderId'));
      const tracking = await getOrdersTracking({ orderNo: _.get(selectShipmentData, 'orderNo') });
      const taskdDetail = await getTaskDetailDeliveryOrder(_.get(selectShipmentData, 'orderNo'));
      const logDetail = await getDataActionLogDo({"orderId":_.get(selectShipmentData, 'orderId')});
      // setEditData(_.get(response, 'data.data'));
      setOrderData(_.get(response, 'data.data'));
      setStatusSelect(_.get(response, 'data.data.statusDetail'));
      setStatusNext(_.get(statusData, 'data.statusList'));
      setTrackingData(_.get(tracking, 'data.data'));
      setTaskDetailData(_.get(taskdDetail, 'data'));
      setNoteData(_.get(selectShipmentData, 'orderId'));
      setLogData(_.get(logDetail,'data.data.logList'))

      setSpinOrder(false);


      const responseData = _.get(response, 'data.data');
      const tableNew = _.map(_.get(responseData, 'pacelDetails'), (item) => {
        return {
          index: _.get(item, 'parcelIndex'),
          _id: _.get(item, '_id'),
          parcelNo: _.get(item, 'parcelNo'),
          parcelId: _.get(item, 'parcelNo'),
          size: _.get(item, 'size'),
          weight: _.get(item, 'weight'),
          shippingPrice: '0',
          statusCode: _.get(item, 'status.statusCode'),
        };
      });

      const tableNewFilter = _.filter(tableNew, (t) => {
        return _.get(t, 'statusCode') !== 'canceled';
      });

      const setNewTable = _.get(responseData, 'detailTypeCode') === 'deliveryOrderParcel' ? tableNewFilter : tableNew
      setEditData({...responseData, table: setNewTable})

    };
    if (selectShipmentData) {
      getOrderId();
    }
  }, [selectShipmentData, trigger]);



  const handleOpenModalEditOrder = () => {
    setVisibleEditOrder(true);
  };

  const handleSwtichForm = () => {
    setSpinOrder(true);
    setEditFlag(true);
    setSpinOrder(false);
  };

  const handelCancelEdit = () => {
    setSpinOrder(true);
    setTrigger((cur) => !cur);
    setEditFlag(false);
    resetFields();
    setSpinOrder(false);
  };

  const onChangeStatus = (value, e) => {
    const filterStatus = _.filter(statusNext, (item) => {
      return item.statusId === e.props.value;
    });
    setStatusSelect(_.get(filterStatus, '[0]'));
    // setStateTask.setVisibleComment(true)
  };

  const handleSaveEdit = (data) => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setSpinOrder(true);
      const checkData = data ? data : editData;
      const body = {
        branchDetail: {
          branchId: _.get(selectBranchData, 'id'),
          branchCode: _.get(selectBranchData, 'branchCode'),
          branchName: _.get(selectBranchData, 'branchName'),
          branchPhone: _.get(selectBranchData, 'branchPhone'),
          branchAddressName: _.get(selectBranchData, 'branchAddressName'),
          branchAddress: _.get(selectBranchData, 'branchAddressDescription'),
          branchAddressLat: _.get(selectBranchData, 'branchAddressLat'),
          branchAddressLng: _.get(selectBranchData, 'branchAddressLng'),
          branchRemark: _.get(selectBranchData, 'branchRemark'),
        },
        customerDetail: _.get(checkData, 'customerDetail'),
        sourceDetail: _.get(checkData, 'sourceDetail'),
        contactDetail: _.get(checkData, 'contactDetail'),
        destinationDetail: _.get(checkData, 'destinationDetail'),
        reasonDetail: _.get(checkData, 'reasonDetail'),
        statusDetail: statusSelect,
        deliveryDate: data ? _.get(data, 'deliveryDate') : moment(_.get(values, 'shippingDate')).format('YYYY-MM-DD'),
        deliveryTime: data ? _.get(data, 'deliveryTime') : moment(_.get(values, 'timeWindow')).format('HH:mm'),
        remark: _.get(values, 'remark'),
        amount: _.get(checkData, 'amount'),
        size: undefined,
        weight: _.get(checkData, 'weight'),
        referenceType: undefined,
        referenceNo: _.get(values, 'refNo'),
        parcel: _.get(checkData, 'parcel'),
      };

      const payload = {
        apiMethod: 'put',
        urlQueue: `/queue/manager/company/${comCode}/channgedeliveryorder`,
        apiUrl: `${process.env.REACT_APP_URL_MANAGER}/order-manager/v5/order/manager/company/${comCode}/web/deliveryorder/${_.get(
          orderData,
          'orderId'
        )}`,
        payload: body,
      };

      try {
        const response = await getQueueMessages(payload);
        if (_.get(response, 'status.code') === 200) {
          successNotification(_.get(response, 'status.message'));
          setTrigger((cur) => !cur);
          deliContext.setTrigger((cur) => !cur);
          setTriggerOrder((cur) => !cur);
          setEditFlag(false);
          resetFields();
          setSpinOrder(false);
          setStatusAddress();
        } else {
          errorNotification(_.get(response, 'status.message'));
          setSpinOrder(false);
        }
      } catch (error) {
        errorNotification(_.get(error, 'response.status.message'));
      }
      // const response = await editOrder(body, _.get(orderData, 'orderId'));
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      //   setTrigger((cur) => !cur);
      //   deliContext.setTrigger((cur) => !cur);
      //   setTriggerOrder((cur) => !cur);
      //   setEditFlag(false);
      //   resetFields();
      //   setSpinOrder(false);
      //   setStatusAddress();
      // } else {
      //   errorNotification(_.get(response, 'status.message'));
      //   setSpinOrder(false);
      // }
    });
  };

  const handleCheckCustomerAddress = (type) => {
    errorNotification(intl.formatMessage({ id: 'deliveryOrderValidateNoAddress', defaultMessage: 'Please update customer address.' }));
    setVisibleEditOrder(true);
    setStatusAddress(type);
  };

  console.log('editData');

  const handleConfirm = () => {
    // if (!_.get(editData, 'sourceDetail.address')) return handleCheckCustomerAddress('confirm');
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblConfirm', defaultMessage: 'Are you sure you want to confirm ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const filterConfirm = _.filter(statusNext, (item) => {
          return item.statusCode === 'confirmed';
        });
        const body = {
          ...editData,
          statusDetail: _.get(filterConfirm, '[0]'),
          branchDetail: {
            branchId: _.get(selectBranchData, 'id'),
            branchCode: _.get(selectBranchData, 'branchCode'),
            branchName: _.get(selectBranchData, 'branchName'),
            branchPhone: _.get(selectBranchData, 'branchPhone'),
            branchAddressName: _.get(selectBranchData, 'branchAddressName'),
            branchAddress: _.get(selectBranchData, 'branchAddressDescription'),
            branchAddressLat: _.get(selectBranchData, 'branchAddressLat'),
            branchAddressLng: _.get(selectBranchData, 'branchAddressLng'),
            branchRemark: _.get(selectBranchData, 'branchRemark'),
          },
        };
        const response = await editOrder(body, _.get(orderData, 'orderId'));
        if (response.status.code === 200) {
          successNotification(response.status.message);
          setSelectShipmentData();
          app.fnc.getCodeAndDataDrawer('', '')
          setTriggerOrder((cur) => !cur);
          deliContext.setTrigger((cur) => !cur);
          resetFields();
        } else {
          errorNotification(response.status.message);
        }
      },
      onCancel() {},
    });
  };

  const handleOpenModalTotalWeight = () => {
    setVisibleTotalWeight(true);
  };

  const handleOpenModalParcel = (value) => {
    if (value) setTypeModalParcel(value);
    if (!value) setTypeModalParcel();
    setVisibleParcel(true);
  };

  const handleOpenModalShipping = () => {
    setVisibleShipping(true);
  };

  const handleOpenModalPay = () => {
    setVisiblePay(true);
  };

  const handleSearchParcel = (value) => {
    if (value) {
      const includeData = _.filter(_.get(orderData, 'pacelDetails'), (item) => {
        return (
          _.includes(_.get(item, 'parcelNo'), value) ||
          _.includes(_.get(item, 'status.statusCode'), _.lowerCase(value)) ||
          _.includes(_.get(item, 'size.sizeName') || '', value)
        );
      });
      setEditData({ ...editData, ['pacelDetails']: includeData });
    } else {
      setEditData({ ...editData, ['pacelDetails']: _.get(orderData, 'pacelDetails') });
    }
  };

  const handleOpenModalTaskDetail = (value, type) => {
    setConfigTaskDetail({
      type: type,
      visible: true,
      id: value,
    });
  };

  const onEditNewModal = () => {
    handelCancelEdit()
    setEditFlag(false)
  }

  console.log('editData', editData);

  return (
    <DeliveryShipmentDetailContext.Provider
      value={{
        orderData,
        editFlag,
        handleOpenModalEditOrder,
        editData,
        handleOpenModalTotalWeight,
        handleOpenModalParcel,
        handleOpenModalShipping,
        handleSearchParcel,
        trackingData,
        handleCheckCustomerAddress,
        taskDetailData,
        handleOpenModalTaskDetail,
        logData,
      }}
    >
      <Row gutter={[16]}>
        <Col span={10}>
          <Card
            title={`${intl.formatMessage({ id: `deliveryOrderNo`, defaultMessage: 'Order No.' })} ${_.get(orderData, 'orderNo')}`}
            extra={
              <div style={{ textAlign: 'center', display: 'flex' }}>
                {editFlag ? (
                  <SelectStatus
                    dropdownStyle={{ minWidth: '200px' }}
                    size="small"
                    value={_.get(statusSelect, 'statusId')}
                    onChange={onChangeStatus}
                    disabled={editFlag ? false : true}
                    showArrow={editFlag ? true : false}
                  >
                    {statusNext.map((item) => (
                      <Select.Option value={item.statusId}>{item.statusName}</Select.Option>
                    ))}
                  </SelectStatus>
                ) : (
                  <Tag
                    className="tag-center-style"
                    style={{ textAlign: 'center', maxWidth: '110px', width: 'auto', minWidth: '80px' }}
                    color={_.get(statusSelect, 'statusColor')}
                  >
                    {_.get(statusSelect, 'statusName')}
                  </Tag>
                )}
              </div>
            }
            bodyStyle={{ padding: 'unset' }}
          >
            <Spin spinning={spinOrder}>
              <div style={{ height: 'calc(100vh - 245px)', overflowY: 'auto' }}>
                <DeliveryOrderDetailInformation form={form} />
              </div>
              <div style={{ borderTop: '1px solid #E5E5E5', padding: '24px' }}>
                {editFlag ? (
                  <Row gutter={[16]}>
                    <Col span={8} />
                    <Col span={8}>
                      <Button02 key="back" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={handelCancelEdit}>
                        <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                      </Button02>
                    </Col>
                    <Col span={8}>
                      <Button01 key="submit" type="primary" btnsize="wd_lg" style={{ margin: 'unset' }} onClick={() => handleSaveEdit()}>
                        <FormattedMessage id="btnSave" defaultMessage="Save" />
                      </Button01>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <DeliveryOrderButtons
                      status={_.get(orderData, 'statusDetail.statusCode')}
                      handleCancelShipmentDetail={handleCancelShipmentDetail}
                      handleSwtichForm={handleSwtichForm}
                      filterConfirmParcel={filterConfirmParcel}
                      editData={editData}
                      handleConfirm={handleConfirm}
                      handleOpenModalPay={handleOpenModalPay}
                    />
                  </>
                )}
              </div>
            </Spin>
          </Card>
        </Col>
        <Col span={14}>
          <Card bodyStyle={{ padding: 'unset' }}>
            <StickyContainer>
              <Spin spinning={spinOrder}>
                <Tabs className="delivery-tabs" style={{ marginTop: '6px' }} size="large" animated={false}>
                  <TabPane tab={intl.formatMessage({ id: `deliveryOrderTabsParcels`, defaultMessage: 'Parcels' })} key="parcels">
                    <DeliveryOrderParcel />
                  </TabPane>
                  <TabPane tab={intl.formatMessage({ id: `deliveryOrderTabsTracking`, defaultMessage: 'Tracking' })} key="tracking">
                    <DeliveryOrderTracking />
                  </TabPane>
                  <TabPane tab={intl.formatMessage({ id: `deliveryOrderTabsTaskDetail`, defaultMessage: 'Task Details' })} key="task">
                    <DeliveryOrderTaskDetail />
                  </TabPane>
                  <TabPane tab={intl.formatMessage({ id: `deliveryOrderTabsLogs`, defaultMessage: 'Action Logs' })} key="log">
                    <DeliveryOrderLogs />
                  </TabPane>
                </Tabs>
              </Spin>
            </StickyContainer>
          </Card>
        </Col>
      </Row>
      {/* <DeliveryEditOrderForm
        visible={visibleEditOrder}
        setVisible={setVisibleEditOrder}
        orderData={editData}
        setEditData={setEditData}
        statusAddress={statusAddress}
        setStatusAddress={setStatusAddress}
        handleSaveEdit={handleSaveEdit}
      /> */}

        <DeliveryCreateOrderForm
          visible={visibleEditOrder}
          setVisible={setVisibleEditOrder}
          setTrigger={setTriggerOrder}
          orderData={listDetailType}
          orderCheckType={'deliveryOrderParcel'}
          menuCode={'storeDetail'}
          editData={editData}
          setEditData={setEditData}
          anyFunction={onEditNewModal}
          branchDetail={selectBranchData}
        />

      <DeliveryWeightTotal
        visible={visibleTotalWeight}
        setVisible={setVisibleTotalWeight}
        weightDefault={_.get(editData, 'weight')}
        data={editData}
        setData={setEditData}
      />

      <DeliveryParcel
        visible={visibleParcel}
        setVisible={setVisibleParcel}
        orderData={orderData}
        sizeList={sizeList}
        typeModalParcel={typeModalParcel}
        setTypeModalParcel={setTypeModalParcel}
        orderId={_.get(selectShipmentData, 'orderId')}
        setTrigger={setTrigger}
      />

      <DeliveryShippingPrice
        visible={visibleShipping}
        setVisible={setVisibleShipping}
        editData={editData}
        setEditData={setEditData}
        orderData={orderData}
        reasonList={_.get(editData, 'deliveryOrderReasonMaster')}
      />

      <DeliveryPay visible={visiblePay} setVisible={setVisiblePay} orderData={orderData} setTrigger={setTrigger} />

      <ModalTaskDetail config={configTaskDetail} setConfig={setConfigTaskDetail} />
    </DeliveryShipmentDetailContext.Provider>
  );
};

const DeliveryShipmentDetail = Form.create({
  name: 'assignTeam-form',
  mapPropsToFields() {},
})(DeliveryShipmentDetailForm);

export default DeliveryShipmentDetail;
