import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';

const { Option } = Select;

const CreditNotePriceForm = (props) => {
  const { form, dataForm, setDataForm, selectItem, initial } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();

  useEffect(() => {

    if (_.size(selectItem)) {
      let totalOrigin = _.sumBy(selectItem, 'invTotal') || 0; //มูลค่าเดิม
      let difference = _.sumBy(selectItem, 'amount') || 0; // ผลต่าง
      let totalCorrect = totalOrigin - difference; //มูลค่าที่ถูกต้อง
      let vatNumber = difference * (_.get(dataForm, 'vat', 0) / 100)
      let total = difference + vatNumber

      console.log('selectItemFuncNumber', selectItem, '->', totalOrigin, difference, totalCorrect, vatNumber, total);


      setDataForm((prev) => ({
        ...prev,
        totalOrigin: totalOrigin,
        totalCorrect: totalCorrect,
        difference: difference,
        vatNumber: vatNumber,
        total: total,
      }));

      setFieldsValue({
        vatNumber: numberFormatter(vatNumber)
      })
    }

    
  }, [selectItem]);

  useEffect(() => {
    if (_.get(initial, 'optionsVat') && _.get(dataForm, 'vat')) {
      setFieldsValue({
        vat: _.get(dataForm, 'vat')
      })
    } else if (_.get(initial, 'optionsVat')){
      setFieldsValue({
        vat: 0 
      })
    }
  }, [_.get(initial, 'optionsVat'), _.get(dataForm, 'vat')])

  const onChangeVat = (value) => {

    const vatNumber = _.get(dataForm, 'difference', 0) * (value / 100)
    const total = _.get(dataForm, 'difference', 0) + vatNumber

    setDataForm((prev) => ({
      ...prev,
      vat: value,
      vatNumber: vatNumber,
      total: total
    }))

    setFieldsValue({
      vatNumber: numberFormatter(vatNumber)
    })
  };

  const onChangeRemark = (value) => {
    setDataForm((prev) => ({
      ...prev,
      remark: value
    }))
  }

  return (
    <Form form={form} colon={false} layout="vertival" className="creditnote-form">
      <Row gutter={[16]}>
        <Col span={9}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'creditnoteModalFormRemark', defaultMessage: 'Remark' })} req={false} />}
          >
            {getFieldDecorator('remark', {
              initialValue: _.get(dataForm, 'remark'),
            })(<Input.TextArea autoSize={{ minRows: 8, maxRows: 8 }} maxLength={500} onChange={(e) => onChangeRemark(e.target.value)}/>)}
          </Form.Item>
        </Col>
        <Col span={5}></Col>
        <Col span={10}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', marginTop: '35px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormTotalOriginalValue" defaultMessage="Total Original Value" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'totalOrigin', 0))} THB</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormTotalCorrectValue" defaultMessage="Total Correct Value" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'totalCorrect', 0))} THB</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormDifference" defaultMessage="Difference" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'difference', 0))} THB</div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            <div style={{ marginRight: '46px' }}>
              <FormattedMessage id="creditnoteModalFormVat" defaultMessage="Vat" />
            </div>
            <div style={{ display: 'flex' }}>
              <Row gutter={[16]}>
                <Col span={9}>
                  <Form.Item label="">
                    {getFieldDecorator('vat', {
                      // initialValue: _.get(initial, 'optionsVat.[1].taxPercent'),
                    })(
                      <Select
                        onChange={(value) => onChangeVat(value)}
                        value={_.get(dataForm, 'vat') || 0}
                      >
                        {_.map(initial.optionsVat, (item) => (
                          <Option value={item.taxPercent}>{item.taxPercentTxt}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <Form.Item label="">
                    {getFieldDecorator('vatNumber', {
                      initialValue: '0.00',
                    })(<Input suffix="THB" readOnly />)}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            <div>
              <FormattedMessage id="creditnoteModalFormTotal" defaultMessage="Total" />
            </div>
            <div>{numberFormatter(_.get(dataForm, 'total', 0))} THB</div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default CreditNotePriceForm;
