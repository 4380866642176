import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import _ from 'lodash';
import { messageLabel } from '../../../../../components/message-component';
import LabeRequireForm from '../../../../../components/label-required-form';
import { getSaleOrderById, getSaleOrderList } from '../../../../../controllers/sale-order/sale-order-api-new';
import ListItemFilter from './filter';
import { getCustomerOneFullDetail } from '../../../../../controllers/engagement';
import TableProjectDetail from '../../component/table';
import moment from 'moment';
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal';

const ModalProjectCreatePricingSelectSO = ({
  visible,
  onOk,
  onCancel,
  setPricingValue,
  projectDataForPricing,
}) => {
  const intl = useIntl();
  const [saleOrderList, setSaleOrderList] = useState([]);
  const [customerDetail, setCustomerDetail] = useState([]);
  const [trigger, setTrigger] = useState(false);

  const [soTotal, setSOTotal] = useState({
    total: 0,
  });
  const [searchSOValue, setSearchSOValue] = useState({
    startDate: '',
    endDate: '',
    issuedName: '',
    filter: {
      quotationNo: '',
      saleOrderNo: '',
    },
    projectCount: 0,
    page: 1,
    limit: 10,
    orderBy: undefined,
    sortBy: undefined,
  });

  useEffect(() => {
    const getSaleOrderApi = async () => {
      const payload = {
        tab: 'approved',
        startDate: _.get(searchSOValue, 'startDate'),
        endDate: _.get(searchSOValue, 'endDate'),
        issuedName: _.get(searchSOValue, 'issuedName'),
        customerId: [projectDataForPricing.customerId],
        filter: {
          quotationNo: _.get(searchSOValue, 'quotationNo'),
          saleOrderNo: _.get(searchSOValue, 'saleOrderNo'),
        },
        projectCount: 0,
        paging: _.get(searchSOValue, 'page'),
        rowsPerPages: _.get(searchSOValue, 'limit'),
        orderBy: _.get(searchSOValue, 'sortBy'),
        orderType: _.get(searchSOValue, 'orderBy'),
      };
      const response = await getSaleOrderList(payload);
      console.log('response', response);
      if (_.get(response, 'status.code') === 200) {
        setSaleOrderList(_.get(response, 'data.data'));
        setSOTotal({
          total: _.get(response, 'data.total'),
        });
      }
    };
    const getCustomerDetail = async () => {
      const response = await getCustomerOneFullDetail(projectDataForPricing.customerId);
      if (_.get(response, 'status.code') === 200) {
        setCustomerDetail(_.get(response, 'data'));
      }
    };

    if (visible === true) {
      getSaleOrderApi();
      getCustomerDetail();
    }
  }, [
    trigger,
    visible,
    _.get(searchSOValue, 'page'),
    _.get(searchSOValue, 'limit'),
    _.get(searchSOValue, 'orderBy'),
    _.get(searchSOValue, 'sortBy'),
  ]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('deliveryOrderViewOrderSaleOrderNo', 'Sale Order No.'),
      dataIndex: 'saleOrderNo',
      key: 'saleOrderNo',
      sorter: true,
      fixed: 'left',
      width: 180,
    },
    {
      title: messageLabel('saleOrderItemColumn', 'Item'),
      dataIndex: 'totalItem',
      key: 'totalItem',
      align: 'right',
      width: 150,
      sorter: true,
    },
    {
      title: messageLabel('lblTotal', 'Total'),
      dataIndex: 'total',
      key: 'total',
      width: 150,
      sorter: true,
      align: 'right',
      render: (text, record, index) => (
        <span>
          {numberFormatDecimal(_.get(record, 'total'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
        </span>
      ),
    },
    {
      title: messageLabel('reportSalesOrderColumnsIssuedby', 'Issued By'),
      dataIndex: 'issuedName',
      key: 'issuedName',
      width: 150,
      sorter: true,
      render: (text, record, index) => _.get(record, 'issuedName') || '-',
    },
    {
      title: messageLabel('invoiceLabelIssued Date', 'Issued Date'),
      dataIndex: 'issueDate',
      key: 'issueDate',
      width: 150,
      sorter: true,
      render: (text, record, index) => _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'
    },
    {
      title: messageLabel('quotationLabelQuotationNo', 'Quotation No.'),
      dataIndex: 'quotationNo',
      key: 'quotationNo',
      width: 150,
      sorter: true,
      render: (text, record, index) => _.get(record, 'quotationNo') || '-',
    },
  ];

  const onSelectSaleOrderRow = async (selectedRow) => {
    const responseSO = await getSaleOrderById(selectedRow.saleOrderId);
    setPricingValue(_.get(responseSO, 'data.data'));
    onCancel();
  };

  const handleClose = () => {
    onCancel();
  };

  const handleSearch = (code, value) => {
    setSearchSOValue((prev) => ({ ...prev, [code]: value }));
  };

  const handleSubmitSearch = () => {
    setTrigger((prev) => !prev);
    setSearchSOValue((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'invoiceLabelSelectSaleOrder', defaultMessage: 'Select Sales Order' })}
      // onOk={handleClose}
      visible={visible}
      onCancel={onCancel}
      width={1120}
      zIndex={1000}
      centered={true}
      // bodyStyle={{ height: 'calc(100vh - 150px)', overflowY: 'scroll' }}
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className="listItemTextHeader" style={{ padding: '0px 16px 16px 0px' }}>
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter searchSOValue={searchSOValue} onChange={handleSearch} handleSubmitSearch={handleSubmitSearch} />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader" style={{ paddingTop: '0px' }}>
            <FormattedMessage id="modalAddInvoiceLabelSaleOrderNo" defaultMessage="Sale Order" />
          </div>
          <div style={{ height: '625px', padding: '16px' }}>
            <Form layout="vertical" className="po-form">
              <Row gutter={24} style={{ marginTop: '5px', marginBottom: '10px' }}>
                <Col span={8}>
                  <Form.Item
                    label={<LabeRequireForm text={intl.formatMessage({ id: 'ProjectPricingCustomerCol', defaultMessage: 'Customer' })} />}
                    style={{ width: '300px' }}
                  >
                    <p>{customerDetail.customerName}</p>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={<LabeRequireForm text={intl.formatMessage({ id: 'ProjectPricingPhone', defaultMessage: 'Phone' })} />}>
                    <p>{customerDetail.phone}</p>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <LabeRequireForm text={intl.formatMessage({ id: 'ProjectPricingEmail', defaultMessage: 'Email' })} req={false} />
                    }
                  >
                    <p>{customerDetail.customerEmail ? customerDetail.customerEmail : '-'}</p>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <TableProjectDetail
              style={{}}
              columns={columns}
              listData={saleOrderList}
              listTotal={_.get(soTotal, 'total')}
              onRowClick={(row) => onSelectSaleOrderRow(row)}
              searchMemberValue={searchSOValue}
              setSearchMemberValue={setSearchSOValue}
              scroll={{x: _.sumBy(columns, 'width'), y: 400}}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

ModalProjectCreatePricingSelectSO.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalProjectPricingSelectSOForm = Form.create({ name: 'select_qt_Form' })(ModalProjectCreatePricingSelectSO);

export default ModalProjectPricingSelectSOForm;
