import { Card, Row, Col, Tag } from 'antd';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import _ from 'lodash';

const ItemTopGlobal = (prop) => {
  const intl = useIntl();
  const { dataBucket } = prop;

  const data = [
    {
      title: intl.formatMessage({ id: 'taecoTableTalaadthaiRevenue', defaultMessage: 'Revenue (THB)' }),
      value: _.get(dataBucket, 'data.revenue') ? _.get(dataBucket, 'data.revenue', '-') : '-',
    },
    {
      title: intl.formatMessage({ id: 'taecoTableTalaadthaiOccupancyRate', defaultMessage: 'Occupancy Rate' }),
      value: _.get(dataBucket, 'data.occupancyRate') ? _.get(dataBucket, 'data.occupancyRate', '-') : '-',
    },
    {
      title: intl.formatMessage({ id: 'taecoTableTalaadthaiArr', defaultMessage: 'ARR (THB)' }),
      value: _.get(dataBucket, 'data.arr') ? _.get(dataBucket, 'data.arr', '-') : '-',
    },
    {
      title: intl.formatMessage({ id: 'taecoTableTalaadthaiService', defaultMessage: 'Service (Unloading)' }),
      value: _.get(dataBucket, 'data.serviceUnloading.total') ? _.get(dataBucket, 'data.serviceUnloading.total', '-') : '-',
      tag: `${_.get(dataBucket, 'data.serviceUnloading.percen') ? _.get(dataBucket, 'data.serviceUnloading.percen', '-') : '-'} %`,
      // tagColor: dataBucket?.data.serviceUnloading.status,
      tagColor: 'gold',
    },
    {
      title: intl.formatMessage({ id: 'taecoTableTalaadthaiAccumulateVehicle', defaultMessage: 'Accumulate Vehicle' }),
      value: _.get(dataBucket, 'data.accumulateVehicle') ? _.get(dataBucket, 'data.accumulateVehicle', '-') : '-',
    },
    {
      title: intl.formatMessage({ id: 'taecoTableTalaadthaiActiveTicket', defaultMessage: 'Active Ticket' }),
      value: _.get(dataBucket, 'data.activeTicket') ? _.get(dataBucket, 'data.activeTicket', '-') : '-',
    },
  ];

  console.log('dataBucket', dataBucket, data);

  return (
    <Row gutter={[24, 2]}>
      {_.map(data, (item, index) => (
        <Col span={4}>
          <Card style={{ borderRadius: '8px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '12px' }}>
              <LabeRequireForm text={item.title} req={false} bold />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px', fontWeight: 'bold' }}>
              {item.value}
              {item.tag && (
                <Tag color={item.tagColor} style={{ fontSize: '14px', padding: '2px 6px', alignSelf: 'flex-end' }}>
                  {item.tag}
                </Tag>
              )}
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ItemTopGlobal;
