import _ from 'lodash';
import httpClient from '../../components/axiosClient';

const comCode = localStorage.getItem('comCode');
const langValue = localStorage.getItem('langValue');

const invStatusData = [
  { statusCode: 'waiting', statusTxt: 'Waiting' },
  { statusCode: 'darft', statusTxt: 'Darft' },
  { statusCode: 'billed', statusTxt: 'Billed' },
  { statusCode: 'canceled', statusTxt: 'Canceled' },
];

const billingStatusData = [
  { statusCode: 'waiting', statusTxt: 'Waiting' },
  { statusCode: 'darft', statusTxt: 'Darft' },
  { statusCode: 'billed', statusTxt: 'Billed' },
  { statusCode: 'canceled', statusTxt: 'Canceled' },
];

const getInvoiceReport = async ({ body }) => {
  console.log('getInvoiceReport 1', body);

  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/invoice-report`, body);
    console.log('getInvoiceReport 2', response);

    if (response.status === 200) {
      const dataGet = _.get(response, 'data.list');
      const dataTotal = _.get(response, 'data.total');
      const dataMap = _.map(dataGet, (o) => ({
        ...o,
        index: _.get(o, 'index'),
        invNo: _.get(o, 'invoiceNo'),
        customerCode: _.get(o, 'customer.customerCode'),
        customer: _.get(o, 'customer.customerName'),
        invStatus: {
          text: langValue === 'EN' ? _.get(o, 'invoiceStatus.EN') : _.get(o, 'invoiceStatus.TH'),
          color: _.get(o, 'invoiceStatus.color'),
        },
        soNo: _.get(o, 'soNo'),
        blNo: _.get(o, 'blNo'),
        blStatus: {
          text: langValue === 'EN' ? _.get(o, 'billingStatus.EN') : _.get(o, 'billingStatus.TH'),
          color: _.get(o, 'billingStatus.color'),
        },
        totalAmount: _.get(o, 'total') || 0,
        issuedBy: _.get(o, 'issuedBy.issueBy'),
        team: _.get(o, 'team.teamName'),
        issuedDate: _.get(o, 'issuedDate'),
        createdDate: _.get(o, 'createdDate'),
      }));
      return { list: dataMap, total: dataTotal };
    } else {
      return [];
    }
  } catch (error) {
    console.log('getInvoiceReport error', error);

    return [];
  }
};

const getInvoiceStatusData = async () => {
  try {
    const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/status/getdata`);
    if (response.status === 200) {
      const dataGet = _.get(response, 'data');
      console.log('dataGet', dataGet);
      const dataMap = _.map(dataGet, (o) => ({
        ...o,
        label: langValue === 'EN' ? _.get(o, 'detail.EN') : _.get(o, 'detail.TH'),
        value: _.get(o, '_id'),
      }));
      return dataMap;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getBillingStatusData = async () => {
  // try {
  //   const response = await httpClient.get(`${comCode}`);
  //   if (response.status === 200) {
  //     const dataGet = _.get(response, 'data');
  const dataMap = _.map(billingStatusData, (o) => ({
    ...o,
    value: o.statusCode,
    label: o.statusTxt,
  }));
  return dataMap;
  // } else {
  //   return [];
  // }
  // } catch (error) {
  //   return [];
  // }
};

const exportInvoiceReport = async ({ body }) => {
  try {
    const response = await httpClient.post(`/v1/report/manager/company/${comCode}/invoice-report-excel`, body, {
      responseType: 'arraybuffer',
    });

    if (response.status === 200) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export { getInvoiceReport, getInvoiceStatusData, getBillingStatusData, exportInvoiceReport };
