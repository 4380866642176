import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Icon, Dropdown, Select, Button, Menu, Checkbox } from 'antd';
import _ from 'lodash';
import Button_01 from '../../../../components/v2/button_01';

const { Option } = Select;

const DisplayBtn = (props) => {
  const { displayData, setcheckDisplayOcc, checkDisplay } = props;

  const [visible, setVisible] = useState(false);
  const [listArrayDisplay, setListArrayDisplay] = useState(displayData);
  const [tempCheck, setTempCheck] = useState({ ...checkDisplay });
  const [textErrorSelectDisplay, setTextErrorSelectDisplay] = useState('');

  console.log('POOOCOCO', checkDisplay);

  const handleClickButton = () => {
    if (visible) {
      setTempCheck({ ...checkDisplay });
      setListArrayDisplay(displayData.map((item) => ({ ...item, check: checkDisplay[item.key] || false })));
    }
    setVisible(!visible);
  };
  

  const handleOnVisiblecolumns = () => {
    const temparr = [...displayData];
    const tempCheck = Object.keys(checkDisplay);
    setListArrayDisplay(temparr);
    setcheckDisplayOcc(tempCheck.reduce((acc, key) => ({ ...acc, [key]: true }), {}));

    setTextErrorSelectDisplay('');
    setVisible(!visible);
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showDisplay = [...listArrayDisplay];
    let tempDisplay = [];

    console.log('chekch', checked, 'val:', val);

    tempDisplay = _.map(showDisplay, (item) => {
      if (val === item.key) {
        return {
          ...item,
          check: checked,
        };
      } else
        return {
          ...item,
        };
    });
    setTempCheck((prev) => ({ ...prev, [val]: checked }));
    setListArrayDisplay(tempDisplay);
  };
  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayDisplay.length <= 0) {
      setTextErrorSelectDisplay(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectDisplay('');
    }

    setcheckDisplayOcc(tempCheck);
    setVisible(false);
  };

  const handleReset = () => {
    handleOnVisiblecolumns();
    setTextErrorSelectDisplay('');
  };
  //_.size(listArrayDisplay) > 0 ? listArrayDisplay :
  const menuColumn = () => {
    return (
      <Menu className="menu-columns">
        <Menu.Item className="menu-columns-list-item" style={{ backgroundColor: '#ffffff' }}>
          {_.map(listArrayDisplay, (item, i) => (
            <div key={i} style={{ display: 'block', marginBottom: '10px' }}>
              <Checkbox value={item.key} onChange={checkedValuecolumns} checked={_.get(item, 'check') ? true : false}>
                <span style={{ fontSize: '13px' }}>{item.title}</span>
              </Checkbox>
            </div>
          ))}
        </Menu.Item>

        <Menu.Item style={{ color: '#FF1C00', textAlign: 'left', backgroundColor: '#ffffff' }}>{textErrorSelectDisplay}</Menu.Item>

        <Menu.Divider/>

        <Menu.Item style={{ backgroundColor: '#ffffff' }}>
          <Button.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleCheckOk} style={{ width: '50%', fontSize: '13px', border: 'none', boxShadow: 'none' }}>
              <FormattedMessage id="btnOK" defaultMessage="OK" />
            </Button>
            <Button onClick={handleReset} style={{ width: '50%', fontSize: '13px', border: 'none', boxShadow: 'none' }}>
              <FormattedMessage id="btnReset" defaultMessage="Reset" />
            </Button>
          </Button.Group>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown className="list-view-dropdown" trigger={['click']} placement="bottomRight" overlay={menuColumn} visible={visible}>
      <Button_01 type="primary" onClick={handleClickButton}>
        <FormattedMessage id="btnDisplay" defaultMessage="Display" /> <Icon type="down" />
      </Button_01>
    </Dropdown>
  );
};

export default DisplayBtn;
