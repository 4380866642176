import React, { useContext } from 'react';
import { Button, Card, Divider, Dropdown, Menu, Modal, Tabs } from 'antd';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router';
import { StickyContainer } from 'react-sticky';
import moment from 'moment';
import _ from 'lodash';
import './css/index.css';

import { successNotification, errorNotification } from '../../../components/v2/notification';
import { PageSettings } from '../../../config/page-settings';
import ProjectDetail from './detail';
import ProjectStakeholder from './stakeholder';
import ModalEditProject from '../../../components/project/modal-edit/index';
import ModalStakeHolder from '../../../components/project/modal-stakeholder/index';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import Attachment from './attachment';
import MemberProjectDetail from './member';
import ProjectDetailSetting from './setting';
import ModalProjectAddMemeberForm from '../../../components/project/modal-add-member';

import {
  getAvatarList,
  getProjectById,
  deleteStakeholder,
  deleteProject,
  getSearchStakeHolder,
} from '../../../controllers/project/project-api';
import { getMember } from '../../../controllers/project-new/member';
import { DownOutlined } from '@ant-design/icons';
import Button_01 from '../../../components/v2/button_01';
import ProjectPricingDetail from './pricing';
import ModalProjectCreatePricing from './pricing/component/modal-create';
import ModalProjectViewPricing from './pricing/component/modal-view';
import { exportPricingList, getPricingList } from '../../../controllers/project-new/project-detail';

const { TabPane } = Tabs;

const DetailPage = () => {
  const intl = useIntl();
  const { checkPermissionAction } = useContext(PageSettings);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [okAdd, setOkAdd] = useState(false);
  const [cancelAdd, setCancelAdd] = useState(false);
  const [isOpenCreatePricing, setIsOpenCreatePricing] = useState(false);
  const [isOpenViewPricing, setIsOpenViewPricing] = useState(false);
  const [visibleModalStakeHolder, setVisibleModalStakeHolder] = useState(false);
  const { project_id } = useParams();
  const history = useHistory();

  const [listData, setListData] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [avatarList, setAvatarList] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [projectDataForPricing, setProjectDataForPricing] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [stakedata, setStakeData] = useState();
  const [totalstake, setTotalStake] = useState();
  const [triggerStake, setTriggerStake] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [error, setError] = useState(null);
  const [pricingValue, setPricingValue] = useState();
  const [pricingList, setPricingList] = useState();
  const [pricingListFull, setPricingListFull] = useState();
  const [selectedRecord, setSelectedRecord] = useState('');

  const [tabKey, setTabKey] = useState('projectDetails');
  const [visibleAtt, setVisibleAtt] = useState(false);

  const [searchMemberValue, setSearchMemberValue] = useState({
    name: '',
    memberType: '',
    emOrTeamType: '',
    phone: '',
    email: '',
    priority: '',
    page: 1,
    limit: 10,
    sortBy: undefined,
    orderBy: undefined,
  });

  const [pricingListTotal, setPricingListTotal] = useState({
    total: 0,
  });
  const [searchPricingValue, setSearchPricingValue] = useState({
    pricingList: [],
    pricingTotal: 0,
    saleOrderNo: '',
    customerName: '',
    contactName: '',
    contactPhone: '',
    updatedByName: '',
    updatedDate: [],

    page: 1,
    limit: 10,
    orderBy: undefined,
    sortBy: undefined,
  });

  useEffect(() => {
    const getAvatarApi = async () => {
      const response = await getAvatarList();
      setAvatarList(response || []);
    };

    getAvatarApi();
  }, []);

  useEffect(() => {
    const getApi = async () => {
      const payload = {
        refToNo: _.get(searchPricingValue, 'saleOrderNo'),
        refToCustomerName: _.get(searchPricingValue, 'customerName'),
        refToContactName: _.get(searchPricingValue, 'contactName'),
        refToContactPhone: _.get(searchPricingValue, 'contactPhone'),
        updatedByName: _.get(searchPricingValue, 'updatedByName'),
        updatedDateFrom: _.get(searchPricingValue, 'updatedDate[0]')
          ? moment(_.get(searchPricingValue, 'updatedDate[0]')).format('YYYY-MM-DD')
          : '',
        updatedDateTo: _.get(searchPricingValue, 'updatedDate[1]')
          ? moment(_.get(searchPricingValue, 'updatedDate[1]')).format('YYYY-MM-DD')
          : '',
        referenceType: 'project',
        referenceId: project_id,

        pageNumber: _.get(searchPricingValue, 'page'),
        limit: _.get(searchPricingValue, 'limit'),
        orderBy: _.get(searchPricingValue, 'sortBy'),
        orderType: _.get(searchPricingValue, 'orderBy'),
      };
      const response = await getPricingList(payload);
      console.log('response', response);
      if (_.get(response, 'status.code') === 200) {
        setPricingList(_.get(response, 'data.listReference'));
        setPricingListFull(_.get(response, 'data'));
        setPricingListTotal(_.get(response, 'data.listReference.length'));
      }
      setPricingListTotal({
        total: _.get(response, 'data.totalItem'),
      });
    };
    getApi();
  }, [
    pricingValue,
    trigger,
    _.get(searchPricingValue, 'page'),
    _.get(searchPricingValue, 'limit'),
    _.get(searchPricingValue, 'orderBy'),
    _.get(searchPricingValue, 'sortBy'),
  ]);

  console.log(pricingListFull, 'fulful');

  const handleExport = async () => {
    const payload = {
      refToNo: pricingList.referenceNo,
      refToCustomerName: pricingList.businessPartnerName,
      refToContactName: pricingList.contactName,
      refToContactPhone: pricingList.contactPhone,
      updatedByName: pricingList.updatedByName,
      updatedDate: pricingList.updatedByDate,
      referenceType: 'project',
      referenceId: projectDataForPricing.projectId,
      pageNumber: 1,
      limit: pricingListFull.totalItem,
      orderBy: 'referenceNo',
      orderType: 'asc',
      viewFormat: [
        {
          colName: intl.formatMessage({ id: 'ProjectPricingSoNoCol', defaultMessage: 'SO No.' }),
          sequence: 1,
          colCode: 'referenceNo',
        },
        {
          colName: intl.formatMessage({ id: 'ProjectPricingCustomerCol', defaultMessage: 'Customer' }),
          sequence: 2,
          colCode: 'businessPartnerName',
        },
        {
          colName: intl.formatMessage({ id: 'ProjectPricingContactCol', defaultMessage: 'Contact' }),
          sequence: 3,
          colCode: 'contactName',
        },
        {
          colName: intl.formatMessage({ id: 'ProjectPricingContactPhoneCol', defaultMessage: 'Contact Phone' }),
          sequence: 4,
          colCode: 'contactPhone',
        },
        {
          colName: intl.formatMessage({ id: 'ProjectPricingUpdateByCol', defaultMessage: 'Update By' }),
          sequence: 5,
          colCode: 'createdByName',
        },
        {
          colName: intl.formatMessage({ id: 'ProjectPricingUpdateDateCol', defaultMessage: 'Update Date' }),
          sequence: 6,
          colCode: 'createdDate',
        },
      ],
    };

    const response = await exportPricingList(payload);
    if (response.status.code === 200) {
      window.open(response.data.url);
      successNotification(response.status.message);
    } else {
      errorNotification(response.status.message);
    }
  };

  useEffect(() => {
    const getById = async () => {
      const response = await getProjectById(project_id);
      const item = _.get(response, 'data');
      setProjectData({
        id: item.projectId,
        index: item.index,
        projectNo: item.projectNo,
        projectName: item.projectName,
        projectType: item.projectTypeName,
        priority: item.projectPriorityName,
        customer: item.customerName,
        projectManager: item.projectMangerName,
        planDate: item.planDate,
        reference: item.referenceNo,
        remark: item.remark,
        projectImgProfile: item.projectImgProfile,
        projectTypeId: item.projectTypeId,
        projectTypeName: item.projectTypeName,
        projectPriorityId: item.projectPriorityId,
        planStart: moment(item.planStart, 'YYYY-MM-DD'),
        planFinish: moment(item.planFinish, 'YYYY-MM-DD'),
        customerId: item.customerId,
        projectMangerId: parseInt(item.projectMangerId),
        projectPriorityIcon: item.projectPriorityIcon,
        fileHash: item.projectImgProfileFileHash,
      });
      setProjectDataForPricing(item);
    };
    getById();
  }, [project_id, trigger]);

  useEffect(() => {
    const getSkate = async () => {
      const body = {
        search: '',
        searchBy: 'all',
        orderType: 'desc',
        orderBy: '',
        paging: paginationPage,
        rowsPerPages: paginationSize,
      };
      const skate = await getSearchStakeHolder(body, project_id);
      setStakeData(skate.data.data);
      setTotalStake(skate.data.total);
    };
    getSkate();
  }, [project_id, triggerStake, paginationPage, paginationSize]);

  const getMemberData = async () => {
    const body = {
      name: _.get(searchMemberValue, 'name'),
      member_type: _.get(searchMemberValue, 'memberType'),
      type: _.get(searchMemberValue, 'emOrTeamType'),
      phone: _.get(searchMemberValue, 'phone'),
      email: _.get(searchMemberValue, 'email'),
      priority: _.get(searchMemberValue, 'priority'),
      page: _.get(searchMemberValue, 'page'),
      pageSize: _.get(searchMemberValue, 'limit'),
      orderBy: _.get(searchMemberValue, 'sortBy'),
      order: _.get(searchMemberValue, 'orderBy'),
    };
    try {
      const listData = await getMember({ body, projectId: project_id });
      setListData(listData.data);
      setListTotal(listData.data.length);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getMemberData();
  }, [
    trigger,
    _.get(searchMemberValue, 'page'),
    _.get(searchMemberValue, 'limit'),
    _.get(searchMemberValue, 'orderBy'),
    _.get(searchMemberValue, 'sortBy'),
  ]);

  const handleSearchMember = (code, value) => {
    setSearchMemberValue((prev) => ({ ...prev, [code]: value }));
  };

  const handleSubmitSearch = () => {
    setTrigger((prev) => !prev);
    setSearchMemberValue((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  const handleResetSearch = () => {
    setTrigger((prev) => !prev);
    setSearchMemberValue((prev) => ({
      ...prev,
      name: '',
      memberType: '',
      emOrTeamType: '',
      phone: '',
      email: '',
      priority: '',
      page: 1,
      limit: 10,
      sortBy: undefined,
      orderBy: undefined,
    }));
  };

  const handleEditProject = () => {
    setVisibleEdit(true);
  };

  const handleVisibleAddProjectMember = () => {
    setVisibleAdd(true);
  };

  const handleCancelAddProjectMember = (value) => {
    setCancelAdd(true);
    setVisibleAdd(false);
  };

  const handleOkAddProjectMember = (value) => {
    setOkAdd(true);
    setTrigger((prev) => !prev);
    setVisibleAdd(false);
  };

  const handleDeleteProject = (data) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmDeleteProject`,
        defaultMessage: 'Are you sure to delete project',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteProject(data.id);
        if (response.status === 200) {
          setTrigger((current) => !current);
          successNotification(response.data.massage);
          historyHome();
        } else {
          errorNotification(response.data.massage);
        }
      },
      onCancel() {},
    });
  };

  const handleDeleteSkateholder = (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `monitorCreateTaskModalConfirmDeleteSkate`,
        defaultMessage: 'Are you sure to delete stakeholder',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteStakeholder(id);
        if (response.status === 200) {
          setTriggerStake((current) => !current);
          successNotification(response.data.massage);
        } else {
          errorNotification(response.data.massage);
        }
      },
      onCancel() {},
    });
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };
  const historyHome = () => {
    history.push('/main/project/list');
  };

  const handleOpenCreatePricing = () => {
    setIsOpenCreatePricing(true);
  };
  const handleCancelCreatePricing = () => {
    setIsOpenCreatePricing(false);
    setPricingValue();
    setSelectedRecord()
  };

  const handleOpenViewPricing = (record) => {
    setSelectedRecord({...record, action: 'view'});
    setIsOpenViewPricing(true);
  };
  const handleCancelViewPricing = () => {
    setIsOpenViewPricing(false);
    setPricingValue();
    setSelectedRecord();
  };

  const handleSearchPricing = (code, value) => {
    setSearchPricingValue((prev) => ({ ...prev, [code]: value }));
  };

  const handleSearchPricinSO = (code, value) => {
    setTrigger((prev) => !prev);
    setSearchPricingValue((prev) => ({ ...prev, [code]: value }));
  };

  const handleSubmitSearchPricing = () => {
    setTrigger((prev) => !prev);
    setSearchPricingValue((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  const handleResetSearchPricing = () => {
    setTrigger((prev) => !prev);
    setSearchPricingValue((prev) => ({
      ...prev,
      saleOrderNo: '',
      customerName: '',
      contactName: '',
      contactPhone: '',
      updatedByName: '',
      updatedDate: [],
    }));
  };

  const handleEditPricing = (data) => {
    setIsOpenViewPricing(false)
    setIsOpenCreatePricing(true)
    setSelectedRecord({...selectedRecord, action: 'edit'})
  }

  const handleCopyPricing = (data) => {
    setIsOpenViewPricing(false)
    setIsOpenCreatePricing(true)
    setSelectedRecord({...data, action: 'copy'})
  }

  console.log('dataDetal', selectedRecord)

  ///////////////////////////////////////// newmenu

  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        {checkPermissionAction('P39PG3C1', 'P39PG3C1A2') ? (
          <Menu.Item key="1">
            <Button key="1" ghost type="link" onClick={() => handleOpenCreatePricing()}>
              <FormattedMessage id="ProjectPricingCreate" defaultMessage="Create Pricing" />
            </Button>
          </Menu.Item>
        ) : null}
        {checkPermissionAction('P39PG3C1', 'P39PG3C1A5') ? (
          <Menu.Item key="2">
            <Button key="2" ghost type="link" onClick={() => handleExport()}>
              <FormattedMessage id="btnExportExcel" defaultMessage="Export Excel" />
            </Button>
          </Menu.Item>
        ) : null}
      </Menu>
      // )}
      // </PageSettings.Consumer>
    );
  };

  const callback = (key) => {
    setTabKey(key);
  };

  const tabBarExtraContent = () => {
    return (
      <div style={{ marginRight: '23px' }}>
        <PageSettings.Consumer>
          {({ checkPermissionAction }) => {
            if (tabKey === 'projectDetails') {
              return (
                <div className="action-button-group-project">
                  {checkPermissionAction('P39PG2C1', 'P39PG2C1A3') ? (
                    <div>
                      <Button02
                        style={{ margin: '0px 0px 0px 10px' }}
                        className="action-button-group-delete"
                        btnsize="wd_df"
                        onClick={() => handleDeleteProject(projectData)}
                      >
                        <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                      </Button02>
                    </div>
                  ) : null}
                  {checkPermissionAction('P39PG2C1', 'P39PG2C1A2') ? (
                    <div>
                      <Button01
                        className="action-button-group-delete"
                        btnsize="wd_df"
                        fontsize="sm"
                        type="primary"
                        onClick={handleEditProject}
                      >
                        <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                      </Button01>
                    </div>
                  ) : null}
                </div>
              );
            } else if (tabKey === 'member') {
              return (
                <div className="action-button-group-project">
                  {checkPermissionAction('P39PG2C1', 'P39PG2C1A2') ? (
                    <div>
                      <Button01
                        className="action-button-group-delete"
                        btnsize="wd_df"
                        fontsize="sm"
                        type="primary"
                        onClick={handleVisibleAddProjectMember}
                      >
                        <FormattedMessage id="btnAdd" defaultMessage="Add" />
                      </Button01>
                    </div>
                  ) : null}
                </div>
              );
            } else if (tabKey === 'pricing') {
              return (
                <div className="action-button-group-project">
                  {checkPermissionAction('P39PG2C1', 'P39PG2C1A2') ? (
                    <div>
                      <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight" className="ant-dropdown-custom">
                        <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                          <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                          <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                        </Button_01>
                      </Dropdown>
                    </div>
                  ) : null}
                </div>
              );
            } else {
              return null;
            }
          }}
        </PageSettings.Consumer>
      </div>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Card bodyStyle={{ margin: '0px', padding: '0px' }}>
            <StickyContainer>
              <Tabs
                className="monitor-main-tabs customer-tab-margin"
                size="large"
                defaultActiveKey="projectDetails"
                onChange={callback}
                animated={false}
                tabBarExtraContent={
                  tabBarExtraContent()
                  // <div>
                  //   <Dropdown
                  //     overlay={menuColumn}
                  //     trigger={['click']}
                  //     onVisibleChange={setVisible}
                  //     visible={visible}
                  //     placement="bottomRight"
                  //   >
                  //     <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                  //       <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                  //       <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                  //     </Button_01>
                  //   </Dropdown>
                  // </div>
                }
              >
                <TabPane
                  tab={intl.formatMessage({ id: `projectProfileLabelrojectDetails`, defaultMessage: 'Project Details' })}
                  key="projectDetails"
                >
                  <div className="padding-between-card" style={{ margin: '24px 0px' }}>
                    {checkPermissionAction('P39PG2C1', 'P39PG2C1A1') ? <ProjectDetail record={projectData} /> : null}
                  </div>

                  <div className="item-title">
                    <span className="item-title-text">
                      <FormattedMessage id="stakeholderLabelStakeholder" defaultMessage="Stakeholder" />
                    </span>
                    <div>
                      {checkPermissionAction('P39PG2C1', 'P39PG2C1A1') ? (
                        <Button01 fontsize="sm" type="primary" onClick={() => setVisibleModalStakeHolder(true)}>
                          <FormattedMessage id="btnAdd" defaultMessage="Add" />
                        </Button01>
                      ) : null}
                    </div>
                  </div>
                  <Divider type="horizontal" style={{ margin: '12px 0px' }} />

                  <div style={{ margin: '24px' }}>
                    {checkPermissionAction('P39PG2C2', 'P39PG2C2A1') ? (
                      <ProjectStakeholder
                        setVisible={setVisibleModalStakeHolder}
                        stakedata={stakedata}
                        totalstake={totalstake}
                        paginationPage={paginationPage}
                        handleDeleteSkateholder={handleDeleteSkateholder}
                        handlePagination={handlePagination}
                        handleSizeChange={handleSizeChange}
                      />
                    ) : null}
                  </div>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '16px 24px 0px' }}>
                    <span className="item-title-text">
                      <FormattedMessage id="attachmentLabel" defaultMessage="Attachment" />
                    </span>
                    <div>
                      <Button01 fontsize="sm" type="primary" onClick={() => setVisibleAtt(true)}>
                        <FormattedMessage id="btnUpload" defaultMessage="Upload" />
                      </Button01>
                    </div>
                  </div>
                  <Divider type="horizontal" style={{ margin: '12px 0px' }} />

                  <div style={{ margin: '24px' }}>
                    <Attachment visible={visibleAtt} setVisible={setVisibleAtt} id={project_id} />
                  </div>
                </TabPane>

                <TabPane tab={intl.formatMessage({ id: `projectProfileLabelrojectMember`, defaultMessage: 'Member' })} key="member">
                  <MemberProjectDetail
                    id={project_id}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    listData={listData}
                    listTotal={listTotal}
                    searchMemberValue={searchMemberValue}
                    setSearchMemberValue={setSearchMemberValue}
                    handleSearchMember={handleSearchMember}
                    handleSubmitSearch={handleSubmitSearch}
                    handleResetSearch={handleResetSearch}
                  />
                </TabPane>

                <TabPane tab={intl.formatMessage({ id: `projectProfileLabelrojectPricing`, defaultMessage: 'Pricing' })} key="pricing">
                  {checkPermissionAction('P39PG3C1', 'P39PG3C1A1') ? (
                    <ProjectPricingDetail
                      pricingList={pricingList}
                      handleOpenViewPricing={handleOpenViewPricing}
                      searchPricingValue={searchPricingValue}
                      setSearchPricingValue={setSearchPricingValue}
                      handleSearchPricing={handleSearchPricing}
                      handleSearchPricinSO={handleSearchPricinSO}
                      handleSubmitSearchPricing={handleSubmitSearchPricing}
                      handleResetSearchPricing={handleResetSearchPricing}
                      pricingListTotal={pricingListTotal}
                      handleCopyPricing={handleCopyPricing}
                    />
                  ) : null}
                </TabPane>

                <TabPane tab={intl.formatMessage({ id: `projectProfileLabelrojectSetting`, defaultMessage: 'Setting' })} key="setting">
                  <ProjectDetailSetting id={project_id} />
                </TabPane>
              </Tabs>
            </StickyContainer>
          </Card>

          <ModalEditProject
            visible={visibleEdit}
            setVisible={setVisibleEdit}
            avatarList={avatarList}
            projectData={projectData}
            setTrigger={setTrigger}
            disabledCustomer={_.size(pricingList)}
          />

          <ModalStakeHolder
            visible={visibleModalStakeHolder}
            setVisible={setVisibleModalStakeHolder}
            id={project_id}
            setTriggerStake={setTriggerStake}
            stakedata={stakedata}
          />

          <ModalProjectAddMemeberForm
            visible={visibleAdd}
            setVisible={setVisibleAdd}
            onOk={handleOkAddProjectMember}
            onCancel={handleCancelAddProjectMember}
            setTrigger={setTrigger}
            project_id={project_id}
          />

          <ModalProjectCreatePricing
            visible={isOpenCreatePricing}
            setVisible={setIsOpenCreatePricing}
            onOk={handleOpenCreatePricing}
            onCancel={handleCancelCreatePricing}
            projectDataForPricing={projectDataForPricing}
            pricingValue={pricingValue}
            setPricingValue={setPricingValue}
            setTrigger={setTrigger}
            selectedRecord={selectedRecord}
          />
          <ModalProjectViewPricing
            visible={isOpenViewPricing}
            setVisible={setIsOpenViewPricing}
            onCancel={handleCancelViewPricing}
            projectDataForPricing={projectDataForPricing}
            pricingValue={pricingValue}
            setPricingValue={setPricingValue}
            selectedRecord={selectedRecord}
            setTrigger={setTrigger}
            handleEditPricing={handleEditPricing}
            handleCopyPricing={handleCopyPricing}
          />
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default DetailPage;
