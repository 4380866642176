import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Menu, Modal, Tabs, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import '../css/index.css';
import { StickyContainer } from 'react-sticky';
import DetailsTab from './details-tab';
import PaymentTab from './payment-tab';
import UploadFileModal from '../upload-file/upload-modal';
import { InvoiceContext } from '../../context';
import _ from 'lodash';
import ModalCreateListItem from '../list-item/add';
import ModalEditListItem from '../list-item/edit';
import ListItem from '../list-item/list/index';
import moment from 'moment';
import ModalSelectCustomerForm from '../select-customer/customer-modal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import DetailsTabView from './view/details-tab-view';
import PaymentTabView from './view/payment-tab-view';
import ModalApproveForm from '../approve/approve-modal';
import ModalCancelForm from '../cancel/cancel-modal';
import ModalRejectForm from '../reject/reject-modal';
import ModalWaitingForm from '../waiting/waiting-modal';
import ModalDraftForm from '../delete/delete-modal';
import ModalSelectQuotationForm from '../select-saleorder/saleorder-select-modal';
import SaleOrderPrint from '../../../../components/modelprint/slaeorderprint';
import { useReactToPrint } from 'react-to-print';
import ListItemServiceInvoice from '../list-item/list-service';
import ModalEditListServiceItemSO from '../list-item/edit-service';
import ModalCreateListServiceItemSO from '../list-item/add-service';
import { getItemCheckStock } from '../../../../controllers/quotation/quotation-api-new';
import { v4 as uuidv4 } from 'uuid';
import ModelAddItemAR from '../../../account-receivable/modal/quotation/additem';
import ARPrint from '../../../../components/modelprint/ARPrint';
import { uploadFileInvoice, InvoicePDFDownloader } from '../../../../controllers/invoice-new/invoice-api';
import ModelAddItemIN from './additem';
import ModalPDFViewver from '../../../../component-function/pdf-viewver';
import { ThaiNumberToText, UrlPDF } from '../../../../component-function/generator-pdf';
import ModalEditListServiceItemInvoice from '../list-item/edit-service';
import ModalCreateListServiceItemInvoice from '../list-item/add-service';
import { SelectCustomerHook } from '../select-customer/selectHook';

const { TabPane } = Tabs;

const SaleOrderModal = ({ form, title, visible, onOk, onCancel, attData, setAttData, refreshModalSaleOrder }) => {
  const { state, setState, func } = useContext(InvoiceContext);
  const {
    initialMemberList,
    initialTaxList,
    initialCampaignList,
    initialReasonList,
    record,
    visibleApprove,
    visibleReject,
    visibleCancel,
    visibleDelete,
    visibleWaiting,
    isOpenQuotation,
    recordReport,
    sizeRecordReport,
    signatureUser,
    selectCustomer,
    signatureIssu,
    isFetch,
    vehicleList,
    pageTable,
    sizeTable,
    warehouseList,
    saleOrderQTY,
    recordList,
    totalWarehouseList,
    visibleAddINV,
    reportDataPending,
    defaultSaleOrder,
  } = state;
  const {
    setSelectCustomer,
    setCustomerTrigger,
    setPageTable,
    setSizeTable,
    setWarehouseList,
    setSaleOrderQTY,
    setRecordList,
    setTotalWarehouseList,
    setVisibleAddINV,
    setRecordReport,
  } = setState;
  const {
    handleOpenModalUpload,
    handleOpenApprove,
    handleSaveApprove,
    handleCancelApprove,
    handleOpenReject,
    handleSaveReject,
    handleCancelReject,
    handleOpenCancel,
    handleSaveCancel,
    handleCancelCancelQT,
    handleOpenDelete,
    handleSaveDelete,
    handleCancelDelete,
    handleSaveWaiting,
    handleCancelWaiting,
    handleOpenWaiting,
    handleDeleteAttAchment,
    handleCleckSaleOrder,
    handleCancelAddItem,
    handleOpenModalQuotationPending,
  } = func;
  const [dataForm, setDataForm] = useState();
  const [tabkeys, setTabkeys] = useState('detail');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [checkQty, setCheckeQty] = useState(false);
  const [createData, setCreateData] = useState();
  const [editData, setEditData] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQt, setIsOpenQt] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const intl = useIntl();
  const { setFieldsValue, resetFields } = form;
  const formCustomerRef = useRef();
  const invoicePrntRef = useRef();
  const [searchGroup, setSearchGroup] = useState({ discount: 0, discountNumber: 0.0 });
  //--------------------------------------------------------------------------
  const [visibleListService, setVisibleListService] = useState(false);
  const [visibleAddService, setVisibleAddService] = useState(false);
  const [visibleEditService, setVisibleEditService] = useState(false);
  const [creatService, setCreatService] = useState();
  const [editService, setEditService] = useState();
  const [loading, setLoading] = useState(false);
  const [visiblePrint, setVisiblePrint] = useState(false);
  const [urlPrint, setUrlPrint] = useState();

  //--------------------------------------------------------

  const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer, searchCustomer } = SelectCustomerHook(); 

  console.log('selectCustomerHook 1', customerList)

  console.log('quotationObj', dataForm, _.get(dataForm, 'quotationObj'), '/', _.max(paymentItem, 'dueDate'));
  console.log('recordModal', record, '/attdata: ', attData);

  useEffect(() => {
    console.log('formData', record, dataForm);
    if (record) {
      if (_.get(record, 'code') !== 'edit' && _.get(record, 'code') !== 'view') {
        let updateData = [];
        console.log('ViewData1');
        setDataForm((item) => ({
          ..._.get(record, 'saleOrderDetail'),
          saleOrderDateTxt: _.get(record, 'saleOrderDetail.issueDate')
            ? moment(_.get(record, 'saleOrderDetail.issueDate')).format('DD/MM/YYYY')
            : _.get(item, 'saleOrderObj.issueDate'),
          saleOrderIssueBy: _.get(record, 'saleOrderDetail.issuedName')
            ? _.get(record, 'saleOrderDetail.issuedName')
            : _.get(item, 'saleOrderObj.issueBy'),
          address: _.get(item, 'address'),
          address2: _.get(item, 'address2'),
          addressName: _.get(item, 'addressName'),
          contactName: _.get(item, 'contactName'),
          customer: _.get(item, 'customer'),
          customerAddress: _.get(item, 'customerAddress'),
          customerAddressBookId: _.get(item, 'customerAddressBookId'),
          customerContact: _.get(item, 'customerContact'),
          customerContactEmail: _.get(item, 'customerContactEmail'),
          customerContactId: _.get(item, 'customerContactId'),
          customerContactName: _.get(item, 'customerContactName'),
          customerContactPhone: _.get(item, 'customerContactPhone'),
          customerId: _.get(item, 'customerId'),
          customerName: _.get(item, 'customerName'),
          customerTaxNo: _.get(item, 'customerTaxNo'),
          fullAddress: _.get(item, 'fullAddress'),
          code: _.get(record, 'code'),
        }));
      } else if (_.get(record, 'code') !== 'view') {
        console.log('ViewData2');
        setDataForm((item) => ({
          ...record,
          saleOrderDateTxt: _.get(record, 'saleOrderDetail.issueDate')
            ? moment(_.get(record, 'saleOrderDetail.issueDate')).format('DD/MM/YYYY')
            : _.get(record, 'saleOrderDateTxt'),
          saleOrderIssueBy: _.get(record, 'saleOrderDetail.issuedName')
            ? _.get(record, 'saleOrderDetail.issuedName')
            : _.get(record, 'saleOrderIssueBy'),
          address: _.get(item, 'address'),
          address2: _.get(item, 'address2'),
          addressName: _.get(item, 'addressName'),
          contactName: _.get(item, 'contactName'),
          customer: _.get(item, 'customer'),
          customerAddress: _.get(item, 'customerAddress'),
          customerAddressBookId: _.get(item, 'customerAddressBookId')
            ? _.get(item, 'customerAddressBookId')
            : _.get(record, 'customerAddressBookId'),
          customerContact: _.get(item, 'customerContact'),
          customerContactEmail: _.get(item, 'customerContactEmail') ? _.get(item, 'customerContactName') : _.get(record, 'customerEmail'),
          customerContactId: _.get(item, 'customerContactId') ? _.get(item, 'customerContactName') : _.get(record, 'customerContact'),
          customerContactName: _.get(item, 'customerContactName') ? _.get(item, 'customerContactName') : _.get(record, 'customerContact'),
          customerContactPhone: _.get(item, 'customerContactPhone') ? _.get(item, 'customerContactPhone') : _.get(record, 'customerPhone'),
          customerId: _.get(item, 'customerId') ? _.get(item, 'customerId') : _.get(record, 'customerId'),
          customerName: _.get(item, 'customerName') ? _.get(item, 'customerName') : _.get(record, 'customerName'),
          customerTaxNo: _.get(item, 'customerTaxNo') ? _.get(item, 'customerTaxNo') : _.get(record, 'customerTaxNo'),
          fullAddress: _.get(item, 'fullAddress') ? _.get(item, 'fullAddress') : _.get(record, 'customerAddress'),
          taxPercent: _.get(record, 'taxPercentage'),
        }));
      } else {
        console.log('ViewData3');
        setDataForm((item) => ({
          ...record,
          saleOrderDateTxt: _.get(record, 'saleOrderDetail.issueDate')
            ? moment(_.get(record, 'saleOrderDetail.issueDate')).format('DD/MM/YYYY')
            : _.get(record, 'saleOrderDateTxt'),
          saleOrderIssueBy: _.get(record, 'saleOrderDetail.issuedName')
            ? _.get(record, 'saleOrderDetail.issuedName')
            : _.get(record, 'saleOrderIssueBy'),
          address: _.get(item, 'address'),
          address2: _.get(item, 'address2'),
          addressName: _.get(item, 'addressName'),
          contactName: _.get(item, 'contactName'),
          customer: _.get(item, 'customer'),
          customerAddress: _.get(item, 'customerAddress'),
          customerAddressBookId: _.get(item, 'customerAddressBookId')
            ? _.get(item, 'customerAddressBookId')
            : _.get(record, 'customerAddressBookId'),
          customerContact: _.get(item, 'customerContact'),
          customerContactEmail: _.get(item, 'customerContactEmail') ? _.get(item, 'customerContactName') : _.get(record, 'customerEmail'),
          customerContactId: _.get(item, 'customerContactId') ? _.get(item, 'customerContactName') : _.get(record, 'customerContact'),
          customerContactName: _.get(item, 'customerContactName') ? _.get(item, 'customerContactName') : _.get(record, 'customerContact'),
          customerContactPhone: _.get(item, 'customerContactPhone') ? _.get(item, 'customerContactPhone') : _.get(record, 'customerPhone'),
          customerId: _.get(item, 'customerId') ? _.get(item, 'customerId') : _.get(record, 'customerId'),
          customerName: _.get(item, 'customerName') ? _.get(item, 'customerName') : _.get(record, 'customerName'),
          customerTaxNo: _.get(item, 'customerTaxNo') ? _.get(item, 'customerTaxNo') : _.get(record, 'customerTaxNo'),
          fullAddress: _.get(item, 'fullAddress') ? _.get(item, 'fullAddress') : _.get(record, 'customerAddress'),
          taxPercent: _.get(record, 'taxPercentage'),
        }));
      }

      const itemList = _.map(_.get(record, 'itemList'), (o, i) => ({
        ...o,
        totaQty: _.get(o, 'qtyNum'),
        subtotal: _.get(o, 'totalNum'),
        index: i + 1,
      }));

      setSelectItem(itemList);

      // const setobj = _.map(_.get(record, 'attanchment'), (o, i) => ({
      //   ...o,
      //   index: i + 1,
      //   attachmentName: _.get(o, 'fileName'),
      //   remark: _.get(o, 'remark'),
      //   fileHash: _.get(o, 'fileHash'),
      // }));
      // setAttData(setobj);
    }
  }, [record]);

  useEffect(() => {
    if (selectItem) {
      const checkIndex = _.findIndex(selectItem, (o) => o.isOutOfStock === true);
      const isOutOfStock = checkIndex !== -1 ? true : false;
      setCheckeQty(isOutOfStock);
    }
  }, [selectItem, visibleAdd, visibleEdit]);

  useEffect(() => {
    if (_.get(dataForm, 'customerId')) {
      setFieldsValue({ customerName: _.get(dataForm, 'customerName') });
    }
    if (_.get(dataForm, 'quotationObj.quotationId')) {
      console.log('clearrrr', dataForm);
      setFieldsValue({ quotationNo: _.get(dataForm, 'quotationObj.quotationNo') });
    }
  }, [dataForm]);

  console.log('dataForm', dataForm);

  useEffect(() => {
    if (_.size(selectItem) > 0) {
      let cloneItem = [...selectItem];
      console.log('cloneItem', cloneItem);

      let allCredits = [];
      let data = [];

      for (let i = 0; _.size(selectItem) > i; i++) {
        if (!_.includes(allCredits, cloneItem[i].credits)) {
          console.log('allCredits -> ', allCredits, cloneItem[i].credits);

          const filterItem = _.filter(selectItem, (obj) => obj.credits === cloneItem[i].credits);

          let total = cloneItem[i].subtotal;

          if (_.size(filterItem) > 0) {
            const mapTotal = _.map(filterItem, (v, i) => {
              return {
                totalNum: _.toNumber(v.subtotal),
              };
            });
            total = _.sumBy(mapTotal, 'totalNum');
            allCredits.push(cloneItem[i].credit);
          }
          data.push({
            credit: cloneItem[i].credit,
            amount: total,
          });
        }
      }

      console.log('Quotation soby', data, _.sortBy(data, 'credit'));

      if (_.size(data) > 0) {
        const sortBy = _.sortBy(data, (o) => _.toNumber(o.credit));
        const mapIndex = _.map(sortBy, (o, i) => {
          return {
            ...o,
            index: i + 1,
            remark: '',
            dueDate: moment().add(o.credit, 'day'),
          };
        });
        setPaymentItem(mapIndex);
      }
    }
  }, [selectItem]);

  useEffect(() => {
    console.log('recoredAtt', record, '/', _.get(record, 'attanchment'));
    if (record) {
      const mapAttachment = _.map(_.get(record, 'attanchment'), (o, i) => {
        let temp = {
          id: _.get(o, 'fileHash'),
          index: i + 1,
          attachmentName: _.get(o, 'fileName'),
          remark: _.get(o, 'remark'),
          file: [{ name: _.get(o, 'fileName'), uid: _.get(o, 'fileHash'), url: _.get(o, 'fileUrl'), action: 'N' }],
          fileRaw: _.get(o, 'fileUrl'),
          action: 'N',
          form: 'backend',
          fileHash: _.get(o, 'fileHash'),
        };
        return temp;
      });
      console.log('mapAttachment', mapAttachment);
      setAttData(mapAttachment);
    }
  }, [record]);

  const saveWithStatus = (status) => {
    let rStatus;
    if (status === 'draftTorejected') {
      rStatus = 'draft';
    }
    const payload = record;
    payload.status = rStatus;
    // console.log('savex payload1', _.get(record, 'status.code'), values);
    console.log('savex payload2', _.get(record, 'status.code'), payload);
    // console.log('savex payload3', _.get(record, 'status.code'), dataForm);
    // return;
    onOk(payload, resetFields);
    // resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setSearchGroup({ discount: 0, discountNumber: 0.0 });
    setPaymentItem([]);
  };
  // const duedate = _.maxBy(paymentItem, 'credit');
  // console.log(
  //   'paymentItemdate',
  //   paymentItem,
  //   _.maxBy(paymentItem, 'credit'),
  //   moment(_.get(duedate, 'dueDate')).format('YYYY-MM-DD'),
  //   moment()
  //     .add(5, 'days')
  //     .format('YYYY-MM-DD')
  // );

  const handleSave = (status) => {
    console.log('handleSave', dataForm, status, selectItem);
    if (status === 'draftTorejected') {
      saveWithStatus(status);
    } else {
      const duedate = _.maxBy(paymentItem, 'credit');
      const checkIndex = _.findIndex(selectItem, (o) => o.isOutOfStock === true);
      const isOutOfStock = checkIndex !== -1 ? true : false;

      form.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        if (isOutOfStock) {
          setCheckeQty(isOutOfStock);
          return;
        }

        const itemList = _.map(selectItem, (o) =>
          o.categoryCode === 'service'
            ? {
                // campaignId: _.get(o, 'count'),
                // campaignName: _.get(o, 'count'),
                credit: '',
                discount: _.get(o, 'discountNum'),
                discountPercent: _.get(o, 'discountPercent'),
                itemId: _.get(o, 'itemMasterId'),
                price: _.get(o, 'pricePerUnitNum'), //price
                remark: _.get(o, 'remark'),
                totaQty: _.get(o, 'qtyNum'), //qty
                unitId: _.get(o, 'unitId'),
                unitName: _.get(o, 'uom'),
                saleOrderItemId: o.saleOrderItemId,
              }
            : {
                saleOrderItemId: o.saleOrderItemId,
                totaQty: _.get(o, 'totaQty') ? _.get(o, 'totaQty') : _.get(o, 'qtyNum'),
                warehouseSeletedList: o.warehouseSeletedList,
              }
        );

        const filtervehicleList = _.filter(vehicleList, (item) => {
          return _.get(item, 'assetId') === _.get(values, 'vehicleInternal');
        });

        const assetTemp = _.get(filtervehicleList, '[0]');

        console.log('filtervehicleList', filtervehicleList, '/', _.get(values, 'vehicleInternal'));

        console.log('SaveattData', attData);

        let att = await Promise.all(
          _.map(attData, async (p) => {
            if (_.get(p, 'fileHash')) {
              const formData = new FormData();
              formData.append('fileHash', _.get(p, 'fileHash'));
              formData.append('remark', _.get(p, 'remark') ? _.get(p, 'remark') : '');
              // const reaspon = await uploadFileInvoice(formData);

              const payload = { fileHash: _.get(p, 'fileHash'), remark: _.get(p, 'remark') };
              return payload;
            }
            {
              const formData = new FormData();
              formData.append('file', _.get(p, 'fileRaw'));
              formData.append('saleOrderId', _.get(record, 'saleOrderId'));
              formData.append('invoiceId', _.get(record, 'invoiceId'));
              formData.append('remark', _.get(p, 'remark') ? _.get(p, 'remark') : '');
              const reaspon = await uploadFileInvoice(formData);

              const payload = { fileHash: _.get(reaspon, 'data.fileHash'), remark: _.get(p, 'remark') };
              return payload;
            }
            // const formData = new FormData();
            // formData.append('file', _.get(p, 'fileRaw'));
            // formData.append('fileHash', _.get(p, 'fileHash'));
            // formData.append('saleOrderId', _.get(record, 'saleOrderId'));
            // formData.append('invoiceId', _.get(record, 'invoiceId'));
            // formData.append('remark', _.get(p, 'remark') ? _.get(p, 'remark') : '');
            // const reaspon = await uploadFileInvoice(formData);

            // const payload = { fileHash: _.get(reaspon, 'data.fileHash'), remark: _.get(p, 'remark') };
            // return payload;
          })
        );

        console.log('attDatainSave', attData);

        const payload = {
          invoiceId: _.get(dataForm, 'invoiceId') || '',
          statusCode: status,
          assetId: _.get(assetTemp, 'assetId') || '',
          serialNo: _.get(assetTemp, 'serialNo') || '',
          brandName: _.get(assetTemp, 'brandName') || '',
          modelName: _.get(assetTemp, 'modelName') || '',
          assetName: _.get(values, 'vehicleInternal') ? _.get(assetTemp, 'assetName') : _.get(values, 'vehicleOther') || '',
          vehicleTypeCode: _.get(values, 'vehicleType') || _.get(dataForm, 'vehicleTypeCode'),
          saleOrderId: _.get(dataForm, 'saleOrderId') || _.get(defaultSaleOrder, 'saleOrderId') || '',
          issueById: _.get(values, 'by') || '',
          issueDate: moment(_.get(values, 'date')).format('YYYY-MM-DD') || '',
          // dueDate:moment(_.get(duedate, 'dueDate')).format('YYYY-MM-DD'),
          dueDate: moment(_.get(values, 'date'))
            .add(_.get(duedate, 'credit') || 0, 'days')
            .format('YYYY-MM-DD'),
          remark: _.get(values, 'remark'),
          attachment: att,
          itemList: itemList,
          subTotal: _.get(dataForm, 'sumTotals'),
          discountPercent: _.get(dataForm, 'discountNumber'),
          discount: _.get(dataForm, 'discount'),
          subTotalAfterDiscount: _.get(dataForm, 'subtotalDiscount'),
          taxPercent: _.get(dataForm, 'taxNumber'),
          tax: _.get(dataForm, 'tax') ? _.toNumber(_.get(dataForm, 'tax').toFixed(2)) : 0,
          total: _.get(dataForm, 'totalAll') ? _.toNumber(_.get(dataForm, 'totalAll').toFixed(2)) : 0,
          code: 'view',
          customerId: _.get(dataForm, 'customerId'),
          customerName: _.get(dataForm, 'customerName'),
          customerAddressBookId: _.get(dataForm, 'customerAddressBookId'),
          customerContactId: _.get(dataForm, 'customerContactId'),
          customerContactPhone: _.get(dataForm, 'customerContactPhone'),
          customerContactEmail: _.get(dataForm, 'customerContactEmail'),
          customerContactName: _.get(dataForm, 'customerContactName'),
          customerAddressBookName: _.get(dataForm, 'addressName'),
          customerAddressBookFullAddress: _.get(dataForm, 'fullAddress'),
          customerTaxNo: _.get(dataForm, 'customerTaxNo'),
        };

        console.log('savex values', values);
        console.log('savex payload', payload, searchGroup);

        
        onOk(payload, resetFields);
        handleCancelQuotation();
        setCheckeQty(false);
        setDataForm();
        setCreateData();
        setAttData([]);
        setSelectItem([]);
        setSearchGroup({ discount: 0, discountNumber: 0.0 });
        setPaymentItem([]);
      });
    }
  };

  const handleCancelQuotation = () => {
    onCancel();
    setCheckeQty(false);
    resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setSearchGroup({ discount: 0, discountNumber: 0.0 });
    setPaymentItem([]);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleOk = () => {
    setIsOpen(false);
    setDataForm((prev) => ({ ...prev, quotationObj: {} }));
    form.setFieldsValue({ quotationNo: '' });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const operations = (
    <div className="tag-center-div" style={{ padding: '12px 12px' }}>
      <Tag className="tag-center-style" color={_.get(record, 'statusColor')}>
        {_.get(record, 'statusTxt')}
      </Tag>
    </div>
  );

  // const handlePrint = useReactToPrint({
  //   content: () => invoicePrntRef.current,
  //   documentTitle: 'data',
  //   copyStyles: true,
  // });

  const handlePrint = async () => {
    const formateData = fomatePDF(record);
    const response = await InvoicePDFDownloader(_.get(record, 'invoiceNo'), formateData);
    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      if (url) {
        setUrlPrint(url);
        setVisiblePrint(true);
      }
      // GeneratorPDF(response)
    }
  };

  const fomatePDF = (props) => {
    const totalNum = _.get(props, 'total') ? _.get(props, 'total') : 0;
    return {
      ...record,
      totalNumberText: ThaiNumberToText(totalNum.toFixed(2)),
      discountPercentTxt: numberFormatter(_.get(props, 'discountPercent')),
      taxPercentageTxt: numberFormatter(_.get(props, 'taxPercentage')),
    };
  };

  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          id: _.get(record, 'saleOrderId'),
          code: _.get(record, 'code'),
          status: 'deleted',
        };
        resetFields();
        setDataForm();
        setCreateData();
        setAttData([]);
        setSelectItem([]);
        setSearchGroup({ discount: 0, discountNumber: 0.0 });
        setPaymentItem([]);
        handleSaveDelete(payload);
      },
      handleCancelQuotation() {},
    });
  };

  const handleEdit = () => {
    refreshModalSaleOrder(_.get(record, 'invoiceId'), 'edit');
  };

  console.log('recordrecord', record, dataForm);

  const footer =
    _.get(record, 'stautsCode') === 'waiting'
      ? [
          // <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
          //   <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          // </Button_02>,
          // <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleDelete()}>
          //   <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          // </Button_01>,
          // <Button_01
          //   style={{ margin: '0px 0px 0px 10px', width: '18%' }}
          //   key="cancelQT"
          //   type="primary"
          //   btnsize="wd_df"
          //   onClick={() => handleOpenCancel()}
          // >
          //   <FormattedMessage id="btnCancelSalesOrder" defaultMessage="Cancel Sales Order" />
          // </Button_01>,
          // <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" btnsize="wd_df" onClick={() => handleOpenReject()}>
          //   <FormattedMessage id="btnReject" defaultMessage="Reject" />
          // </Button_01>,
          // <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="approve" type="primary" btnsize="wd_df" onClick={() => handleOpenApprove()}>
          //   <FormattedMessage id="btnApprove" defaultMessage="Approve" />
          // </Button_01>,
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {_.get(dataForm, 'stautsCode') !== 'draft' && _.get(dataForm, 'stautsCode') !== 'canceled' && (
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                <FormattedMessage id="btnPrint" defaultMessage="Print" />
              </Button_01>
            )}
            <div>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button_02>
              {_.get(dataForm, 'stautsCode') !== 'canceled' ? (
                <Button_01
                  style={{ margin: '0px 0px 0px 10px' }}
                  key="cancelQT"
                  type="primary"
                  btnsize="wd_df"
                  onClick={() => handleOpenCancel()}
                >
                  <FormattedMessage id="btnCancelInvoice" defaultMessage="Cancel" />
                </Button_01>
              ) : null}
            </div>
          </div>,
        ]
      : _.get(record, 'stautsCode') === 'draft' && _.get(dataForm, 'code') === 'view'
      ? [
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                <FormattedMessage id="btnPrint" defaultMessage="Print" />
              </Button_01>
            </div>
            <div>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button_02>
              {/* <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="delte" btnsize="wd_df" onClick={() => handleDelete()}>
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </Button_01>
        <Button_01
          style={{ margin: '0px 0px 0px 10px' }}
          key="cancelQT"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleOpenCancel()}
        >
          <FormattedMessage id="btnCancelSalesOrder" defaultMessage="Cancel Sales Order" />
        </Button_01>
        <Button_01
          style={{ margin: '0px 0px 0px 10px' }}
          key="waiting"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleOpenWaiting()}
        >
          <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
        </Button_01> */}
            </div>

            {/* {_.get(dataForm, 'code') === 'view' ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
              <FormattedMessage id="btnPrint" defaultMessage="Print" />
            </Button_01>
          </div>
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>
          <div>
            <Button_01
              style={{ margin: '0px 0px 0px 10px' }}
              key="waiting"
              type="primary"
              btnsize="wd_df"
              onClick={() => handleOpenWaiting()}
            >
              <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
            </Button_01>
            <Button_01
              style={{ margin: '0px 0px 0px 10px' }}
              key="reject"
              type="primary"
              btnsize="wd_df"
              onClick={() => handleOpenReject()}
            >
              <FormattedMessage id="btnReject" defaultMessage="Reject" />
            </Button_01>
          </div>
        </div>
      ) : null
      }
      {
        _.get(record, 'code') === 'edit' ? (
          <>
            <Button_01
              style={{ margin: '0px 0px 0px 10px' }}
              key="draft"
              btnsize="wd_df"
              type="primary"
              onClick={() => handleSave('draft')}
              disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
            >
              <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
            </Button_01>

            <Button_01
              style={{ margin: '0px 0px 0px 10px' }}
              key="submit"
              type="primary"
              btnsize="wd_df"
              disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
              onClick={() => handleSave('waiting')}
            >
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button_01>
          </>
        ) : null
      } */}
          </div>,
        ]
      : _.get(record, 'stautsCode') === 'canceled'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
        ]
      : _.get(dataForm, 'code') === 'view'
      ? [
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {_.get(dataForm, 'stautsCode') !== 'draft' && _.get(dataForm, 'stautsCode') !== 'canceled' && (
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                <FormattedMessage id="btnPrint" defaultMessage="Print" />
              </Button_01>
            )}
            <div>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button_02>
              {_.get(dataForm, 'stautsCode') !== 'canceled' ? (
                <Button_01
                  style={{ margin: '0px 0px 0px 10px' }}
                  key="cancelQT"
                  type="primary"
                  btnsize="wd_df"
                  onClick={() => handleOpenCancel()}
                >
                  <FormattedMessage id="btnCancelInvoice" defaultMessage="Cancel" />
                </Button_01>
              ) : null}
            </div>
          </div>,
        ]
      : [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_02
            style={{ margin: '0px 0px 0px 10px', width: '12%' }}
            key="draft"
            btnsize="wd_df"
            disabled={_.size(selectItem) === 0}
            onClick={() => handleSave('draft')}
          >
            <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
          </Button_02>,
          <Button_01 key="save" type="primary" btnsize="wd_df" disabled={_.size(selectItem) === 0} onClick={() => handleSave('waiting')}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ];

  // const actionFooter =
  //   _.get(record, 'status.code') === 'waiting' ? (
  //     [

  //     ]
  //   ) : _.get(record, 'status.code') === 'draft' ? (
  //     [

  //       // <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="draft" btnsize="wd_df" onClick={() => handleSave('draft')}>
  //       //   <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
  //       // </Button_01>,

  //     ]
  //   ) : _.get(record, 'status.code') === 'canceled' ? (
  //     [
  //       <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
  //         <FormattedMessage id="btnClose" defaultMessage="Close" />
  //       </Button_02>,
  //     ]
  //   ) : _.get(dataForm, 'code') === 'view' ? (
  //     []
  //   ) : (

  //   );

  const handleOpenModalEdit = (data) => {
    setEditService(data);
    setVisibleEditService(true);
  };

  const onChangeDiscount = async (value, code) => {
    const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.0;
    if (code === 'discount') {
      setSearchGroup({ discount: parseInt(value || 0), discountNumber: ((sumTotal * parseInt(value || 0)) / 100).toFixed(2) });
    } else if (code === 'discountNumber') {
      setSearchGroup({ discount: ((parseFloat(value || 0.0) / sumTotal) * 100).toFixed(2), discountNumber: parseFloat(value || 0.0) });
    }
  };

  const handleOpenModalListService = () => {
    setVisibleListService(true);
  };

  const menuColumn = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setVisibleAddINV(true);
        }}
      >
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnItem" defaultMessage="Item" />
        </Button>
      </Menu.Item>
      <Menu.Item onClick={handleOpenModalListService}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnService" defaultMessage="Service" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancelQuotation}
      width={1102}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={footer}
    >
      {_.get(record, 'code') === 'view' && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
            tabBarExtraContent={operations}
          >
            {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTabView
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  selectCustomer,
                  record,
                  checkQty,
                }}
              />
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `invoiceLabelCredit`, defaultMessage: 'Credit' })} key="credit">
              <PaymentTabView dataSource={paymentItem} selectItem={selectItem} setSelectItem={setSelectItem} />
            </TabPane>
            {/* ) : null} */}
          </Tabs>
        </StickyContainer>
      )}

      {_.get(record, 'code') === 'edit' && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
            tabBarExtraContent={operations}
          >
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTab
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  formCustomerRef,
                  isOpenQuotation,
                  record,
                  handleOpenModalEdit,
                  onChangeDiscount,
                  searchGroup,
                  handleDeleteAttAchment,
                  menuColumn,
                  checkQty,
                  vehicleList,
                  saleOrderQTY,
                  setSaleOrderQTY,
                }}
              />

              <ModalSelectCustomerForm
                ref={formCustomerRef}
                visible={isOpen}
                setIsOpen={setIsOpen}
                onOk={handleOk}
                dataForm={dataForm}
                setDataForm={setDataForm}
                isOpenQuotation={isOpenQuotation}
                selectCustomer={selectCustomer}
                setSelectCustomer={setSelectCustomer}
                setSelectItem={setSelectItem}
                setCustomerTrigger={setCustomerTrigger}
              />

              <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
              />

              <ModalEditListItem
                visible={visibleEdit}
                setVisible={setVisibleEdit}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                data={editData}
              />
            </TabPane>
            <TabPane tab={intl.formatMessage({ id: `invoiceLabelCredit`, defaultMessage: 'Credit' })} key="credit">
              <PaymentTab dataSource={paymentItem} setDataSource={setPaymentItem} selectItem={selectItem} setSelectItem={setSelectItem} />
            </TabPane>
          </Tabs>
        </StickyContainer>
      )}

      {!_.get(record, 'code') && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
          >
            {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTab
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  setIsOpenQt,
                  isOpenQt,
                  formCustomerRef,
                  isOpenQuotation,
                  handleOpenModalEdit,
                  onChangeDiscount,
                  searchGroup,
                  menuColumn,
                  checkQty,
                  vehicleList,
                  record,
                  saleOrderQTY,
                  setSaleOrderQTY,
                }}
              />

              <ModalSelectCustomerForm
                ref={formCustomerRef}
                visible={isOpen}
                setIsOpen={setIsOpen}
                onOk={handleOk}
                dataForm={dataForm}
                setDataForm={setDataForm}
                isOpenQuotation={isOpenQuotation}
                selectCustomer={selectCustomer}
                setSelectCustomer={setSelectCustomer}
                setSelectItem={setSelectItem}
                setCustomerTrigger={setCustomerTrigger}
              />

              <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
                formCustomerRef={formCustomerRef}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
              />

              <ModalEditListItem
                visible={visibleEdit}
                setVisible={setVisibleEdit}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                data={editData}
              />
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `invoiceLabelCredit`, defaultMessage: 'Credit' })} key="credit">
              <PaymentTab dataSource={paymentItem} setDataSource={setPaymentItem} selectItem={selectItem} setSelectItem={setSelectItem} />
            </TabPane>
            {/* ) : null} */}
          </Tabs>
        </StickyContainer>
      )}

      <ModelAddItemIN
        title={
          _.get(record, 'saleOrderDetail.saleOrderNo')
            ? intl.formatMessage({ id: 'ARPendingDeliverie', defaultMessage: 'Pending Deliverie' }) +
              ' · ' +
              `${intl.formatMessage({ id: `ARSONo`, defaultMessage: 'SO No' })}` +
              ' ' +
              _.get(record, 'saleOrderDetail.saleOrderNo')
            : intl.formatMessage({ id: 'ARPendingDeliverie', defaultMessage: 'Pending Deliverie' }) +
              ' · ' +
              `${intl.formatMessage({ id: `ARSONo`, defaultMessage: 'SO No' })}` +
              ' ' +
              _.get(record, 'saleOrderNo')
        }
        visible={visibleAddINV}
        onCancel={handleCancelAddItem}
        record={record}
        paginationPage={pageTable}
        setPaginationPage={setPageTable}
        paginationShow={sizeTable}
        setPaginationSize={setSizeTable}
        warehouseList={warehouseList}
        saleOrderQTY={saleOrderQTY}
        setWarehouseList={setWarehouseList}
        setSaleOrderQTY={setSaleOrderQTY}
        handleCleckSaleOrder={handleCleckSaleOrder}
        recordList={recordList}
        setRecordList={setRecordList}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        totalWarehouseList={totalWarehouseList}
        setTotalWarehouseList={setTotalWarehouseList}
      />

      <div style={{ display: 'none' }}>
        {/* <div> */}
        {/* <SaleOrderPrint
          ref={invoicePrntRef}
          record={record}
          recordReport={recordReport}
          sizeRecordReport={sizeRecordReport}
          signatureUser={signatureUser}
          signatureIssu={signatureIssu}
        /> */}
        <ARPrint
          ref={invoicePrntRef}
          record={record}
          signatureUser={signatureUser}
          recordReport={recordReport}
          setRecordReport={setRecordReport}
        />
      </div>

      <ModalApproveForm
        title={intl.formatMessage({ id: `quotationModalApproved`, defaultMessage: 'Approved' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleApprove}
        onOk={handleSaveApprove}
        onCancel={handleCancelApprove}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />
      <ModalCancelForm
        title={
          intl.formatMessage({ id: `invoiceModalCancelInvoice`, defaultMessage: 'Cancel Invoice' }) + ' · ' + _.get(record, 'invoiceNo')
        }
        visible={visibleCancel}
        onOk={handleSaveCancel}
        onCancel={handleCancelCancelQT}
        initialReasonList={initialReasonList}
        invoiceId={_.get(record, 'invoiceId')}
        code={_.get(record, 'code')}
      />
      <ModalRejectForm
        title={intl.formatMessage({ id: `quotationModalRejected`, defaultMessage: 'Rejected' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleReject}
        onOk={handleSaveReject}
        onCancel={handleCancelReject}
        initialReasonList={initialReasonList}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />

      <ModalDraftForm
        title={intl.formatMessage({ id: `quotationModalDraft`, defaultMessage: 'Draft' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleDelete}
        onOk={handleSaveDelete}
        onCancel={handleCancelDelete}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />

      <ModalWaitingForm
        title={intl.formatMessage({ id: `quotationModalWaiting`, defaultMessage: 'Waiting' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleWaiting}
        onOk={handleSaveWaiting}
        onCancel={handleCancelWaiting}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />

      <ListItemServiceInvoice
        visible={visibleListService}
        setVisible={setVisibleListService}
        setVisibleAdd={setVisibleAddService}
        setCreateData={setCreatService}
        dataForm={dataForm}
      />

      <ModalCreateListServiceItemInvoice
        visible={visibleAddService}
        setVisible={setVisibleAddService}
        data={creatService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <ModalEditListServiceItemInvoice
        visible={visibleEditService}
        setVisible={setVisibleEditService}
        data={editService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalPDFViewver visible={visiblePrint} setVisible={setVisiblePrint} url={urlPrint} handlePrint={handlePrint} />
    </Modal>
  );
};

SaleOrderModal.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCustomerSaleOrderForm = Form.create({ name: 'Customer_Form' })(SaleOrderModal);

export default ModalCustomerSaleOrderForm;
