import React, { useCallback, useState, useRef, createContext, useEffect } from 'react';
import FormItem from './modal-form';
import FormItemDtail from './modal-form-detail';
import ImageItem from './image-form';
import { Modal, Col, Row, Tabs } from 'antd';
import _ from 'lodash';
import Button01 from '../../components/v2/button_01.js';
import Button02 from '../../components/v2/button_02.js';
import { useIntl, FormattedMessage } from 'react-intl';
import httpClient from '../../components/axiosClient';
import { successNotification, errorNotification } from '../v2/notification';
import ModalCreateGroupType from '../create-group-type/index.js';
import ModalCreateBrandModel from '../create-brand-model/index.js';

export const itemMasterContext = createContext();
const { TabPane } = Tabs;

export default ({
  visible,
  setVisible,
  setTriggerAPI,
  record,
  setRecord,
  dataDefault,
  setBrandId,
  dataModel,
  createItem,
  setCreateItem,
  setEventDataDefault,
  groupTypeId,
  setGroupTypeId,
  typeModal,
  warehouseData,
  handleCustomerPopupScroll,
  handleSearchWarhouse,
  fncReturnData,
}) => {
  const comCode = localStorage.getItem('comCode');
  const imageRef = useRef();
  const intl = useIntl();
  const itemGroupCode = _.get(dataDefault, 'itemGroup');
  const itemTypeCode = _.get(dataDefault, 'itemType');
  const itemBrand = _.get(dataDefault, 'itemBrand');
  const itemCurrency = _.get(dataDefault, 'itemCurrency');

  const [formRef, setFormRef] = useState(null);
  const [formRefDetail, setFormRefDetail] = useState(null);
  const [itemImage, setItemImage] = useState();
  const [disabledButtonSave, setDisableButtonSave] = useState(false);
  const [uomOption, setUomOption] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [tab, setTab] = useState('overview');
  const [proCheck, setProCheck] = useState(true);
  const [serCheck, setSerCheck] = useState(false);
  const [groupModal, setGroupModal] = useState(false);
  const [brandModal, setBrandModal] = useState(false);
  const [groupModalType, setGroupModalType] = useState(false);

  useEffect(() => {
    if (record) {
      // console.log('BrandIDEdit',record);
      setBrandId(_.get(record, 'brandId') || '');
    }
  }, [record, visible]);

  const saveFormRef = useCallback((node) => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  const saveFormRefDetail = useCallback((node) => {
    if (node !== null) {
      setFormRefDetail(node);
    }
  }, []);

  useEffect(() => {
    if (_.get(record, 'categoryCode') === 'service' && visible === true) {
      handleCategoryService();
    }
  }, [visible]);

  const handleCancel = async () => {
    setVisible(false);
    // setRecord()
    setItemImage();
    setImageFile('');
    formRef.resetFields();
    formRefDetail.resetFields();
    imageRef.current.value = null;
    setBrandId('');
    handleCategoryProduct();
    setCreateItem({});
    setGroupTypeId({});
    setRecord({});
  };

  const handleSave = async () => {
    try {
      const validate1 = await formRef.validateFields();
      const validate2 = await formRefDetail.validateFields();

      const itemGroup = _.filter(itemGroupCode, (o) => {
        return o.itemGroupId === validate1.itemGroupCode;
      });
      const itemType = _.filter(itemTypeCode, (o) => {
        return o.typeId === validate1.itemTypeCode;
      });
      const itemBrandId = _.filter(itemBrand, (o) => {
        return o.itemBrandId === validate1.brandName;
      });
      const itemCurrencyId = _.filter(itemCurrency, (o) => {
        return o.itemCurrencyId === validate1.currencyCode;
      });
      const itemModelId = _.filter(dataModel, (o) => {
        return o.itemModelId === validate1.modelName;
      });
      // const itemUOMCat = _.filter(dataModel, (o) => {
      //   return o.itemModelId === validate1.modelName;
      // });
      console.log('itemType',itemType,'/find:',validate1.itemTypeCode,'/all:',itemTypeCode);
      if (record === undefined) {
        const body = {
          itemGroupCode: _.get(itemGroup[0], 'itemGroupCode'),
          itemTypeCode: _.get(itemType[0], 'itemtypeCode'),
          brandName: _.get(itemBrandId[0], 'itemBrandName') ? _.get(itemBrandId[0], 'itemBrandName') : undefined,
          modelName: _.get(itemModelId[0], 'itemModelName') ? _.get(itemModelId[0], 'itemModelName') : null,
          itemBrandId: _.get(itemBrandId[0], 'itemBrandId') ? _.get(itemBrandId[0], 'itemBrandId') : undefined,
          itemModelId: _.get(itemModelId[0], 'itemModelId') ? _.get(itemModelId[0], 'itemModelId') : undefined,
          itemCode: validate1.itemCode,
          itemName: validate1.itemName,
          description: validate1.descriptions ? validate1.descriptions : '',
          standardPrice: validate2.standardPrice,
          currencyCode: _.get(itemCurrencyId[0], 'itemCurrencyCode'),
          purchaseLeadTime: 0,
          minimumStock: 0,
          maximumStock: 0,
          defaultLocationCode: undefined,
          standardCost: validate2.standardCost ? parseFloat(validate2.standardCost) : 0,
          standardPrice: validate2.standardPrice ? parseFloat(validate2.standardPrice) : 0,
          width: validate2.width ? parseFloat(validate2.width) : undefined,
          length: validate2.length ? parseFloat(validate2.length) : undefined,
          height: validate2.height ? parseFloat(validate2.height) : undefined,
          weight: validate2.weight ? parseFloat(validate2.weight) : undefined,
          pickingTypeId: validate2.pickingType ? validate2.pickingType : undefined,
          uomId: validate1.uom ? validate1.uom : undefined,
          uomCategoryId: validate1.uomCategory ? validate1.uomCategory : undefined,
          categoryCode: serCheck === true ? 'service' : 'product',
          creditDay: validate2.credit ? parseFloat(validate2.credit) : undefined,
          FDA: validate2.fda ? validate2.fda : undefined,
          warehouseId: _.get(validate1,'warehouseId') ? _.get(validate1,'warehouseId'): undefined,
        };
        try {
          const response = await httpClient.post(`/v3/item/manager/${comCode}/createdataitemmaster`, body);
          console.log('responsexxxxx', response)
          if (response.status === 200) {
            if (response.data.data.data.item_master_id) {
              if (itemImage) {
                let formData = new FormData();
                formData.append('file', itemImage);
                await httpClient.put(
                  `/v3/item/manager/${comCode}/updatedataitemmaster/photo/${response.data.data.data.item_master_id}`,
                  formData
                );
              }
            }

            if (_.isFunction(fncReturnData)) {
              fncReturnData(response.data.data.data)
            }

            setVisible(false);
            setTriggerAPI((current) => !current);
            setItemImage();
            formRef.resetFields();
            formRefDetail.resetFields();
            setItemImage();
            setImageFile('');
            imageRef.current.value = null;
            successNotification(response.data.status.message);
            setDisableButtonSave(false);
            setBrandId('');
            setCreateItem({});
            setGroupTypeId({});

            window.location.href = `/main/inventory/item-masters/item-detail/${response.data.data.data.item_master_id}`;
          }
        } catch (error) {
          console.log('responsexxxxx', error, error.response)
          errorNotification(error.response.data.status.message);
          setDisableButtonSave(false);
        }
      } else {
        const body = {
          itemMasterId: _.get(record, 'itemMasterId'),
          itemGroupCode: _.get(itemGroup[0], 'itemGroupCode'),
          itemTypeCode: _.get(itemType[0], 'itemtypeCode'),
          brandName: _.get(itemBrandId[0], 'itemBrandName') ? _.get(itemBrandId[0], 'itemBrandName') : undefined,
          modelName: _.get(itemModelId[0], 'itemModelName') ? _.get(itemModelId[0], 'itemModelName') : null,
          itemBrandId: _.get(itemBrandId[0], 'itemBrandId') ? _.get(itemBrandId[0], 'itemBrandId') : undefined,
          itemModelId: _.get(itemModelId[0], 'itemModelId') ? _.get(itemModelId[0], 'itemModelId') : null,
          itemCode: validate1.itemCode,
          itemName: validate1.itemName,
          description: validate1.descriptions ? validate1.descriptions : undefined,
          standardCost: validate2.standardCost ? parseFloat(validate2.standardCost) : 0,
          standardPrice: validate2.standardPrice ? parseFloat(validate2.standardPrice) : 0,
          width: validate2.width ? parseFloat(validate2.width) : undefined,
          length: validate2.length ? parseFloat(validate2.length) : undefined,
          height: validate2.height ? parseFloat(validate2.height) : undefined,
          weight: validate2.weight ? parseFloat(validate2.weight) : undefined,
          pickingTypeId: validate2.pickingType ? validate2.pickingType : undefined,
          uomId: validate1.uom ? validate1.uom : undefined,
          uomCategoryId: validate1.uomCategory ? validate1.uomCategory : undefined,
          categoryCode: serCheck === true ? 'service' : 'product',
          creditDay: validate2.credit ? parseFloat(validate2.credit) : undefined,
          FDA: validate2.fda ? validate2.fda : undefined,
        };

        try {
          const response = await httpClient.put(`/v3/item/manager/${comCode}/updatedataitemmaster`, body);
          if (response.status === 200) {
            if (itemImage) {
              let formData = new FormData();
              formData.append('file', itemImage);
              await httpClient.put(`/v3/item/manager/${comCode}/updatedataitemmaster/photo/${_.get(record, 'itemMasterId')}`, formData);
            }

            setTriggerAPI((current) => !current);
            formRef.resetFields();
            formRefDetail.resetFields();
            setItemImage();
            setImageFile('');
            imageRef.current.value = null;
            successNotification(response.data.status.message);
            setDisableButtonSave(false);
            setBrandId('');
            setVisible(false);
            // console.log('testvisible');
          }
        } catch (error) {
          errorNotification(error.response.data.data.message);
          setDisableButtonSave(false);
        }
      }
    } catch (error) {
      // console.log('error', error);
    }
  };

  const onTabChange = (value) => {
    setTab(value);
  };

  const handleCategoryProduct = (value) => {
    setSerCheck(false);
    setProCheck(true);
  };

  const handleCategoryService = (value) => {
    setSerCheck(true);
    setProCheck(false);
  };

  const handleCloseBrandModal = () => {
    setBrandModal(false);
  };

  const handleOpenGroupModal = (type) => {
    setGroupModal(true);
    setGroupModalType(type);
  };

  const handleCloseGroupModal = () => {
    setGroupModal(false);
  };

  const onSelectGroupType = (data) => {
    if (groupModalType === 'type') {
      formRef.setFieldsValue({
        itemTypeCode:  _.get(data, 'typeId'),
        
      });
    }
    else {
      formRef.setFieldsValue({
        itemGroupCode:  _.get(data, 'groupId'),
      });
    }
    
    
  };

  return (
    <itemMasterContext.Provider
      value={{
        state: { imageRef, imageFile },
        setState: { setImageFile },
      }}
    >
      <div>
        <Modal
          title={
            record
              ? intl.formatMessage({ id: 'modalItemMasterFormItemTitleEdit', defaultMessage: 'Edit Item Master' })
              : intl.formatMessage({ id: 'modalItemMasterFormItemTitle', defaultMessage: 'Create Item Master' })
          }
          className="card-body"
          centered={true}
          visible={visible}
          bodyStyle={{ height: 'calc(100vh - 330px)', overflowY: 'scroll' }}
          // , overflowY: 'auto'
          width={880}
          onCancel={handleCancel}
          onOk={handleSave}
          footer={[
            <Button02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancel} margin=" unset">
              <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
            </Button02>,
            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={handleSave} disabled={disabledButtonSave}>
              <FormattedMessage id="btnSave" defaultMessage="Save" />
            </Button01>,
          ]}
        >
          <Tabs animated={false} style={{ paddingLeft: '15px' }} className="viewTicketTab" onChange={onTabChange} activeKey={tab}>
            <TabPane tab={<FormattedMessage id="AssetOverviewTitle" defaultMessage="Overview" />} key="overview">
              <Row style={{ paddingTop: '36px' }}>
                <Col span={7} style={{ padding: '36px' }}>
                  <ImageItem itemImage={itemImage} setItemImage={setItemImage} record={record} />
                </Col>
                <Col span={17} style={{ padding: '27 0px 0px 0px' }}>
                  <FormItem
                    ref={saveFormRef}
                    record={record}
                    dataDefault={dataDefault}
                    setBrandId={setBrandId}
                    dataModel={dataModel}
                    setUomOption={setUomOption}
                    uomOption={uomOption}
                    handleCategoryProduct={handleCategoryProduct}
                    handleCategoryService={handleCategoryService}
                    proCheck={proCheck}
                    serCheck={serCheck}
                    handleOpenGroupModal={handleOpenGroupModal}
                    setBrandModal={setBrandModal}
                    createItem={createItem}
                    setCreateItem={setCreateItem}
                    groupTypeId={groupTypeId}
                    typeModal={typeModal}
                    warehouseData={warehouseData}
                    handleCustomerPopupScroll={handleCustomerPopupScroll}
                    handleSearchWarhouse={handleSearchWarhouse}
                    visible={visible}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane
              className="viewTicketTab"
              tab={<FormattedMessage id="TabDetail" defaultMessage="Detail" />}
              key="detail"
              forceRender={true}
            >
              <Row style={{ paddingTop: '36px' }}>
                <Col span={7} style={{ padding: '36px' }}>
                  <ImageItem itemImage={itemImage} setItemImage={setItemImage} record={record} />
                </Col>
                <Col span={17} style={{ padding: '27 0px 0px 0px' }}>
                  <FormItemDtail
                    ref={saveFormRefDetail}
                    record={record}
                    dataDefault={dataDefault}
                    setBrandId={setBrandId}
                    dataModel={dataModel}
                    setUomOption={setUomOption}
                    uomOption={uomOption}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          {brandModal && (
            <ModalCreateBrandModel
              visible={brandModal}
              onCancel={handleCloseBrandModal}
              setCreateItem={setCreateItem}
              setBrandId={setBrandId}
              setEventDataDefault={setEventDataDefault}
            />
          )}

          {groupModal && (
            <ModalCreateGroupType
              visible={groupModal}
              onCancel={handleCloseGroupModal}
              setCreateItem={onSelectGroupType}
              setBrandId={setBrandId}
              setEventDataDefault={setEventDataDefault}
              groupModalType={groupModalType}
            />
          )}
        </Modal>
      </div>
    </itemMasterContext.Provider>
  );
};
