import { Table } from 'antd';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const TableNetVehicleIncomeReport = (props) => {
  const { columns, listData, page, total, loading, showTotal, handleChange } = props;
  return (
    <div style={{ padding: '16px 24px' }}>
      <>
        <Table
          className="custom-column-border-table"
          dataSource={listData}
          columns={columns}
          onChange={handleChange}
          loading={loading}
          scroll={{ x: true }}
          pagination={{
            total: total,
            showTotal: showTotal,
            defaultCurrent: 1,
            current: page,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            showSizeChanger: true,
            locale: { items_per_page: '' },
          }}
        />
        {_.size(listData) > 0 ? (
          <div className="total-items-member">
            <span style={{ fontSize: '13px' }}>
              <FormattedMessage id="lblTotal" defaultMessage="Total" />
              {` ${total || 0} `}
              <FormattedMessage id="lblitems" defaultMessage="items" />
            </span>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default TableNetVehicleIncomeReport;
