import React from 'react';
import ChartLineBar from '../../component/chart-line-bar';

const LaborKG = (prop) => {
  const {chartData} = prop
  console.log('KGKGKGKG',chartData);
  
  // const chartData = [
  //   {
  //     name: 'ตลาดส้ม',
  //     bar: 200,
  //     line: 1700,
  //   },
  //   {
  //     name: 'ตลาดผลไม้รวม',
  //     bar: 300,
  //     line: 1800,
  //   },
  //   {
  //     name: 'ตลาดดอกไม้และสังฆภัณฑ์',
  //     bar: 230,
  //     line: 1500,
  //   },
  //   {
  //     name: 'ตลาดสด ชั้น 1',
  //     bar: 120,
  //     line: 2700,
  //   },
  //   {
  //     name: 'ตลาดสด ชั้น 2',
  //     bar: 500,
  //     line: 2000,
  //   },
  //   {
  //     name: 'ตลาดของแห้ง',
  //     bar: 450,
  //     line: 1000,
  //   },
  //   {
  //     name: 'ตลาดอาหารทะเล',
  //     bar: 360,
  //     line: 3000,
  //   },
  //   {
  //     name: 'ตลาดปลาน้ำจืด',
  //     bar: 420,
  //     line: 2500,
  //   },
  // ];

  const chartConfig = {
    barLegend: 'Productivity',
    lineLegend: 'Revenue',
    barColor: '#4CB140',
    lineColor: '#3BA223',
    lineDash: '2 2',
    lineLegendType: 'plainline',
    domain: [0, 'auto'],
    yLeft: 'kg. (*1,000)',
    yRight: 'Revenue (THB)',
  };

  return <ChartLineBar data={chartData} config={chartConfig} />;
};

export default LaborKG;
