import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { errorNotification, successNotification } from '../../../components/v2/notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { select } from 'glamor';
import {
  findArrActualMarket,
  findNetAreaMarket,
  findOccrateAndArrMarket,
  findOccRateMarket,
  findRevenueMarket,
  getMarketAll,
  getTotalMarket,
} from '../../../controllers/taeco-overview-api/taeco-overview-api';

const MarketContext = createContext();

const MarketProvider = ({ children }) => {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('waiting');
  const [filterDate, setFilterDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });
  const [selectSingle, SetSelectSingle] = useState('');
  const [singleMarketname, setSingleMarketName] = useState();
  const [dataBucket, setDataBucket] = useState();

  const [mapItemDashboard, setMapItemDashboard] = useState([]);
  const [mapSelectFilter, setMapSelectFilter] = useState(['contributionRate', 'availableLots']);
  //----------------------------------------------------------------
  const [marketAllList, setMarketAllList] = useState([]);
  const [marketSingleSelect, setMarketSingleSelect] = useState([]);
  const [marketAllGroupList, setMarketAllGroupList] = useState([]);
  const [inintRevenue, setinintRevenue] = useState(false);

  const [revenueNet, setRevenueNet] = useState([]);
  const [selectMarketNet, setSelectMarketNet] = useState();
  const [loadingRevenueNet, setLoadingRevenueNet] = useState(false);
  const [revenueNetDate, setRevenueNetDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [revenueOcc, setRevenueOcc] = useState([]);
  const [selectMarketOcc, setSelectMarketOcc] = useState();
  const [loadingRevenueOcc, setLoadingRevenueOcc] = useState(false);
  const [revenueOccDate, setRevenueOccDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [revenueActual, setRevenueActual] = useState([]);
  const [selectMarketActual, setSelectMarketActual] = useState();
  const [loadingRevenueActual, setLoadingRevenueActual] = useState(false);
  const [revenueActualDate, setRevenueActualDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [revenueOccArr, setRevenueOccArr] = useState([]);
  const [selectMarketOccArr, setSelectMarketOccArr] = useState();
  const [loadingRevenueOccArr, setLoadingRevenueOccArr] = useState(false);
  const [revenueOccArrDate, setRevenueOccArrDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [revenueGroup, setRevenueGroup] = useState([]);
  const [selectMarketRevenueGroup, setSelectMarketRevenueGroup] = useState();
  const [loadingRevenueGroup, setLoadingRevenueGroup] = useState(false);
  const [revenueGroupDate, setRevenueGroupDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });
  //-------------------------------------------------------------

  useEffect(() => {
    const getMarketApi = async () => {
      try {
        const response = await getMarketAll();
        console.log('getMarketAll', response);
        const addNewArray = {
          name: intl.formatMessage({ id: 'RevenueAllMarket', defaultMessage: 'All Market' }),
          objectId: 'all',
        };
        setMarketAllList(response);
        const filterMarket = _.filter(response, (i, index) => {
          return index !== 0;
        });
        console.log('MKKMMK', filterMarket);
        SetSelectSingle(_.get(response, '[0].objectId'));
        setMarketSingleSelect(filterMarket);
      } catch {}
    };
    getMarketApi();
  }, []);


  useEffect(() => {
    const getMarketAll = async () => {
      try {
        const filterNotMarketSelect = _.filter(marketAllList, (i, index) => {
          return i.objectId !== selectSingle;
        });
        const filterMarketSelect = _.filter(marketAllList, (i, index) => {
          return i.objectId === selectSingle;
        });
        setMarketSingleSelect(filterNotMarketSelect);
        console.log('asdadasd',filterMarketSelect);
        
        setSingleMarketName(filterMarketSelect)
        setSelectMarketNet([])
        setSelectMarketOcc([])
        setSelectMarketActual([])
        setSelectMarketOccArr([])
        setSelectMarketRevenueGroup([])

        
      } catch {}
    };
    if (selectSingle !== '') {
      getMarketAll();
    }
  }, [selectSingle]);


  useEffect(() => {
    const getTeacoMarketApi = async () => {
      const [startDate, endDate] = filterDateApi(revenueNetDate);

      const payload = {
        type: _.get(filterDate, 'type'),
        startDate,
        endDate,
      };

      try {
        const response = await getTotalMarket(payload);
        console.log('getTeacoMarket', response);
        setDataBucket(response);
        onSetItemDashBoard(_.get(response, 'data.marketArea'));
      } catch {}
    };

    getTeacoMarketApi();
  }, [filterDate, selectSingle]);

  useEffect(() => {
    const initApiRevenue = async () => {
      setLoadingRevenueNet(true)
      setLoadingRevenueOcc(true)
      setLoadingRevenueActual(true)
      setLoadingRevenueOccArr(true)
      setLoadingRevenueGroup(true)
      try {
        getRevenueAndNetApi();
        getOccApi();
        getActaulApi();
        getOccArrApi();
        getRevenueApi();
        setinintRevenue(true)
      } catch {}
    };
    if (tabKey === 'revenue' && !inintRevenue) {
      initApiRevenue();
    }
  }, [tabKey]);

  useEffect(() => {
    if (inintRevenue) {
      setLoadingRevenueNet(true)
      getRevenueAndNetApi();
    }
  }, [revenueNetDate,selectMarketNet]);


  useEffect(() => {
    //----OCC--------------------------------//
    if (inintRevenue) {
      setLoadingRevenueOcc(true)
      getOccApi();
    }
  }, [revenueOccDate, selectMarketOcc]);

  useEffect(() => {
    //-----------Actaul-------------------------------//
    if (inintRevenue) {
      setLoadingRevenueActual(true)
      getActaulApi();
    }

  }, [revenueActualDate, selectMarketActual]);

  useEffect(() => {
    //-----------OccArr-------------------------------//
    if (inintRevenue) {
      setLoadingRevenueOccArr(true)
      getOccArrApi();
    }

  }, [revenueOccArrDate, selectMarketOccArr]);

  useEffect(() => {
    if (inintRevenue) {
      setLoadingRevenueGroup(true)
      getRevenueApi();
    }

  }, [revenueGroupDate, selectMarketRevenueGroup]);


  const getRevenueAndNetApi = async () => {
    const [startDate, endDate] = filterDateApi(revenueNetDate);
    let newSelect = [selectSingle,...selectMarketNet]
    console.log('someomomomo',newSelect);
    
    // if (_.includes(selectMarketNet,selectSingle) ) {
    //   newSelect = _.filter(selectMarketNet,(i) => {
    //     return i !== se
    //   })
    // }

    const payload = {
      type: _.get(revenueNetDate, 'type'),
      startDate,
      endDate,
      marketArr: newSelect,
    };

    try {
      const response = await findNetAreaMarket(payload);
      const arrayres = _.get(response, 'data');
      console.log('findRevenueAndNet', arrayres);
      setRevenueNet(arrayres);
      setLoadingRevenueNet(false)
    } catch {}
  };

  const getOccApi = async () => {
    const [startDate, endDate] = filterDateApi(revenueOccDate);

    const payload = {
      type: _.get(revenueOccDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketOcc,
    };

    try {
      const response = await findOccRateMarket(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueOcc', arrayres);
      setRevenueOcc(arrayres);
      setLoadingRevenueOcc(false)
    } catch {}
  };

  const getActaulApi = async () => {
    const [startDate, endDate] = filterDateApi(revenueActualDate);

    const payload = {
      type: _.get(revenueActualDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketActual,
    };

    try {
      const response = await findArrActualMarket(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueOcc', arrayres);
      setRevenueActual(arrayres);
      setLoadingRevenueActual(false)
    } catch {}
  };

  const getOccArrApi = async () => {
   
    const [startDate, endDate] = filterDateApi(revenueOccArrDate);

    const payload = {
      type: _.get(revenueOccArrDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketOccArr,
    };

    try {
      const response = await findOccrateAndArrMarket(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueOcc', arrayres);
      setRevenueOccArr(arrayres);
      setLoadingRevenueOccArr(false)
    } catch {}
  };

  const getRevenueApi = async () => {
    
    const [startDate, endDate] = filterDateApi(revenueGroupDate);

    const payload = {
      type: _.get(revenueGroupDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketRevenueGroup,
    };

    try {
      const response = await findRevenueMarket(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueOcc', arrayres);
      setRevenueGroup(arrayres);
      setLoadingRevenueGroup(false)
    } catch {}
  };

  const onSetItemDashBoard = (data) => {
    setMapItemDashboard(data);
  };

  const filterDateApi = (date) => {
    let startDate, endDate;
    if (_.get(date, 'type') === 'month' || _.get(date, 'type') === 'year') {
      startDate = moment(_.get(date, 'value'))
        .startOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
      endDate = moment(_.get(date, 'value'))
        .endOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
    } else if (_.get(date, 'type') === 'quarter') {
      startDate = moment()
        .quarter(_.get(date, 'value'))
        .startOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
      endDate = moment()
        .quarter(_.get(date, 'value'))
        .endOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
    } else {
      startDate = moment(_.get(date, 'value[0]')).format('YYYY-MM-DD');
      endDate = moment(_.get(date, 'value[1]')).format('YYYY-MM-DD');
    }

    return [startDate, endDate];
  };

  const ex = () => {};

  return (
    <MarketContext.Provider
      value={{
        state: {
          tabKey,
          filterDate,
          mapSelectFilter,
          mapItemDashboard,
          dataBucket,
          marketAllList,
          revenueNet,
          revenueOcc,
          revenueActual,
          revenueOccArr,
          revenueGroup,
          selectMarketNet,
          selectMarketOcc,
          selectMarketActual,
          selectMarketOccArr,
          selectMarketRevenueGroup,
          marketAllGroupList,
          selectSingle,
          marketSingleSelect,
          singleMarketname,
          loadingRevenueNet,
          loadingRevenueOcc,
          loadingRevenueActual,
          loadingRevenueOccArr,
          loadingRevenueGroup,
        },
        setState: {
          setTabKey,
          setFilterDate,
          setMapSelectFilter,
          setMarketAllList,
          setRevenueNetDate,
          setRevenueOccDate,
          setRevenueActualDate,
          setRevenueOccArrDate,
          setRevenueGroupDate,
          setSelectMarketNet,
          setSelectMarketOcc,
          setSelectMarketActual,
          setSelectMarketOccArr,
          setSelectMarketRevenueGroup,
          SetSelectSingle,
        },
        func: {
          ex,
        },
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export { MarketContext, MarketProvider };
