import React, { useState, useEffect, useMemo } from 'react';
import './index.css';
import { Card, Table, Dropdown, Menu, Button, Row, Col, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import ReportItemSalesAction from './action';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDebounce } from '../../../controllers/debounce';
import moment from 'moment';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import Button01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import { getBrandData, getModelData } from '../../../controllers/po/po';
import { exportReportItemSale, getReportItemSale } from '../../../controllers/report/customer-report/api';
import { exportInvoiceReport, getBillingStatusData, getInvoiceReport, getInvoiceStatusData } from '../../../controllers/invoice-report';
import { getTeamOrg } from '../../../controllers/invoice-new/invoice-api';
import { getMemberIssueBy } from '../../../controllers/quotation/quotation-api-new';
import { downloadExcelArrayBuffer } from '../../../component-function/download-excel';
import { getInitialColumns } from '../../../controllers/initial-column';

const langValue = localStorage.getItem('langValue');

const InvoiceReport = () => {
  const intl = useIntl();

  // const columns = [
  //   {
  //     title: '#',
  //     dataIndex: 'index',
  //     key: 'index',
  //     align: 'center',
  //     width: 70,
  //     fixed: 'left',
  //     default: true,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'reportInvoiceReportColumnsINVNo', defaultMessage: 'INV No.' }),
  //     dataIndex: 'invoiceNo',
  //     key: 'invoiceNo',
  //     sorter: true,
  //     default: true,
  //     fixed: 'left',
  //     render: (text, record, index) => record.invNo,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'reportInvoiceReportColumnsCustomerCode', defaultMessage: 'Customer Code' }),
  //     dataIndex: 'customerCode',
  //     key: 'customerCode',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => record.customerCode,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'reportInvoiceReportColumnsCustomer', defaultMessage: 'Customer' }),
  //     dataIndex: 'customer',
  //     key: 'customer',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => record.customer,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'menuReportInvoiceReporINVStatus', defaultMessage: 'INV Status' }),
  //     dataIndex: langValue === 'EN' ? 'invoiceStatus.EN' : 'invoiceStatus.TH',
  //     key: langValue === 'EN' ? 'invoiceStatus.EN' : 'invoiceStatus.TH',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => (
  //       <div className="tag-center-div">
  //         <Tag className="tag-center-style" color={_.get(record, 'invStatus.color')}>
  //           {_.get(record, 'invStatus.text')}
  //         </Tag>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'reportInvoiceReportColumnsSONo', defaultMessage: 'SO No.' }),
  //     dataIndex: 'soNo',
  //     key: 'soNo',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => record.soNo,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'reportInvoiceReportColumnsBLNo', defaultMessage: 'BL No.' }),
  //     dataIndex: 'blNo',
  //     key: 'blNo',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => record.blNo,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'menuReportInvoiceReporBLStatus', defaultMessage: 'BL Status' }),
  //     dataIndex: langValue === 'EN' ? 'billingStatus.EN' : 'billingStatus.TH',
  //     key: langValue === 'EN' ? 'billingStatus.EN' : 'billingStatus.TH',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => (
  //       <div className="tag-center-div">
  //         <Tag className="tag-center-style" color={_.get(record, 'blStatus.color')}>
  //           {_.get(record, 'blStatus.text')}
  //         </Tag>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'menuReportInvoiceReporTotalAmount', defaultMessage: 'Total Amount' }),
  //     dataIndex: 'total',
  //     key: 'total',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => numberFormatter(record.totalAmount || 0) + ' THB',
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'menuReportInvoiceReporIssuedby', defaultMessage: 'Issued by' }),
  //     dataIndex: 'issuedBy',
  //     key: 'issuedBy',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => record.issuedBy,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'menuReportInvoiceReporTeam', defaultMessage: 'Team' }),
  //     dataIndex: 'team.teamName',
  //     key: 'team.teamName',
  //     sorter: true,
  //     default: false,
  //     render: (text, record, index) => record.team,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'menuReportInvoiceReporIssuedDate', defaultMessage: 'Issued Date' }),
  //     dataIndex: 'issuedDate',
  //     key: 'issuedDate',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => record.issuedDate,
  //   },
  //   {
  //     title: intl.formatMessage({ id: 'menuReportInvoiceReporCreatedDate', defaultMessage: 'Created Date' }),
  //     dataIndex: 'createdDate',
  //     key: 'createdDate',
  //     align: 'right',
  //     sorter: true,
  //     default: true,
  //     render: (text, record, index) => record.createdDate,
  //   },
  // ];

  // const setShowColumnArr = _.map(columns, (item) => {
  //   return item.dataIndex;
  // });

  // const setShowColumn = columns.reduce((obj, item) => {
  //   obj[item.dataIndex] = item.default;
  //   return obj;
  // }, {});

  // const ignoreColumn = _.map(
  //   _.filter(columns, (item) => {
  //     return item.default === false;
  //   }),
  //   (item) => {
  //     return item.dataIndex;
  //   }
  // );

  // const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [search, setSearch] = useState({
    invNo: undefined,
    customer: undefined,
    invStatus: undefined,
    soNo: undefined,
    blNo: undefined,
    blStatus: undefined,
    issuedBy: undefined,
    team: [],
    issuedDate: [],
    searchDate: [moment().startOf('month'), moment().endOf('month')],
  });

  // const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  // const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  // const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [column2, setColumn2] = useState({
    columns: [],
    setShowColumnArr: [],
    setShowColumn: {},
  });

  const ignoreColumn = useMemo(() => {
    return _.map(
      _.filter(column2.columns, (item) => {
        return item.default === false;
      }),
      (item) => {
        return item.dataIndex;
      }
    );
  }, [column2.columns]);

  // Compute the filtered columns based on the ignore list
  const newColumns = useMemo(() => {
    return column2.columns.filter((col) => !ignoreColumn.includes(col.key));
  }, [column2.columns]);

  // Compute the list array from the setShowColumnArr by excluding the ignored keys
  const newListArray = useMemo(() => {
    return _.difference(column2.setShowColumnArr, ignoreColumn);
  }, [column2.setShowColumnArr]);

  // Initialize state with computed values
  const [newDataColumns, setNewDataColumns] = useState(newColumns);
  const [actionColumns, setActionColumns] = useState(column2.columns);
  const [listArrayColumns, setListArrayColumns] = useState(newListArray);
  const [defaultShowColumn, setDefaultShowColumn] = useState(column2.setShowColumn);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2;
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [refTypeList, setRefTypeList] = useState([]);
  const [summary, setSummary] = useState([]);
  const searchDebounce = useDebounce(search, 500);

  const [teamList, setTeamList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [billingList, setBillingList] = useState([]);
  const [memberList, setMemberList] = useState([]);

  const [columnWidthMaximum, setColumnWidthMaximum] = useState(0);
  const [isFetch, setIsFetch] = useState(false);
  // intl.formatMessage({ id: 'resourceAllowanceNo', defaultMessage: 'Allowance No.' })
  //ใช้แมปปิ้งเข้า table
  const mapColumnTable = ({ cols }) => {
    const mapColumn = _.map(cols, (o) => {
      const notShow = _.includes(['teamName'], o.columnCode) ? false : true;

      const obj = {
        title: <FormattedMessage id={o.columnLanguageCode} defaultMessage={o.columnName.en}/>,
        dataIndex: o.columnMapping,
        key: o.columnMapping,
        width: o.columnWidth.web || undefined,
        widthPdf: o.columnWidth.pdf,
        align: o.columnAlign,
        fixed: o.columnFixed,
        sorter: o.columnSorter,
        default: notShow,
      };
      if (o.columnCode === 'invoiceStatus') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) =>
            _.get(record, 'invStatus') ? (
              <div className="tag-center-div">
                <Tag className="tag-center-style" color={_.get(record, 'invStatus.color')}>
                  {_.get(record, 'invStatus.text')}
                </Tag>
              </div>
            ) : null,
        };
      } else if (o.columnCode === 'billingStatus') {
        console.log('billingStatus', o);

        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) =>
            _.get(record, 'blStatus.text') ? (
              <div className="tag-center-div">
                <Tag className="tag-center-style" color={_.get(record, 'blStatus.color')}>
                  {_.get(record, 'blStatus.text')}
                </Tag>
              </div>
            ) : null,
        };
      } else if (o.columnCode === 'teamName') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) =>
            record.team ? (
              <div className="row-team-table" title={record.team}>
                {record.team}
              </div>
            ) : null,
        };
      } else if (o.columnCode === 'invoiceNo') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => record.invNo,
        };
      } else if (o.columnCode === 'total') {
        return {
          ...o,
          ...obj,
          ['render']: (text, record, index) => numberFormatter(record.totalAmount || 0) + ' THB',
        };
      }

      return {
        ...o,
        ...obj,
      };
    });

    const rejectKeys = ['team.teamName'];
    const filteredColumns = _.filter(mapColumn, (column) => !_.includes(rejectKeys, column.dataIndex));
    const mapKeyColumns = _.map(filteredColumns, (column) => column.dataIndex);
    const mapShowColumn = _.reduce(
      mapColumn,
      (obj, item) => {
        obj[item.dataIndex] = item.default;
        return obj;
      },
      {}
    );

    return { col: mapColumn, keyShowArr: mapKeyColumns, keyShow: mapShowColumn };
  };

  // Update states when columnNormal2 changes
  useEffect(() => {
    setNewDataColumns(newColumns);
    setActionColumns(column2.columns);
    setListArrayColumns(newListArray);
    setDefaultShowColumn(column2.setShowColumn);
  }, [column2, newColumns, newListArray]);

  useEffect(() => {
    const getApi = async () => {
      const response = await getInitialColumns({ tableCode: 'saleInvoice' });
      const getColumns = _.get(response, 'data.data.tableConfig.columns');

      console.log('getColumns', getColumns);

      const getColumnWidthMaximum = _.get(response, 'data.data.tableConfig.columnWidthMaximum');
      const { col, keyShowArr, keyShow } = mapColumnTable({ cols: getColumns });
      setColumn2({ columns: col, setShowColumnArr: keyShowArr, setShowColumn: keyShow });
      setColumnWidthMaximum(getColumnWidthMaximum);
    };
    getApi();
  }, []);

  useEffect(() => {
    const getInitialData = async () => {
      const [getTeam, getInvoiceStatus, getBillingStatus, getMemberIssuedBy] = await Promise.all([
        getTeamOrg(),
        getInvoiceStatusData(),
        getBillingStatusData(),
        getMemberIssueBy({}),
      ]);
      const teamMap = _.map(_.get(getTeam, 'team[0].children'), (o) => ({ ...o, value: _.toString(o.value), label: o.title }));
      const memberMap = _.map(getMemberIssuedBy, (o) => ({ ...o, value: _.toString(o.memComId), label: o.fullname }));
      setTeamList(teamMap);
      setInvoiceList(getInvoiceStatus);
      setBillingList(getInvoiceStatus);
      setMemberList(memberMap);
    };
    getInitialData();
  }, []);

  useEffect(() => {
    console.log('search', search);
    const getInvoiceReportList = async () => {
      setLoading(true);
      const body = {
        where: {
          createdDate: {
            startDate: _.get(search, 'searchDate[0]') ? moment(_.get(search, 'searchDate[0]')).format('YYYY/MM/DD') : null,
            endDate: _.get(search, 'searchDate[0]') ? moment(_.get(search, 'searchDate[1]')).format('YYYY/MM/DD') : null,
          },
          issuedDate: {
            startDate: _.get(search, 'issuedDate[0]') ? moment(_.get(search, 'issuedDate[0]')).format('YYYY/MM/DD') : null,
            endDate: _.get(search, 'issuedDate[0]') ? moment(_.get(search, 'issuedDate[1]')).format('YYYY/MM/DD') : null,
          },
          invoiceNo: _.get(search, 'invNo'),
          customerName: _.get(search, 'customer'),
          soNo: _.get(search, 'soNo'),
          blNo: _.get(search, 'blNo'),
          invoiceStatusIdArr: _.size(_.get(search, 'invStatus')) ? _.get(search, 'invStatus') : undefined,
          billingStatusIdArr: _.size(_.get(search, 'blStatus')) ? _.get(search, 'blStatus') : undefined,
          memComIdArr: _.size(_.get(search, 'issuedBy')) ? _.get(search, 'issuedBy') : undefined,
          orgIdArr: _.size(_.get(search, 'team')) ? _.get(search, 'team') : undefined,
        },

        page: page,
        pageSize: size,
        orderBy: sortField,
        order: sortOrder,
      };
      const response = await getInvoiceReport({ body });

      setListData(_.get(response, 'list'));
      setTotal(_.get(response, 'total'));
      setLoading(false);
    };
    getInvoiceReportList();
  }, [page, size, sortOrder, sortField, isFetch, _.get(search, 'searchDate')]);

  const onFilterOrder = async (value, code) => {
    console.log('onFilterOrder', value, code);
    setSearch({ ...search, [code]: value });
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        where: {
          createdDate: {
            startDate: _.get(search, 'searchDate[0]') ? moment(_.get(search, 'searchDate[0]')).format('YYYY/MM/DD') : null,
            endDate: _.get(search, 'searchDate[0]') ? moment(_.get(search, 'searchDate[1]')).format('YYYY/MM/DD') : null,
          },
          issuedDate: {
            startDate: _.get(search, 'issuedDate[0]') ? moment(_.get(search, 'issuedDate[0]')).format('YYYY/MM/DD') : null,
            endDate: _.get(search, 'issuedDate[0]') ? moment(_.get(search, 'issuedDate[1]')).format('YYYY/MM/DD') : null,
          },
          invoiceNo: _.get(search, 'invNo'),
          customerName: _.get(search, 'customer'),
          soNo: _.get(search, 'soNo'),
          blNo: _.get(search, 'blNo'),
          invoiceStatusIdArr: _.size(_.get(search, 'invStatus')) ? _.get(search, 'invStatus') : undefined,
          billingStatusIdArr: _.size(_.get(search, 'blStatus')) ? _.get(search, 'blStatus') : undefined,
          memComIdArr: _.size(_.get(search, 'issuedBy')) ? _.get(search, 'issuedBy') : undefined,
          orgIdArr: _.size(_.get(search, 'team')) ? _.get(search, 'team') : undefined,
        },
        page: 1,
        pageSize: total,
        orderBy: sortField,
        order: sortOrder,
        statusExport: true,
        viewFormat: _.map(column2.columns, (o) => {
          const obj = {
            header: intl.formatMessage({ id: o.title?.props?.id, defaultMessage: o.title?.props?.defaultMessage }) || o.title,
            width: _.get(o, 'columnWidth.excel') || 30,
          };

          if (o.key === 'customerCode') {
            return {
              ...obj,
              key: 'customer.customerCode',
            };
          } else if (o.key === 'customer') {
            return {
              ...obj,
              key: 'customer.customerName',
            };
          } else if (o.key === 'issuedBy') {
            return {
              ...obj,
              key: 'issuedBy.issueBy',
            };
          }
          return {
            ...obj,
            key: o.key,
          };
        }),

        ///แบบเก่า
        // viewFormat: [
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsSoNo', defaultMessage: 'SO No.' }),
        //     sequence: 1,
        //     colCode: 'soNo',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsCustomerCode', defaultMessage: 'Customer Code' }),
        //     sequence: 2,
        //     colCode: 'customerCode',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsCustomerName', defaultMessage: 'Customer Name' }),
        //     sequence: 3,
        //     colCode: 'customerName',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsItemCode', defaultMessage: 'Item Code' }),
        //     sequence: 4,
        //     colCode: 'itemCode',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsItem', defaultMessage: 'Item' }),
        //     sequence: 5,
        //     colCode: 'itemName',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsAvgCost', defaultMessage: 'Avg Cost' }),
        //     sequence: 6,
        //     colCode: 'avgCost',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsPrice', defaultMessage: 'Price' }),
        //     sequence: 7,
        //     colCode: 'price',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsQty', defaultMessage: 'Qty' }),
        //     sequence: 8,
        //     colCode: 'qty',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsUnit', defaultMessage: 'Unit' }),
        //     sequence: 9,
        //     colCode: 'unitName',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsDiscount', defaultMessage: 'Discount' }),
        //     sequence: 10,
        //     colCode: 'discount',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsTotalSales', defaultMessage: 'Total Sales' }),
        //     sequence: 11,
        //     colCode: 'totalSales',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsTotalCost', defaultMessage: 'Total Cost' }),
        //     sequence: 12,
        //     colCode: 'totalCost',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsProfit', defaultMessage: 'Profit' }),
        //     sequence: 13,
        //     colCode: 'profits',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsIssuedBy', defaultMessage: 'Issued by' }),
        //     sequence: 14,
        //     colCode: 'issuedByName',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsPoNo', defaultMessage: 'PO No.' }),
        //     sequence: 15,
        //     colCode: 'purchaseOrderNo',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsPoDate', defaultMessage: 'PO Date' }),
        //     sequence: 16,
        //     colCode: 'purchaseOrderDate',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsQtNo', defaultMessage: 'QT No.' }),
        //     sequence: 17,
        //     colCode: 'quotationNo',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsQTDate', defaultMessage: 'QT Date' }),
        //     sequence: 18,
        //     colCode: 'quotationDate',
        //   },
        //   {
        //     colName: intl.formatMessage({ id: 'reportItemSalesColumnsIssuesDate', defaultMessage: 'Issued Date' }),
        //     sequence: 19,
        //     colCode: 'issueDate',
        //   },
        // ],
      };
      const response = await exportInvoiceReport({ body });
      if (response.status === 200) {
        const disposition = response.headers['content-type'];
        downloadExcelArrayBuffer({ binaryString: response.data, disposition });
        successNotification('Success');
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {}
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card
        className="reportItemSalesCard"
        title={intl.formatMessage({ id: 'menuInvoiceReport', defaultMessage: 'Invoice Report' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
        extra={
          <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
            <Button01 key="submit" type="primary" btnsize="wd_df">
              <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
            </Button01>
          </Dropdown>
        }
      >
        <ReportItemSalesAction
          search={search}
          setSearch={setSearch}
          setPage={setPage}
          setIsFetch={setIsFetch}
          onFilterOrder={onFilterOrder}
          refTypeList={refTypeList}
          teamList={teamList}
          billingList={billingList}
          invoiceList={invoiceList}
          memberList={memberList}
          actionColumns={{
            columns: column2.columns,
            setShowColumn: column2.setShowColumn,
            newDataColumns,
            setNewDataColumns,
            setListArrayColumns,
            setDefaultShowColumn,
            listArrayColumns,
            defaultShowColumn,
            newColumns,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum,
          }}
        />
        <div style={{ padding: '16px 24px' }}>
          <Table
            dataSource={listData}
            columns={newDataColumns}
            onChange={handleChange}
            loading={loading}
            scroll={{ x: true }}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              current: page,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
          />
          {_.size(listData) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default InvoiceReport;
