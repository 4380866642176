import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import _ from 'lodash';
import { messageLabel } from '../../../../components/message-component';
import moment from 'moment';
import CustomTableComponent from '../../components/table';
import LabeRequireForm from '../../../../components/label-required-form';
import { SelectCustomerHook } from '../select-customer-new/selectHook';
import ListItemFilter from './filter';
import { AccountReceiveContext, InvoiceContext } from '../../context';
import { getCreditNoteBL } from '../../../../controllers/credit-note/credit-note';

const { Option } = Select;

const SelectCreditNoteModal = ({ form, title, visible, onOk, onCancel, setDataForm, dataForm, setSelectItem, selectItem }) => {
  const [reportDataPending, setReportDataPending] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderType: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    invoiceNo: '',
    paymentTermNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [],
    statusCode: [],
    issueDateStart: '',
    issueDateEnd: '',
  });

  const [creditNoteData, setCreditNoteData] = useState({
    cnNo: '',
    customer: '',
    invNo: '',
    fromINV: '',
    toINV: '',

    creditNoteList: [],
    creditNoteTotal: 0,
    orderType: undefined,
    orderBy: undefined,
    page: 1,
    limit: 10,
    loading: false,
  });

  const clearReport = reportDataPending;
  console.log('dataFormCus', dataForm);
  const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = form;
  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const { customerList, contactList, addressList, handleSearchCustomer, resetCustomer } = SelectCustomerHook();
  const [searchGroup, setSearchGroup] = useState([]);
  const [event, setEvent] = useState([]);
  const { state, setState, func } = useContext(AccountReceiveContext);
  // const { setDefaultSaleOrder } = setState;
  // const { handleOpenModalQuotationPending } = func;

  const intl = useIntl();

  console.log('reportDataPending', reportDataPending);
  console.log('creditNoteData', creditNoteData);

  useEffect(() => {
    setCreditNoteData((prev) => ({ ...prev, loading: true }));

    const getApi = async () => {
      const payload = {
        customerId: _.get(dataForm, 'customerId'),
        creditNoteNo: _.get(creditNoteData, 'cnNo'),
        invoiceNo: _.get(creditNoteData, 'invNo'),
        issueDateFrom: _.get(creditNoteData, 'fromINV') ? moment(_.get(creditNoteData, 'fromINV')).format('YYYY-MM-DD') : '',
        issueDateTo: _.get(creditNoteData, 'toINV') ? moment(_.get(creditNoteData, 'toINV')).format('YYYY-MM-DD') : '',

        pageNumber: pageTable,
        limit: sizeTable,
        orderBy: _.get(creditNoteData, 'orderBy'),
        orderType: _.get(creditNoteData, 'orderType'),
      };
      const response = await getCreditNoteBL(payload);

      console.log(response, 'creditNoteData2');

      setCreditNoteData((prev) => ({
        ...prev,
        creditNoteList: _.get(response, 'data.docs'),
        creditNoteTotal: _.get(response, 'data.total'),
      }));
    };

    setCreditNoteData((prev) => ({ ...prev, loading: false }));
    if (visible === true) {
      getApi();
      console.log(creditNoteData, 'creditNoteData3');
    }
  }, [
    visible,
    _.get(creditNoteData, 'paginationPage'),
    _.get(creditNoteData, 'paginationSize'),
    _.get(creditNoteData, 'orderBy'),
    _.get(creditNoteData, 'orderType'),
    _.get(creditNoteData, 'statusCode'),
    _.get(creditNoteData, 'customerName'),
    pageTable,
    sizeTable,
    event,
  ]);

  // useEffect(() => {
  //   if (visible === true) {
  //     handleSearchCustomer(_.get(dataForm, 'customerId'));
  //   }
  // }, [visible]);

  useEffect(() => {
    if (getFieldValue('customerName')) {
      const onChange = async (value, code) => {
        setReportDataPending({ ...reportDataPending, [code]: value });
      };
      onChange(getFieldValue('customerName'), 'customerName');
    }
  }, [getFieldValue('customerName')]);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: messageLabel('creditNoteColumnsCNNo', 'CN No.'),
      dataIndex: 'detail.creditNoteNo',
      key: 'detail.creditNoteNo',
      sorter: true,
      fixed: 'left',
      width: 150,
      ellipsis: true,
      render: (text, record, index) => _.get(record, "detail.creditNoteNo"),
    },
    {
      title: messageLabel('modalAddInvoiceColumnINVNo', 'INV No.'),
      dataIndex: 'detail.invoiceHeader.invoiceNo',
      key: 'detail.invoiceHeader.invoiceNo',
      sorter: true,
      render: (text, record, index) => _.get(record, "detail.invoiceHeader.invoiceNo"),
    },
    {
      title: messageLabel('creditNoteFormLabelINVDate', 'INV Date'),
      dataIndex: 'detail.invoiceHeader.issueDetail.issueDate',
      key: 'detail.invoiceHeader.issueDetail.issueDate',
      sorter: true,
      render: (text, record, index) => moment(_.get(record, "detail.invoiceHeader.issueDetail.issueDate"), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: messageLabel('creditNoteColumnsItem', 'Item'),
      dataIndex: 'detail.itemInvoice',
      key: 'detail.itemInvoice',
      sorter: true,
      render: (text, record, index) => _.size(_.get(record, 'detail.itemInvoice')),
    },
    {
      title: messageLabel('creditNoteColumnsTotal', 'Total'),
      dataIndex: 'detail.totalTxt',
      key: 'detail.totalTxt',
      sorter: true,
      render: (text, record, index) => _.get(record, "detail.totalTxt"),
    },
    {
      title: messageLabel('creditNoteColumnsIssuedBy', 'Issued By'),
      dataIndex: 'detail.issueDetail.issueByName',
      key: 'detail.issueDetail.issueByName',
      sorter: true,
      render: (text, record, index) => _.get(record, "detail.issueDetail.issueByName"),
    },
    {
      title: messageLabel('creditNoteColumnsIssuedDate', 'Issued Date'),
      dataIndex: 'detail.issueDetail.issueDate',
      key: 'detail.issueDetail.issueDate',
      sorter: true,
      render: (text, record, index) => _.get(record, "detail.issueDetail.issueDate") ? moment(_.get(record, "detail.issueDetail.issueDate"), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-',
    },
  ];

  const handleClose = () => {
    onCancel();
    setCreditNoteData({
      cnNo: '',
      customer: '',
      invNo: '',
      fromINV: '',
      toINV: '',
  
      creditNoteList: [],
      creditNoteTotal: 0,
      orderType: undefined,
      orderBy: undefined,
      page: 1,
      limit: 10,
      loading: false,
    });
    setSearchGroup();
  };

  const onSelectQuotationRow = async (value) => {
    let temp = [...selectItem];
    const formattedValue = {
      creditNoteId: _.get(value, "creditNoteId"),
      documentNo:_.get(value, "detail.creditNoteNo"),
      invoiceNo: _.get(value, "detail.creditNoteNo"),
      dueDateTxt: _.get(value, "detail.invoiceHeader.issueDetail.dueDate") ? moment(_.get(value, "detail.invoiceHeader.issueDetail.dueDate"), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-',
      issueDateTxt: _.get(value, "detail.issueDetail.issueDate") ? moment(_.get(value, "detail.issueDetail.issueDate"), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-',
      remark: _.get(value, "detail.remark"),
      totalNum: _.get(value, "detail.total"),
      totalTxt: _.get(value, "detail.totalTxt"),
      type: "creditnote"
    };
    temp.push(formattedValue);
    const mapselectitem = _.map(temp, (item, i) => {
      return {
        ...item,
        index: i + 1,
      };
    });

    setSelectItem(mapselectitem);
    // setSelectItem((prev) => [...prev, value]);
    onCancel();
  };

  const handleSave = async (value) => {
    // handleOpenModalQuotationPending(value);
    onCancel();
  };

  const handleReplace = (value) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblReplace', defaultMessage: 'Are you sure you want to replace ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        if (value) {
          console.log('saleselectvalue', value);
          // handleOpenModalQuotationPending(value);
          onCancel();
        }
      },
      onCancel() {},
    });
  };

  const handleChange = (value, key) => {
    setCreditNoteData((prev) => ({ ...prev, [key]: value }));
  };

  const onAllowClear = (value, code) => {
    if (value) {
      setCreditNoteData({ ...creditNoteData, [code]: '' });
    }
  };

  const onClick = () => {
    setEvent((prev) => !prev);
  };

  const handleFieldSort = (value) => {
    setCreditNoteData((prev) => ({ ...prev, orderBy: value }));
  };

  const handleOrderSort = (value) => {
    setCreditNoteData((prev) => ({ ...prev, orderType: value }));
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'BLAddCreditNote', defaultMessage: 'Add Credit Note' })}
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1120}
      zIndex={1000}
      centered={true}
      bodyStyle={{ height: 'calc(100vh - 150px)', overflowY: 'scroll' }}
      footer={[
        <Button_01 key="close" type="primary" btnsize="wd_df" onClick={() => handleClose()}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_01>,
      ]}
    >
      <Row>
        <Col span={6}>
          <div className="listItemTextHeader" style={{ padding: '0px 16px 16px 0px' }}>
            <FormattedMessage id="modalListItemFilterText" defaultMessage="Filter" />
          </div>
          <div className="listItemDividerCenter">
            <ListItemFilter onChange={handleChange} onAllowClear={onAllowClear} searchGroup={creditNoteData} onClick={onClick} />
          </div>
        </Col>
        <Col span={18}>
          <div className="listItemTextHeader" style={{ paddingTop: '0px' }}>
            <FormattedMessage id="BLAddCreditNoteTitle" defaultMessage="Credit Note" />
          </div>
          <div style={{ height: '625px', padding: '16px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <Form form={form} layout="vertical" className="po-form">
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })} req={false} />
                  }
                  style={{ width: '300px' }}
                >
                  {getFieldDecorator('customerName', {
                    initialValue: _.get(dataForm, 'customerId'),
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'invoiceLabelCustomerPlaceholder', defaultMessage: 'please select customer' }),
                      },
                    ],
                  })(
                    <Select
                      placeholder={intl.formatMessage({
                        id: 'purchesModalFormPlaceholderVendor',
                        defaultMessage: 'Select Vendor or Supplier',
                      })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      showSearch
                      style={{ width: '400px' }}
                      onSearch={handleSearchCustomer}
                    >
                      {customerList && customerList.map((item) => <Option key={item.customerId}>{item.customerName}</Option>)}
                    </Select>
                  )}
                </Form.Item>
              </Form>
            </Row>
            <CustomTableComponent
              columns={columns}
              dataSource={_.get(creditNoteData, 'creditNoteList')}
              total={_.get(creditNoteData, 'creditNoteTotal')}
              role={false}
              onRow={false}
              onRowClick={(o) => onSelectQuotationRow(o)}
              paginationPage={pageTable}
              setPaginationPage={setPageTable}
              paginationShow={true}
              setPaginationSize={setSizeTable}
              paginationSize={sizeTable}
              setFieldSort={handleFieldSort}
              setOrderSort={handleOrderSort}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

SelectCreditNoteModal.defaultProps = {
  // const intl = useIntl();
  // title: ,
  // title: intl.formatMessage({ id: 'quotationLabelSelectCustomer', defaultMessage: 'Select Customer' }),
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalSelectCreditNoteForm = Form.create({ name: 'select_credit_note_Form' })(SelectCreditNoteModal);

export default ModalSelectCreditNoteForm;
