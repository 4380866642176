import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Input, DatePicker, Select } from 'antd';
import CollapseCustom from '../../../components/collapse-custom';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import '../index.css';
import { CreditNoteContext } from '../credit-note-context';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import ActionColumnCN from './action-columns';

const { Option } = Select;
const { RangePicker } = DatePicker;

const CreditNoteAction = () => {
  const props = useContext(CreditNoteContext);
  const {
    setSearchGroup,
    setPaginationPage,
    searchGroup,
    columns,
    setShowColumn,
    newDataColumns,
    listArrayColumns,
    defaultShowColumn,
    newColumns,
    setNewDataColumns,
    setListArrayColumns,
    setDefaultShowColumn,
    textErrorSelectColumn,
    setTextErrorSelectColumn,
    vendorData,
    statusList,
    keyTabMonitor,
    visible,
    setVisible,
    setTrigger,
    actionColumns,
    optionsTeam,
    optionsIssueBy,
  } = props;
  const intl = useIntl();

  console.log('optionss', optionsTeam, optionsIssueBy, keyTabMonitor);

  const onChange = (value, code) => {
    setSearchGroup({ ...searchGroup, [code]: value });
    // setPaginationPage(1);
  };

  const onApply = () => {
    setPaginationPage(1);
    setTrigger((cur) => !cur);
  };

  const onReset = () => {
    setSearchGroup({ searchDate: _.get(searchGroup, 'searchDate') });
    setPaginationPage(1);
    setTrigger((cur) => !cur);
  };

  const formFilter = () => {
    return (
      <Form colon={false} className="po-form-filter">
        <>
          <Row gutter={[24, 2]}>
            {keyTabMonitor !== 'draft' && (
              <Col span={8}>
                <Form.Item
                  label={<LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormCNNo', defaultMessage: 'CN No.' })} req={false} />}
                >
                  <Input
                    allowClear
                    value={_.get(searchGroup, 'cnNo')}
                    onChange={(e) => onChange(e.target.value, 'cnNo')}
                    placeholder={intl.formatMessage({ id: 'creditNoteFormCNNoPlaceholder', defaultMessage: 'Enter CN No.' })}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormCustomer', defaultMessage: 'Customer' })} req={false} />
                }
              >
                <Input
                  allowClear
                  value={_.get(searchGroup, 'customer')}
                  onChange={(e) => onChange(e.target.value, 'customer')}
                  placeholder={intl.formatMessage({ id: 'creditNoteFormCustomerPlaceholder', defaultMessage: 'Enter Customer' })}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormINVNo', defaultMessage: 'INV No.' })} req={false} />}
              >
                <Input
                  allowClear
                  value={_.get(searchGroup, 'invNo')}
                  onChange={(e) => onChange(e.target.value, 'invNo')}
                  placeholder={intl.formatMessage({ id: 'creditNoteFormINVNoPlaceholder', defaultMessage: 'Enter INV No.' })}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormINVDate', defaultMessage: 'INV Date' })} req={false} />
                }
              >
                <RangePicker
                  allowClear
                  style={{ width: '100%' }}
                  value={_.get(searchGroup, 'invDate')}
                  format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                  onChange={(e) => onChange(e, 'invDate')}
                  placeholder={[
                    intl.formatMessage({ id: 'creditNoteFormINVDateFromDatePlaceholder', defaultMessage: 'Select Form Date' }),
                    intl.formatMessage({ id: 'creditNoteFormINVDateToDatePlaceholder', defaultMessage: 'Select To Date' }),
                  ]}
                />
              </Form.Item>
            </Col>

            {keyTabMonitor === 'approved' && (
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormBlNo', defaultMessage: ' BL No.' })} req={false} />
                  }
                >
                  <Input
                    allowClear
                    value={_.get(searchGroup, 'blNo')}
                    onChange={(e) => onChange(e.target.value, 'blNo')}
                    placeholder={intl.formatMessage({ id: 'creditNoteFormBlNoPlaceholder', defaultMessage: 'Enter BL No.' })}
                  />
                </Form.Item>
              </Col>
            )}

            {keyTabMonitor === 'approved' && (
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormBlDate', defaultMessage: 'BL Date' })} req={false} />
                  }
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    value={_.get(searchGroup, 'blDate')}
                    format={['DD/MM/YYYY', 'DD/MM/YYYY']}
                    onChange={(e) => onChange(e, 'blDate')}
                    placeholder={[
                      intl.formatMessage({ id: 'creditNoteFormINVDateFromDatePlaceholder', defaultMessage: 'Select Form Date' }),
                      intl.formatMessage({ id: 'creditNoteFormINVDateToDatePlaceholder', defaultMessage: 'Select To Date' }),
                    ]}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={8}>
              <Form.Item
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormIssuedby', defaultMessage: 'Issued By' })} req={false} />
                }
              >
                <Select
                  showSearch
                  allowClear={true}
                  onChange={(value) => onChange(value, 'issuedBy')}
                  filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                  value={_.get(searchGroup, 'issuedBy') || undefined}
                  placeholder={intl.formatMessage({ id: 'creditNoteFormIssuedbyPlaceholder', defaultMessage: 'Select Issued By' })}
                >
                  {_.map(optionsIssueBy, (item) => (
                    <Option value={item.memComId}>{item.fullname}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormTeam', defaultMessage: 'Team' })} req={false} />}
              >
                <Select
                  showSearch
                  allowClear={true}
                  onChange={(value) => onChange(value, 'team')}
                  filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
                  value={_.get(searchGroup, 'team') || undefined}
                  placeholder={intl.formatMessage({ id: 'creditNoteFormTeamPlaceholder', defaultMessage: 'Select Team' })}
                >
                  {_.map(optionsTeam, (item) => (
                    <Option key={item.name} value={item.org_id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {keyTabMonitor !== 'draft' && <Col span={8} />}
            <Col span={8} />

            <Col span={8}>
              <div style={{ textAlign: 'center', padding: '45px 0px 0px 0px', display: 'flex', justifyContent: 'end' }}>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={onReset}>
                  <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
                </Button_02>

                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={onApply}>
                  <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
                </Button_01>
              </div>
            </Col>
          </Row>
        </>
      </Form>
    );
  };

  return (
    <div style={{ padding: '12px 0px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            <RangePicker
              placeholder={[
                intl.formatMessage({ id: `customerFilterDateStartPH`, defaultMessage: 'Select Date' }),
                intl.formatMessage({ id: `customerFilterDateEndPH`, defaultMessage: 'Select Date' }),
              ]}
              onChange={(e) => onChange(e, 'searchDate')}
              value={_.get(searchGroup, 'searchDate')}
              format={'DD/MM/YYYY'}
              style={{ marginRight: '12px' }}
            />
            <ActionColumnCN
              columns={columns}
              setShowColumn={setShowColumn}
              newDataColumns={newDataColumns}
              listArrayColumns={listArrayColumns}
              defaultShowColumn={defaultShowColumn}
              newColumns={newColumns}
              setNewDataColumns={setNewDataColumns}
              setListArrayColumns={setListArrayColumns}
              setDefaultShowColumn={setDefaultShowColumn}
              textErrorSelectColumn={textErrorSelectColumn}
              setTextErrorSelectColumn={setTextErrorSelectColumn}
              visible={visible}
              setVisible={setVisible}
              columnMinimum={1}
              keyTabMonitor={keyTabMonitor}
            />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default CreditNoteAction;
