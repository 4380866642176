import React, { useState, useEffect } from 'react';
import './index.css';
import { Card, Table, Dropdown, Menu, Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import ReportItemPurchaseAction from './action';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDebounce } from '../../controllers/debounce';
import moment from 'moment';
import { successNotification, errorNotification } from '../../components/v2/notification';
import Button01 from '../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import { numberFormatNoDecimal } from '../../component-function/fnc-number-format-decimal';
import { getBrandData, getModelData } from '../../controllers/po/po';
import { exportReportItemPurchase, getMemberIssueBy, getReportItemPurchase } from '../../controllers/report/inventory-report/api';
import { getTeamOrg } from '../../controllers/team/team';
import ModalPDFViewverOptionalColumn from '../../component-function/pdf-viewver-optional-column';
import { UrlPDF } from '../../component-function/generator-pdf';
import { downloadPDFApi } from '../../controllers/pdf';

const dateFormat = 'DD/MM/YYYY';

const ItemPurchaseReport = () => {
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
      widthPdf: 40,
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsPoNo" defaultMessage="PO No." />,
      dataIndex: 'poNo',
      key: 'poNo',
      align: 'left',
      sorter: true,
      default: true,
      fixed: 'left',
      widthPdf: 30,
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsVendorOrSupplier" defaultMessage="Vendor or Supplier" />,
      dataIndex: 'customerName',
      key: 'customerName',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 40,
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsItemCode" defaultMessage="Item Code" />,
      dataIndex: 'itemCode',
      key: 'itemCode',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 19,
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsItem" defaultMessage="item" />,
      dataIndex: 'itemName',
      key: 'itemName',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 30,
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsStandardPrice" defaultMessage="Standard Price" />,
      dataIndex: 'standardPrice',
      key: 'standardPrice',
      sorter: true,
      default: false,
      align: 'right',
      widthPdf: 15,
      numFormat: 2,
      render: (text, record, index) => numberFormatter(record.standardPrice || 0) + ' THB',
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsPrice" defaultMessage="Price" />,
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      default: true,
      align: 'right',
      widthPdf: 19,
      numFormat: 2,
      render: (text, record, index) => numberFormatter(record.price || 0) + ' THB',
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsQty" defaultMessage="Qty" />,
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      default: true,
      align: 'right',
      widthPdf: 15,
      numFormat: 0,
      render: (text) => numberFormatNoDecimal(text || 0)
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsUnit" defaultMessage="Unit" />,
      dataIndex: 'unitName.TH',
      key: 'unitName.TH',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 15,
      render: (text, record, index) => _.get(record, 'unitName.EN'),
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsDiscount" defaultMessage="Discount" />,
      dataIndex: 'discount',
      key: 'discount',
      sorter: true,
      default: true,
      align: 'right',
      widthPdf: 23,
      numFormat: 2,
      render: (text, record, index) => numberFormatter(record.discount || 0) + ' THB',
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsAmount" defaultMessage="Amount" />,
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      sorter: true,
      default: true,
      widthPdf: 25,
      numFormat: 2,
      render: (text, record, index) => numberFormatter(record.amount || 0) + ' THB',
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsRefNo" defaultMessage="Ref No." />,
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      align: 'left',
      sorter: true,
      default: false,
      widthPdf: 25,
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsVehicle" defaultMessage="Vehicle" />,
      dataIndex: 'vehicle',
      key: 'vehicle',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 20,
      render: (text) => text || '-'
    },
    {
      title: <FormattedMessage id="reportPurchaseColumnsCreatedDate" defaultMessage="Created Date" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 25,
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsTeam" defaultMessage="Team" />,
      dataIndex: 'team',
      key: 'team',
      align: 'left',
      sorter: true,
      default: false,
      widthPdf: 40,
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsIssuedBy" defaultMessage="Issued By" />,
      dataIndex: 'issuedByName',
      key: 'issuedByName',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 35,
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsIssuedDate" defaultMessage="Issued Date" />,
      dataIndex: 'issueDate',
      key: 'issueDate',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 25,
    },

    {
      title: <FormattedMessage id="reportPurchaseColumnsRemark" defaultMessage="Remark" />,
      dataIndex: 'remark',
      key: 'remark',
      align: 'left',
      sorter: true,
      default: true,
      widthPdf: 20,
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => {
    return item.dataIndex;
  });
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(
    _.filter(columns, (item) => {
      return item.default === false;
    }),
    (item) => {
      return item.dataIndex;
    }
  );
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [filteredIndexColumns, setFilteredIndexColumns] = useState(columns.filter((column) => column.key !== 'index'));
  const [search, setSearch] = useState({ searchDate: [moment().startOf('month'), moment().endOf('month')] });
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2;
  const columnIndex = 10;
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [urlPdf, setUrlPdf] = useState(null);
  const [listData, setListData] = useState([]);
  const [refTypeList, setRefTypeList] = useState([]);
  const [summary, setSummary] = useState([]);
  const searchDebounce = useDebounce(search, 500);

  const [optionsBrand, setOptionsBrand] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandSearch, setBrandSearch] = useState();
  const debounceBrandSearch = useDebounce(brandSearch, 500);

  const [optionsModel, setOptionsModel] = useState([]);
  const [loadingModel, setLoadingModel] = useState(false);
  const [modelSearch, setModelSearch] = useState();
  const debounceModelSearch = useDebounce(modelSearch, 500);
  const [isFetch, setIsFetch] = useState(false);

  const [optionsTeam, setOptionsTeam] = useState([]);
  const [optionsIssueBy, setOptionsIssueBy] = useState([]);

  global['setStringSearchMongo'] = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  console.log('optionsIssueBy', optionsIssueBy);

  console.log('columns', columns);
  console.log('filteredIndexColumns', filteredIndexColumns);

  useEffect(() => {
    const infoData = async () => {
      const getTeam = await getTeamOrg();
      setOptionsTeam(_.get(getTeam, 'data'));

      const payloadIssueBy = {};
      const getIssueBy = await getMemberIssueBy(payloadIssueBy);
      setOptionsIssueBy(getIssueBy || []);
    };

    infoData();
  }, []);

  useEffect(() => {
    console.log('search', search);
    const getListDate = async () => {
      setLoading(true);
      const body = {
        where: {
          issueDate:
            _.get(search, 'searchDate') && _.size(search.searchDate)
              ? {
                  startDate: moment(_.get(search, 'searchDate[0]')).format('YYYY-MM-DD'),
                  endDate: moment(_.get(search, 'searchDate[1]')).format('YYYY-MM-DD'),
                }
              : undefined,
          poNo: _.get(search, 'poNo') || undefined,
          customerName: _.get(search, 'vendorOrSupplier') || undefined,
          $or: _.get(search, 'item')
            ? [
                { itemCode: { $regex: global['setStringSearchMongo'](_.get(search, 'item')), $options: 'i' } || undefined },
                { itemName: { $regex: global['setStringSearchMongo'](_.get(search, 'item')), $options: 'i' } || undefined },
              ]
            : undefined,
          // "itemName": _.get(search, 'item') || undefined,
          itemBrandId: _.size(_.get(search, 'brand')) ? _.get(search, 'brand') : undefined,
          itemModelId: _.size(_.get(search, 'brand')) && _.size(_.get(search, 'model')) ? _.get(search, 'model') : undefined,
          referenceNo: _.get(search, 'referenceNo') || undefined,

          orgIdOwner: _.get(search, 'team') ? [_.toString(_.get(search, 'team'))] : undefined,
          issuedByName: _.get(search, 'issuedBy') || undefined,
          createdAt:
            _.get(search, 'createdAt') && _.size(search.createdAt)
              ? {
                  startDate: moment(_.get(search, 'createdAt[0]')).format('YYYY-MM-DD'),
                  endDate: moment(_.get(search, 'createdAt[1]')).format('YYYY-MM-DD'),
                }
              : undefined,
        },

        page: page,
        pageSize: size,
        orderBy: sortField,
        order: sortOrder,
      };
      const response = await getReportItemPurchase(body);
      setListData(_.get(response, 'data.list'));
      setTotal(_.get(response, 'data.total'));
      setRefTypeList(_.get(response, 'data.initialData'));
      setSummary(_.get(response, 'data.summary'));
      setLoading(false);
    };
    getListDate();
  }, [page, size, sortOrder, sortField, isFetch, _.get(search, 'searchDate')]);

  useEffect(() => {
    const fetcBrand = async () => {
      setLoadingBrand(true);
      const body = {
        indexStart: 0,
        indexEnd: 10,
        item_brand_name: brandSearch || '',
      };
      const response = await getBrandData(body);
      setOptionsBrand(_.get(response, 'data.data.list'));
      setLoadingBrand(false);
    };
    fetcBrand(); // โหลดข้อมูลเริ่มต้น
  }, [debounceBrandSearch]);

  useEffect(() => {
    const fetcModel = async () => {
      setLoadingModel(true);
      const body = {
        indexStart: 0,
        indexEnd: 10,
        item_model_name: modelSearch || '',
        item_brand_id: _.get(search, 'brand') || [],
      };
      const response = await getModelData(body);
      setOptionsModel(_.get(response, 'data.data.list'));
      setLoadingModel(false);
    };
    if (_.get(search, 'brand')) {
      fetcModel(); // โหลดข้อมูลเริ่มต้น
    }
  }, [debounceModelSearch, search]);

  const onFilterOrder = async (value, code) => {
    console.log('onFilterOrder', value, code);
    if (code === 'brand' && _.size(value)) {
      setSearch({ ...search, [code]: value, model: [] });
    } else {
      setSearch({ ...search, [code]: value });
    }
    // setPage(1)
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        where: {
          issueDate:
            _.get(search, 'searchDate') && _.size(search.searchDate)
              ? {
                  startDate: moment(_.get(search, 'searchDate[0]')).format('YYYY-MM-DD'),
                  endDate: moment(_.get(search, 'searchDate[1]')).format('YYYY-MM-DD'),
                }
              : undefined,
          poNo: _.get(search, 'poNo') || undefined,
          customerName: _.get(search, 'vendorOrSupplier') || undefined,
          itemName: _.get(search, 'item') || undefined,
          itemBrandId: _.size(_.get(search, 'brand')) ? _.get(search, 'brand') : undefined,
          itemModelId: _.size(_.get(search, 'brand')) && _.size(_.get(search, 'model')) ? _.get(search, 'model') : undefined,
          referenceNo: _.get(search, 'referenceNo') || undefined,

          orgIdOwner: _.get(search, 'team') ? [_.toString(_.get(search, 'team'))] : undefined,
          issuedByName: _.get(search, 'issuedBy') || undefined,
          createdAt:
            _.get(search, 'createdAt') && _.size(search.createdAt)
              ? {
                  startDate: moment(_.get(search, 'createdAt[0]')).format('YYYY-MM-DD'),
                  endDate: moment(_.get(search, 'createdAt[1]')).format('YYYY-MM-DD'),
                }
              : undefined,
        },
        page: 1,
        pageSize: total,
        orderBy: sortField,
        order: sortOrder,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsPoNo', defaultMessage: 'PO No.' }),
            sequence: 1,
            colCode: 'poNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsVendorOrSupplier', defaultMessage: 'Vendor or Supplier' }),
            sequence: 2,
            colCode: 'customerName',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsItemCode', defaultMessage: 'Item Code' }),
            sequence: 3,
            colCode: 'itemCode',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsItem', defaultMessage: 'Item' }),
            sequence: 4,
            colCode: 'itemName',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsStandardPrice', defaultMessage: 'Standard Price' }),
            sequence: 5,
            colCode: 'standardPrice',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsPrice', defaultMessage: 'Price' }),
            sequence: 6,
            colCode: 'price',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsQty', defaultMessage: 'Qty' }),
            sequence: 7,
            colCode: 'qty',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsUnit', defaultMessage: 'Unit' }),
            sequence: 8,
            colCode: 'unitName.EN',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsDiscount', defaultMessage: 'Discount' }),
            sequence: 9,
            colCode: 'discount',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsAmount', defaultMessage: 'Amount' }),
            sequence: 10,
            colCode: 'amount',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsRefNo', defaultMessage: 'Ref No.' }),
            sequence: 11,
            colCode: 'referenceNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsVehicle', defaultMessage: 'Vehicle' }),
            sequence: 12,
            colCode: 'vehicle',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsCreatedDate', defaultMessage: 'Created Date' }),
            sequence: 13,
            colCode: 'createdAt',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsTeam', defaultMessage: 'Team' }),
            sequence: 14,
            colCode: 'team',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsIssuedBy', defaultMessage: 'Issued by' }),
            sequence: 15,
            colCode: 'issuedByName',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsIssuedDate', defaultMessage: 'Issued Date' }),
            sequence: 16,
            colCode: 'issueDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportPurchaseColumnsRemark', defaultMessage: 'Remark' }),
            sequence: 17,
            colCode: 'remark',
          },
        ],
      };
      const response = await exportReportItemPurchase(body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {}
  };

  //เรียก  pdf ครั้งแรก
  useEffect(() => {
    setLoadingPdf(true);

    const getApi = async () => {
      const mapColumnChecked = _.chain(filteredIndexColumns)
        .filter((o) => o.default !== false)
        .map((o) => o.dataIndex)
        .value();

      let mapColumnCheckedAdd = [...mapColumnChecked];

      const printColumnOptions = _.chain(filteredIndexColumns)
        .filter((o) => o.default !== false)
        .map((o, index) => ({
          columnHeaderName: intl.formatMessage({ id: o.title?.props?.id, defaultMessage: o.title?.props?.defaultMessage }) || o.title,
          columnWidth: o.widthPdf,
          columnMapping: o.dataIndex,
          columnAlign: o.align,
          columnNumberFormat: o.numFormat,
        }))
        .value();

      const body = {
        filter: {
          where: {
            issueDate:
              _.get(search, 'searchDate') && _.size(search.searchDate)
                ? {
                    startDate: moment(_.get(search, 'searchDate[0]')).format('YYYY-MM-DD'),
                    endDate: moment(_.get(search, 'searchDate[1]')).format('YYYY-MM-DD'),
                  }
                : undefined,
            poNo: _.get(search, 'poNo') || undefined,
            customerName: _.get(search, 'vendorOrSupplier') || undefined,
            $or: _.get(search, 'item')
              ? [
                  { itemCode: { $regex: global['setStringSearchMongo'](_.get(search, 'item')), $options: 'i' } || undefined },
                  { itemName: { $regex: global['setStringSearchMongo'](_.get(search, 'item')), $options: 'i' } || undefined },
                ]
              : undefined,
            itemBrandId: _.size(_.get(search, 'brand')) ? _.get(search, 'brand') : undefined,
            itemModelId: _.size(_.get(search, 'brand')) && _.size(_.get(search, 'model')) ? _.get(search, 'model') : undefined,
            referenceNo: _.get(search, 'referenceNo') || undefined,

            orgIdOwner: _.get(search, 'team') ? [_.toString(_.get(search, 'team'))] : undefined,
            issuedByName: _.get(search, 'issuedBy') || undefined,
            createdAt:
              _.get(search, 'createdAt') && _.size(search.createdAt)
                ? {
                    startDate: moment(_.get(search, 'createdAt[0]')).format('YYYY-MM-DD'),
                    endDate: moment(_.get(search, 'createdAt[1]')).format('YYYY-MM-DD'),
                  }
                : undefined,
          },
          page: 1,
          pageSize: 10000,
          orderBy: sortField,
          order: sortOrder,
        },
        printTableOptions: {tableName: 'รายงานของที่ซื้อตามรายการ'},
        printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
        printColumnOptions: _.concat([{ columnHeaderName: '#', columnWidth: columnIndex , columnMapping: 'index', columnAlign: 'center'}], printColumnOptions),
      };

      console.log('handleApply body', body);

      const response = await downloadPDFApi({ tableCode: 'sourcingReportItemPurchase', body: body });
      console.log('handleApply response', response);

      if (_.get(response, 'status') === 200) {
        const url = await UrlPDF(response);
        console.log('handleApply url', url);

        if (url) {
          setUrlPdf(url);
          setLoadingPdf(false);
        }
      } else {
        setUrlPdf(null);
        setLoadingPdf(false);
      }
    };

    if (openPdf) {
      getApi();
    }
  }, [openPdf]);

  //เปิด pdf
  const handlePrintPdf = () => {
    setOpenPdf(true);
  };

  //apply column pdf
  const handleApply = async (columnChecked) => {
    setLoadingPdf(true);
    const mapColumnChecked = _.chain(columnChecked)
      .filter((o) => o.default !== false)
      .map((o) => o.dataIndex)
      .value();

    let mapColumnCheckedAdd = [...mapColumnChecked];

    const printColumnOptions = _.chain(columnChecked)
      .filter((o) => o.default !== false)
      .map((o, index) => ({
        columnHeaderName: intl.formatMessage({ id: o.title?.props?.id, defaultMessage: o.title?.props?.defaultMessage }) || o.title,
        columnWidth: o.widthPdf,
        columnMapping: o.dataIndex,
        columnAlign: o.align,
        columnNumberFormat: o.numFormat,
      }))
      .value();

    const body = {
      filter: {
        where: {
          issueDate:
            _.get(search, 'searchDate') && _.size(search.searchDate)
              ? {
                  startDate: moment(_.get(search, 'searchDate[0]')).format('YYYY-MM-DD'),
                  endDate: moment(_.get(search, 'searchDate[1]')).format('YYYY-MM-DD'),
                }
              : undefined,
          poNo: _.get(search, 'poNo') || undefined,
          customerName: _.get(search, 'vendorOrSupplier') || undefined,
          $or: _.get(search, 'item')
            ? [
                { itemCode: { $regex: global['setStringSearchMongo'](_.get(search, 'item')), $options: 'i' } || undefined },
                { itemName: { $regex: global['setStringSearchMongo'](_.get(search, 'item')), $options: 'i' } || undefined },
              ]
            : undefined,
          itemBrandId: _.size(_.get(search, 'brand')) ? _.get(search, 'brand') : undefined,
          itemModelId: _.size(_.get(search, 'brand')) && _.size(_.get(search, 'model')) ? _.get(search, 'model') : undefined,
          referenceNo: _.get(search, 'referenceNo') || undefined,

          orgIdOwner: _.get(search, 'team') ? [_.toString(_.get(search, 'team'))] : undefined,
          issuedByName: _.get(search, 'issuedBy') || undefined,
          createdAt:
            _.get(search, 'createdAt') && _.size(search.createdAt)
              ? {
                  startDate: moment(_.get(search, 'createdAt[0]')).format('YYYY-MM-DD'),
                  endDate: moment(_.get(search, 'createdAt[1]')).format('YYYY-MM-DD'),
                }
              : undefined,
        },
        page: 1,
        pageSize: 10000,
        orderBy: sortField,
        order: sortOrder,
      },
      printTableOptions: {tableName: 'รายงานของที่ซื้อตามรายการ'},
      printColumnCodes: _.concat(['index'], mapColumnCheckedAdd),
      printColumnOptions: _.concat( [{columnHeaderName: '#', columnWidth: columnIndex, columnMapping: 'index', columnAlign: 'center'}], printColumnOptions),
    };

    console.log('handleApply body', body);

    const response = await downloadPDFApi({ tableCode: 'sourcingReportItemPurchase', body: body });
    console.log('handleApply response', response);

    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      console.log('handleApply url', url);

      if (url) {
        setUrlPdf(url);
        setLoadingPdf(false);
      }
    } else {
      setUrlPdf(null);
      setLoadingPdf(false);
    }
  };

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={() => handlePrintPdf()}>
          <FormattedMessage id="btnPrintPDF" defaultMessage="Print (PDF)" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card
        className="reportItemPurchaseCard"
        title={intl.formatMessage({ id: 'menuItemPurchaseReport', defaultMessage: 'Item Purchase Report' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
        extra={
          <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
            <Button01 key="submit" type="primary" btnsize="wd_df">
              <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
            </Button01>
          </Dropdown>
        }
      >
        <Row style={{ padding: '32px 24px 16px 24px' }}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <div className="reportItemSalesTextHead">
              <FormattedMessage id="menuReportPurchaseTotalItem" defaultMessage="Total Item" />
            </div>
            <div className="reportItemSalesTextValue">
              {numberFormatNoDecimal(_.get(summary, 'totalItem') || 0)} {_.get(summary, 'totalItem') > 1 ? intl.formatMessage({ id: 'billingItems', defaultMessage: 'Items' }) : intl.formatMessage({ id: 'reportItemRequestColumnsItem', defaultMessage: 'Item' })}
            </div>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <div className="reportItemSalesTextHead">
              <FormattedMessage id="menuReportPurchaseTotalPurchase" defaultMessage="Total Purchase" />
            </div>
            <div className="reportItemSalesTextValue">{numberFormatter(_.get(summary, 'totalPurchase') || 0)} THB</div>
          </Col>

          <Col span={8} style={{ textAlign: 'center' }}>
            <div className="reportItemSalesTextHead">
              <FormattedMessage id="menuReportPurchaseTotalDiscount" defaultMessage="Total Discount" />
            </div>
            <div className="reportItemSalesTextValue">{numberFormatter(_.get(summary, 'totolDisCount') || 0)} THB</div>
          </Col>
        </Row>
        <ReportItemPurchaseAction
          search={search}
          setSearch={setSearch}
          onFilterOrder={onFilterOrder}
          refTypeList={refTypeList}
          setPage={setPage}
          actionColumns={{
            columns,
            setShowColumn,
            newDataColumns,
            setNewDataColumns,
            setListArrayColumns,
            setDefaultShowColumn,
            listArrayColumns,
            defaultShowColumn,
            newColumns,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum,
            filteredIndexColumns,
            setFilteredIndexColumns,
          }}
          brand={{
            optionsBrand,
            loadingBrand,
            brandSearch,
            setBrandSearch,
          }}
          model={{
            optionsModel,
            loadingModel,
            modelSearch,
            setModelSearch,
          }}
          team={{
            optionsTeam,
          }}
          issueBy={{
            optionsIssueBy,
          }}
          setIsFetch={setIsFetch}
        />
        <div style={{ padding: '16px 24px' }}>
          <Table
            dataSource={listData}
            columns={newDataColumns}
            onChange={handleChange}
            loading={loading}
            scroll={{ x: true }}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              current: page,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
            }}
          />
          {_.size(listData) > 0 ? (
            <div className="total-items-member">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </div>
      </Card>
      <ModalPDFViewverOptionalColumn
        paperSize={274}
        paperIndexSize={columnIndex}
        paperUnit={intl.formatMessage({ id: 'unitCm', defaultMessage: 'cm.' })}
        visible={openPdf}
        url={urlPdf}
        loading={loadingPdf}
        setVisible={setOpenPdf}
        handleApply={handleApply}
        column={filteredIndexColumns} //actionColumn
      />
    </div>
  );
};

export default ItemPurchaseReport;
