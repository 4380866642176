import React, { useContext, useEffect, useState } from 'react';
import DoughnutRevenue from './doughnut-revenue';
import SLA from './sla';
import LaborHR from './labor-hr';
import LaborKG from './labor-kg';
import { Divider, Spin } from 'antd';
import FilterOnTopTab from '../component/filter-op-top-tab';
import { FormattedMessage, useIntl } from 'react-intl';
import TableRevenueLeastArea from '../component/table-net-least-area';
import { OverviewContext } from '../../context';
import _ from 'lodash';
import { messageLabel } from '../../../../components/message-component';

const TabServiceOverview = () => {
  const { state, setState, func } = useContext(OverviewContext);

  const {
    revenueList,
    selectMarketRevenue,
    marketAllList,
    slaData,
    vechicleData,
    selectVechiecle,
    laborHRData,
    selectLaborHR,
    laborKGData,
    revenueListLoading,
    slaDataLoading,
    vechicleDataLoading,
    laborHRDataLoading,
    laborKGDataLoading,
  } = state;
  const {
    setSelectMarkRevenue,
    setSelectVechiecle,
    setSelectLaborHR,
    setRevenueChartDate,
    setSlaDataDate,
    setVechicleDate,
    setLaborHRDate,
    setLaborKGDate,
  } = setState;
  const intl = useIntl();
  const [charDataRevenue, setChartDataRevenue] = useState([]);
  const [charDataSla, setChartDataSla] = useState([]);
  const [charDataLaborHR, setChartDataLaborHR] = useState([]);
  const [charDataLaborKG, setChartDataLaborKG] = useState([]);
  const aligntextleft = 'left';
  const aligntextRight = 'right';
  const widthChildColumn = 105;
  const dividerService = (
    <div style={{ padding: '24px 24px 0px' }}>
      <Divider style={{ marginBottom: '0px' }} />
    </div>
  );

  const styleTitle = {
    alignItems: 'center',
    padding: '24px 24px 0px 24px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    top: 0,
    width: '100%',
    zIndex: 1,
    fontWeight: 'bold',
    color: '#1D3557',
  };

  const formatTime = (index) => {
    const hours = String(index).padStart(2, '0'); // เติม 0 ด้านหน้าหากเลขหลักเดียว
    return `${hours}:00`; // รูปแบบ HH:mm
  };

  const dynamicColumns = Array.from({ length: 25 }, (_a, i) => ({
    title: formatTime(i), // ใช้ตัวเลขเป็น Title
    children: [
      {
        title: messageLabel('TaecoServiceCap ', 'Cap.'),
        dataIndex: `${i}.cap`,
        key: `${i}.cap`,
        align: 'center',
        width: widthChildColumn,
        sorter: (a, b) => a.i.cap - b.i.cap,
        render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, `${i}.cap`)}</div>,
        //_.get(record,'1.cap')
      },
      {
        title: messageLabel('TaecoServiceProd', 'Prod.'),
        dataIndex: `${i}.prod`,
        key: `${i}.prod`,
        align: 'center',
        width: widthChildColumn,
        sorter: (a, b) => a.i.prod - b.i.prod,
        render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, `${i}.prod`)}</div>,
      },
    ],
  }));

  const columnSLA = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('RevenueMarket', 'Market'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.name}</div>,
    },
    {
      title: messageLabel('TaecoServiceUnderFive', 'Under 5 min.'),
      children: [
        {
          title: messageLabel('TaecoServiceTransaction ', 'Transaction (Ticket)'),
          dataIndex: 'under5.transaction',
          key: 'under5.transaction',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.under5.transaction - b.under5.transaction,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.under5.transaction}</div>,
        },
        {
          title: messageLabel('TaecoServicePercentage', 'Percentage'),
          dataIndex: 'under5.percentage',
          key: 'under5.percentage',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.under5.percentage - b.under5.percentage,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.under5.percentage}</div>,
        },
      ],
    },
    {
      title: messageLabel('TaecoServiceUnderTwenty', 'Under 20 min.'),
      children: [
        {
          title: messageLabel('TaecoServiceTransaction ', 'Transaction (Ticket)'),
          dataIndex: 'under20.transaction',
          key: 'under20.transaction',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.under20.transaction - b.under20.transaction,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.under20.transaction}</div>,
        },
        {
          title: messageLabel('TaecoServicePercentage', 'Percentage'),
          dataIndex: 'under20.percentage',
          key: 'under20.percentage',
          align: 'center',
          sorter: (a, b) => a.under20.transaction - b.under20.transaction,
          width: widthChildColumn,

          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.under20.percentage}</div>,
        },
      ],
    },
    {
      title: messageLabel('TaecoServiceThanTwenty', 'More than 20 min.'),
      children: [
        {
          title: messageLabel('TaecoServiceTransaction ', 'Transaction (Ticket)'),
          dataIndex: 'morethan20.transaction',
          key: 'morethan20.transaction',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.morethan20.transaction - b.morethan20.transaction,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.morethan20.transaction}</div>,
        },
        {
          title: messageLabel('TaecoServicePercentage', 'Percentage'),
          dataIndex: 'morethan20.percentage',
          key: 'morethan20.percentage',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.morethan20.percentage - b.morethan20.percentage,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.morethan20.percentage}</div>,
        },
      ],
    },
  ];

  const columnVehicle = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('TaecoServiceVehicle', 'Vehicle'),
      dataIndex: 'vehicle',
      key: 'vehicle',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.vehicle.length - b.vehicle.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.vehicle}</div>,
    },
    {
      title: messageLabel('TaecoServiceNumberofVehicle', 'Number of Vehicle'),
      dataIndex: 'numberVehicle',
      key: 'numberVehicle',
      align: 'center',
      width: widthChildColumn,
      sorter: (a, b) => a.numberVehicle - b.numberVehicle,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.numberVehicle}</div>,
    },
    {
      title: messageLabel('TaecoServiceAverageUnit', 'Average Unit'),
      dataIndex: 'averageUnit',
      key: 'averageUnit',
      align: 'center',
      width: widthChildColumn,
      sorter: (a, b) => a.averageUnit - b.averageUnit,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.averageUnit}</div>,
    },
    {
      title: messageLabel('TaecoServiceAveragekg.', 'Average kg.'),
      dataIndex: 'averageKg',
      key: 'averageKg',
      align: 'center',
      width: widthChildColumn,
      sorter: (a, b) => a.averageKg - b.averageKg,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.averageKg}</div>,
    },
    {
      title: messageLabel('TaecoServiceAverageServiceTime(min.).', 'Average Service Time (min.)'),
      dataIndex: 'averageServiceTime',
      key: 'averageServiceTime',
      align: 'center',
      width: widthChildColumn,
      sorter: (a, b) => a.averageServiceTime - b.averageServiceTime,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.averageServiceTime}</div>,
    },
  ];

  const columnLaborHR = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('RevenueMarket', 'Market'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.name}</div>,
    },
    {
      title: messageLabel('TaecoServiceProductivity', 'Productivity (%)'),
      dataIndex: 'productivity',
      key: 'productivity',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.productivity.length - b.productivity.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.productivity}</div>,
    },
    ...dynamicColumns,
  ];

  const columnLaborKG = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('RevenueMarket', 'Market'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{record.name}</div>,
    },
    {
      title: messageLabel('TaecoServiceTransaction ', 'Transaction'),
      children: [
        {
          title: messageLabel('TaecoServiceTicket ', 'Ticket'),
          dataIndex: 'transaction.ticket',
          key: 'transaction.ticket',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.transaction.ticket - b.transaction.ticket,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.transaction.ticket}</div>,
        },
        {
          title: messageLabel('TaecoServicePercentage', 'Percentage'),
          dataIndex: 'transaction.percentage',
          key: 'transaction.percentage',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.transaction.percentage - b.transaction.percentage,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.transaction.percentage}</div>,
        },
      ],
    },
    {
      title: messageLabel('RevenueWorker', 'Worker'),
      dataIndex: 'woker',
      key: 'woker',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.woker - b.woker,
      render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record,'woker')}</div>,
    },
    {
      title: messageLabel('TaecoServiceKilogram', 'Kilogram'),
      children: [
        {
          title: messageLabel('TaecoServiceKilogram', 'Kilogram'),
          dataIndex: 'kilogram.ticket',
          key: 'kilogram.ticket',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.kilogram.ticket - b.kilogram.ticket,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.kilogram.ticket}</div>,
        },
        {
          title: messageLabel('TaecoServicePercentage', 'Percentage'),
          dataIndex: 'kilogram.percentage',
          key: 'kilogram.percentage',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.kilogram.percentage - b.kilogram.percentage,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.kilogram.percentage}</div>,
        },
      ],
    },
    {
      title: messageLabel('TaecoServiceRevenue', 'Revenue'),
      children: [
        {
          title: messageLabel('TaecoServiceThaiBaht', 'Thai Baht (THB)'),
          dataIndex: 'revenue.ticket',
          key: 'revenue.ticket',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.revenue.ticket - b.revenue.ticket,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.revenue.ticket}</div>,
        },
        {
          title: messageLabel('TaecoServicePercentage', 'Percentage'),
          dataIndex: 'revenue.percentage',
          key: 'revenue.percentage',
          align: 'center',
          width: widthChildColumn,
          sorter: (a, b) => a.revenue.percentage - b.revenue.percentage,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{record.revenue.percentage}</div>,
        },
      ],
    },
  ];

  const changeToNumber = (value) => {
    const number = _.chain(value)
      .replace(/,/g, '')
      .toNumber()
      .value();
    return number;
  };

  useEffect(() => {
    const mapDataArea = async () => {
      const getColor = (index) => {
        const colors = ['#0CA150', '#6BBB6E', '#519DE9', '#0066CC', '#B2B0EA', '#EC7A08', '#EF9234', '#C8190B'];
        return colors[index % colors.length];
      };

      const map = _.map(revenueList, (i,index) => {
        return {
          name: _.get(i, 'name'),
          value: changeToNumber(_.get(i, 'revenue')),
          percentage: changeToNumber(_.get(i, 'percentage')),
          color: getColor(index),
        };
      });

      setChartDataRevenue(map);
    };

    if (_.size(revenueList) > 0) {
      mapDataArea();
    }
  }, [revenueList]);

  useEffect(() => {
    const mapDatasla = async () => {
      const mapData = _.map(slaData, (i) => {
        const less5 = changeToNumber(_.get(i, 'under5.transaction'));

        const less20 = changeToNumber(_.get(i, 'under20.transaction'));

        const more20 = changeToNumber(_.get(i, 'morethan20.transaction'));

        return {
          name: _.get(i, 'name'),
          less5: less5,
          less20: less20,
          more20: more20,
          scatter: (less5 + less20 + more20) * 0.9,
        };
      });
      setChartDataSla(mapData);
    };
    if (_.size(slaData) > 0) {
      mapDatasla();
    }
  }, [slaData]);

  useEffect(() => {
    const mapDatasla = async () => {
      const mapData = _.map(slaData, (i) => {
        const less5 = changeToNumber(_.get(i, 'under5.transaction'));

        const less20 = changeToNumber(_.get(i, 'under20.transaction'));

        const more20 = changeToNumber(_.get(i, 'morethan20.transaction'));

        return {
          name: _.get(i, 'name'),
          less5: less5,
          less20: less20,
          more20: more20,
          scatter: (less5 + less20 + more20) * 0.9,
        };
      });
      setChartDataSla(mapData);
    };
    if (_.size(slaData) > 0) {
      mapDatasla();
    }
  }, [slaData]);

  useEffect(() => {
    const mapDataLaborHR = async () => {
      const testsum = _.sumBy(laborHRData, `1.cap`);
      const chartData = Array.from({ length: 24 }, (_i, hour) => {
        const capacity = _.sumBy(laborHRData, `${hour}.cap`);
        const productivity = _.sumBy(laborHRData, `${hour}.prod`);
        return {
          name: `${String(hour).padStart(2, '0')}:00`,
          capacity,
          productivity,
        };
      });

      setChartDataLaborHR(chartData);
    };
    if (_.size(laborHRData) > 0) {
      mapDataLaborHR();
    }
  }, [laborHRData]);

  useEffect(() => {
    const mapDataLaborKG = async () => {
      const mapData = _.map(laborKGData, (i) => {
        return {
          name: _.get(i, 'name'),
          bar: _.get(i, 'revenue.ticket'),
          line: _.get(i, 'kilogram.ticket'),
        };
      });

      setChartDataLaborKG(mapData);
    };
    if (_.size(laborHRData) > 0) {
      mapDataLaborKG();
    }
  }, [laborKGData]);

  return (
    <div style={{ backgroundColor: '#FFFF' }}>
      {/* Doughnut  */}
      <Spin spinning={revenueListLoading}>
        <div style={{ marginBottom: '20px' }}>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'TaecoServiceRevenue', defaultMessage: 'Revenue' })}
            mutiSelect={false}
            date={true}
            setDate={setRevenueChartDate}
          />
        </div>
        <div>
          <DoughnutRevenue chartData={charDataRevenue} />
        </div>
      </Spin>
      {dividerService}

      {/* SLA  */}
      <Spin spinning={slaDataLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'TaecoServiceSLA', defaultMessage: 'SLA' })}
            mutiSelect={false}
            date={true}
            setDate={setSlaDataDate}
          />
        </div>
        <SLA chartData={charDataSla} />
        <div>
          <TableRevenueLeastArea columns={columnSLA} data={slaData} total={_.size(slaData)} />
        </div>
      </Spin>
      {dividerService}
      <Spin spinning={vechicleDataLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'TaecoServiceVehicle', defaultMessage: 'Vehicle' })}
            mutiSelect={true}
            sizeSelect={4}
            marketAllList={marketAllList}
            selectMarket={selectVechiecle}
            setSelectMarket={setSelectVechiecle}
            date={true}
            setDate={setVechicleDate}
          />
        </div>

        <div>
          <TableRevenueLeastArea columns={columnVehicle} data={vechicleData} total={_.size(vechicleData)} />
        </div>
      </Spin>
      {dividerService}
      <Spin spinning={laborHRDataLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'TaecoServiceLaborProductivityHR', defaultMessage: 'Labor Productivity (hr.)' })}
            mutiSelect={true}
            sizeSelect={4}
            marketAllList={marketAllList}
            selectMarket={selectLaborHR}
            setSelectMarket={setSelectLaborHR}
            date={true}
            setDate={setLaborHRDate}
          />
        </div>
        <LaborHR chartData={charDataLaborHR} />
        <div>
          <TableRevenueLeastArea columns={columnLaborHR} data={laborHRData} total={_.size(laborHRData)} />
        </div>
      </Spin>
      {dividerService}
      <Spin spinning={laborKGDataLoading}>
        <div>
          <FilterOnTopTab
            title={intl.formatMessage({ id: 'TaecoServiceLaborProductivityKG', defaultMessage: 'Labor Productivity (kg.)' })}
            mutiSelect={false}
            date={true}
            setDate={setLaborKGDate}
          />
        </div>
        <LaborKG chartData={charDataLaborKG} />
        <div>
          <TableRevenueLeastArea columns={columnLaborKG} data={laborKGData} total={_.size(laborKGData)} />
        </div>
      </Spin>
      {dividerService}
      <div style={{ marginTop: '24px' }}></div>
    </div>
  );
};

export default TabServiceOverview;
