import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  findAreaUtilization,
  findAreaUtilizationMarketGroup,
  findOccrateAndArr,
  findRevenue,
  findRevenueAndNet,
  findServiceLaborHR,
  findServiceLaborKG,
  findServicesla,
  findServiceVehicle,
  getMarketAll,
  getMarketAllGroup,
  getTeacoMarket,
  getTotalMarket,
  getTotalTeacoMarketHeader,
} from '../../controllers/taeco-overview-api/taeco-overview-api';
import { select } from 'glamor';

const OverviewContext = createContext();

const OverviewProvider = ({ children }) => {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('map');
  const [filterDate, setFilterDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });
  const [filterDateTab, setfilterDateTab] = useState('today');
  const [filterDateDuration, setFilterDateDuration] = useState([]);
  const [dataBucket, setDataBucket] = useState();

  const [mapItemDashboard, setMapItemDashboard] = useState([]);
  const [mapSelectFilter, setMapSelectFilter] = useState(['contributionRate', 'availableLots']);
  //----------------------------------------------------------------
  const [inintRevenue, setinintRevenue] = useState(false);
  const [marketAllList, setMarketAllList] = useState([]);
  const [marketAllGroupList, setMarketAllGroupList] = useState([]);

  const [revenueNet, setRevenueNet] = useState([]);
  const [revenueNetLoading, setRevenueNetLoading] = useState([]);
  const [revenueNetDate, setRevenueNetDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [revenueOcc, setRevenueOcc] = useState([]);
  const [revenueOccAll, setRevenueOccAll] = useState([]);
  const [selectMarketOcc, setSelectMarketOcc] = useState(['all']);
  const [revenueOccLoading, setRevenueOccLoading] = useState([]);
  const [revenueOccDate, setRevenueOccDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [revenueArea, setRevenueArea] = useState([]);
  const [selectMarketArea, setSelectMarketArea] = useState(['all']);
  const [revenueAreaLoading, setRevenueAreaLoading] = useState([]);
  const [revenueAreaDate, setRevenueAreaDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [revenueAreaGroup, setRevenueAreaGroup] = useState([]);
  const [selectMarketAreaGroup, setSelectMarketAreaGroup] = useState(['all']);
  const [revenueAreaGroupLoading, setRevenueAreaGroupLoading] = useState([]);
  const [revenueAreaGroupDate, setRevenueAreaGroupDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });
  //----------------------------------------------------------------
  const [inintService, setinintService] = useState(false);
  const [revenueList, setReveneList] = useState([]);
  const [selectMarketRevenue, setSelectMarkRevenue] = useState(['all']);
  const [revenueListLoading, setReveneListLoading] = useState([]);
  const [revenueChartDate, setRevenueChartDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });
  const [slaData, setSLAData] = useState([]);
  const [slaDataLoading, setSLADataLoading] = useState([]);
  const [slaDataDate, setSlaDataDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });
  const [vechicleData, setVechicleData] = useState([]);
  const [vechicleDataLoading, setVechicleDataLoading] = useState([]);
  const [vechicleDate, setVechicleDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });
  const [selectVechiecle, setSelectVechiecle] = useState(['all']);

  const [laborHRData, setLaborHR] = useState([]);
  const [selectLaborHR, setSelectLaborHR] = useState(['all']);
  const [laborHRDataLoading, setLaborHRLoading] = useState([]);
  const [laborHRDataDate, setLaborHRDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });

  const [laborKGData, setLaborKG] = useState([]);
  const [laborKGDataLoading, setLaborKGLoading] = useState([]);
  const [laborKGDataDate, setLaborKGDate] = useState({
    type: 'month',
    value: moment().format('MMMM YYYY'),
  });


  const [tempChartDate, setTempChartDate] = useState({
    start: '2024-01-01',
    end: '2024-03-31',
  });

  console.log('filterDate', filterDate);

  useEffect(() => {
    const initApiRevenue = async () => {
      setRevenueNetLoading(true);
      setRevenueOccLoading(true);
      setRevenueAreaLoading(true);
      setRevenueAreaGroupLoading(true);
      try {
        getRevenueAndNetApi();
        getOccApi();
        getAreaApi();
        getAreaGroupApi();
        setinintRevenue(true);
      } catch {}
    };
    const initApiService = async () => {
      setReveneListLoading(true);
      setSLADataLoading(true);
      setVechicleDataLoading(true);
      setLaborHRLoading(true);
      setLaborKGLoading(true);
      try {
        getRevenreApi();
        getSlaApi();
        getVechicleApi();
        getLaborHRApi();
        getLaborKGApi();
        setinintService(true);
      } catch {}
    };
    if (tabKey === 'revenue' && !inintRevenue) {
      initApiRevenue();
    } else if (tabKey === 'service' && !inintService) {
      initApiService();
    }
  }, [tabKey]);

  useEffect(() => {
    const getTeacoMarketApi = async () => {
      const [startDate, endDate] = filterDateApi(filterDate);

      const payload = {
        code: _.get(filterDate, 'type'),
        startDate,
        endDate,
      };

      try {
        const response = await getTotalTeacoMarketHeader(payload);
        console.log('getTotalTeacoMarketHeader', response);
        setDataBucket(response);
        // onSetItemDashBoard(_.get(response, 'data.marketArea'));
      } catch {}
    };

    getTeacoMarketApi();
  }, [filterDate]);

  useEffect(() => {
    const getTeacoMarketApi = async () => {
      console.log('filterDateTab', filterDateTab);

      let startDate;
      let endDate;

      // const [startDate, endDate] = filterDateApi(filterDateDuration);

      if (filterDateTab === 'today') {
        startDate = moment().format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
      } else if (filterDateTab === 'thisweek') {
        startDate = moment().startOf('week').format('YYYY-MM-DD');
        endDate = moment().endOf('week').format('YYYY-MM-DD');
      } else if (filterDateTab === 'thismonth') {
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment().endOf('month').format('YYYY-MM-DD');
      } else if (filterDateTab === 'duration') {
        startDate = moment(filterDateDuration[0]).format('YYYY-MM-DD');
        endDate = moment(filterDateDuration[1]).format('YYYY-MM-DD');
      }

      const payload = {
        code: filterDateTab,
        startDate: startDate,
        endDate: endDate,
      };

      try {
        const response = await getTeacoMarket(payload);
        console.log('getTeacoMarket', response);
        // setDataBucket(response);
        onSetItemDashBoard(_.get(response, 'data.marketArea'));
      } catch {}
    };

    getTeacoMarketApi();
  }, [filterDateTab, filterDateDuration]);

  useEffect(() => {
    const getMarketApi = async () => {
      try {
        const response = await getMarketAll();
        const addNewArray = {
          name: intl.formatMessage({ id: 'RevenueAllMarket', defaultMessage: 'All Market' }),
          objectId: 'all',
        };
        setMarketAllList([addNewArray, ...response]);
      } catch {}
    };
    getMarketApi();
  }, []);

  useEffect(() => {
    const getMarketApi = async () => {
      try {
        const response = await getMarketAllGroup();
        const addNewArray = {
          name: intl.formatMessage({ id: 'RevenueAllMarketGroup', defaultMessage: 'All Market Group' }),
          objectId: 'all',
        };
        setMarketAllGroupList([addNewArray, ...response]);
      } catch {}
    };

    getMarketApi();
  }, []);

  useEffect(() => {
    if (inintRevenue === true) {
      setRevenueNetLoading(true);
      getRevenueAndNetApi();
    }
  }, [revenueNetDate]);


  useEffect(() => {
    //----OCC--------------------------------//

    if (inintRevenue === true) {
      setRevenueOccLoading(true);
      getOccApi();
    }
  }, [revenueOccDate, selectMarketOcc]);



  useEffect(() => {
    //-----------Area-------------------------------//

    if (inintRevenue === true) {
      setRevenueAreaLoading(true);
      getAreaApi();
    }
  }, [revenueAreaDate, selectMarketArea]);



  useEffect(() => {
    //-----------Area-------------------------------//

    if (inintRevenue === true) {
      setRevenueAreaGroupLoading(true);
      getAreaGroupApi();
    }
  }, [revenueAreaGroupDate, selectMarketAreaGroup]);



  useEffect(() => {
    //-----------Revenue-------------------------------//
    if (inintService) {
      setReveneListLoading(true);
      getRevenreApi();
    }
  }, [revenueChartDate]);

 

  useEffect(() => {
    //-----------Sla-------------------------------//

    if (inintService) {
      setSLADataLoading(true);
      getSlaApi();
    }
  }, [slaDataDate]);



  useEffect(() => {
    //-----------Vechicle-------------------------------//

    if (inintService) {
      setVechicleDataLoading(true);
      getVechicleApi();
    }
  }, [selectVechiecle,vechicleDate]);



  useEffect(() => {
    //-----------Labor HR-------------------------------//

    if (inintService) {
      setLaborHRLoading(true);
      getLaborHRApi();
    }
  }, [selectLaborHR,laborHRDataDate]);



  useEffect(() => {
    //------------Labor KG---------------------------------//

    if (inintService) {
      setLaborKGLoading(true);
      getLaborKGApi();
    }
  }, [laborKGDataDate]);

  const getRevenueAndNetApi = async () => {
    console.log('NETNETN', revenueNetDate);

    const [startDate, endDate] = filterDateApi(revenueNetDate);

    const payload = {
      type: _.get(revenueNetDate, 'type'),
      startDate,
      endDate,
    };

    try {
      const response = await findRevenueAndNet(payload);

      const arrayres = _.get(response, 'data');
      const updatedData = [
        { ...arrayres.actual, description: intl.formatMessage({ id: 'RevenueActual', defaultMessage: 'Actual' }) },
        { ...arrayres.budget, description: intl.formatMessage({ id: 'RevenueBudget', defaultMessage: 'Budget' }) },
        { ...arrayres.diff, description: intl.formatMessage({ id: 'RevenueDiff', defaultMessage: 'Diff' }) },
      ];
      console.log('findRevenueAndNet', _.get(response, 'data'), 'dataKeys', updatedData);
      setRevenueNetLoading(false);
      setRevenueNet(updatedData);
    } catch {}
  };

  const getOccApi = async () => {
    const [startDate, endDate] = filterDateApi(revenueOccDate);

    const payload = {
      type: _.get(revenueOccDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketOcc,
    };

    try {
      const response = await findOccrateAndArr(payload);

      const arrayres = _.get(response, 'data.table');
      console.log('findRevenueOcc', arrayres);
      setRevenueOccAll(_.get(response, 'data.graph[0]'))
      setRevenueOcc(arrayres);
      setRevenueOccLoading(false);
    } catch {}
  };

  const getAreaApi = async () => {
    const [startDate, endDate] = filterDateApi(revenueAreaDate);

    const payload = {
      type: _.get(revenueAreaDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketArea,
    };

    try {
      const response = await findAreaUtilization(payload);

      const arrayres = _.get(response, 'data.table');
      console.log('findRevenueOcc', arrayres);
      setRevenueArea(arrayres);
      setRevenueAreaLoading(false);
    } catch {}
  };

  const getAreaGroupApi = async () => {
    const [startDate, endDate] = filterDateApi(revenueAreaGroupDate);

    const payload = {
      type: _.get(revenueAreaGroupDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketAreaGroup,
    };

    try {
      const response = await findAreaUtilizationMarketGroup(payload);

      const arrayres = _.get(response, 'data.table');
      console.log('findRevenueOcc', arrayres);
      setRevenueAreaGroup(arrayres);
      setRevenueAreaGroupLoading(false);
    } catch {}
  };

  const getRevenreApi = async () => {
    const [startDate, endDate] = filterDateApi(revenueChartDate);

    const payload = {
      type: _.get(revenueChartDate, 'type'),
      startDate,
      endDate,
      // marketArr: selectMarketAreaGroup,
      // type: 'month',
      // startDate: _.get(tempChartDate, 'start'),
      // endDate: _.get(tempChartDate, 'end'),
    };

    try {
      const response = await findRevenue(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueOcc', arrayres);
      setReveneList(arrayres);
      setReveneListLoading(false);
    } catch {}
  };

  const getSlaApi = async () => {
    const [startDate, endDate] = filterDateApi(slaDataDate);

    const payload = {
      type: _.get(slaDataDate, 'type'),
      startDate,
      endDate,
      // marketArr: selectMarketAreaGroup,
      // type: 'month',
      // startDate: _.get(tempChartDate, 'start'),
      // endDate: _.get(tempChartDate, 'end'),
    };

    try {
      const response = await findServicesla(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueOcc', arrayres);
      setSLAData(arrayres);
      setSLADataLoading(false);
    } catch {}
  };

  const getVechicleApi = async () => {
    const [startDate, endDate] = filterDateApi(vechicleDate);

    const payload = {
      type: _.get(vechicleDate, 'type'),
      startDate,
      endDate,
      marketArr: selectVechiecle,
      // type: 'month',
      // startDate: _.get(tempChartDate, 'start'),
      // endDate: _.get(tempChartDate, 'end'),
      // marketArr: selectVechiecle,
    };

    try {
      const response = await findServiceVehicle(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueOcc', arrayres);
      setVechicleData(arrayres);
      setVechicleDataLoading(false);
    } catch {}
  };

  const getLaborHRApi = async () => {
    const [startDate, endDate] = filterDateApi(laborHRDataDate);

    const payload = {
      type: _.get(laborHRDataDate, 'type'),
      startDate,
      endDate,
      marketArr: selectMarketAreaGroup,
      // type: 'month',
      // startDate: _.get(tempChartDate, 'start'),
      // endDate: _.get(tempChartDate, 'end'),
      marketArr: selectLaborHR,
    };

    try {
      const response = await findServiceLaborHR(payload);

      const arrayres = _.get(response, 'data');
      const mapSumData = _.map(arrayres,() => {

      })
      console.log('findRevenueLBHR', arrayres);
      setLaborHR(arrayres);
      setLaborHRLoading(false);
    } catch {}
  };

  const getLaborKGApi = async () => {
    const [startDate, endDate] = filterDateApi(laborKGDataDate);

    const payload = {
      type: _.get(laborKGDataDate, 'type'),
      startDate,
      endDate,
      // marketArr: selectMarketAreaGroup,
      // type: 'month',
      // startDate: _.get(tempChartDate, 'start'),
      // endDate: _.get(tempChartDate, 'end'),
    };

    try {
      const response = await findServiceLaborKG(payload);

      const arrayres = _.get(response, 'data');
      console.log('findRevenueLBKG', arrayres);
      setLaborKG(arrayres);
      setLaborKGLoading(false);
    } catch {}
  };

  const onSetItemDashBoard = (data) => {
    setMapItemDashboard(data);
  };

  const filterDateApi = (date) => {
    let startDate, endDate;
    if (_.get(date, 'type') === 'month' || _.get(date, 'type') === 'year') {
      startDate = moment(_.get(date, 'value'))
        .startOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
      endDate = moment(_.get(date, 'value'))
        .endOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
    } else if (_.get(date, 'type') === 'quarter') {
      startDate = moment()
        .quarter(_.get(date, 'value'))
        .startOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
      endDate = moment()
        .quarter(_.get(date, 'value'))
        .endOf(_.get(date, 'type'))
        .format('YYYY-MM-DD');
    } else {
      startDate = moment(_.get(date, 'value[0]')).format('YYYY-MM-DD');
      endDate = moment(_.get(date, 'value[1]')).format('YYYY-MM-DD');
    }

    return [startDate, endDate];
  };

  const ex = () => {};

  return (
    <OverviewContext.Provider
      value={{
        state: {
          tabKey,
          filterDate,
          filterDateTab,
          mapSelectFilter,
          mapItemDashboard,
          dataBucket,
          marketAllList,
          revenueNet,
          revenueOcc,
          revenueArea,
          revenueAreaGroup,
          selectMarketOcc,
          selectMarketArea,
          selectMarketAreaGroup,
          marketAllGroupList,
          revenueList,
          selectMarketRevenue,
          selectLaborHR,
          slaData,
          vechicleData,
          selectVechiecle,
          laborHRData,
          selectLaborHR,
          laborKGData,
          revenueNetDate,
          revenueOccDate,
          revenueAreaDate,
          revenueAreaGroupDate,
          revenueNetLoading,
          revenueOccLoading,
          revenueAreaLoading,
          revenueAreaGroupLoading,
          revenueListLoading,
          slaDataLoading,
          vechicleDataLoading,
          laborHRDataLoading,
          laborKGDataLoading,
          revenueOccAll,
        },
        setState: {
          setTabKey,
          setFilterDate,
          setfilterDateTab,
          setMapSelectFilter,
          setFilterDateDuration,
          setMarketAllList,
          setRevenueNetDate,
          setRevenueOccDate,
          setRevenueAreaDate,
          setRevenueAreaGroupDate,
          setRevenueChartDate,
          setSlaDataDate,
          setVechicleDate,
          setLaborHRDate,
          setLaborKGDate,
          setSelectMarketOcc,
          setSelectMarketArea,
          setSelectMarketAreaGroup,
          setSelectMarkRevenue,
          setSelectVechiecle,
          setSelectLaborHR,
        },
        func: {
          ex,
        },
      }}
    >
      {children}
    </OverviewContext.Provider>
  );
};

export { OverviewContext, OverviewProvider };
