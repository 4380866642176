import React, { useState, useEffect } from 'react';
import { AutoComplete, Form, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCustomer } from '../../controllers/customer-vendor/customer-vendor';
import _ from 'lodash';
import LabeRequireForm from '../label-required-form';

const { Option } = Select;

const LoadmoreCustomerCreditNote = (props) => {
  const intl = useIntl();
  const { form, data, setData, required, initialId, initialName, visible } = props;
  const getFieldDecorator = form ? form.getFieldDecorator : null;
  const [customerData, setCustomerData] = useState({});
  const [customerDataMaster, setCustomerDataMaster] = useState({});
  const [customerlist, setCustomerlist] = useState([]);
  const [customerListMaster, setCustomerListMaster] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [firstStep, setFirstStep] = useState(false);

  useEffect(() => {
    if (initialId && visible) {
      setSearch(initialName);
      setFirstStep(true);
    } else {
      setSearch('');
      setFirstStep(true);
      setCustomerData({});
      setCustomerDataMaster({});
      setCustomerlist([]);
      setCustomerListMaster([]);
      setPage(1);
    }

    if (!visible) setFirstStep(false)
  }, [initialId, visible]);

  useEffect(() => {
    if (firstStep) getCustomerData();
  }, [page, firstStep]);

  useEffect(() => {
    if(!search && _.size(customerlist) === 0) getCustomerData()
  }, [search])

  const getCustomerData = async () => {
    const body = {
      searchBy: 'customerName',
      searchVal: search,
      page: page,
      limit: 20,
    };
    try {
      const response = await getCustomer(body);
      setCustomerData(response);
      setCustomerDataMaster(response);
      setCustomerlist([...customerlist, ..._.get(response, 'customers', [])]);
      setCustomerListMaster([...customerListMaster, ..._.get(response, 'customers', [])]);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSelect = (value, option) => {
    if (form) {
      form.setFieldsValue({ customer: value });
      setData(_.get(option, 'props.data'));
    } else {
      setData((prev) => ({ ...prev, customer: value }));
    }
  };

  const handleChange = (value) => {
    console.log('searchInv change', value)
    const findCustomer = _.find(customerlist, (item) => {
      return item.customerName === value || item.customerId === value;
    });
    setData((prev) => ({ ...prev, customer: findCustomer, customerSearch: value }));
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    console.log('handleScroll', scrollTop, clientHeight, scrollHeight)
    if ((scrollTop + clientHeight) + 50 >= scrollHeight) {
      if (_.get(customerData, 'allCustomers') !== _.size(customerlist)) setPage(page + 1);
    }
  };

  const handleSearch = _.debounce(async (value) => {
    setSearch(value);
    if (value && value !== '') {
      const body = {
        searchBy: 'customerName',
        searchVal: value,
        page: 1,
        limit: 20,
      };
      const response = await getCustomer(body);
      console.log('searchInvHandleSearch', value, response)
      setCustomerData(response);
      setCustomerlist(_.get(response, 'customers', []));
    } else {
      setCustomerData(customerDataMaster);
      setCustomerlist(customerListMaster);
    }
  }, 500);

  console.log('includes', _.includes(search, 'ไม่กำหนด'));

  return (
    <div>
      <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
        <FormattedMessage id="modalAddInvoiceLabelCustomer" defaultMessage="Customer" />
        
        <span style={{ fontSize: '13px', color: '#ff0000'  }}> *</span> 
      </div>
      <Select
        allowClear
        showSearch
        onSearch={handleSearch}
        onChange={handleChange}
        onPopupScroll={handleScroll}
        value={initialId || undefined}
        filterOption={false}

        placeholder={intl.formatMessage({ id: 'modalAddInvoicePHRefCustomer', defaultMessage: 'Select Customer' })}
        style={{ width: '45%' }}
      >
        {_.map(customerlist, (item) => (
          <Option key={_.get(item, 'customerId')} value={_.get(item, 'customerId')} data={item}>
            {_.get(item, 'customerName')}
          </Option>
        ))}
      </Select>
    </div>
  );
};

LoadmoreCustomerCreditNote.defaultProps = {
  required: true,
};

export default LoadmoreCustomerCreditNote;
