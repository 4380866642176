import React from 'react';
import { Card } from 'antd';
import { useIntl } from 'react-intl';
import ChartStackBar from '../../component/chart-stackbar';

const SLA = (prop) => {
  // const chartData = [
  //   {
  //     name: 'ตลาดส้ม',
  //     less5: 80,
  //     less20: 20,
  //     more20: 10,
  //     scatter: 100,
  //   },
  //   {
  //     name: 'ตลาดผลไม้รวม',
  //     less5: 70,
  //     less20: 35,
  //     more20: 30,
  //     scatter: 120,
  //   },
  //   {
  //     name: 'ตลาดดอกไม้และสังฆภัณฑ์',
  //     less5: 80,
  //     less20: 40,
  //     more20: 40,
  //     scatter: 140,
  //   },
  //   {
  //     name: 'ตลาดสด ชั้น 1',
  //     less5: 90,
  //     less20: 50,
  //     more20: 60,
  //     scatter: 180,
  //   },
  //   {
  //     name: 'ตลาดสด ชั้น 2',
  //     less5: 95,
  //     less20: 65,
  //     more20: 75,
  //     scatter: 200,
  //   },
  //   {
  //     name: 'ตลาดของแห้ง',
  //     less5: 100,
  //     less20: 70,
  //     more20: 80,
  //     scatter: 210,
  //   },
  //   {
  //     name: 'ตลาดอาหารทะเล',
  //     less5: 110,
  //     less20: 75,
  //     more20: 85,
  //     scatter: 230,
  //   },
  //   {
  //     name: 'ตลาดปลาน้ำจืด',
  //     less5: 120,
  //     less20: 80,
  //     more20: 90,
  //     scatter: 250,
  //   },
  // ];
  const {chartData} = prop

  const config = {
    chartColors: { less5: '#4CB140', less20: '#FED966', more20: '#FC3535' },
    legendNames: {
      less5: '<5 min.',
      less20: '<20 min.',
      more20: '>20 min.',
      scatter: '<5 min. 90%'
    },
    yLeft: 'Transaction (Ticket)'
  };

  return <ChartStackBar data={chartData} config={config} />;
};

export default SLA;
