import { Col, DatePicker, Form, Row, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const PriceFormView = ({ form, selectItem, setDataForm, dataForm, initialTaxList, record }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const discount = parseInt(getFieldValue('discount') || 0);
  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.0);
  const tax = getFieldValue('tax');
  const taxNumber = parseFloat(getFieldValue('taxNumber') || 0.0);
  const sumTotal = _.size(selectItem) > 0 ? numberFormatter(_.sumBy(selectItem, 'totalPrice')) : 0.0;
  const subtotalDiscount = numberFormatter(sumTotal - discountNumber);
  const subtotalTax = numberFormatter(parseFloat(subtotalDiscount) + taxNumber);

  console.log('PriceForm', selectItem, dataForm);
  console.log('PriceForm X', discount, discountNumber, tax, taxNumber, sumTotal, subtotalDiscount, subtotalTax);

  useEffect(() => {
    if (discount && discount > 0) {
      setFieldsValue({
        ['discountNumber']: (sumTotal * discount) / 100,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    }
  }, [discount, sumTotal]);

  useEffect(() => {
    if (discountNumber && discountNumber > 0) {
      setFieldsValue({
        ['discount']: (discountNumber / sumTotal) * 100,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    }
  }, [discountNumber, sumTotal]);

  useEffect(() => {
    if (tax >= 0) {
      setFieldsValue({
        ['taxNumber']: (parseFloat(subtotalDiscount) * tax) / 100,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: (parseFloat(subtotalDiscount) * tax) / 100,
      }));
    }
  }, [tax, subtotalDiscount]);

  return (
    <Form name="pirce" className="po-form">
      <Row gutter={[24]}>
        <Col span={10}>
          <Row gutter={[24]}>
            {/* <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidto', defaultMessage: 'Valid to' })} req={false} />
                }
              >
                {_.get(dataForm, 'validDay') || '-'} <FormattedMessage id="quotationLabelDay" defaultMessage="Day" />,{' '}
                {_.get(dataForm, 'validDate') ? moment(_.get(dataForm, 'validDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('validRemark', {
                  initialValue: _.get(record, 'remark') || '',
                })}
                {_.get(dataForm, 'remark') || '-'}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelSubtotal" defaultMessage="Subtotal" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'subTotal')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelDiscount" defaultMessage="Discount" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">{numberFormatter(_.get(dataForm, 'discountPercent')) + '%'}</div>
                <div className="group-form-item-price-input-view">
                  {numberFormatter(_.get(dataForm, 'discount')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelSubtotalAfterDiscount" defaultMessage="Subtotal After Discount" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'subTotalAfterDiscount')) || '0.00'}{' '}
                <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelTax" defaultMessage="Tax" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">{numberFormatter(_.get(dataForm, 'taxPercent')) + '%'}</div>
                <div className="group-form-item-price-input-view">
                  {numberFormatter(_.get(dataForm, 'tax'))} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className="label-bold">
                <FormattedMessage id="quotationLabelTotal" defaultMessage="Total" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'total')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PriceFormView.defaultProps = {};

export default PriceFormView;
