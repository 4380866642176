import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../components/v2/button_01';
import moment from 'moment';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, onAllowClear, onClick } = props;
  const intl = useIntl();
  const dateFormat = 'DD/MM/YYYY';

  return (
    <div>
      <div style={{ overflowY: 'auto', height: '580px' }}>
        <Divider type="horizontal" style={{ margin: 'unset' }} />
        <div style={{ padding: '16px 16px 16px 0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="invoiceLabelINVNo" defaultMessage="INV No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'invoiceNo')}
                value={_.get(searchGroup, 'invoiceNo')}
                placeholder={intl.formatMessage({
                  id: 'invoiceLabelINVNoPlaceHolder',
                  defaultMessage: 'Enter INV No.',
                })}
              />
            </div>
          </div>

          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="invoiceLabelSONo" defaultMessage="SO No." />
            </div>
            <div>
              <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'saleOrderNo')}
                value={_.get(searchGroup, 'saleOrderNo')}
                placeholder={intl.formatMessage({
                  id: 'BLAddCreditNoteSONoPlaceHolder',
                  defaultMessage: 'Enter SO No.',
                })}
              />
            </div>
          </div>
          {/* <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="modalListItemFilterTextStatus" defaultMessage="Status" />
            </div>
            <Select
              showSearch
              allowClear={true}
              mode="multiple"
              onSearch={(value) => brand.setBrandSearch(value)}
              onChange={(value) => onChange(value, 'status')}
              dataSource={brand.optionsBrand}
              filterOption={false}
              value={_.get(searchGroup, 'status')}
              placeholder={intl.formatMessage({ id: 'modalListItemFilterTextStatusPlaceholder', defaultMessage: 'Select Status' })}
              style={{ width: '100%' }}
              notFoundContent={brand.loadingBrand ? 'Loading...' : null}
            >
              {_.map(brand.optionsBrand, (item) =>
                  <Option value={item.item_brand_id}>
                    {item.item_brand_name}
                  </Option>
                )}
            </Select> 
             <div>
              <Select
                showSearch
                allowClear={true}
                mode='multiple'
                onSearch={(value) => model.setModelSearch(value)}
                onChange={(value) => onChange(value, 'model')}
                dataSource={model.optionsModel}
                filterOption={false}
                value={_.get(searchGroup, 'model')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextModelPlaceholder', defaultMessage: 'Select Model' })}
                style={{ width: '100%' }}
                notFoundContent={model.loadingModel ? 'Loading...' : null}
                disabled={_.get(searchGroup, 'brand') ? false : true}
              >
                {_.map(model.optionsModel, (item) =>
                  <Option value={item.item_model_id}>
                    {item.item_model_name}
                  </Option>
                )}
              </Select>
            </div> 
          </div> */}
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddInvoiceFromINVDate" defaultMessage="From INV Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                style={{ width: '100%' }}
                value={_.get(searchGroup, 'issueDateStart') ? moment(_.get(searchGroup, 'issueDateStart')) : ''}
                onChange={(e) => onChange(e, 'issueDateStart')}
                placeholder={intl.formatMessage({ id: 'BLAddINVDatePlaceholder', defaultMessage: 'Select INV Date' })}
              />
              {/* <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'itemCode')}
                value={_.get(searchGroup, 'itemCode')}
                placeholder={intl.formatMessage({ id: 'modalListItemFilterTextItemCodePlaceholder', defaultMessage: 'Enter Item Code' })}
              /> */}
            </div>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
              <FormattedMessage id="BLAddInvoiceToINVDate" defaultMessage="To INV Date" />
            </div>
            <div>
              <DatePicker
                allowClear={onAllowClear}
                format={dateFormat}
                value={_.get(searchGroup, 'issueDateEnd') ? moment(_.get(searchGroup, 'issueDateEnd')) : ''}
                style={{ width: '100%' }}
                onChange={(e) => onChange(e, 'issueDateEnd')}
                placeholder={intl.formatMessage({ id: 'BLAddINVDatePlaceholder', defaultMessage: 'Select INV Date' })}
              />
              {/* <Input
                allowClear={true}
                onChange={(e) => onChange(e.target.value, 'itemName')}
                value={_.get(searchGroup, 'itemName')}
                placeholder={intl.formatMessage({
                  id: 'modalListItemFilterTextItemNamePlaceholder',
                  defaultMessage: 'Enter Sale Order No.',
                })}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: '16px 16px 16px 0px' }}>
        <Button_01 key="submit" type="primary" style={{ width: '250px', margin: 'unset' }} onClick={onClick}>
          <FormattedMessage id="btnApply" defaultMessage="Apply" />
        </Button_01>
      </div>
    </div>
  );
};

export default ListItemFilter;
