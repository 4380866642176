import React from 'react';
import ChartBar from '../../component/chart-bar';

const ARR = () => {
  const chartData = [
    { name: 'Jan 25', bar1: 4000, bar2: 3500, bar3: 4200, bar4: 3800, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Feb 25', bar1: 3000, bar2: 3200, bar3: 3100, bar4: 3050, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Mar 25', bar1: 2000, bar2: 2500, bar3: 2300, bar4: 2400, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Apr 25', bar1: 2780, bar2: 2900, bar3: 2750, bar4: 2850, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'May 25', bar1: 1890, bar2: 2100, bar3: 2050, bar4: 1950, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Jun 25', bar1: 2390, bar2: 2500, bar3: 2450, bar4: 2550, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Jul 25', bar1: 3490, bar2: 3300, bar3: 3400, bar4: 3450, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Aug 25', bar1: 3200, bar2: 3100, bar3: 3250, bar4: 3150, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Sep 25', bar1: 2800, bar2: 2700, bar3: 2750, bar4: 2850, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Oct 25', bar1: 2600, bar2: 2500, bar3: 2550, bar4: 2650, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Nov 25', bar1: 3100, bar2: 3150, bar3: 3080, bar4: 3120, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
    { name: 'Dec 25', bar1: 3700, bar2: 3600, bar3: 3650, bar4: 3680, barName1: 'Mock ตลาด1', barName2: 'Mock ตลาด2', barName3: 'Mock ตลาด3', barName4: 'Mock ตลาด4'},
  ];  

  const chartConfig = {
    domain: {
      left: [0, 'auto'],
    },
    yLeft: 'Area (THB/sq.m.)',
  };

  const CustomLegend = ({ payload, barNames, lineNames }) => {
    const barItems = payload.filter((entry) => entry.dataKey.startsWith('bar'));
    const lineItems = payload.filter((entry) => entry.dataKey.startsWith('line'));

    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '100px', marginTop: '15px', lineHeight: '30px' }}>
        <div>
          {barItems.map((entry, index) => (
            <div key={entry.dataKey} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div style={{ width: '12px', height: '12px', backgroundColor: entry.color }}></div>
              <span>ARR (A) - {barNames[entry.dataKey] || entry.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return <ChartBar data={chartData} config={chartConfig} CustomLegend={CustomLegend}/>;
};

export default ARR;
