import React, { useContext, useState } from 'react';
import { PageSettings } from '../../../config/page-settings';
import { MarketContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, Dropdown, Icon, Menu, Row, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import FilterOnTopTab from '../talaadthai/component/filter-op-top-tab';
import Button_01 from '../../../components/v2/button_01';
import ItemTopGlobal from './component/item-top';
import TabRevenueMarket from './revenue';

const { TabPane } = Tabs;

const MarketPage = ({ isFullScreen, setIsFullScreen }) => {
  const intl = useIntl();
  const { state, setState, func } = useContext(MarketContext);

  const { tabKey, dataBucket, selectSingle, marketAllList } = state;
  const { setTabKey, setFilterDate, SetSelectSingle } = setState;
  const {} = func;

  const callback = (key) => {
    setTabKey(key);
  };

  const buttonFullScreen = (
    <Button_01
      key="submit"
      type="primary"
      style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '11%' }}
      onClick={() => {
        setIsFullScreen((prev) => !prev);
      }}
    >
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Icon type="fullscreen" style={{ marginRight: 8, fontSize: 24 }} />
        <FormattedMessage id="btnFullScreen" defaultMessage="Full Screen" />
      </span>
    </Button_01>
  );

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]} style={{ backgroundColor: '#fafafa' }}>
            <Col span={24} style={{ paddingBottom: '24px' }}>
              <FilterOnTopTab
                title={
                  <span>
                    <Icon type="shop" style={{ marginRight: 8, fontSize: 16 }} />
                    {intl.formatMessage({ id: 'taecoTableTalaadthai', defaultMessage: 'Talaadthai' })}
                  </span>
                }
                button={buttonFullScreen}
                date={true}
                setDate={setFilterDate}
                paddingEdit={'12px 24px'}
                single={true}
                selectSingle={selectSingle}
                setSelectSigle={SetSelectSingle}
                marketAllList={marketAllList}
              />
            </Col>
            <Col span={24}>
              <ItemTopGlobal dataBucket={dataBucket} />
            </Col>
            <Col span={24}>
              <Card className="monitor-main-card">
                <StickyContainer>
                  <Tabs
                    className="monitor-main-tabs customer-tab-margin"
                    size="large"
                    defaultActiveKey="map"
                    onChange={callback}
                    animated={false}
                    tabBarStyle={{ padding: '0 24px' }}
                  >
                    <TabPane
                      tab={intl.formatMessage({ id: `OverviewTabMap`, defaultMessage: 'Map' })}
                      key="map"
                      style={{ overflowY: 'scroll' }}
                    ></TabPane>

                    <TabPane tab={intl.formatMessage({ id: `OverviewTabRevenue`, defaultMessage: 'Revenue' })} key="revenue">
                      <TabRevenueMarket />
                    </TabPane>

                    <TabPane
                      tab={intl.formatMessage({ id: `OverviewTabService`, defaultMessage: 'Service (Unlonding)' })}
                      key="service"
                    ></TabPane>

                    <TabPane tab={intl.formatMessage({ id: `OverviewTabVechicle`, defaultMessage: 'Vechicle' })} key="vechicle"></TabPane>
                  </Tabs>
                </StickyContainer>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default MarketPage;
