import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Divider, Dropdown, Form, Icon, Input, Menu, Modal, Row, Select } from 'antd';
import LabeRequireForm from '../../../../../components/label-required-form';
import Search from 'antd/lib/input/Search';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import ModalProjectPricingSelectSOForm from './modal-select-so';
import Button_02 from '../../../../../components/v2/button_02';
import {
  createPricingList,
  editPricingList,
  getDestinationPricing,
  getWeightPricing,
} from '../../../../../controllers/project-new/project-detail';
import moment from 'moment';
import { DownOutlined } from '@ant-design/icons';
import CustomTableComponent from './table';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../../component-function/fnc-number';
import styled from 'styled-components';
import { errorNotification, successNotification } from '../../../../../components/v2/notification';
import getCompanyProfile from '../../../../../controllers/company/get-company-profile';
import { getSaleOrderById } from '../../../../../controllers/sale-order/sale-order-api-new';

const { Option } = Select;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .ant-input {
    padding-right: 45px; // Make room for suffix
    border-color: ${(props) => (props.$hasError ? 'red' : '#d9d9d9')};

    &:hover,
    &:focus {
      border-color: ${(props) => (props.$hasError ? 'red' : '#4096ff')};
    }
  }
`;

const Suffix = styled.span`
  position: absolute;
  right: 10px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
`;

const CustomInput = ({ value, onChange, hasError, onKeyDown, onFocus, suffixText = 'THB', onReset, className, disabled }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputContainer $hasError={hasError}>
        <Input value={value} onChange={onChange} onKeyDown={onKeyDown} onFocus={onFocus} className={className} disabled={disabled} />
        <Suffix>{suffixText}</Suffix>
        {/* <Icon style={{ marginLeft: 10 }} className="color-reset-amount" type="undo" onClick={() => onReset()} /> */}
      </InputContainer>
    </div>
  );
};

const ModalProjectCreatePricing = (props) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: <FormattedMessage id="columnsProjectPricingSource" defaultMessage="Source" />,
      dataIndex: 'source',
      key: 'source',
      width: 200,
      sorter: (a, b) => a.sourceName.localeCompare(b.sourceName),

      render: (text, record, index) => (
        <Select
          style={{ width: '200px', border: `1px solid ${(record.empty.source || record.empty.all) ? 'red' : '#D9D9D9'}`, borderRadius: '8px' }}
          value={record.source}
          onChange={(value) => handleChangeTable('source', value, index)}
          placeholder={intl.formatMessage({ id: 'columnsProjectPricingPHRefSource', defaultMessage: 'Select Source' })}
        >
          <Option value={'*'}>ไม่กำหนด</Option>
          {_.map(listSource, (item) => (
            <Option value={item.companyAddressBookId}>{item.addressName}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingDestination" defaultMessage="Destination" />,
      dataIndex: 'destination',
      key: 'destination',
      width: 200,
      sorter: (a, b) => a.destinationName.localeCompare(b.destinationName),

      render: (text, record, index) => (
        <Select
          style={{ width: '200px', border: `1px solid ${(record.empty.destination || record.empty.all) ? 'red' : '#D9D9D9'}`, borderRadius: '8px' }}
          value={record.destination}
          onChange={(value) => handleChangeTable('destination', value, index)}
          placeholder={intl.formatMessage({ id: 'columnsProjectPricingPHRefDestination', defaultMessage: 'Select Destination' })}
        >
          <Option value={'*'}>ไม่กำหนด</Option>
          {_.map(listDestination, (item) => (
            <Option value={item.companyAddressBookId}>{item.addressName}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingWeightCondition" defaultMessage="Weight Condition" />,
      dataIndex: 'weight',
      key: 'weight',
      width: 200,
      sorter: (a, b) => a.weightName.localeCompare(b.weightName),

      render: (text, record, index) => (
        <Select
          style={{ width: '100%', border: `1px solid ${(record.empty.weight || record.empty.all) ? 'red' : '#D9D9D9'}`, borderRadius: '8px' }}
          value={record.weight}
          onChange={(value) => handleChangeTable('weight', value, index)}
          placeholder={intl.formatMessage({ id: 'columnsProjectPricingPHRefWeightCondition', defaultMessage: 'Select Weight Condition' })}
        >
          {_.map(listWeight, (item) => (
            <Option value={item._id}>{item.name}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingGasPriceMin" defaultMessage="Gas Price (Minimum)" />,
      dataIndex: 'gasPriceMin',
      key: 'gasPriceMin',
      width: 200,
      sorter: (a, b) => a.gasPriceMin - b.gasPriceMin,
      render: (text, record, index) => {
        return (
          <CustomInput
            value={record.gasPriceMin}
            onChange={(e) => handleChangeTable('gasPriceMin', e.target.value, index)}
            hasError={_.get(record, 'empty.gasPriceMin') || _.get(record, 'empty.gasPriceMinValue') || _.get(record, 'empty.all')}
            onKeyDown={handleKeyDownDecimal2Fixed}
            onFocus={handleFocus}
            suffixText={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
            // disabled={record.type === 'return'}
          />
        );
      },
    },
    {
      title: <FormattedMessage id="columnsProjectPricingGasPriceMax" defaultMessage="Gas Price (Maximum)" />,
      dataIndex: 'gasPriceMax',
      key: 'gasPriceMax',
      width: 200,
      sorter: (a, b) => a.gasPriceMax - b.gasPriceMax,
      render: (text, record, index) => {
        return (
          <CustomInput
            value={record.gasPriceMax}
            onChange={(e) => handleChangeTable('gasPriceMax', e.target.value, index)}
            hasError={_.get(record, 'empty.gasPriceMax') || _.get(record, 'empty.gasPriceMaxValue') || _.get(record, 'empty.all')}
            onKeyDown={handleKeyDownDecimal2Fixed}
            onFocus={handleFocus}
            suffixText={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
            // disabled={record.type === 'return'}
          />
        );
      },
    },

    {
      title: <FormattedMessage id="columnsProjectPricingItemOrService" defaultMessage="Item or Service" />,
      dataIndex: 'type',
      key: 'type',
      width: 400,
      render: (text, record, index) => (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            border: `1px solid ${(record.empty.item || record.empty.all) ? 'red' : '#D9D9D9'}`,
            borderRadius: '8px',
            background: '#fff',
          }}
        >
          <Select
            style={{
              width: '40%',
              marginRight: '3px',
            }}
            value={record.type}
            onChange={(value) => handleChangeTable('type', value, index)}
          >
            <Option value="product">
              <FormattedMessage id="projectPricingItem" defaultMessage="Item" />
            </Option>
            <Option value="service">
              <FormattedMessage id="projectPricingService" defaultMessage="Service" />
            </Option>
          </Select>

          <Select
            style={{
              width: '60%',
            }}
            value={record.item}
            onChange={(value) => handleChangeTable('item', value, index)}
            placeholder={intl.formatMessage({ id: 'columnsProjectPricingPHRefItem', defaultMessage: 'Select Item' })}
          >
            {_.get(record, 'type') === 'product'
              ? _.map(listItem, (item) => (
                  <Option key={item.itemId} value={item.itemId}>
                    {item.itemName}
                  </Option>
                ))
              : _.map(listService, (item) => (
                  <Option key={item.itemId} value={item.itemId}>
                    {item.itemName}
                  </Option>
                ))}
          </Select>
        </div>
      ),
    },

    {
      title: <FormattedMessage id="columnsProjectPricingUnit" defaultMessage="Unit" />,
      dataIndex: 'unit',
      key: 'unit',
      width: 200,
      sorter: (a, b) => a.unit.localeCompare(b.unit),

      // render: (text, record, index) => (
      //   <Select
      //     style={{ width: '100%', border: `1px solid ${record.empty.unit ? 'red' : '#D9D9D9'}`, borderRadius: '8px' }}
      //     value={record.unit}
      //     onChange={(value) => handleChangeTable('unit', value, index)}
      //     placeholder={intl.formatMessage({ id: 'columnsProjectPricingPHRefUnit', defaultMessage: 'Select Unit' })}
      //   >
      //     <Option value="return">
      //       <FormattedMessage id="modalCreditNoteColumnTypeReturn" defaultMessage="Return" />
      //     </Option>
      //     <Option value="discount">
      //       <FormattedMessage id="modalCreditNoteColumnTypeDiscount" defaultMessage="Discount" />
      //     </Option>
      //   </Select>
      // ),
    },
    {
      title: <FormattedMessage id="columnsProjectPricingPriceUnit" defaultMessage="Price/Unit" />,
      dataIndex: 'priceUnit',
      key: 'priceUnit',
      width: 200,
      sorter: (a, b) => a.priceUnit - b.priceUnit,
      render: (text, record, index) => {
        return (
          <CustomInput
            value={record.priceUnit}
            onChange={(e) => handleChangeTable('priceUnit', e.target.value, index)}
            hasError={_.get(record, 'empty.priceUnit') || _.get(record, 'empty.all')}
            onKeyDown={handleKeyDownDecimal2Fixed}
            onFocus={handleFocus}
            suffixText={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
            // disabled={record.type === 'return'}
          />
        );
      },
    },
    {
      title: <FormattedMessage id="columnsProjectPricingRemark" defaultMessage="Remark" />,
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
      render: (text, record, index) => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                className="suffix-input-zindex"
                style={{ paddingRight: 10, height: '35px', borderColor: _.get(record, 'empty.remark') ? 'red' : undefined }}
                value={record.remark}
                onChange={(e) => handleChangeTable('remark', e.target.value, index)}
                placeholder={intl.formatMessage({ id: 'columnsProjectPricingPHRefRemark', defaultMessage: 'Enter Remark' })}
              />
            </div>
          </>
        );
      },
    },
  ];

  const intl = useIntl();
  const { visible, onCancel, projectDataForPricing, pricingValue, setPricingValue, setTrigger, selectedRecord } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [totalTable, setTotalTable] = useState(0);
  const [pageTable, setPageTable] = useState(1);
  const [pageSizeTable, setPageSizeTable] = useState(10);
  const [orderBy, setOrderBy] = useState('');
  const [orderType, setOrderType] = useState('');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listTable, setListTable] = useState([]);

  const [listItem, setListItem] = useState([]);
  const [listService, setListService] = useState([]);
  const [listSource, setListSource] = useState([]);
  const [listDestination, setListDestination] = useState([]);
  const [listWeight, setListWeight] = useState([]);

  const [triggerTable, setTriggerTable] = useState(false);

  // const [visibleItem, setVisibleItem] = useState(false)

  console.log('pricingValue', pricingValue, listItem, listService);
  console.log('listItemOption', listSource, listDestination, listWeight);
  console.log('dataTable', dataTable, selectedRowKeys, selectedRecord, pricingValue);
  console.log('projectDataForPricing', projectDataForPricing);

  useEffect(() => {
    const getInfo = async () => {
      const getSource = await getCompanyProfile();
      const getWeight = await getWeightPricing({});

      setListSource(_.get(getSource, 'AddressBookList'));
      setListDestination(_.get(getSource, 'AddressBookList'));
      setListWeight(_.get(getWeight, 'data'));

      console.log('getInfoData', getSource, getWeight);
    };
    getInfo();
  }, []);

  useEffect(() => {
    const getInfoData = async () => {
      // const bodyDestination = {
      //   pageNumber: 1,
      //   limit: 100,
      //   orderType: 'asc',
      //   searchObj: { address: '', addressName: '' },
      //   customerId: _.get(pricingValue, '[0].customerId'),
      // };

      // const getDestination = await getDestinationPricing(bodyDestination);
      // setListDestination(_.get(getDestination, 'data.list'));

      const itemList = _.get(pricingValue, '[0].itemList');

      if (_.size(itemList)) {
        let items = [];
        let services = [];

        _.map(itemList, (item) => {
          if (item.categoryCode === 'product') {
            items.push(item);
          } else if (item.categoryCode === 'service') {
            services.push(item);
          }
        });

        setListItem(items);
        setListService(services);
      }
    };

    if (pricingValue) {
      getInfoData();
    } else {
      setListItem([]);
      setListService([]);
    }
  }, [pricingValue, triggerTable]);

  useEffect(() => {
    const formatDataInfo = async () => {
      console.log('selectedRecord', selectedRecord);
      const responseSO = await getSaleOrderById(selectedRecord.referenceId);
      setPricingValue(_.get(responseSO, 'data.data'));
      if (_.get(selectedRecord, 'action') === 'edit') {
        const mapTableDate = _.orderBy(
          _.map(_.get(selectedRecord, 'projectConfig'), (item, index) => {
            return {
              ...item,
              // index: index,
              index: item.index,
              source: item.source.companyAddressBookId ? item.source.companyAddressBookId : '*',
              sourceName: item.source.addressName ? item.source.addressName : 'ไม่กำหนด',
              destination: item.destination.companyAddressBookId ? item.destination.companyAddressBookId : '*',
              destinationName: item.destination.addressName ? item.destination.addressName : 'ไม่กำหนด',
              weight: item.projectWeightCondId,
              weightName: _.chain(listWeight)
                .filter((weight) => weight._id === item.projectWeightCondId)
                .get('[0].name')
                .value(),
              gasPriceMin: item.gasPriceMin,
              gasPriceMax: item.gasPriceMax,
              type: item.itemList.categoryCode,
              item: item.itemList.itemId,
              itemSaleOrder: item.itemList,
              unit: item.itemList.unitName,
              priceUnit: item.pricePerUnit,
              remark: item.remark,
              empty: onSetAllEmpty(false),
            };
          }),
          ['index'],
          ['asc']
        );
        setDataTable(mapTableDate);
      } else {
        const mapTableDate = _.orderBy(
          _.map(_.get(selectedRecord, 'projectConfig'), (item, index) => {
            return {
              index: item.index,
              source: item.source.companyAddressBookId ? item.source.companyAddressBookId : '*',
              sourceName: item.source.addressName ? item.source.addressName : 'ไม่กำหนด',
              destination: item.destination.companyAddressBookId ? item.destination.companyAddressBookId : '*',
              destinationName: item.destination.addressName ? item.destination.addressName : 'ไม่กำหนด',
              weight: item.projectWeightCondId,
              weightName: _.chain(listWeight)
                .filter((weight) => weight._id === item.projectWeightCondId)
                .get('[0].name')
                .value(),
              gasPriceMin: item.gasPriceMin,
              gasPriceMax: item.gasPriceMax,
              type: 'product',
              item: undefined,
              itemSaleOrder: undefined,
              unit: undefined,
              priceUnit: item.pricePerUnit,
              remark: item.remark,
              empty: onSetAllEmpty(false),
            };
          }),
          ['index'],
          ['asc']
        );
        setDataTable(mapTableDate);
        setPricingValue('');
      }

      setTriggerTable(!triggerTable);
    };

    if (selectedRecord) {
      formatDataInfo();
    } else {
      setDataTable([]);
      setPricingValue('');
    }
  }, [selectedRecord]);

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);

    const newData = _.map(dataTable, (item) => {
      return {
        ...item,
        type: 'product',
        item: undefined,
        itemSaleOrder: undefined,
        unit: undefined,
      };
    });
    setDataTable(newData);
  };

  const checkValidate = () => {
    let cloneData = [...dataTable];
    let empty = false;
    let emptyFillRequired = false;

    _.map(dataTable, (o, index) => {
      //--------------------------------------------//
      if (o.source === undefined || o.source === '') cloneData[index].empty.source = true;
      if (o.destination === undefined || o.destination === '') cloneData[index].empty.destination = true;
      if (o.weight === undefined || o.weight === '') cloneData[index].empty.weight = true;
      if (o.item === undefined || o.item === '') cloneData[index].empty.item = true;
      if (o.gasPriceMin === undefined || o.gasPriceMin === '') cloneData[index].empty.gasPriceMinValue = true;
      if (o.gasPriceMax === undefined || o.gasPriceMax === '') cloneData[index].empty.gasPriceMinValue = true;
      if (o.priceUnit === undefined || o.priceUnit === '') cloneData[index].empty.priceUnit = true;

      //--------------------------------------------//
      if (
        !o.source ||
        !o.destination ||
        !o.weight ||
        !o.item ||
        o.gasPriceMin === undefined ||
        o.gasPriceMin === '' ||
        o.gasPriceMax === undefined ||
        o.gasPriceMax === '' ||
        o.priceUnit === undefined ||
        o.priceUnit === ''
      ) {
        empty = true;
        emptyFillRequired = true;
      }
      //--------------------------------------------//
  
    });
    console.log('checkValidate cloneData', cloneData);

    // func เช็ค empty ทั้งหมด
    // empty = _.some(cloneData, (obj) => _.some(_.values(obj.empty), Boolean));
    setDataTable(cloneData);
    console.log('check 1 pass', empty);

    if (emptyFillRequired) {
      errorNotification(
        intl.formatMessage({ id: 'projectPricingPleaseFillRequired', defaultMessage: 'Please fill in all required fields.' })
      );
    }

    return empty;
  };

  const checkItemConfiguration = () => {
    const fieldsToCheck = ['source', 'destination', 'weight', 'gasPriceMin', 'gasPriceMax', 'type', 'item', 'priceUnit'];
    let empty = false;
    let cloneData = [...dataTable]
    const dataTableNew = _.map(dataTable, (item) => {
      return {
        ...item,
        gasPriceMin: _.toNumber(item.gasPriceMin),
        gasPriceMax: _.toNumber(item.gasPriceMax),
        priceUnit: _.toNumber(item.priceUnit),
      }
    })
    console.log('checkItemConfiguration cloneData1', cloneData);

    for (let i = 0; i < _.size(dataTableNew); i++) {
      for (let j = i + 1; j < _.size(dataTableNew); j++) {
        const item1 = _.pick(dataTableNew[i], fieldsToCheck);
        const item2 = _.pick(dataTableNew[j], fieldsToCheck);
    console.log('checkItemConfiguration for => ', item1, item2, _.isEqual(item1, item2));

        if (_.isEqual(item1, item2)) {
          empty = true;
          cloneData[i].empty = setEmptyTable(cloneData[i].empty, 'all', true);
          cloneData[j].empty = setEmptyTable(cloneData[j].empty, 'all', true);
          setDataTable(cloneData);
        }
      }
    }
    console.log('checkItemConfiguration cloneData2', cloneData);
    if (empty === true) {
      errorNotification(intl.formatMessage({ id: 'projectPricingConditions', defaultMessage: 'Conditions are duplicated.' }));
    }
    return empty;
  };

  console.log('checkJJJJJ', dataTable, listSource);

  const handleSave = async () => {
    const isValidate = await checkValidate();
    const isItemConfiguration = await checkItemConfiguration();

    if (isValidate || isItemConfiguration) {
      return;
    }

    const getApi = async () => {
      const listItem = _.map(dataTable, (item) => {
        const source =
          item.source === '*'
            ? { companyAddressBookId: null, addressName: null }
            : _.chain(listSource)
                .filter((o) => o.companyAddressBookId === item.source)
                .get('[0]')
                .value();
        const destination =
          item.destination === '*'
            ? { companyAddressBookId: null, addressName: null }
            : _.chain(listDestination)
                .filter((o) => o.companyAddressBookId === item.destination)
                .get('[0]')
                .value();

        return {
          ...item,
          source: source,
          destination: destination,
          projectWeightCondId: item.weight,
          gasPriceMin: item.gasPriceMin ? _.toNumber(item.gasPriceMin) : 0,
          gasPriceMax: item.gasPriceMax ? _.toNumber(item.gasPriceMax) : 0,
          itemList: item.itemSaleOrder,
          pricePerUnit: item.priceUnit ? _.toNumber(item.priceUnit) : 0,
          remark: item.remark ? item.remark : '',
        };
      });

      let listItemEdit;
      if (_.get(selectedRecord, 'action') === 'edit') {
        const listOld = _.get(selectedRecord, 'projectConfig');
        const deletedItem = listOld.filter((item1) => !listItem.some((item2) => item1._id === item2._id));
        const mapDeleted = _.map(deletedItem, (o) => {
          return {
            ...o,
            deleted: true,
          };
        });

        listItemEdit = [...listItem, ...mapDeleted];
        console.log('SaveDeletedItem', listOld, deletedItem, listItemEdit);
      }

      const createOrUpdate =
        _.get(selectedRecord, 'action') === 'edit'
          ? {
              referenceId: pricingValue ? pricingValue[0].saleOrderId : null,
              referenceNo: pricingValue ? pricingValue[0].saleOrderNo : null,
              businessPartnerName: pricingValue ? pricingValue[0].customerName : null,
              businessPartnerId: pricingValue ? pricingValue[0].customerId : null,
              contactName: pricingValue ? pricingValue[0].customerContactName : null,
              contactPhone: pricingValue ? pricingValue[0].customerContactPhone : null,
              createdBy: pricingValue ? pricingValue[0].createdBy : null,
              createdByName: pricingValue ? pricingValue[0].issuedName : null,
              createdDate: pricingValue ? pricingValue[0].issueDate : null,
            }
          : {
              referenceId: pricingValue ? projectDataForPricing.projectId : null,
              referenceNo: pricingValue ? projectDataForPricing.projectNo : null,
              businessPartnerName: pricingValue ? projectDataForPricing.customerName : null,
              businessPartnerId: pricingValue ? projectDataForPricing.customerId : null,
              createdBy: pricingValue ? projectDataForPricing.createdBy : null,
              createdByName: pricingValue ? projectDataForPricing.createdByName : null,
              createdDate: pricingValue ? projectDataForPricing.createdDate : null,
            };

      const payload = {
        ...createOrUpdate,
        refTo: {
          referenceId: pricingValue ? pricingValue[0].saleOrderId : null,
          referenceNo: pricingValue ? pricingValue[0].saleOrderNo : null,
          businessPartnerName: pricingValue ? pricingValue[0].customerName : null,
          businessPartnerId: pricingValue ? pricingValue[0].customerId : null,
          contactName: pricingValue ? pricingValue[0].customerContactName : null,
          contactPhone: pricingValue ? pricingValue[0].customerContactPhone : null,
          createdBy: pricingValue ? pricingValue[0].createdBy : null,
          createdByName: pricingValue ? pricingValue[0].issuedName : null,
          createdDate: pricingValue ? pricingValue[0].issueDate : null,
        },
        projectConfigItems: _.get(selectedRecord, 'action') === 'edit' ? listItemEdit : listItem,
        _id: _.get(selectedRecord, 'action') === 'edit' ? selectedRecord._id : undefined,
      };

      console.log('SavePayload', payload, selectedRecord);

      const response = _.get(selectedRecord, 'action') === 'edit' ? await editPricingList(payload) : await createPricingList(payload);
      console.log('responseSave', response);
      // console.log('payload', payload);
      if (_.get(response, 'status.code') === 200) {
        successNotification(_.get(response, 'status.message'));
        setTrigger((prev) => !prev);
        onCancel();
        setPricingValue('');
        handleClear();
      } else {
        errorNotification(_.get(response, 'status.message'));
      }
    };

    await getApi();
  };

  const menuColumn = (
    <Menu>
      <Menu.Item onClick={() => handleAddNew()}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="projectPricingAddNew" defaultMessage="Add New" />
        </Button>
      </Menu.Item>
      {_.size(selectedRowKeys) > 0 && (
        <Menu.Item onClick={() => handleCopy()}>
          <Button style={{ padding: 'unset' }} ghost type="link">
            <FormattedMessage id="projectPricingCopyRow" defaultMessage="Copy Row" />
          </Button>
        </Menu.Item>
      )}
      {_.size(selectedRowKeys) > 0 && (
        <Menu.Item onClick={() => handleDelete()}>
          <Button style={{ padding: 'unset' }} ghost type="link">
            <FormattedMessage id="projectPricingDelete" defaultMessage="Delete" />
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log('pending start', selectedRowKeys, selectedRows, listTable);
      let selectList = [];

      setSelectedRowKeys(selectedRowKeys);

    },
    getCheckboxProps: (record) => ({
      // disabled: _.get(listCreate, '[0]orderStatusCode')
      //   ? _.get(listCreate, '[0]orderStatusCode') !== _.get(record, 'orderStatusCode')
      //   : null,
    }),
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderType('desc');
      } else {
        setOrderType('asc');
      }
    } else {
      setOrderType();
    }
    setOrderBy(field);
    setPageTable(pagination.current);
    setPageSizeTable(pagination.pageSize);
  };

  const handleChangeTable = (type, value, index) => {
    console.log('handleChangeTable', type, value, index, dataTable);

    const newData =
      dataTable[index].empty.all === true
        ? _.map(dataTable, (item) => {
            return {
              ...item,
              // empty: onSetAllEmpty(false),
              empty: setEmptyTable(item.empty, 'all', false)
            };
          })
        : [...dataTable];

    newData[index][type] = value;
    newData[index].empty = setEmptyTable(newData[index].empty, type, false);

    // func check value

    // อัพเดททีละแถว
    // if (newData[index].empty.all === true) {
    //   newData[index].empty = onSetAllEmpty(false);
    // }

    if (type === 'type') {
      newData[index].item = undefined;
      newData[index].unit = undefined;
      newData[index].itemSaleOrder = undefined;
    }

    if (type === 'source') {
      newData[index].sourceName = _.chain(listSource)
        .filter((o) => o.companyAddressBookId === value)
        .get('[0].addressName')
        .value();
    }

    if (type === 'destination') {
      newData[index].destinationName = _.chain(listDestination)
        .filter((o) => o.companyAddressBookId === value)
        .get('[0].addressName')
        .value();
    }

    if (type === 'weight') {
      newData[index].weightName = _.chain(listWeight)
        .filter((o) => o._id === value)
        .get('[0].name')
        .value();
    }

    if (type === 'item') {
      const type = newData[index].type;
      const filterItem = _.filter(type === 'product' ? listItem : listService, (item) => item.itemId === value);
      const itemSaleOrder = filterItem[0];
      newData[index].unit = _.get(itemSaleOrder, 'unitName');
      newData[index].itemSaleOrder = itemSaleOrder;
    }

    if (type === 'gasPriceMin') {
      const gasPriceMin = _.toNumber(value);
      const gasPriceMax = _.toNumber(newData[index].gasPriceMax);

      if (value === undefined || value === '') {
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMinValue', true);
      } else if (gasPriceMin > gasPriceMax) {
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMin', true);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMax', true);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMinValue', false);
      } else {
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMin', false);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMax', false);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMinValue', false);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMaxValue', false);
      }
    }

    if (type === 'gasPriceMax') {
      const gasPriceMax = _.toNumber(value);
      const gasPriceMin = _.toNumber(newData[index].gasPriceMin);

      if (value === undefined || value === '') {
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMaxValue', true);
      } else if (gasPriceMin > gasPriceMax) {
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMax', true);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMin', true);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMaxValue', false);
      } else {
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMin', false);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMax', false);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMaxValue', false);
        newData[index].empty = setEmptyTable(newData[index].empty, 'gasPriceMinValue', false);
      }
    }

    if (type === 'priceUnit') {
      if (value === '') {
        newData[index].empty = setEmptyTable(newData[index].empty, 'priceUnit', true);
      } else {
        newData[index].empty = setEmptyTable(newData[index].empty, 'priceUnit', false);
      }
    }
    setDataTable(newData);
  };

  const setEmptyTable = (empty, type, boolean) => {
    const emptys = {
      ...empty,
      [type]: boolean,
    };
    return emptys;
  };

  const handleAddNew = () => {
    const proJectId = _.get(selectedRecord, 'action') === 'edit' ? _.get(selectedRecord, 'projectConfig[0].proJectId') : undefined;
    const proJectPricingId =
      _.get(selectedRecord, 'action') === 'edit' ? _.get(selectedRecord, 'projectConfig[0].proJectPricingId') : undefined;

    setDataTable([
      ...dataTable,
      {
        index: _.size(dataTable),
        source: undefined,
        sourceName: '',
        destination: undefined,
        destinationName: '',
        weight: undefined,
        weightName: '',
        gasPriceMin: 0.0,
        gasPriceMax: 0.0,
        type: 'product',
        item: undefined,
        itemSaleOrder: undefined,
        unit: undefined,
        priceUnit: 0.0,
        remark: undefined,
        empty: onSetAllEmpty(false),

        proJectId: proJectId,
        proJectPricingId: proJectPricingId,
      },
    ]);
  };

  const onSetAllEmpty = (boolean) => {
    const empty = {
      all: boolean,
      source: boolean,
      destination: boolean,
      weight: boolean,
      gasPriceMin: boolean,
      gasPriceMax: boolean,
      gasPriceMinValue: boolean,
      gasPriceMaxValue: boolean,
      type: boolean,
      item: boolean,
      unit: false,
      priceUnit: boolean,
      remark: false,
    };

    return empty;
  };

  const handleCopy = () => {
    const proJectId = _.get(selectedRecord, 'action') === 'edit' ? _.get(selectedRecord, 'projectConfig[0].proJectId') : undefined;
    const proJectPricingId =
      _.get(selectedRecord, 'action') === 'edit' ? _.get(selectedRecord, 'projectConfig[0].proJectPricingId') : undefined;

    const copy = _.map(selectedRowKeys, (rowId, index) => {
      return {
        ...dataTable[rowId],
        index: index + _.size(dataTable),

        // edit
        _id: undefined,
        createdAt: undefined,
        createdBy: undefined,
        deleted: undefined,
        proJectId: proJectId,
        proJectPricingId: proJectPricingId,
        projectWeightCond: undefined,
        projectWeightCondId: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
        __v: undefined,
      };
    });

    console.log('copy', copy);
    setDataTable([...dataTable, ...copy]);
  };

  const handleDelete = () => {
    const deleteRow = _.filter(dataTable, (data) => !selectedRowKeys.includes(data.index));
    console.log('deleteRow', deleteRow);
    const mapIndex = _.map(deleteRow, (item, index) => {
      return {
        ...item,
        index: index,
      };
    });
    setDataTable(mapIndex);
    setSelectedRowKeys([]);
  };

  const handleClear = () => {
    setDataTable([]);
    setSelectedRowKeys([]);
    setListItem([]);
    setListService([]);
  };

  const onCloseModal = () => {
    handleClear();
    onCancel();
  };
  
  return (
    <div>
      <Modal
        title={
          _.get(selectedRecord, 'action') === 'edit'
            ? intl.formatMessage({ id: `modalProjectEditPricing`, defaultMessage: 'Edit Pricing' }) +
              '·' +
              _.get(selectedRecord, 'referenceNo')
            : intl.formatMessage({ id: `modalProjectCreatePricing`, defaultMessage: 'Create Pricing' })
        }
        visible={visible}
        onCancel={onCloseModal}
        width={1120}
        bodyStyle={{ padding: '24px 12px', overflowY: 'auto' }}
        footer={[
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => onCloseModal()} margin=" unset">
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
          <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleSave()} disabled={_.some(dataTable, (obj) => _.some(_.values(obj.empty), Boolean))}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ]}
      >
        <div style={{ height: '550px' }}>
          <Form name="customer" colon={false}>
            <Row gutter={[24]} style={{ padding: '0px 12px' }}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'invoiceLabelSO', defaultMessage: 'Sales Order No.' })} />
                  }
                  className="form-line-height"
                >
                  <Search
                    placeholder={intl.formatMessage({ id: 'invoiceLabelSO', defaultMessage: 'Sales Order No.' })}
                    onSearch={(value) => handleOpen()}
                    enterButton
                    className="search-icon-input"
                    readOnly
                    value={pricingValue ? pricingValue[0].saleOrderNo : null}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      bold={true}
                      text={intl.formatMessage({ id: 'invoiceSaleOrderDate', defaultMessage: 'Sales Order Date' })}
                    />
                  }
                >
                  <DatePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'invoiceSaleOrderDate', defaultMessage: 'Sales Order Date' })}
                    disabled
                    format="DD/MM/YYYY"
                    value={pricingValue ? moment(pricingValue[0].issueDate) : null}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'invoiceSOIssuedby', defaultMessage: 'SO Issued by' })} />
                  }
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'invoiceSOIssuedby', defaultMessage: 'SO Issued by' })}
                    disabled
                    value={pricingValue ? pricingValue[0].issuedName : null}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24]} style={{ padding: '0px 12px' }}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })} />
                  }
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'invoiceLabelCustomer', defaultMessage: 'Customer' })}
                    disabled
                    value={pricingValue ? projectDataForPricing.customerName : null}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm bold={true} text={intl.formatMessage({ id: 'ProjectPricingContactCol', defaultMessage: 'Contact' })} />
                  }
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'ProjectPricingContactCol', defaultMessage: 'Contact' })}
                    disabled
                    value={pricingValue ? pricingValue[0].customerContactName : null}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      bold={true}
                      text={intl.formatMessage({ id: 'ProjectPricingContactPhoneCol', defaultMessage: 'Contact Phone' })}
                    />
                  }
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'ProjectPricingPhone', defaultMessage: 'Phone' })}
                    disabled
                    value={pricingValue ? pricingValue[0].customerContactPhone : null}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24]}>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px' }}>
                <span className="item-title-text">
                  <FormattedMessage id="projectPricingConfiguration" defaultMessage="Configuration" />
                </span>
                <div>
                  <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight" disabled={!pricingValue}>
                    <Button_01 key="submit" type="primary" btnsize="wd_df" style={{ margin: 'unset' }}>
                      <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                      <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                    </Button_01>
                  </Dropdown>
                </div>
              </div>
              <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px', padding: 'unset' }} />

              <div style={{ margin: '24px' }}>
                <CustomTableComponent
                  rowKey={(record) => record.index}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataTable}
                  // scroll={{ x: _.sumBy(columns, 'width')}}
                  scroll={{ x: _.sumBy(columns, 'width'), y: 163 }}
                  role={true}
                  onRow={true}
                  // total={totalTable}
                  // loading={loadingOrder}
                  paginationPage={pageTable}
                  paginationSize={pageSizeTable}
                  // contentAction={contentAction}
                  handleChange={handleChange}
                />
              </div>

              <>
                {_.some(dataTable, (item) => _.get(item, 'empty.all')) && (
                  <div>
                    <span style={{ color: 'red', marginTop: '24px', marginLeft: '24px' }}>
                      <FormattedMessage id="projectPricingConditionsAreDuplicated" defaultMessage="Conditions are duplicated." />
                    </span>
                  </div>
                )}

                {(_.some(dataTable, (item) => _.get(item, 'empty.gasPriceMin')) || _.some(dataTable, (item) => _.get(item, 'empty.gasPriceMax'))) && (
                  <div>
                    <span style={{ color: 'red', marginTop: '24px', marginLeft: '24px' }}>
                      <FormattedMessage id="projectPricingPriceMinExceeds" defaultMessage="The minimum price exceeds the maximum price." />
                    </span>
                  </div>
                )}

                {/* {_.some(dataTable, (item) => _.get(item, 'empty.gasPriceMax')) && (
                  <div>
                    <span style={{ color: 'red', marginTop: '24px', marginLeft: '24px' }}>
                      <FormattedMessage id="projectPricingPriceMaxExceeds" defaultMessage="The maximum price exceeds the minimum price." />
                    </span>
                  </div>
                )} */}

                {(_.some(dataTable, (item) => _.get(item, 'empty.source')) ||
                  _.some(dataTable, (item) => _.get(item, 'empty.destination')) ||
                  _.some(dataTable, (item) => _.get(item, 'empty.weight')) ||
                  _.some(dataTable, (item) => _.get(item, 'empty.item')) ||
                  _.some(dataTable, (item) => _.get(item, 'empty.priceUnit')) ||
                  _.some(dataTable, (item) => _.get(item, 'empty.gasPriceMinValue')) ||
                  _.some(dataTable, (item) => _.get(item, 'empty.gasPriceMaxValue'))) && (
                  <div>
                    <span style={{ color: 'red', marginTop: '24px', marginLeft: '24px' }}>
                      <FormattedMessage id="projectPricingPleaseFillRequired" defaultMessage="Please fill in all required fields." />
                    </span>
                  </div>
                )}
              </>

              {pricingValue && (
                <span className="item-title-text" style={{ color: '#8AABDB', margin: '24px', cursor: 'pointer' }} onClick={handleAddNew}>
                  <Icon type="plus" />
                  <FormattedMessage id="projectPricingAddNew" defaultMessage="Add New" />
                </span>
              )}
            </Row>
          </Form>
        </div>
      </Modal>
      <ModalProjectPricingSelectSOForm
        visible={isOpen}
        setVisible={setIsOpen}
        onOk={handleOpen}
        onCancel={handleCancel}
        setPricingValue={setPricingValue}
        projectDataForPricing={projectDataForPricing}
      />
    </div>
  );
};

export default ModalProjectCreatePricing;
