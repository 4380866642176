import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Menu, Modal, Tabs, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import '../css/index.css';
import { StickyContainer } from 'react-sticky';
import DetailsTab from './details-tab';
import PaymentTab from './payment-tab';
import { SaleOrderContext } from '../../context';
import _ from 'lodash';
import ModalCreateListItem from '../list-item/add';
import ModalEditListItem from '../list-item/edit';
import ListItem from '../list-item/list/index';
import moment from 'moment';
import ModalSelectCustomerForm from '../select-customer/customer-modal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import DetailsTabView from './view/details-tab-view';
import PaymentTabView from './view/payment-tab-view';
import ModalApproveForm from '../approve/approve-modal';
import ModalCancelForm from '../cancel/cancel-modal';
import ModalRejectForm from '../reject/reject-modal';
import ModalWaitingForm from '../waiting/waiting-modal';
import ModalDraftForm from '../delete/delete-modal';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';
import SaleOrderPrint from '../../../../components/modelprint/slaeorderprint';
import ListItemServiceSO from '../list-item/list-service';
import ModalEditListServiceItemSO from '../list-item/edit-service';
import ModalCreateListServiceItemSO from '../list-item/add-service';
import { getItemCheckStock, SaleOrderPDFDownloader } from '../../../../controllers/quotation/quotation-api-new';
import { v4 as uuidv4 } from 'uuid';
import { ThaiNumberToText, UrlPDF } from '../../../../component-function/generator-pdf';
import ModalPDFViewver from '../../../../component-function/pdf-viewver';
import { PageSettings } from '../../../../config/page-settings';

const { TabPane } = Tabs;

const SaleOrderModal = ({ form, title, visible, onOk, onCancel, attData, setAttData, refreshModalSaleOrder, nextStatus, tabMain }) => {
  console.log('checkoutprops', nextStatus, tabMain);
  const { checkPermissionAction } = useContext(PageSettings);
  const { state, setState, func } = useContext(SaleOrderContext);
  const {
    initialMemberList,
    initialTaxList,
    initialReasonList,
    record,
    visibleApprove,
    visibleReject,
    visibleCancel,
    visibleDelete,
    visibleWaiting,
    isOpenQuotation,
    recordReport,
    sizeRecordReport,
    signatureUser,
    selectCustomer,
    signatureIssu,
  } = state;
  const { setSelectCustomer } = setState;
  const {
    handleOpenModalUpload,
    handleOpenApprove,
    handleSaveApprove,
    handleCancelApprove,
    handleOpenReject,
    handleSaveReject,
    handleCancelReject,
    handleOpenCancel,
    handleSaveCancel,
    handleCancelCancelQT,
    handleOpenDelete,
    handleSaveDelete,
    handleCancelDelete,
    handleSaveWaiting,
    handleCancelWaiting,
    handleOpenWaiting,
    handleDeleteAttAchment,
    handleSaveToRevise,
  } = func;
  const [dataForm, setDataForm] = useState();
  const [tabkeys, setTabkeys] = useState('detail');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [checkQty, setCheckeQty] = useState(false);
  const [createData, setCreateData] = useState();
  const [editData, setEditData] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQt, setIsOpenQt] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const intl = useIntl();
  const { setFieldsValue, resetFields } = form;
  const formCustomerRef = useRef();
  const saleOrderPrintRef = useRef();
  const [searchGroup, setSearchGroup] = useState({ discount: 0, discountNumber: 0.0 });
  //--------------------------------------------------------------------------
  const [visibleListService, setVisibleListService] = useState(false);
  const [visibleAddService, setVisibleAddService] = useState(false);
  const [visibleEditService, setVisibleEditService] = useState(false);
  const [creatService, setCreatService] = useState();
  const [editService, setEditService] = useState();
  const [loading, setLoading] = useState(false);
  const [visiblePrint, setVisiblePrint] = useState(false);
  const [urlPrint, setUrlPrint] = useState();

  useEffect(() => {
    if (_.get(record, 'quotationId') && visible && _.get(record, 'code')) {
      setDataForm({
        ...record,
        customerContact: record.customerContactId,
        address: record.customerAddressBookName,
        fullAddress: record.customerAddressBookFullAddress,
      });
    }
  }, [record, visible]);

  useEffect(() => {
    const fetchData = async () => {
      if (record) {
        setDataForm({
          ...record,
          customerContact: record.customerContactId,
          address: record.customerAddressBookName,
          fullAddress: record.customerAddressBookFullAddress,
        });
        const itemNew = _.get(record, 'itemList') || [];
        if (_.size(itemNew) > 0) {
          const itemNewKey = _.map(itemNew, (o) => ({ itemMasterId: o.itemId, qty: o.qty }));
          try {
            const responseItem = await getItemCheckStock({ itemMasterList: itemNewKey });
            const itemCheck = _.get(responseItem, 'data') || [];
            let newItem = [];
            newItem = itemNew.map((obj) => {
              const index = itemCheck.findIndex((el) => el['itemMasterId'] == obj['itemId']);
              const { isOutOfStock } = index !== -1 ? itemCheck[index] : {};
              return {
                ...obj,
                isOutOfStock,
              };
            });
            const itemList = _.map(newItem, (o, i) => ({
              ...o,
              index: i + 1,
              promotionCampaign: o.campaignName,
              promotionCampaignId: o.campaignId,
              credits: o.credit,
              discountPrice: o.discount,
              discountPercentage: o.discountPercent,
              pricePerUnit: o.price,
              qty: _.toString(o.qty),
              remark: _.toString(o.remark),
              itemMasterId: o.itemId,
              itemCode: o.itemCode,
              itemName: o.itemName,
              totalPrice: _.toString(o.total),
              uomId: _.toString(o.unit),
              uomName: _.toString(o.unitName),
              uuid: uuidv4(),
              type: _.get(o, 'categoryCode'),
            }));

            setSelectItem(itemList);
          } catch (error) {}
        }
      }
    };

    fetchData();
  }, [record]);

  useEffect(() => {
    if (selectItem) {
      const checkIndex = _.findIndex(selectItem, (o) => o.isOutOfStock === true);
      const isOutOfStock = checkIndex !== -1 ? true : false;
      setCheckeQty(isOutOfStock);
    }
  }, [selectItem, visibleAdd, visibleEdit]);

  useEffect(() => {
    if (_.get(dataForm, 'customerId')) {
      setFieldsValue({ customerName: _.get(dataForm, 'customerName') });
    }
    if (_.get(dataForm, 'quotationObj.quotationId')) {
      setFieldsValue({ quotationNo: _.get(dataForm, 'quotationObj.quotationNo') });
    }
  }, [dataForm]);

  useEffect(() => {
    if (_.size(selectItem) > 0) {
      let cloneItem = [...selectItem];
      let allCredits = [];
      let data = [];
      for (let i = 0; _.size(selectItem) > i; i++) {
        if (!_.includes(allCredits, cloneItem[i].credits)) {
          const filterItem = _.filter(selectItem, (obj) => obj.credits === cloneItem[i].credits);

          let total = cloneItem[i].totalPrice;

          if (_.size(filterItem) > 0) {
            const mapTotal = _.map(filterItem, (v, i) => {
              return {
                totalPrice: _.toNumber(v.totalPrice),
              };
            });
            total = _.sumBy(mapTotal, 'totalPrice');
            allCredits.push(cloneItem[i].credits);
          }
          data.push({
            credit: cloneItem[i].credits,
            amount: total,
          });
        }
      }
      if (_.size(data) > 0) {
        const sortBy = _.sortBy(data, (o) => _.toNumber(o.credit));
        const mapIndex = _.map(sortBy, (o, i) => {
          return {
            ...o,
            index: i + 1,
            remark: '',
            dueDate: moment().add(o.credit, 'day'),
          };
        });
        setPaymentItem(mapIndex);
      }
    }
  }, [selectItem]);

  useEffect(() => {
    if (record) {
      const mapAttachment = _.map(_.get(record, 'attachment'), (o, i) => ({
        id: _.get(o, 'fileHash'),
        index: i + 1,
        attachmentName: _.get(o, 'fileName'),
        remark: _.get(o, 'remark'),
        file: [{ name: _.get(o, 'fileName'), uid: _.get(o, 'fileHash'), url: _.get(o, 'fileUrl'), action: 'N' }],
        fileRaw: _.get(o, 'fileUrl'),
        action: 'N',
        form: 'backend',
      }));
      setAttData(mapAttachment);
    }
  }, [record]);

  useEffect(() => {
    if (visible === false) {
      handleCancelQuotation();
    }
  }, [visible]);

  const checkItemLimit = async (itemNewKey, itemNew) => {
    try {
      const responseItem = await getItemCheckStock({ itemMasterList: itemNewKey });
      const itemCheck = _.get(responseItem, 'data') || [];
      let newItem = [];
      newItem = itemNew.map((obj) => {
        const index = itemCheck.findIndex((el) => el['itemMasterId'] == obj['itemId']);
        const { isOutOfStock } = index !== -1 ? itemCheck[index] : {};
        return {
          ...obj,
          isOutOfStock,
        };
      });
      const itemList = _.map(newItem, (o, i) => ({
        ...o,
        index: i + 1,
        promotionCampaign: o.campaignName,
        promotionCampaignId: o.campaignId,
        credits: o.credit,
        discountPrice: o.discount,
        discountPercentage: o.discountPercent,
        pricePerUnit: o.price,
        qty: _.toString(o.qty),
        remark: _.toString(o.remark),
        itemMasterId: o.itemId,
        itemCode: o.itemCode,
        itemName: o.itemName,
        totalPrice: _.toString(o.total),
        uomId: _.toString(o.unit),
        uomName: _.toString(o.unitName),
        uuid: uuidv4(),
      }));
      return itemList;
    } catch (error) {}
  };

  const saveWithStatus = (status) => {
    let rStatus;
    if (status === 'draftTorejected') {
      rStatus = 'draft';
    }
    const payload = record;
    payload.status = rStatus;
    // return;
    onOk(payload, resetFields);
    // resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setSearchGroup({ discount: 0, discountNumber: 0.0 });
    setPaymentItem([]);
  };

  console.log('recordxxxxx', record, dataForm);

  const changeToNumber = (value) => {
    const number = _.chain(value)
      .replace(/,/g, '')
      .toNumber()
      .value();
    return number;
  };

  const handleSave = (status) => {
    if (status === 'draftTorejected') {
      saveWithStatus(status);
    } else {
      const checkIndex = _.findIndex(selectItem, (o) => o.isOutOfStock === true);
      const isOutOfStock = checkIndex !== -1 ? true : false;

      form.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        if (isOutOfStock) {
          setCheckeQty(isOutOfStock);
          return;
        }

        const sumtotal = (parseFloat(_.get(dataForm, 'subtotalDiscount')) || 0) + (parseFloat(_.get(values, 'taxNumber')) || 0);

        const itemList = _.map(selectItem, (o) => ({
          itemId: o.itemMasterId,
          qty: _.toString(o.qty),
          unit: _.toString(o.uomId),
          unitName: _.toString(o.uomName),
          price: _.toString(o.pricePerUnit),
          campaignName: _.toString(o.promotionCampaign),
          campaignId: _.toString(o.promotionCampaignId),
          discountPercent: _.toString(o.discountPercentage),
          discount: _.toString(o.discountPrice),
          total: _.toString(o.totalPrice),
          credit: _.toString(o.credits),
          remark: _.toString(o.remark),
        }));

        const payload = {
          customerId: _.get(dataForm, 'customerId') || null,
          customerName: _.get(dataForm, 'customerName') || null,
          customerAddressBookId: _.get(dataForm, 'customerAddressBookId') || null,
          customerContactId: _.get(dataForm, 'customerContactId') || null,
          customerContactPhone: _.get(dataForm, 'customerContactPhone') || null,
          customerContactEmail: _.get(dataForm, 'customerContactEmail') || null,
          issueBy: _.get(values, 'by'),
          issueDate: moment(_.get(values, 'date')).format('YYYY-MM-DD'),
          referenceNo: _.get(values, 'refNo') || null,
          validDay: _.get(values, 'validDay'),
          validDate: moment(_.get(values, 'validDate')).format('YYYY-MM-DD'),
          remark: _.get(values, 'validRemark') || null,
          status: status,
          subTotal: _.get(dataForm, 'sumTotal') || 0,
          discountPercent: _.get(record, 'code') === 'view' ? _.get(dataForm, 'discountPercent') : parseFloat(_.get(values, 'discount')),
          discount: _.get(record, 'code') === 'view' ? _.get(dataForm, 'discount') : parseFloat(_.get(values, 'discountNumber')),
          // subTotalAfterDiscount: _.sumBy(selectItem, 'totalPrice') - parseFloat(_.get(values, 'discountNumber')),
          subTotalAfterDiscount:
            _.get(record, 'code') === 'view'
              ? _.get(dataForm, 'subTotalAfterDiscount')
              : changeToNumber(_.get(dataForm, 'subtotalDiscount')) || '0',
          subTotal: _.get(record, 'code') === 'view' ? _.get(dataForm, 'subTotal') : changeToNumber(_.get(dataForm, 'sumTotal')) || 0,
          tax: _.get(record, 'code') === 'view' ? _.get(dataForm, 'tax') : _.get(values, 'taxNumber') || 0,
          taxPercent: _.get(record, 'code') === 'view' ? _.get(dataForm, 'taxPercent') : _.get(values, 'tax') || 0,
          total: _.get(record, 'code') === 'view' ? _.get(dataForm, 'total') : sumtotal || 0,
          itemList: itemList,
          quotationId: _.get(dataForm, 'quotationId') || null,
          quotationNo: _.get(dataForm, 'quotationNo') || null,
          purchaseOrderNo: _.get(values, 'poOrderNo') || null,
          purchaseOrderDate: _.get(values, 'poDate') ? moment(_.get(values, 'poDate')).format('YYYY-MM-DD') : null,
          saleOrderId: _.get(dataForm, 'saleOrderId') || null,
          saleOrderNo: _.get(dataForm, 'saleOrderNo') || null,
          code: _.get(dataForm, 'code') || null,
          reference: _.get(values, 'reference') || null,
        };
        onOk(payload, resetFields, onResetState);
        // resetFields();
        // setCheckeQty(false);
        // setDataForm();
        // setCreateData();
        // setAttData([]);
        // setSelectItem([]);
        // setSearchGroup({ discount: 0, discountNumber: 0.0 });
        // setPaymentItem([]);
      });
    }
  };

  const onResetState = () => {
    setCheckeQty(false);
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setSearchGroup({ discount: 0, discountNumber: 0.0 });
    setPaymentItem([]);
  };

  const handleCancelQuotation = () => {
    onCancel();
    setCheckeQty(false);
    resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setSearchGroup({ discount: 0, discountNumber: 0.0 });
    setPaymentItem([]);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleOk = () => {
    setIsOpen(false);
    setDataForm((prev) => ({ ...prev, quotationObj: {} }));
    form.setFieldsValue({ quotationNo: '' });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const operations = (
    <div className="tag-center-div" style={{ padding: '12px 12px' }}>
      <Tag className="tag-center-style" color={_.get(record, 'status.color')}>
        {_.get(record, 'status.txt')}
      </Tag>
    </div>
  );

  // const handlePrint = useReactToPrint({
  //   content: () => saleOrderPrintRef.current,
  //   documentTitle: 'data',
  //   copyStyles: true,
  // });

  const handlePrint = async () => {
    // const formateData = fomatePDF(editDefault)
    const body = {
      detail: {
        ...record,
        issueDate: moment(record.issueDate, 'YYYY-MM-DD')
          .add(543, 'years')
          .format('DD/MM/YYYY'),
        purchaseOrderDate: record.purchaseOrderDate
          ? moment(record.purchaseOrderDate, 'YYYY-MM-DD')
              .add(543, 'years')
              .format('DD/MM/YYYY')
          : null,
        totalAmoutBathThai: ThaiNumberToText(_.toString((record.total || 0).toFixed(2))),
        discount: numberFormatter(record.discount || 0),
        subTotal: numberFormatter(record.subTotal || 0),
        subTotalAfterDiscount: numberFormatter(record.subTotalAfterDiscount || 0),
        tax: numberFormatter(record.tax || 0),
        total: numberFormatter(record.total || 0),
      },
      item: _.map(selectItem, (item) => {
        return {
          ...item,
          pricePerUnit: numberFormatter(item.pricePerUnit || 0),
          discount: numberFormatter(item.discount || 0),
          total: numberFormatter(item.total || 0),
        };
      }),
    };
    const response = await SaleOrderPDFDownloader(_.get(record, 'saleOrderNo'), body);
    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      if (url) {
        setUrlPrint(url);
        setVisiblePrint(true);
      }
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          id: _.get(record, 'saleOrderId'),
          code: _.get(record, 'code'),
          status: 'deleted',
        };
        resetFields();
        setDataForm();
        setCreateData();
        setAttData([]);
        setSelectItem([]);
        setSearchGroup({ discount: 0, discountNumber: 0.0 });
        setPaymentItem([]);
        handleSaveDelete(payload);
      },
      handleCancelQuotation() {},
    });
  };

  const handleEdit = () => {
    refreshModalSaleOrder(_.get(record, 'saleOrderId'), 'edit');
  };

  const actionFooter =
    _.get(record, 'status.code') === 'waiting'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleDelete()}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="cancelQT"
            type="primary"
            btnsize="wd_at"
            onClick={() => handleOpenCancel()}
          >
            <FormattedMessage id="btnCancelSalesOrder" defaultMessage="Cancel Sales Order" />
          </Button_01>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" btnsize="wd_df" onClick={() => handleOpenReject()}>
            <FormattedMessage id="btnReject" defaultMessage="Reject" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="approve"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleOpenApprove()}
          >
            <FormattedMessage id="btnApprove" defaultMessage="Approve" />
          </Button_01>,
        ]
      : _.get(record, 'status.code') === 'draft'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="delte" btnsize="wd_df" onClick={() => handleDelete()}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="cancelQT"
            type="primary"
            btnsize="wd_at"
            onClick={() => handleOpenCancel()}
          >
            <FormattedMessage id="btnCancelSalesOrder" defaultMessage="Cancel Sales Order" />
          </Button_01>,
          _.get(record, 'code') === 'view' && (
            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="edit" btnsize="wd_df" type="primary" onClick={() => handleEdit()}>
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button_01>
          ),
          _.get(record, 'code') === 'edit' && (
            <>
              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="draft"
                btnsize="wd_df"
                type="primary"
                onClick={() => handleSave('draft')}
                disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
              >
                <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
              </Button_01>

              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="submit"
                type="primary"
                btnsize="wd_df"
                disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
                onClick={() => handleSave('waiting')}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button_01>
            </>
          ),
        ]
      : _.get(record, 'status.code') === 'approved'
      ? [
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                <FormattedMessage id="btnPrint" defaultMessage="Print" />
              </Button_01>
            </div>
            <div>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
              </Button_02>
              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="waiting"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleOpenWaiting()}
              >
                <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
              </Button_01>
              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="reject"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleOpenReject()}
              >
                <FormattedMessage id="btnReject" defaultMessage="Reject" />
              </Button_01>
            </div>
          </div>,
        ]
      : _.get(record, 'status.code') === 'canceled'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnClose" defaultMessage="Close" />
          </Button_02>,
        ]
      : _.get(record, 'status.code') === 'rejected'
      ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          // <Button_01
          //   style={{ margin: '0px 0px 0px 10px', width: '12%' }}
          //   key="save"
          //   type="primary"
          //   btnsize="wd_df"
          //   onClick={() => handleSave('draftTorejected')}
          // >
          //   <FormattedMessage id="btnDraft" defaultMessage="Draft" />
          // </Button_01>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" type="primary" btnsize="wd_df" onClick={handleOpenWaiting}>
            <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="approve"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleOpenApprove()}
          >
            <FormattedMessage id="btnApprove" defaultMessage="Approve" />
          </Button_01>,
        ]
      : [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_02 style={{ margin: '0px 0px 0px 10px', width: '12%' }} key="draft" btnsize="wd_df" onClick={() => handleSave('draft')}>
            <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
          </Button_02>,
          <Button_01 key="save" type="primary" btnsize="wd_df" disabled={_.size(selectItem) === 0} onClick={() => handleSave('waiting')}>
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>,
        ];

  console.log('handleButton', record, nextStatus);
  const handleButton = () => {
    if (_.get(record, 'code') === 'view') {
      return [
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {checkPermissionAction('P11PG1C1', 'P11PG1C1A9') && (
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                <FormattedMessage id="btnPrint" defaultMessage="Print" />
              </Button_01>
            )}
          </div>
          <div>{renderButtons()}</div>
        </div>,
      ];
    } else if (_.get(record, 'code') === 'edit') {
      return renderButtons();
    } else {
      return renderButtonsCreate();
    }
  };

  const renderButtons = () => {
    const buttons = [];

    buttons.push(
      <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button_02>
    );
    if (_.includes(nextStatus, 'canceled') && _.get(record, 'code') !== 'edit' && checkPermissionAction('P11PG1C1', 'P11PG1C1A5')) {
      buttons.push(
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="cancelQT" type="primary" btnsize="wd_df" onClick={() => handleOpenCancel()}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_01>
      );
    }

    if (_.includes(nextStatus, 'torevise') && checkPermissionAction('P11PG1C1', 'P11PG1C1A10')) {
      buttons.push(
        <Button_01
          style={{ margin: '0px 0px 0px 10px' }}
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() =>
            handleSaveToRevise({
              id: _.get(record, 'saleOrderId'),
              code: _.get(record, 'code'),
              status: 'torevise',
              // statusRemark: null,
            })
          }
        >
          <FormattedMessage id="btnToRevise" defaultMessage="To Revise" />
        </Button_01>
      );
    }

    if (_.includes(nextStatus, 'deleted') && checkPermissionAction('P11PG1C1', 'P11PG1C1A4')) {
      buttons.push(
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleDelete()}>
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </Button_01>
      );
    }

    if (
      (tabMain === 'draft' || tabMain === 'torevise') &&
      _.get(record, 'code') === 'view' &&
      checkPermissionAction('P11PG1C1', 'P11PG1C1A3')
    ) {
      buttons.push(
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="edit" btnsize="wd_df" type="primary" onClick={() => handleEdit()}>
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button_01>
      );
    }

    if (_.get(record, 'code') === 'edit' && checkPermissionAction('P11PG1C1', 'P11PG1C1A6')) {
      buttons.push(
        <Button_01
          style={{ margin: '0px 0px 0px 10px' }}
          key="draft"
          btnsize="wd_df"
          type="primary"
          onClick={() => handleSave(tabMain)}
          disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>
      );
    }

    if (_.includes(nextStatus, 'waiting') && checkPermissionAction('P11PG1C1', 'P11PG1C1A7')) {
      buttons.push(
        <Button_01
          style={{ margin: '0px 0px 0px 10px', width: 'auto' }}
          key="submit"
          type="primary"
          btnsize="wd_df"
          disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
          onClick={() => handleSave('waiting')}
        >
          <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
        </Button_01>
      );
    }

    if (_.includes(nextStatus, 'approved') && checkPermissionAction('P11PG1C1', 'P11PG1C1A12')) {
      buttons.push(
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="approve" type="primary" btnsize="wd_df" onClick={() => handleOpenApprove()}>
          <FormattedMessage id="btnApprove" defaultMessage="Approve" />
        </Button_01>
      );
    }

    if (_.includes(nextStatus, 'rejected') && checkPermissionAction('P11PG1C1', 'P11PG1C1A13')) {
      buttons.push(
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" btnsize="wd_df" onClick={() => handleOpenReject()}>
          <FormattedMessage id="btnReject" defaultMessage="Reject" />
        </Button_01>
      );
    }

    return buttons;
  };

  const renderButtonsCreate = () => {
    const buttons = [];

    buttons.push(
      <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button_02>
    );
    if (checkPermissionAction('P11PG1C1', 'P11PG1C1A6')) {
      buttons.push(
        <Button_01
          style={{ margin: '0px 0px 0px 10px' }}
          key="draft"
          btnsize="wd_df"
          type="primary"
          onClick={() => handleSave('draft')}
          disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>
      );
    }

    if (checkPermissionAction('P11PG1C1', 'P11PG1C1A7')) {
      buttons.push(
        <Button_01
          style={{ margin: '0px 0px 0px 10px', width: 'auto' }}
          key="submit"
          type="primary"
          btnsize="wd_df"
          disabled={_.size(selectItem) === 0 ? true : checkQty ? true : false}
          onClick={() => handleSave('waiting')}
        >
          <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
        </Button_01>
      );
    }

    return buttons;
  };

  console.log('renderButtons', renderButtons());

  const handleOpenModalEdit = (data) => {
    setEditData(data);
    setEditService(data);
    if (_.get(data, 'type') === 'product') setVisibleEdit(true);
    if (_.get(data, 'type') === 'service') setVisibleEditService(true);
    // setEditData(data);
    // setVisibleEdit(true);
  };

  const onChangeDiscount = async (value, code) => {
    const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, 'totalPrice') : 0.0;
    if (code === 'discount') {
      setSearchGroup({ discount: parseInt(value || 0), discountNumber: ((sumTotal * parseInt(value || 0)) / 100).toFixed(2) });
    } else if (code === 'discountNumber') {
      setSearchGroup({ discount: ((parseFloat(value || 0.0) / sumTotal) * 100).toFixed(2), discountNumber: parseFloat(value || 0.0) });
    }
  };

  const handleOpenModalListService = () => {
    setVisibleListService(true);
  };

  const menuColumn = (
    <Menu>
      <Menu.Item onClick={() => setVisibleItem(true)}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnItem" defaultMessage="Item" />
        </Button>
      </Menu.Item>
      <Menu.Item onClick={handleOpenModalListService}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnService" defaultMessage="Service" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancelQuotation}
      width={1000}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      footer={visible ? handleButton() : null}
    >
      {_.get(record, 'code') === 'view' && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
            tabBarExtraContent={operations}
          >
            {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTabView
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  selectCustomer,
                  record,
                  checkQty,
                }}
              />
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelPayment`, defaultMessage: 'Payment' })} key="payment">
              <PaymentTabView dataSource={paymentItem} selectItem={selectItem} setSelectItem={setSelectItem} />
            </TabPane>
            {/* ) : null} */}
          </Tabs>
        </StickyContainer>
      )}

      {_.get(record, 'code') === 'edit' && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
            tabBarExtraContent={operations}
          >
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTab
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  formCustomerRef,
                  isOpenQuotation,
                  record,
                  handleOpenModalEdit,
                  onChangeDiscount,
                  searchGroup,
                  handleDeleteAttAchment,
                  menuColumn,
                  checkQty,
                }}
              />

              <ModalSelectCustomerForm
                ref={formCustomerRef}
                visible={isOpen}
                setIsOpen={setIsOpen}
                onOk={handleOk}
                dataForm={dataForm}
                setDataForm={setDataForm}
                isOpenQuotation={isOpenQuotation}
                selectCustomer={selectCustomer}
                setSelectCustomer={setSelectCustomer}
                setSelectItem={setSelectItem}
              />

              <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
              />

              <ModalEditListItem
                visible={visibleEdit}
                setVisible={setVisibleEdit}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                data={editData}
              />
            </TabPane>
            <TabPane tab={intl.formatMessage({ id: `quotationLabelPayment`, defaultMessage: 'Payment' })} key="payment">
              <PaymentTab dataSource={paymentItem} setDataSource={setPaymentItem} selectItem={selectItem} setSelectItem={setSelectItem} />
            </TabPane>
          </Tabs>
        </StickyContainer>
      )}

      {!_.get(record, 'code') && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
          >
            {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTab
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  setIsOpenQt,
                  isOpenQt,
                  formCustomerRef,
                  isOpenQuotation,
                  handleOpenModalEdit,
                  onChangeDiscount,
                  searchGroup,
                  menuColumn,
                  checkQty,
                }}
              />

              <ModalSelectCustomerForm
                ref={formCustomerRef}
                visible={isOpen}
                setIsOpen={setIsOpen}
                onOk={handleOk}
                dataForm={dataForm}
                setDataForm={setDataForm}
                isOpenQuotation={isOpenQuotation}
                selectCustomer={selectCustomer}
                setSelectCustomer={setSelectCustomer}
                setSelectItem={setSelectItem}
              />

              <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
                formCustomerRef={formCustomerRef}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
              />

              <ModalEditListItem
                visible={visibleEdit}
                setVisible={setVisibleEdit}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                data={editData}
              />
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelPayment`, defaultMessage: 'Payment' })} key="payment">
              <PaymentTab dataSource={paymentItem} setDataSource={setPaymentItem} selectItem={selectItem} setSelectItem={setSelectItem} />
            </TabPane>
            {/* ) : null} */}
          </Tabs>
        </StickyContainer>
      )}

      <div style={{ display: 'none' }}>
        {/* <div> */}
        <SaleOrderPrint
          ref={saleOrderPrintRef}
          record={record}
          recordReport={recordReport}
          sizeRecordReport={sizeRecordReport}
          signatureUser={signatureUser}
          signatureIssu={signatureIssu}
        />
      </div>

      <ModalApproveForm
        title={intl.formatMessage({ id: `quotationModalApproved`, defaultMessage: 'Approved' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleApprove}
        onOk={handleSaveApprove}
        onCancel={handleCancelApprove}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />
      <ModalCancelForm
        title={
          intl.formatMessage({ id: `quotationModalCancelSalesOrder`, defaultMessage: 'Cancel Sales Order' }) +
          ' · ' +
          _.get(record, 'saleOrderNo')
        }
        visible={visibleCancel}
        onOk={handleSaveCancel}
        onCancel={handleCancelCancelQT}
        initialReasonList={initialReasonList}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />
      <ModalRejectForm
        title={intl.formatMessage({ id: `quotationModalRejected`, defaultMessage: 'Rejected' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleReject}
        onOk={handleSaveReject}
        onCancel={handleCancelReject}
        initialReasonList={initialReasonList}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />
      <ModalDraftForm
        title={intl.formatMessage({ id: `quotationModalDraft`, defaultMessage: 'Draft' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleDelete}
        onOk={handleSaveDelete}
        onCancel={handleCancelDelete}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />
      <ModalWaitingForm
        title={intl.formatMessage({ id: `quotationModalWaiting`, defaultMessage: 'Waiting' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleWaiting}
        onOk={handleSaveWaiting}
        onCancel={handleCancelWaiting}
        saleOrderId={_.get(record, 'saleOrderId')}
        code={_.get(record, 'code')}
      />

      <ListItemServiceSO
        visible={visibleListService}
        setVisible={setVisibleListService}
        setVisibleAdd={setVisibleAddService}
        setCreateData={setCreatService}
        dataForm={dataForm}
      />
      <ModalCreateListServiceItemSO
        visible={visibleAddService}
        setVisible={setVisibleAddService}
        data={creatService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <ModalEditListServiceItemSO
        visible={visibleEditService}
        setVisible={setVisibleEditService}
        data={editService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalPDFViewver visible={visiblePrint} setVisible={setVisiblePrint} url={urlPrint} handlePrint={handlePrint} />
    </Modal>
  );
};

SaleOrderModal.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCustomerSaleOrderForm = Form.create({ name: 'Customer_Form' })(SaleOrderModal);

export default ModalCustomerSaleOrderForm;
