import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');
const memComId = localStorage.getItem('memComId');

const getInvoiceList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/invoice/page`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const createInvoice = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/invoice`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error.response.data;
  }
};

const getDetailInvoice = async (id) => {
  try {
    const response = await httpClient.get(`/v3/invoice/manager/company/${comCode}/invoice/detail/${id}`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const uploadFileInvoice = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/invoice/document`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const InvoicePDFDownloader = async (no, payload) => {
  const comCode = localStorage.getItem('comCode');
  try {
    let url = `/v1/po/manager/company/${comCode}/invoice-pdf-v2/${no}`
    if (comCode === "SA" || comCode === "SAT") {
      url = `/v1/po/manager/company/${comCode}/invoice-pdf/${no}`
    }
    const response = await httpClient.post(url, payload, {
      responseType: 'blob'
    })
    if (response.status === 200) {
      return response
    } else {
      return []
    }
  } catch (error) {
  }
}

const updatestatusInvoice = async (payload) => {
  try {
    const response = await httpClient.put(`/v3/invoice/manager/company/${comCode}/invoice`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return error.response.data;
  }
};

const exportFileInvoice = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/invoice/export`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getDefaultAllList = async (payload) => {
  try {
    const response = await httpClient.post(`/v4/task/manager/company/${comCode}/inittialtask`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getTaskAtbillingList = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/taskbl/page`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const exportFileBilling = async (payload) => {
  try {
    const response = await httpClient.post(`/v3/invoice/manager/company/${comCode}/invoicetaskpending/export`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getTeamOrg = async () => {
  try {
    const response = await httpClient.get(`/v4/organizations/company/${comCode}/member/${memComId}`);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
}

export {
  getInvoiceList,
  createInvoice,
  getDetailInvoice,
  uploadFileInvoice,
  updatestatusInvoice,
  exportFileInvoice,
  getDefaultAllList,
  getTaskAtbillingList,
  exportFileBilling,
  InvoicePDFDownloader,
  getTeamOrg
};
