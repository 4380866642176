import React, { PureComponent, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ChartLine = ({ data, legendFormatter, datasetConfig, config = {} }) => {
  const { domain = { left: [0, 'auto'], right: [0, 100] }, yAxisIdMapping = {}, yLeft, yRight } = config;

  const hasRightYAxisData = Object.values(yAxisIdMapping).includes('right');
  const shouldRotateLabels = data.length > 6;
  const [xAxisHeight, setXAxisHeight] = useState(30);
  const [containerHeight, setContainerHeight] = useState(350);

  useEffect(() => {
    if (data && data.length > 0) {
      const longestLabel = data.reduce((longest, item) => (item.name.length > longest.length ? item.name : longest), '');
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const textWidth = context.measureText(longestLabel).width;
      const calculatedHeight = Math.min(120, Math.ceil(textWidth * Math.sin(Math.PI / 4))); // Use angle to adjust height
      const xAxisCalculatedHeight = calculatedHeight + 20; // Add padding
      setXAxisHeight(xAxisCalculatedHeight);

      // Adjust container height dynamically
      const baseHeight = 350;
      const extraHeight = xAxisCalculatedHeight > 30 ? xAxisCalculatedHeight - 30 : 0;
      setContainerHeight(baseHeight + extraHeight);
    }
  }, [data]);

  return (
    <ResponsiveContainer style={{ marginTop: '20px', padding: '5px' }} width="100%" height={shouldRotateLabels ? containerHeight : 350}>
      <LineChart data={data}>
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        <XAxis
          dataKey="name"
          padding={data.length > 4 ? { left: 30, right: 30 } : { left: 150, right: 150 }}
          stroke="#050505"
          tick={{ fontSize: 12, fill: '#050505' }}
          angle={shouldRotateLabels ? -90 : 0}
          textAnchor={shouldRotateLabels ? 'end' : 'middle'}
          // height={shouldRotateLabels ? 80 : undefined}
          height={shouldRotateLabels ? xAxisHeight + 40 : undefined}
        />
        <YAxis
          label={{ value: yLeft, angle: -90, position: 'insideLeft', fill: '#050505' }}
          domain={domain.left}
          yAxisId="left"
          tickCount={6}
          stroke="#050505"
          tickFormatter={(value) => {
            if (value >= 1000000) return `${Math.floor(value / 1000000)}M`;
            if (value >= 1000) return `${Math.floor(value / 1000)}k`;
            return Math.floor(value);
          }}
        />
        {hasRightYAxisData && (
          <YAxis
            orientation="right"
            label={{ value: yRight, angle: 90, position: 'insideRight', fill: '#050505' }}
            domain={domain.right}
            yAxisId="right"
            tickCount={6}
            stroke="#050505"
            tickFormatter={(value) => {
              if (value >= 1000000) return `${value / 1000000}M`;
              if (value >= 1000) return `${value / 1000}k`;
              return value;
            }}
          />
        )}
        <Tooltip formatter={(value) => value.toLocaleString()} />
        <Legend
          formatter={legendFormatter}
          wrapperStyle={{
            position: 'relative',
          }}
        />
        {Object.keys(datasetConfig).map((key) => (
          <Line
            key={key}
            name={datasetConfig[key].label}
            dataKey={key}
            dot={{ strokeDasharray: '' }}
            stroke={datasetConfig[key].color}
            strokeDasharray={datasetConfig[key].stokeDasharray}
            yAxisId={yAxisIdMapping[key] || 'left'}
            legendType={datasetConfig[key].legendType}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

ChartLine.defaultProps = {
  data: [],
  legendFormatter: (value) => value,
  datasetConfig: {},
};

export default ChartLine;
