import '../index.css';
import React from 'react';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';

const SummaryNetVehicleIncomeReport = (props) => {
  const { listSummaryData } = props;

  return (
    <Row type="flex" justify="center" align="middle" style={{ padding: '32px 40px 16px 40px' }}>
      <Col style={{ textAlign: 'center', flex: 1 }}>
        <div className="summary-net-vehicle-income-report-title">
          <FormattedMessage id="summaryNetVehicleIncomeReportTotalTripTitle" defaultMessage="Total Trip" />
        </div>
        <div className="summary-net-vehicle-income-report-value">{numberFormatNoDecimal(_.get(listSummaryData, 'totalTrip'))}</div>
      </Col>
      <Col style={{ textAlign: 'center', flex: 1 }}>
        <div className="summary-net-vehicle-income-report-title">
          <FormattedMessage id="summaryNetVehicleIncomeReportTotalRevenueTitle" defaultMessage="Total Revenue" />
        </div>
        <div className="summary-net-vehicle-income-report-value">{_.get(listSummaryData, 'totalRevenue')} THB</div>
      </Col>
      <Col style={{ textAlign: 'center', flex: 1 }}>
        <div className="summary-net-vehicle-income-report-title">
          <FormattedMessage id="summaryNetVehicleIncomeReportTotalExpenseTitle" defaultMessage="Total Expense" />
        </div>
        <div className="summary-net-vehicle-income-report-value">{_.get(listSummaryData, 'totalExpense')} THB</div>
      </Col>
      <Col style={{ textAlign: 'center', flex: 1 }}>
        <div className="summary-net-vehicle-income-report-title">
          <FormattedMessage id="summaryNetVehicleIncomeReportTotalEarningsTitle" defaultMessage="Total Earnings" />
        </div>
        <div className="summary-net-vehicle-income-report-value">{_.get(listSummaryData, 'totalEarnings')} THB</div>
      </Col>
      <Col style={{ textAlign: 'center', flex: 1 }}>
        <div className="summary-net-vehicle-income-report-title">
          <FormattedMessage id="summaryNetVehicleIncomeReportAvgEarningsPerTripTitle" defaultMessage="Avg. Earnings per Trip" />
        </div>
        <div className="summary-net-vehicle-income-report-value">{_.get(listSummaryData, 'avgEarningsPerTrip')} THB</div>
      </Col>
    </Row>
  );
};

export default SummaryNetVehicleIncomeReport;
