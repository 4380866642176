import { Col, DatePicker, Form, Input, Row, Select, Tag, Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import LabeRequireForm from '../../../components/label-required-form';
import CustomTableComponent from '../components/table';
import CollapseCustom from '../../../components/collapse-custom';
import { messageLabel } from '../../../components/message-component';
import _ from 'lodash';
import { numberFormatDecimal, numberFormatNoDecimal } from '../../../component-function/fnc-number-format-decimal';
import { QuotationContext } from '../context';
import QuotationModal from '../modal/quotation/quotation-modal';
import moment from 'moment';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import { useAppContext } from '../../../includes/indexProvider';
import { PageSettings } from '../../../config/page-settings';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const WaitingQuotation = () => {
  const { checkPermissionAction } = useContext(PageSettings);
  const { state, setState, func } = useContext(QuotationContext);
  const { isOpenQuotation, reportDataDynamic, statusList } = state;
  const { seIsOpenQuotation, setReportDataDynamic } = setState;
  const { handleOpenModalQuotation } = func;
  const { quotationList, quotationTotal, paginationPage, loading, rangePickerDate } = reportDataDynamic;
  const intl = useIntl();

  const app = useAppContext();
  const langValue = app.state.langValue;
  const [locale, setLocale] = useState(enUS);
  let codeLang = langValue ? langValue : 'EN';
  let changeLang = codeLang === 'EN' ? enUS : thTH;
  console.log('langgggg', changeLang, locale);

  useEffect(() => {
    changeLocale();
  }, []);

  const changeLocale = () => {
    setLocale(changeLang);
    if (!changeLang) {
      moment.locale('en');
    } else {
      moment.locale('th-th');
    }
  };

  const handleFieldSort = (value) => {
    setReportDataDynamic((prev) => ({ ...prev, fieldSort: value }));
  };

  const handleOrderSort = (value) => {
    setReportDataDynamic((prev) => ({ ...prev, orderSort: value }));
  };

  const handlePaginationPage = (value) => {
    setReportDataDynamic((prev) => ({ ...prev, paginationPage: value }));
  };

  const handlePaginationSize = (value) => {
    setReportDataDynamic((prev) => ({ ...prev, paginationSize: value }));
  };

  const handleSelectRangePicker = (value) => {
    setReportDataDynamic((prev) => ({ ...prev, rangePickerDate: value, paginationPage: 1 }));
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      fixed: 'left',
      width: 70,
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('quotationLabelQuotationNo', 'Quotation No'),
      dataIndex: 'quotationNo',
      key: 'quotationNo',
      sorter: true,
      fixed: 'left',
      width: 180,
    },
    // {
    //   title: messageLabel('quotationLabelSaleOrderNo', 'Sale Order No.'),
    //   dataIndex: 'saleOrderNo',
    //   key: 'saleOrderNo',
    //   sorter: true,
    //   fixed: 'left',
    //   width: 150,
    //   ellipsis: true,
    // },
    {
      title: messageLabel('quotationLabelCustomer', 'Customer'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      // fixed: 'left',
      // width: 200,
    },
    {
      title: messageLabel('quotationLabelItem', 'Item'),
      dataIndex: 'totalItem',
      key: 'totalItem',
      sorter: true,
      render: (text, record, index) => _.get(record, 'totalItem'),
    },
    {
      title: messageLabel('quotationLabelTotal', 'Total'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      width: 200,
      align: 'right',
      render: (text, record, index) => (
        // _.get(record, 'total') ? (
        <span>
          {numberFormatDecimal(_.get(record, 'total'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
        </span>
      ),
      // ) : null,
    },
    {
      title: messageLabel('quotationLabelStatus', 'Status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: 200,
      align: 'center',
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag className="tag-center-style" color={record.status.color}>
            {record.status.txt}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('quotationLabelIssuedBy', 'Issued by'),
      dataIndex: 'issuedName',
      key: 'issuedName',
      sorter: true,
      // render: (text, record, index) => '',
    },
    {
      title: messageLabel('quotationLabelIssuedDate', 'Issued Date'),
      dataIndex: 'issueDate',
      key: 'issueDate',
      sorter: true,
      render: (text, record, index) =>
        _.get(record, 'issueDate') ? moment(_.get(record, 'issueDate'), 'YYYY-MM-DD').format(dateFormat) : null,
    },
  ];

  const advancedFilter = (action, value) => {
    const handleChange = (value, key) => {
      action((prev) => ({ ...prev, [key]: value, paginationPage: 1 }));
    };

    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelQuotationNo', defaultMessage: 'Quotation No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'quotationNo')}
                onChange={(e) => handleChange(e.target.value, 'quotationNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintQuotationNo', defaultMessage: 'Enter Quotation No.' })}
              />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'quotationLabelSaleOrderNo', defaultMessage: 'Sale Order No.' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'saleOrderNo')}
                onChange={(e) => handleChange(e.target.value, 'saleOrderNo')}
                placeholder={intl.formatMessage({ id: 'quotationHintSaleOrderNo', defaultMessage: 'Enter Sale Order No.' })}
              />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelCustomer', defaultMessage: 'Customer' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(value, 'customerName')}
                onChange={(e) => handleChange(e.target.value, 'customerName')}
                placeholder={intl.formatMessage({ id: 'quotationHintCustomer', defaultMessage: 'Enter Customer' })}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelStatus', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => handleChange(value, 'status')}
                placeholder={intl.formatMessage({ id: 'quotationHintStatus', defaultMessage: 'Select Status' })}
                mode="multiple"
              >
                {statusList &&
                  statusList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.txt}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={<LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelStatus', defaultMessage: 'Status' })} req={false} />}
            >
              <Select
                allowClear
                onChange={(value) => handleChange(value, 'status')}
                placeholder={intl.formatMessage({ id: 'quotationHintStatus', defaultMessage: 'Select Status' })}
                mode="multiple"
              >
                {statusList &&
                  statusList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.txt}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row> */}
      </Form>
    );
  };

  const contentAction = (record) => {
    console.log('content', record);
    return (
      <div style={{ display: 'grid' }}>
        {checkPermissionAction('P28PG1C1', 'P28PG1C1A4') && (
          <div>
            <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModalQuotation(record, 'view')}>
              <FormattedMessage id="btnView" defaultMessage="View" />
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <RangePicker
              value={rangePickerDate}
              format={dateFormat}
              onChange={handleSelectRangePicker}
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'quotationFilterPHFormDueDate', defaultMessage: 'Select Form Date' }),
                intl.formatMessage({ id: 'quotationFilterPHToDueDate', defaultMessage: 'Selet To Date' }),
              ]}
              locale={locale}
            />
          }
        >
          {advancedFilter(setReportDataDynamic, reportDataDynamic)}
        </CollapseCustom>
      </div>
      <div className="content-body-report">
        <CustomTableComponent
          rowKey={(record) => record.quotationId}
          columns={columns}
          dataSource={quotationList}
          scroll={{ x: true }}
          role={true}
          onRow={true}
          total={quotationTotal}
          loading={loading}
          paginationPage={paginationPage}
          setFieldSort={handleFieldSort}
          setOrderSort={handleOrderSort}
          setPaginationSize={handlePaginationSize}
          setPaginationPage={handlePaginationPage}
          contentAction={contentAction}
        />
      </div>
    </div>
  );
};

export default WaitingQuotation;
