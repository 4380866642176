import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import SearchItemRequest from '../../components/item-request/search'
import ItemRequestFilter from './component/filter'
import ItemRequestSearchInput from './component/search'
import ItemRequestContent from './component/content'
import ModalRequestItem from '../../components/item-request/request-item'
import ModalFormBasketItem from '../../components/item-request/basket'
import { Row, Col, Card } from 'antd'
import { getWareHouseList } from '../../controllers/warehouse/warehouse'
import { getItemGroupAndType, getRequestItemDetail } from '../../controllers/item-request/item-request'
import { useDebounce } from '../../controllers/debounce'
import NoDataBackground2 from '../../components/no-data-new'
import { PageSettings } from '../../config/page-settings'

const ItemRequest = () => {
  const [visibleModalSearch, setVisibleModalSearch] = useState(true)
  const [visibleModalRequest, setVisibleModalRequest] = useState(false)
  const [visibleModalBasket, setVisibleModalBasket] = useState(false)
  const [basket, setBasket] = useState([])
  const [toggle, setToggle] = useState(true);
  const [requestData, setRequestData] = useState()
  const [panelWarehouse, setPanelWarehouse] = useState(false)
  const [panelItemBrand, setPanelItemBrand] = useState(false)
  const [panelItemGroup, setPanelItemGroup] = useState(false)
  const [panelItemType, setPanelItemType] = useState(false)
  const [warehouseData, setWarehouseData] = useState([])
  const [warehouseCheckAll, setWarehouseCheckAll] = useState(false)
  const [warehouseInterminate, setWarehouseInterminate] = useState(false)
  const [warehouseSelect, setWarehouseSelect] = useState([])
  const [itemBrandData, setItemBrandData] = useState([])
  const [itemBrandCheckAll, setItemBrandCheckAll] = useState(false)
  const [itemBrandInterminate, setItemBrandInterminate] = useState(false)
  const [itemBrandSelect, setItemBrandSelect] = useState([])
  const [itemGroupData, setItemGroupData] = useState([])
  const [itemGroupCheckAll, setItemGroupCheckAll] = useState(false)
  const [itemGroupInterminate, setItemGroupInterminate] = useState(false)
  const [itemGroupSelect, setItemGroupSelect] = useState([])
  const [itemTypeData, setItemTypeData] = useState([])
  const [itemTypeCheckAll, setItemTypeCheckAll] = useState(false)
  const [itemTypeInterminate, setItemTypeInterminate] = useState(false)
  const [itemTypeSelect, setItemTypeSelect] = useState([])
  const [itemData, setItemData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [mainTrigger, setMainTrigger] = useState(false)
  const [scroll, setScroll] = useState(12)
  const debouncSearch = useDebounce(searchValue, 500)
  const debounceScroll = useDebounce(scroll, 500)
  const [requestTypeData, setRequestTypeData] = useState([])

  useEffect(() => {
    async function GetWarehouse() {
      try {
        const payload = {
          indexStart: 0,
          indexEnd: 100,
          searchLeftMenu: '',
          isNotCheckPermission: true,
        }
        const response = await getWareHouseList(payload)
        setWarehouseData(_.get(response, 'data.data.warehouseList'))
      } catch (error) {

      }
    }
    GetWarehouse()
  }, [])

  useEffect(() => {
    async function GetWarehouseTypeAndGroup() {
      try {
        const body = {
          warehouseIdList: warehouseSelect
        }
        const response = await getItemGroupAndType(body)
        setItemGroupData(_.get(response, 'data.listGroup'))
        setItemTypeData(_.get(response, 'data.listType'))
        setItemBrandData(_.get(response, 'data.listBrand'))
      } catch (error) {

      }
    }
    GetWarehouseTypeAndGroup()
  }, [warehouseSelect])

  useEffect(() => {
    async function GetAllItem() {
      try {
        const payload = {
          "warehouseIdList": warehouseSelect,
          "itemBrandIdList": itemBrandSelect,
          "itemGroupIdList": itemGroupSelect,
          "itemTypeIdList": itemTypeSelect,
          "indexStart": 0,
          "indexEnd": debounceScroll,
          "search": debouncSearch
        }
        const response = await getRequestItemDetail(payload)
        setItemData(_.chunk(_.get(response, 'data.stockList'), 4))
        setRequestTypeData(_.get(response, 'data.requestTypeList'))
      } catch (error) {

      }
    }
    if (visibleModalSearch === false) {
      GetAllItem()
    }
  }, [warehouseSelect, itemBrandSelect, itemGroupSelect, itemTypeSelect, visibleModalSearch, debouncSearch, debounceScroll, mainTrigger])




  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleClickItem = (value) => {
    console.log('handleClickBasket', value)
    setVisibleModalRequest(true)
    setRequestData({...value, uomId: _.get(value, 'uomId') ? _.get(value, 'uomId') : _.get(value, 'uom.uom_id')})
  }

  const handleClickBasket = () => {
    setVisibleModalBasket(true)
  }

  const handleChangeInput = (value) => {
    setSearchValue(value)
  }

  const onScroll = (e) => {
    const target = e.target;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 50) {
      setScroll(prevValue => prevValue + 12);
    }
  }


  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          {
            checkPermissionAction('P45PG1C1', 'P45PG1C1A1') ? (
              <Row gutter={[16]}>
                <Col span={toggle ? 6 : 0}>
                  <ItemRequestFilter
                    panelWarehouse={panelWarehouse}
                    setPanelWarehouse={setPanelWarehouse}
                    panelItemBrand={panelItemBrand}
                    setPanelItemBrand={setPanelItemBrand}
                    panelItemGroup={panelItemGroup}
                    setPanelItemGroup={setPanelItemGroup}
                    panelItemType={panelItemType}
                    setPanelItemType={setPanelItemType}
                    warehouse={{
                      warehouseData,
                      warehouseCheckAll,
                      setWarehouseCheckAll,
                      warehouseInterminate,
                      setWarehouseInterminate,
                      warehouseSelect,
                      setWarehouseSelect
                    }}
                    itemBrand={{
                      itemBrandData,
                      itemBrandCheckAll,
                      itemBrandInterminate,
                      itemBrandSelect,
                      setItemBrandCheckAll,
                      setItemBrandInterminate,
                      setItemBrandSelect,
                    }}
                    itemGroup={{
                      itemGroupData,
                      itemGroupCheckAll,
                      itemGroupInterminate,
                      itemGroupSelect,
                      setItemGroupCheckAll,
                      setItemGroupInterminate,
                      setItemGroupSelect,
                    }}
                    itemType={{
                      itemTypeData,
                      itemTypeCheckAll,
                      itemTypeInterminate,
                      itemTypeSelect,
                      setItemTypeCheckAll,
                      setItemTypeInterminate,
                      setItemTypeSelect,
                    }}
                  />
                </Col>
                <Col span={toggle ? 18 : 24}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <ItemRequestSearchInput
                        handleToggle={handleToggle}
                        toggle={toggle}
                        handleClickBasket={handleClickBasket}
                        basket={basket}
                        handleChangeInput={handleChangeInput}
                      />
                    </Col>
                    <Col span={24}>
                      {
                        _.size(itemData) > 0 ? (
                          <ItemRequestContent
                            data={itemData}
                            handleClickItem={handleClickItem}
                            onScroll={onScroll}
                          />
                        ) : (
                          <Card
                            title=''
                            style={{ borderRadius: '8px' }}
                            bodyStyle={{ height: 'calc(100vh - 179px)', paddingTop: 'unset' }}
                          >
                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <NoDataBackground2
                                text1={{ id: 'nodataXItemRequest1', text: 'Please Select Filter' }}
                                text2={{ id: 'nodataXItemRequest2', text: 'Item will appear here.' }}
                              />
                            </div>
                          </Card>
                        )
                      }

                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null
          }
          <SearchItemRequest
            visible={visibleModalSearch}
            setVisible={setVisibleModalSearch}
            warehouse={{
              warehouseData,
              warehouseCheckAll,
              setWarehouseCheckAll,
              warehouseInterminate,
              setWarehouseInterminate,
              warehouseSelect,
              setWarehouseSelect
            }}
            itemBrand={{
              itemBrandData,
              itemBrandCheckAll,
              itemBrandInterminate,
              itemBrandSelect,
              setItemBrandCheckAll,
              setItemBrandInterminate,
              setItemBrandSelect,
            }}
            itemGroup={{
              itemGroupData,
              itemGroupCheckAll,
              itemGroupInterminate,
              itemGroupSelect,
              setItemGroupCheckAll,
              setItemGroupInterminate,
              setItemGroupSelect,
            }}
            itemType={{
              itemTypeData,
              itemTypeCheckAll,
              itemTypeInterminate,
              itemTypeSelect,
              setItemTypeCheckAll,
              setItemTypeInterminate,
              setItemTypeSelect,
            }}
          />
          <ModalRequestItem
            visible={visibleModalRequest}
            setVisible={setVisibleModalRequest}
            data={requestData}
            basket={basket}
            setBasket={setBasket}
          />

          <ModalFormBasketItem
            visible={visibleModalBasket}
            setVisible={setVisibleModalBasket}
            basket={basket}
            setBasket={setBasket}
            handleClickItem={handleClickItem}
            setMainTrigger={setMainTrigger}
            requestTypeData={requestTypeData}
          />
        </div>
      )}

    </PageSettings.Consumer>
  )
}

export default ItemRequest
