import React, { useContext, useEffect, useState } from 'react';
import FilterMultiple from '../../component/filter-multiple';
import AdvanchImage from './advanch-image';
import { OverviewContext } from '../../context';
import FullScreen from 'react-fullscreen-crossbrowser';

const TabMapOverview = ({ fullscreenPageRef }) => {
  const { state, setState, func } = useContext(OverviewContext);
  const { mapSelectFilter, mapItemDashboard, filterDateMap } = state;
  const { setMapSelectFilter, setFilterDateMap, setFilterDateDuration } = setState;
  const [isFullScreen, setIsFullScreen] = useState(false);



  return (
    <FullScreen enabled={isFullScreen} onChange={setIsFullScreen} className="fullscreen-container">
      <div style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
        <div style={ !isFullScreen ? { 
          position: 'relative',
          zIndex: 2,
          backgroundColor: '#fff'
        } : {}}>
          <div style={ !isFullScreen ? { 
            position: 'sticky',
            left: 0,
            right: 0,
            width: '100%'
          } : {}}>
            <FilterMultiple 
              mapSelectFilter={mapSelectFilter} 
              setMapSelectFilter={setMapSelectFilter} 
              isFullScreen={isFullScreen} 
              setIsFullScreen={setIsFullScreen} 
            />
          </div>
        </div>

        <div style={ !isFullScreen ? { 
          position: 'relative',
          marginTop: '24px',
          zIndex: 1,
          overflow: 'auto'
        } : {}}>
          <AdvanchImage 
            mapSelectFilter={mapSelectFilter} 
            mapItemDashboard={mapItemDashboard} 
          />
        </div>
      </div>
    </FullScreen>
  );
};

export default TabMapOverview;