import { Col, DatePicker, Form, Row, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../../components/label-required-form';
import { numberFormatDecimal } from '../../../../../component-function/fnc-number-format-decimal';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'ddd, MMM DD YYYY';

const PriceFormView = ({ form, selectItem, setDataForm, dataForm, initialTaxList, record }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const intl = useIntl();
  const discount = parseInt(getFieldValue('discount') || 0);
  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.0);
  const tax = getFieldValue('tax');
  const taxNumber = parseFloat(getFieldValue('taxNumber') || 0.0);
  const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, (o) => _.toNumber(o.totalPrice)) : 0.0;
  const sumTotalSum = sumTotal;

  const subtotalDiscount = sumTotal - discountNumber;
  const subtotalTax = numberFormatter(parseFloat(subtotalDiscount) + taxNumber);
  console.log('PriceForm', selectItem, dataForm);
  console.log('PriceForm y', _.toNumber(sumTotal), sumTotal, _.toNumber(subtotalDiscount));
  console.log('PriceForm X', discount, discountNumber, tax, taxNumber, sumTotal, subtotalDiscount, subtotalTax);

  const [startUseEffectSumTotalSum, setStartUseEffectSumTotalSum] = useState(false);

  console.log('recorddddddddddd', !record, record, discount, discountNumber, dataForm, startUseEffectSumTotalSum);

  useEffect(() => {
    if (_.get(record, 'discountPercent') || _.get(record, 'discount')) {
      console.log('ondiscount', record, _.get(record, 'discountPercent'), _.get(record, 'discount'));
      setFieldsValue({
        ['discount']: _.get(record, 'discountPercent') ? _.get(record, 'discountPercent').toFixed(2) : 0.0,
        ['discountNumber']: _.get(record, 'discount') ? _.get(record, 'discount').toFixed(2) : 0.0,
      });
    }
  }, [record]);

  useEffect(() => {
    console.log('sumTotalSum', sumTotalSum, discount);

    if (startUseEffectSumTotalSum) {
      setFieldsValue({
        ['discountNumber']: ((sumTotalSum * discount) / 100).toFixed(2),
        ['taxNumber']: ((parseFloat(sumTotalSum) * tax) / 100).toFixed(2),
      });
    } else {
      setStartUseEffectSumTotalSum(true);
    }
  }, [sumTotalSum]);

  // useEffect(() => {
  //   if (discount && discount > 0) {
  //     setFieldsValue({
  //       ['discountNumber']: (sumTotal * discount) / 100,
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0,
  //       ['discountNumber']: 0.0,
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   }
  // }, [discount, sumTotal]);

  // useEffect(() => {
  //   if (discountNumber && discountNumber > 0) {
  //     setFieldsValue({
  //       ['discount']: (discountNumber / sumTotal) * 100,
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0,
  //       ['discountNumber']: 0.0,
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   }
  // }, [discountNumber, sumTotal]);

  useEffect(() => {
    if (tax >= 0) {
      setFieldsValue({
        ['taxNumber']: (parseFloat(subtotalDiscount) * tax) / 100,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: (parseFloat(subtotalDiscount) * tax) / 100,
      }));
    }
  }, [tax, subtotalDiscount]);

  return (
    <Form name="pirce">
      <Row gutter={[24]}>
        <Col span={10}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidto', defaultMessage: 'Valid to' })} req={false} />
                }
              >
                <Form.Item>
                  {getFieldDecorator('validDay', {
                    initialValue: _.get(record, 'validDay') || '',
                  })}
                  {_.get(dataForm, 'validDay') || '-'} <FormattedMessage id="quotationLabelDay" defaultMessage="Day" />,{' '}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('validDate', {
                    initialValue: _.get(record, 'validDate') ? moment(_.get(record, 'validDate'), 'YYYY-MM-DD') : undefined,
                  })}
                  {_.get(dataForm, 'validDate') ? moment(_.get(dataForm, 'validDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('validRemark', {
                  initialValue: _.get(record, 'remark') || '',
                })}
                {_.get(dataForm, 'remark') || '-'}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelSubtotal" defaultMessage="Subtotal" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'subTotal')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelDiscount" defaultMessage="Discount" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">
                  <Form.Item>
                    {getFieldDecorator('discount', {
                      initialValue: _.get(record, 'discountPercent') ? _.get(record, 'discountPercent').toFixed(2) : 0.0,
                    })}
                    {numberFormatter(_.get(dataForm, 'discountPercent')) + '%'}
                  </Form.Item>
                </div>
                <div className="group-form-item-price-input-view">
                  <Form.Item>
                    {getFieldDecorator('discountNumber', {
                      initialValue: _.get(record, 'discount') ? _.get(record, 'discount').toFixed(2) : 0.0,
                    })}
                    {numberFormatter(_.get(dataForm, 'discount')) || '0.00'}{' '}
                    <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelSubtotalAfterDiscount" defaultMessage="Subtotal After Discount" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'subTotalAfterDiscount')) || '0.00'}{' '}
                <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelTax" defaultMessage="Tax" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">
                  <Form.Item>
                    {getFieldDecorator('tax', {
                      initialValue: _.get(record, 'taxPercent') || _.get(initialTaxList, '[0].percentage'),
                    })}
                    {numberFormatter(_.get(dataForm, 'taxPercent')) + '%'}
                  </Form.Item>
                </div>
                <div className="group-form-item-price-input-view">
                  <Form.Item>
                    {getFieldDecorator('taxNumber', {
                      initialValue: _.get(record, 'tax') || 0.0,
                    })}
                    {numberFormatter(_.get(dataForm, 'tax'))} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span>
                <FormattedMessage id="quotationLabelTotal" defaultMessage="Total" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(_.get(dataForm, 'total')) || '0.00'} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PriceFormView.defaultProps = {};

export default PriceFormView;
