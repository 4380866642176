import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Divider, Form, Menu, Modal, Tabs, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import '../css/index.css';
import CustomerForm from './customer-form';
import CustomTableComponent from '../../components/table';
import { messageLabel } from '../../../../components/message-component';
import PriceForm from './price-form';
import { StickyContainer } from 'react-sticky';
import DetailsTab from './details-tab';
import PaymentTab from './payment-tab';
import CampainTab from './campain-tab';
import UploadFileModal from '../upload-file/upload-modal';
import { QuotationContext } from '../../context';
import _ from 'lodash';
import ModalCreateListItem from '../list-item/add';
import ListItem from '../list-item/list/index';
import moment from 'moment';
import ModalSelectCustomerForm from '../select-customer/customer-modal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import DetailsTabView from './view/details-tab-view';
import PaymentTabView from './view/payment-tab-view';
import CampainTabView from './view/campain-tab-view';
import ModalApproveForm from '../approve/approve-modal';
import ModalCancelForm from '../cancel/cancel-modal';
import ModalRejectForm from '../reject/reject-modal';
import ModalWaitingForm from '../waiting/waiting-modal';
import ModalDraftForm from '../delete/delete-modal';
import QuotationPrint from '../../../../components/modelprint/quotationprint';
import { useReactToPrint } from 'react-to-print';
import ModalCreateListServiceItemQT from '../list-item/add-service';
import ListItemServiceQT from '../list-item/list-service';
import ModalEditListServiceItemPO from '../../../po/modal/list-item/edit-service';
import { v4 as uuidv4 } from 'uuid';
import ModalPDFViewver from '../../../../component-function/pdf-viewver';
import { ThaiNumberToText, UrlPDF } from '../../../../component-function/generator-pdf';
import { QuotationGetCreditDay, QuotationPDFDownloader } from '../../../../controllers/quotation/quotation-api-new';
import { PageSettings } from '../../../../config/page-settings';

const { TabPane } = Tabs;

const QuotationModal = ({ form, title, visible, onOk, onCancel, attData, setAttData, refreshModalQuotation, nextStatus, tabMain}) => {
    const { checkPermissionAction } = useContext(PageSettings);
  const { state, setState, func } = useContext(QuotationContext);
  const {
    initialMemberList,
    initialTaxList,
    initialCampaignList,
    initialReasonList,
    record,
    setRecord,
    visibleApprove,
    visibleReject,
    visibleCancel,
    visibleDelete,
    visibleWaiting,
    isOpenQuotation,
    attachmentData,
    recordReport,
    sizeRecordReport,
    signatureUser,
    signatureIssu,
    vendorData
  } = state;
  const { seIsOpenQuotation, setSizeRecordReport } = setState;
  const {
    handleOpenModalUpload,
    handleOpenApprove,
    handleSaveApprove,
    handleCancelApprove,
    handleOpenReject,
    handleSaveReject,
    handleCancelReject,
    handleOpenCancel,
    handleSaveCancel,
    handleCancelCancelQT,
    handleOpenDelete,
    handleSaveDelete,
    handleCancelDelete,
    handleSaveWaiting,
    handleCancelWaitng,
    handleOpenWaiting,
    handleDeleteAttAchment,
    handleSaveToRevise,
  } = func;
  const [dataForm, setDataForm] = useState();
  const [tabkeys, setTabkeys] = useState('detail');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [createData, setCreateData] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const [defaultEditItem, setDefaultEditItem] = useState({});
  //--------------------------------------------------------------------------
  const [visibleListService, setVisibleListService] = useState(false);
  const [visibleAddService, setVisibleAddService] = useState(false);
  const [visibleEditService, setVisibleEditService] = useState(false);
  const [creatService, setCreatService] = useState();
  const [editService, setEditService] = useState();
  const [loading, setLoading] = useState(false);
  const [visiblePrint, setVisiblePrint] = useState(false)
  const [urlPrint, setUrlPrint] = useState()
  // console.log('form data for save', dataForm, selectItem);
  console.log('QuotationModal', record);

  const intl = useIntl();
  const { setFieldsValue, resetFields } = form;
  const formCustomerRef = useRef();
  const quotationPrintrRef = useRef();

  //set map object qt
  useEffect(() => {
    if (_.get(record, 'quotationId') && visible && _.get(record, 'code')) {
      console.log('dataFormUseEffect', dataForm, record);
      console.log('dataFormUseEffect', record);
      setDataForm({
        ...record,
        customerContact: record.customerContactId,
        address: record.customerAddressBookName,
        fullAddress: record.customerAddressBookFullAddress,
        subtotalDiscount: record.subTotalAfterDiscount,
        subTotal: record.subTotal,
        sumTotal: record.subTotal,
      });
    }
  }, [record, visible]);

  //set item
  useEffect(() => {
    if (record) {
      const itemList = _.map(_.get(record, 'itemList'), (o, i) => ({
        ...o,
        index: i + 1,
        promotionCampaign: o.campaignName,
        promotionCampaignId: o.campaignId,
        credits: o.credit,
        discountPrice: o.discount,
        discountPercentage: o.discountPercent,
        pricePerUnit: o.price,
        qty: _.toString(o.qty),
        remark: _.toString(o.remark),
        itemMasterId: o.itemId,
        itemCode: o.itemCode,
        itemName: o.itemName,
        totalPrice: _.toString(o.total),
        uomId: _.toString(o.unit),
        uomName: _.toString(o.unitName),
        uuid: uuidv4()
      }));

      setSelectItem(itemList);
    }
  }, [record]);

  useEffect(() => {
    console.log('uesEffect dataForm', dataForm);
    if (_.get(dataForm, 'customerId')) {
      setFieldsValue({
        customerName: _.get(dataForm, 'customerName'),
      });
    }
  }, [dataForm, isOpenQuotation]);

  useEffect(() => {
    console.log('selectItem', selectItem);
    if (_.size(selectItem) > 0) {
      let cloneItem = [...selectItem];
      console.log('cloneItem', cloneItem);

      let allCredits = [];
      let data = [];

      for (let i = 0; _.size(selectItem) > i; i++) {
        if (!_.includes(allCredits, cloneItem[i].credits)) {
          console.log('allCredits -> ', allCredits, cloneItem[i].credits);

          const filterItem = _.filter(selectItem, (obj) => obj.credits === cloneItem[i].credits);

          let total = cloneItem[i].totalPrice;

          if (_.size(filterItem) > 0) {
            const mapTotal = _.map(filterItem, (v, i) => {
              return {
                totalPrice: _.toNumber(v.totalPrice),
              };
            });
            total = _.sumBy(mapTotal, 'totalPrice');
            allCredits.push(cloneItem[i].credits);
          }
          data.push({
            credit: cloneItem[i].credits,
            amount: total,
          });
        }
      }

      console.log('Quotation soby', data, _.sortBy(data, 'credit'));

      if (_.size(data) > 0) {
        const sortBy = _.sortBy(data, (o) => _.toNumber(o.credit));
        const mapIndex = _.map(sortBy, (o, i) => {
          return {
            ...o,
            index: i + 1,
            remark: '',
          };
        });
        setPaymentItem(mapIndex);
      }
    }
  }, [selectItem]);

  //set attachment
  useEffect(() => {
    if (record) {
      const mapAttachment = _.map(_.get(record, 'attachment'), (o, i) => ({
        id: _.get(o, 'fileHash'),
        index: i + 1,
        attachmentName: _.get(o, 'fileName'),
        remark: _.get(o, 'remark'),
        file: [{ name: _.get(o, 'fileName'), uid: _.get(o, 'fileHash'), url: _.get(o, 'fileUrl'), action: 'N' }],
        fileRaw: _.get(o, 'fileUrl'),
        action: 'N',
        form: 'backend',
      }));

      setAttData(mapAttachment);
    }
  }, [record]);

    useEffect(() => {
      if (visible === false) {
        handleCancelQuotation();
      }
    }, [visible]);

  const saveWithStatus = (status) => {
    let rStatus;
    if (status === 'draftTorejected') {
      rStatus = 'draft';
    }
    const payload = record;
    payload.status = rStatus;
    // console.log('savex payload1', _.get(record, 'status.code'), values);
    console.log('savex payload2', _.get(record, 'status.code'), payload);
    // console.log('savex payload3', _.get(record, 'status.code'), dataForm);
    // return;
    onOk(payload, resetFields);
    // resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setSizeRecordReport(0);
    setPaymentItem([]);
  };

  const handleSave = (status) => {
    if (status === 'draftTorejected') {
      saveWithStatus(status);
    } else {
      form.validateFields(async (err, values) => {
        if (err) {
          return;
        }

        console.log('handleSaveDataForm', dataForm, 'handleSaveValues', values, 'handleSaveSelectItem', selectItem);

        const itemList = _.map(selectItem, (o) => ({
          itemId: o.itemMasterId,
          qty: _.toString(o.qty),
          unit: _.toString(o.uomId),
          unitName: _.toString(o.uomName),
          price: _.toString(o.pricePerUnit),
          campaignName: _.toString(o.promotionCampaign),
          campaignId: _.toString(o.promotionCampaignId),
          discountPercent: _.toString(o.discountPercentage),
          discount: _.toString(o.discountPrice),
          total: _.toString(o.totalPrice),
          credit: _.toString(o.credits),
          remark: _.toString(o.remark),
        }));

        const payload = {
          customerId: _.get(dataForm, 'customerId') || null,
          customerName: _.get(dataForm, 'customerName') || null,
          customerAddressBookId: _.get(dataForm, 'customerAddressBookId') || null,
          customerContactId: _.get(dataForm, 'customerContactId') || null,
          customerContactPhone: _.get(dataForm, 'customerContactPhone') || null,
          customerContactEmail: _.get(dataForm, 'customerContactEmail') || null,
          issueBy: _.get(values, 'by'),
          issueDate: moment(_.get(values, 'date')).format('YYYY-MM-DD'),
          referenceNo: _.get(values, 'refNo') || null,
          validDay: _.get(values, 'validDay'),
          validDate: moment(_.get(values, 'validDate')).format('YYYY-MM-DD'),
          remark: _.get(values, 'validRemark') || null,
          status: status,
          subTotal: _.get(record, 'code') === 'view' ? _.get(dataForm, 'subTotal') :_.get(dataForm, 'sumTotal') || 0,
          discount: _.get(record, 'code') === 'view' ? _.get(dataForm, 'discount') :_.get(values, 'discountNumber') || 0,
          discountPercent:_.get(record, 'code') === 'view' ? _.get(dataForm, 'discountPercent') : _.get(values, 'discount') || 0,
          subTotalAfterDiscount: _.get(record, 'code') === 'view' ? _.get(dataForm, 'subTotalAfterDiscount') :_.get(dataForm, 'subtotalDiscount') || '0',
          subTotal: _.get(record, 'code') === 'view' ? _.get(dataForm, 'subTotal') :_.get(dataForm, 'sumTotal') || 0,
          tax: _.get(record, 'code') === 'view' ? _.get(dataForm, 'tax') :_.get(values, 'taxNumber') || 0,
          taxPercent: _.get(record, 'code') === 'view' ? _.get(dataForm, 'taxPercent') :_.get(values, 'tax') || 0,
          total: _.get(record, 'code') === 'view' ? _.get(dataForm, 'total') : parseFloat(_.get(dataForm, 'subtotalDiscount')) + parseFloat(_.get(values, 'taxNumber')) || 0,
          
          itemList: itemList,
          quotationId: _.get(dataForm, 'quotationId') || null,
          code: _.get(dataForm, 'code') || null,
        };

        console.log('savex payload1', _.get(record, 'status.code'), values);
        console.log('savex payload2', _.get(record, 'status.code'), payload);
        console.log('savex payload3', _.get(record, 'status.code'), dataForm);
        // return;
        onOk(payload, resetFields);
        // resetFields();
        setDataForm();
        setCreateData();
        setAttData([]);
        setSelectItem([]);
        setSizeRecordReport(0);
        setPaymentItem([]);
      });
    }
  };

  const handleCancelQuotation = () => {
    onCancel();
    resetFields();
    setDataForm();
    setCreateData();
    setAttData([]);
    setSelectItem([]);
    setSizeRecordReport(0);
    setPaymentItem([]);
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const editItem = (value) => {
    if (_.get(value, 'categoryCode') === 'service') {
      setEditService({ ...value, code: 'edit' })
      setVisibleEditService(true)

    } else {
      setDefaultEditItem({ ...value, code: 'edit' });
      setVisibleAdd(true);
    }
  };

  const operations = (
    <div className="tag-center-div" style={{ padding: '12px 14px' }}>
      <Tag className="tag-center-style" color={_.get(record, 'status.color')}>
        {_.get(record, 'status.txt')}
      </Tag>
    </div>
  );

  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          id: _.get(record, 'quotationId'),
          code: _.get(record, 'code'),
          status: 'deleted',
        };
        handleSaveDelete(payload, resetFields);
      },
      onCancel() { },
    });
  };

  // const handlePrint = useReactToPrint({
  //   content: () => quotationPrintrRef.current,
  //   documentTitle: 'data',
  //   copyStyles: true,
  // });

  const handlePrint = async () => {
    const formateData = await fomatePDF(record)
    
    const response = await QuotationPDFDownloader(_.get(record, 'quotationNo'), formateData)
    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response)
      if (url) {
        setUrlPrint(url)
        setVisiblePrint(true)
      }
      // GeneratorPDF(response)
    }
  }


  const fomatePDF = async (props) => {
    const totalNum = _.get(props, 'total') ? _.get(props, 'total', 0) : 0;
    const getCreditDay = await QuotationGetCreditDay(_.get(record, 'customerId'))
    console.log('getCreditDay', getCreditDay)
    return {
      ...record,
      totalNumberText: ThaiNumberToText(totalNum.toFixed(2)),
      discountTxt: numberFormatter(_.get(props, 'discount')),
      discountPercentTxt: numberFormatter(_.get(props, 'discountPercent')),
      taxTxt: numberFormatter(_.get(props, 'tax')),
      taxPercentageTxt: numberFormatter(_.get(props, 'taxPercent')),
      subTotalTxt: numberFormatter(_.get(props, 'subTotal')),
      subTotalAfterDiscountTxt: numberFormatter(_.get(props, 'subTotalAfterDiscount')),
      totalTxt: numberFormatter(totalNum.toFixed(2)),
      creditDay: _.get(getCreditDay, 'data.data.creditDay')
    }
  }


  const handleEdit = () => {
    refreshModalQuotation(_.get(record, 'quotationId'), 'edit');
  };

  const actionFooter =
    _.get(record, 'status.code') === 'waiting'
      ? [
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button_02>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleDelete()}>
          <FormattedMessage id="btnDelete" defaultMessage="Detete" />
        </Button_01>,
        <Button_01
          style={{ margin: '0px 0px 0px 10px', width: '18%' }}
          key="cancelQT"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleOpenCancel()}
        >
          <FormattedMessage id="btnCancelQuotation" defaultMessage="Cancel Quotation" />
        </Button_01>,
        <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" btnsize="wd_df" onClick={() => handleOpenReject()}>
          <FormattedMessage id="btnReject" defaultMessage="Reject" />
        </Button_01>,
        <Button_01
          style={{ margin: '0px 0px 0px 10px' }}
          key="approve"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleOpenApprove()}
        >
          <FormattedMessage id="btnApprove" defaultMessage="Approve" />
        </Button_01>,
      ]
      : _.get(record, 'status.code') === 'draft'
        ? [
          <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_02>,
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="delete" btnsize="wd_df" type="primary" onClick={() => handleDelete()}>
            <FormattedMessage id="btnDelete" defaultMessage="Detete" />
          </Button_01>,
          <Button_01
            style={{ margin: '0px 0px 0px 10px', width: '18%' }}
            key="cancelQT"
            type="primary"
            btnsize="wd_df"
            onClick={() => handleOpenCancel()}
          >
            <FormattedMessage id="btnCancelQuotation" defaultMessage="Cancel Quotation" />
          </Button_01>,

          _.get(dataForm, 'code') === 'view' && (
            <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="edit" btnsize="wd_df" type="primary" onClick={() => handleEdit()}>
              <FormattedMessage id="btnEdit" defaultMessage="Edit" />
            </Button_01>
          ),
          _.get(dataForm, 'code') === 'edit' && (
            <>
              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="draft"
                btnsize="wd_df"
                type="primary"
                onClick={() => handleSave('draft')}
              >
                <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
              </Button_01>
              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="submit"
                type="primary"
                btnsize="wd_df"
                disabled={_.size(selectItem) > 0 ? false : true}
                onClick={() => handleSave('waiting')}
              >
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button_01>
            </>
          ),
        ]
        : _.get(record, 'status.code') === 'approved'
          ? [
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                  <FormattedMessage id="btnPrint" defaultMessage="Print" />
                </Button_01>
              </div>
              <div>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                  <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                </Button_02>
                <Button_01
                  style={{ margin: '0px 0px 0px 10px' }}
                  key="waiting"
                  type="primary"
                  btnsize="wd_df"
                  onClick={() => handleOpenWaiting()}
                >
                  <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
                </Button_01>
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" btnsize="wd_df" onClick={() => handleOpenReject()}>
                  <FormattedMessage id="btnReject" defaultMessage="Reject" />
                </Button_01>
              </div>
            </div>
          ]
          : _.get(record, 'status.code') === 'canceled'
            ? [
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button_02>,
            ]
            : _.get(record, 'status.code') === 'rejected'
              ? [
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                  <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                </Button_02>,
                // <Button_01
                //   style={{ margin: '0px 0px 0px 10px', width: '12%' }}
                //   key="save"
                //   type="primary"
                //   btnsize="wd_df"
                //   onClick={() => handleOpenDelete('draftTorejected')}
                // >
                //   <FormattedMessage id="btnDraft" defaultMessage="Draft" />
                // </Button_01>,
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="waiting" type="primary" btnsize="wd_df" onClick={handleOpenWaiting}>
                  <FormattedMessage id="btnWaiting" defaultMessage="Waiting" />
                </Button_01>,
                <Button_01
                  style={{ margin: '0px 0px 0px 10px' }}
                  key="approve"
                  type="primary"
                  btnsize="wd_df"
                  onClick={() => handleOpenApprove()}
                >
                  <FormattedMessage id="btnApprove" defaultMessage="Approve" />
                </Button_01>,
              ]
              : [
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                  <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
                </Button_02>,
                <Button_02 style={{ margin: '0px 0px 0px 10px', width: '12%' }} key="draft" btnsize="wd_df" onClick={() => handleSave('draft')}>
                  <FormattedMessage id="btnSaveDraft" defaultMessage="Save Draft" />
                </Button_02>,
                <Button_01
                  key="save"
                  type="primary"
                  btnsize="wd_df"
                  disabled={_.size(selectItem) === 0 ? true : false}
                  onClick={() => handleSave('waiting')}
                >
                  <FormattedMessage id="btnSave" defaultMessage="Save" />
                </Button_01>,
              ];

  const handleOpenModalListService = () => {
    setVisibleListService(true);
  };

   const handleButton = () => {
      if (_.get(record, 'code') === 'view') {
        return [
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {checkPermissionAction('P28PG1C1', 'P28PG1C1A9') && (
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                  <FormattedMessage id="btnPrint" defaultMessage="Print" />
                </Button_01>
              )}
            </div>
            <div>{renderButtons()}</div>
          </div>,
        ];
      } else if (_.get(record, 'code') === 'edit') {
        return renderButtons();
      } else {
        return renderButtonsCreate();
      }
    };
  
    const renderButtons = () => {
      const buttons = [];
  
      buttons.push(
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_02>
      );
      if (_.includes(nextStatus, 'canceled') && _.get(record, 'code') !== 'edit' && checkPermissionAction('P28PG1C1', 'P28PG1C1A7')) {
        buttons.push(
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="cancelQT" type="primary" btnsize="wd_df" onClick={() => handleOpenCancel()}>
            <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
          </Button_01>
        );
      }
  
      if (_.includes(nextStatus, 'torevise') && checkPermissionAction('P28PG1C1', 'P28PG1C1A12')) {
        buttons.push(
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="submit"
            type="primary"
            btnsize="wd_df"
            onClick={() =>
              handleSaveToRevise({
                id: _.get(record, 'quotationId'),
                code: _.get(record, 'code'),
                status: 'torevise',
                // statusRemark: null,
              })
            }
          >
            <FormattedMessage id="btnToRevise" defaultMessage="To Revise" />
          </Button_01>
        );
      }
  
      if (_.includes(nextStatus, 'deleted') && checkPermissionAction('P28PG1C1', 'P28PG1C1A8')) {
        buttons.push(
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="submit" type="primary" btnsize="wd_df" onClick={() => handleDelete()}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button_01>
        );
      }
  
      if (
        (tabMain === 'draft' || tabMain === 'torevise') &&
        _.get(record, 'code') === 'view' &&
        checkPermissionAction('P28PG1C1', 'P28PG1C1A3')
      ) {
        buttons.push(
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="edit" btnsize="wd_df" type="primary" onClick={() => handleEdit()}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button_01>
        );
      }
  
      if (_.get(record, 'code') === 'edit' && (checkPermissionAction('P28PG1C1', 'P28PG1C1A3') || checkPermissionAction('P28PG1C1', 'P28PG1C1A10'))) {
        buttons.push(
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="draft"
            btnsize="wd_df"
            type="primary"
            onClick={() => handleSave(tabMain)}
            disabled={_.size(selectItem) === 0 ? true : false}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>
        );
      }
  
      if (_.includes(nextStatus, 'waiting') && checkPermissionAction('P28PG1C1', 'P28PG1C1A11')) {
        buttons.push(
          <Button_01
            style={{ margin: '0px 0px 0px 10px', width: 'auto' }}
            key="submit"
            type="primary"
            btnsize="wd_df"
            disabled={_.size(selectItem) === 0 ? true : false}
            onClick={() => handleSave('waiting')}
          >
            <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
          </Button_01>
        );
      }
  
      if (_.includes(nextStatus, 'approved') && checkPermissionAction('P28PG1C1', 'P28PG1C1A5')) {
        buttons.push(
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="approve" type="primary" btnsize="wd_df" onClick={() => handleOpenApprove()}>
            <FormattedMessage id="btnApprove" defaultMessage="Approve" />
          </Button_01>
        );
      }
  
      if (_.includes(nextStatus, 'rejected') && checkPermissionAction('P28PG1C1', 'P28PG1C1A6')) {
        buttons.push(
          <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" btnsize="wd_df" onClick={() => handleOpenReject()}>
            <FormattedMessage id="btnReject" defaultMessage="Reject" />
          </Button_01>
        );
      }
  
      return buttons;
    };
  
    const renderButtonsCreate = () => {
      const buttons = [];
  
      buttons.push(
        <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_02>
      );
      if (checkPermissionAction('P28PG1C1', 'P28PG1C1A10')) {
        buttons.push(
          <Button_01
            style={{ margin: '0px 0px 0px 10px' }}
            key="draft"
            btnsize="wd_df"
            type="primary"
            onClick={() => handleSave('draft')}
            disabled={_.size(selectItem) === 0 ? true : false}
          >
            <FormattedMessage id="btnSave" defaultMessage="Save" />
          </Button_01>
        );
      }
  
      if (checkPermissionAction('P28PG1C1', 'P28PG1C1A11')) {
        buttons.push(
          <Button_01
            style={{ margin: '0px 0px 0px 10px', width: 'auto' }}
            key="submit"
            type="primary"
            btnsize="wd_df"
            disabled={_.size(selectItem) === 0 ? true : false}
            onClick={() => handleSave('waiting')}
          >
            <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
          </Button_01>
        );
      }
  
      return buttons;
    };

  const menuColumn = (
    <Menu>
      <Menu.Item onClick={() => setVisibleItem(true)}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnItem" defaultMessage="Item" />
        </Button>
      </Menu.Item>
      <Menu.Item onClick={handleOpenModalListService}>
        <Button style={{ padding: 'unset' }} ghost type="link">
          <FormattedMessage id="expenseBtnService" defaultMessage="Service" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancelQuotation}
      width={1000}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '600px' }}
      footer={visible ? handleButton() : null}
    >
      {_.get(record, 'code') === 'view' && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
            tabBarExtraContent={operations}
          >
            {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTabView
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  record,
                }}
              />

              {/* <ModalSelectCustomerForm visible={isOpen} onCancel={handleCancel} onOk={handleOk} setDataForm={setDataForm} /> */}

              {/* <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                initialCampaignList={initialCampaignList}
              /> */}
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelPayment`, defaultMessage: 'Payment' })} key="payment">
              <PaymentTabView dataSource={paymentItem} />
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
            {/* <TabPane tab={intl.formatMessage({ id: `quotationLabelCampaign`, defaultMessage: 'Campaign' })} key="campain">
              <CampainTabView initialCampaignList={initialCampaignList} />
            </TabPane> */}
            {/* ) : null} */}
          </Tabs>
        </StickyContainer>
      )}

      {_.get(record, 'code') === 'edit' && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
            tabBarExtraContent={operations}
          >
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTab
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  formCustomerRef,
                  isOpenQuotation,
                  record,
                  editItem,
                  handleDeleteAttAchment,
                  menuColumn,
                }}
              />

              <ModalSelectCustomerForm
                ref={formCustomerRef}
                visible={isOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                setDataForm={setDataForm}
                isOpenQuotation={isOpenQuotation}
              />

              <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                initialCampaignList={initialCampaignList}
                defaultEditItem={defaultEditItem}
                setDefaultEditItem={setDefaultEditItem}
              />

            </TabPane>

            <TabPane tab={intl.formatMessage({ id: `quotationLabelPayment`, defaultMessage: 'Payment' })} key="payment">
              <PaymentTab dataSource={paymentItem} setDataSource={setPaymentItem} />
            </TabPane>
          </Tabs>
        </StickyContainer>
      )}

      {_.get(record, 'code') === 'createSaleOrder' && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
          >
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTab
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  formCustomerRef,
                  isOpenQuotation,
                  record,
                  menuColumn,
                }}
              />

              <ModalSelectCustomerForm
                ref={formCustomerRef}
                visible={isOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                setDataForm={setDataForm}
                isOpenQuotation={isOpenQuotation}
              />

              <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                initialCampaignList={initialCampaignList}
                defaultEditItem={defaultEditItem}
                setDefaultEditItem={setDefaultEditItem}
              />
            </TabPane>

            <TabPane tab={intl.formatMessage({ id: `quotationLabelPayment`, defaultMessage: 'Payment' })} key="payment">
              <PaymentTab dataSource={paymentItem} />
            </TabPane>
          </Tabs>
        </StickyContainer>
      )}

      {!_.get(record, 'code') && (
        <StickyContainer>
          <Tabs
            className="monitor-main-tabs customer-tab-margin"
            size="large"
            defaultActiveKey="detail"
            onChange={callback}
            animated={false}
          >
            {/* {checkPermissionAction(`P22PG1C3`, `P22PG1C3A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
              <DetailsTab
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  formCustomerRef,
                  isOpenQuotation,
                  record,
                  editItem,
                  menuColumn,
                }}
              />

              <ModalSelectCustomerForm
                ref={formCustomerRef}
                visible={isOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                setDataForm={setDataForm}
                isOpenQuotation={isOpenQuotation}
              />

              <ListItem
                visible={visibleItem}
                setVisible={setVisibleItem}
                setVisibleAdd={setVisibleAdd}
                setCreateData={setCreateData}
                vendorData={customerData}
                form={form}
                setDataForm={setDataForm}
                dataForm={dataForm}
                handleOpen={handleOpen}
              />

              <ModalCreateListItem
                visible={visibleAdd}
                setVisible={setVisibleAdd}
                data={createData}
                selectItem={selectItem}
                setSelectItem={setSelectItem}
                initialCampaignList={initialCampaignList}
                defaultEditItem={defaultEditItem}
                setDefaultEditItem={setDefaultEditItem}
              />
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C4`, `P22PG1C4A1`) ? ( */}
            <TabPane tab={intl.formatMessage({ id: `quotationLabelPayment`, defaultMessage: 'Payment' })} key="payment">
              <PaymentTab dataSource={paymentItem} setDataSource={setPaymentItem} />
            </TabPane>
            {/* ) : null} */}

            {/* {checkPermissionAction(`P22PG1C5`, `P22PG1C5A1`) ? ( */}
            {/* <TabPane tab={intl.formatMessage({ id: `quotationLabelCampaign`, defaultMessage: 'Campaign' })} key="campain">
              <CampainTab initialCampaignList={initialCampaignList} />
            </TabPane> */}
            {/* ) : null} */}
          </Tabs>
        </StickyContainer>
      )}
      <div style={{ display: 'none' }}>
        {/* <div > */}
        <QuotationPrint
          ref={quotationPrintrRef}
          record={record}
          recordReport={recordReport}
          visibleModal={visible}
          sizeRecordReport={sizeRecordReport}
          signatureUser={signatureUser}
          signatureIssu={signatureIssu}
        />
      </div>

      <ModalApproveForm
        title={intl.formatMessage({ id: `quotationLabelApproved`, defaultMessage: 'Approved' }) + ' · ' + _.get(record, 'quotationNo')}
        visible={visibleApprove}
        onOk={handleSaveApprove}
        onCancel={handleCancelApprove}
        quotationId={_.get(record, 'quotationId')}
        code={_.get(record, 'code')}
      />
      <ModalCancelForm
        title={intl.formatMessage({ id: `quotationLabelCancelQuotation`, defaultMessage: 'Cancel Quotation' })}
        visible={visibleCancel}
        onOk={handleSaveCancel}
        onCancel={handleCancelCancelQT}
        initialReasonList={initialReasonList}
        quotationId={_.get(record, 'quotationId')}
        code={_.get(record, 'code')}
      />
      <ModalRejectForm
        title={intl.formatMessage({ id: `quotationLabelRejected`, defaultMessage: 'Rejected' }) + ' · ' + _.get(record, 'quotationNo')}
        visible={visibleReject}
        onOk={handleSaveReject}
        onCancel={handleCancelReject}
        initialReasonList={initialReasonList}
        quotationId={_.get(record, 'quotationId')}
        code={_.get(record, 'code')}
      />
      <ModalDraftForm
        title={intl.formatMessage({ id: `quotationLabelDraft`, defaultMessage: 'Draft' }) + ' · ' + _.get(record, 'quotationNo')}
        visible={visibleDelete}
        onOk={handleSaveDelete}
        onCancel={handleCancelDelete}
        quotationId={_.get(record, 'quotationId')}
        code={_.get(record, 'code')}
      />
      <ModalWaitingForm
        title={intl.formatMessage({ id: `quotationLabelWaiting`, defaultMessage: 'Waiting' }) + ' · ' + _.get(record, 'quotationNo')}
        visible={visibleWaiting}
        onOk={handleSaveWaiting}
        onCancel={handleCancelWaitng}
        quotationId={_.get(record, 'quotationId')}
        code={_.get(record, 'code')}
      />

      <ListItemServiceQT
        visible={visibleListService}
        setVisible={setVisibleListService}
        setVisibleAdd={setVisibleAddService}
        setCreateData={setCreatService}
        vendorData={vendorData}
        form={form}
        dataForm={dataForm}
      />
      <ModalCreateListServiceItemQT
        visible={visibleAddService}
        setVisible={setVisibleAddService}
        data={creatService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <ModalEditListServiceItemPO
        visible={visibleEditService}
        setVisible={setVisibleEditService}
        data={editService}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalPDFViewver
        visible={visiblePrint}
        setVisible={setVisiblePrint}
        url={urlPrint}
        handlePrint={handlePrint}
      />

    </Modal>
  );
};

QuotationModal.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalCustomerQuotationForm = Form.create({ name: 'Customer_Form' })(QuotationModal);

export default ModalCustomerQuotationForm;
