import React, { useState } from 'react';
import ChartLine from '../../component/chart-line';

const LaborHR = (prop) => {
  const {chartData} = prop
  // const chartData = Array.from({ length: 24 }, (_, hour) => {
  //   const capacity = 210000 - hour * 5000;
  //   const productivity = capacity - 10000;
  //   return {
  //     name: `${String(hour).padStart(2, '0')}:00`,
  //     capacity,
  //     productivity,
  //   };
  // });
  
  console.log("LOLOLOLOLOL",chartData);
  

  const datasetConfig = {
    capacity: { label: 'Capacity', color: '#3BA223' },
    productivity: { label: 'Productivity', color: '#3BA223', stokeDasharray: '2 2', legendType: 'plainline' },
  };

  const chartConfig = {
    domain: {
      left: [0, 'auto'],
    },
    yAxisIdMapping: {
      capacity: 'left',
      productivity: 'left',
    },
    yLeft: "Hour",
  };

  return ( <ChartLine data={chartData} legendFormatter={(value) => datasetConfig[value]?.label || value} datasetConfig={datasetConfig}  config={chartConfig}/>
  );
};

export default LaborHR;
