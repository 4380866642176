import React, { useContext, useState, useRef } from 'react';
import { Table, Popover, Button, List } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { ProjectContext } from '../context';
import { PageSettings } from '../../../config/page-settings';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import '../project-list/css/index.css';

const ProjectSource = (props) => {
  const { newDataColumns, hanldeClickModal, handleDelete, scrollTableX } = props;
  const { state, setState, fnc } = useContext(ProjectContext);
  const { paginationPage, total, dataSource, loading } = state;
  const { setPaginationPage, setPaginationSize, setOrderSort, setFieldSort } = setState;

  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const ref = useRef();

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setFieldSort(field);
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={999}
          content={
            <div style={{ display: 'grid' }}>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction, setPageSidebarTitle }) => (
                    <div>
                      {checkPermissionAction('P39PG2', 'P39PG2A1') ? (
                        <Link
                          onClick={() => setPageSidebarTitle(['Project Details'])}
                          style={{ fontWeight: 'initial' }}
                          to={'/main/project/list/project-detail/' + _.get(record, 'id')}
                        >
                          <Button style={{ width: 100 }} type="link" ghost>
                            <FormattedMessage id="btnView" defaultMessage="View" />
                          </Button>
                        </Link>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>

              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P39PG1C1', 'P39PG1C1A3') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => hanldeClickModal(record, 'edit')}>
                          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
              <>
                <PageSettings.Consumer>
                  {({ checkPermissionAction }) => (
                    <div>
                      {checkPermissionAction('P39PG1C1', 'P39PG1C1A4') ? (
                        <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record, 'delete')}>
                          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                        </Button>
                      ) : null}
                    </div>
                  )}
                </PageSettings.Consumer>
              </>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setRecordPopover();
    setOffsetPopover([0, 0]);
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Table
        rowKey={(row) => row.id}
        onChange={handleChange}
        loading={loading}
        columns={newDataColumns}
        scroll={{ x: 'max-content' }}
        dataSource={dataSource}
        pagination={{
          total: total,
          current: paginationPage,
          showTotal: showTotal,
          defaultCurrent: 1,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          showSizeChanger: true,
          locale: { items_per_page: '' },
          onChange: handlePagination,
          onShowSizeChange: handleSizeChange,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (!event.target.href) {
                const { x, y } = ref.current.getBoundingClientRect();
                setVisiblePopoverTable(true);
                setOffsetPopover([event.pageX - x, event.pageY - y]);
                setRecordPopover(record);
              }
            },
          };
        }}
      />
      {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
      {_.get(dataSource,'length') > 0 ? (
        <div className="total-items-member">
          <span style={{ fontSize: '13px' }}>
            <FormattedMessage id="lblTotal" defaultMessage="Total" />
            {` ${total || 0} `}
            <FormattedMessage id="lblitems" defaultMessage="items" />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ProjectSource;
