import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button01 from '../../../../components/v2/button_01';
import { Button, Divider, Modal } from 'antd';
import TableProjectDetail from '../component/table';
import {
  deleteGasApi,
  deleteReimbursementApi,
  getGasList,
  getReimbursementList,
} from '../../../../controllers/project-new/project-setting';
import ModalReimbursementForm from './modal-reimbursement';
import ModalGasForm from './modal-gas';
import moment from 'moment';
import _ from 'lodash';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal';

const ProjectDetailSetting = (props) => {
  const { id } = props;
  const intl = useIntl();
  const [listReimbursement, setListReimbursement] = useState([]);
  const [totalReimbursement, setTotalReimbursement] = useState(0);
  const [triggerReimbursement, setTriggerReimbursement] = useState(false);
  const [tableChangeReimbursement, setTableChangeReimbursement] = useState({ page: 1, limit: 5, orderBy: null, sortBy: null });
  const [openReimbursement, setOpenReimbursement] = useState(false);
  const [recordReimbursement, setRecordReimbursement] = useState({});

  const [listGas, setListGas] = useState([]);
  const [totalGas, setTotalGas] = useState(0);
  const [triggerGas, setTriggerGas] = useState(false);
  const [tableChangeGas, setTableChangeGas] = useState({ page: 1, limit: 5, orderBy: null, sortBy: null });
  const [openGas, setOpenGas] = useState(false);
  const [recordGas, setRecordGas] = useState({});

  console.log('tableChangeReimbursement', tableChangeReimbursement);

  useEffect(() => {
    const getReimbursement = async () => {
      const body = {
        limit: _.get(tableChangeReimbursement, 'limit'),
        pageNumber: _.get(tableChangeReimbursement, 'page'),
        proJectId: id,
        orderBy: _.get(tableChangeReimbursement, 'sortBy'),
        orderType: _.get(tableChangeReimbursement, 'orderBy'),
      };
      const response = await getReimbursementList(body);
      console.log('getReimbursementList', response);
      setListReimbursement(_.get(response, 'data.data'));
      setTotalReimbursement(_.get(response, 'data.totalItem'));
    };

    if (id) getReimbursement();
  }, [id, triggerReimbursement, tableChangeReimbursement]);

  useEffect(() => {
    const getGas = async () => {
      const body = {
        limit: _.get(tableChangeGas, 'limit'),
        pageNumber: _.get(tableChangeGas, 'page'),
        proJectId: id,
        orderBy: _.get(tableChangeGas, 'sortBy'),
        orderType: _.get(tableChangeGas, 'orderBy'),
      };
      const response = await getGasList(body);
      console.log('getGas', response);
      setListGas(_.get(response, 'data.data'));
      setTotalGas(_.get(response, 'data.totalItem'));
    };

    if (id) getGas();
  }, [id, triggerGas, tableChangeGas]);

  const columnsReimbursement = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsReimbursement2', defaultMessage: 'Reimbursement' }),
      dataIndex: 'reimbusement',
      key: 'reimbusement',
      sorter: true,
      width: 200,
      fixed: 'left',
      render: (text, record, index) => _.get(record, 'reimbusement.basePayName'),
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsLimit', defaultMessage: 'Limit' }),
      dataIndex: 'limit',
      key: 'limit',
      sorter: true,
      width: 150,
      align: 'right',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      sorter: true,
      width: 350,
      render: (text, record, index) => (text ? text : '-'),
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsUpdateBy', defaultMessage: 'Update by' }),
      dataIndex: 'updatedByName',
      key: 'updatedByName',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsUpdateDate', defaultMessage: 'Update Date' }),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      width: 150,
    },
  ];

  const columnsGas = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasType', defaultMessage: 'Gas Type' }),
      dataIndex: 'gasTypeName',
      key: 'gasTypeName',
      sorter: true,
      width: 150,
      fixed: 'left',
      render: (text, record, index) => _.get(record, 'projectGasType[0].gasTypeName')
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasprice', defaultMessage: 'Gas Price' }),
      dataIndex: 'gasPrice',
      key: 'gasPrice',
      sorter: true,
      width: 150,
      align: 'right',
      render: (text, record, index) => (
        <span>
          {numberFormatDecimal(_.get(record, 'gasPrice'))} <FormattedMessage id="labelTHB" defaultMessage="THB" />
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsEffectiveStartDate', defaultMessage: 'Effective Start Date' }),
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsEffectiveEndDate', defaultMessage: 'Effective End Date' }),
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      width: 200,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasUpdateBy', defaultMessage: 'Update by' }),
      dataIndex: 'updatedByName',
      key: 'updatedByName',
      sorter: true,
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'projectDetailSettingColumnsGasUpdateDate', defaultMessage: 'Update Date' }),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      width: 150,
    },
  ];

  const handleOpen = (code) => {
    if (code === 'reimbursement') {
      setOpenReimbursement(true);
    } else {
      setOpenGas(true);
    }
  };

  const contentActionReimbursement = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => editReimbursement(record)}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteReimbursement(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const editReimbursement = (record) => {
    setRecordReimbursement({
      ...record,
      action: 'edit',
    });
    setOpenReimbursement(true);
  };

  const deleteReimbursement = (record) => {
    console.log('deleteReimbursement', record);
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `projectDetailModalConfirmDeleteProject`,
        defaultMessage: 'Do you want to delete?',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const id = _.get(record, '_id');
        const response = await deleteReimbursementApi(id);
        console.log('deleteReimbursement delete', response);
        if (response.status.code === 200) {
          setTriggerReimbursement((current) => !current);
          successNotification(response.status.message);
        } else {
          errorNotification(response.status.message);
        }
      },
      onCancel() {},
    });
  };

  const contentActionGas = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => editGas(record)}>
            <FormattedMessage id="btnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteGas(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const editGas = (record) => {
    setRecordGas({ ...record, action: 'edit' });
    setOpenGas(true);
  };

  const deleteGas = (record) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({
        id: `projectDetailModalConfirmDeleteProject`,
        defaultMessage: 'Do you want to delete?',
      }),
      okText: intl.formatMessage({
        id: 'modalBtnConfirm',
        defaultMessage: 'Confirm',
      }),
      cancelText: intl.formatMessage({
        id: 'modalBtnCancel',
        defaultMessage: 'Cancel',
      }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const id = _.get(record, '_id');
        const response = await deleteGasApi(id);
        console.log('deleteGasApi delete', response);
        if (response.status.code === 200) {
          setTriggerGas((current) => !current);
          successNotification(response.status.message);
        } else {
          errorNotification(response.status.message);
        }
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '16px 24px 0px' }}>
        <span className="item-title-text">
          <FormattedMessage id="projectDetailSettingLableReimbursement2" defaultMessage="Reimbursement" />
        </span>
        <div>
          <Button01 fontsize="sm" type="primary" onClick={() => handleOpen('reimbursement')}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button01>
        </div>
      </div>
      <Divider type="horizontal" style={{ margin: '12px 0px' }} />
      <div>
        <TableProjectDetail
          columns={columnsReimbursement}
          listData={listReimbursement}
          listTotal={totalReimbursement}
          contentAction={contentActionReimbursement}
          searchMemberValue={tableChangeReimbursement}
          setSearchMemberValue={setTableChangeReimbursement}
          onRow={true}
          pageSizeOptions={['5', '10', '20', '30', '40', '50']}
        />
      </div>

      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '16px 24px 0px' }}>
        <span className="item-title-text">
          <FormattedMessage id="projectDetailSettingLableGas" defaultMessage="Gas" />
        </span>
        <div>
          <Button01 fontsize="sm" type="primary" onClick={() => handleOpen('gas')}>
            <FormattedMessage id="btnAdd" defaultMessage="Add" />
          </Button01>
        </div>
      </div>
      <Divider type="horizontal" style={{ margin: '12px 0px' }} />
      <div>
        <TableProjectDetail
          columns={columnsGas}
          listData={listGas}
          listTotal={totalGas}
          contentAction={contentActionGas}
          searchMemberValue={tableChangeGas}
          setSearchMemberValue={setTableChangeGas}
          onRow={true}
          pageSizeOptions={['5', '10', '20', '30', '40', '50']}
        />
      </div>

      <ModalReimbursementForm
        visible={openReimbursement}
        setVisibel={setOpenReimbursement}
        setTrigger={setTriggerReimbursement}
        record={recordReimbursement}
        setRecord={setRecordReimbursement}
        id={id}
      />

      <ModalGasForm
        visible={openGas}
        setVisibel={setOpenGas}
        setTrigger={setTriggerGas}
        record={recordGas}
        setRecord={setRecordGas}
        id={id}
      />
    </div>
  );
};

export default ProjectDetailSetting;
