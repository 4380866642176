import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, Input } from 'antd';
import Button01 from '../../../components/v2/button_01';
import Button02 from '../../../components/v2/button_02';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import LabeRequireForm from '../../../components/label-required-form';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import { updateStatusCreditNote, getCnReason } from '../../../controllers/credit-note/credit-note';

const { Option } = Select;
const { TextArea } = Input;

const ModalCnStatus = (props) => {
  const { visible, setVisible, type, form, data, setTrigger, setVisibleMain, keyTabMonitor, actionSaveToStatus } = props;
  const { getFieldDecorator, validateFields, resetFields } = form;
  const intl = useIntl();
  const langValue = localStorage.getItem('langValue');
  const nameType =
    type === 'draft'
      ? intl.formatMessage({ id: 'modalRequestStatusDraft', defaultMessage: 'Draft' })
      : type === 'torevise'
      ? intl.formatMessage({ id: 'modalRequestStatusToRevise', defaultMessage: 'To Revise' })
      : type === 'waiting'
      ? intl.formatMessage({ id: 'modalRequestStatusWaiting', defaultMessage: 'Waiting' })
      : type === 'canceled'
      ? intl.formatMessage({ id: 'modalRequestStatusCanceled', defaultMessage: 'Canceled' })
      : type === 'reOpen'
      ? intl.formatMessage({ id: 'modalRequestStatusReOpen', defaultMessage: 'Reopen' })
      : type === 'rejected'
      ? intl.formatMessage({ id: 'modalRequestStatusRejected', defaultMessage: 'Rejected' })
      : type === 'closed'
      ? intl.formatMessage({ id: 'modalRequestStatusClosed', defaultMessage: 'Closed' })
      : intl.formatMessage({ id: 'modalRequestStatusApproved', defaultMessage: 'Approved' });
  const [reasonList, setReasonList] = useState([]);
  const [disbleButton, setDisbleButton] = useState(false);

  useEffect(() => {
    const getDataDefault = async () => {
      const response = await getCnReason();
      console.log('getCnReason', response)
      setReasonList(_.get(response, 'data'));
    };
    getDataDefault();
  }, []);

  const handleConfirmStatus = () => {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setDisbleButton(true);
      const body = {
        statusCode: type,
        creditNoteId: _.get(data, 'cnId'),
        reasonId: _.get(values, 'reason') || '',
        remark: _.get(values, 'remark') || '',
        // actionBy: localStorage.getItem('memComId'),
      };
      const response = await updateStatusCreditNote(body);
      console.log('updateStatusCreditNote', response)
      if (_.get(response, 'data.status') === 200) {
        successNotification(_.get(response, 'data.message'));
        setVisible(false);
        setVisibleMain(false);
        setTrigger((cur) => !cur);
        setDisbleButton(false);
        resetFields();
        actionSaveToStatus(type, _.get(data, 'cnId'))
      } else {
        errorNotification(_.get(response, 'data.error'));
        setDisbleButton(false);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
  };

  return (
    <Modal
      title={keyTabMonitor === 'draft' ? nameType : `${nameType}・${_.get(data, 'cnNo')}`}
      visible={visible}
      width={370}
      centered={true}
      onCancel={handleCancel}
      footer={[
        <Button02 key="back" btnsize="wd_df" onClick={handleCancel} disabled={!disbleButton > 0 ? false : true}>
          <FormattedMessage id="btnCancel" defaultMessage="Cancel" />
        </Button02>,
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={handleConfirmStatus}
          style={{ margin: 'unset' }}
          disabled={!disbleButton ? false : true}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button01>,
      ]}
    >
      <Form form={form} layout="vertical">
        {type === 'rejected' || type === 'canceled' || type === 'closed' ? (
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'modalRequestStatusFormReason', defaultMessage: 'Reason' })} req={true} />
            }
          >
            {getFieldDecorator('reason', {
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'modalRequestStatusFormReasonValidate', defaultMessage: 'Please Select Reason' }),
                },
              ],
            })(
              <Select placeholder={intl.formatMessage({ id: 'modalRequestStatusFormReasonPlaceholder', defaultMessage: 'Select Reason' })}>
                {_.map(reasonList, (item) => (
                  <Option key={item.reasonId}>{langValue === 'TH' ? item.detail.th : item.detail.en}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
        <Form.Item
          label={
            <LabeRequireForm text={intl.formatMessage({ id: 'modalRequestStatusFormRemark', defaultMessage: 'Remark' })} req={false} />
          }
        >
          {getFieldDecorator('remark', {})(<TextArea autosize={{ minRows: 3, maxRows: 3 }} maxLength={250} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const ModalCnStatusForm = Form.create({
  name: 'global_state',
})(ModalCnStatus);

export default ModalCnStatusForm;
