import { Form, Modal, Tabs, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Button02 from '../../../../components/v2/button_02';
import Button01 from '../../../../components/v2/button_01';
import CreditNoteDetail from './detail';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import ReferenceTab from '../../../../components/reference';
import { PageSettings } from '../../../../config/page-settings';
import { errorNotification, successNotification } from '../../../../components/v2/notification';
import {
  deleteCn,
  getCreditNoteDetail,
  saveCreditNote,
  saveEditCreditNote,
  uploadCnAttachment,
} from '../../../../controllers/credit-note/credit-note';
import moment from 'moment';
import Resizer from 'react-image-file-resizer';

const { TabPane } = Tabs;

const ModalCreditNoteForm = (props) => {
  const { visible, setVisible, form, setTrigger, defaultData, initial, nextStatus, actionSaveToStatus, keyTabMonitor } = props;
  const { validateFields, resetFields, getFieldValue, setFieldsValue } = form;

  const intl = useIntl();
  const { checkPermissionAction } = useContext(PageSettings);

  const [tabChange, setTabChange] = useState('1');
  const [dataForm, setDataForm] = useState({});
  const [selectItem, setSelectItem] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);

  const [refItem, setRefItem] = useState([]);

  const [loadingButton, setLoadingButton] = useState(false);

  console.log('defaultDataEdit', defaultData, dataForm, selectItem, attachmentData);
  console.log('initial', initial);

  useEffect(() => {
    const getDetail = async () => {
      const response = await getCreditNoteDetail(_.get(defaultData, 'cnId'));
      const data = _.get(response, 'data');
      console.log('getCreditNote xxxxxxx -> ', response);

      if (_.get(data, 'invNo')) {
        setFieldsValue({
          invNo: _.get(data, 'invNo'),
          issueBy: _.get(data, 'issueBy'),
          issueDate: _.get(data, 'issueDate') ? moment(_.get(data, 'issueDate'), 'YYYY-MM-DD') : undefined,
          taxNumber: _.get(data, 'taxNumber'),
          vat: _.get(data, 'vat') ? _.get(data, 'vat') : _.get(initial, 'optionsVat.[1].taxPercent'),
        });
      }

      setDataForm(data);
      setSelectItem(_.get(response, 'selectItem'));
      setAttachmentData(_.get(response, 'attachmentData'));
      setRefItem([]);
    };

    if (visible && _.get(defaultData, 'action') !== 'create') {
      getDetail();
    } else {
      setFieldsValue({
        // vat: _.get(initial, 'optionsVat.[1].taxPercent')
        vat: 0,
      });
    }
  }, [visible]);

  const handleTabChange = (tab) => {
    setTabChange(tab);
  };

  const handleCancel = () => {
    setVisible(false);
    resetFields();
    setDataForm({});
    setSelectItem([]);
    setTabChange('1');
    setAttachmentData([]);
  };

  const operations = _.get(defaultData, 'action') !== 'create' && (
    <div style={{ textAlign: 'center', display: 'flex', padding: '10px' }}>
      <Tag className="tag-center-style" color={_.get(dataForm, 'statusColor') || ''}>
        {_.get(dataForm, 'statusName') || ''}
      </Tag>
    </div>
  );

  const renderButtons = () => {
    const buttons = [];
    buttons.push(
      <Button02 style={{ margin: '0px 5px' }} key="back" btnsize="wd_df" onClick={handleCancel}>
        <FormattedMessage id="btnClose" defaultMessage="Close" />
      </Button02>
    );

    if (_.includes(nextStatus, 'deleted') && checkPermissionAction('P87PG1C1', 'P87PG1C1A5') && _.get(defaultData, 'action') === 'edit') {
      buttons.push(
        <Button01
          style={{ margin: '0px 5px' }}
          key="delete"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleDelete(_.get(dataForm, 'cnId'))}
        >
          <FormattedMessage id="btnDelete" defaultMessage="Delete" />
        </Button01>
      );
    }
    if (checkPermissionAction('P87PG1C1', 'P87PG1C1A6') || checkPermissionAction('P87PG1C1', 'P87PG1C1A7')) {
      buttons.push(
        <Button01
          style={{ margin: '0px 5px' }}
          key="draft"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSave(_.get(dataForm, 'statusCode') ? _.get(dataForm, 'statusCode') : 'draft')}
          disabled={_.size(selectItem) > 0 && !loadingButton ? false : true}
        >
          <FormattedMessage id="userDetailModalBtnSave" defaultMessage="Save" />
        </Button01>
      );
    }

    if (checkPermissionAction('P87PG1C1', 'P87PG1C1A8')) {
      buttons.push(
        <Button01
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSave('waiting')}
          disabled={_.size(selectItem) > 0 && !loadingButton ? false : true}
          style={{ margin: '0px 5px', width: 'auto' }}
        >
          <FormattedMessage id="btnSendApprove" defaultMessage="Send Approve" />
        </Button01>
      );
    }

    return buttons;
  };

  const checkReason = () => {
    let emptyOther = false;

    _.map(selectItem, (item, index) => {
      if (item.remark === 'RC605') {
        if (item.others === '' || !item.others) {
          emptyOther = true;
          selectItem[index].empty.others = true;
        }
      }
    });

    return emptyOther;
  };

  const checkEmptyAll = () => {
    let empty = false;
    _.map(selectItem, (item, index) => {
      const others = _.get(item, 'empty.others', false);
      const type = _.get(item, 'type') === '' || !_.get(item, 'type') ? true : _.get(item, 'empty.type', false);
      const qty = _.get(item, 'qty') === '' || !_.get(item, 'qty') ? true : _.get(item, 'empty.qty', false);
      const priceUnitValue = _.get(item, 'priceUnit');
      const priceUnit = priceUnitValue === '' || !priceUnitValue || priceUnitValue === 0 || priceUnitValue === "0" 
        ? true 
        : _.get(item, 'empty.priceUnit', false);

      if (others === true || type === true || qty === true || priceUnit === true) {
        empty = true;
      }

      selectItem[index].empty = {
        others: others,
        type: type,
        qty: qty,
        priceUnit: priceUnit,
      };
    });

    return empty;
  };

  const handleSave = async (type) => {
    setLoadingButton(true);
    validateFields(async (err, values) => {
      if (checkReason() || checkEmptyAll()) {
        setLoadingButton(false);
        return;
      }

      if (err) {
        setLoadingButton(false);
        return;
      }

      console.log('handleSave', values, dataForm, selectItem, type, initial);
      const body = {
        creditNoteId: _.get(dataForm, 'cnId') ? _.get(dataForm, 'cnId') : undefined,
        invoiceId: dataForm.invId,
        button: type,
        issueDetail: {
          issueBy: _.get(dataForm, 'issueBy'),
          issueByName: _.chain(initial.optionsIssueBy)
            .filter((issue) => issue.memComId === _.get(dataForm, 'issueBy'))
            .get('[0].fullname')
            .value(),
          issueDate: _.get(dataForm, 'issueDate'),
        },

        itemInvoice: _.map(selectItem, (item) => {
          return {
            _id: item.id,
            itemCode: item.itemCode,
            invoiceQty: _.toNumber(item.invQty),
            qty: _.toNumber(item.qty),
            type: item.type,
            item: item.item,
            unit: item.invUnit,
            unitName: item.invUnitName,
            invPriceUnit: _.toNumber(item.invPriceUnit),
            priceUnit: _.toNumber(item.priceUnit),
            cnRemark: item.remark,
            other: item.others,
            category: item.category,
          };
        }),

        remark: _.get(dataForm, 'remark'),
        totalOrigin: _.get(dataForm, 'totalOrigin'),
        totalCorrect: _.get(dataForm, 'totalCorrect'),
        difference: _.get(dataForm, 'difference'),
        vat: _.get(dataForm, 'vat'),
        vatNumber: _.get(dataForm, 'vatNumber'),
        total: _.get(dataForm, 'total'),
      };

      console.log('handleSave body', body);

      // return;

      const response = _.get(dataForm, 'cnId') ? await saveEditCreditNote(body) : await saveCreditNote(body);

      console.log('handleSave response', response);
      if (response.status === 200) {
        successNotification(_.get(response, 'data.message'));

        if (_.size(attachmentData) > 0) await uploadData(attachmentData, _.get(response, 'data.data.creditNoteId'));

        handleCancel();
        setTrigger((cur) => !cur);
        actionSaveToStatus(type, _.get(response, 'data.data.creditNoteId')); // ส่ง response -> ต้องใช้ cnId
      } else {
        errorNotification(_.get(response, 'data.message'));
      }
      setLoadingButton(false);
    });
  };

  const uploadData = async (att, id) => {
    for (let index = 0; index < att.length; index++) {
      const element = att[index];
      const temp = _.get(element, 'fileRaw');
      if (_.get(temp, 'type') === 'image/jpeg' || _.get(temp, 'type') === 'image/png') {
        const image = await resizeFile(temp);
        const fileChange = dataURLtoFile(image, _.get(temp, 'name').toLowerCase());
        let formData = new FormData();
        formData.append('files', fileChange);
        // formData.append('fileName', _.get(temp, 'name'));
        formData.append('remark', element.remark || '');
        await uploadCnAttachment(formData, id);
      } else if (_.get(temp, 'type') === 'application/pdf') {
        let formDataPDF = new FormData();
        formDataPDF.append('files', temp);
        // formDataPDF.append('fileName', _.get(temp, 'name'));
        formDataPDF.append('remark', element.remark || '');
        await uploadCnAttachment(formDataPDF, id);
      }
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        720,
        720,
        'JPG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    for (var i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      className: 'customer-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const response = await deleteCn(id);

        if (response.status === 200) {
          successNotification(intl.formatMessage({ id: `creditNoteDeletedSuccess`, defaultMessage: 'Credit Note Deleted Successfully' }));
          setTrigger((cur) => !cur);
          setVisible(false);
        } else {
          errorNotification(intl.formatMessage({ id: `creditNoteDeletedUnSuccess`, defaultMessage: 'Credit Note Deleted UnSuccessfully' }));
        }
      },
      onCancel() {},
    });
  };

  return (
    <Modal
      title={
        _.get(defaultData, 'action') === 'create'
          ? intl.formatMessage({ id: `creditNoteModalTitleFormCreate`, defaultMessage: 'Create Credit Note' })
          : intl.formatMessage({ id: `creditNoteModalTitleFormEdit`, defaultMessage: 'Edit Credit Note' })
      }
      visible={visible}
      onCancel={handleCancel}
      width={1000}
      centered={true}
      bodyStyle={{ padding: 'unset' }}
      zIndex={100}
      footer={visible ? renderButtons() : undefined}
    >
      <Tabs animated={false} onChange={handleTabChange} activeKey={tabChange} tabBarExtraContent={operations} className="po-tabs-modal">
        <TabPane tab={intl.formatMessage({ id: `creditNoteModalTitleDetails`, defaultMessage: 'Details' })} key="1">
          <CreditNoteDetail
            form={form}
            initial={initial}
            dataForm={dataForm}
            setDataForm={setDataForm}
            selectItem={selectItem}
            setSelectItem={setSelectItem}
            attachmentData={attachmentData}
            setAttachmentData={setAttachmentData}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: `creditNoteModalTitleReference`, defaultMessage: 'Reference' })} key="2">
          <ReferenceTab visiblemodal={visible} selectItem={refItem} setSelectItem={setRefItem} typeRef={'po'} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

const CreditNoteForm = Form.create({
  name: 'credit-note-form',
  mapPropsToFields() {},
})(ModalCreditNoteForm);

export default CreditNoteForm;
