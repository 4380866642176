import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Divider, Form, Modal, Tabs, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import '../css/index.css';
import CustomerForm from './customer-form';
import CustomTableComponent from '../../components/table';
import { messageLabel } from '../../../../components/message-component';
import PriceForm from './price-form';
import { StickyContainer } from 'react-sticky';
import DetailsTab from './details-tab';
import PaymentTab from './payment-tab';
import CampainTab from './campain-tab';
import UploadFileModal from '../upload-file/upload-modal';
import { AccountReceiveContext } from '../../context';
import _ from 'lodash';
import ModalCreateListItem from '../list-item/add';
import ListItem from '../list-item/list/index';
import moment from 'moment';
import ModalSelectCustomerForm from '../select-customer/customer-modal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import PaymentTabView from './view/payment-pending';
import CampainTabView from './view/campain-pending';
import ModalApproveForm from '../approve/approve-modal';
import ModalCancelForm from '../cancel/cancel-modal';
import ModalRejectForm from '../reject/reject-modal';
import ModalWaitingForm from '../waiting/waiting-modal';
import ModalDraftForm from '../delete/delete-modal';
import ModalSelectQuotationForm from '../select-quotation/quotation-select-modal';
import DetailsTabPending from './view/detail-pending';
import ModalPaidForm from './paid-modal.js';
import { uploadARAttachment, uploadARAttachmentPaid } from '../../../../controllers/account-receive/index.js';
import { async } from 'q';
import ARPrint from '../../../../components/modelprint/ARPrint.js';
import { useReactToPrint } from 'react-to-print';
import ModelAddItemAR from './additem/index.js';
import DetailsTabPendingViewinvoice from './viewinvoice/detail-viewinvoice.js';
import ModalAddARItem from './additem/add-remove.js';
import ModalSelectCustomerBillForm from '../select-customer-new/customer-modal.js';
import { BLTaskPDFDownloader, uploadBLAttachment } from '../../../../controllers/billing-note/billingapi.js';
import BiliingNotePrint from '../../../../components/modelprint/biliingnoteprint.js';
import ModalAddCustomerItemTask from '../select-task/customer-item-task.js';
import DetailsTabTaskPending from './view/detail-task-pending.js';
import { errorNotification } from '../../../../components/v2/notification.js';
import { ThaiNumberToText, UrlPDF } from '../../../../component-function/generator-pdf.js';
import ModalPDFViewver from '../../../../component-function/pdf-viewver.js';

const { TabPane } = Tabs;

const BillingTaskModal = ({ form, title, visible, onOk, onCancel, attData, setAttData, record, signatureUser }) => {
  const { state, setState, func } = useContext(AccountReceiveContext);
  const {
    initialMemberList,
    initialTaxList,
    initialCampaignList,
    initialReasonList,

    setRecord,
    visibleApprove,
    visibleReject,
    visibleCancel,
    visibleDelete,
    visibleWaiting,
    isOpenQuotation,
    attachmentPaidData,
    visibleAddItem,
    pageTable,
    sizeTable,
    warehouseList,
    saleOrderQTY,
    recordList,
    viewDetail,
    totalWarehouseList,
    recordReport,
    selectCustomer,
    statusList,
    isLoadButton,
  } = state;
  const {
    setAttachmentPaidData,
    setPageTable,
    setSizeTable,
    setWarehouseList,
    setSaleOrderQTY,
    setRecordList,
    setTotalWarehouseList,
    setRecordReport,
    setSelectCustomer,
    setIsLoadButton,
  } = setState;
  const {
    handleOpenModalUpload,
    handleOpenApprove,
    handleSaveApprove,
    handleCancelApprove,
    handleOpenReject,
    handleSaveReject,
    handleCancelReject,
    handleOpenCancel,
    handleSaveCancel,
    handleCancelCancelQT,
    handleOpenDelete,
    handleSaveDelete,
    handleCancelDelete,
    handleSaveWaiting,
    handleCancelWaiting,
    handleOpenWaiting,
    handleOpenPaid,
    handleCancelPaid,
    handleOpenAddItem,
    handleCancelAddItem,
    handleCleckSaleOrder,
    handleOpenViewInvoice,
    handleSavePaid,
  } = func;
  const [dataForm, setDataForm] = useState();
  const [tabkeys, setTabkeys] = useState('detail');
  const [visibleItem, setVisibleItem] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [selectWHData, setSelectWHData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQt, setIsOpenQt] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const [tempARAtt, setTempARAtt] = useState([]);
  const [visiblePrint, setVisiblePrint] = useState(false);
  const [urlPrint, setUrlPrint] = useState();

  // console.log('form data for save', dataForm, selectItem);
  console.log('BillingTaskModal dataForm', dataForm);

  const intl = useIntl();
  const { setFieldsValue, resetFields } = form;
  const formCustomerRef = useRef();
  const ARPrintrRef = useRef();

  console.log('formCustomerRefAR', record, '/', selectItem, '/');
  console.log('recordOwer', record);

  useEffect(() => {
    if (record) {
      console.log('formData', record);
      if (_.get(record, 'code') === 'view') {
        let updateData = [];

        if (
          !_.get(record, 'itemList[0].customer.customerId') ||
          !_.get(record, 'itemList[0].contact.contactId') ||
          !_.get(record, 'itemList[0].toAddressId')
        ) {
          setDataForm((item) => ({
            ...record,
            discountNum: 0,
            discountPercent: 0,
            taxPercent: 0,
            taxNum: 0,
            subtotal: _.get(record, 'total'),
            subtotalAfterDiscount: _.get(record, 'total'),
          }));
        } else {
          setDataForm((item) => ({
            ...record,
            discountNum: 0,
            discountPercent: 0,
            taxPercent: 0,
            taxNum: 0,
            subtotal: _.get(record, 'total'),
            subtotalAfterDiscount: _.get(record, 'total'),
            customerId: _.get(record, 'itemList[0].customer.customerId'),
            customerName: _.get(record, 'itemList[0].customer.customerName'),
            customerAddress: _.get(record, 'itemList[0].customer.customerAddress'),
            customerContactId: _.get(record, 'itemList[0].contact.entityRef'),
            customerContactName: _.get(record, 'itemList[0].contact.contactName'),
            customerContactPhone: _.get(record, 'itemList[0].contact.contactPhone'),
            customerContactEmail: _.get(record, 'itemList[0].contact.contactEmail'),
            customerAddressBookId: _.get(record, 'itemList[0].toAddressId'),
            customerAddressBookName: _.get(record, 'itemList[0].toAddressName'),
          }));
        }
      } else {
        setDataForm((item) => ({
          ...record,
          taxPercent: 0,
        }));
      }

      // setDataForm({ ..._.get(record, 'saleOrderDetail'), code: _.get(record, 'code') });

      const itemList = _.map(_.get(record, 'itemList'), (o, i) => ({
        ...o,
        subtotal: _.get(o, 'totalNum'),
        otherValue: _.get(o, 'other') || 0,
        index: i + 1,
      }));

      setSelectItem(itemList);

      // const setobj = _.map(_.get(record, 'attanchment'), (o, i) => ({
      //   ...o,
      //   index: i + 1,
      //   attachmentName: _.get(o, 'fileName'),
      //   remark: _.get(o, 'remark'),
      // }));
      // setAttData(setobj);
    }
  }, [record]);

  useEffect(() => {
    console.log('recoredAtt', record, '/', _.get(record, 'attanchment'));
    if (record) {
      const mapAttachment = _.map(_.get(record, 'attanchment'), (o, i) => {
        let temp = {
          id: _.get(o, 'fileHash'),
          index: i + 1,
          attachmentName: _.get(o, 'fileName'),
          remark: _.get(o, 'remark'),
          file: [{ name: _.get(o, 'fileName'), uid: _.get(o, 'fileHash'), url: _.get(o, 'fileUrl'), action: 'N' }],
          fileRaw: _.get(o, 'fileUrl'),
          action: 'N',
          form: 'backend',
          fileHash: _.get(o, 'fileHash'),
        };
        return temp;
      });
      console.log('mapAttachment', mapAttachment);
      setAttData(mapAttachment);
    }
  }, [record]);

  console.log('dataFormdataForm', dataForm);

  useEffect(() => {
    if (_.get(dataForm, 'customerId')) {
      setFieldsValue({ customerName: _.get(dataForm, 'customerName') });
    }
  }, [dataForm]);

  // useEffect(() => {
  //   console.log('selectItem', selectItem);
  //   if (_.size(selectItem) > 0) {
  //     let cloneItem = [...selectItem];
  //     console.log('cloneItem', cloneItem);

  //     let allCredits = [];
  //     let data = [];

  //     for (let i = 0; _.size(selectItem) > i; i++) {
  //       if (!_.includes(allCredits, cloneItem[i].credits)) {
  //         console.log('allCredits -> ', allCredits, cloneItem[i].credits);

  //         const filterItem = _.filter(selectItem, (obj) => obj.credits === cloneItem[i].credits);

  //         let total = cloneItem[i].totalPrice;

  //         if (_.size(filterItem) > 0) {
  //           const mapTotal = _.map(filterItem, (v, i) => {
  //             return {
  //               totalPrice: _.toNumber(v.totalPrice),
  //             };
  //           });
  //           total = _.sumBy(mapTotal, 'totalPrice');
  //           allCredits.push(cloneItem[i].credits);
  //         }
  //         data.push({
  //           credit: cloneItem[i].credits,
  //           amount: total,
  //         });
  //       }
  //     }

  //     console.log('Quotation soby', data, _.sortBy(data, 'credit'));

  //     if (_.size(data) > 0) {
  //       const sortBy = _.sortBy(data, (o) => _.toNumber(o.credit));
  //       const mapIndex = _.map(sortBy, (o, i) => {
  //         return {
  //           ...o,
  //           index: i + 1,
  //           remark: '',
  //           dueDate: moment().add(o.credit, 'day'),
  //         };
  //       });
  //       setPaymentItem(mapIndex);
  //     }
  //   }
  // }, [selectItem]);

  const handleSave = async (status) => {
    console.log('handleSave', dataForm, status);

    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      if (_.get(dataForm, 'totalAll') <= 0) {
        errorNotification(
          intl.formatMessage({
            id: 'biliingnoteTotalamountshouldnotlessthanorequalto0',
            defaultMessage: 'Total amount should not less than or equal to 0',
          })
        );
        return;
      }

      if (!_.get(dataForm, 'customerAddressBookId')) {
        errorNotification(
          intl.formatMessage({
            id: 'biliingnotePleaseEnterAddressCustomer',
            defaultMessage: 'Please Select Address',
          })
        );
        return;
      }

      setIsLoadButton(true);

      const itemList = _.map(selectItem, (o) => ({
        taskId: _.get(o, 'taskId'),
        remark: '',
        amount: _.get(o, 'amountTemp'),
        other: parseFloat(_.get(o, 'otherValue')) || 0,
      }));

      console.log('itemList', itemList);

      const payloads = {
        billingItemType: 'task',
        customerId: _.get(dataForm, 'customerId'),
        customerName: _.get(dataForm, 'customerName'),
        customerAddressBookId: _.get(dataForm, 'customerAddressBookId'),
        customerContactId: _.get(dataForm, 'addressName') ? _.get(dataForm, 'addressName') : _.get(dataForm, 'customerContactId'),
        customerContactPhone: _.get(dataForm, 'customerContactPhone')
          ? _.get(dataForm, 'customerContactPhone')
          : _.get(dataForm, 'customerPhone'),
        customerContactEmail: _.get(dataForm, 'customerContactEmail')
          ? _.get(dataForm, 'customerContactEmail')
          : _.get(dataForm, 'customerEmail'),
        customerContactName: _.get(dataForm, 'customerContactName')
          ? _.get(dataForm, 'customerContactName')
          : _.get(dataForm, 'customerContact'),
        customerAddressBookName: _.get(dataForm, 'addressName') ? _.get(dataForm, 'addressName') : _.get(dataForm, 'customerAddressName'),
        customerAddressBookFullAddress: _.get(dataForm, 'fullAddress')
          ? _.get(dataForm, 'fullAddress')
          : _.get(dataForm, 'customerAddress'),
        customerTaxNo: _.get(dataForm, 'customerTaxNo'),
        issueById: _.get(values, 'by'),
        issueDate: moment(_.get(values, 'date')).format('YYYY-MM-DD'),
        dueDate: moment(_.get(values, 'dueDate')).format('YYYY-MM-DD'),
        remark: _.get(values, 'remark') || '',
        attachment: [],
        invoiceList: [],
        taskList: itemList,
        itemList: [],
        subTotal: _.get(dataForm, 'sumTotals'),
        discountPercent: parseFloat(_.get(dataForm, 'discountPercent')),
        discount: _.get(dataForm, 'discountNumber'),
        subTotalAfterDiscount: _.get(dataForm, 'subtotalDiscount'),
        taxPercent: _.get(dataForm, 'taxPercentage'),
        tax: _.get(dataForm, 'taxNumber'),
        total: parseFloat(_.get(dataForm, 'totalAll')) + _.get(dataForm, 'taxNumber'),
      };

      // console.log('savex values', att);
      console.log('savex payload', dataForm, payloads, itemList);
      // return;
      onOk(payloads, resetStateAll);
    });
  };

  const resetStateAll = () => {
    resetFields();
    setDataForm();
    setSelectWHData();
    setAttData([]);
    setSelectItem([]);
  };

  const handleCancelQuotation = () => {
    onCancel();
    resetFields();
    setDataForm();
    setSelectWHData();
    setAttData([]);
    setSelectItem([]);
    setSelectCustomer();
  };

  const callback = (key) => {
    setTabkeys(key);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCloseARItem = () => {
    setVisibleAdd(false);
  };

  const operations = _.get(record, 'code') !== 'view' && (
    <div className="tag-center-div" style={{ padding: '12px 2px' }}>
      <Tag style={{ width: '100%', padding: '0px 25px' }} className="tag-center-style" color={_.get(record, 'statusColor')}>
        {_.get(record, 'statusTxt')}
      </Tag>
    </div>
  );

  // const handlePrint = useReactToPrint({
  //   content: () => ARPrintrRef.current,
  //   documentTitle: 'data',
  //   copyStyles: true,
  // });

  const handlePrint = async () => {
    const formateData = fomatePDF(record);
    const response = await BLTaskPDFDownloader(_.get(record, 'billingNo'), formateData);
    if (_.get(response, 'status') === 200) {
      const url = await UrlPDF(response);
      if (url) {
        setUrlPrint(url);
        setVisiblePrint(true);
      }
      // GeneratorPDF(response)
    }
  };

  const fomatePDF = (props) => {
    const totalNum = _.get(props, 'total') ? _.get(props, 'total') : 0;
    return {
      ...record,
      totalNum: totalNum,
      totalNumberText: ThaiNumberToText(totalNum.toFixed(2)),
      discountTxt: numberFormatter(_.get(props, 'discountNum')),
      discountPercentTxt: numberFormatter(_.get(props, 'discountPercent')),
      taxTxt: numberFormatter(_.get(props, 'taxNum')),
      taxPercentageTxt: numberFormatter(_.get(props, 'taxPercentage')),
      subTotalTxt: numberFormatter(_.get(props, 'subtotal')),
      subTotalAfterDiscountTxt: numberFormatter(_.get(props, 'subtotalAfterDiscount')),
      totalTxt: numberFormatter(totalNum.toFixed(2)),
    };
  };


  const handleDelete = () => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
        const payload = {
          id: _.get(record, 'saleOrderId'),
          code: _.get(record, 'code'),
          status: 'deleted',
        };
        handleSaveDelete(payload);
      },
      onCancel() { },
    });
  };

  const handleOk = () => {
    setIsOpen(false);
    setDataForm((prev) => ({ ...prev, quotationObj: {} }));
    form.setFieldsValue({ quotationNo: '' });
  };
  

  const actionFooter =
    _.get(record, 'code') === 'view'
      ? [
        <Button_02
          style={{ margin: '0px 0px 0px 10px' }}
          key="back"
          btnsize="wd_df"
          onClick={() => {
            handleCancelQuotation();
            resetFields();
            setDataForm();
            setSelectWHData();
            setAttData([]);
            setSelectItem([]);
          }}
        >
          <FormattedMessage id="btnClose" defaultMessage="Close" />
        </Button_02>,
        <Button_01
          style={{ margin: '0px 0px 0px 10px' }}
          key="submit"
          type="primary"
          btnsize="wd_df"
          onClick={() => handleSave('pending')}
          disabled={_.size(selectItem) && !isLoadButton ? false : true}
        >
          <FormattedMessage id="btnSave" defaultMessage="Save" />
        </Button_01>,
      ]
      : _.get(record, 'code') === 'waiting'
        ? [
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                <FormattedMessage id="btnPrint" defaultMessage="Print" />
              </Button_01>
            </div>
            <div style={{ display: 'flex' }}>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                <FormattedMessage id="btnClose" defaultMessage="Close" />
              </Button_02>
              <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="cancelQT"
                type="primary"
                btnsize="wd_df"
                onClick={() => {
                  handleOpenPaid('waiting', _.get(record, 'billingId'));
                }}
              >
                <FormattedMessage id="ARPaid" defaultMessage="Paid" />
              </Button_01>{' '}
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
                <FormattedMessage id="BLbtnCAncel" defaultMessage="Cancel" />
              </Button_01>
            </div>
          </div>,
          // <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
          //   <FormattedMessage id="btnClose" defaultMessage="Close" />
          // </Button_02>,
          // <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
          //   <FormattedMessage id="btnPrint" defaultMessage="Print" />
          // </Button_01>,
          // <Button_01
          //   style={{ margin: '0px 0px 0px 10px' }}
          //   key="cancelQT"
          //   type="primary"
          //   btnsize="wd_df"
          //   onClick={() => {
          //     handleOpenPaid('waiting', _.get(record, 'billingId'));
          //   }}
          // >
          //   <FormattedMessage id="ARPaid" defaultMessage="Paid" />
          // </Button_01>,
          // // <Button_01 style={{ margin: '0px 0px 0px 10px', width: '10%' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
          // //   <FormattedMessage id="BLbtnCAncel" defaultMessage="Cancel" />
          // // </Button_01>,
        ]
        : _.get(record, 'code') === 'partialpayment'
          ? [
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                  <FormattedMessage id="btnPrint" defaultMessage="Print" />
                </Button_01>
              </div>
              <div style={{ display: 'flex' }}>
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                  <FormattedMessage id="btnClose" defaultMessage="Close" />
                </Button_02>
                {/* <Button_01
                style={{ margin: '0px 0px 0px 10px' }}
                key="cancelQT"
                type="primary"
                onClick={() => {
                  handleOpenPaid('paid', _.get(record, 'billingId'));
                }}
              >
                <FormattedMessage id="ARPayment" defaultMessage="Payment" />
              </Button_01> */}
                <Button_01
                  style={{ margin: '0px 0px 0px 10px' }}
                  key="cancelQT"
                  type="primary"
                  btnsize="wd_df"
                  onClick={() => {
                    handleOpenPaid('waiting', _.get(record, 'billingId'));
                  }}
                >
                  <FormattedMessage id="ARPaid" defaultMessage="Paid" />
                </Button_01>
                <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
                  <FormattedMessage id="BLbtnCAncel" defaultMessage="Cancel" />
                </Button_01>
              </div>
            </div>,
            // <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
            //   <FormattedMessage id="btnClose" defaultMessage="Close" />
            // </Button_02>,
            // <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
            //   <FormattedMessage id="btnPrint" defaultMessage="Print" />
            // </Button_01>,

            // <Button_01
            //   style={{ margin: '0px 0px 0px 10px' }}
            //   key="cancelQT"
            //   type="primary"
            //   btnsize="wd_df"
            //   onClick={() => {
            //     handleOpenPaid('partialpayment', _.get(record, 'billingId'));
            //   }}
            // >
            //   <FormattedMessage id="ARPaid" defaultMessage="Paid" />
            // </Button_01>,
            // <Button_01 style={{ margin: '0px 0px 0px 10px', width: '10%' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
            //   <FormattedMessage id="BLbtnCAncel" defaultMessage="Cancel" />
            // </Button_01>,
          ]
          : _.get(record, 'code') === 'paid'
            ? [
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                  <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
                    <FormattedMessage id="btnPrint" defaultMessage="Print" />
                  </Button_01>
                </div>
                <div style={{ display: 'flex' }}>
                  <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                    <FormattedMessage id="btnClose" defaultMessage="Close" />
                  </Button_02>
                  <Button_01
                    style={{ margin: '0px 0px 0px 10px' }}
                    key="cancelQT"
                    type="primary"
                    onClick={() => {
                      handleOpenPaid('paid', _.get(record, 'billingId'));
                    }}
                  >
                    <FormattedMessage id="ARPayment" defaultMessage="Payment" />
                  </Button_01>
                  <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
                    <FormattedMessage id="BLbtnCAncel" defaultMessage="Cancel" />
                  </Button_01>
                </div>
              </div>,
              // <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
              //   <FormattedMessage id="btnClose" defaultMessage="Close" />
              // </Button_02>,
              // <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={() => handlePrint()}>
              //   <FormattedMessage id="btnPrint" defaultMessage="Print" />
              // </Button_01>,
              // <Button_01
              //   style={{ margin: '0px 0px 0px 10px' }}
              //   key="cancelQT"
              //   type="primary"
              //   onClick={() => {
              //     handleOpenPaid('paid', _.get(record, 'billingId'));
              //   }}
              // >
              //   <FormattedMessage id="ARPayment" defaultMessage="Payment" />
              // </Button_01>,
              // <Button_01 style={{ margin: '0px 0px 0px 10px', width: '10%' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
              //   <FormattedMessage id="BLbtnCAncel" defaultMessage="Cancel" />
              // </Button_01>,
              // <Button_01 style={{ margin: '0px 0px 0px 10px', width: '15%' }} key="reject" type="primary" onClick={() => handleOpenCancel()}>
              //   <FormattedMessage id="ARCanceledInvoice" defaultMessage="Canceled Invoice" />
              // </Button_01>,
              // <Button_01 style={{ margin: '0px 0px 0px 10px', width: '18%' }} key="waiting" type="primary" onClick={() => handleOpenWaiting()}>
              //   <FormattedMessage id="ARWaitingPayment" defaultMessage="Waiting for Payment" />
              // </Button_01>,
            ]
            : _.get(record, 'code') === 'canceled'
              ? [
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                  <FormattedMessage id="btnClose" defaultMessage="Close" />
                </Button_02>,
                //   <Button_01 style={{ margin: '0px 0px 0px 10px', width: '18%' }} key="waiting" type="primary" onClick={() => handleOpenWaiting()}>
                //     <FormattedMessage id="ARWaitingPayment" defaultMessage="Waiting for Payment" />
                //   </Button_01>,
              ]
              : [
                <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleCancelQuotation}>
                  <FormattedMessage id="btnClose" defaultMessage="Close" />
                </Button_02>,
              ];

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancelQuotation}
      width={1000}
      centered={true}
      // bodyStyle={{ height: 'calc(100vh - 150px)', overflowY: 'scroll', padding: '0px' }}
      bodyStyle={{ padding: 'unset' }}
      footer={actionFooter}
    >
      <StickyContainer>
        <Tabs
          className="monitor-main-tabs customer-tab-margin"
          size="large"
          defaultActiveKey="detail"
          onChange={callback}
          animated={false}
          tabBarExtraContent={operations}
        >
          <TabPane tab={intl.formatMessage({ id: `quotationLabelDetails`, defaultMessage: 'Details' })} key="details">
            {_.get(record, 'code') === 'view' && (
              <DetailsTabTaskPending
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  handleOpenAddItem,
                  saleOrderQTY,
                  setSaleOrderQTY,
                  recordList,
                }}
              />
            )}

            {_.get(record, 'code') === 'waiting' && (
              <DetailsTabTaskPending
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  handleOpenAddItem,
                }}
              />
            )}

            {_.get(record, 'code') === 'partialpayment' && (
              <DetailsTabTaskPending
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  handleOpenAddItem,
                }}
              />
            )}

            {_.get(record, 'code') === 'paid' && (
              <DetailsTabTaskPending
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                  handleOpenAddItem,
                }}
              />
            )}

            {_.get(record, 'code') === 'canceled' && (
              <DetailsTabTaskPending
                form={form}
                property={{
                  handleOpenModalUpload,
                  setDataForm,
                  dataForm,
                  setVisibleItem,
                  initialMemberList,
                  handleOpen,
                  selectItem,
                  setSelectItem,
                  attData,
                  setAttData,
                  initialTaxList,
                }}
              />
            )}
          </TabPane>
        </Tabs>
      </StickyContainer>

      {_.get(record, 'code') === 'viewinvoice' && (
        <DetailsTabPendingViewinvoice
          form={form}
          property={{
            setDataForm,
            dataForm,
            selectItem,
            setSelectItem,
            viewDetail,
            handleOpenViewInvoice,
          }}
        />
      )}

      <div style={{ display: 'none' }}>
        <BiliingNotePrint
          ref={ARPrintrRef}
          record={record}
          signatureUser={signatureUser}
          recordReport={recordReport}
          setRecordReport={setRecordReport}
        />
      </div>
      <ModalCancelForm
        title={intl.formatMessage({ id: 'ARCanceledInvoice', defaultMessage: 'Canceled Invoice' }) + ' · ' + _.get(record, 'billingNo')}
        visible={visibleCancel}
        onOk={handleSaveCancel}
        onCancel={handleCancelCancelQT}
        initialReasonList={_.get(record, 'reasonToCanceled')}
        arId={_.get(record, 'billingId')}
        code={_.get(record, 'code')}
      />

      <ModalWaitingForm
        title={intl.formatMessage({ id: 'ARWaitingPayment', defaultMessage: 'Waiting for Payment' }) + ' · ' + _.get(record, 'saleOrderNo')}
        visible={visibleWaiting}
        onOk={handleSaveWaiting}
        onCancel={handleCancelWaiting}
        initialReasonList={_.get(record, 'reasonToWaiting')}
        arId={_.get(record, 'arId')}
        code={_.get(record, 'code')}
      />
      {/* paid */}
      <ModalPaidForm
        title={intl.formatMessage({ id: 'ARPaid', defaultMessage: 'Paid' }) + ' · ' + _.get(record, 'billingNo')}
        onCancel={handleCancelPaid}
        initialMemberList={initialMemberList}
        attData={attachmentPaidData}
        setAttData={setAttachmentPaidData}
        attDatainvoice={attData}
        recordAR={record}
        onOk={handleSavePaid}
        saleId={_.get(record, 'billingId')}
        blNo={_.get(record, 'billingNo')}
        typeChecked={'task'}
      />

      <ModelAddItemAR
        title={
          intl.formatMessage({ id: 'ARPendingDeliverie', defaultMessage: 'Pending Deliverie' }) +
          ' · ' +
          `${intl.formatMessage({ id: `ARSONo`, defaultMessage: 'SO No' })}` +
          ' ' +
          _.get(record, 'saleOrderDetail.saleOrderNo')
        }
        visible={visibleAddItem}
        onCancel={handleCancelAddItem}
        initialMemberList={initialMemberList}
        attData={attachmentPaidData}
        setAttData={setAttachmentPaidData}
        attDatainvoice={attData}
        record={record}
        onOk={onOk}
        saleId={_.get(record, 'saleOrderNoId')}
        form={form}
        paginationPage={pageTable}
        setPaginationPage={setPageTable}
        paginationShow={sizeTable}
        setPaginationSize={setSizeTable}
        warehouseList={warehouseList}
        saleOrderQTY={saleOrderQTY}
        setWarehouseList={setWarehouseList}
        setSaleOrderQTY={setSaleOrderQTY}
        handleCleckSaleOrder={handleCleckSaleOrder}
        recordList={recordList}
        setRecordList={setRecordList}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        totalWarehouseList={totalWarehouseList}
        setTotalWarehouseList={setTotalWarehouseList}
      />

      <ModalSelectCustomerBillForm
        ref={formCustomerRef}
        visible={isOpen}
        setIsOpen={setIsOpen}
        onOk={handleOk}
        dataForm={dataForm}
        setDataForm={setDataForm}
        isOpenQuotation={isOpenQuotation}
        selectCustomer={selectCustomer}
        setSelectCustomer={setSelectCustomer}
        setSelectItem={setSelectItem}
      />

      <ModalPDFViewver visible={visiblePrint} setVisible={setVisiblePrint} url={urlPrint} handlePrint={handlePrint} />

    </Modal>
  );
};

BillingTaskModal.defaultProps = {
  title: 'Modal',
  visible: false,
  onOk: () => console.warn('onOk not function '),
  onCancel: () => console.warn('onCancel not function '),
};

const ModalBillingTaskForm = Form.create({ name: 'Billing_Form' })(BillingTaskModal);

export default ModalBillingTaskForm;
