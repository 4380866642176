import { Col, DatePicker, Form, Row, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LabeRequireForm from '../../../../components/label-required-form';
import { numberFormatDecimal } from '../../../../component-function/fnc-number-format-decimal';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../component-function/fnc-number';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';

const PriceForm = ({ form, selectItem, setDataForm, dataForm, initialTaxList, record }) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const issueDate = getFieldValue('date');
  const intl = useIntl();
  const discount = parseInt(getFieldValue('discount') || 0);
  const discountNumber = parseFloat(getFieldValue('discountNumber') || 0.0);
  const tax = getFieldValue('tax');
  const taxNumber = parseFloat(getFieldValue('taxNumber') || 0.0);
  const sumTotal = _.size(selectItem) > 0 ? _.sumBy(selectItem, (o) => _.toNumber(o.totalPrice)) : 0.0;
  const sumTotalSum = sumTotal
  // const sumTotal = 3000.02

  const subtotalDiscount = sumTotal - discountNumber;
  const subtotalTax = numberFormatter(parseFloat(subtotalDiscount) + taxNumber);
  console.log('PriceForm', selectItem, dataForm);
  console.log('PriceForm y', _.toNumber(sumTotal), sumTotal, _.toNumber(subtotalDiscount));
  console.log('PriceForm X', discount, discountNumber, tax, taxNumber, sumTotal, subtotalDiscount, subtotalTax);

  const [startUseEffectSumTotalSum, setStartUseEffectSumTotalSum] = useState(false)

  console.log('recorddddddddddd', !record, record, discount, discountNumber, dataForm, startUseEffectSumTotalSum);

    useEffect(() => {
      if (_.get(record, 'discountPercent') || _.get(record, 'discount')) {
        console.log('ondiscount', record, _.get(record, 'discountPercent'), _.get(record, 'discount'))
        setFieldsValue({
          ['discount']: _.get(record, 'discountPercent') ? _.get(record, 'discountPercent').toFixed(2) : 0.0,
          ['discountNumber']:  _.get(record, 'discount') ? _.get(record, 'discount').toFixed(2) : 0.0,
        })
      } 
  
    }, [record])


  useEffect(() => {
    console.log('sumTotalSum', sumTotalSum, discount)
    
    if(startUseEffectSumTotalSum) {
      setFieldsValue({
        ['discountNumber']: ((sumTotalSum * discount) / 100).toFixed(2),
        ['taxNumber']: ((parseFloat(sumTotalSum) * tax) / 100).toFixed(2),
      });
    } else {
      setStartUseEffectSumTotalSum(true)
    }
  }, [sumTotalSum])

  // useEffect(() => {
  //   if (discount && discount > 0) {
  //     console.log('PriceForm X discountNumber', (sumTotal * discount) / 100, sumTotal * discount);
  //     setFieldsValue({
  //       ['discountNumber']: ((sumTotal * discount) / 100).toFixed(2),
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   } else {
  //     setFieldsValue({
  //       ['discount']: 0,
  //       ['discountNumber']: 0.0,
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   }
  // }, [discount, sumTotal]);

  // useEffect(() => {
  //   console.log('recorddddddddddd value', discountNumber)

  //   if (discountNumber && discountNumber > 0) {
  //     console.log('recorddddddddddd if')
  //     setFieldsValue({
  //       ['discount']: ((discountNumber / sumTotal) * 100).toFixed(2),
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   } else {
  //     console.log('recorddddddddddd else')

  //     setFieldsValue({
  //       ['discount']: 0,
  //       ['discountNumber']: 0.0,
  //     });
  //     setDataForm((o) => ({
  //       ...o,
  //       sumTotal: sumTotal,
  //       subtotalDiscount: subtotalDiscount,
  //       taxNumber: taxNumber,
  //     }));
  //   }
  // }, [discountNumber, sumTotal]);

  useEffect(() => {
    if (tax >= 0) {
      setFieldsValue({
        ['taxNumber']: ((parseFloat(subtotalDiscount) * tax) / 100).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: (parseFloat(subtotalDiscount) * tax) / 100,
      }));
    }
  }, [tax, subtotalDiscount]);

  const onChangeDays = (value) => {
    if (value) {
      setFieldsValue({
        ['validDate']: moment(issueDate).add(_.toInteger(value), 'day'),
      });
    } else {
      setFieldsValue({
        ['validDate']: null,
      });
    }

    console.log('valueeee', value, dataForm);
  };

  const onChangeDate = (value) => {
    if (value) {
      const valueDays = moment(value).format('YYYY-MM-DD');
      const currentDays = moment().format('YYYY-MM-DD');
      const diffDays = moment(valueDays, 'YYYY-MM-DD').diff(moment(currentDays, 'YYYY-MM-DD'), 'days');

      let newFormat = diffDays;

      if (diffDays >= 0) {
        newFormat = diffDays;
      } else {
        newFormat = 0;
      }

      setFieldsValue({
        ['validDay']: _.toString(newFormat),
      });
    } else {
      setFieldsValue({
        ['validDay']: null,
      });
    }
  };

  const onChangeDiscount = (value) => {
    console.log('onChangeDiscount', value);
    if (value && value > 0) {
      console.log('PriceForm X discountNumber', (sumTotal * value) / 100, sumTotal * value);
      setFieldsValue({
        // ['discount']: value.toFixed(2),
        ['discountNumber']: ((sumTotal * value) / 100).toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    }
  };

  const onChangeDiscountNumber = (value) => {
    if (value && value > 0) {
      setFieldsValue({
        ['discount']: ((value / sumTotal) * 100).toFixed(2),
        // ['discountNumber']: value.toFixed(2),
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    } else {
      setFieldsValue({
        ['discount']: 0,
        ['discountNumber']: 0.0,
      });
      setDataForm((o) => ({
        ...o,
        sumTotal: sumTotal,
        subtotalDiscount: subtotalDiscount,
        taxNumber: taxNumber,
      }));
    }
  };

  // useEffect(() => {
  //   setDataForm((o) => ({
  //     ...o,
  //     subtotalTax: subtotalTax,
  //   }));
  // }, [tax, subtotalDiscount, discountNumber, sumTotal, discount, sumTotal]);
  return (
    <Form name="pirce" className='po-form'>
      <Row gutter={[24]}>
        <Col span={10}>
          <Row gutter={[24]}>
            <Col span={8}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidto', defaultMessage: 'Valid to' })} req={true} />
                }
              >
                {getFieldDecorator('validDay', {
                  initialValue: _.get(record, 'validDay') || '',
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnDay', defaultMessage: 'Please select Day' }),
                    },
                  ],
                })(
                  <Input
                    suffix={intl.formatMessage({ id: 'quotationLabelDays', defaultMessage: 'Days' })}
                    onChange={(e) => onChangeDays(e.target.value)}
                    onKeyDown={handleKeyDownNumber}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item colon={false} label={<LabeRequireForm text={' '} req={false} />}>
                {getFieldDecorator('validDate', {
                  initialValue: _.get(record, 'validDate') ? moment(_.get(record, 'validDate'), 'YYYY-MM-DD') : undefined,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'quotationWarnvalidDate', defaultMessage: 'Please select valid date' }),
                    },
                  ],
                })(
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({ id: 'quotationHintValidDate', defaultMessage: 'Select valid Date' })}
                    onChange={(e) => onChangeDate(e)}
                    disabledDate={(value) => value < form.getFieldValue('date')}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24]}>
            <Col span={24}>
              <Form.Item
                colon={false}
                label={
                  <LabeRequireForm text={intl.formatMessage({ id: 'quotationLabelValidRemark', defaultMessage: 'Remark' })} req={false} />
                }
              >
                {getFieldDecorator('validRemark', { initialValue: _.get(record, 'remark') || '' })(
                  <TextArea autoSize={{ minRows: 4, maxRows: 4 }} maxLength={250} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <div className="group-form-price">
            <div>
              <span className='label-bold'>
                <FormattedMessage id="quotationLabelSubtotal" defaultMessage="Subtotal" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(sumTotal)} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className='label-bold'>
                <FormattedMessage id="quotationLabelDiscount" defaultMessage="Discount" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">
                  <Form.Item colon={false}>
                    {getFieldDecorator('discount', {
                      initialValue: _.get(record, 'discountPercent') ? _.get(record, 'discountPercent').toFixed(2) : 0.0,
                    })(
                      <Input
                        suffix={intl.formatMessage({ id: 'quotationWarnPercent', defaultMessage: '%' })}
                        onChange={(e) => onChangeDiscount(e.target.value)}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className="group-form-item-price-input">
                  <Form.Item colon={false}>
                    {getFieldDecorator('discountNumber', {
                      initialValue: _.get(record, 'discount') ? _.get(record, 'discount').toFixed(2) : 0.0,
                    })(
                      <Input
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                        onChange={(e) => onChangeDiscountNumber(e.target.value)}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className='label-bold'>
                <FormattedMessage id="quotationLabelSubtotalAfterDiscount" defaultMessage="Subtotal After Discount" />
              </span>
            </div>
            <div>
              <span>
                {numberFormatter(subtotalDiscount)} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className='label-bold'>
                <FormattedMessage id="quotationLabelTax" defaultMessage="Tax" />
              </span>
            </div>
            <div>
              <div className="group-form-item-price">
                <div className="group-form-item-price-percent">
                  <Form.Item colon={false}>
                    {getFieldDecorator('tax', {
                      initialValue: _.get(record, 'taxPercent') || _.get(initialTaxList, '[0].percentage'),
                    })(
                      <Select>
                        {_.map(initialTaxList, (item, i) => (
                          <Option key={i} value={item.percentage}>
                            {item.titleCode}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
                <div className="group-form-item-price-input">
                  <Form.Item colon={false}>
                    {getFieldDecorator('taxNumber', {
                      initialValue: _.get(record, 'tax') || 0.0,
                    })(
                      <Input
                        onKeyDown={handleKeyDownDecimal2Fixed}
                        readOnly={true}
                        suffix={intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="group-form-price">
            <div>
              <span className='label-bold'>
                <FormattedMessage id="quotationLabelTotal" defaultMessage="Total" />
              </span>
            </div>
            <div>
              <span>
                {subtotalTax} <FormattedMessage id="quotationLabelTHB" defaultMessage="THB" />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PriceForm.defaultProps = {};

export default PriceForm;
