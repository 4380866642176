import React, { useState } from 'react';
import CustomerForm from './customer-form-view';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import PriceForm from './price-form-view';
import { Button, Divider } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import ReactBnbGallery from 'react-bnb-gallery';

const DetailsTabView = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    record,
  } = property;
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: true,
      width: 120,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      sorter: true,
      render: (text, record, index) => record.qty + ' ' + record.uomName,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnPrice', defaultMessage: 'Price/Unit' }),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text, record, index) => numberFormatter(record.pricePerUnit) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'quotationLblColumnCredits', defaultMessage: 'Credit (days)' }),
      dataIndex: 'credits',
      key: 'credits',
      sorter: true,
      render: (text, record, index) => record.credits ? record.credits : '-',
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnPromotionCampaign', defaultMessage: 'Promotion or Campaign' }),
    //   dataIndex: 'promotionCampaign',
    //   key: 'promotionCampaign',
    //   render: (text, record, index) => record.promotionCampaign || '-',
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsDiscount', defaultMessage: 'Discount' }),
      dataIndex: 'discountPrice',
      key: 'discountPrice',
      render: (text, record, index) => numberFormatter(record.discountPrice) + ' ' + 'THB',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: true,
      fixed: 'right',
      // width: 120,
      render: (text, record, index) => numberFormatter(record.totalPrice) + ' ' + 'THB',
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (x, data) =>
        _.get(record, 'code') === 'view' ? <span onClick={() => handlePreview(data)}>{data.attachmentName}</span> : data.attachmentName,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attData, (item) => {
      return item.id !== value;
    });
    setAttData(filterSelect);
  };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const deleteItem = (value) => {
    const filterSelect = _.filter(selectItem, (item) => {
      return item.itemMasterId !== value;
    });
    setSelectItem(filterSelect);
  };

  const contentActionItem = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record.itemMasterId)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.id)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const handlePreview = async (file) => {
    console.log('filehandlePreview', file);
    let isPDF = _.get(file, 'attachmentName').includes('.pdf');
    if (isPDF) return window.open(_.get(file, 'fileRaw'), '_blank');
    const mapdata = {
      photo: _.get(file, 'fileRaw'),
      number: 1,
      caption: _.get(file, 'remark') !== 'undefined' ? _.get(file, 'remark') : undefined,
      thumbnail: _.get(file, 'fileRaw'),
    };

    console.log('mapdata', mapdata);
    setModalView(mapdata);
    setStateModal(!stateModal);
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };

  return (
    <div className="scroll-sm scroll-height" style={{height: '550px'}}>
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
          record={record}
        />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelItem" defaultMessage="Item" />
          </span>
          <div>
            {/* <Button_01 key="add" style={{ margin: '0px 0px 0px 10px' }} type="primary" btnsize="wd_df" onClick={() => setVisibleItem(true)}>
              <FormattedMessage id="btnAdd" defaultMessage="Add" />
            </Button_01> */}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={false}
            role={false}
            columns={columns}
            dataSource={selectItem}
            scroll={{ x: true }}
            contentAction={contentActionItem}
          />
          <div className="content-body-price">
            <PriceForm form={form} selectItem={selectItem} setDataForm={setDataForm} dataForm={dataForm} initialTaxList={initialTaxList} record={record}/>
          </div>
        </div>
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelAttactment" defaultMessage="Attactment" />
          </span>
          <div>
            {/* <Button_01
              key="add"
              style={{ margin: '0px 0px 0px 10px' }}
              type="primary"
              btnsize="wd_df"
              onClick={() => handleOpenModalUpload()}
            >
              <FormattedMessage id="btnUpload" defaultMessage="Upload" />
            </Button_01> */}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={false}
            role={true}
            columns={columnsAttach}
            dataSource={attData}
            scroll={{ x: true }}
            contentAction={contentAction}
          />
          <HandlerModal />
        </div>
      </div>
    </div>
  );
};

export default DetailsTabView;
