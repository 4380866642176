import { Button, Col, DatePicker, Icon, Modal, Row, Select } from 'antd';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SuffixInput from '../../../components/v2/suffix';
import { DownOutlined } from '@ant-design/icons';
import '../index.css';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';
import moment from 'moment';

const { Option, OptGroup } = Select;

const FilterMultiple = (props) => {
  const intl = useIntl();
  const { mapSelectFilter, setMapSelectFilter, isFullScreen, setIsFullScreen } = props;

  const [activeView, setActiveView] = useState('store');

  const options = [
    {
      label: intl.formatMessage({ id: 'taecoTableTalaadthaiOptRental', defaultMessage: 'Rental' }),
      value: [
        {
          value: 'contributionRate',
          label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpContributionRate', defaultMessage: 'Contribution Rate' }),
        },
        {
          value: 'availableLots',
          label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpAvailableLots', defaultMessage: 'Available Lots' }),
        },
        {
          value: 'occupancyRate',
          label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpOccupancyRate', defaultMessage: 'Occupancy Rate' }),
        },
        {
          value: 'inOut',
          label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpInOutLots', defaultMessage: 'In/Out Lots' }),
          disabled: true,
        },
        { value: 'arr', label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpARR', defaultMessage: 'ARR' }) },
      ],
    },

    {
      label: intl.formatMessage({ id: 'taecoTableTalaadthaiOptUnloading', defaultMessage: 'Unloading' }),
      value: [
        { value: 'revenue', label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpRevenue', defaultMessage: 'Revenue (Unloading)' }) },
        { value: 'sla', label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpSLA', defaultMessage: 'SLA (Unloading)' }), disabled: true },
        {
          value: 'transaction',
          label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpTransaction', defaultMessage: 'Transaction (Unloading)' }),
        },
      ],
    },

    
    {
      label: intl.formatMessage({ id: 'taecoTableTalaadthaiOptParkingLots', defaultMessage: 'Parking Lots' }),
      value: [
        {
          value: 'availableParkingLots',
          label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpAvailableParkingLots', defaultMessage: 'Available Parking Lots' }),
          disabled: true,
        },
        {
          value: 'utilizationRate',
          label: intl.formatMessage({ id: 'taecoTableTalaadthaiOpUtilizationRate', defaultMessage: 'UtilizationRate' }),
          disabled: true,
        },
      ],
    },
  ];

  const handleChange = (values) => {
    console.log('handleChange', values);
    if (_.size(values) <= 2) {
      setMapSelectFilter(values);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
    <div
      style={{
        // display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 24px 0px 24px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        // position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 1,
      }}
    >
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <div
            style={{
              // position: 'relative',
              width: '400px',
            }}
          >
            <Select
              mode="multiple"
              placeholder={intl.formatMessage({ id: 'taecoTableTalaadthaiPHSelectFilterMap', defaultMessage: 'Select Filter' })}
              style={{
                width: '400px',
              }}
              value={mapSelectFilter}
              onChange={handleChange}
            >
              {options.map((opt) => (
                <OptGroup label={opt.label}>
                  {opt.value.map((option) => (
                    <Option
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                      style={option.disabled ? { backgroundColor: '#fff', color: '#909AAF' } : {}}
                    >
                      {option.label}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
            <span
              style={{
                position: 'absolute',
                top: '50%',
                right: '12px',
                transform: 'translateY(-50%)',
                fontSize: '14px',
                color: '#999',
                pointerEvents: 'none',
              }}
            >
              {_.size(mapSelectFilter)} / 2{/* <DownOutlined style={{fontSize: '12px', color: '#000', marginLeft: '6px'}}/> */}
            </span>
          </div>
        </Col>
        <Col>
          <div style={{ display: 'inline-flex' }}>
            <div className="toggle-container">
              <Button className={`toggle-button ${activeView === 'store' ? 'active' : ''}`} onClick={() => setActiveView('store')}>
                <Icon type="shop" style={{ fontSize: 24 }} />
              </Button>
              <Button className={`toggle-button ${activeView === 'car' ? 'active' : ''}`} onClick={() => setActiveView('car')}>
                <Icon type="car" style={{ fontSize: 24 }} />
              </Button>
            </div>

            <Button_01
              key="submit"
              type="primary"
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '40%',
                height: 'auto',
                margin: '0px 0px 0px 20px',
                padding: '0px'
              }}
              onClick={() => setIsFullScreen((prev) => !prev)}
            >
              {isFullScreen ? (
                <Icon type="fullscreen-exit" style={{ fontSize: 24 }} />
              ) : (
                <Icon type="fullscreen" style={{ fontSize: 24 }} />
              )}
            </Button_01>
          </div>
        </Col>
      </Row>
    </div>
    </div>
  );
};

export default FilterMultiple;
