import _ from 'lodash';
import httpClient from '../../components/axiosClient';
const comCode = localStorage.getItem('comCode');

const getTeacoMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/getTeacoMarket`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getTotalTeacoMarketHeader = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/getTeacoHeader`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getMarketAll = async () => {
  try {
    const response = await httpClient.get(`/v1/datalake/manager/getmarketall`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getMarketAllGroup = async () => {
  try {
    const response = await httpClient.get(`/v1/datalake/manager/getmarketgroupall`);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findRevenueAndNet = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/findrevenueandnet`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findOccrateAndArr = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/findoccrateandarr`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findAreaUtilization = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/findareautilization`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findAreaUtilizationMarketGroup = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/findareautilizationbymarketgroup`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findRevenue = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/service/findrevenue`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findServicesla = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/service/findsla`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findServiceVehicle = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/service/findvehicle`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findServiceLaborHR = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/service/findlaborproductivityhr`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findServiceLaborKG = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/service/findlaborproductivitykg`, payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getTotalMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/market/gettotalmarket`,payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findNetAreaMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/market/revenue/findnetarea`,payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findOccRateMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/market/revenue/findoccrate`,payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findArrActualMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/market/revenue/findarractual`,payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findOccrateAndArrMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/market/revenue/findoccrateandarr`,payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const findRevenueMarket = async (payload) => {
  try {
    const response = await httpClient.post(`/v1/datalake/manager/market/revenue/findrevenue`,payload);
    if (_.get(response, 'status') === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};


export {
  getTeacoMarket,
  getTotalTeacoMarketHeader,
  getMarketAll,
  findRevenueAndNet,
  findOccrateAndArr,
  findAreaUtilization,
  findAreaUtilizationMarketGroup,
  getMarketAllGroup,
  findRevenue,
  findServicesla,
  findServiceVehicle,
  findServiceLaborHR,
  findServiceLaborKG,
  getTotalMarket,
  findNetAreaMarket,
  findOccRateMarket,
  findArrActualMarket,
  findOccrateAndArrMarket,
  findRevenueMarket,
}