import _ from 'lodash';

const downloadExcelArrayBuffer = ({ binaryString, disposition }) => {
  let fileName = disposition ? _.get(disposition.split('filename='), '[1]') : 'download.xlsx';

  const blob = new Blob([binaryString], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export { downloadExcelArrayBuffer };
