import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { Divider, Spin } from 'antd';
import moment from 'moment';
import { MarketContext } from '../context';
import FilterOnTopTab from '../../talaadthai/component/filter-op-top-tab';
import TableRevenueLeastArea from '../../talaadthai/component/table-net-least-area';
import DisplayBtn from '../../talaadthai/component/revenue-display-btn';
import { messageLabel } from '../../../../components/message-component';
import Button_01 from '../../../../components/v2/button_01';
import NetAreaChart from './net-area-chart';
import { Scatter } from 'recharts';
import OCCRate from './occ-rate';
import ARR from './arr';
import OccRateArr from './occ-rate-arr';
import Revenue from './revenue';

const TabRevenueMarket = ({}) => {
  const { state, setState, func } = useContext(MarketContext);

  const {
    marketAllList,
    revenueNet,
    revenueOcc,
    revenueActual,
    revenueOccArr,
    revenueGroup,
    selectMarketNet,
    selectMarketOcc,
    selectMarketActual,
    selectMarketOccArr,
    selectMarketRevenueGroup,
    marketAllGroupList,
    selectSingle,
    marketSingleSelect,
    singleMarketname,
    loadingRevenueNet,
    loadingRevenueOcc,
    loadingRevenueActual,
    loadingRevenueOccArr,
    loadingRevenueGroup,
  } = state;
  const {
    setRevenueNetDate,
    setRevenueOccDate,
    setRevenueActualDate,
    setRevenueOccArrDate,
    setRevenueGroupDate,
    setSelectMarketNet,
    setSelectMarketOcc,
    setSelectMarketActual,
    setSelectMarketOccArr,
    setSelectMarketRevenueGroup,
    setSingleSelect,
    setFilterDate,
  } = setState;
  const intl = useIntl();
  const aligntextleft = 'left';
  const aligntextRight = 'right';
  const widthChildColumn = 105;
  const [charDataNet, setChartDataNet] = useState([]);
  const [charDataOcc, setChartDataOcc] = useState([]);
  const [charDataArea, setChartDataArea] = useState([]);
  const [charDataAreaGroup, setChartDataAreaGroup] = useState([]);
  const [selectMarket, setSelectMarket] = useState({
    occ: [],
    area: [],
    areaU: [],
  });
  const [tableLeast, setTableLeast] = useState({
    page: 1,
    size: 10,
    field: undefined,
    order: undefined,
  });

  const [checkDisplayOcc, setcheckDisplayOcc] = useState({
    bar: true,
    line: true,
  });

  const [checkDisplayArea, setcheckDisplayArea] = useState({
    gross: true,
    net: true,
    efficiency: true,
  });

  const [checkDisplayAreaU, setcheckDisplayAreaU] = useState({
    gross: true,
    net: true,
    efficiency: true,
  });

  const findMarket = (array) => {
    const map = _.map(array, (item, i) => {
      const result = _.find(marketAllList, { objectId: item });
      return result;
    });

    return map;
  };

  const columnLeast = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('MarketTaecoMonth', 'Month'),
      dataIndex: 'monthView',
      key: 'monthView',
      align: 'center',
      width: 200,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{_.get(record, 'monthView')}</div>,
    },
    {
      title: _.get(singleMarketname, '[0].name'),
      children: [
        {
          title: messageLabel('MarketTaecoGrossArea', 'Gross Area (sq.m.)'),
          dataIndex: 'market.[0].grossArea',
          key: 'market.[0].grossArea',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].grossArea')}</div>,
        },
        {
          title: messageLabel('RevenueRevenueMB', 'Revenue (MB)'),
          dataIndex: 'market.[0].netAreaA',
          key: 'market.[0].netAreaA',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].netAreaA')}</div>,
        },
        {
          title: messageLabel('RevenueRevenueMB', 'Revenue (MB)'),
          dataIndex: 'market.[0].netAreaB',
          key: 'market.[0].netAreaB',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].netAreaB')}</div>,
        },
      ],
    },
    ..._.map(findMarket(selectMarketNet), (item, i) => ({
      title: _.get(item, `name`),
      key: _.get(item, `objectId`),
      dataIndex: _.get(item, `objectId`),
      children: [
        {
          title: messageLabel('MarketTaecoGrossArea', 'Gross Area (sq.m.)'),
          dataIndex: 'market.[0].grossArea',
          key: 'market.[0].grossArea',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].grossArea')}</div>,
        },
        {
          title: messageLabel('RevenueRevenueMB', 'Revenue (MB)'),
          dataIndex: 'market.[0].netAreaA',
          key: 'market.[0].netAreaA',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].netAreaA')}</div>,
        },
        {
          title: messageLabel('RevenueRevenueMB', 'Revenue (MB)'),
          dataIndex: 'market.[0].netAreaB',
          key: 'market.[0].netAreaB',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].netAreaB')}</div>,
        },
      ],
    })),
  ];

  const columnOcc = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('MarketTaecoMonth', 'Month'),
      dataIndex: 'monthView',
      key: 'monthView',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{_.get(record, 'monthView')}</div>,
    },
    {
      title: _.get(singleMarketname, '[0].name'),
      children: [
        {
          title: messageLabel('MarketTaecoOCCRateA', 'OCC Rate (A) (%)'),
          dataIndex: 'market.[0].occRateA',
          key: 'market.[0].occRateA',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].occRateA')}</div>,
        },
        {
          title: messageLabel('MarketTaecoOCCRateB', 'OCC Rate (B) (%)'),
          dataIndex: 'market.[0].occRateB',
          key: 'market.[0].occRateB',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].occRateB')}</div>,
        },
      ],
    },
    ..._.map(findMarket(selectMarketOcc), (item, i) => ({
      title: _.get(item, `name`),
      key: _.get(item, `objectId`),
      dataIndex: _.get(item, `objectId`),
      children: [
        {
          title: messageLabel('MarketTaecoOCCRateA', 'OCC Rate (A) (%)'),
          dataIndex: 'market.[0].occRateA',
          key: 'market.[0].occRateA',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].occRateA')}</div>,
        },
        {
          title: messageLabel('MarketTaecoOCCRateB', 'OCC Rate (B) (%)'),
          dataIndex: 'market.[0].occRateB',
          key: 'market.[0].occRateB',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].occRateB')}</div>,
        },
      ],
    })),
  ];

  const columnMarketActual = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('MarketTaecoMonth', 'Month'),
      dataIndex: 'monthView',
      key: 'monthView',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{_.get(record, 'monthView')}</div>,
    },
    {
      title: _.get(singleMarketname, '[0].name'),
      children: [
        {
          title: messageLabel('MarketTaecoARRA', 'ARR (A) (THB/sq.m.)'),
          dataIndex: 'market.[0].arr',
          key: 'market.[0].arr',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].arr')}</div>,
        },
        {
          title: messageLabel('MarketTaecoAvgArr', 'Avg. ARR (A) (THB/sq.m.)'),
          dataIndex: 'market.[0].avgArr',
          key: 'market.[0].avgArr',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].avgArr')}</div>,
        },
      ],
    },
    ..._.map(findMarket(selectMarketActual), (item, i) => ({
      title: _.get(item, `name`),
      key: _.get(item, `objectId`),
      dataIndex: _.get(item, `objectId`),
      children: [
        {
          title: messageLabel('MarketTaecoARRA', 'ARR (A) (THB/sq.m.)'),
          dataIndex: 'market.[0].arr',
          key: 'market.[0].arr',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].arr')}</div>,
        },
        {
          title: messageLabel('MarketTaecoAvgArr', 'Avg. ARR (A) (THB/sq.m.)'),
          dataIndex: 'market.[0].avgArr',
          key: 'market.[0].avgArr',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].avgArr')}</div>,
        },
      ],
    })),
  ];

  const columnMarketOcAndArr = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('MarketTaecoMonth', 'Month'),
      dataIndex: 'monthView',
      key: 'monthView',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{_.get(record, 'monthView')}</div>,
    },
    {
      title: _.get(singleMarketname, '[0].name'),
      children: [
        {
          title: messageLabel('MarketTaecoOCCRateA', 'OCC Rate (A) (%)'),
          dataIndex: 'market.[0].occRate',
          key: 'market.[0].occRate',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].occRate')}</div>,
        },
        {
          title: messageLabel('MarketTaecoARRA', 'ARR (A) (THB/sq.m.)'),
          dataIndex: 'market.[0].arr',
          key: 'market.[0].arr',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].arr')}</div>,
        },
      ],
    },
    ..._.map(findMarket(selectMarketOccArr), (item, i) => ({
      title: _.get(item, `name`),
      key: _.get(item, `objectId`),
      dataIndex: _.get(item, `objectId`),
      children: [
        {
          title: messageLabel('MarketTaecoOCCRateA', 'OCC Rate (A) (%)'),
          dataIndex: 'market.[0].occRate',
          key: 'market.[0].occRate',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].occRate')}</div>,
        },
        {
          title: messageLabel('MarketTaecoARRA', 'ARR (A) (THB/sq.m.)'),
          dataIndex: 'market.[0].arr',
          key: 'market.[0].arr',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].arr')}</div>,
        },
      ],
    })),
  ];

  const columnMarketRevenue = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: messageLabel('MarketTaecoMonth', 'Month'),
      dataIndex: 'monthView',
      key: 'monthView',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      render: (text, record, index) => <div style={{ textAlign: aligntextleft }}>{_.get(record, 'monthView')}</div>,
    },
    {
      title: _.get(singleMarketname, '[0].name'),
      children: [
        {
          title: messageLabel('MarketTaecoRevA', 'Rev. (A) (MB)'),
          dataIndex: 'market.[0].revA',
          key: 'market.[0].revA',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].revA')}</div>,
        },
        {
          title: messageLabel('MarketTaecoRevB', 'Rev. (B) (MB)'),
          dataIndex: 'market.[0].revB',
          key: 'market.[0].revB',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].revB')}</div>,
        },
        {
          title: messageLabel('MarketTaecoDiff', 'Diff. Rev. (MB)'),
          dataIndex: 'market.[0].diffRev',
          key: 'market.[0].diffRev',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].diffRev')}</div>,
        },
      ],
    },
    ..._.map(findMarket(selectMarketRevenueGroup), (item, i) => ({
      title: _.get(item, `name`),
      key: _.get(item, `objectId`),
      dataIndex: _.get(item, `objectId`),
      children: [
        {
          title: messageLabel('MarketTaecoRevA', 'Rev. (A) (MB)'),
          dataIndex: 'market.[0].revA',
          key: 'market.[0].revA',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].revA')}</div>,
        },
        {
          title: messageLabel('MarketTaecoRevB', 'Rev. (B) (MB)'),
          dataIndex: 'market.[0].revB',
          key: 'market.[0].revB',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].revB')}</div>,
        },
        {
          title: messageLabel('MarketTaecoDiff', 'Diff. Rev. (MB)'),
          dataIndex: 'market.[0].diffRev',
          key: 'market.[0].diffRev',
          align: 'center',
          width: widthChildColumn,
          render: (text, record, index) => <div style={{ textAlign: aligntextRight }}>{_.get(record, 'market.[0].diffRev')}</div>,
        },
      ],
    })),
  ];

  const displayOcc = [
    {
      key: 'bar',
      title: 'OCC Rate',
      check: true,
    },
    {
      key: 'line',
      title: 'ARR',
      check: true,
    },
  ];

  const displayArea = [
    {
      key: 'gross',
      title: 'Gross Area',
      check: true,
    },
    {
      key: 'net',
      title: 'Net Area',
      check: true,
    },
    {
      key: 'efficiency',
      title: 'Efficiency',
      check: true,
    },
  ];

  const changeToNumber = (value) => {
    const number = _.chain(value)
      .replace(/,/g, '')
      .toNumber()
      .value();
    return number;
  };

  useEffect(() => {
    const mapDataNet = async () => {
      const mockData = _.map(revenueNet, (i) => {
        const s = [
          {
            name: 'ตลาดสด ชั้น1',
            grossArea: '0.00',
            netAreaA: '100.00',
            netAreaB: '50.00',
          },
          {
            name: 'ตลาดสด ชั้น2',
            grossArea: '0.00',
            netAreaA: '200.00',
            netAreaB: '100.00',
          },
          {
            name: 'ตลาดสด ชั้น3',
            grossArea: '0.00',
            netAreaA: '300.00',
            netAreaB: '200.00',
          },
          {
            name: 'ตลาดสด ชั้น4',
            grossArea: '0.00',
            netAreaA: '400.00',
            netAreaB: '250.00',
          },
        ];
        return { ...i, market: s };
      });

      // let mapData = _.map(mockData, (item, index) => {

      //   return {
      //     name: item.monthView,
      //     ..._.reduce(item.market, (acc, marketItem) => {
      //       acc["name"]= item.monthView;
      //       acc[`Net Area (A) - ${marketItem.name}`] = changeToNumber(marketItem.netAreaA);
      //       return acc;
      //     }, {}),
      //     ..._.reduce(item.market, (acc, marketItem) => {
      //       acc[`Net Area (B) - ${marketItem.name}`] = changeToNumber(marketItem.netAreaB);
      //       return acc;
      //     }, {}) ,
      //   };
      // });

      let mapData = _.map(mockData, (item, index) => {
        return {
          name: item.monthView,
          ..._.reduce(
            item.market,
            (acc, marketItem) => {
              acc['name'] = item.monthView;
              acc[`Net Area (A) - ${marketItem.name}`] = changeToNumber(marketItem.netAreaA);
              return acc;
            },
            {}
          ),
          ..._.reduce(item.market, (acc, marketItem) => {
            acc[`Net Area (B) - ${marketItem.name}`] = changeToNumber(marketItem.netAreaB);
            return acc;
          }, {}) ,
        };
      });

      // let mapDataSactter = _.map(mockData, (item, index) =>
      //   _.reduce(
      //     item.market,
      //     (acc, marketItem) => {
      //       acc[`Net Area (B) - ${marketItem.name}`] = changeToNumber(marketItem.netAreaB);
      //       return acc;
      //     },
      //     {}
      //   )
      // );

      // let mapScatter = {
      //   bar: mapData,
      //   scatter: mapDataSactter,
      // };

      console.log('ChartDataAAAA', mapData);

      setChartDataNet(mapData);
    };

    if (_.size(revenueNet) > 0) {
      mapDataNet();
    }
  }, [revenueNet]);

  const buttonExport = (
    <Button_01 type="primary">
      <FormattedMessage id="btnExport" defaultMessage="Export" />
    </Button_01>
  );

  const buttonDisplayOcc = <DisplayBtn displayData={displayOcc} checkDisplay={checkDisplayOcc} setcheckDisplayOcc={setcheckDisplayOcc} />;

  const buttonDisplayArea = (
    <DisplayBtn displayData={displayArea} checkDisplay={checkDisplayArea} setcheckDisplayOcc={setcheckDisplayArea} />
  );

  const buttonDisplayAreaU = (
    <DisplayBtn displayData={displayArea} checkDisplay={checkDisplayAreaU} setcheckDisplayOcc={setcheckDisplayAreaU} />
  );

  const dividerRevenue = (
    <div style={{ padding: '24px 24px 0px' }}>
      <Divider style={{ marginBottom: '0px' }} />
    </div>
  );

  return (
    <div style={{ backgroundColor: '#FFFF' }}>
      {/* TalaadThai Revenue */}
      <Spin spinning={loadingRevenueNet}>
      <div>
        <FilterOnTopTab
          title={intl.formatMessage({ id: 'MarketTaecoNetArea', defaultMessage: 'Net Area' })}
          button={buttonDisplayOcc}
          mutiSelect={true}
          sizeSelect={3}
          marketAllList={marketSingleSelect}
          selectMarket={selectMarketNet}
          setSelectMarket={setSelectMarketNet}
          setDate={setRevenueNetDate}
          date={true}
          selectSingle={selectSingle}
        />
      </div>
      <div style={{ marginTop: '24px' }}>
        <NetAreaChart chartData={charDataNet} />
      </div>
      <div>
        <FilterOnTopTab button={buttonExport} onlyButton={true} />
      </div>
      <div>
        <TableRevenueLeastArea columns={columnLeast} data={revenueNet} total={_.size(revenueNet)} paginationShow={true} />
      </div>
      </Spin>
      {/*------------------------------------------------------------ */}
      {dividerRevenue}
      <Spin spinning={loadingRevenueOcc}>
      <div>
        <FilterOnTopTab
          title={intl.formatMessage({ id: 'MarketTaecoOCC', defaultMessage: 'OCC Rate' })}
          button={buttonDisplayOcc}
          mutiSelect={true}
          sizeSelect={3}
          marketAllList={marketSingleSelect}
          selectMarket={selectMarketOcc}
          setSelectMarket={setSelectMarketOcc}
          setDate={setRevenueOccDate}
          date={true}
          selectSingle={selectSingle}
        />
      </div>
      <div style={{ marginTop: '24px' }}>
        <OCCRate/>
      </div>
      <div>
        <FilterOnTopTab button={buttonExport} onlyButton={true} />
      </div>
      <div>
        <TableRevenueLeastArea
          columns={columnOcc}
          data={revenueOcc}
          total={_.size(revenueOcc)}
          paginationPage={_.get(tableLeast, 'page')}
          paginationShow={true}
          paginationSize={_.get(tableLeast, 'size')}
        />
      </div>
      </Spin>
      {/*------------------------------------------------------------ */}
      {dividerRevenue}
      <Spin spinning={loadingRevenueActual}>
      <div>
        <FilterOnTopTab
          title={intl.formatMessage({ id: 'MarketTaecoARR', defaultMessage: 'ARR (Actual)' })}
          button={buttonDisplayArea}
          mutiSelect={true}
          sizeSelect={3}
          marketAllList={marketAllList}
          selectMarket={selectMarketActual}
          setSelectMarket={setSelectMarketActual}
          setDate={setRevenueActualDate}
          date={true}
          selectSingle={selectSingle}
        />
      </div>
      <div style={{ marginTop: '24px' }}>
        <ARR/>
      </div>
      <div>
        <FilterOnTopTab button={buttonExport} onlyButton={true} />
      </div>
      <div>
        <TableRevenueLeastArea columns={columnMarketActual} data={revenueActual} total={_.size(revenueActual)} paginationShow={true} />
      </div>
      </Spin>
      {/*------------------------------------------------------------ */}
      {dividerRevenue}
      <Spin spinning={loadingRevenueOccArr}>
      <div>
        <FilterOnTopTab
          title={intl.formatMessage({ id: 'MarketTaecoOccArr', defaultMessage: 'OCC Rate and ARR' })}
          button={buttonDisplayAreaU}
          mutiSelect={true}
          sizeSelect={3}
          marketAllList={marketAllList}
          selectMarket={selectMarketOccArr}
          setSelectMarket={setSelectMarketOccArr}
          setDate={setRevenueOccArrDate}
          date={true}
          selectSingle={selectSingle}
        />
      </div>
      <div style={{ marginTop: '24px' }}>
        <OccRateArr/>
      </div>
      <div>
        <FilterOnTopTab button={buttonExport} onlyButton={true} />
      </div>
      <div>
        <TableRevenueLeastArea columns={columnMarketOcAndArr} data={revenueOccArr} total={_.size(revenueOccArr)} paginationShow={true} />
      </div>
      </Spin>
      {/*------------------------------------------------------------ */}
      {dividerRevenue}
      <Spin spinning={loadingRevenueOccArr}>
      <div>
        <FilterOnTopTab
          title={intl.formatMessage({ id: 'MarketTaecoRevenue', defaultMessage: 'Revenue' })}
          button={buttonDisplayAreaU}
          mutiSelect={true}
          sizeSelect={3}
          marketAllList={marketAllList}
          selectMarket={selectMarketRevenueGroup}
          setSelectMarket={setSelectMarketRevenueGroup}
          setDate={setRevenueGroupDate}
          date={true}
          selectSingle={selectSingle}
        />
      </div>
      <div style={{ marginTop: '24px' }}>
        <Revenue/>
      </div>
      <div>
        <FilterOnTopTab button={buttonExport} onlyButton={true} />
      </div>
      <div>
        <TableRevenueLeastArea columns={columnMarketRevenue} data={revenueGroup} total={_.size(revenueGroup)} paginationShow={true} />
      </div>
      </Spin>
      <div style={{ marginTop: '24px' }}></div>
    </div>
  );
};

export default TabRevenueMarket;
