import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Form, Input, Modal, Row, Select, DatePicker } from 'antd';

import _ from 'lodash';

import LabeRequireForm from '../../../../components/label-required-form';
// import ModalProjectEditMemeber from './component/modal-edit';
import TableProjectDetail from '../component/table';
import CollapseCustom from '../../../../components/collapse-custom';
import { getPricingList } from '../../../../controllers/project-new/project-detail';
import Button_02 from '../../../../components/v2/button_02';
import Button_01 from '../../../../components/v2/button_01';
import ActionColumn from './component/action-column';

const ProjectPricingDetail = (props) => {
  const { RangePicker } = DatePicker;
  const {
    handleOpenViewPricing,
    pricingList,
    searchPricingValue,
    setSearchPricingValue,
    handleSearchPricing,
    handleSearchPricinSO,
    handleSubmitSearchPricing,
    handleResetSearchPricing,
    pricingListTotal,
    handleCopyPricing
  } = props;

  const intl = useIntl();

  const [selectedColumns, setSelectedColumns] = useState({
    column1: true,
    column2: true,
    column3: true,
    column4: true,
    column5: true,
  });

  const [activeColumns, setActiveColumns] = useState(selectedColumns);

  const handleColumnChange = (column, checked) => {
    setSelectedColumns((prev) => ({ ...prev, [column]: checked }));
  };

  const handleResetColumns = () => {
    const resetState = {
      column1: true,
      column2: true,
      column3: true,
      column4: true,
      column5: true,
    };
    setSelectedColumns(resetState);
    setActiveColumns(resetState);
  };

  const handleSubmitColumns = () => {
    setActiveColumns(selectedColumns);
  };

  const checkboxItems = [
    {
      label: 'Customer',
      checked: selectedColumns.column1,
      onChange: (checked) => handleColumnChange('column1', checked),
    },
    {
      label: 'Contact',
      checked: selectedColumns.column2,
      onChange: (checked) => handleColumnChange('column2', checked),
    },
    {
      label: 'Contact Phone',
      checked: selectedColumns.column3,
      onChange: (checked) => handleColumnChange('column3', checked),
    },
    {
      label: 'Update By',
      checked: selectedColumns.column4,
      onChange: (checked) => handleColumnChange('column4', checked),
    },
    {
      label: 'Update Date',
      checked: selectedColumns.column5,
      onChange: (checked) => handleColumnChange('column5', checked),
    },
  ];

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      default: true,
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'ProjectPricingSoNoCol', defaultMessage: 'SO No.' }),
      dataIndex: 'referenceNo',
      key: 'referenceNo',
      sorter: true,
      default: true,
      width: 150,
    },
    activeColumns.column1 && {
      title: intl.formatMessage({ id: 'ProjectPricingCustomerCol', defaultMessage: 'Customer' }),
      dataIndex: 'businessPartnerName',
      key: 'businessPartnerName',
      sorter: true,
      default: false,
      width: 150,
    },
    activeColumns.column2 && {
      title: intl.formatMessage({ id: 'ProjectPricingContactCol', defaultMessage: 'Contact' }),
      dataIndex: 'contactName',
      key: 'contactName',
      sorter: true,
      default: true,
      width: 150,
    },
    activeColumns.column3 && {
      title: intl.formatMessage({ id: 'ProjectPricingContactPhoneCol', defaultMessage: 'Contact Phone' }),
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      sorter: true,
      default: true,
      width: 150,
      ellipsis: true,
    },
    activeColumns.column4 && {
      title: intl.formatMessage({ id: 'ProjectPricingUpdateByCol', defaultMessage: 'Update By' }),
      dataIndex: 'updatedByName',
      key: 'updatedByName',
      sorter: true,
      default: true,
      width: 150,
      ellipsis: true,
    },
    activeColumns.column5 && {
      title: intl.formatMessage({ id: 'ProjectPricingUpdateDateCol', defaultMessage: 'Update Date' }),
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      sorter: true,
      default: false,
      width: 150,
    },
  ].filter(Boolean);

  const advancedFilter = () => {
    return (
      <Form colon={false}>
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ProjectPricingCustomerFilter', defaultMessage: 'Customer' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchPricingValue, 'customerName')}
                onChange={(e) => handleSearchPricing('customerName', e.target.value)}
                placeholder={intl.formatMessage({ id: 'ProjectPricingCustomerPHFilter', defaultMessage: 'Enter Customer' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'ProjectPricingContactFilter', defaultMessage: 'Contact' })} req={false} />
              }
            >
              <Input
                allowClear
                value={_.get(searchPricingValue, 'contactName')}
                onChange={(e) => handleSearchPricing('contactName', e.target.value)}
                placeholder={intl.formatMessage({ id: 'ProjectPricingContactPHFilter', defaultMessage: 'Enter Contact' })}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ProjectPricingContactPhoneFilter', defaultMessage: 'Contact Phone' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchPricingValue, 'contactPhone')}
                onChange={(e) => handleSearchPricing('contactPhone', e.target.value)}
                placeholder={intl.formatMessage({ id: 'ProjectPricingContactPhonePHFilter', defaultMessage: 'Enter Contact Phone' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ProjectPricingUpdateByFilter', defaultMessage: 'Update By' })}
                  req={false}
                />
              }
            >
              <Input
                allowClear
                value={_.get(searchPricingValue, 'updatedByName')}
                onChange={(e) => handleSearchPricing('updatedByName', e.target.value)}
                placeholder={intl.formatMessage({ id: 'ProjectPricingUpdateByPHFilter', defaultMessage: 'Enter Update By' })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'ProjectPricingUpdateDateFilter', defaultMessage: 'Update Date' })}
                  req={false}
                />
              }
            >
              <RangePicker
                style={{ width: '100%' }}
                placeholder={
                  intl.formatMessage({ id: 'ProjectPricingUpdateFromDatePHFilter', defaultMessage: 'Select From Date' }) +
                  ' - ' +
                  intl.formatMessage({ id: 'ProjectPricingUpdateToDatePHFilter', defaultMessage: 'Select To Date' })
                }
                format="DD/MM/YYYY"
                value={_.get(searchPricingValue, 'updatedDate')}
                onChange={(e) => handleSearchPricing('updatedDate', e)}
                allowClear={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 2]}>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right', marginTop: '20px' }}>
            <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleResetSearchPricing()}>
              <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
            </Button_02>

            <Button_01
              style={{ margin: '0px 0px 0px 10px' }}
              key="print"
              type="primary"
              btnsize="wd_df"
              onClick={() => handleSubmitSearchPricing()}
            >
              <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
            </Button_01>
          </Col>
        </Row>
      </Form>
    );
  };

  const contentAction = (record) => {
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenViewPricing(record)}>
            <FormattedMessage id="btnView" defaultMessage="View" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleCopyPricing(record)}>
            <FormattedMessage id="btnCopy" defaultMessage="Copy" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="warehouse-layout-content warehouse-tabs-details scroll-sm">
      <div style={{ paddingTop: 20 }}>
        <CollapseCustom
          label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
          extra={
            <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <LabeRequireForm text={intl.formatMessage({ id: 'ProjectPricingSoNo', defaultMessage: 'SO No. :' })} req={false} />
              <Input
                style={{ marginLeft: '10px', width: '50%' }}
                allowClear
                placeholder={intl.formatMessage({ id: 'ProjectPricingSoNoPH', defaultMessage: 'Enter SO No.' })}
                onChange={(e) => handleSearchPricinSO('saleOrderNo', e.target.value )}
              />
              <ActionColumn checkboxItems={checkboxItems} onReset={handleResetColumns} onSubmit={handleSubmitColumns} />
            </div>
          }
        >
          {advancedFilter()}
        </CollapseCustom>
      </div>
      <TableProjectDetail
        columns={columns}
        listData={pricingList}
        listTotal={_.get(pricingListTotal, "total")}
        contentAction={contentAction}
        onRow={true}
        searchMemberValue={searchPricingValue}
        setSearchMemberValue={setSearchPricingValue}
      />
    </div>
  );
};

export default ProjectPricingDetail;
