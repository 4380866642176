import React, { useState, useEffect, useContext } from 'react';
import { Card, Tabs, Tag, Modal, Button, Dropdown, Menu } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { StickyContainer } from 'react-sticky';
import Button01 from '../../components/v2/button_01';
import './index.css';
// import PurchaseCreate from './modal/create/index';
// import EditPurchase from './modal/edit/index';
// import ViewPurchase from './modal/view/index';
import { CreditNoteContext } from './credit-note-context';
import getCustomerVenderApi from '../../controllers/getCustomer/get-customer-vender';
import _ from 'lodash';
import { updateStatusPo, getTaxList, getDeliveryType, getVehicleList, getMemberIssueBy } from '../../controllers/po/po';
import { successNotification, errorNotification } from '../../components/v2/notification';
import { PageSettings } from '../../config/page-settings';
import moment from 'moment';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';
import { getTeamOrg } from '../../controllers/team/team';
import { getConfigMenu } from '../../controllers/get-config-menu/get-config-menu-api';
import CreditNoteTab from './credit-note-tab';
import CreditNoteForm from './modal/form';
import { exportCreditNote, getConfigMenuCn, getRemarkCn, searchCreditNote } from '../../controllers/credit-note/credit-note';
import { DownOutlined } from '@ant-design/icons';
import CreditNoteView from './modal/view';
import { render } from 'less';

const { TabPane } = Tabs;

const CreditNote = () => {
  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
      align: 'center',
      width: 70,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsCNNo" defaultMessage="CN No." />,
      dataIndex: 'cnNo',
      key: 'cnNo',
      fixed: 'left',
      width: 180,
      sorter: true,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsINVNo" defaultMessage="INV No." />,
      dataIndex: 'invNo',
      key: 'invNo',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsINVDate" defaultMessage="INV Date" />,
      dataIndex: 'invDate',
      key: 'invDate',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsCustomerCode" defaultMessage="Customer Code" />,
      dataIndex: 'customerCode',
      key: 'customerCode',
      sorter: true,
      width: 200,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsCustomer" defaultMessage="Customer" />,
      dataIndex: 'customer',
      key: 'customer',
      sorter: true,
      width: 250,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsItem" defaultMessage="Item" />,
      dataIndex: 'item',
      key: 'item',
      sorter: true,
      align: 'right',
      width: 180,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsTotal" defaultMessage="Total" />,
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      sorter: true,
      width: 180,
      render: (text, record, index) => numberFormatter(_.get(record, 'total')) + ' THB',
    },

    {
      title: <FormattedMessage id="creditNoteColumnsblNo" defaultMessage="BL No." />,
      dataIndex: 'blNo',
      key: 'blNo',
      sorter: true,
      width: 180,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsblDate" defaultMessage="BL Date" />,
      dataIndex: 'blDate',
      key: 'blDate',
      sorter: true,
      width: 180,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsTeam" defaultMessage="Team" />,
      dataIndex: 'team',
      key: 'team',
      sorter: true,
      width: 300,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsIssuedBy" defaultMessage="Issued by" />,
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: true,
      width: 150,
    },
    {
      title: <FormattedMessage id="creditNoteColumnsIssuedDate" defaultMessage="Issued Date" />,
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      width: 150,
    },
  ];

  const setShowColumn = {
    index: true,
    cnNo: true,
    invNo: true,
    invDate: true,
    customerCode: true,
    customer: true,
    item: true,
    total: true,
    blNo: true,
    blDate: true,
    team: false,
    createdBy: true,
    createdDate: true,
  };

  const setShowColumnArr = [
    'index',
    'cnNo',
    'invNo',
    'invDate',
    'customerCode',
    'customer',
    'item',
    'total',
    'blNo',
    'blDate',
    'team',
    'createdBy',
    'createdDate',
  ];

  const { checkPermissionAction } = useContext(PageSettings);

  const ignoreColumn = ['team'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });

  const [keyTabMonitor, setKeyTabMonitor] = useState('draft');
  const [tabAllMenu, setTabAllMenu] = useState({});
  const [nextStatus, setNextStatus] = useState(['wating', 'deleted']);
  const [tab, setTab] = useState([]);

  const [searchGroup, setSearchGroup] = useState({ searchDate: [moment().startOf('month'), moment().endOf('month')] });
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);
  const [creditNoteList, setCreditNoteList] = useState([]);
  const [total, setTotal] = useState(0);
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [trigger, setTrigger] = useState(false);

  const [optionsTeam, setOptionsTeam] = useState([]);
  const [optionsIssueBy, setOptionsIssueBy] = useState([]);
  const [optionsVat, setOptionsVat] = useState([]);
  const [optionsRemark, setOptionsRemark] = useState([]);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');

  const [defaultData, setDefaultData] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleView, setVisibleView] = useState(false);

  const [loading, setLoading] = useState(false);

  console.log('responseMenu', tabAllMenu, nextStatus, tab, _.includes(nextStatus, 'torevise'));

  useEffect(() => {
    const getDataVender = async () => {
      const bodyMenuCn = {};
      const responseMenu = await getConfigMenuCn(bodyMenuCn);

      const getTeam = await getTeamOrg();
      const getIssueBy = await getMemberIssueBy({});
      const getRemark = await getRemarkCn();
      const getVat = await getTaxList();

      console.log('response', getRemark);

      const tempVat = _.map(_.get(getVat, 'data.data'), (i) => {
        if (i.taxPercent === -7) {
          return {
            ...i,
            taxPercentTxt: <FormattedMessage id={`IncludeVAT`} defaultMessage="In VAT" />,
          };
        } else {
          return { ...i };
        }
      });

      setTab(_.get(responseMenu, 'statusMap'));
      setTabAllMenu(_.get(responseMenu, 'config_val'));

      setOptionsTeam(_.get(getTeam, 'data'));
      setOptionsIssueBy(getIssueBy || []);
      setOptionsVat(tempVat);
      setOptionsRemark(getRemark);
    };
    getDataVender();
  }, []);

  console.log('searchGroup', searchGroup);

  useEffect(() => {
    const SearchData = async () => {
      setLoading(true);
      const body = {
        page: paginationPage,
        limit: paginationSize,
        orderBy: fieldSort,
        order: orderSort,
        statusCode: keyTabMonitor,

        searchDateFrom: _.get(searchGroup, 'searchDate[0]') ? moment(_.get(searchGroup, 'searchDate[0]')).format('YYYY-MM-DD') : undefined,
        searchDateTo: _.get(searchGroup, 'searchDate[1]') ? moment(_.get(searchGroup, 'searchDate[1]')).format('YYYY-MM-DD') : undefined,
        creditNoteNo: _.get(searchGroup, 'cnNo') || undefined,
        customer: _.get(searchGroup, 'customer') || undefined,
        invoiceNo: _.get(searchGroup, 'invNo') || undefined,
        invDateFrom: _.get(searchGroup, 'invDate[0]') ? moment(_.get(searchGroup, 'invDate[0]')).format('YYYY-MM-DD') : undefined,
        invDateTo: _.get(searchGroup, 'invDate[1]') ? moment(_.get(searchGroup, 'invDate[1]')).format('YYYY-MM-DD') : undefined,
        blNo: _.get(searchGroup, 'blNo') || undefined,
        billingDateFrom: _.get(searchGroup, 'blDate[0]') ? moment(_.get(searchGroup, 'blDate[0]')).format('YYYY-MM-DD') : undefined,
        billingDateTo: _.get(searchGroup, 'blDate[1]') ? moment(_.get(searchGroup, 'blDate[1]')).format('YYYY-MM-DD') : undefined,

        team: _.get(searchGroup, 'team') ? [`${_.get(searchGroup, 'team')}`] : undefined,
        issuedBy: _.get(searchGroup, 'issuedBy') || undefined,
      };
      const response = await searchCreditNote(body);

      setCreditNoteList(_.get(response, 'data.data.cnList'));
      setTotal(_.get(response, 'data.data.totalItem'));
      setLoading(false);
    };
    SearchData();
  }, [fieldSort, orderSort, keyTabMonitor, paginationPage, paginationSize, trigger]);

  useEffect(() => {
    if (tabAllMenu && keyTabMonitor) setNextStatus(tabAllMenu[keyTabMonitor]);

    if (keyTabMonitor === 'draft') {
      const ignor = ['cnNo', 'blNo', 'blDate'];
      const filterIgnor = _.filter(newColumns, (column) => !_.includes(ignor, column.dataIndex));

      const fixedLeft = _.map(filterIgnor, (columns) => (columns.dataIndex === 'invNo' ? { ...columns, fixed: 'left' } : columns));

      setNewDataColumns(fixedLeft);
      setListArrayColumns(setShowColumnArr);
      setDefaultShowColumn(setShowColumn);
    } else if (keyTabMonitor === 'approved') {
      setNewDataColumns(newColumns);
      setListArrayColumns([...setShowColumnArr]);
      setDefaultShowColumn({ ...setShowColumn });
    } else {
      const ignor = ['blNo', 'blDate'];
      const filterIgnor = _.filter(newColumns, (column) => !_.includes(ignor, column.dataIndex));

      setNewDataColumns(filterIgnor);
      setListArrayColumns([...setShowColumnArr]);
      setDefaultShowColumn({ ...setShowColumn });
    }
  }, [keyTabMonitor, tabAllMenu]);

  const callback = (key) => {
    setKeyTabMonitor(key);
    setCreditNoteList([]);
    setPaginationPage(1);
    setSearchGroup({ searchDate: [moment().startOf('month'), moment().endOf('month')] });
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort();
    }
    setFieldSort(field);
    setPaginationSize(pagination.pageSize);
    setPaginationPage(pagination.current);
  };

  const handleOpenModal = (data, action) => {
    if (action === 'create') {
      setDefaultData({ action: action });
      setVisibleForm(true);
    }
    if (action === 'edit') {
      setDefaultData({ ...data, action: action });
      setVisibleForm(true);
    }
    if (action === 'view') {
      setDefaultData({ ...data, action: action });
      setVisibleView(true);
    }
  };

  const actionSaveToStatus = (typeNext, id) => {
    /* 
      action -> view ,edit
      typeNext -> เปลี่ยนตามแถบ
    */

    setVisibleView(false);
    setVisibleForm(false);
    setKeyTabMonitor(typeNext);


    setTimeout(() => {
      setDefaultData({ cnId: id, action: 'view' }); // รับ response -> ต้องใช้ cnId
      setVisibleView(true);
      setPaginationPage(1)
    }, 500);
  };

  const handleExport = async () => {
    setLoading(true);

    let ignor = [];

    if (keyTabMonitor === 'draft') {
      ignor = ['creditNoteNo', 'invoiceHeader.billingNo', 'invoiceHeader.billingDate'];
    } else if (keyTabMonitor === 'approved') {
      ignor = [];
    } else {
      ignor = ['invoiceHeader.billingNo', 'invoiceHeader.billingDate'];
    }

    const viewFormat = [
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsCNNo', defaultMessage: 'CN No.' }),
        sequence: 1,
        colCode: 'creditNoteNo',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsINVNo', defaultMessage: 'INV No.' }),
        sequence: 2,
        colCode: 'invoiceHeader.invoiceNo',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsINVDate', defaultMessage: 'INV Date' }),
        sequence: 3,
        colCode: 'invoiceHeader.issueDetail.issueDate',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsCustomerCode', defaultMessage: 'Customer Code' }),
        sequence: 4,
        colCode: 'invoiceHeader.saleOrderDetail.customerCode',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsCustomer', defaultMessage: 'Customer' }),
        sequence: 5,
        colCode: 'invoiceHeader.customerDetail.customerName',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsItem', defaultMessage: 'Item' }),
        sequence: 6,
        colCode: 'invoiceHeader.saleOrderDetail.totalItem',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsTotal', defaultMessage: 'Total' }),
        sequence: 7,
        colCode: 'invoiceHeader.total',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsblNo', defaultMessage: 'BL No.' }),
        sequence: 8,
        colCode: 'invoiceHeader.billingNo',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsblDate', defaultMessage: 'BL Date' }),
        sequence: 9,
        colCode: 'invoiceHeader.billingDate',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsTeam', defaultMessage: 'Team' }),
        sequence: 10,
        colCode: 'teamList',
      },
      {
        colName: intl.formatMessage({ id: 'creditNoteColumnsIssuedBy', defaultMessage: 'Issued by' }),
        sequence: 11,
        colCode: 'issueDetail.issueByName',
      },
      {
        colName: intl.formatMessage({
          id: 'creditNoteColumnsIssuedDate',
          defaultMessage: 'Issued Date',
        }),
        sequence: 12,
        colCode: 'issueDetail.issueDate',
      },
    ];

    const filterIgnor = _.filter(viewFormat, (column) => !_.includes(ignor, column.colCode));
    const mapViewFormat = _.map(filterIgnor, (col, index) => {
      return {
        ...col,
        sequence: index + 1,
      };
    });

    const body = {
      page: paginationPage,
      limit: paginationSize,
      orderBy: fieldSort,
      order: orderSort,
      statusCode: keyTabMonitor,

      searchDateFrom: _.get(searchGroup, 'searchDate[0]') ? moment(_.get(searchGroup, 'searchDate[0]')).format('YYYY-MM-DD') : undefined,
      searchDateTo: _.get(searchGroup, 'searchDate[1]') ? moment(_.get(searchGroup, 'searchDate[1]')).format('YYYY-MM-DD') : undefined,
      creditNoteNo: _.get(searchGroup, 'cnNo') || undefined,
      customer: _.get(searchGroup, 'customer') || undefined,
      invoiceNo: _.get(searchGroup, 'invNo') || undefined,
      invDateFrom: _.get(searchGroup, 'invDate[0]') ? moment(_.get(searchGroup, 'invDate[0]')).format('YYYY-MM-DD') : undefined,
      invDateTo: _.get(searchGroup, 'invDate[1]') ? moment(_.get(searchGroup, 'invDate[1]')).format('YYYY-MM-DD') : undefined,
      billingNo: _.get(searchGroup, 'billingNo') || undefined,
      billingDateFrom: _.get(searchGroup, 'blDate[0]') ? moment(_.get(searchGroup, 'blDate[0]')).format('YYYY-MM-DD') : undefined,
      billingDateTo: _.get(searchGroup, 'blDate[1]') ? moment(_.get(searchGroup, 'blDate[1]')).format('YYYY-MM-DD') : undefined,

      team: _.get(searchGroup, 'team') ? [`${_.get(searchGroup, 'team')}`] : undefined,
      issuedBy: _.get(searchGroup, 'issuedBy') || undefined,
      statusExport: true,
      viewFormat: mapViewFormat,
    };
    const response = await exportCreditNote(body);
    console.log('handleExport', response, body);

    if (response.status.code === 200) {
      window.open(response.data.url);
      successNotification(response.status.message);
    } else {
      errorNotification(response.status.message);
    }

    setLoading(false);
  };

  const handleEditModal = () => {
    setDefaultData((prev) => ({ ...prev, action: 'edit' }));
    setVisibleView(false);
    setVisibleForm(true);
  };

  const menuColumn = () => {
    return (
      <Menu>
        {checkPermissionAction('P87PG1C1', 'P87PG1C1A2') ? (
          <Menu.Item key="1">
            <Button key="1" ghost type="link" onClick={() => handleOpenModal({}, 'create')}>
              <FormattedMessage id="creditNoteLabelCreateCreditNote" defaultMessage="Create Credit Note" />
            </Button>
          </Menu.Item>
        ) : null}

        <Menu.Item key="2">
          <Button key="2" ghost type="link" onClick={() => handleExport()}>
            <FormattedMessage id="creditNoteLabelExportExcel" defaultMessage="Export Excel" />
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) =>
        checkPermissionAction('P87PG1', 'P87PG1A1') ? (
          <CreditNoteContext.Provider
            value={{
              searchGroup,
              setSearchGroup,
              setPaginationPage,
              columns,
              setShowColumn,
              newDataColumns,
              listArrayColumns,
              defaultShowColumn,
              newColumns,
              setNewDataColumns,
              setListArrayColumns,
              setDefaultShowColumn,
              textErrorSelectColumn,
              setTextErrorSelectColumn,
              creditNoteList,
              handleChange,
              paginationPage,
              total,
              handleOpenModal,
              loading,
              keyTabMonitor,
              visible,
              setVisible,
              setTrigger,
              optionsTeam,
              optionsIssueBy,
              tab,
            }}
          >
            {checkPermissionAction('P87PG1C1', 'P87PG1C1A1') ? (
              <Card bodyStyle={{ padding: 'unset', height: 'calc(100vh - 110px)' }}>
                <StickyContainer>
                  {_.size(tab) > 0 ? (
                    <Tabs
                      className="po-tabs"
                      size="large"
                      defaultActiveKey="1"
                      onChange={callback}
                      activeKey={keyTabMonitor}
                      animated={false}
                      tabBarExtraContent={
                        // checkPermissionAction('P87PG1C1', 'P87PG1C1A1') ? (
                        <div className="po-extra-tabs">
                          <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
                            <Button01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                              <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
                              <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                            </Button01>
                          </Dropdown>
                        </div>
                        // ) : null
                      }
                    >
                      {_.map(tab, (item) => (
                        <TabPane
                          tab={intl.formatMessage({ id: `creditNoteTitleTab${item.code}`, defaultMessage: item.code })}
                          key={item.code}
                        >
                          <CreditNoteTab />
                        </TabPane>
                      ))}
                    </Tabs>
                  ) : null}
                </StickyContainer>

                <CreditNoteForm
                  visible={visibleForm}
                  setVisible={setVisibleForm}
                  setTrigger={setTrigger}
                  defaultData={defaultData}
                  initial={{
                    optionsTeam,
                    optionsIssueBy,
                    optionsVat,
                    optionsRemark,
                  }}
                  nextStatus={nextStatus}
                  actionSaveToStatus={actionSaveToStatus}
                  keyTabMonitor={keyTabMonitor}
                />

                <CreditNoteView
                  visible={visibleView}
                  setVisible={setVisibleView}
                  setTrigger={setTrigger}
                  defaultData={defaultData}
                  initial={{
                    optionsTeam,
                    optionsIssueBy,
                    optionsVat,
                    optionsRemark,
                  }}
                  keyTabMonitor={keyTabMonitor}
                  nextStatus={nextStatus}
                  actionSaveToStatus={actionSaveToStatus}
                  handleEdit={handleEditModal}
                />
              </Card>
            ) : null}
          </CreditNoteContext.Provider>
        ) : null
      }
    </PageSettings.Consumer>
  );
};

export default CreditNote;
