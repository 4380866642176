import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useAppContext } from '../../../includes/indexProvider';
import { Card, Table, Button, notification, List, Icon } from 'antd';
import { debounce } from 'lodash';
import { OverTimeContext } from '../report-context';
import ActionOvertimeReport from './action-over-time-report';
import httpClient from '../../../components/axiosClient';
import _ from 'lodash';
import moment from 'moment';
import IconExcel from '../../../components/image/excel.svg';
import styles from './css/over-time-report.css';
import cssStyleCenter from '../report-center.css';
import { PageSettings } from '../../../config/page-settings';
import { Link } from 'react-router-dom';
import Button_01 from '../../../components/v2/button_01';

export default () => {
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  const { state, setState, fnc } = useContext(OverTimeContext);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnRequestNo', defaultMessage: 'Request No.' }),
      dataIndex: 'no',
      key: 'no',
      width: 180,
      sorter: (a, b) => a.no - b.no,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnRequestDate', defaultMessage: 'Request Date' }),
      dataIndex: 'requestDate',
      key: 'requestDate',
      width: 180,
      sorter: (a, b) => a.requestDate - b.requestDate,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnName', defaultMessage: 'Name' }),
      dataIndex: 'requesterName',
      key: 'requesterName',
      width: 250,
      sorter: (a, b) => a.requesterName - b.requesterName,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnFromDate', defaultMessage: 'From (Date)' }),
      dataIndex: 'fromDate',
      key: 'fromDate',
      sorter: (a, b) => a.fromDate - b.fromDate,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnUntilDate', defaultMessage: 'To (Date)' }),
      dataIndex: 'utilDate',
      key: 'utilDate',
      sorter: (a, b) => a.utilDate - b.utilDate,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnTotalHours', defaultMessage: 'Total Hours' }),
      dataIndex: 'totalHours',
      key: 'totalHours',
      sorter: (a, b) => a.totalHours - b.totalHours,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnStatus', defaultMessage: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnTopic', defaultMessage: 'Topic' }),
      dataIndex: 'topic',
      key: 'topic',
      sorter: (a, b) => a.topic - b.topic,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnAttachment', defaultMessage: 'Attachment' }),
      dataIndex: 'attachment',
      key: 'attachment',
      sorter: (a, b) => a.attachment - b.attachment,
      sortDirections: ['descend', 'ascend'],
      //width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnLocation', defaultMessage: 'Location' }),
      dataIndex: 'location',
      key: 'location',
      // width: 300,
      sorter: (a, b) => a.location - b.location,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnRequestorsComment', defaultMessage: 'Requestor’s comment' }),
      dataIndex: 'requesterComment',
      key: 'requesterComment',
      sorter: (a, b) => a.requesterComment - b.requesterComment,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnApprover', defaultMessage: 'Approver' }),
      dataIndex: 'approver',
      key: 'approver',
      sorter: (a, b) => a.approver - b.approver,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
    {
      title: intl.formatMessage({ id: 'reportOverTimeReportColumnApproversComment', defaultMessage: 'Approver’s comment' }),
      dataIndex: 'approverComment',
      key: 'approverComment',
      ellipsis: true,
      sorter: (a, b) => a.approverComment - b.approverComment,
      sortDirections: ['descend', 'ascend'],
      // width: 200,
    },
  ];

  const setShowColumn = {
    index: true,
    no: true,
    requestDate: true,
    requesterName: true,
    status: true,
    fromDate: true,
    utilDate: true,
    totalHours: true,
    topic: false,
    attachment: false,
    location: false,
    requesterComment: false,
    approver: false,
    approverComment: false,
  };

  const setShowColumnArr = ['index', 'no', 'requestDate', 'requesterName', 'status', 'fromDate', 'utilDate', 'totalHours'];

  const ignoreColumn = ['topic', 'attachment', 'location', 'requesterComment', 'approver', 'approverComment'];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const today = new Date();
  const dateFormat = 'ddd, MMM DD YYYY';
  const dateNow = moment(today).format('YYYY-MM');
  const dateNowRangePicker = [moment(today, dateFormat), moment(today, dateFormat)];

  const [loading, setLoading] = useState(false);
  const [overtimeReport, setOvertimeReport] = useState([]);

  const [visible, setVisible] = useState(false);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);

  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(10);

  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);

  const [selectSearchData, setSelectSearchData] = useState('all');
  const [fieldChang, setFieldChang] = useState('');
  const [refreshTable, setRefreshTable] = useState(false);

  const [rangePicker, setRangePicker] = useState(dateNowRangePicker);
  const [fromDate, setFromDate] = useState(
    moment(today)
      .startOf('month')
      .format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState(
    moment(today)
      .endOf('month')
      .format('YYYY-MM-DD')
  );
  const [total, setTotal] = useState();
  const [extraSort, setExtraSort] = useState();
  const [fieldSort, setFieldSort] = useState();
  const [orderSort, setOrderSort] = useState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getOvertimeReport();
  }, [fieldChang, fromDate, toDate, state.checkedKeys, extraSort, selectSearchData, state.selectedKeys]);

  const getOvertimeReport = async () => {
    setLoading(true);
    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: _.size(state.checkedKeys) !== 0 ? state.checkedKeys : state.selectedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: fieldChang === '' ? paginationPage : 1,
        rowsPerPages: paginationSize,
        ordertype: orderSort,
        orderby: fieldSort,
      };

      const response = await httpClient.post(`/v1/resource/manager/company/${state.comId}/report/overtime`, body);
      if (response.status === 200) {
        setOvertimeReport(_.get(response, 'data.data.overtimes'));
        setTotal(response.data.data.totals);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const checkedValuecolumns = (e) => {
    let checked = e.target.checked;
    let val = e.target.value;
    let showColumn = { ...defaultShowColumn };
    if (checked === true) {
      const found = listArrayColumns.find((element) => element === val);
      if (found === undefined) {
        setListArrayColumns([...listArrayColumns, val]);
      }
      showColumn[val] = true;
    } else {
      const newListArray = [...listArrayColumns];
      const found = listArrayColumns.findIndex((element) => element === val);
      if (found >= 0) {
        newListArray.splice(found, 1);
        setListArrayColumns(newListArray);
      }
      showColumn[val] = false;
    }
    setDefaultShowColumn(showColumn);
  };

  const handleCheckOk = () => {
    const setNewArr = [];
    if (listArrayColumns.length <= 4) {
      setTextErrorSelectColumn(<FormattedMessage id="lblSelectAtLeastColumn" defaultMessage="Select at least 1 column." />);
      return;
    } else {
      setTextErrorSelectColumn('');
    }
    for (let i = 0; i < columns.length; i++) {
      const found = listArrayColumns.find((element) => element == columns[i].key);
      if (found != undefined) {
        setNewArr.push(columns[i]);
      }
    }
    setNewDataColumns(setNewArr);
    handleOnVisiblecolumns();
  };

  const handleReset = () => {
    const newListArrayColumns = [];
    newColumns.forEach((element) => {
      newListArrayColumns.push(element.key);
    });
    setNewDataColumns(newColumns);
    setListArrayColumns(newListArrayColumns);
    handleOnVisiblecolumns();
    setDefaultShowColumn(setShowColumn);
    setTextErrorSelectColumn('');
  };

  const handleOnVisiblecolumns = () => {
    setVisible(!visible);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    if (!flag) {
      // FNC คืนค่า Default อัติโนมัติ
      const newListArrayColumns = [];
      newDataColumns.forEach((element) => {
        newListArrayColumns.push(element.key);
      });

      const reduceOBJ = _.reduce(
        newListArrayColumns,
        function(result, value, key) {
          result[value] = true;
          return result;
        },
        {}
      );
      setListArrayColumns(newListArrayColumns);
      setDefaultShowColumn(reduceOBJ);
      setTextErrorSelectColumn('');
    }
  };

  const handleSearch = debounce((fieldChang) => {
    setFieldChang(fieldChang ? fieldChang : '');
  }, 500);

  const handleSelectData = (value) => {
    setSelectSearchData(value === undefined ? 'all' : value);
  };

  const onSelectRangePicker = (date, dateString) => {
    setRangePicker(dateString);
    setFromDate(moment(date[0]).format('YYYY-MM-DD'));
    setToDate(moment(date[1]).format('YYYY-MM-DD'));
  };

  const onSelectMonthPicker = (date) => {
    const startOfMonth = moment(date, 'YYYY/MM')
      .startOf('month')
      .format('YYYY-MM-DD');
    const endOfMonth = moment(date, 'YYYY/MM')
      .endOf('month')
      .format('YYYY-MM-DD');
    setFromDate(startOfMonth);
    setToDate(endOfMonth);
  };

  const handlePagination = (page) => {
    setPaginationPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPaginationSize(size);
    setPaginationPage(current);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setOrderSort('desc');
      } else {
        setOrderSort('asc');
      }
    } else {
      setOrderSort('');
    }
    setExtraSort(extra);
    setFieldSort(field);
  };

  const handleExport = async () => {
    setDisabled(true);
    try {
      const body = {
        fromDate: fromDate,
        utilDate: toDate,
        orgId: state.checkedKeys,
        search: fieldChang,
        searchBy: selectSearchData,
        paging: paginationPage === 1 ? paginationPage : 1,
        rowsPerPages: total,
        orderby: orderSort,
        ordertype: fieldSort,
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnRequestNo', defaultMessage: 'Request No.' }),
            sequence: 1,
            colCode: 'no',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnRequestDate', defaultMessage: 'Request Date' }),
            sequence: 2,
            colCode: 'requestDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnName', defaultMessage: 'Name' }),
            sequence: 3,
            colCode: 'requesterName',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnFromDate', defaultMessage: 'From (Date)' }),
            sequence: 4,
            colCode: 'fromDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnUntilDate', defaultMessage: 'To (Date)' }),
            sequence: 5,
            colCode: 'utilDate',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnTotalHours', defaultMessage: 'Total Hours' }),
            sequence: 6,
            colCode: 'totalHours',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnStatus', defaultMessage: 'Status' }),
            sequence: 7,
            colCode: 'status',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnTopic', defaultMessage: 'Topic' }),
            sequence: 8,
            colCode: 'topic',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnAttachment', defaultMessage: 'Attachment' }),
            sequence: 9,
            colCode: 'attachment',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnLocation', defaultMessage: 'Location' }),
            sequence: 10,
            colCode: 'location',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnRequestorsComment', defaultMessage: 'Requestor’s comment' }),
            sequence: 11,
            colCode: 'requesterComment',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnApprover', defaultMessage: 'Approver' }),
            sequence: 12,
            colCode: 'approver',
          },
          {
            colName: intl.formatMessage({ id: 'reportOverTimeReportColumnApproversComment', defaultMessage: 'Approver’s comment' }),
            sequence: 13,
            colCode: 'approverComment',
          },
        ],
      };
      const response = await httpClient.post(`/v1/resource/manager/company/${state.comId}/export/report/overtime`, body);
      if (response.status === 200) {
        window.open(response.data.data.url);
        successNotification(response.data.status.message);
        setDisabled(false);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {
      //errorNotification(error.response.data.status.message);
    }
  };

  const successNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const errorNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const showTotal = (total) => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const iconExcel = () => <img className="icon-excel" src={IconExcel} />;

  const HeaderAndToggle = () => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ cursor: 'pointer' }} onClick={fnc.handleToggle}>
          <Icon type={state.toggle ? 'menu-unfold' : 'menu-fold'} style={{ color: '#e73845' }} />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <p>
            <FormattedMessage id="reportOverTimeReportTitle" defaultMessage="Overtime Report" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <OverTimeContext.Consumer>
      {() => (
        <Card
          className="overtime-report-card"
          title={<HeaderAndToggle />}
          extra={
            // <Button className="button-link-export" type="link" onClick={handleExport} disabled={disabled}>
            //   <FormattedMessage id="btnExport" defaultMessage="Export" /> <Icon component={iconExcel} />
            // </Button>
            <Button_01 type="primary" style={{width: '100%'}}  onClick={handleExport}>
              <FormattedMessage id="btnExport" defaultMessage="Export" />
            </Button_01>
          }
        >
          <ActionOvertimeReport
            columns={columns}
            handleReset={handleReset}
            handleCheckOk={handleCheckOk}
            visible={visible}
            handleVisibleChange={handleVisibleChange}
            checkedValuecolumns={checkedValuecolumns}
            onChange={handleSearch}
            onSelect={handleSelectData}
            selectSearchData={selectSearchData}
            checked={defaultShowColumn}
            textErrorSelectColumn={textErrorSelectColumn}
            handleOnVisiblecolumns={handleOnVisiblecolumns}
            onSelectRangePicker={onSelectRangePicker}
            onSelectMonthPicker={onSelectMonthPicker}
          />

          <Table
            className="report-table"
            rowKey={(record) => record.index}
            onChange={handleChange}
            loading={loading}
            dataSource={overtimeReport}
            columns={newDataColumns}
            // scroll={{ x: 2500, y: 780 }}
            scroll={_.size(overtimeReport) !== 0 ? { x: 2500, y: `calc(100vh - 392px)` } : { x: 'auto' }}
            pagination={{
              total: total,
              showTotal: showTotal,
              defaultCurrent: 1,
              pageSizeOptions: ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              locale: { items_per_page: '' },
              onChange: handlePagination,
              onShowSizeChange: handleSizeChange,
            }}
          />

          {overtimeReport.length !== 0 ? (
            <div className="total-items-overtime-report">
              <span style={{ fontSize: '13px' }}>
                <FormattedMessage id="lblTotal" defaultMessage="Total" />
                {` ${total || 0} `}
                <FormattedMessage id="lblitems" defaultMessage="items" />
              </span>
            </div>
          ) : null}
        </Card>
      )}
    </OverTimeContext.Consumer>
  );
};
