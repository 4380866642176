import React, { useState, useEffect } from 'react';
import { Col, ConfigProvider, Modal, Row } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import './css/index.css';
import _ from 'lodash';
import DashBoardAssignee from './dashboard-assignee';
import DashBoardTab from './dashboard-tab';
import httpClient from '../axiosClient';
import Fullscreen from 'react-fullscreen-crossbrowser';

const ModalDashboard = (props) => {
  const intl = useIntl();
  const { visible, setVisible, orgId, teamName, fullscreenPageRef } = props;

  const comCode = localStorage.getItem('comCode');
  const [listMember, setListMember] = useState([]);
  const [listOrder, setListOrder] = useState([]);
  const [listAssignee1, setListAssignee1] = useState([]);
  const [listAssignee2, setListAssignee2] = useState([]);
  const [qrId, setQrId] = useState('');
  const [triggerQr, setTriggerQr] = useState(false);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalMem, setTotalMem] = useState(0);
  console.log('qrId', qrId);
  console.log('teamName', teamName);

  useEffect(() => {
    if (orgId && visible) {
      getDashboard();

      const interval = setInterval(() => {
        getDashboard();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [qrId, visible]);

  const getDashboard = async () => {
    try {
      const response = await httpClient.get(`/v5/order/manager/company/${comCode}/web-tas/dashboard/?orgId=${orgId}`);
      setListMember(_.get(response, 'data.data.memberTeamSummary'));
      setListOrder(_.get(response, 'data.data.orderStatusSummary'));
      setTotalOrder(_.get(response, 'data.data.totalQueue'));
      setTotalMem(_.get(response, 'data.data.totalMember'));
      const data = _.get(response, 'data.data.queueList');
      const size = _.size(data);

      if (size <= 10) {
        setListAssignee1(data);
        setListAssignee2([]);
      } else if (size < 20) {
        setListAssignee1(data.slice(0, 10));
        setListAssignee2(data.slice(10 - size));
      } else if (size >= 20) {
        setListAssignee1(data.slice(-20, -10));
        setListAssignee2(data.slice(-10));
      }
    } catch (error) {
      return;
    }
  };

  console.log('testlistAssignne', listAssignee1, listAssignee2);

  useEffect(() => {
    const getQr = async () => {
      const body = {
        data: {
          orgId: orgId,
        },
      };
      try {
        const response = await httpClient.post(`/v4/resource/manager/companies/${comCode}/generateQr`, body);
        setQrId(`qrId=${_.get(response, 'data.data.qrId')}&orgId=${orgId}`);
      } catch (error) {
        return;
      }
    };

    if (orgId && visible) getQr();
  }, [orgId, visible, triggerQr]);

  console.log('listAssignee', listAssignee1, listAssignee2);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <Modal
        className="modal-item-unset-padding"
        title={false}
        visible={visible}
        onCancel={handleCancel}
        centered={true}
        width={'100%'}
        footer={false}
        closable={false}
        bodyStyle={{
          height: '100vh',
          backgroundColor: '#FCFCFC',
        }}
      >
        <Fullscreen enabled={visible} onChange={setVisible}>
          <div style={{ backgroundColor: '#fafafa', height: '100%' }}>
            <Row gutter={[12, 12]}>
              <Col span={6}>
                <DashBoardTab
                  qrId={qrId}
                  setTriggerQr={setTriggerQr}
                  listMember={listMember}
                  listOrder={listOrder}
                  teamName={teamName}
                  totalOrder={totalOrder}
                  totalMem={totalMem}
                />
              </Col>
              <Col span={_.size(listAssignee2) > 0 ? 9 : 18}>
                <DashBoardAssignee data={listAssignee1} />
              </Col>
              {_.size(listAssignee2) > 0 && (
                <Col span={9}>
                  <DashBoardAssignee data={listAssignee2} />
                </Col>
              )}
            </Row>
          </div>
        </Fullscreen>
      </Modal>
    </div>
  );
};

export default ModalDashboard;
