import { Button, Col, Icon, Input, Modal, Row, Select, Tag } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../v2/button_01';
import { messageLabel } from '../message-component';
import CustomTableComponent from './components/table';
import ModalItemForm from './modal-item';
import { getItemBasePay, updateItemBasePay } from '../../controllers/base-pay/basepay';
import { useAppContext } from '../../includes/indexProvider';
import { errorNotification, successNotification } from '../v2/notification';
import Button_02 from '../v2/button_02';
import { PageSettings } from '../../config/page-settings';
import { deleteGasType, getGasTypeList } from '../../controllers/project-new/project-setting';

const { Option } = Select;

const ModalSelectItemProjectGas = ({ visible, setVisble, menuCode, setItem, onSelectItem, setTrigger, onCheckItem, reApi }) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => record.index,
    },
    {
      title: messageLabel('modalSelectItemColumnGasType', 'Gas Type'),
      dataIndex: 'gasTypeName',
      key: 'gasTypeName',
      sorter: true,
      width: 250,
      ellipsis: true,
      fixed: 'left',
    },

    {
      title: messageLabel('modalSelectItemColumnStatus', 'Status'),
      dataIndex: 'gasStatus',
      key: 'gasStatus',
      // sorter: true,
      width: 150,
      render: (text, record, index) => (
        <div className="tag-center-div">
          <Tag
            style={{ fontSize: '14px' }}
            className="tag-center-style"
            color={_.get(record, 'gasStatus') === 'Active' ? '#3BA223' : '#898989'}
          >
            {_.get(record, 'gasStatus')}
          </Tag>
        </div>
      ),
    },
    {
      title: messageLabel('modalSelectItemColumnCreatedby', 'Created By'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 200,
      sorter: true,
    },
    {
      title: messageLabel('modalSelectItemColumnCreatedDate', 'Created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      width: 200,
    },
    {
      title: messageLabel('modalSelectItemColumnDeletedBy', 'Deleted By'),
      dataIndex: 'deletedBy',
      key: 'deletedBy',
      width: 200,
      sorter: true,
      render: (text, record, index) => (text ? text : '-'),
    },
    {
      title: messageLabel('modalSelectItemColumnDeletedDate', 'Deleted Date'),
      dataIndex: 'deletedDate',
      key: 'deletedDate',
      sorter: true,
      width: 200,
      render: (text, record, index) => (text ? text : '-'),
    },
  ];
  const ignoreColumn = [];
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));

  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const [sortFieldTable, setSortFieldTable] = useState('');
  const [sortOrderTable, setSortOrderTable] = useState('');
  const [toggleFilter, setToggleFilter] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [listStatus, setListStatus] = useState([
    {
      code: 'Active',
      lang: {
        EN: 'Active',
        TH: 'ใช้งาน',
      },
    },
    {
      code: 'Inactive',
      lang: {
        EN: 'Inactive',
        TH: 'ไม่ได้ใช้งาน',
      },
    },
  ]);
  const [listType, setListType] = useState([]);
  const [filter, setFilter] = useState({ status: 'Active' });
  const [record, setRecord] = useState({});
  const [isModal, setIsModal] = useState(false);
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;

  console.log('datasource', dataSource);

  useEffect(() => {
    setTrigger((event) => !event);
    setFilter({ status: 'Active' });
    setPageTable(1);
    setSizeTable(10);
  }, [visible]);

  useEffect(() => {
    const getAPI = async () => {
      const body = {
        gasTypeName: _.get(filter, 'gasType') || undefined,
        gasStatus: _.get(filter, 'status') || undefined,
        limit: sizeTable,
        pageNumber: pageTable,
        orderBy: sortFieldTable,
        orderType: sortOrderTable,
      };

      const response = await getGasTypeList(body);
      if (_.get(response, 'status.code') === 200) {
        setDataSource(response.data.data);
        setTotal(response.data.totalItem);
        // setListStatus(response.data.basePayStatus);
        setListType(response.data.basePayType);
      }
    };
    getAPI();
  }, [visible, toggleFilter]);

  const handleToggleFilter = () => {
    setToggleFilter((event) => !event);
    setPageTable(1);
  };

  const handleChangeTable = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrderTable('desc');
      } else {
        setSortOrderTable('asc');
      }
    } else {
      setSortOrderTable('');
    }
    setSortFieldTable(field);
    setPageTable(pagination.current);
    setSizeTable(pagination.pageSize);
    setToggleFilter((event) => !event);
  };

  const handleChangeFilter = async (value, code) => {
    setFilter({ ...filter, [code]: value });
    // setPage(1);
  };

  const handleSelect = (record) => {
    if (_.isFunction(setItem)) setItem(record);
    if (_.isFunction(onSelectItem)) onSelectItem(_.get(record, '_id'));
    setVisble(false);
  };

  const handleOpenModalAdd = (record) => {
    setRecord(record ? record : {});
    setIsModal(true);
  };

  const contentAction = (record) => {
    return _.get(record, 'gasStatus') === 'Active' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleSelect(record)}>
            <FormattedMessage id="btnSelect" defaultMessage="Select" />
          </Button>
        </div>

        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A6') || checkPermissionAction('P81PG1C2', 'P81PG1C2A3') ? (
                <Button
                  style={{ width: 100 }}
                  type="link"
                  ghost
                  onClick={() => {
                    handleOpenModalAdd(record);
                    // setEditData(record);
                  }}
                >
                  <FormattedMessage id="btnEdit" defaultMessage="Edit" />
                </Button>
              ) : null}
            </div>
          )}
        </PageSettings.Consumer>

        <PageSettings.Consumer>
          {({ checkPermissionAction }) => (
            <div>
              {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A7') || checkPermissionAction('P81PG1C2', 'P81PG1C2A4') ? (
                <Button style={{ width: 100 }} type="link" ghost onClick={() => handleDelete(record)}>
                  <FormattedMessage id="btnDelete" defaultMessage="Delete" />
                </Button>
              ) : null}
            </div>
          )}
        </PageSettings.Consumer>
      </div>
    ) : null;
  };

  const handleDelete = (record) => {
    Modal.confirm({
      className: 'maintenance-report-modal-confirm',
      centered: true,
      icon: null,
      title: intl.formatMessage({ id: 'lblDelete', defaultMessage: 'Are you sure you want to delete ?' }),
      okText: intl.formatMessage({ id: 'btnConfirm', defaultMessage: 'Confirm' }),
      cancelText: intl.formatMessage({ id: 'btnCancel', defaultMessage: 'Cancel' }),
      okButtonProps: {
        style: {
          width: '96px',
        },
        type: 'primary',
      },
      cancelButtonProps: {
        style: {
          width: '96px',
        },
        type: 'danger',
      },
      async onOk() {
    
        try {
          const response = await deleteGasType(_.get(record, '_id'));
          if (_.get(response, 'data.status.code') === 200) {
            successNotification(response.data.status.message);
            if (_.isFunction(reApi)) {
              reApi();
            }
            setToggleFilter((event) => !event);
            setTrigger((event) => !event);
          }
        } catch (error) {
          errorNotification(error.response.data.data.message);
        }
      },
      onCancel() {},
    });
  };

  const handleCancel = () => {
    setVisble(false);
    if (_.isFunction(onCheckItem)) {
      onCheckItem();
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'modalSelectItemTitleGasType', defaultMessage: 'Gas Type' })}
      visible={visible}
      onOk={() => setVisble(false)}
      onCancel={() => handleCancel()}
      width={1122}
      zIndex={1000}
      centered={true}
      bodyStyle={{ padding: 'unset', height: '645px' }}
      className="modalBrandAndModel"
      footer={[
        <Button_02 key="close" btnsize="wd_df" onClick={() => handleCancel()}>
          <FormattedMessage id="btnCloseItem" defaultMessage="Close" />
        </Button_02>,
      ]}
    >
      <Row>
        <Col span={6} style={{ borderRight: '1px solid #e8e8e8' }}>
          <div style={{ height: '600px' }}>
            <div
              className="listItemTextHeader"
              style={{
                padding: '24px 16px 16px 0px',
                marginTop: '-5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ width: '50%', marginLeft: '24px' }}>
                <FormattedMessage id="modalSelectItemTitleFilter" defaultMessage="Filter" />
              </span>
            </div>
            <div style={{ padding: '16px 16px 8px 0px', marginLeft: '15px' }}>
              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalSelectItemLabelGasType" defaultMessage="Gas Type" />
                </div>
                <div>
                  <Input
                    allowClear={true}
                    onChange={(e) => handleChangeFilter(e.target.value, 'gasType')}
                    value={_.get(filter, 'gasType')}
                    placeholder={intl.formatMessage({
                      id: 'modalSelectItemPHRefGasType',
                      defaultMessage: 'Enter Gas Type',
                    })}
                  />
                </div>
              </div>

              <div style={{ marginBottom: '16px' }}>
                <div className="" style={{ marginBottom: '8px', color: '#1D3557' }}>
                  <FormattedMessage id="modalSelectItemLabelStatus" defaultMessage="Status" />
                </div>
                <Select
                  allowClear
                  showSearch
                  onChange={(value) => handleChangeFilter(value, 'status')}
                  filterOption={false}
                  value={_.get(filter, 'status')}
                  placeholder={intl.formatMessage({ id: 'modalSelectItemPHRefStatus', defaultMessage: 'Select Status' })}
                  style={{ width: '100%' }}
                >
                  {_.map(listStatus, (item) => (
                    <Option value={item.code}>{langValue === 'TH' ? item.lang.TH : item.lang.EN}</Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <div style={{ height: '50px' }}>
            <div style={{ padding: '0px 16px 10px 24px' }}>
              <Button_01
                style={{ margin: '0px', width: '100%' }}
                key="print"
                type="primary"
                btnsize="wd_df"
                onClick={() => handleToggleFilter()}
              >
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_01>
            </div>
          </div>
        </Col>

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Col span={18}>
          <div
            className="listItemTextHeader"
            style={{
              padding: '14px 24px 15px 24px',
              marginTop: '-5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <FormattedMessage id="modalSelectItemTitleGasType" defaultMessage="Gas Type" />
            </span>

            <PageSettings.Consumer>
              {({ checkPermissionAction }) => (
                <div>
                  {checkPermissionAction('P2S1PG2C16', 'P2S1PG2C16A5') || checkPermissionAction('P81PG1C2', 'P81PG1C2A2') ? (
                    <Button_01
                      style={{ marginRight: '-1px' }}
                      key="close"
                      type="primary"
                      btnsize="wd_df"
                      onClick={() => handleOpenModalAdd()}
                    >
                      <FormattedMessage id="btnCreate" defaultMessage="Create" />
                    </Button_01>
                  ) : null}
                </div>
              )}
            </PageSettings.Consumer>
          </div>
          <div
            style={{
              padding: '16px',
            }}
          >
            <CustomTableComponent
              columns={newColumns}
              dataSource={dataSource}
              total={total}
              onRow={true}
              role={true}
              onChange={handleChangeTable}
              paginationPage={pageTable}
              paginationShow={true}
              paginationSize={sizeTable}
              scroll={{ x: _.sumBy(newColumns, 'width'), y: '450px' }}
              contentAction={contentAction}
            />
          </div>
        </Col>
      </Row>

      <ModalItemForm
        title={
          _.size(record)
            ? intl.formatMessage({ id: 'modalSelectItemTitleEditGasType', defaultMessage: 'Edit Gas Type' })
            : intl.formatMessage({ id: 'modalSelectItemTitleCreateGasType', defaultMessage: 'Create Gas Type' })
        }
        visible={isModal}
        setVisible={setIsModal}
        setToggle={setToggleFilter}
        record={record}
        listType={listType}
        reApi={reApi}
      />
    </Modal>
  );
};

ModalSelectItemProjectGas.defaultProps = {};

export default ModalSelectItemProjectGas;
