import React, { useEffect, useState } from 'react';
import CollapseCustom from '../../../components/collapse-custom';
import { useIntl, FormattedMessage } from 'react-intl';
import ReportItemMovementActionColumns from './action-columns';
import { Input, Form, Select, DatePicker, Row, Col } from 'antd';
import LabeRequireForm from '../../../components/label-required-form';
import _ from 'lodash';
import Button_01 from '../../../components/v2/button_01';
import Button_02 from '../../../components/v2/button_02';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReportItemMovementAction = (props) => {
  const intl = useIntl();
  const { search, setSearch, setPage, setIsFetch, onFilterOrder, actionColumns, refTypeList, itemListSelect, movementModule, movementRefType, form } = props;
  console.log('movementRefType', movementRefType)

  const { itemList, setItemOption, handleItemPopupScroll, handleItemChange, handleItemSearchChange, itemLoading } = itemListSelect

  const [defaultItem, setDefaultItem] = useState()
  const { getFieldDecorator, } = form;
  const lang = localStorage.getItem('langValue');


  useEffect(() => {
    console.log('search', search)
    if(_.get(search, 'item')) {
      setDefaultItem(_.get(search, 'item'))
    }
  }, [search])


  const onChangeSelect = (value) => {
    onFilterOrder(value, 'item')
    handleItemChange(value)
  }

  const handleApply = () => {
    setIsFetch((event) => !event)
    setPage(1)
  }

  const handleReset = () => {
    setIsFetch((event) => !event)
    setSearch((prev) => ({...prev, warehouse: '', transactionTypeCode: '', lotNoOrSerialNo: '', expiredDate: [], actionDate: [], refType: undefined, refNo: ''}))
    setPage(1)
  }

  const formFilter = () => {
    return (
      <Form colon={false} className="deliveryForm">
        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemMovementColumnsWarehouse', defaultMessage: 'Warehouse' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemMovementPHRefWarehouse', defaultMessage: 'Enter Warehouse Code or Warehouse Name' })}
                onChange={(e) => onFilterOrder(e.target.value, 'warehouse')}
                value={_.get(search, 'warehouse') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemMovementColumnsType', defaultMessage: 'Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemMovementPHRefType', defaultMessage: 'Select Type' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'transactionTypeCode')}
                value={_.get(search, 'transactionTypeCode') || undefined}
              >
                {_.map(movementModule, (item) => (
                  <Option key={item.code} value={item.code}>{lang === 'TH' ? item.txt.TH : item.txt.EN}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemMovementColumnsLotNoOrSerialNo', defaultMessage: 'Lot No. or Serial No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemMovementPHRefLotNoOrSerialNo', defaultMessage: 'Enter Lot No. or Serial No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'lotNoOrSerialNo')}
                value={_.get(search, 'lotNoOrSerialNo') || undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemMovementColumnsExpiredDate', defaultMessage: 'Expired Date' })} req={false} />
              }
            >
              <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportItemMovementPHRefExpiredStartDate', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportItemMovementPHRefExpiredEndDate', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'expiredDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              value={_.get(search, 'expiredDate') || undefined}
            />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemMovementColumnsActionDate', defaultMessage: 'Action Date' })} req={false} />
              }
            >
              <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportItemMovementPHRefActionStartDate', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportItemMovementPHRefActionEndDate', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'actionDate')}
              value={_.get(search, 'actionDate') || undefined}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
            />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm
                  text={intl.formatMessage({ id: 'reportItemMovementColumnsReferenceType', defaultMessage: 'Reference Type' })}
                  req={false}
                />
              }
            >
              <Select
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemMovementPHRefReferenceType', defaultMessage: 'Select Reference Type' })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                onChange={(value) => onFilterOrder(value, 'refType')}
                value={_.get(search, 'refType') || undefined}
              >
                {_.map(movementRefType, (item) => (
                  <Option key={item.code} value={item.code}>{lang === 'TH' ? item.txt.TH : item.txt.EN}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label={
                <LabeRequireForm text={intl.formatMessage({ id: 'reportItemMovementColumnsReferenceNo', defaultMessage: 'Reference No.' })} req={false} />
              }
            >
              <Input
                allowClear={true}
                placeholder={intl.formatMessage({ id: 'reportItemMovementPHRefReferenceNo', defaultMessage: 'Enter Reference No.' })}
                onChange={(e) => onFilterOrder(e.target.value, 'refNo')}
                value={_.get(search, 'refNo') || undefined}
              />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8} style={{ textAlign: 'right' }}>
          <Form.Item
              label={<LabeRequireForm text={''} req={false} />}
            >
              <Button_01 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={handleReset}>
                <FormattedMessage id="btnResetFilter" defaultMessage="Reset" />
              </Button_01>
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="print" type="primary" btnsize="wd_df" onClick={handleApply}>
                <FormattedMessage id="btnApplyFilter" defaultMessage="Apply" />
              </Button_02>
          </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div style={{ marginTop: '16px' }}>
      <CollapseCustom
        label={intl.formatMessage({ id: `btnFilter`, defaultMessage: 'Filter' })}
        extra={
          <div style={{ display: 'flex' }}>
            {/* <RangePicker
              allowClear={true}
              placeholder={[
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateFrom', defaultMessage: 'Select From Date' }),
                intl.formatMessage({ id: 'reportReconcilePHExpiredDateTo', defaultMessage: 'Select To Date' }),
              ]}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => onFilterOrder(value, 'searchdDate')}
              style={{ width: '100%' }}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
            /> */}
            <div style={{marginRight: 20, alignContent: 'center' }}>
            Item <span style={{color: 'red'}}>*</span> :
            </div>
            <Form colon={false} className="deliveryForm">
            {getFieldDecorator('item', {
              initialValue:'',
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'reportItemSalesPSI', defaultMessage: 'Please Select Item' }),
                },
              ],
            })(

            <Select
              style={{width: 250}}
              placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefModel', defaultMessage: 'Select Item' })}
              defaultActiveFirstOption={false}
              showSearch
              // allowClear
              onPopupScroll={handleItemPopupScroll}
              // onSelect={handleItemChangeSelectForm}
              onChange={handleItemChange}
              onSearch={handleItemSearchChange}
              // loading={itemLoading}
              filterOption={false}
              value={_.get(search, 'item')}
            >
              {itemList && itemList.map((item) => setItemOption(item))}
            </Select>
            )}

            </Form>

            <ReportItemMovementActionColumns actionColumns={actionColumns} />
          </div>
        }
      >
        {formFilter()}
      </CollapseCustom>
    </div>
  );
};

export default ReportItemMovementAction;
