import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Switch, Divider, AutoComplete, Input, Select, Spin, Table } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Button_02 from '../v2/button_02';
import Button_01 from '../v2/button_01';
import LabeRequireForm from '../label-required-form';
import { getQueueMessages } from '../../hooks/use-queue-api';
import { getChangeNextStatus, getOrdersByIdParcel } from '../../controllers/orders-controller/api';
import { errorNotification, successNotification } from '../v2/notification';
import moment from 'moment';
import { numberFormatter } from '../../component-function/fnc-inputnumber-currency';

const { Option } = Select;
const comCode = localStorage.getItem('comCode');

const DeliveryViewOrder = (props) => {
  const { visible, data, onEdit, trigger, setTrigger, onCancel } = props;
  const intl = useIntl();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState([]);
  const [dataInfo, setDataInfo] = useState({});

  console.log('data', data);

  console.log('trigger', trigger, data);

  useEffect(() => {
    const getApi = async () => {
      const response = await getOrdersByIdParcel(_.get(data, '_id'));
      const responseStatus = await getChangeNextStatus(_.get(data, '_id'));

      const responseData = _.get(response, 'data.data');
      const filterStatus = _.filter(
        _.get(responseStatus, 'data.data.statusList'),
        (list) => list.statusCode !== _.get(responseData, 'statusDetail.statusCode')
      );

      console.log('getOrdersByIdParcel response', response, responseStatus, data);

      const tableNew = _.map(_.get(responseData, 'pacelDetails'), (item) => {
        if (_.get(responseData, 'detailTypeCode') === 'deliveryOrderItem') {
          return {
            _id: _.get(item, '_id'),
            itemMasterId: _.get(item, 'size.item_master_id'),
            itemCode: _.get(item, 'size.item_code'),
            itemName:
              (_.get(item, 'size.item_brand_name') || '') +
              ' ' +
              (_.get(item, 'size.item_model_name') || '') +
              ' ' +
              (_.get(item, 'size.item_name') || ''),
            item_brand_name: _.get(item, 'size.item_brand_name'),
            item_model_name: _.get(item, 'size.item_model_name'),
            item_name: _.get(item, 'size.item_name'),
            qty: _.get(item, 'size.qty'),
            uomId: _.get(item, 'size.uom.uom_id'),
            uom: _.get(item, 'size.uom.uom_name_en'),
            index: _.get(item, 'parcelIndex'),
          };
        } else {
          return {
            index: _.get(item, 'parcelIndex'),
            _id: _.get(item, '_id'),
            parcelNo: _.get(item, 'parcelNo'),
            parcelId: _.get(item, 'parcelNo'),
            size: _.get(item, 'size'),
            weight: _.get(item, 'weight'),
            shippingPrice: '0',
            statusCode: _.get(item, 'status.statusCode'),
          };
        }
      });

      const tableNewFilter = _.filter(tableNew, (t) => {
        return _.get(t, 'statusCode') !== 'canceled';
      });

      const setNewTable = _.get(responseData, 'detailTypeCode') === 'deliveryOrderParcel' ? tableNewFilter : tableNew

      console.log('NEWNNE',setNewTable);
      

      setDataInfo(responseData);
      setTableData(setNewTable);
      setButtonStatus(filterStatus);
    };

    if (_.get(data, '_id')) {
      getApi();
    }
  }, [data, trigger]);

  const columnsItem = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'deliveryViewColumnsItemCode', defaultMessage: 'Item Code' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      // sorter: (a, b) => a.itemCode.localeCompare(b.itemCode),
      sorter: (a, b) => {
        const valA = _.get(a, 'itemCode', '');
        const valB = _.get(b, 'itemCode', '');
        return valA.localeCompare(valB);
      },
      // sorter: true,
    },
    {
      title: intl.formatMessage({ id: 'deliveryViewColumnsItem', defaultMessage: 'Item' }),
      dataIndex: 'itemName',
      key: 'itemName',
      // sorter: true,
      sorter: (a, b) => {
        const valA = _.get(a, 'itemName', '');
        const valB = _.get(b, 'itemName', '');
        return valA.localeCompare(valB);
      },
    },
    {
      title: intl.formatMessage({ id: 'deliveryViewColumnsQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      // sorter: true,
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: intl.formatMessage({ id: 'deliveryViewColumnsUoM', defaultMessage: 'UoM' }),
      dataIndex: 'uom',
      key: 'uom',
      // sorter: true,
      sorter: (a, b) => {
        const valA = _.get(a, 'uom', '');
        const valB = _.get(b, 'uom', '');
        return valA.localeCompare(valB);
      },
    },
  ];

  const columnsParcel = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'deliveryViewColumnsParcelNo', defaultMessage: 'Parcel No.' }),
      dataIndex: 'parcelNo',
      key: 'parcelNo',
      // sorter: true,
      sorter: (a, b) => {
        const valA = _.get(a, 'parcelNo', '');
        const valB = _.get(b, 'parcelNo', '');
        return valA.localeCompare(valB);
      },
    },
    {
      title: intl.formatMessage({ id: 'deliveryViewColumnsSize', defaultMessage: 'Size' }),
      dataIndex: 'size.sizeName',
      key: 'size.sizeName',
      // sorter: true,
      sorter: (a, b) => {
        const valA = _.get(a, 'size.sizeName', '');
        const valB = _.get(b, 'size.sizeName', '');
        return valA.localeCompare(valB);
      },
    },
    {
      title: intl.formatMessage({ id: 'deliveryViewColumnsWeight', defaultMessage: 'Weight (kg.)' }),
      dataIndex: 'weight',
      key: 'weight',
      // sorter: true,
      sorter: (a, b) => a.weight - b.weight,
      render: (text) => numberFormatter(text),
    },
  ];

  const onChangeTo = async (status) => {
    setLoading(true);
    const comCode = localStorage.getItem('comCode');

    const body = {
      orderStatus: status,
      comment: '',
    };

    const payload = {
      apiMethod: 'put',
      urlQueue: `/queue/manager/company/${comCode}/channgedeliveryorder`,
      apiUrl: `${process.env.REACT_APP_URL_MANAGER}/order-manager/v5/order/manager/company/${comCode}/web/deliveryorder-status/${_.get(
        data,
        '_id'
      )}`,
      payload: body,
    };

    try {
      const response = await getQueueMessages(payload);
      console.log('getQueueMessages data:', response);
      if (response.status.code === 200) {
        successNotification(_.get(response, 'status.message'));
      } else {
        errorNotification(_.get(response, 'status.message'));
      }
    } catch (error) {
      console.log('Error fetching queue messages:', error, error.response);
      errorNotification(_.get(error, 'response.data.status.message'));
    }

    console.log('onChangeTo isLoad', loading);

    setLoading(false);
    setTrigger((event) => !event);
  };

  const onEditModal = () => {
    let setData = {
      orderType: _.get(dataInfo, 'orderType', '-'),
      deliveryDate: _.get(dataInfo, 'deliveryDate', '-'),
      deliveryTime: _.get(dataInfo, 'deliveryTime', '-'),
      createdby: _.get(dataInfo, 'createdBy', '-'),
      createdDate: _.get(dataInfo, 'createdDate', '-'),

      customerId: _.get(dataInfo, 'customerDetail.customerId', '-'),
      customerName: _.get(dataInfo, 'customerDetail.comtomerName', '-'),
      customerPhone: _.get(dataInfo, 'customerDetail.phone', '-'),
      customerAddress: _.get(dataInfo, 'sourceDetail.address', '-'),
      customerPostcode: _.get(dataInfo, 'sourceDetail.postcode', '-'),

      recipientId: _.get(dataInfo, 'contactDetail.customerContactId', '-'),
      recipientName: _.get(dataInfo, 'contactDetail.customerContactName', '-'),
      recipientPhone: _.get(dataInfo, 'contactDetail.customerContactPhone', '-'),
      recipientAddress: _.get(dataInfo, 'customerDetail.address', '-'),
      recipientPostcode: _.get(dataInfo, 'customerDetail.postcode', '-'),

      referenceNo: _.get(dataInfo, 'customerDetail.referenceNo', ''),

      table: tableData,
      detailTypeCode: _.get(dataInfo, 'detailTypeCode'),
      statusCode: _.get(dataInfo, 'statusDetail.statusCode'),
      _id: _.get(data, '_id'),
    };

    dataInfo.table = tableData;

    onEdit(dataInfo);
  };

  const footer = [
    <Button_02
      style={{ margin: '0px 10px 0px 0px' }}
      key="back"
      btnsize="wd_df"
      onClick={() => onCancel()}
      margin="unset"
      disabled={loading}
    >
      <FormattedMessage id="btnClose" defaultMessage="Close" />
    </Button_02>,
    <>
      {_.get(dataInfo, 'statusDetail.statusCode') === 'open' ? (
        <Button_01
          style={{ margin: '0px 10px 0px 0px' }}
          key="submit01"
          type="primary"
          btnsize="wd_df"
          onClick={() => onEditModal()}
          disabled={loading}
        >
          <FormattedMessage id="btnEdit" defaultMessage="Edit" />
        </Button_01>
      ) : null}
    </>,
    <>
      {_.some(buttonStatus, (item) => item.statusCode === 'canceled') ? (
        <Button_01
          style={{ margin: '0px 10px 0px 0px', width: 'auto' }}
          key="submit01"
          type="primary"
          btnsize="wd_df"
          onClick={() => onChangeTo('canceled')}
          disabled={loading}
        >
          <FormattedMessage id="deliveryOrderModalViewButtonCancelDO" defaultMessage="Cancel DO" />
        </Button_01>
      ) : null}
    </>,
    <>
      {_.some(buttonStatus, (item) => item.statusCode === 'rejected') ? (
        <Button_01
          style={{ margin: '0px 10px 0px 0px' }}
          key="submit01"
          type="primary"
          btnsize="wd_df"
          onClick={() => onChangeTo('rejected')}
          disabled={loading}
        >
          <FormattedMessage id="deliveryOrderModalViewButtonRejected" defaultMessage="Rejected" />
        </Button_01>
      ) : null}
    </>,
    <>
      {_.some(buttonStatus, (item) => item.statusCode === 'open') ? (
        <Button_01
          style={{ margin: '0px 10px 0px 0px' }}
          key="submit01"
          type="primary"
          btnsize="wd_df"
          onClick={() => onChangeTo('open')}
          disabled={loading}
        >
          <FormattedMessage id="deliveryOrderModalViewButtonOpen" defaultMessage="Open" />
        </Button_01>
      ) : null}
    </>,
    <>
      {_.some(buttonStatus, (item) => item.statusCode === 'confirmed') ? (
        <Button_01
          style={{ margin: '0px 10px 0px 0px' }}
          key="submit01"
          type="primary"
          btnsize="wd_df"
          onClick={() => onChangeTo('confirmed')}
          disabled={loading}
        >
          <FormattedMessage id="deliveryOrderModalViewButtonConfirm" defaultMessage="Confirmed" />
        </Button_01>
      ) : null}
    </>,
  ];

  return (
    <Modal
      title={
        <span style={{ fontSize: '15px' }}>
          {intl.formatMessage({
            id: `deliveryOrderViewTitleDeliveryOrder`,
            defaultMessage: 'Delivery Order',
          }) +
            '・' +
            _.get(data, 'orderNo')}
        </span>
      }
      centered={true}
      width={900}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
      zIndex={998}
      bodyStyle={{ padding: 'unset', height: 'calc(100vh - 300px)', overflowY: 'auto' }}
      footer={footer}
    >
      <Spin spinning={loading}>
        <Form layout="vertical" className="transporationSettingForm" style={{ padding: '0px' }}>
          <div style={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1' }}>
            <div className="item-title" style={{ padding: '10px 24px 0px 24px' }}>
              <span className="item-title-text">
                <FormattedMessage id="deliveryOrderViewDetails" defaultMessage="Details" />
              </span>

              <div style={{ width: '135px', paddingTop: '3px' }}>
                <p
                  style={{
                    // backgroundColor: record['statusColor'],
                    backgroundColor: _.get(dataInfo, 'statusDetail.statusColor'),
                    color: 'white',
                    textAlign: 'center',
                    borderRadius: '10px',
                    // height: '23px',
                    // width: '100%',
                    padding: ' 0px 10px',
                  }}
                >
                  {_.get(dataInfo, 'statusDetail.statusName')}
                </p>
              </div>
            </div>

            <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
          </div>
          <div style={{ padding: '24px' }}>
            <Row gutter={[8, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderType', defaultMessage: 'Order Type' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'orderType', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderDate', defaultMessage: 'Delivery Date' })}
                      req={false}
                    />
                  }
                >
                  {moment(_.get(dataInfo, 'deliveryDate', '-'), 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderTiem', defaultMessage: 'Delivery Time' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'deliveryTime', '-')}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderStore', defaultMessage: 'Store' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'branchDetail.branchCode', '-') + ' ' + _.get(dataInfo, 'branchDetail.branchName', '')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderSaleOrderNo', defaultMessage: 'Sale Order No.' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'saleOrderDetail.saleOrderNo', '-') || '-'}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderReferenceNo', defaultMessage: 'Reference No.' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'referenceNo', '-')}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderCreatedby', defaultMessage: 'Created by' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'createdBy', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderCreatedDate', defaultMessage: 'Created Date' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'createdDate', '-')}
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="item-title" style={{ padding: '10px 24px 0px 24px' }}>
            <span className="item-title-text" style={{ fontSize: '15px' }}>
              <FormattedMessage id="deliveryOrderViewCustomer" defaultMessage="Customer" />
            </span>
          </div>

          <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />

          <div style={{ padding: '24px' }}>
            <Row gutter={[8, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm text={intl.formatMessage({ id: 'deliveryOrderViewOrderName', defaultMessage: 'Name' })} req={false} />
                  }
                >
                  {_.get(dataInfo, 'customerDetail.comtomerName', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'orderModalContact', defaultMessage: 'Contact' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'contactDetail.customerContactName', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderRecipientPhoneNumber', defaultMessage: 'Phone Number' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'contactDetail.customerContactPhone', '-')}
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderPhoneNumber', defaultMessage: 'Phone Number' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'customerDetail.phone', '-')}
                </Form.Item>
              </Col>
              <Col span={8}></Col> */}
            </Row>
            <Row gutter={[8, 24]}>
              <Col span={16}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'orderModalAddressSource', defaultMessage: 'Source Address' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'sourceDetail.address_name') ? _.get(dataInfo, 'sourceDetail.address_name', '-') + ',' : ''} {_.get(dataInfo, 'sourceDetail.address', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'orderModalPostCodeSource', defaultMessage: 'Source PostCode' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'sourceDetail.postcode')||  '-'}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 24]}>
              <Col span={16}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'orderModalAddressDestination', defaultMessage: 'Destination Address' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'destinationDetail.address_name') ? _.get(dataInfo, 'destinationDetail.address_name') + ',' : ''} {_.get(dataInfo, 'destinationDetail.address', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'orderModalPostCodeDestination', defaultMessage: 'Destination PostCode' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'destinationDetail.postcode')||  '-'}
                </Form.Item>
              </Col>
            </Row>
          </div>

          {/* <div className="item-title" style={{ padding: '10px 24px 0px 24px' }}>
            <span className="item-title-text" style={{ fontSize: '15px' }}>
              <FormattedMessage id="deliveryOrderViewRecipient" defaultMessage="Recipient" />
            </span>
          </div>

          <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} /> */}

          {/* <div style={{ padding: '24px' }}>
            <Row gutter={[8, 24]}>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderRecipientName', defaultMessage: 'Name' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'contactDetail.customerContactName', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'deliveryOrderViewOrderRecipientPhoneNumber', defaultMessage: 'Phone Number' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'contactDetail.customerContactPhone', '-')}
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row gutter={[8, 24]}>
              <Col span={16}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'orderModalAddressDestination', defaultMessage: 'Destination Address' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'destinationDetail.address_name') ? _.get(dataInfo, 'destinationDetail.address_name') + ',' : ''} {_.get(dataInfo, 'destinationDetail.address', '-')}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <LabeRequireForm
                      text={intl.formatMessage({ id: 'orderModalPostCodeDestination', defaultMessage: 'Destination PostCode' })}
                      req={false}
                    />
                  }
                >
                  {_.get(dataInfo, 'destinationDetail.postcode', '-')}
                </Form.Item>
              </Col>
            </Row>
          </div> */}

          <div className="item-title" style={{ padding: '10px 24px 0px 24px' }}>
            <span className="item-title-text" style={{ fontSize: '15px' }}>
              {_.get(dataInfo, 'detailTypeCode') === 'deliveryOrderItem' ? (
                <FormattedMessage id="deliveryOrderViewItem" defaultMessage="Item" />
              ) : (
                <FormattedMessage id="deliveryOrderViewParcel" defaultMessage="Parcel" />
              )}
            </span>
          </div>

          <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />

          <div style={{ padding: '24px' }}>
            <Table
              dataSource={tableData}
              columns={_.get(dataInfo, 'detailTypeCode') === 'deliveryOrderItem' ? columnsItem : columnsParcel}
              pagination={false}
            />
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DeliveryViewOrder;
