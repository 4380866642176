import React, { useEffect } from 'react';
import { Badge, Col, DatePicker, Form, Icon, Input, Row, Select } from 'antd';
import _ from 'lodash';
import LabeRequireForm from '../../../../../components/label-required-form';
import { useIntl } from 'react-intl';
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;

const CreditNoteInvView = (props) => {
  const { dataForm, initial } = props;
  const intl = useIntl();

  return (
    <Form colon={false} layout="vertival" className="creditnote-form">
      <Row gutter={[24, 8]}>
        <Col span={8}>
          <Form.Item
            label={<LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelINVNo', defaultMessage: 'INV No.' })} req={false} />}
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>{_.get(dataForm, 'invNo') || '-'}</span>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelINVDate', defaultMessage: 'INV Date' })} req={false} />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>
                {_.get(dataForm, 'invDate') ? moment(_.get(dataForm, 'invDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'creditNoteFormLabelINVIssuedBy', defaultMessage: 'INV Issued by' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>{_.get(dataForm, 'invIssuedBy') || '-'}</span>
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 8]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelCustomer', defaultMessage: 'Customer' })} req={false} />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>{_.get(dataForm, 'customer') || 'Customer'}</span>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'creditnoteFormLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'invNo') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span
                    title={`${_.get(dataForm, 'address')}} ${(<Badge color="#000000" className="badge-text" />)} ${_.get(
                      dataForm,
                      'fullAddress'
                    )}`}
                    style={{ color: '#050505' }}
                  >
                    {_.get(dataForm, 'address')} - {/* <Badge color="#000000" className="badge-text" />  */}
                    {_.get(dataForm, 'fullAddress')}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#898989' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelTaxNo', defaultMessage: 'Tax No.' })}{' '}
                    {_.get(dataForm, 'customerTaxNo') || '-'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <Icon
                    type="environment"
                    style={{
                      fontSize: '12px',
                      color: '#1D3557',
                      paddingLeft: '0px',
                      paddingRight: '4px',
                      cursor: 'pointer',
                    }}
                  />
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelCustomerAddress', defaultMessage: 'Customer Address' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelTaxNo', defaultMessage: 'Tax No.' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'creditnoteFormLabelCustomerContact', defaultMessage: 'Contact' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            {_.get(dataForm, 'invNo') ? (
              <div className="text-group-customer">
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>{_.get(dataForm, 'customerContactName')}</span>
                </div>
                <div className="text-location-short">
                  <span style={{ color: '#050505' }}>
                    {_.get(dataForm, 'customerContactPhone')}, {_.get(dataForm, 'customerContactEmail')}
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-group-customer">
                <div className="text-short-placeholer">
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelCustomerContact', defaultMessage: 'Contact' })}
                  </span>
                </div>
                <div className="text-short-placeholer">
                  <span style={{ color: '#050505' }}>
                    {intl.formatMessage({ id: 'creditnoteFormLabelEmail', defaultMessage: 'Email' })},{' '}
                    {intl.formatMessage({ id: 'creditnoteFormLabelPhone', defaultMessage: 'Phone' })}
                  </span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 8]}>
        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm text={intl.formatMessage({ id: 'creditNoteFormLabelIssueBy', defaultMessage: 'Issue by' })} req={false} />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>
                {_.get(dataForm, 'issueBy')
                  ? _.chain(_.get(initial, 'optionsIssueBy'))
                      .filter((issueby) => issueby.memComId === _.get(dataForm, 'issueBy'))
                      .get('[0].fullname')
                      .value()
                  : '-'}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={
              <LabeRequireForm
                text={intl.formatMessage({ id: 'creditNoteFormLabelIssueDate', defaultMessage: 'Issue Date' })}
                req={false}
              />
            }
            className="form-line-height"
          >
            <div className="text-location-short">
              <span style={{ color: '#050505' }}>
                {_.get(dataForm, 'issueDate') ? moment(_.get(dataForm, 'issueDate'), 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col span={8}></Col>
      </Row>
    </Form>
  );
};

export default CreditNoteInvView;
