import React, { useEffect, useRef, useState } from 'react';
import LabeRequireForm from '../../../../components/label-required-form';
import _ from 'lodash';
import Button_01 from '../../../../components/v2/button_01';
import { Button, Divider, Icon, Input, Popover, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleKeyDownDecimal2Fixed, handleKeyDownNumber } from '../../../../component-function/fnc-number';
import CustomTableComponent from '../form/detail-component/table';
import { numberFormatter } from '../../../../component-function/fnc-inputnumber-currency';
import moment from 'moment';
import CreditNoteInvView from './detail-component/inv-form';
import CreditNotePriceView from './detail-component/price-form';
import ModalPDFViewver from '../../../../component-function/pdf-viewver';
import ReactBnbGallery from 'react-bnb-gallery';

const { Option } = Select;

const CreditNoteDetail = (props) => {
  const { initial, dataForm, setDataForm, selectItem, setSelectItem, attachmentData, setAttachmentData } = props;

  const intl = useIntl();
  const ref2 = useRef();
  const langValue = localStorage.getItem('langValue');

  const [visibleInvoice, setVisibelInvoice] = useState(false);
  const [visibleAddItem, setVisibleAddItem] = useState(false);

  // Attachment
  const [openUpload, setOpenUpload] = useState(false);
  const [typeAttachment, setTypeAttachment] = useState('');
  const [defaultAttachment, setDefaultAttachment] = useState({});

  const [stateModal, setStateModal] = useState(false);
  const [galleryImg, setGalleryImg] = useState([]);
  const [numberImage, setNumberImage] = useState(0);

  console.log('selectItem', selectItem);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 60,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnItemCode', defaultMessage: 'ItemCode' }),
      dataIndex: 'itemCode',
      key: 'itemCode',
      sorter: (a, b) => {
        return a.itemCode.localeCompare(b.itemCode);
      },
      width: 140,
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnItem', defaultMessage: 'Item' }),
      dataIndex: 'item',
      key: 'item',
      width: 140,
      sorter: (a, b) => {
        return a.item.localeCompare(b.item);
      },
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnINVQty', defaultMessage: 'INV Qty' }),
      dataIndex: 'invQty',
      key: 'invQty',
      width: 140,
      sorter: (a, b) => {
        return a.item.localeCompare(b.item);
      },
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnINVPriceUnit', defaultMessage: 'INV Price/Unit' }),
      dataIndex: 'invPriceUnit',
      key: 'invPriceUnit',
      width: 200,
      sorter: (a, b) => {
        return a.invPriceUnit.localeCompare(b.invPriceUnit);
      },
      render: (text, record) => (text ? numberFormatter(text) : 0) + ' THB',
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnType', defaultMessage: 'Type' }),
      dataIndex: 'type',
      key: 'type',
      width: 200,
      sorter: (a, b) => {
        return a.type.localeCompare(b.type);
      },
      render: (text, record) =>
        text === 'return' ? (
          <FormattedMessage id="modalCreditNoteColumnTypeReturn" defaultMessage="Return" />
        ) : text === 'discount' ? (
          <FormattedMessage id="modalCreditNoteColumnTypeDiscount" defaultMessage="Discount" />
        ) : (
          '-'
        ),
    },

    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnQty', defaultMessage: 'Qty' }),
      dataIndex: 'qty',
      key: 'qty',
      width: 200,
      align: 'right',
      sorter: (a, b) => {
        return a.qty.localeCompare(b.qty);
      },
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnUnit', defaultMessage: 'Unit' }),
      dataIndex: 'unitName',
      key: 'unitName',
      width: 100,
      sorter: (a, b) => {
        return a.unit.localeCompare(b.unit);
      },
    },

    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnPriceUnit', defaultMessage: 'Price/Unit' }),
      dataIndex: 'priceUnit',
      key: 'priceUnit',
      width: 250,
      // align: _.get(dataForm, 'code') !== 'view' ? 'right' : 'left',
      sorter: (a, b) => {
        return a.priceUnit.localeCompare(b.priceUnit);
      },
      render: (text, record) => (text ? numberFormatter(text) : 0) + ' THB',
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnCreditNoteRemark', defaultMessage: 'Creadit Note Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
      render: (text, record) =>
        text
          ? _.chain(_.get(initial, 'optionsRemark'))
              .filter((remark) => remark.reasonCode === text)
              .get(langValue === 'TH' ? '[0].detail.TH' : '[0].detail.EN')
              .value()
          : '',
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnOthers', defaultMessage: 'Others (Please specify)' }),
      dataIndex: 'others',
      key: 'others',
      width: 300,
    },
    {
      title: intl.formatMessage({ id: 'modalCreditNoteColumnAmount', defaultMessage: 'Amount' }),
      dataIndex: 'amount',
      key: 'amount',
      fixed: 'right',
      width: 200,
      align: 'right',
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record) => {
        console.log('record', record);
        return (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {numberFormatter(_.get(record, 'amount') || 0) + ' ' + intl.formatMessage({ id: 'quotationLabelTHB', defaultMessage: 'THB' })}
          </div>
        );
      },
    },
  ];

  const handleOpenModalAddItem = () => {
    setVisibleAddItem(true);
  };

  // Attachment

  const columnsAtt = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (text, record, index) => (
        <span style={{ cursor: 'pointer' }} onClick={() => handlePreview(record, attachmentData)}>
          {record.attachmentName}
        </span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const contentActionAttment = (record) => {
    console.log('contentAction', record);
    return (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUpload(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record.id)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    );
  };

  const handleCreateUpload = () => {
    setTypeAttachment('add');
    setOpenUpload(true);
  };

  const handleEditUpload = (data) => {
    setDefaultAttachment(data);
    setTypeAttachment('edit');
    setOpenUpload(true);
  };

  const deleteAtt = (id) => {
    const filterSelect = _.filter(attachmentData, (item) => {
      return item.id !== id;
    });
    setAttachmentData(filterSelect);
  };

  const handlePreview = (record, data) => {
    const isPDF = _.includes(_.get(record, 'attachmentName'), 'pdf');
    if (isPDF) return window.open(_.get(record, 'fileRaw'), '_blank');
    const mapPhoto = data.map((item, index) => {
      return {
        photo: _.get(item, 'fileRaw'),
        number: index,
        caption: _.get(item, 'attachmentName'),
        subcaption: _.get(item, 'remark'),
        thumbnail: _.get(item, 'fileRaw'),
      };
    });
    const filerDefault = _.filter(mapPhoto, (item) => {
      return item.photo === record.fileRaw;
    });
    setGalleryImg(mapPhoto);
    setNumberImage(_.get(filerDefault, '[0].number'));
    setStateModal(true);
  };

  return (
    <div style={{ height: '550px', overflowY: 'auto' }}>
      <div style={{ margin: '0px 24px' }}>
        <CreditNoteInvView dataForm={dataForm} initial={initial} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px 24px 0px 24px' }}>
        <span className="item-title-text">
          <FormattedMessage id="creditNoteItem" defaultMessage="Item" />
        </span>
      </div>
      <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />

      <div className="content-body">
        <CustomTableComponent
          onRow={true}
          role={true}
          columns={columns}
          dataSource={selectItem}
          scroll={{ x: true }}
          // contentAction={contentActionItem}
        />

        <div className="content-body-price">
          <CreditNotePriceView dataForm={dataForm} initial={initial} />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '24px 24px 0px 24px' }}>
        <span className="item-title-text">
          <FormattedMessage id="purchesModalTitleAttachment" defaultMessage="Attachment" />
        </span>
      </div>
      <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />

      <div className="content-body">
        <CustomTableComponent
          onRow={true}
          role={true}
          columns={columnsAtt}
          dataSource={_.sortBy(attachmentData, [
            function(o) {
              return o.index;
            },
          ])}
          scroll={{ x: true }}
          // contentAction={contentActionAttment}
        />
      </div>

      {/* ************************************* Modal ************************************/}

      {/* <ModalPDFViewver
        visible={visiblePrint}
        setVisible={setVisiblePrint}
        url={urlPrint}
        handlePrint={handlePrint}
        handlePrintA5={handlePrintA5}
        singlePage={true}
      /> */}

      {_.size(attachmentData) > 0 ? (
        <ReactBnbGallery show={stateModal} photos={galleryImg} onClose={() => setStateModal(false)} activePhotoIndex={numberImage} />
      ) : null}
    </div>
  );
};

export default CreditNoteDetail;
