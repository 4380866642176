import React, { useState } from 'react';
import CustomerForm from './customer-pending';
import { FormattedMessage, useIntl } from 'react-intl';
import Button_01 from '../../../../../components/v2/button_01';
import CustomTableComponent from '../../../components/table';
import PriceForm from './price-form-pending';
import { Button, Divider, Dropdown, Icon, Input, Menu, Popover } from 'antd';
import { numberFormatter } from '../../../../../component-function/fnc-inputnumber-currency';
import _ from 'lodash';
import ModalSelectinvoiceForm from '../../select-invoice/invoice-select-modal';
import ReactBnbGallery from 'react-bnb-gallery';
import { DownOutlined } from '@ant-design/icons';
import ModalSelectCreditNoteForm from '../../select-credit-note/credit-note-select-modal';
import moment from 'moment';

const DetailsTabPending = ({ form, property }) => {
  const intl = useIntl();
  const {
    handleOpenModalUpload,
    setDataForm,
    dataForm,
    setVisibleItem,
    initialMemberList,
    handleOpen,
    selectItem,
    setSelectItem,
    attData,
    setAttData,
    initialTaxList,
    handleOpenAddItem,
    saleOrderQTY,
    setSaleOrderQTY,
    recordList,
  } = property;

  console.log('qtyTxt', selectItem, '/Ddatafrom', dataForm);
  console.log('attData', attData);

  const [isOpenQT, setIsOpenQT] = useState(false);
  const [isOpenCreditNote, setIsOpenCreditNote] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [modalView, setModalView] = useState();

  const handleOpenQuotationNo = () => {
    setIsOpenQT(true);
  };

  const handleSelectQuotationNo = (value) => {
    setIsOpenQT(false);
  };

  const handleCancelQuotationNo = () => {
    setIsOpenQT(false);
  };

  const handleOpenCreditNote = () => {
    setIsOpenCreditNote(true);
  };

  const handleSelectCreditNote = (value) => {
    setIsOpenCreditNote(false);
  };

  const handleCancelCreditNote = () => {
    setIsOpenCreditNote(false);
  };

  const handleChangeInputRemark = (value, index) => {
    let temp = [...selectItem];
    temp[index].remark = value;
    setSelectItem(temp);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record) => (
        <Popover
          content={contentActionItem(record)}
          trigger="click"
          // placement="rightTop"
        >
          <div style={{ width: '70px' }}>{text}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'billingDocumentNo', defaultMessage: 'Document No.' }),
      dataIndex: 'documentNo',
      key: 'documentNo',
      sorter: (a, b) => {
        return a.documentNo.localeCompare(b.documentNo);
      },
      width: 150,
      fixed: 'left',
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '150px' }}>{text}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'biliingnoteColumnDueDate', defaultMessage: 'Due Date' }),
      dataIndex: 'dueDateTxt',
      key: 'dueDateTxt',
      sorter: (a, b) => {
        return a.dueDateTxt.localeCompare(b.dueDateTxt);
      },
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '120px' }}>{text}</div>
        </Popover>
      ),
    },
    {
      title: intl.formatMessage({ id: 'biliingnoteColumnIssuedDate', defaultMessage: 'Issued Date' }),
      dataIndex: 'issueDateTxt',
      key: 'issueDateTxt',
      sorter: (a, b) => {
        return a.issueDateTxt.localeCompare(b.issueDateTxt);
      },
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '120px' }}>{text}</div>
        </Popover>
      ),
      // render: (text, record, index) => console.log('qtyTxt');,
    },
    {
      title: intl.formatMessage({ id: 'biliingnoteColumnRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
      sorter: (a, b) => {
        return a.remark.localeCompare(b.remark);
      },
      render: (text, record, index) =>
        _.get(dataForm, 'code') === 'view' ? (
          <Input
            value={_.get(record, 'remark')}
            onChange={(e) => handleChangeInputRemark(e.target.value, index)}
            placeholder={intl.formatMessage({ id: 'biliingnoteColumnRemarkPlaceholder', defaultMessage: 'Enter Remark' })}
          />
        ) : (
          _.get(record, 'remark')
        ),
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsAmount', defaultMessage: 'Amount' }),
      dataIndex: 'totalTxt',
      key: 'totalTxt',
      fixed: 'right',
      width: 150,
      sorter: (a, b) => {
        return a.totalTxt.localeCompare(b.totalTxt);
      },
      render: (text, record) => (
        <Popover content={contentActionItem(record)} trigger="click">
          <div style={{ width: '150px' }}>{text}</div>
        </Popover>
      ),
      // render: (text, record, index) => _.get(record,'totalTxt') + ' ' + 'THB',
    },
  ];

  const columnsAttach = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
      dataIndex: 'attachmentName',
      key: 'attachmentName',
      sorter: true,
      render: (x, data) =>
        _.get(dataForm, 'code') !== 'view' ? <span onClick={() => handlePreview(data)}>{data.attachmentName}</span> : data.attachmentName,
    },
    // {
    //   title: intl.formatMessage({ id: 'purchesOrderModalColumnsFileName', defaultMessage: 'File Name' }),
    //   dataIndex: 'fileName',
    //   key: 'fileName',
    //   sorter: true,
    //   render: (text, record, index) =>
    //     _.get(dataForm, 'code') === 'view' ? (
    //       <>{_.get(record, 'fileName')}</>
    //     ) : (
    //       <Button
    //         style={{ color: '#050505' }}
    //         onClick={() => {
    //           window.open(_.get(record, 'fileUrl'), _.get(record, 'fileName', '_blank'));
    //         }}
    //         type="link"
    //       >
    //         {_.get(record, 'fileName')}
    //       </Button>
    //     ),
    //   // render: (text, record, index) => (<a href={_.get(record,'fileUrl')}>{_.get(record,'fileName')}</a>),
    // },
    {
      title: intl.formatMessage({ id: 'purchesOrderModalColumnsRemark', defaultMessage: 'Remark' }),
      dataIndex: 'remark',
      key: 'remark',
    },
  ];

  const deleteAtt = (value) => {
    const filterSelect = _.filter(attData, (item) => {
      return item.id !== value.id;
    });
    console.log('fileHash', value);
    setAttData(filterSelect);
  };

  const handleEditUploadInside = (value) => {
    handleOpenModalUpload(value);
  };

  const deleteItem = (value) => {
    let filterSelect = []

    if (_.get(value, 'invoiceId')) {
      filterSelect = _.filter(selectItem, (item) => {
        return item.invoiceId !== value.invoiceId;
      });
    } else if (_.get(value, 'creditNoteId')) {
      filterSelect = _.filter(selectItem, (item) => !_.isEqual(item, value));
    }

  
    let tempSO = _.get(filterSelect, '[0]');

    const mapDelete = _.map(filterSelect, (item, index) => {
      return {
        ...item,
        index: index + 1,
      };
    });
    console.log('deleteItem', value, selectItem, filterSelect, mapDelete)

    setSaleOrderQTY(tempSO);
    setSelectItem(mapDelete);
  };

  console.log(selectItem, _.get(dataForm, 'code'), "selectItemselectItem")

  const contentActionItem = (record) => {
    console.log('contentActionllllllll', record);
    return _.get(dataForm, 'code') === 'view' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteItem(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  const contentAction = (record) => {
    console.log('contentAction', record);
    return _.get(dataForm, 'code') === 'view' ? (
      <div style={{ display: 'grid' }}>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => handleEditUploadInside(record)}>
            <FormattedMessage id="orgTabMemberBtnEdit" defaultMessage="Edit" />
          </Button>
        </div>
        <div>
          <Button style={{ width: 100 }} type="link" ghost onClick={() => deleteAtt(record)}>
            <FormattedMessage id="btnDelete" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    ) : null;
  };

  const HandlerModal = (item) => {
    return (
      <div>
        <ReactBnbGallery show={stateModal} photos={modalView} onClose={() => setStateModal(false)} />
      </div>
    );
  };

  const handlePreview = async (file) => {
    console.log('filehandlePreview', file);
    let isPDF = _.get(file, 'attachmentName').includes('.pdf');
    if (isPDF) return window.open(_.get(file, 'fileRaw'), '_blank');
    const mapdata = {
      photo: _.get(file, 'fileRaw'),
      number: 1,
      caption: _.get(file, 'remark') !== 'undefined' ? _.get(file, 'remark') : undefined,
      thumbnail: _.get(file, 'fileRaw'),
    };

    console.log('mapdata', mapdata);
    setModalView(mapdata);
    setStateModal(!stateModal);
  };

  const menuColumn = () => {
    return (
      // <PageSettings.Consumer>
      //   {({ checkPermissionAction }) => (
      <Menu>
        <Menu.Item key="1">
          {_.get(dataForm, 'code') === 'view' ? (
            <Button
              key="1"
              ghost
              type="link"
              onClick={() => handleOpenQuotationNo()}
              disabled={_.get(dataForm, 'customerName') ? false : true}
              style={{
                border: _.get(dataForm, 'customerName') ? undefined : 'none',
              }}
            >
              <FormattedMessage id="menuInvoice" defaultMessage="Invoice" />
            </Button>
          ) : null}
        </Menu.Item>
        {_.get(dataForm, 'code') === 'view' ? (
          <Menu.Item key="2">
            <Button
              key="2"
              ghost
              type="link"
              onClick={() => handleOpenCreditNote()}
              disabled={_.get(dataForm, 'customerName') ? false : true}
              style={{
                border: _.get(dataForm, 'customerName') ? undefined : 'none',
              }}
            >
              <FormattedMessage id="ARCreditNote" defaultMessage="Credit Note" />
            </Button>
          </Menu.Item>
        ) : null}
      </Menu>
      //   )}
      // </PageSettings.Consumer>
    );
  };

  return (
    <div className="scroll-sm scroll-height">
      {/* {_.get(dataForm, 'code') !== 'view' ? (
        <>
          <div className="item-title">
            <span className="item-title-text">
              <FormattedMessage id="quotationLabelDetails" defaultMessage="Details" />
            </span>

            <div style={{ width: '18%', paddingTop: '3px' }}>
              <p
                style={{
                  // backgroundColor: record['statusColor'],
                  backgroundColor: _.get(dataForm, 'statusColor'),
                  color: 'white',
                  textAlign: 'center',
                  borderRadius: '10px',
                  // height: '23px',
                  width: '100%',
                  padding: ' 0px 10px',
                }}
              >
                {_.get(dataForm, 'stautsTxt')}
              </p>
            </div>
          </div>

          <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        </>
      ) : null} */}
      <div className="content-body">
        <CustomerForm
          form={form}
          setDataForm={setDataForm}
          dataForm={dataForm}
          initialMemberList={initialMemberList}
          handleOpen={handleOpen}
          selectItem={selectItem}
        />
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="BLDocument" defaultMessage="Document" />
          </span>
          <div>
            {/* {_.get(dataForm, 'code') === 'view' ? (
              <Button_01
                key="add"
                style={{ margin: '0px 0px 0px 10px' }}
                type="primary"
                btnsize="wd_df"
                onClick={() => handleOpenQuotationNo()}
                disabled={_.get(dataForm, 'customerName') ? false : true}
              >
                <FormattedMessage id="btnAdd" defaultMessage="Add" />
              </Button_01>
            ) : null} */}
            {_.get(dataForm, 'code') === 'view' && (
              <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight" className="ant-dropdown-custom">
                <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => {}}>
                  <FormattedMessage id="btnAdd" defaultMessage="Add" />
                  <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
                </Button_01>
              </Dropdown>
            )}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columns}
            dataSource={selectItem}
            scroll={{ x: 1500 }}
            // contentAction={contentActionItem}
          />
          <div className="content-body-price">
            <PriceForm form={form} selectItem={selectItem} setDataForm={setDataForm} dataForm={dataForm} initialTaxList={initialTaxList} />
          </div>
        </div>
      </div>
      <div>
        <div className="item-title">
          <span className="item-title-text">
            <FormattedMessage id="quotationLabelAttactment" defaultMessage="Attactment" />
          </span>
          <div>
            {_.get(dataForm, 'code') === 'view' ? (
              <Button_01
                key="add"
                style={{ margin: '0px 0px 0px 10px' }}
                type="primary"
                btnsize="wd_df"
                onClick={() => handleOpenModalUpload()}
              >
                <FormattedMessage id="btnUpload" defaultMessage="Upload" />
              </Button_01>
            ) : null}
          </div>
        </div>
        <Divider type="horizontal" style={{ marginBottom: '10px', marginTop: '10px' }} />
        <div className="content-body">
          <CustomTableComponent
            onRow={true}
            role={true}
            columns={columnsAttach}
            dataSource={attData}
            scroll={{ x: true }}
            contentAction={contentAction}
          />
          <HandlerModal />
        </div>
      </div>
      <ModalSelectinvoiceForm
        visible={isOpenQT}
        onOk={handleSelectQuotationNo}
        onCancel={handleCancelQuotationNo}
        setDataForm={setDataForm}
        dataForm={dataForm}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
      />
      <ModalSelectCreditNoteForm
        visible={isOpenCreditNote}
        onOk={handleSelectCreditNote}
        onCancel={handleCancelCreditNote}
        setDataForm={setDataForm}
        dataForm={dataForm}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
      />
    </div>
  );
};

export default DetailsTabPending;
