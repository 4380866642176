import React, { useContext, useState } from 'react';
import { PageSettings } from '../../config/page-settings';
import { OverviewContext } from './context';
import { StickyContainer } from 'react-sticky';
import { Button, Card, Col, DatePicker, Dropdown, Icon, Menu, Modal, Row, Select, Tabs } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import Button_01 from '../../components/v2/button_01';
import _ from 'lodash';
import moment from 'moment';
import TabMapOverview from './talaadthai/map/index';
import Talaadthai from './component/talaadthai';
import ItemTop from './component/item-top';
import TabRevenueOverview from './talaadthai/revenue';
import TabServiceOverview from './talaadthai/service-unloading';
import FilterOnTopTab from './talaadthai/component/filter-op-top-tab';
import Button_02 from '../../components/v2/button_02';
import ItemTopGlobal from './market/component/item-top';
import store from '../../components/image/store.svg';

const { TabPane } = Tabs;
const { Option } = Select;

const OverviewPage = ({ fullscreenPageRef, isFullScreen, setIsFullScreen }) => {
  const intl = useIntl();
  const { state, setState, func } = useContext(OverviewContext);

  const { tabKey, filterDateTab, dataBucket } = state;
  const { setTabKey, setFilterDate, setfilterDateTab, setFilterDateDuration } = setState;
  const {} = func;

  const [dateRange, setDateRange] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [itemOption, setItemOption] = useState([
    {
      value: 'today',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiToday', defaultMessage: 'Today' }),
    },
    {
      value: 'thisweek',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiThisWeek', defaultMessage: 'This Week' }),
    },
    {
      value: 'thismonth',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiThisMonth', defaultMessage: 'This Month' }),
    },
    {
      value: 'duration',
      lable: intl.formatMessage({ id: 'taecoTableTalaadthaiDuration', defaultMessage: 'Duration' }),
    },
  ]);

  const callback = (key) => {
    setTabKey(key);
  };

  const tabBarExtra = tabKey === 'map' && (
    <div>
      <Select
        placeholder={intl.formatMessage({ id: 'reportItemSalesPHRefModel', defaultMessage: 'Select Item' })}
        defaultActiveFirstOption={false}
        showSearch
        allowClear
        filterOption={false}
        defaultValue={'today'}
        value={filterDateTab}
        onChange={(value) => {
          if (value) {
            onChangeDate(value);
          } else {
            setfilterDateTab('today');
            setFilterDateDuration([]);
          }
        }}
        onSelect={(value) => {
          onChangeDate(value);
        }}
        style={{ width: '200px' }}
      >
        {_.map(itemOption, (item) => (
          <Option key={item.value} value={item.value}>
            {item.lable}
          </Option>
        ))}
      </Select>
    </div>
  );

  const buttonFullScreen = (
    <Button_01
      key="submit"
      type="primary"
      style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '11%' }}
      onClick={() => {
        setIsFullScreen((prev) => !prev);
      }}
    >
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Icon type="fullscreen" style={{ marginRight: 8, fontSize: 24 }} />
        <FormattedMessage id="btnFullScreen" defaultMessage="Full Screen" />
      </span>
    </Button_01>
  );

  const onChangeDate = (value) => {
    if (value === 'duration') {
      setIsModalVisible(true);
    } else {
      setfilterDateTab(value);
      setFilterDateDuration([]);
    }
  };

  const handleOk = () => {
    const [startDate, endDate] = dateRange;
    console.log(
      'jCheck',
      dateRange,
      moment(startDate, 'DD/MM/YYYY').format('YYYY/MM/DD'),
      moment(endDate, 'DD/MM/YYYY').format('YYYY/MM/DD')
    );
    const formattedStartDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

    setfilterDateTab('duration');
    setFilterDateDuration([formattedStartDate, formattedEndDate]);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDateRange([]);
  };

  return (
    <PageSettings.Consumer>
      {({ checkPermissionAction }) => (
        <div>
          <Row gutter={[16, 16]} style={{ backgroundColor: '#fafafa' }}>
            <Col span={24}>
              {/* <Talaadthai fullscreenPageRef={fullscreenPageRef} /> */}
              <FilterOnTopTab
                title={
                  <span>
                    {/* <Icon type="shop" style={{ marginRight: 8, fontSize: 16 }} /> */}
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        marginRight: 8,
                        marginBottom: 3,
                        mask: `url(${store}) no-repeat center`,
                        WebkitMask: `url(${store}) no-repeat center`,
                        backgroundColor: '#1d3557',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    ></div>
                    {intl.formatMessage({ id: 'taecoTableTalaadthai', defaultMessage: 'Talaadthai' })}
                  </span>
                }
                // button={buttonFullScreen}
                date={true}
                setDate={setFilterDate}
                paddingEdit={'12px 24px'}
                single={false}
                mutiSelect={false}
                allMonth={true}
              />
            </Col>
            <Col span={24}>
              {/* <ItemTop /> */}
              <ItemTopGlobal dataBucket={dataBucket} />
            </Col>
            <Col span={24}>
              <Card className="monitor-main-card">
                <StickyContainer>
                  <Tabs
                    className="monitor-main-tabs customer-tab-margin"
                    size="large"
                    defaultActiveKey="map"
                    onChange={callback}
                    animated={false}
                    tabBarStyle={{ padding: '0 24px' }}
                    tabBarExtraContent={tabBarExtra}
                  >
                    <TabPane
                      tab={intl.formatMessage({ id: `OverviewTabMap`, defaultMessage: 'Map' })}
                      key="map"
                      style={{ overflowY: 'scroll' }}
                    >
                      <TabMapOverview />
                    </TabPane>

                    <TabPane tab={intl.formatMessage({ id: `OverviewTabRevenue`, defaultMessage: 'Revenue' })} key="revenue">
                      <TabRevenueOverview />
                    </TabPane>

                    <TabPane tab={intl.formatMessage({ id: `OverviewTabService`, defaultMessage: 'Service (Unlonding)' })} key="service">
                      <TabServiceOverview />
                    </TabPane>

                    <TabPane tab={intl.formatMessage({ id: `OverviewTabVechicle`, defaultMessage: 'Vechicle' })} key="vechicle"></TabPane>
                  </Tabs>
                </StickyContainer>
              </Card>
            </Col>
          </Row>

          <Modal
            title={intl.formatMessage({ id: 'taecoTableTalaadthaiSelectDuration', defaultMessage: 'Select Duration' })}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button_02 style={{ margin: '0px 0px 0px 10px' }} key="back" btnsize="wd_df" onClick={() => handleCancel()}>
                <FormattedMessage id="btnClose" defaultMessage="Cancel" />
              </Button_02>,
              <Button_01 key="submit" type="primary" btnsize="wd_df" onClick={() => handleOk()}>
                <FormattedMessage id="btnSave" defaultMessage="Save" />
              </Button_01>,
            ]}
          >
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              value={dateRange.length ? [moment(dateRange[0], 'DD/MM/YYYY'), moment(dateRange[1], 'DD/MM/YYYY')] : []}
              onChange={(dates, dateStrings) => setDateRange(dateStrings)}
            />
          </Modal>
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default OverviewPage;
